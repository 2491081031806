.react-tiny-popover-container {
    z-index: 9998;
}

.page-digitalsprout {
    header {
        display: none;
    }

    .quick {
        display: none;
    }
}


.active_tab_course {
    border-bottom: 2px solid #00863C;
}

//헤더영역

.DigitalsproutHeader {
    width: 100%;
    height: 40px;
    border-bottom: 1px solid #ddd;

    @media screen and(max-width:768px) {
        display: none;
    }

    .DigitalsproutHeader_content {
        width: 100%;
        max-width: 1200px;
        height: 100%;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        align-items: center;

        @media screen and(max-width:1024px) {
            width: 95%;
        }


        .Header_content_Left {
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;


            img {
                width: 190px;
            }
        }

        .Header_content_right {
            height: 70%;
            display: flex;
            justify-content: center;
            align-items: center;

            .text-user {
                font-weight: 500;
                font-size: 13px;
                line-height: 16px;
                color: #333333 !important;
                margin-right: 7px;
            }

            .text-user-logout {
                font-weight: 400;
                font-size: 13px;
                line-height: 16px;
                color: #949494 !important;
                padding-right: 8px;
                border-right: 1px solid #ddd;
            }


            .text-user-level {
                padding: 0 8px;
                border-right: 1px solid #ddd;
                font-weight: 500;
                font-size: 13px;
                line-height: 16px;
                color: #333333 !important;
                display: flex;
                align-items: flex-end;

                .gray_text_class {
                    margin-left: 4px;
                    font-weight: 400;
                    font-size: 13px;
                    line-height: 16px;
                    color: #949494 !important;

                }
            }

            .text-user-xps {
                padding: 0 8px;
                border-right: 1px solid #ddd;
                font-weight: 500;
                font-size: 13px;
                line-height: 16px;
                color: #333333 !important;
                display: flex;
                align-items: flex-end;

                .gray_text_class {
                    margin-left: 4px;
                    font-weight: 400;
                    font-size: 13px;
                    line-height: 16px;
                    color: #949494 !important;

                }
            }

            .text-user-badge {
                padding: 0 8px;
                border-right: 1px solid #ddd;
                font-weight: 500;
                font-size: 13px;
                line-height: 16px;
                color: #333333 !important;
                display: flex;
                align-items: flex-end;

                .gray_text_class {
                    margin-left: 4px;
                    font-weight: 400;
                    font-size: 13px;
                    line-height: 16px;
                    color: #949494 !important;

                }
            }

            .text-user-mypage {
                padding: 0 8px;
                font-weight: 500;
                font-size: 13px;
                line-height: 16px;
                color: #949494 !important;
            }

            .text-user-admin {
                padding-left: 8px;
                border-left: 1px solid #ddd;
                font-weight: 500;
                font-size: 13px;
                line-height: 16px;
                color: #949494 !important;
            }

            a {
                font-weight: 400;
                font-size: 12px;
                line-height: 14px;
                color: #333333;

                &:nth-child(1) {
                    margin-right: 20px;
                    padding-right: 20px;
                    border-right: 2px solid #ddd;
                }
            }
        }

    }
}




.nav_body_bottom {
    height: 70%;
    display: flex;
    justify-content: center;
    align-items: center;

    .text-user {
        font-weight: 500;
        font-size: 13px;
        line-height: 16px;
        color: #333333 !important;
        margin-right: 7px;
    }

    .text-user-logout {
        font-weight: 400;
        font-size: 13px;
        line-height: 16px;
        color: #949494 !important;
        padding-right: 8px;
        border-right: 1px solid #ddd;
    }


    .text-user-level {
        padding: 0 8px;
        border-right: 1px solid #ddd;
        font-weight: 500;
        font-size: 13px;
        line-height: 16px;
        color: #333333 !important;
        display: flex;
        align-items: flex-end;

        .gray_text_class {
            margin-left: 4px;
            font-weight: 400;
            font-size: 13px;
            line-height: 16px;
            color: #949494 !important;

        }
    }

    .text-user-xps {
        padding: 0 8px;
        border-right: 1px solid #ddd;
        font-weight: 500;
        font-size: 13px;
        line-height: 16px;
        color: #333333 !important;
        display: flex;
        align-items: flex-end;

        .gray_text_class {
            margin-left: 4px;
            font-weight: 400;
            font-size: 13px;
            line-height: 16px;
            color: #949494 !important;

        }
    }

    .text-user-badge {
        padding: 0 8px;
        border-right: 1px solid #ddd;
        font-weight: 500;
        font-size: 13px;
        line-height: 16px;
        color: #333333 !important;
        display: flex;
        align-items: flex-end;

        .gray_text_class {
            margin-left: 4px;
            font-weight: 400;
            font-size: 13px;
            line-height: 16px;
            color: #949494 !important;

        }
    }

    .text-user-mypage {
        padding: 0 8px;
        font-weight: 500;
        font-size: 13px;
        line-height: 16px;
        color: #949494 !important;
    }

    .text-user-admin {
        padding-left: 8px;
        border-left: 1px solid #ddd;
        font-weight: 500;
        font-size: 13px;
        line-height: 16px;
        color: #949494 !important;
    }

    a {
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
        color: #333333;

        &:nth-child(1) {
            margin-right: 20px;
            padding-right: 20px;
            border-right: 2px solid #ddd;
        }
    }
}


.software_header {
    width: 100%;
    border-bottom: 1px solid #ddd;

    .software_header_content {
        width: 100%;
        max-width: 1200px;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;

        .software_header_content_top {
            margin-top: 40px;
            margin-bottom: 30px;
            display: flex;
            align-items: center;

            .toggle_nav_btn {
                position: absolute;
                top: 40%;
                left: 5%;
                transform: translate(-10%, -50%);
                display: none;

                @media screen and(max-width:768px) {
                    display: block;
                }
            }


            img {
                @media screen and(max-width:768px) {
                    width: 70%;
                    margin: 0 auto;
                    display: flex;
                    justify-content: center;
                    align-items: cetner;
                }
            }
        }

        .software_header_content_bottom {
            width: 100%;
            max-width: 690px;
            margin: 0 auto 0 auto;
            display: flex;
            justify-content: space-between;
            align-items: center;

            @media screen and(max-width:768px) {
                width: 90%;
            }

            span {
                font-weight: 600;
                font-size: 16px;
                line-height: 19px;
                color: #282828;
            }

            a {
                width: 10%;
                text-align: center;
                padding-bottom: 15px;

                @media screen and(max-width:768px) {
                    width: 20%;
                }
            }

            .targetA {
                border-bottom: 4px solid #066B47;
            }
        }
    }
}


.mobile_nav_wrapper {
    width: 100%;
    min-width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.85);
    z-index: 9999;
    height: 100%;
    overflow: hidden;

    & * {
        touch-action: none;
    }

    &>div {
        width: 95%;
        height: 100%;
        background: #fff;

        .nav_top_wrap {
            width: 100%;
            max-width: 90%;
            margin: 0 auto;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 10px 0;

            img {
                width: 90%;
                max-width: 44px;
            }
        }

        .nav_body_wrap {
            width: 100%;
            max-width: 90%;
            margin: 0 auto;
            display: flex;
            flex-direction: column;
            gap: 40px;
            margin-top: 100px;

            a {
                display: flex;
                justify-content: space-between;
                align-items: center;

                span {
                    font-weight: 400;
                    font-size: 22px;
                    letter-spacing: -0.02em;
                    color: #333333;
                }
            }
        }
    }
}


.software_maincontent_wrap {
    width: 100%;

    .software_maincontent_section1 {
        width: 100%;
        height: 736px;
        position: relative;

        @media screen and (max-width:1024px) {
            height: auto;
            padding: 60px 0 0 0;
        }

        .video_bg_wrap {
            width: 100%;
            height: 100%;
            overflow: hidden;
            position: absolute;
            top: 50%;
            left: 50%;
            z-index: -1;
            transform: translate(-50%, -50%);

            video {
                text-align: center;
                margin: 0 auto;
                width: 100%;

                @media screen and (max-width:1024px) {
                    width: auto;
                }
            }
        }

        .section1_content_top,
        .section1_content_mid,
        .section1_content_bottom {
            width: 100%;
            max-width: 1200px;
            margin: 0 auto;
        }

        .section1_content_top {
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
            height: 60%;

            @media screen and(max-width:1024px) {
                flex-direction: column-reverse;
                gap: 30px;
                justify-content: center;
            }

            @media screen and(max-width:768px) {
                flex-direction: column-reverse;
                height: 80%;
            }

            .top_content_left_notice {
                width: 48%;
                display: flex;
                flex-direction: column;
                gap: 15px;
                background: rgba(255, 255, 255, 0.95);
                border-radius: 4px;
                padding: 25px;

                @media screen and(max-width:1024px) {
                    width: 90%;
                    background: rgba(255, 255, 255, 0.95);
                    border-radius: 4px;
                    align-self: center;
                }

                @media screen and(max-width:768px) {
                    width: 100%;
                    background: #fff;
                    border-radius: 0px;
                }

                .notice_top {
                    width: 100%;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                    h3 {
                        color: #066B47;
                        font-weight: 600;
                        font-size: 18px;
                        line-height: 21px;
                        position: relative;

                        &::before {
                            content: "";
                            position: absolute;
                            top: 25px;
                            height: 4px;
                            width: 100%;
                            background: #066B47;
                        }
                    }

                    button {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }
                }

                .notice_bottom {
                    width: 100%;

                    .notice_text_wrap {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;

                        .notice_text {
                            font-weight: 400;
                            font-size: 15px;
                            line-height: 25px;
                            color: #282828;

                            @media screen and(max-width:768px) {
                                width: 300px;
                                // padding:0 5px;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                white-space: nowrap;
                            }

                            @media screen and(max-width:414px) {
                                width: 250px;
                                // padding:0 5px;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                white-space: nowrap;
                            }
                        }

                        .notice_date {
                            font-weight: 400;
                            font-size: 13px;
                            line-height: 25px;
                            color: #518D78;
                        }
                    }
                }
            }

            .top_content_right_login {
                width: 30%;
                display: flex;
                flex-direction: column;
                gap: 15px;
                background: #FFFFFF;
                border-radius: 4px;
                padding: 25px;

                @media screen and(max-width:1024px) {
                    width: 45%;
                    margin-right: 5%;
                }

                @media screen and(max-width:768px) {
                    width: 80%;
                    margin: 0 auto 50px auto;
                }

                @media screen and(max-width:414px) {
                    width: 95%;
                    margin: 0 auto 50px auto;
                }

                &>h3 {
                    width: 100%;
                    text-align: center;
                    font-weight: 600;
                    font-size: 18px;
                    line-height: 21px;
                    color: #282828;
                }

                .sw_login_form {
                    width: 100%;

                    .login_form_input_wrap {
                        display: flex;
                        flex-direction: column;
                        justify-content: flex-start;
                        margin: 10px 0;

                        label {
                            font-weight: 500;
                            font-size: 13px;
                            line-height: 16px;
                            margin-bottom: 3px;
                            color: #767676;
                        }

                        input {
                            background: #FFFFFF;
                            border: 1px solid #D5D8E2;
                            border-radius: 4px;
                            height: 40px;
                            padding-left: 20px;

                            &::placeholder {
                                font-weight: 400;
                                font-size: 14px;
                                line-height: 17px;
                                color: #767676;
                            }
                        }
                    }


                    .checkId {
                        margin: 20px 0 12px 0;
                        display: flex;
                        gap: 10px;
                        align-items: center;
                        justify-content: flex-start;

                        input[type="checkbox"] {
                            display: none;
                        }

                        input[type="checkbox"]+label {
                            display: inline-block;
                            width: 20px;
                            height: 20px;
                            border-radius: 4px;
                            overflow: hidden;
                            border: 1px solid #ddd;
                            position: relative;
                        }

                        input[id="saveLogin"]:checked+label::after {
                            content: '✔';
                            color: #fff;
                            background: #066B47;
                            font-size: 13px;
                            width: 20px;
                            height: 20px;
                            text-align: center;
                            position: absolute;
                            left: 0;
                            top: 0;
                        }

                        .id_save {
                            font-weight: 300;
                            font-size: 14px;
                            line-height: 17px;
                            color: #282828;
                        }
                    }

                    .login_button_wrap {
                        width: 100%;
                        margin-bottom: 13px;

                        .sw_login_button {
                            width: 100%;
                            height: 45px;
                            background: #282828;
                            border-radius: 4px;
                            color: #fff;
                            text-align: center;
                        }
                    }

                    .sign_wrap {
                        width: 100%;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;

                        .id_pw_find {
                            font-weight: 400;
                            font-size: 14px;
                            line-height: 17px;
                            color: #767676;
                        }

                        .signup {
                            font-weight: 500;
                            font-size: 14px;
                            line-height: 17px;
                            color: #4B9986;
                        }
                    }
                }
            }



        }

        .section1_content_mid {
            margin-top: 30px;
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: start;
            color: #fff;

            @media screen and(max-width:1024px) {
                padding: 30px;
            }

            @media screen and(max-width:768px) {
                background: #0A2421;
                margin-top: 0px;
            }

            &>span {
                content: "";
                height: 7px;
                width: 115px;
                background-color: #fff;

                @media screen and(max-width:500px) {
                    width: 90px;
                }
            }

            &>h3 {
                font-weight: 500;
                font-size: 18px;
                line-height: 21px;
                color: #FFFFFF;
                margin: 10px 0 15px 0;

                &>span {
                    color: #EAFF97;
                    font-size: 18px;

                    @media screen and(max-width:500px) {
                        font-size: 16px;
                    }
                }
            }

            &>h2 {
                font-weight: 300;
                font-size: 35px;
                line-height: 42px;
                color: #FFFFFF;
                text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.29);

                @media screen and(max-width:768px) {
                    font-size: 23px;
                }

                @media screen and(max-width:500px) {
                    font-size: 20px;
                }

                &>span {
                    font-weight: 700;
                    font-size: 35px;
                    line-height: 42px;

                    @media screen and(max-width:768px) {
                        font-size: 23px;
                    }

                    @media screen and(max-width:500px) {
                        font-size: 20px;
                    }
                }
            }
        }

        .section1_content_bottom {
            width: 100%;
            min-width: 100%;
            display: flex;
            margin-top: 30px;

            @media screen and(max-width:1024px) {
                background: #0A2421;
                margin-top: 50px;
                flex-wrap: wrap;
            }

            @media screen and(max-width:768px) {
                background: #0A2421;
                margin-top: 0px;
                flex-direction: column;
            }

            .section1_bottom_not_content_wrap {
                width: 100%;
                // max-width: 360px;
                background: rgba(255, 255, 255, 0.89);
                height: 100%;
                // max-height: 139px;
                min-height: 139px;
                border-bottom: 6px solid #fff;

                &:nth-child(1) {
                    border-right: 1px solid rgba(60, 53, 45, 0.33);
                }

                @media screen and(max-width:1024px) {
                    display: none;
                }
            }

            .section1_bottom_content_wrap {
                width: 100%;
                max-width: 300px;
                height: 100%;
                background: rgba(255, 255, 255, 0.89);
                height: 100%;
                // max-height: 139px;
                min-height: 139px;
                display: flex;
                justify-content: center;
                align-items: center;
                border-right: 1px solid rgba(60, 53, 45, 0.33);

                @media screen and(max-width:1024px) {
                    flex-wrap: wrap;
                    width: 50%;
                    min-width: 50%;
                    max-width: 50%;
                }

                @media screen and(max-width:768px) {
                    max-width: 100%;
                    min-width: 100%;
                }

                &:nth-child(even) {
                    border-bottom: 6px solid #011844;
                }

                &:nth-child(odd) {
                    border-bottom: 6px solid #066B47;
                }

                &>div {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-direction: column;

                    .title {
                        font-weight: 500;
                        font-size: 20px;
                        line-height: 24px;
                        color: #282828;
                        margin-bottom: 5px;
                    }

                    .title_en {
                        font-weight: 500;
                        font-size: 13px;
                        line-height: 16px;
                        color: #00472E;
                        opacity: 0.7;
                    }
                }
            }
        }
    }

    .software_maincontent_section2 {
        padding-top: 80px;
        width: 100%;
        max-width: 1200px;
        margin: 0 auto;

        @media screen and(max-width:768px) {
            width: 90%;
        }

        .doing_top {
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin-bottom: 95px;

            @media screen and(max-width:768px) {
                margin-bottom: 25px;
            }

            &>p {
                margin-bottom: 25px;
                font-weight: 500;
                font-size: 18px;
                line-height: 21px;
                color: #333333;

                @media screen and(max-width:768px) {
                    margin-bottom: 15px;
                }

                &>span {
                    font-weight: 500;
                    font-size: 18px;
                    line-height: 21px;
                    color: #2C7B5E;

                    @media screen and(max-width:500px) {
                        font-size: 16px;
                    }
                }
            }

            &>h2 {
                font-weight: 300;
                font-size: 35px;
                line-height: 42px;
                color: #333333;

                @media screen and(max-width:768px) {
                    font-size: 25px;
                }

                @media screen and(max-width:500px) {
                    font-size: 22px;
                }

                &>span {
                    font-weight: 700;
                    font-size: 35px;
                    line-height: 42px;
                    color: #333333;

                    @media screen and(max-width:768px) {
                        font-size: 25px;
                    }

                    @media screen and(max-width:500px) {
                        font-size: 22px;
                    }
                }

            }
        }

        .doing_cont_wrap {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            gap: 40px;
            margin-bottom: 125px;

            @media screen and(max-width:1024px) {
                flex-wrap: wrap;
                padding: 0 50px;
            }

            @media screen and(max-width:768px) {
                padding: 0;
            }


            .doing_cont {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                width: 30%;
                gap: 35px;

                @media screen and(max-width:1024px) {
                    width: 47%;
                }

                @media screen and(max-width:768px) {
                    width: 100%;
                }

                .doing_cont_imgwrap {
                    border-radius: 13px;
                    overflow: hidden;
                    display: flex;
                    justify-content: center;
                    align-items: flex-end;
                    width: 100%;
                    max-width: 280px;
                    height: 100%;
                    max-height: 297px;
                    min-height: 297px;
                    // padding-bottom: 30px;

                    img {
                        width: 100%;
                    }

                }

                .odd {
                    background: #011844;
                }

                .even {
                    background: linear-gradient(148.05deg, #209168 5.22%, #066B47 95.56%);
                }

                .doing_cont_contwrap {
                    width: 80%;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;

                    p {
                        word-break: keep-all;

                        &:nth-child(1) {
                            font-weight: 700;
                            font-size: 18px;
                            line-height: 21px;
                            color: #066B47;
                            margin-bottom: 10px;
                        }

                        &:nth-child(2) {
                            font-weight: 500;
                            font-size: 15px;
                            line-height: 18px;
                            color: #282828;
                            text-align: center;
                        }
                    }
                }

            }


            @media screen and(max-width:768px) {
                flex-direction: column;
            }
        }

        .learning_experience {
            border-left: 7px solid #011844;
            padding-left: 10px;
            margin-bottom: 45px;

            @media screen and(max-width:1024px) {
                margin-left: 30px;
            }

            @media screen and(max-width:768px) {
                margin-bottom: 20px;
                margin-left: 0;
            }

            &>p {
                font-weight: 500;
                font-size: 18px;
                line-height: 21px;
                color: #282828;
                margin-bottom: 30px;

                @media screen and(max-width:768px) {
                    margin-bottom: 11px;
                }

                &>span {
                    font-weight: 500;
                    font-size: 18px;
                    line-height: 21px;
                    color: #066B46;

                    @media screen and(max-width:500px) {
                        font-size: 16px;

                    }
                }
            }

            &>h2 {
                font-weight: 300;
                font-size: 35px;
                line-height: 42px;
                color: #282828;

                @media screen and(max-width:768px) {
                    font-size: 25px;

                }

                @media screen and(max-width:500px) {
                    font-size: 22px;

                }

                &>span {
                    font-weight: 700;
                    font-size: 35px;
                    line-height: 42px;
                    color: #282828;

                    @media screen and(max-width:768px) {
                        font-size: 25px;

                    }

                    @media screen and(max-width:500px) {
                        font-size: 22px;

                    }
                }

            }
        }

        .computer_wrap {
            width: 100%;
            max-width: 1980px;
            position: relative;
            margin-bottom: 150px;

            &::before {
                content: "";
                width: 2400px;
                height: 300px;
                position: absolute;
                background: #011844;
                right: 50%;
                top: 20%;
                z-index: 2;

                @media screen and(max-width:1024px) {
                    height: 350px;
                    right: 0;
                }

                @media screen and(max-width:768px) {
                    display: none;
                }
            }

            &::after {
                content: "";
                width: 2400px;
                height: 220px;
                position: absolute;
                background: #066B47;
                left: 27%;
                top: 60%;
                z-index: 1;

                @media screen and(max-width:1024px) {
                    height: 350px;
                    left: 0;
                }

                @media screen and(max-width:768px) {
                    display: none;
                }
            }

            .img_wrap {
                width: 100%;
                max-width: 1200px;
                display: flex;
                justify-content: space-between;
                align-items: flex-start;
                padding: 0 15px;

                @media screen and(max-width:1024px) {
                    flex-direction: column;
                    padding: 0;
                }

                img {
                    width: 60%;
                    z-index: 3;

                    @media screen and(max-width:1024px) {
                        width: 70%;
                        margin: 0 auto;
                    }

                    @media screen and(max-width:768px) {
                        width: 100%;
                    }
                }
            }
        }


        .instructor_wrap {
            width: 100%;
            max-width: 1980px;
            position: relative;
            margin-bottom: 100px;
            display: flex;
            justify-content: space-between;
            align-items: center;

            @media screen and(max-width:1024px) {
                flex-direction: column-reverse;
            }

            .instructor_contetn_left {
                width: 40%;
                display: flex;
                flex-wrap: wrap;
                justify-content: space-around;
                align-items: center;
                gap: 10px;

                @media screen and(max-width:1024px) {
                    width: 100%;
                }

                &>div {
                    width: 30%;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    gap: 10px;

                    img {
                        width: 120px;
                        height: 120px;

                        @media screen and(max-width:1024px) {
                            width: 160px;
                            height: 160px;
                        }

                        @media screen and(max-width:768px) {
                            width: 110px;
                            height: 110px;
                        }
                    }

                    p {
                        font-weight: 500;
                        font-size: 15px;
                        line-height: 18px;
                        color: #333333;

                        span {
                            font-weight: 600;
                            font-size: 12px;
                            line-height: 18px;
                            color: #333333;
                        }
                    }
                }
            }

            .instructor_contetn_right {
                width: 45%;
                position: relative;

                @media screen and(max-width:1024px) {
                    width: 60%;
                    margin: 0 auto 30px auto;
                }

                @media screen and(max-width:768px) {
                    width: 90%;
                    margin: 0 auto 30px auto;
                }

                // &>img {
                //     position: absolute;
                //     right: 10%;
                //     top: 75%;
                //     transform: translate(0, -50%);
                //     z-index: -1;
                //     width: 200px;
                //     height: 210px;

                //     @media screen and(max-width:768px) {
                //         right: -3%;
                //         top: 75%;
                //         z-index: -1;
                //         width: 180px;
                //         height: 180px;
                //     }

                //     @media screen and(max-width:500px) {
                //         right: -3%;
                //         top: 75%;
                //         z-index: -1;
                //         width: 250px;
                //         height: 250px;
                //     }

                //     @media screen and(max-width:375px) {
                //         right: -2%;
                //         top: 75%;
                //         z-index: -1;
                //         width: 140px;
                //         height: 140px;
                //     }
                // }

                &>div {
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    align-items: flex-start;

                    &>p {
                        font-weight: 500;
                        font-size: 18px;
                        line-height: 21px;
                        margin-bottom: 20px;
                        color: #333333;

                        @media screen and(max-width:768px) {
                            font-size: 14px;
                            margin-bottom: 10px;
                        }

                        &>span {
                            font-weight: 500;
                            font-size: 18px;
                            line-height: 21px;
                            color: #0D6746;

                            @media screen and(max-width:768px) {
                                font-size: 14px;
                            }
                        }
                    }

                    &>h3 {
                        font-weight: 300;
                        font-size: 35px;
                        line-height: 42px;
                        color: #333333;

                        @media screen and(max-width:768px) {
                            font-size: 25px;
                        }

                        .ins_h3_000 {
                            font-weight: 700;
                            font-size: 35px;
                            line-height: 42px;
                            color: #333333;

                            @media screen and(max-width:768px) {
                                font-size: 25px;
                            }
                        }

                        .ins_h3_fff {
                            font-weight: 300;
                            font-size: 35px;
                            line-height: 42px;
                            color: #fff;

                            @media screen and(max-width:768px) {
                                font-size: 25px;
                            }
                        }
                    }
                }
            }
        }

    }

}

.slideWrapper_Sw {
    width: 100%;
    max-width: 1980px;
    position: relative;
    border-top: 1px solid #ddd;
    height: 60px;

    .course-data {
        display: flex;
        width: 100%;
        max-width: 1200px;
        margin: 0 auto;
        height: 100%;
        align-items: center;

        .card_slide_top {
            .slide_button {
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 10px;
            }
        }
    }

    .swiper-slide {
        img {
            width: 140px !important;
            height: 30px !important;
        }
    }

}

.user_top {
    display: flex;
    justify-content: space-between;

    .namewrap {
        width: 30%;

        p {
            font-weight: 600;
            font-size: 16px;
            color: #282828;

            &>span {
                margin-left: 6px;
                font-weight: 500;
                font-size: 14px;
            }
        }
    }

    .datawrap {
        width: 75%;
        display: flex;
        align-items: center;

        .text-user-logout {
            font-weight: 400;
            font-size: 12px;
            line-height: 14px;
            color: #949494;
            padding-right: 10px;

            @media screen and(max-width:414px) {
                font-size: 11px;
            }
        }

        .text-user-message {
            padding: 0 10px;
            border-right: 1px solid #ddd;
            border-left: 1px solid #ddd;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 3px;

            .message_text {
                font-weight: 400;
                font-size: 12px;
                line-height: 14px;
                color: #949494;

                @media screen and(max-width:414px) {
                    font-size: 11px;
                }
            }

            .header__msgcnt {
                background: #489659;
                height: 16px;
                width: 16px;
                display: inline-block;
                border-radius: 10px;
                display: flex;
                justify-content: center;
                align-items: center;

                &>p {
                    font-size: 10px;
                    vertical-align: middle;
                    color: #fff;
                }
            }
        }

        .text-user-mypage {
            font-weight: 400;
            font-size: 12px;
            line-height: 14px;
            color: #949494;
            padding-left: 10px;

            @media screen and(max-width:414px) {
                font-size: 11px;
            }
        }

    }

}

.game_wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 13px 50px;
    background-color: #1D3F58;
    border-radius: 4px;
    box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.07);
    margin-top: 16px;

    &>div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        &>span {
            color: #fff;
            font-weight: 600;
            font-size: 14px;
            line-height: 16px;
            color: #FFFFFF;

        }

        &>p {
            display: flex;
            align-items: center;
            gap: 3px;

            &>span {
                font-weight: 400;
                font-size: 12px;
                line-height: 13px;
                color: #DFDFD8;
            }
        }
    }
}

.faq_wrapper {

    @media screen and(max-width:1024px) {
        width: 95%;
        margin: 0 auto;
    }

    &.content {
        padding: 0 !important;
    }

    .sub__content {
        padding-left: 0 !important;
    }

    .cont_header {
        margin: 70px 0 25px 0;

        h2 {
            font-weight: 500;
            font-size: 25px;
            line-height: 30px;
            color: #2B2F3B;
            width: 100%;
            padding: 10px 0;
            border-bottom: 1px solid #333;
        }
    }

    .input {
        @media screen and(max-width:768px) {
            width: 100% !important;
        }
    }
}

.notice_wrapper {

    @media screen and(max-width:1024px) {
        width: 95%;
        margin: 0 auto;
    }

    &.content {
        padding: 0 !important;
    }

    .sub__content {
        padding-left: 0 !important;
    }

    .cont_header {
        margin: 70px 0 25px 0;

        h2 {
            font-weight: 500;
            font-size: 25px;
            line-height: 30px;
            color: #2B2F3B;
            width: 100%;
            padding: 10px 0;
            border-bottom: 1px solid #333;
        }
    }
}

.my_course_wrap {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 20px;

    .my_course_top {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin-bottom: 8px;

        &>span {
            font-weight: 400;
            font-size: 12px;
            line-height: 14px;
            color: #3E3E3E;
            cursor: pointer;

            &:nth-child(1) {
                padding: 0 6px;
            }

            &:nth-child(2) {
                padding-left: 6px;
                padding-right: 6px;
                border-left: 1px solid #ddd;
            }

            &:nth-child(3) {
                padding-left: 6px;
                border-left: 1px solid #ddd;
            }
        }

    }

    .my_course_bottom {
        height: 100%;
        max-height: 180px;
        min-height: 180px;
        overflow-y: scroll;
        overflow-x: hidden;

        &::-webkit-scrollbar {
            width: 8px;
        }

        &::-webkit-scrollbar-thumb {
            height: 30px;
            background: rgba(105, 105, 105, 0.65);
            border-radius: 19px;
            background-clip: padding-box;
            border: 2px solid transparent;
        }

        &::-webkit-scrollbar-track {
            background: none
        }

        .my_course_cont_wrap {
            display: flex;
            flex-direction: column;
            border: 1px solid #ddd;
            overflow: hidden;
            border-radius: 4px;
            margin-bottom: 9px;

            .my_course_data_top {
                display: flex;
                border-bottom: 1px solid #ddd;

                .top_img_wrap {
                    width: 35%;
                    max-width: 84px;
                    height: 50px;
                    /* flex-basis: 35%; */
                    overflow: hidden;
                    margin-right: 10px;

                    img {
                        width: 100%;
                        max-width: 80px;
                    }

                }

                .top_cont_wrap {
                    padding: 5px 0 0 0;

                    p {
                        font-weight: 400;
                        font-size: 11px;
                        line-height: 15px;
                        color: #696969;
                    }

                    .course_tt {
                        font-weight: 600;
                        font-size: 13px;
                        line-height: 16px;
                        color: #333333;
                        width: 160px;
                        // padding: 0 5px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                    }
                }

            }

            .my_course_data_bottom {
                display: flex;
                justify-content: space-between;

                &>div:nth-child(1) {
                    // width: 20%;
                    min-width: 80px;
                    max-width: 80px;
                    font-weight: 300;
                    font-size: 12px;
                    line-height: 14px;
                    color: #00863C;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-right: 1px solid #ddd;
                    padding: 7px 0;

                    .rightNum {
                        color: #949494;
                        font-weight: 300;
                        font-size: 12px;
                        line-height: 14px;
                    }
                }

                &>div:nth-child(2) {
                    // width: 50%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    padding: 7px 15px;

                    &>button {
                        font-weight: 500;
                        font-size: 12px;
                        line-height: 14px;
                        color: #066B47;
                    }

                    &>a {
                        font-weight: 500;
                        font-size: 12px;
                        line-height: 14px;
                        color: #066B47;
                    }
                }

                &>div:nth-child(3) {
                    // width: 30%;
                    padding: 7px 8px;
                    background: #066B47;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-left: 1px solid #ddd;

                    a {
                        color: #fff;
                        font-weight: 500;
                        font-size: 12px;
                        line-height: 14px;
                    }
                }
            }

            .myCourseList {

                &>div:nth-child(1),
                &>div:nth-child(3) {
                    display: none;
                }

                &>div:nth-child(2) {
                    width: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
            }

        }
    }
}


.digitalsprout_wrapper {
    .sub__content {
        max-width: 100%;
    }

    .content {
        padding-top: 30px;
    }

    .board__list-header {
        display: block;
    }

    .boardDetail__info {
        justify-content: flex-start !important;
    }

    .board__header {
        @media screen and(max-width:1220px) {
            display: flex;
            flex-direction: column;
            gap: 10px;
        }
    }

    .introduction_title {
        border-bottom: none !important;
        line-height: 0 !important;

    }

    .sub__title {
        border-bottom: none !important;
        line-height: 0 !important;
    }

    .board__header {
        align-items: center;
    }
}

.introduction_content {



    .board__search {
        @media screen and(max-width:500px) {
            flex-wrap: wrap;
            justify-content: space-between !important;
        }

        .select {
            @media screen and(max-width:500px) {
                width: 28%;
            }
        }

        .input {
            @media screen and(max-width:500px) {
                width: 70%;
            }
        }

        button {
            @media screen and(max-width:500px) {
                margin: 10px 0 0 0;
                width: 48%;
            }
        }
    }
}


.introduction_content_wrap {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;

    @media screen and(max-width:1024px) {
        width: 95%;
    }

    .cont_header {
        margin: 70px 0 25px 0;

        h2 {
            font-weight: 500;
            font-size: 25px;
            line-height: 30px;
            color: #2B2F3B;

            width: 100%;
            padding: 10px 0;
            border-bottom: 1px solid #333;
        }
    }

    .cont_body {
        width: 100%;

        .content_text {
            margin-bottom: 30px;

            @media screen and(max-width:768px) {
                width: 100%;
                margin: 0 auto 30px auto;
                word-break: keep-all;
                ;
            }

            p {
                font-weight: 400;
                line-height: 26px;
                color: #333333;

                @media screen and(max-width:768px) {
                    font-size: 14px;
                }
            }
        }

        .top30 {
            margin-top: 30px;
        }

        .boldtext {
            font-weight: 500 !important;
        }
    }

    .cont_footer {
        width: 100%;
        margin: 100px 0 75px 0;
        display: flex;
        justify-content: flex-end;
        align-items: center;

        &>.footer_cont {
            display: flex;
            justify-content: center;
            align-items: flex-end;
            gap: 15px;

            &>span {
                padding-right: 8px;
                border-right: 4px solid #066B47;
                text-align: right;

                p {
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 19px;
                    color: #333333;

                    span {
                        font-weight: 500;
                        font-size: 25px;
                        line-height: 30px;
                        margin-right: 3px;
                        color: #333333;
                    }
                }

            }
        }
    }
}

.curriculum_content_wrap {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    display: flex;

    @media screen and(max-width:1024px) {
        width: 95%;
    }

    .lnb {
        @media screen and(max-width:768px) {
            display: none;
        }
    }

    .cc_content {
        flex: 1 1;
        padding: 0 10px 0 50px;
        max-width: calc(100% - 200px);

        @media screen and(max-width:768px) {
            max-width: 100%;
            padding: 0;
            margin: 0 auto;

        }
    }

    .cont_header {
        margin: 70px 0 25px 0;

        h2 {
            font-weight: 500;
            font-size: 25px;
            line-height: 30px;
            color: #2B2F3B;

            width: 100%;
            padding: 10px 0;
            border-bottom: 1px solid #333;
        }
    }

    .curriculum_top_cont {
        padding: 25px 15px;
        border-bottom: 1px solid #ddd;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        gap: 10px;

        &>div {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            width: 48%;
            gap: 10px;

            &:nth-last-child(1) {
                width: 100%;
            }

            @media screen and(max-width:1075px) {
                width: 100%;
                flex-direction: column;
                align-items: flex-start;
                gap: 0;
                border-bottom: 1px solid #ddd;
            }

            &>p:nth-child(1) {
                width: 23%;
                max-width: 80px;
                word-break: keep-all;
                font-size: 16px;
                letter-spacing: -0.02em;
                color: #333333;
                font-weight: 500;

                @media screen and(max-width:768px) {
                    width: 100%;
                }
            }

            &>p:nth-child(2) {
                font-size: 15px;
                line-height: 28px;
                color: #696969;

                @media screen and(max-width:768px) {
                    width: 100%;
                }
            }
        }
    }

    .curriculum_mid_cont {
        margin-top: 35px;

        &>h3 {
            font-weight: 500;
            font-size: 16px;
            color: #333333;
            position: relative;
            padding-left: 15px;
            margin-bottom: 20px;

            &::after {
                content: '';
                display: block;
                width: 0.7em;
                height: 0.7em;
                border: 3px solid #489659;
                border-radius: 50%;
                position: absolute;
                left: 0;
                top: 5px;
            }
        }
    }

    .lnb__title {
        margin-top: 70px !important;
    }
}


.curriculum_mid_cont {

    .programTable thead tr th {
        font-weight: 500;
        font-size: 16px;
        color: #333333;
    }

    .programTable thead tr th.title {
        width: 5%;
    }

    .programTable thead tr th:nth-child(3) {
        width: 50%;
    }

    .programTable thead tr th:nth-child(4) {
        width: 12%;
    }

    .programTable thead tr th:nth-child(5) {
        width: 12%;
    }

    .programTable thead tr th:nth-child(6) {
        width: 12%;
    }

    .programTable tbody tr td {
        font-weight: 400;
        font-size: 15px;
        color: #333333;
        word-break: keep-all;

        &:nth-child(3) {
            text-align: left;
        }
    }

    .mobileChart {
        border-bottom: 1px solid #ddd;
    }

    .divBox {
        @media screen and(max-width:768px) {
            flex-direction: column;
            margin: 10px 0;
        }

        .rightBox {
            @media screen and(max-width:768px) {
                width: 100%;
                word-break: keep-all;
            }
        }
    }

    .mobileChart .divBox .leftBox {
        width: 120px;
        display: flex;
        align-items: center;
        justify-content: start;
        font-weight: 700;
        font-size: 1rem;
    }

    .study_top_30 {
        margin-top: 30px;
    }

    .study_top_40 {
        margin-top: 40px;
    }

    .study_top_80 {
        margin-top: 80px;
    }

    .study_map {
        padding: 10px 30px;
        display: flex;
        justify-content: flex-start;
        // align-items: center;
        flex-direction: column;
        background: #F6F6F6;
        border-radius: 4px;

        @media screen and(max-width:768px) {
            gap: 15px;
        }


        &>div {
            display: flex;

            @media screen and(max-width:1024px) {
                flex-direction: column;
            }

            span {
                font-size: 15px;
                line-height: 28px;
                color: #696969;

                &:nth-child(1) {
                    width: 100px;
                    margin-right: 5px;
                    font-weight: 500;

                    @media screen and(max-width:1024px) {
                        width: 100%;
                    }
                }
            }

            &:nth-child(2) {

                @media screen and(max-width:1024px) {
                    margin-top: 10px;
                }

                span {
                    &:nth-child(1) {
                        letter-spacing: 26px;
                    }
                }
            }
        }
    }
}

.camp_time {
    width: 100%;
    border: 1px solid #ddd;
    border-radius: 4px;

    @media screen and(max-width:1200px) {
        overflow-x: scroll;
    }

    &>table {
        min-width: 940px;

        tr {


            th,
            td {
                text-align: center;
            }

            th {
                padding: 10px 0;
                font-weight: 500;
                font-size: 16px;
                color: #333333;
                border-bottom: 1px solid #ddd;
            }

            td {
                font-weight: 400;
                font-size: 15px;
                text-align: center;
                color: #696969;
                padding: 10px 0;
            }
        }

        tbody {
            tr {
                &:nth-child(even) {
                    background: #F6F6F6;
                }

                td {
                    border-right: 1px dashed #ddd;

                    &:nth-last-child(1) {
                        border: none;
                    }
                }
            }
        }
    }
}

.camp_notice {
    width: 100%;
    background: #F6F6F6;
    border-radius: 4px;
    padding: 20px 30px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 10px;
    margin-bottom: 100px;

    &>div {
        display: flex;
        align-items: center;

        @media screen and(max-width:768px) {
            flex-direction: column;
            align-items: flex-start;
        }

        &>span {
            &:nth-child(1) {
                width: 50px;
                font-weight: 500;
                color: #696969;
                font-size: 15px;

                @media screen and(max-width:768px) {
                    font-size: 14px;

                }
            }

            &:nth-child(2) {
                font-weight: 400;
                color: #696969;
                font-size: 15px;

                @media screen and(max-width:768px) {
                    font-size: 14px;

                }
            }
        }
    }
}

.space_wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin-right: 10px;

    select {
        border: 1px solid #ddd;
        border-radius: 4px;

        option {
            font-size: 13px;
            color: #696969;
        }
    }

    button {
        background: #066B47;
        border-radius: 4px;
        width: 45px;
        font-size: 13px;
        padding: 3px 0;
        color: #FFFFFF;
    }
}

.videoPopup {
    width: 1200px;
    max-width: 100%;
    height: 800px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 9999;
    background: #F6F6F6;
    border-radius: 4px;
    overflow: hidden;
    padding: 50px 10px 10px 10px;
    filter: drop-shadow(0px 4px 30px rgba(0, 0, 0, 0.53));

    @media screen and(max-width:1024px) {
        height: 500px;
    }

    @media screen and(max-width:768px) {
        height: 400px;
    }

    button {
        position: absolute;
        right: 3%;
        top: 3%;
    }

    video {
        width: 100%;
        height: 700px;

        @media screen and(max-width:1024px) {
            height: 400px;
        }

        @media screen and(max-width:768px) {
            height: 300px;
        }
    }

}

.img_sw_video {
    @media screen and(min-width:1024px) {
        width: 45% !important;
    }
}

.digital_class_wrap {
    .top_tab_online_course {
        display: flex;

        &>div {
            cursor: pointer;
            width: 25%;
            border-top: 1px solid #ddd;
            border-bottom: 1px solid #ddd;
            border-left: 1px solid #ddd;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: 15px 0;
            gap: 5px;

            &:nth-child(1) {
                border-radius: 4px 0 0 4px;
                border-right: 1px solid #ddd;
            }


            &:nth-last-child(1) {
                border-radius: 0 4px 4px 0;
                border-right: 1px solid #ddd;
            }

            &>span {
                padding: 0 20px;

                &:nth-child(1) {
                    color: #333;
                    font-size: 11px;
                }

                &:nth-child(2) {
                    color: #48A785;
                    font-size: 22px;
                    font-weight: 600;
                }
            }
        }

        .tabActive_green {
            background: linear-gradient(96.09deg, #288E6A 41.36%, #37A07B 88.5%);
            position: relative;
            overflow: hidden;
            gap: 10px;

            &>span {
                padding: 0 20px;

                &:nth-child(1) {
                    color: #87CCB4;
                    font-size: 11px;
                    align-self: self-end;
                }

                &:nth-child(2) {
                    color: #fff;
                    font-size: 22px;
                    font-weight: 600;
                    align-self: self-start;
                }
            }

            &::before {
                content: "";
                position: absolute;
                width: 150px;
                height: 150px;
                background: #96ffda27;
                top: 40px;
                right: 160px;
                border-radius: 50%;
            }

            &::after {
                content: "";
                position: absolute;
                width: 70px;
                height: 50px;
                background: #96ffda27;
                top: 0px;
                right: 0px;
            }
        }

        .tabActive_green_right {
            &::before {
                content: "";
                position: absolute;
                width: 150px;
                height: 150px;
                background: #96ffda27;
                top: 40px;
                left: 160px;
                border-radius: 50%;
            }

            &::after {
                content: "";
                position: absolute;
                width: 70px;
                height: 50px;
                background: #96ffda27;
                top: 0px;
                left: 0px;
            }
        }
    }


    .programDetail__top {
        margin-top: 15px;
        display: flex;
        gap: 30px;
        padding-bottom: 30px;
        border-bottom: 1px solid #ddd;

        .thumb {
            border-radius: 4px;
            overflow: hidden;

            img {
                width: 300px;
            }
        }

        .infoTop {
            display: flex;
            flex-direction: column;

            .title_wrapper {
                margin-bottom: 35px;

                h2 {
                    font-weight: 500;
                    font-size: 18px;
                    line-height: 22px;
                    color: #333333;
                }
            }

            .infoDetail {
                display: flex;
                justify-content: flex-start;
                align-items: flex-start;
                flex-wrap: wrap;
                gap: 20px;

                dl {
                    width: 48%;
                    display: flex;
                    gap: 10px;

                    dt {
                        width: 100%;
                        max-width: 64px
                    }
                }
            }
        }
    }

    .programDetail__body {
        margin: 50px 0;

        ._row {
            &>h3 {
                margin-bottom: 25px;
                font-weight: 500;
                font-size: 16px;
                line-height: 19px;
                padding-left: 15px;
                color: #333333;
                position: relative;


                &:after {
                    content: "";
                    display: block;
                    width: 0.7em;
                    height: 0.7em;
                    border: 3px solid #489659;
                    border-radius: 50%;
                    position: absolute;
                    left: 0;
                    top: 3px;
                }
            }
        }
    }


    .bg_gray_color {
        ._row {
            p {
                font-size: 1rem;
                color: #535353;
                line-height: 1.4rem;
                word-break: keep-all;
                text-align: justify;
                border-radius: 4px;
                padding: 20px;
                background: #f6f6f6;
            }
        }
    }

    .detail_table_wrap {
        overflow-x: scroll;
    }

    .detail_table {
        border: 1px solid #d2d2d2;
        text-align: center;
        width: 100%;
        min-width: 825px;
        table-layout: auto;

        >thead {
            background: #f6f6f6;
        }

        th,
        td,
        tr {
            border-bottom: 0.5px solid #d2d2d2;
            vertical-align: middle;
            padding: 5px 10px;
            font-size: 1rem;
        }

        th {
            // background-color: #00b050;
            color: #333333;
            font-weight: 500;
            height: 50px;
        }

        th:nth-child(4) {
            // width: 30%;
        }

        td {
            height: 60px;
            font-size: 0.9rem;
            border: 0.5px solid #d2d2d2;


            @media screen and (max-width: 775px) {
                height: 52px;
            }

        }

        .td_week,
        .td_num {
            width: 60px;
        }

        /*.td_num+td {
            font-weight: bold;
            font-size: 1rem;
          }*/
        .tb_li {
            text-align: center;
        }

        .tb_li.quill_wrap {
            .quill {
                .ql-toolbar.ql-snow {
                    border: none;
                    padding: 0px;
                    height: 0;

                }

                .ql-container {
                    border: none !important;
                    padding: 0px;
                    margin: 0px;
                    border-radius: 0px;

                    &>.ql-editor {
                        &>p {
                            background-color: white !important;
                            text-align: initial;
                        }
                    }
                }
            }
        }

        li {
            list-style: none;
            font-size: 0.9rem;
        }

        li:before {
            content: "- "
        }

        ;
    }

    &.page-content-60938af9ddd5526f6c1d7dda {
        .td_subject {
            width: 10%;
        }
    }

    &.page-content-60938bf6ddd5526f6c1d7ddc {
        .td_subject {
            width: 16%;
        }
    }

    &.page-content-6111113da26d1e77cd86d98f {
        .td_subject {
            width: 15%;
        }

        .td_p {
            width: 10%;
        }
    }

}


.digitalsprout_wrapper {
    .notice_wrapper {
        .lnb__title {
            margin-top: 70px;
        }

        .sub__content {
            flex: 1 1;
            padding: 0 10px 0 50px !important;
            max-width: calc(100% - 200px);

            @media screen and(max-width:768px) {
                max-width: 95%;
            }
        }
    }

    .faq_wrapper {
        .lnb__title {
            margin-top: 70px;
        }

        .sub__content {
            flex: 1 1;
            padding: 0 10px 0 50px !important;
            max-width: calc(100% - 200px);

            @media screen and(max-width:768px) {
                max-width: 95%;
            }
        }
    }
}


.page-digitalsprout {
    .content {
        padding-top: 0 !important;
    }

    .sub__content {
        padding: 0 10px 0 50px !important;

        @media screen and (max-width: 768px) {
            max-width: 95%;
            margin: 0 auto;
        }

        .cont_header {
            margin: 70px 0 25px 0;

            h2 {
                font-weight: 500;
                font-size: 25px;
                line-height: 30px;
                color: #2B2F3B;
                width: 100%;
                padding: 10px 0;
                border-bottom: 1px solid #333;
            }
        }
    }

    .lnb__title {
        margin-top: 70px !important;
    }

    .boardDetail__info {
        justify-content: flex-start !important;
    }

    .board__header {
        justify-content: flex-end;
    }

}


.page-curriculum-digital-offline-class {
    .info {
        width: 90%;
    }

    .Learning_Objectives {
        background: #f6f6f6 !important;
        width: 100%;
        display: flex;
        flex-direction: column;

        p {
            padding: 0 20px !important;
            font-weight: 400 !important;
            font-size: 15px !important;
            line-height: 23px !important;
            background: none;

            &:nth-child(1) {
                padding: 20px 20px 0 20px !important;
            }

            &:nth-last-child(1) {
                padding: 0 20px 20px 20px !important;
            }

            span {
                font-weight: 400 !important;
                font-size: 15px !important;
                line-height: 23px !important;

                &:nth-child(1) {
                    font-weight: 500;
                }
            }
        }
    }

    .Learning_Objectives_table_wrap {
        width: 100%;
        overflow-x: hidden;
        border: 1px solid #ddd;
        border-radius: 4px;

        @media screen and(max-width:1240px) {
            overflow-x: scroll;
        }


        table {
            width: 100%;
            min-width: 940px;
            max-width: 940px;

            thead {
                tr {
                    th {
                        border-bottom: 1px solid #333;
                        height: 45px;
                        text-align: center;
                        line-height: 45px;
                        font-weight: 500;
                        font-size: 16px;
                        color: #333333;

                        &:nth-child(1) {
                            width: 65px;
                        }

                        &:nth-child(2) {
                            width: 170px;
                        }
                    }
                }
            }

            tbody {
                tr {
                    &:nth-last-child(1) {
                        td {
                            border-bottom: none;
                        }
                    }

                    td {
                        border-bottom: 1px solid #ddd;
                        height: 45px;
                        text-align: center;
                        line-height: 45px;
                        font-weight: 400;
                        font-size: 15px;
                        color: #696969;
                        vertical-align: middle;

                        &.spancol {
                            font-weight: 500;
                            font-size: 16px;
                            line-height: 20px;
                            text-align: center;
                            color: #333333;
                            vertical-align: middle;
                            padding: 0 15px;
                            border-bottom: none;
                        }

                        &:nth-last-child(1) {
                            text-align: left;
                            border-left: 1px solid #ddd;
                            line-height: 22px;
                            word-break: keep-all;
                            padding: 10px 0 10px 10px;
                        }

                        &:nth-last-child(2) {
                            border-left: 1px solid #ddd;
                        }
                    }
                }
            }
        }
    }

    .operation_of_the_camp {
        width: 100%;
        overflow-x: hidden;
        border: 1px solid #ddd;
        border-radius: 4px;

        @media screen and(max-width:1240px) {
            overflow-x: scroll;
        }


        table {
            width: 100%;
            min-width: 940px;
            max-width: 940px;

            thead {
                tr {
                    th {
                        border-bottom: 1px solid #333;
                        height: 45px;
                        text-align: center;
                        line-height: 45px;
                        font-weight: 500;
                        font-size: 16px;
                        color: #333333;

                        &:nth-child(1) {
                            width: 65px;
                        }

                        &:nth-child(2) {
                            width: 170px;
                        }
                    }
                }
            }

            tbody {
                tr {
                    &:nth-last-child(1) {
                        td {
                            border-bottom: none;
                        }
                    }

                    td {
                        border-bottom: 1px solid #ddd;
                        height: 45px;
                        text-align: center;
                        line-height: 45px;
                        font-weight: 400;
                        font-size: 15px;
                        color: #696969;


                        &.spancol {
                            font-weight: 500;
                            font-size: 16px;
                            line-height: 20px;
                            text-align: center;
                            color: #333333;
                            vertical-align: middle;
                            padding: 0 15px;
                        }

                        &:nth-last-child(1) {
                            text-align: left;
                            border-left: 1px solid #ddd;
                            padding-left: 10px;
                        }

                        &.textdata {
                            text-align: left;
                            padding-left: 10px;
                            line-height: 22px;
                            word-break: keep-all;
                            vertical-align: middle;
                        }
                    }
                }

                .text_td {
                    padding: 15px 0 10px 15px;

                    p {
                        background: none;
                        padding: 3px 5px;
                        font-size: 15px;
                    }
                }

                .right-border {
                    border-right: 1px solid #ddd;
                }
            }
        }
    }


    .action_plan {
        width: 100%;
        overflow-x: hidden;
        border-bottom: 1px solid #ddd;
        border-radius: 4px;

        @media screen and(max-width:1240px) {
            overflow-x: scroll;
        }


        table {
            width: 100%;
            min-width: 940px;
            max-width: 940px;

            thead {
                tr {
                    th {
                        border:1px solid #ddd;
                        border-bottom: 1px solid #333;
                        height: 45px;
                        text-align: center;
                        line-height: 45px;
                        font-weight: 500;
                        font-size: 16px;
                        color: #333333;

                        &:nth-child(1) {
                            width: 65px;
                        }

                        // &:nth-child(2) {
                        //     width: 170px;
                        // }
                    }
                }
            }

            tbody {
                tr {
                    td {
                        border: 1px solid #ddd;
                        height: 45px;
                        text-align: center;
                        line-height: 1.3rem;
                        font-weight: 400;
                        font-size: 15px;
                        color: #696969;
                        vertical-align: middle;
                        padding:10px;

                        &.spanClass{
                            border-bottom:0;

                        }
                    }

                    &:nth-last-child(1) {
                        td {
                            border-bottom: none;
                        }
                    }
                }

                .left-board {
                    border-left: 1px solid #ddd;
                }
            }
        }
    }

    .action_inst_plan {
        width: 100%;
        overflow-x: hidden;
        border-bottom: 1px solid #ddd;
        border-radius: 4px;

        @media screen and(max-width:1240px) {
            overflow-x: scroll;
        }

        table {
            width: 100%;
            min-width: 940px;
            max-width: 940px;

            thead {
                tr {
                    th {
                        border: 1px solid #ddd;
                        border-bottom: 1px solid #333;
                        height: 45px;
                        text-align: center;
                        line-height: 45px;
                        font-weight: 500;
                        font-size: 16px;
                        color: #333333;

                        &:nth-child(1) {
                            width: 65px;
                        }

                        &:nth-child(2) {
                            width: 65px;
                        }

                        &:nth-child(3) {
                            width: 550px;
                        }

                        &:nth-child(4) {
                            width: 65px;
                        }
                    }
                }
            }

            tbody {
                tr {
                    td {
                        border: 1px solid #ddd;
                        height: 45px;
                        text-align: center;
                        line-height: 1.3rem;
                        font-weight: 400;
                        font-size: 15px;
                        color: #696969;
                        vertical-align: middle;
                    }

                    &:nth-last-child(1) {
                        td {
                            border-bottom: none;
                        }
                    }
                }

                .left-board {
                    border-left: 1px solid #ddd;
                }

                .text-rowspan {
                    vertical-align: middle;
                    line-height: 25px;
                    word-break: keep-all;
                    padding: 0 5px;
                }
            }
        }
    }
}


.page-curriculum-digital-class {
    .sub .wrap {
        display: block;
    }

    .programDetail__top {
        @media screen and(max-width:1024px) {
            flex-direction: column;
        }
    }

    .sub__content {
        padding: 0 !important;

        .thumb {
            width: 240px;

            @media screen and (max-width:1024px) {
                width: 50%;
                margin: 0 auto;
            }

            @media screen and (max-width:768px) {
                width: 70%;
                margin: 0 auto;
            }
        }

        .info_cont {
            dl {
                width: 48% !important;
            }
        }
    }
}