.mobileNav {
  position: fixed;
  left: -250px;
  top: 0;
  width: 250px;
  height: 100%;
  overflow: auto;
  background: $white;
  z-index: 9999;
  transition: all 0.5s;

  &.active {
    left: 0;
  }
}

.mobileNav__header {
  //background: $theme-red;
  background: #1A9952;

  .wrap {
    line-height: 70px;
    text-align: center;
    position: relative;
    color: $white;
    font-size: 1.25rem;

    button {
      position: absolute;
      left: 10px;
      top: 50%;
      transform: translateY(-50%);
      width: 40px;
      height: 50px;
      line-height: 50px;
      color: $white;
      z-index: 2;
      font-size: 2rem;
    }
  }
}

.mobileNav__links {
  .mobileNav__link-item {
    border-bottom: 1px solid $theme-gray-3;

    button {
      line-height: 50px;
      padding: 0 1em;
      width: 100%;
      text-align: left;
      // color: rgb(59, 59, 59);
      display: flex;
      justify-content: space-between;
      align-items: center;

      span {
        font-weight: 400;
        font-size: 16px;
        color: #333;
      }
    }

    .depth {
      // background: $theme-gray-3;
      display: none;

      &.active {
        display: block;
      }

      a {
        display: flex;
        align-items: center;
        justify-content: space-between;
        line-height: 40px;
        padding: 0 1em;
        padding-left: 1.5em;
        font-size: 14px;

        &:nth-last-child(1) {
          border-bottom: 2px solid #1A9952;
        }
      }
    }

  }
}

.mobile_top_nav{
  display: flex;
  gap : 10px;
  position: relative;

  .mobileNav__Button{
    min-width: fit-content;
  }

  .depth2{
    position: absolute;
    left: 10px;
    overflow: auto;
    .active{
      color  : '#00863C';
    }
    .deactive{
      color  : '#333';
    }
  }
}