.download {
  // background: #00863e;
  color: #696969;
  font-size: 0.85rem;

  &:hover {
    color: #489659;
  }
}

.board_detail_title {
  font-weight: bold;
  margin-top: 1.5em;
  margin-bottom: 1em;
}

.board {
  margin-top: 1.25em;
  width: 100%;

  .tab-con {
    width: 100%;

    .tab {
      border: 1px solid;
      width: 50%;
      height: 50px;
    }
  }
}

.btn_error {
  background: $theme-red;
}

.subcontent_wide {
  padding-left: 0 !important;
  max-width: 100% !important;

  .sub__title {
    margin-bottom: 3.5rem !important;
  }
}


//나의강좌
.Myclass_list {
  .board__list {
    .board__list-header {
      display: none;
    }
  }

  .progress_table_box {
    width: 100%;
    overflow-x: auto;
    padding-bottom: 20px;

    .Myclass_progressrate_table {
      width: 100%;
      min-width: 1200px;
      text-align: center;
      border-top: 2px solid #000;

      thead {
        tr {
          th {
            padding: 10px;
            border: 1px solid #ddd;
            background-color: #f9f9f9;
            vertical-align: middle;

            .play_time {
              margin-top: -4px;
              font-size: 0.8rem;
            }
          }
        }
      }

      tbody {
        background-color: #fff;

        tr {
          td {
            padding: 10px;
            border: 1px solid #ddd;
            vertical-align: middle
          }
        }
      }
    }
  }

}



.board__header {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;

  @media screen and (max-width: 1220px) {
    display: block;
  }
}


.submit_container {
  text-align: right;
  margin-top: 20px;

  .evaluate_Btn_submit {
    width: 160px;
    background: #489659;
    color: white;
    height: 40px;
    border-radius: 3px;
  }

  button {
    background: #489659;
    color: white;
    height: 40px;
    border-radius: 3px;
    width: 80px;

    &.result_button {
      margin-right: 10px;
    }

    &.eval_next_btn {
      border: 1px solid #489659;
      color: #489659;
      background: white;
    }

    &.eval_prev_btn {
      border: 1px solid #489659;
      color: #489659;
      background: white;
      float: left;
    }
  }
}


.board__search {
  display: flex;

  @media screen and (max-width: 1220px) {
    margin-top: 5px;
  }

  .select,
  .input {
    height: 45px;
    border: 1px solid $theme-gray-4;
    border-radius: 3px;
    overflow: hidden;

    @media screen and (max-width: 768px) {
      height: 33px;
    }

  }

  .select {
    min-width: 110px;
    margin-right: 10px;

    @media screen and (max-width: 768px) {
      min-width: 70px;
      margin-right: 5px;
    }
  }

  .input {
    width: 210px;
  }

  input,
  select {
    border: none;
    width: 100%;
    height: 100%;
    padding: 0 10px;
    font-size: 0.9rem;
  }

  button {
    background: $theme-black-5;
    height: 45px;
    color: $white;
    border-radius: 4px;
    width: 67px;
    margin-left: 5px;
    font-size: 0.9rem;
    line-height: 0.9rem;

    @media screen and (max-width: 768px) {
      height: 33px;
    }

  }

  .reset__btn {
    //background: $theme-red;
    background: #489659;
    width: 110px;
    display: inline-block;

    &.disabled {
      display: none;
    }
  }
}

.board__list {
  margin-top: 1.25em;

  .board__list-header {
    border-top: 1px solid $theme-black-2;

    .tr {
      text-align: center;
      background: $theme-white-1;
    }
  }

  .board__list-body {
    text-align: center;

    div {
      font-size: 0.85rem;
    }
  }

  .tr {
    display: flex;
    border-bottom: 1px solid $theme-gray-3;
    height: 53px;
    align-items: center;
    justify-content: center;
    line-height: 1.25em;
  }

  .secret_lock {
    margin-left: 8px;
  }

  .col1 {
    flex: none;
    width: 65px;
    text-align: center;
  }

  .col2 {
    flex: 1;
    max-width: calc(100% - 285px);
    padding: 0 15px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .col3 {
    flex: none;
    width: 140px;
    text-align: center;
  }

  .col4 {
    flex: none;
    width: 80px;
    text-align: center;
  }

  .col-title {
    display: none;
  }

  @media screen and (max-width: 1220px) {
    .col1 {
      width: 50px;
    }

    .col2 {
      max-width: calc(100% - 190px);
    }

    .col3 {
      width: 80px;
    }

    .col4 {
      width: 60px;
    }
  }

  @media screen and (max-width: 1024px) {
    .col2 {
      max-width: calc(100% - 130px);
      padding: 0 10px;
    }

    .col4 {
      display: none;
    }

    .board__list-header {
      display: none;
    }

    .board__list-body {
      .tr {
        display: block;
        height: auto;
        position: relative;
        border: 1px solid #b7ccbc;
        //border: 1px solid $theme-gray-3;
        margin-bottom: 15px;
        padding: 3px 10px;

        &:after {
          content: '';
          display: block;
          width: 80px;
          height: 100%;
          background: #fff;
          position: absolute;
          left: 0;
          top: 0;
          z-index: 1;
        }

        &>div {
          text-align: left;
          display: flex;
          width: 100%;
          max-width: 100%;
          flex: 1;
          min-height: 25px;
          align-items: center;
          padding: 5px 10px 5px 0;
          font-size: 0.9rem;

          &.col2 {
            white-space: initial;
            height: auto;
          }
        }
      }

      .col-title {
        position: relative;
        text-align: left;
        z-index: 2;
        display: block;
        width: 80px;
        flex: none;
        line-height: 25px;
        margin-right: 10px;
        padding-left: 6px;
      }
    }
  }
}

.consulting_detail_button {
  display: flex;
  padding: 0 10px;
  margin-top: 20px;

  li {
    cursor: pointer;
    //vertical-align: middle;
    //border: 2px solid #f6f8fb;
    //background: #999999;
    //height: 70px;
    //font-size: 18px;
    //letter-spacing: 0px;
    //color: #ffffff;
    //font-weight: 500;
    //width: 100%;
    //display: inline-block;
    text-align: center;
    background: #fff;
    font-size: 1.05rem;
    line-height: 1.05rem;
    padding: 14px 5px;
    color: #9b9b9c;
    width: 125px;
    border-radius: 5px;

    &~li {
      margin-left: 10px;
    }

    @media screen and (max-width: 1024px) {
      font-size: 1rem;
      padding: 10px;

    }

    &.active {
      background: #1a9952;
      color: #fff;
      box-shadow: 0 16px 10px rgba(26, 153, 82, 0.15);
    }
  }
}

.consulting_detail {
  margin-top: 1.5em !important;
  border-top: 1px solid $theme-black-2 !important;
  // border-bottom: 1px solid #ddd;

  @media screen and (max-width: 768px) {
    margin-top: 1em !important;
  }

}

// 새로작업 //
ul[data-role=table] {
  list-style: none;
  min-width: 100%;
  overflow: auto;

  li.thead {
    //border-top-color: #222222;
    border-top: 2px solid #222222;
    border-bottom: 1px solid #e3e4e5;
  }
}

.consulting_headbox {
  border-bottom: 2px solid #808080 !important;
}



// 끝 //

.boardDetail {
  .preview {
    width: 100%;

    .btn_container {
      text-align: right;

      button {
        background: #489659;
        color: white;
        padding: 5px 15px;
        border-radius: 3px;
      }
    }
  }

  h5.tit {
    position: relative;
    margin: 45px 0 20px;
    padding-left: 25px;
    word-break: keep-all;
    font-size: 18px;
    line-height: 22px;
    color: #111;

    &:after {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      top: 3px;
      width: 9px;
      height: 16px;
      background: #00863e;
      transform: skewX(-35deg);
      transform-origin: left bottom;
    }
  }

  //margin-top: 2.5em;
  //border-top: 2px solid $theme-black-2;


  .boardDetail__title {
    background: $theme-white-1;
    border-bottom: 1px solid $theme-gray-3;
    font-size: 1.5rem;
    line-height: 1.25em;
    padding: 25px 20px;

    @media screen and (max-width: 1220px) {
      font-size: 1.25rem;
      padding: 15px 10px;
    }
  }

  .consulting_detail_cont_wrap {
    display: flex;
    padding: 10px 0;

    @media screen and(max-width:500px) {
      flex-wrap: wrap;
    }

    &>div {
      display: flex;
      align-items: center;
      width: 50%;

      @media screen and(max-width:500px) {
        width: 100%;
        // flex-wrap: wrap;
      }
    }
  }

  .consulting_title {
    width: 25%;
    font-size: 0.9rem;
    font-weight: 600;
    display: inline-block;
    border: none;
    background: none;
    padding: 10px;
    color: #333333;

    @media screen and(max-width:820px) {
      width: 30%;
      padding: 0 !important;
    }

    @media screen and(max-width:500px) {
      width: 25%;
      // flex-wrap: wrap;
    }
  }

  .consulting_sub {
    vertical-align: middle;
    width: 80%;
    display: inline-block;

    p {
      padding: 10px;
      width: 210px;
      word-break: keep-all;
      color: #696969;

      @media screen and(max-width:500px) {
        font-size: 0.85rem;
        // flex-wrap: wrap;
      }
    }
  }

  .boardDetail__info {
    position: relative;
    border-bottom: 1px solid $theme-gray-3;
    display: flex;
    padding: 15px 20px;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;

    .consultingState_box {
      position: absolute;
      right: 10px;
      width: 100%;
      max-width: 84px;
      top: 50%;
      transform: translateY(-50%);

      .consultingState {
        font-size: 1rem;
        font-weight: 700;
      }
    }

    @media screen and (max-width: 1220px) {
      padding: 15px 10px;
    }

    span {
      padding: 0 1em;
      line-height: 0.85em;
      color: $theme-gray-5;
      border-right: 1px solid $theme-gray-6;
      font-size: 0.85rem;

      &:first-child {
        padding-left: 0;
      }

      &:last-child {
        border-right: 0;
        padding-right: 0;
      }
    }
  }

  .con_detail {
    padding: unset !important;

    margin-bott .con_button {
      padding-top: 16px;
      text-align: center;
      border: 1px solid gray;
      height: 70px;
      background: gainsboro;

      button {
        border-radius: 5px;
        height: 35px;
        width: 160px;
      }
    }
  }

  .boardDetail__content {
    padding: 20px;
    padding-bottom: 60px;


    .videoLink{
      iframe{
        width:100%;
        height: 460px;
      }
    }
    @media screen and (max-width: 1220px) {
      padding: 15px 10px;

      .videoLink{
        iframe{
          width:100%;
          height: 400px;
        }
      }
    }

    @media screen and (max-width: 600px) {
      padding: 15px 10px;

      .videoLink{
        iframe{
          width:100%;
          height: 250px;
        }
      }
    }
  }

  .board__answer {
    margin-top: 15px;
    background: #fafafb;

    .answer__header {
      padding: 20px;
    }

    .answer__body {
      padding: 20px;
      font-weight: 400;

      .ql-toolbar.ql-snow {
        display: none;
      }

      .ql-container.ql-snow {
        border: none;
      }

    }
  }

  .attachFiles {
    border-bottom: 1px solid $theme-gray-3;
    border-top: 1px solid $theme-gray-3;
    display: flex;
    padding: 2px 20px;

    @media screen and (max-width: 1220px) {
      padding: 3px 0;
      padding-left: 10px;
    }

    .hd {
      font-size: 1rem;
      line-height: 35px;
      width: 80px;
      text-align: center;
      color: #333;
      border-radius: 25px;

      h1 {
        padding: 10px 0px;
        font-size: 0.85rem;
        text-align: left;
      }
    }

    .download {
      // line-height: 40px;
      // padding: 0 10px;
      // border-radius: 5px;
    }

    .bd {
      line-height: 1em;
      width: calc(100% - 80px);
      padding-left: 1em;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      display: flex;
      align-items: center;


      .bd_ul {
        padding: 0;

        li {
          &:first-child {
            margin-top: 0;
          }

          margin-top:10px
        }
      }
    }
  }


  .boardDetail__list {
    display: flex;
    align-items: center;
    text-align: center;
    padding: 10px 0;
    justify-content: flex-end;
    position: relative;

    .consultingState_change {
      position: absolute;
      left: 18px;
      border: 1px solid #ddd;
      padding: 5px 10px;
      border-radius: 5px;
      cursor: pointer;

      @media screen and (max-width: 1220px) {
        left: 10px;
      }

      span {
        font-size: 0.85rem;
      }
    }

    a {
      color: #949494;
      background: #fff;
      padding: 6px 40px;
      border-radius: 3px;
      border: 1px solid #ddd;
      border-radius: 5px;
      font-size: 0.85rem;

      @media screen and (max-width: 1220px) {
        padding: 5px 20px;
      }
    }

    .delete_btn {
      background-color: #F46E43;
      color: #fff;
      padding: 5px 20px;
      margin-right: 10px;
      border-radius: 3px;
      font-weight: 400;
      font-size: 0.85rem;

      &.updates {
        background-color: #1a9952;
      }
    }


    .survey_del_btn {
      padding: 6px 40px;
      margin-right: 10px;
      font-family: 'Pretendard';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      /* identical to box height */


      color: #FFFFFF;
      background: #F46E43;
      border-radius: 4px;


    }

    .survey_update_btn {
      padding: 6px 40px;
      margin-right: 10px;
      font-family: 'Pretendard';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      /* identical to box height */


      color: #FFFFFF;
      background: #00863C;
      border-radius: 4px;


    }
  }

  .boardDetail__pager {
    border-top: 1px solid$theme-gray-4;


    &.detailBottom {
      display: flex;
    }

    .lefts {
      width: calc(100% - 100px);
      padding-bottom: 10px;

      a {
        padding: 10px 20px;
        padding-bottom: 0;

        @media screen and (max-width: 768px) {
          padding-left: 0;
        }

        &~a {
          padding-top: 7px;
        }

      }

    }

    .rights {
      width: 100px;
      padding-right: 10px;
      padding-left: 10px;
      padding-top: 10px;
      display: flex;
      align-items: start;
      justify-content: center;

      .backList {
        color: #949494;
        border: 1px solid #ddd;
        padding: 4px 20px;
        border-radius: 5px;
        font-size: 0.9rem;
        text-align: center;
        display: block;
      }
    }


    a {
      padding: 10px 20px;
      display: flex;
      align-items: center;
      width: 100%;

      &~a {
        padding-top: 0;
      }

      &.prevs {

        // span{
        //   background-color: #B3B3B3;
        // }
      }

      @media screen and (max-width: 1220px) {
        padding: 10px;
      }

    }

    span {
      font-size: 0.9rem;
      line-height: 33px;
      width: 80px;
      text-align: center;
      background: $black;
      color: $white;
      border-radius: 3px;

      @media screen and (max-width: 1220px) {
        font-size: 0.8rem;
      }

      @media screen and (max-width: 768px) {
        line-height: 23px;
      }
    }

    p {
      line-height: 1em;
      width: calc(100% - 80px);
      padding-left: 1em;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      color: #767676 !important;
    }
  }
}

.answer_tr {
  background-color: #ececec;

  .제목 {
    background: url('#{$image-path}/answer_icon.png') no-repeat;
    background-size: 10px 10px;
    background-position-y: center;
  }
}

.standby_button {
  padding: 3px;
  margin: 0 auto;
  width: 100%;
  max-width: 80px;
  background-color: #1a9952;
  color: #fff;
  font-weight: 500;
  text-align: center;
  border-radius: 100px;
  font-size: 0.8rem !important;

  @media screen and (max-width: 1024px) {
    margin: 0;
  }
}


.ing_button {
  padding: 3px;
  margin: 0 auto;
  width: 100%;
  max-width: 80px;
  background-color: #F46E43;
  color: #fff;
  font-weight: 500;
  text-align: center;
  border-radius: 100px;
  font-size: 0.8rem !important;

  @media screen and (max-width: 1024px) {
    margin: 0;
  }
}

.my_survey_button,
.my_certi_button {
  padding: 9px;
  margin-bottom: 4px;
  width: 100%;
  max-width: 80px;
  background-color: #489659;
  color: #fff;
  font-weight: 700;
  text-align: center;
  border-radius: 100px;
  font-size: 0.9rem;

  &.disabled {
    background-color: #b1b1b1;
  }

  @media screen and (max-width: 1024px) {
    margin: 0;
  }
}

.finish_button {
  padding: 3px;
  margin: 0 auto;
  width: 100%;
  max-width: 80px;
  background-color: #eee;
  color: #fff;
  font-weight: 500;
  text-align: center;
  border-radius: 100px;
  font-size: 0.8rem !important;
  color: #333333;

  @media screen and (max-width: 1024px) {
    margin: 0;
  }
}

.consulting_info {
  padding-top: 30px;
  width: 100%;
  padding-left: 10px;
  padding-right: 10px;

  @media screen and (max-width: 1024px) {
    padding-top: 25px;
  }

  .consulting_info_title {
    font-size: 1.05rem;
    font-weight: 700;
    position: relative;
    padding-left: 17px;

    &::before {
      content: "";
      position: absolute;
      border: 3px solid #1a9952;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      width: 12px;
      height: 12px;
      border-radius: 100px;
    }
  }

  .consulting_info_box {
    margin-top: 18px;
    margin-bottom: 50px;
    padding: 30px;
    border-radius: 10px;
    line-height: 1.7rem;
    font-size: 1rem;
    color: #696969;
    background: #F6F6F6;

    .dots {
      margin-right: 7px;
    }

    .greenMark {
      color: #1a9952;
      font-weight: 700;
    }

    @media screen and (max-width: 1024px) {
      padding: 15px;
      margin-bottom: 30px;
    }
  }
}

.consulting_board_box {
  margin-top: 0;

  @media screen and (max-width: 1024px) {
    margin-top: 20px;
  }

  .board {
    margin-top: 0;
  }

  .delete_pop {
    position: fixed;
    z-index: 10000;
    padding: 60px 30px;
    width: 90%;
    max-width: 1000px;
    height: 600px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    border: 2px solid #000;

    .delete_close {
      margin: 0 auto;
      border: 1px solid #000;
      padding: 10px;
      width: 100%;
      max-width: 160px;
      margin-top: 40px;
      text-align: center;
      border-radius: 5px;
      cursor: pointer;
    }

  }

  .display_none {
    display: none;
  }
}

.consulting_button_box {
  margin-top: 20px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  @media screen and (max-width: 1024px) {
    margin-top: 20px;
  }


  &:after {
    display: block;
    visibility: hidden;
    clear: both;
    content: '';
  }

  .application_form_button {
    float: left;
    width: 100%;
    max-width: 190px;
    padding: 14px;
    background: #6d6d6d;
    color: #fff;
    cursor: pointer;
    text-align: center;
    font-weight: 700;
    border-radius: 3px;

    @media screen and (max-width: 1024px) {
      max-width: 117px;
    }

    @media screen and (max-width: 500px) {
      padding: 9px;
    }

    a {
      color: #fff;
    }
  }

  .application_button {
    margin-left: 10px;
    width: 100%;
    max-width: 130px;
    padding: 8px;
    background: #1a9952;
    color: #fff;
    cursor: pointer;
    text-align: center;
    font-weight: 700;
    border-radius: 3px;
    font-size: 0.9rem;

    &~.application_cancel {
      margin-left: 10px;
    }

    @media screen and (max-width: 1024px) {
      max-width: 99px;
    }

    @media screen and (max-width: 500px) {
      padding: 9px;
    }
  }

  .application_cancel {
    margin-left: 10px;
    width: 100%;
    max-width: 130px;
    padding: 8px;
    background: #F46E43;
    color: #fff;
    cursor: pointer;
    text-align: center;
    font-weight: 700;
    border-radius: 3px;
    font-size: 0.9rem;

    @media screen and (max-width: 1024px) {
      max-width: 117px;
    }

    @media screen and (max-width: 500px) {
      padding: 9px;
    }
  }

}

.stateform_wrap {
  position: absolute;
  width: 80%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  .state_title {
    padding: 50px 0 28px 0;
    font-size: 1.2rem;
    font-weight: 700;
    text-align: center;
  }

  .state_select {
    width: 100%;
    padding: 13px;
    border: 1px solid #0087f5;
    border-radius: 5px;
    margin-bottom: 27px;
  }

}

button.submit_container_btn {
  background: #489659;
  color: white;
  height: 40px;
  border-radius: 3px;
  width: 160px;
}


.board {

  .userNameBlock {
    padding: 14px 14px 14px 16px;
    border: 1px solid #ddd;
    border-radius: 5px;

    &:after {
      display: block;
      visibility: hidden;
      clear: both;
      content: '';
    }

    .userNameBox {
      padding-top: 11px;
      float: left;

      .nameBar {
        display: inline-block;
        margin-right: 8px;
        width: 3px;
        height: 16px;
        transform: translateY(3px);
        background-color: #1d9cd4;
        border-radius: 100px;
      }

      .userName {
        font-weight: 700;
        color: #489659;
      }
    }

    .goEval {
      float: right;

      a {
        display: block;
        padding: 12px 18px;
        color: #fff;
        border-radius: 5px;
        width: 100%;
        height: 100%;
        background: #489659;
      }
    }
  }

}

.evalBox {
  margin-top: 40px;
}

.listButtonArea {
  margin-top: 50px;
  display: flex;
  justify-content: center;

  .pdf_down {
    margin-left: 10px;
    display: flex;
    padding: 10px 20px;
    background-color: #489659;
    color: white;
  }

  .listButton {
    display: inline-block;
    padding: 10px 20px;
    background-color: #e72e35;

    a {

      color: #fff;
    }


  }
}

.page-onair-eventstory {
  .testtest {
    font-size: 100px;
  }

  .thumb {
    display: none;
  }
}

.popoverItem {
  padding: 15px 20px;
  border-radius: 10px;
  color: #fff;
  position: relative;
  z-index: 1000;

  &:after {
    position: absolute;
    left: calc(50% - 6px);
    top: -16px;
    content: '';
    display: block;
    width: 0px;
    height: 0px;
    border-bottom: 8px solid #f00;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 8px solid transparent;

    @media screen and(max-width:768px) {
      left: calc(15% - 6px);
    }
  }

  &.seoul {
    // background:#5796d1;
    background: #00863E;

    &:after {
      border-bottom-color: #00863E;
    }
  }

  &.daejeon {
    background: #e46161;

    &:after {
      border-bottom-color: #e46161;
    }
  }

  &.online {
    background: #fac357;

    &:after {
      border-bottom-color: #fac357;
    }
  }

  h3 {
    margin-bottom: 10px;
    font-weight: 400;
    color: #fff;

    a {
      margin-bottom: 10px;
      font-weight: 400;
      color: #fff;
    }
  }

  p {
    line-height: 1.5em;
    font-weight: 300;
    color: #fff;

    span {
      font-size: 14px;
      display: block;
      font-weight: 300;
      color: #fff;
    }
  }

  .statebox {
    display: flex;
    margin-top: 1em;
    justify-content: flex-end;

    span {
      background: #fff;
      color: #333;
      font-weight: 300;
      padding: 5px 10px;
      border-radius: 2em;
      font-size: 12px;

    }
  }
}

.popoverClose {
  float: right;
  font-weight: 300;
  margin-left: 15px;
  color: #fff;
}







.bo_list {
  display: grid;
  grid-template-columns: 32% 32% 32%;
  grid-row-gap: 28px;
  justify-content: center;
  margin-top: 15px;
  gap: 15px;
  position: relative;

  div {
    &.first_div {
      width: 100%;
    }
  }

  .position_center {
    position: absolute;
    top: 10%;
    left: 50%;
    width: 30%;
    transform: translate(-50%, 0);
  }


  @media (max-width: 1024px) {
    grid-template-columns: 49% 49%;
    grid-row-gap: 20px;
  }

  @media (max-width: 768px) {
    padding: 0 10px;
  }


  @media (max-width: 550px) {
    grid-template-columns: 100%;
  }

  div {
    width: 100%;

    @media (max-width: 1024px) {
      margin: 0;
    }

    // @media (max-width: 767px) {
    //   margin: 0 0 20px 4%;
    //   width: 100%;

    //   &:nth-child(3n+1) {
    //     margin-left: 4%;
    //   }

    //   &:nth-child(2n+1) {
    //     margin-left: 0;
    //   }
    // }

    // @media (max-width: 425px) {
    //   margin: 0 0 20px;
    //   width: 100%;

    //   &:nth-child(3n+1) {
    //     margin-left: 0;
    //   }
    // }

    &.img_container {
      text-align: center;
      background-color: #eaeae9;
      overflow: hidden;

      img {
        object-fit: cover;
        width: 100%;
      }
    }

    &.first_div {
      width: 100%;

      // @media (max-width: 767px) {
      //   width: 50%;
      //   padding: 2%;
      // }

      // @media (max-width: 1024px) {
      //   width: 47%;
      // }
    }




    &:nth-child(3n+1) {
      margin-left: 0;
    }





    a {
      display: block;

      &:hover {
        h5 {
          text-decoration: underline;
        }
      }
    }

    div {
      height: 198px;

      @media screen and (max-width:768px) {
        height: 280px;
      }

      img {
        width: 100%;
        height: 100%;

      }

      @media (max-width: 425px) {
        height: 250px;
      }


    }

    h5 {
      margin: 20px 0 0;
      height: 44px;
      line-height: 22px;
      font-size: 15px;
      color: #333;
      font-weight: 500;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;

      @media (max-width: 550px) {
        height: auto;
        margin-top: 10PX;
      }

    }

    ul {
      display: flex;
      align-items: center;
      margin: 7px 0 0;

      @media (max-width: 550px) {
        margin-top: 3px;
      }


      li {
        position: relative;
        margin: 0 0 0 8px;
        padding: 0 0 0 8px;
        line-height: 18px;
        font-size: 12px;
        color: #696969;
        font-weight: 300;

        &:before {
          content: "";
          position: absolute;
          top: 50%;
          left: 0;
          width: 1px;
          height: 10px;
          background: #696969;
          transform: translateY(-50%);
        }

        &:first-child {
          margin: 0;
          padding: 0;

          &:before {
            width: 0;
            height: 0;
          }
        }
      }
    }
  }
}

.page-community-survey {
  .submit_container {
    button {
      background: #489659;
      color: white;
      height: 40px;
      border-radius: 3px;
      width: 120px;
      padding: 0px 10px;
    }
  }
}

.page-community-survey,
.page-resource-evaluate {

  .content {
    .sub__content {
      .sub__title {
        margin-bottom: 0;
      }

      .board {
        margin-top: 0;

        .board__list {
          margin-top: 0;
        }
      }
    }
  }

  .survey_button_container {
    text-align: center;

    .survey_button {
      padding: 10px 20px;
      margin-top: 0;
      cursor: pointer;
      background: #00863C;
      border-radius: 4px;
      color: #fff;
      font-weight: 500;
      margin-right: 5px;
    }

    .survey_result_button {
      padding: 10px 20px;
      margin-top: 0;
      cursor: pointer;
      background: #3E3E3E;
      border-radius: 4px;
      color: #fff;
      font-weight: 500;
    }

    .survey_button_disabled {
      border-radius: 100px;
      padding: 10px 20px;
      margin-top: 0;
      background-color: #666666;
      color: #fff;
      font-weight: 700;
      cursor: default;

    }

  }

}


.input_wrapper_copy_url {
  display: flex;
  position: relative;
  margin-right: 10px;

  >input {
    border: 1px solid #ddd;
    border-radius: 3px;
    width: auto;
    border-radius: 4px;
    padding-left: 5px;
    height: 35px;
    width: 280px;
    background-color: #f4f4f4;
    border: 1px solid #DDDDDD;
    border-radius: 4px;
    color: #767676;
  }

  >button {
    position: absolute;
    right: 0;
    margin: 0;
    /* border-radius: 0 13px 13px 13px; */
    background-color: #00863c;
    /* padding: 15px; */
    height: 35px;
    border-radius: 0 4px 4px 0;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px 20px;
  }
}


.deny_box {
  background: linear-gradient(90.46deg, #BD2F44 9.38%, #B32137 100%);
  border-radius: 4px;
  padding: 20px 0;
  color: white;
  font-family: 'Pretendard';
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  color: #FFFFFF;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1%;
}