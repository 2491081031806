._modal {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 5000;
  background: rgba(0, 0, 0, 0.25);
  display: flex;
  align-items: center;
  justify-content: center;

  .attendance_date {
    display: inline-block;
    margin-left: 20px;
  }

  .schedule__tabs {
    margin: 0;
    background: #fff;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;

    &+._modal__block {
      border-top-left-radius: 0;
      border-top-right-radius: 0;

      .red {
        color: red;
      }

      .learn_submit {
        ._learn__actions {
          text-align: right;
          margin-top: 20px;
          padding-bottom: 20px;

          .submit {
            background: #2e8bc5;
            color: #fff;
            width: 75px;
            flex: none;
            height: 35px;
            font-size: 14px;
            border-radius: 5px;
          }
        }
      }
    }
  }
}

.swal-text {
  text-align: center
}

._modal__description {
  font-size: 16px;
  // @include theme('color', 'default-color');
  line-height: 1.5em;
  padding-bottom: 1.5em;
}

._modal__content {
  width: 100%;
  max-width: 450px;
  border-radius: 10px;
  overflow: auto;
  max-height: 100vh;

  /* 스크롤바 설정*/
  &::-webkit-scrollbar {
    width: 0;
  }

  /* 스크롤바 막대 설정*/
  &::-webkit-scrollbar-thumb {
    background-color: #d2e0f2;
    /* 스크롤바 둥글게 설정    */
    border-radius: 100px;
  }

  /* 스크롤바 뒷 배경 설정*/
  &::-webkit-scrollbar-track {
    background-color: none;
  }

  // @include theme('background', 'background');
}

._modal__header {
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #eee;
  padding: 0 20px;

  h2 {
    font-weight: 600;
    flex: 1;
  }

  button {
    flex: none;
    width: 16px;
    height: 16px;

    svg {
      vertical-align: super;
    }
  }
}

._modal__body {
  padding: 20px;

  // @include theme('background', 'background');
  &._modal__body--bg-none {
    background: #eee;
    padding: 0 20px 20px;

    .modal_content_body {
      white-space: pre-line;
      padding: 40px;
    }
  }

  .video_grade {
    padding: 0 !important;
  }
}

._modal__row {
  display: flex;
  align-items: center;

  &.mt-10 {
    margin-top: 10px;
  }

  label {
    font-weight: 600;
    font-size: 14px;
    width: 80px;
    flex: none;
    // @include theme('color', 'default-color');
  }

  input[type="file"] {
    width: 100%;
  }

  .select {
    flex: 1;

    select {
      width: 100%;
      height: 40px;
      border-radius: 5px;
      border: 1px solid #ddd;
      padding: 0 10px;
      // @include theme('border-color', 'border');
      background: #fff url('#{$image-path}/small-arrow-bottom.png') no-repeat calc(100% - 10px) center !important;
    }
  }


  .input {
    flex: 1;

    input {
      width: 100%;
      background: #fff;
      height: 40px;
      border-radius: 5px;
      border: 1px solid #ddd;
      // @include theme('border-color', 'border');
    }

    textarea {
      width: 100%;
      background: #fff;
      height: 120px;
      border-radius: 5px;
      border: 1px solid #ddd;
      // @include theme('border-color', 'border');
    }
  }
}


._modal__actions {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;

  .text-danger {
    &.mr-30 {
      margin-right: 30px;
    }
  }

  .submitDiv {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 35px !important;
    border-radius: 5px;
    background: #2e8bc5;
    color: #fff;
    cursor: pointer;
    width: 75px;
    margin-right: 10px;
    font-size: 14px;
  }

  button {
    flex: 1;
    height: 35px;
    background: #eee;
    border-radius: 5px;

    &.submit {
      background: #2e8bc5;
      color: #fff;
    }

    &~button {
      margin-left: 10px;
    }
  }
}

._modal__footer {
  padding: 20px;
  border-top: 1px solid #ddd;

  ._modal__actions {
    justify-content: flex-end;
    margin: 0;
  }

  button {
    width: 75px;
    flex: none;
    height: 35px;
    font-size: 14px;
  }
}

._modal__foot {
  background: #ddd;
  padding: 20px;

  ._modal__actions {
    justify-content: flex-end;
    margin: 0;
  }

  button {
    width: 75px;
    flex: none;
    height: 35px;
    font-size: 14px;
  }
}

.live_video {
  text-align: center;

  .two_live_block {
    padding: 10px 20px;

    .a_btn {
      background: #2e8bc5;
      color: #fff;
      padding: 8px;
      border-radius: 5px;
    }
  }
}

.live {
  ._modal__block {
    border: 1px solid;
    border-radius: 0px;

    @media screen and (max-width: 768px) {
      padding: 30px 20px;
    }

    .info {
      button {
        margin-top: 15px;
        padding: 8px 10px;
        border-radius: 20px;
        background-color: #000000b5;
        color: white;
        font-size: 0.9rem;
        cursor: pointer;
      }

      p {
        margin: 7px;
      }
    }


  }

  .live_container {
    padding: 20px 0;

    .info {
      display: inline-block;
    }

    .lmbtn {
      border-radius: 2em;
      border: 1px solid #ddd;
      font-size: 15px;
      background: #fff;
      line-height: 20px;
      padding: 5px 10px;
      float: right;

      @media screen and (max-width: 768px) {
        margin-top: 10px;
      }
    }
  }
}

.learning_material {
  ._modal__block {
    border: 1px solid;
    // padding: 20px !important;
    border-radius: 0px;
  }
}

.assignment {
  ._modal__block {
    border: 1px solid;
    border-radius: 0px;
  }
}

.etc_material {
  ._modal__block {
    border: 1px solid;
    border-radius: 0px;
  }
}

._modal__block {
  .learningall {
    margin-left: 15px;
  }

  padding: 0 20px;
  background:#fff;
  border-radius: 5px;

  &~._modal__block {

    // margin-top: 20px;
    &.none {
      display: none;
    }
  }

  .help-text {
    margin-top: 8px;
    font-size: 14px;
    color: #999;
    line-height: 1.25em;
    padding-left: 1.125em;
    background: url('#{$image-path}/icon-help.png') no-repeat left 5px !important;

    &~.help-text {
      margin-top: 8px;
    }
  }

  .help-red {
    font-size: 14px;
    color: #bd2130;
    line-height: 1.25em;
    //padding-left: 1.125em;
    width: calc(70%);
    word-break: keep-all;
    margin-top: 8px;
  }


  //09.14새롭게추가
  .grade {
    border: 1px solid #007eff;
    margin-top: 26px;
    padding: 14px;
    border-radius: 5px;
    color: #007eff;
    background: #f2f8ff;

  }

  .upload_time {
    margin-top: 10px;
  }


  &._modal__block--has-header {
    padding: 0 20px;
  }

  ._modal__block-body {
    padding: 20px 0;

    .operationInfo {
      margin-left: 155px;
      margin-top: 20px;
      color: #2e8bc5;
      font-weight: 700;
    }

    ._modal__block-body {
      &:first-child {
        padding: 0;
        padding-bottom: 20px;
      }

      &:last-child {
        padding: 20px 0;
      }

      .insertForm__name {
        width: auto;
        white-space: nowrap;
        padding-right: 25px;
      }
    }

    &~._modal__block-body {
      border-top: 1px solid #ddd;

      .row:nth-child(2) {
        margin-top: 25px;
      }

      &.test_form {
        padding: 0;

        .bg-none:hover {
          background: #666666;
          color: #fff;
        }

        .row {
          border: 1px solid #ddd;
          border-radius: 40px;
          margin: 30px 0;
          padding: 20px;

          .insertForm__name {
            .type {
              color: #666;
            }

            .required {
              font-weight: 600;
            }
          }

          .insertForm__content {



            .toggle {
              color: #666;
              line-height: 28px;
              padding: 0 10px 0 15px;
              font-size: 14px;
              background: #eee;
              border-radius: 2em;
              position: relative;
              left: 89%;
            }

            .quiz_title {
              margin-bottom: 5px;
              font-weight: normal;
            }

            .quiz_description {
              margin-bottom: 5px;
            }

            .quiz_score {
              margin: 15px;
            }

            .test_open {
              border: none;
            }
          }
        }

        .row:first-child {
          border: none
        }
      }
    }

    .a_div {
      padding-top: 8px;

      .a_btn {
        background: #2e8bc5;
        color: #fff;
        padding: 8px;
        border-radius: 5px;
      }
    }

    .custom-checkbox {
      &~.custom-checkbox {
        margin-top: 15px;
      }
    }
  }

  ._modal__block-header {
    display: flex;
    align-items: center;
    height: 55px;
    border-bottom: 1px solid #ddd;

    h2 {
      font-size: 1.125rem;
      color: #000;

      &.i-quiz {
        background: url('#{$image-path}/title-icon-quiz.png') no-repeat left center;
        padding-left: 1.75em;
      }

      &.i-debate {
        background: url('#{$image-path}/i-debate.png') no-repeat left center;
        padding-left: 1.75em;
      }

      &.i-survey {
        background: url('#{$image-path}/i-survey.png') no-repeat left center;
        padding-left: 1.75em;
      }

      &.i-homework {
        background: url('#{$image-path}/title-icon-homework.png') no-repeat left center;
        padding-left: 1.75em;
      }

      &.i-exam {
        background: url('#{$image-path}/i-exam.png') no-repeat left center;
        padding-left: 1.75em;
      }

      &.i-doc2 {
        background: url('#{$image-path}/i-doc2.png') no-repeat left center;
        padding-left: 1.75em;
      }

      &.i-eval1 {
        background: url('#{$image-path}/i-eval1.png') no-repeat left center;
        padding-left: 1.75em;
      }

      &.i-eval2 {
        background: url('#{$image-path}/i-eval2.png') no-repeat left center;
        padding-left: 1.75em;
      }

      &.i-eval3 {
        background: url('#{$image-path}/i-eval3.png') no-repeat left center;
        padding-left: 1.75em;
      }

      &.i-eval-m1 {
        background: url('#{$image-path}/i-eval-m1.png') no-repeat left center;
        padding-left: 1.75em;
      }

      &.i-eval-m2 {
        background: url('#{$image-path}/i-eval-m2.png') no-repeat left center;
        padding-left: 1.75em;
      }
    }

    .tatal_assigment {
      cursor: pointer;
      background: #fff;
      color: #666;
      border-color: #666;
      height: 40px;
      border-radius: 3px;
      padding: 0 13px;
      /* padding-left: 10px; */
      margin: 10px;
    }

    .custom-checkbox {
      margin-right: 30px;
    }

  }

  ._dataTable.assigment,
  ._dataTable.discussion {
    padding-bottom: 40px;
  }



  .oxflextwo {
    margin-top: 0px !important;
    //margin-right: 55px;
  }

  .oxflex {
    margin-right: 55px;
  }

  .flex {
    display: flex;
    height: 40px;
    align-items: center;

    &~.flex {
      margin-top: 10px;
    }

    &.align-top {
      height: auto;
      align-items: flex-start;

      .editor {
        flex: 1;
      }
    }

    .custom-checkbox {
      margin-top: 0 !important;
      min-width: 85px;

      &~.custom-checkbox {
        margin-left: 20px;
      }
    }

    .quiz-true,
    .quiz-false {
      width: 120px;
      flex: none;
      padding-left: 1.25em;
    }

    .quiz-true {
      color: #2e8bc5;
      background: url('#{$image-path}/quiz-true.png') no-repeat left center !important;
    }

    .quiz-false {
      color: #ff2626;
      background: url('#{$image-path}/quiz-false.png') no-repeat left center !important;
    }

  }

  .class {
    .timeform {
      margin-left: 0px;
    }
  }

  .window-cofing {
    h4 {
      width: 135px;
    }
  }

  .timeform {
    margin-left: 0px;

    &.mr-0 {
      margin-left: 0;
    }

    h4 {
      margin-right: 10px;
    }
  }

  .select2 {
    margin-left: 20px;
  }

  .dropdownselect {
    select {
      width: 100% !important;
    }
  }

  .select {
    margin-right: 10px;
    flex: 1;

    &.long {
      select {
        width: 235px !important;
      }
    }

    select {
      min-width: 100px;
      border: 1px solid #ddd;
      border-radius: 5px;
      height: 40px;
      padding: 0 15px;
      width: auto;
      font-size: 14px;
      color: #666666;
      background: #fff url('#{$image-path}/nav-toggle-down.png') no-repeat calc(100% - 15px) center;
    }
  }

  .input {
    margin-right: 10px;
    flex: 1;

    input {
      width: 100%;
      border: 1px solid #ddd;
      border-radius: 5px;
      height: 50px;
      padding: 0 15px;
      font-size: 14px;
      color: #666666;
    }

    &.diflex {
      display: flex;
      align-items: center;


      .rightInfo {
        margin-left: 7px;
      }

    }
  }


  ._btn {
    display: block;
    height: 50px;
    background: #666;
    border-radius: 5px;
    color: #fff;
    font-size: 14px;
    padding: 0 15px;
    flex: none;
    white-space: nowrap;

    &.small {
      height: 40px;
    }

    &.bg-none {
      background: none;
      color: #666;
      border: 1px solid #999;
      line-height: 38px;
      margin-right: 10px;
    }

    &._btn--uploader {
      background: none;
      color: #666;
      border: 1px solid #999;
      line-height: 38px;
      margin-left: 10px;
    }

    &._btn--youtube {
      background: #f00;
      color: #fff;
      border: 1px solid #f00;
      line-height: 38px;
      margin-left: 10px;
    }

    &._btn--img {
      padding-left: 40px;
      background: #666 url('#{$image-path}/icon-upload-img.png') no-repeat 14px center !important;
    }
  }
}


.insertForm {

  .insertForm {
    margin-left: 0px;
  }

  .assign_submit {
    display: flex;

    @media screen and (max-width: 768px) {
      display: block;

      strong {
        width: 35%;
      }
    }
  }

  .feedbackrow {
    padding-top: 20px;
  }

  .row {
    margin: 0;

    &~.row {
      margin-top: 10px;
    }
  }

  .filelist_container {
    margin-top: 15px;

    p {
      display: inline-block;
      min-width: 120px;
    }

    button {
      display: inline-block;
      background: #666;
      color: white;
      border-radius: 5px;
      width: 30px;
      height: 30px;
      border: solid 1px #999;
    }
  }

  .insertForm__name {
    @media screen and (max-width: 768px) {
      width: 20%;
    }

    width: 155px;
    flex: none;
    line-height: 20px;
    padding: 10px 0;

    &.required {
      &:after {
        content: '*';
        color: #00863C;
      }
    }
  }

  .oxquiz {
    margin-left: 29px;
    display: inline-flex;
  }

  .insertForm__content {
    display: flex;
    flex-direction: column;
    justify-content: center;

    //align-items: center;
    .quiz-remove {
      float: right;
    }

    .editor2 {
      width: 60rem;
      margin-left: 45px;
      margin-top: 15px;
    }

    .top_flex {
      display: inline-flex;
    }

    flex: 1;

    .help-text-block {
      margin-top: 10px;
    }

    .file-btn {
      cursor: pointer;

      input {
        display: none;

        &.file_name {
          @media screen and (max-width: 768px) {
            width: 55%;
          }

          display: inline-block;
          height: 40px;
          border: 1px solid #ddd;
          cursor: default;
        }
      }

      ._btn {
        @media screen and (max-width: 768px) {
          width: 45%;
        }

        display: inline-block;
        border: 1px solid #999;
        padding-top: 10px;
        padding-left: 40px;
        background: #666 url('#{$image-path}/icon-down.png') no-repeat 14px center !important;

        &._btn--nextcloud {
          border: 1px solid #0082c9;
          background: #0082c9 url('#{$image-path}/icon-down.png') no-repeat 14px center !important;
        }
      }
    }

    .input {
      width: 250px;
      flex: none;

      &.full {
        width: 100%;
      }

      &.short {
        width: 100px;
      }

      // 220607 시험 서술형 추가
      &.and {
        display: flex;

        input {
          margin: 0 10px;
        }

        .flex2 {
          display: flex;
          margin-right: 25px;
          min-width: fit-content;
        }
      }

      input {
        height: 40px;
      }
    }

    // 220607 시험 서술형 추가
    .word_limit_info {
      padding-top: 10px;

      p {
        font-size: 0.85rem;
      }
    }

    h2 {}

    .piece {
      margin-bottom: 20px;

      .piece_title {
        display: inline-block;
        width: 100px;
      }

      .input {
        display: inline-block;
      }
    }

    // 220609 시험관련 추가
    .rubric_tb {
      width: 100%;
      border: 1px solid #ccc;
      text-align: center;

      thead {
        background: #e0e0e0;
      }

      th,
      td {
        border: 1px solid #ccc;
        padding: 5px;
        font-size: 0.85rem;

        div {
          position: relative;

          button {
            position: absolute;
            right: 255px;
            width: 25px;
            height: 25px;
            border: 1px solid #ddd;
            border-radius: 50%;
            background: #ff5151;
            color: #fff;
            font-weight: bold;
          }
        }
      }
    }
  }

  .insertForm--inner {
    .custom-checkbox {
      margin-top: 0 !important;
    }

    ._modal__block-body {
      padding: 20px 0 !important;
    }

    .insertForm__name {
      width: 155px !important;
    }
  }
}

.learning-state {
  padding: 20px 0;

  p {
    font-size: 1rem;

    &~p {
      margin-top: 5px;
    }
  }

  .green {
    color: #6ea24d;
  }
}

.learning-state-search {
  border-top: 1px solid #ddd;
  padding: 20px 0;
  display: flex;
  align-items: center;

  .select {
    width: 115px;
    flex: none;
    margin-right: 10px;

    select {
      width: 100%;
    }
  }

  .input {
    max-width: 320px;

    input {
      height: 40px;
    }
  }

  button {
    width: 90px;
    height: 40px;
    border-radius: 5px;
    color: #666;
    border: 1px solid #666;
  }
}

._modal__block--evaluation {
  background: #e5eaf0;

}

.evaluation-options {
  margin-top: 20px;

  .evaluation-item {
    background: #fff;
    height: 75px;
    display: flex;
    align-items: center;
    padding: 0 30px;
    border-radius: 5px;

    &~.evaluation-item {
      margin-top: 10px;
    }

    strong {
      width: 210px;
      flex: none;
    }

    .btn-underline {
      color: #2f8bc5;
      border-bottom: 1px solid #2f8bc5;
    }
  }
}

.evaluation-item-form {
  display: flex;
  align-items: center;

  .select {
    width: 150px;
    flex: none;

    select {
      width: 100%;
    }
  }

  button {
    height: 40px;
    width: 75px;
    background: #2e8bc5;
    color: #fff;
    border-radius: 5px;
  }
}

.evaluation-feedback {
  .row {
    display: flex;
  }

  .insertForm__name {
    width: 110px;
    flex: none;
  }

  .insertForm__content {
    flex: 1;
  }
}

.evaluation-score {
  display: flex;
  align-items: center;

  .input {
    width: 100px;
    flex: none;
    margin-left: 10px;
  }

  input {
    height: 40px !important;

  }
}

.evaluation-info {

  h2 {
    color: #2e8bc5;
    font-size: 1.125rem;

    svg {
      margin-top: -3px;
      margin-right: 5px;
    }
  }

  p {
    margin-top: 5px;
    color: #000;

    span {
      color: #6ea24d;
    }
  }

  .block {
    &~.block {
      margin-top: 10px;
      padding-top: 10px;
      border-top: 1px solid #ddd;
    }
  }

  ._flex {
    display: flex;
    align-items: center;
    margin: 0 -10px;
  }

  .col-info {
    padding: 0 10px;
    font-size: 1rem;
    color: #999;

    &~.col-info {
      border-left: 1px solid #ddd;
      line-height: 10px;
    }

    strong {
      font-weight: 400;
      color: #000;
    }
  }
}

.block--codeEditor {
  &~.block--codeEditor {
    margin-top: 20px;
    padding-top: 15px;
    border-top: 1px solid #ddd;
  }

  .title {
    font-size: 1rem;
    margin-bottom: 10px;
  }

  .CodeMirror {
    border-radius: 0;
  }

  .codeEditor {
    border: none;
  }
}

.editorTab {
  display: flex;
  align-items: center;
  background: #2c3038;

  button {
    padding: 0 10px 0 32px;
    color: #fff;
    flex: 0;
    font-size: 12px;
    height: 30px;
    background: #0C1021 url('#{$image-path}/i-editor-tab.png') no-repeat 10px center !important;
  }
}

.assignment_content {
  max-width: 150px;
  text-align: center;
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
}

.popup-arrow {
  color: #292d3e;
  stroke-width: 2px;
  stroke: #464545;
  stroke-dasharray: 30px;
  stroke-dashoffset: -54px;
  filter: drop-shadow(0 -3px 3px rgba(0, 0, 0, .16));
}

.popup_tooltip {
  background-color: #292d3e;
  color: #fff;
  border-radius: 5px;
  padding: .5rem;
  font-size: 12px;
}


.smallInput {
  width: 200px !important;
}


///0804 변경 부분

.dragWrap {
  margin-left: 145px;

  &.disnone {
    display: none;
  }

  .dndWrap {
    &:after {
      display: block;
      visibility: hidden;
      clear: both;
      content: '';
    }

    width:100%;

    .sliceStu {
      background-color: #aebbd4;
      padding: 10px 10px 2px 10px;
      //float:left;
      width: 31.33333%;
      margin: 0 1% 20px 1%;
      display: inline-grid;
      border-radius: 5px;
      transition: 0.3s ease;

      .text-danger {
        color: #00eaff;
        font-weight: 700;
        display: block;
        width: 100%;
        text-align: center;
        padding-bottom: 10px;
      }



      .teamName {
        width: 100%;
        margin-bottom: 8px;
        font-weight: 700;
        color: #fff;
        text-align: center;
        padding: 10px;
        border-radius: 5px;
        background-color: #477ee2;
        transition: 0.3s ease;

      }

      .dndInBox {
        width: 100%;
        border-radius: 5px;

        &:after {
          display: block;
          visibility: hidden;
          clear: both;
          content: '';
        }

        .usersName {
          float: left;
        }

        .custom-checkbox.usersCheck {
          float: right;
          margin-top: 4px;

          input[type=radio]:checked+span {
            background: none !important;
            border: 5px solid #2e8bc5 !important;
          }

          span {
            border: 1px solid #8da2c7;
          }

        }
      }
    }
  }
}


.sliceButton {
  margin-top: 10px;
  display: flex;

  .autoDragButton {
    height: 40px;
    display: flex;
    align-items: center;
    color: #fff;
    border-radius: 5px;
    margin-left: 10px;
    background-color: #2e8bc5;
    padding: 0 15px;
  }
}

.nameleftside {
  width: 155px !important;
}


.teamListWrap {
  width: 100%;
  height: 100%;
  margin-bottom: 15px;

  .listInfo {
    text-align: right;
    margin-bottom: 13px;
    font-size: 0.85rem;

  }

  .teambuildingList {
    width: 100%;

    .btn_select {
      @media screen and (max-width: 768px) {
        left: 75%;
        margin-top: 5px;
      }

      border: 1px solid #2e8bc5;
      color: #2e8bc5;
      padding: 10px 20px;
      border-radius: 5px;
      position: relative;
      left: 88%;
    }

    .teamList {
      width: 100%;
      padding: 10px;
      border: 1px solid #bdbdbd;
      border-radius: 5px;
      margin-bottom: 15px;

      &:after {
        display: block;
        visibility: hidden;
        clear: both;
        content: ''
      }

      .teamSelect {
        float: left;
        margin: 17px 10px 16px 3px;
      }

      .teamListTitle {
        width: 120px;
        align-items: center;
        padding: 20px 0;
        border-radius: 5px;
        padding: 10px;
        text-align: center;
        background-color: #2e8bc5;
        color: #fff;
        font-weight: 700;

        .teasLeader {
          width: 100%;
          margin-top: 10px;
          padding: 5px;
          border-radius: 0 0 5px 5px;
          font-size: 0.8rem;
          background-color: #d9f0ff;
          font-weight: 400;
          color: #000;
        }

      }

      .teamListNameList {
        margin-left: 10px;
        width: calc(100% - 130px);
        align-items: center;
        padding: 20px 0;
        border: 1px solid #d5d5d5;
        padding: 10px;

        .teamListName {
          display: inline-block;
          margin-right: 8px;
        }

        &.select {
          margin-right: 0;
          width: calc(100% - 156px);
          min-height: 46px;
        }

      }


    }
  }

}

.react-pdf__Page {
  .react-pdf__Page__canvas {
    width: 100% !important;
  }
}


._modal__block {
  .formTopTities {
    margin: 30px 0;
    font-size: 1.3rem !important;
    font-weight: 700;
    text-align: center;
  }

  .masterListState {
    border: 1px solid #2e8bc5;
    border-radius: 5px;
    padding: 22px;
    padding-bottom: 12px;
    margin-bottom: 20px;
    background-color: #f6f8fb;

    .masterSubTitle {
      margin-bottom: 9px;
      font-weight: bold;
    }

    .stateWrap {
      padding: 20px;
      border: 1px solid #d7e7ff;
      margin-bottom: 10px;
      background-color: #fff;
      display: flex;
      align-items: center;

      .learningTitle {
        width: 100%;
        font-size: 1.15rem;
        font-weight: 700;
        color: #2e8bc5;
      }

      .stateBottom {
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: flex-end;

        .learningSection {
          margin-right: 5px;
        }

        .learningModuleSection {}
      }


    }

  }
}

.numbold {
  font-size: 1.05rem;
  font-weight: bold;
  color: #2e8bc5;
}

.sizeup {
  font-size: 1.35rem;
}

.formBottomTities {
  margin-top: 16px;
  margin-bottom: 20px;
  text-align: center;
  color: #ca0000;
}

._modal__block {
  .watchButtonsWrap {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;

    .watchButtons {
      padding: 10px 20px;
      border-radius: 5px;
      background-color: #888888;
      color: #fff;
      margin-left: 5px;
      font-size: 0.9rem;
      cursor: pointer;

      &.buttonsAct {
        background-color: #0057b0;
      }

      &:hover {
        background-color: #007eff;
      }
    }
  }
}

.greens {
  background-color: #0a984c;

  &:hover {
    background-color: #04582b !important;
  }
}

.reds {
  background-color: #525252;

  &:hover {
    background-color: #161313 !important;
  }
}

.separateButtons {
  padding: 4px 13px;
  background-color: #6e6d7f;
  border-radius: 5px;
  // 스타일 수정을 위해 주석처리
  // margin-left: 20px;
  margin: 0 10px;
  font-size: 0.8rem;
  color: #fff;
}



._modal__block {
  position: relative;

  .myCloudButton {
    position: absolute;
    right: 24px;
    top: 8px;
    padding: 8px 12px;
    border-radius: 5px;
    color: #fff;
    background-color: #2e8bc5;
    font-size: 0.9rem;

  }
}

._modal__actions {
  position: relative;

  .clodeTitle {
    position: absolute;
    left: 0;
    font-size: 1.1rem;
    font-weight: 700;

  }
}

.clouds {
  padding: 23px !important;

  .cloudTable {
    background-color: #fff;
    width: 100%;
    text-align: center;
    border-collapse: collapse;
    table-layout: auto !important;

    thead {
      tr {
        th {
          border: 1px solid #fff !important;
          padding: 14px 0;
          background-color: #2e8bc5;
          color: #fff;

          &:nth-child(1) {
            width: 50px;
          }
        }
      }
    }

    tbody {
      tr {
        td {
          border: 1px solid #ddd;
          padding: 7px;
          text-align: center;
          height: 50px;
          font-size: 0.85rem;
          vertical-align: middle;

          .custom-checkbox {
            justify-content: center;

            span {
              margin-right: 0;

            }
          }

        }
      }
    }
  }
}

.blues {
  color: #2e8bc5;
  font-size: 1.2rem;
}





.insertForm__content {
  .recordBox {
    width: 100%;

    .linkBoxNone {
      padding: 10px;
      font-weight: 700;
      font-size: 0.9rem;
    }

    .linkBox {
      width: 100%;
      border: 1px solid #2e8bc5;
      margin-bottom: 5px;
      border-radius: 5px;



      &:hover {
        background-color: #e1f1ff;
      }

      a {
        padding: 10px 13px;
        display: block;
        color: #2e8bc5;
        font-weight: 700;
        font-size: 0.9rem;

        .createTimes {
          display: block;
          width: 100%;
          border-top: 1px solid #ddd;
          margin-top: 7px;

          .createTimesTitle {
            font-size: 0.9rem;
            color: #646464;
            font-weight: 400;
            display: inline-block;
            margin-right: 4px;
          }

          .createTimesTime {
            font-size: 0.85rem !important;
            color: #646464 !important;
            font-weight: 400 !important;
          }

        }

      }
    }
  }
}


.modal_info {
  margin: 20px 0;
  margin-top: 0;
  border: 1px solid #ccc;
  padding: 20px;
  text-align: center;
}

.virtualLab {

  .virualLabInfo {
    width: 100%;
    margin-bottom: 20px;
    text-align: center;
    font-size: 1.05rem;
    margin-top: 40px;

    .timesCheck {
      margin: 35px auto;
      width: 100%;
      max-width: 497px;
      padding: 23px;
      border: 1px solid #d0d0d0;

      &:after {
        display: block;
        visibility: hidden;
        clear: both;
        content: '';
      }

      .timeCheckStart {
        float: left;
        width: 50%;
        max-width: 226px;
        padding: 0 6px;

        .timeCheckTitle {
          margin-bottom: 13px;
          font-size: 1.15rem;
          font-weight: 700;
        }

        .timeCheckTime {
          border-radius: 5px;
          border: 1px solid #ddd;
          padding: 10px;
          background-color: #fff;
          color: #2f2f2f;
          box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.17);
        }

      }

    }
  }

  .virtualLabBuutonWrap {
    width: 100%;
    display: flex;
    justify-content: center;

    .virtualLabBuuton {
      display: block;
      padding: 13px 21px;
      background-color: #0079c2;
      color: #fff;
      font-weight: 700;
      font-size: 1.1rem;

      &:hover {
        background: #004670;
      }

      &.activeVirs {
        background: #f27200;

        &:hover {
          background-color: #f27200 !important;
        }
      }
    }

    .virtualExtendButton {
      display: block;
      padding: 13px 21px;
      background-color: #6b6b6b;
      color: #fff;
      font-weight: 700;
      font-size: 1.1rem;
      margin-left: 13px;

      &:hover {
        background: #404040;
      }

      &.activeEx {
        background: #0067f4;

        &:hover {
          background-color: #004bb1 !important;
        }
      }
    }



  }

  .iframeWrap {
    width: 100%;
    margin-top: 40px;
    margin-bottom: 30px;
    position: relative;

    .framesBack {
      position: absolute;
      width: 100%;
      height: 430px;
      background: rgba(0, 0, 0, 0.8);
      z-index: 1;
      opacity: 1;
      transition: 0.8s ease;

      &.disappear {
        opacity: 0;
        z-index: -1;
      }

      .framesLoading {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        max-width: 266px;

        .framesLoadingTitle {
          font-size: 1.2rem;
          font-weight: bold;
          color: #fff;
          text-align: center;
        }

        .framesLoadingBarWrap {
          width: 100%;
          text-align: center;

          .framsLoadingBar {
            margin-top: 20px;
            width: 100%;
            height: 10px;
            background-color: #fff;
            border-radius: 100px;
            overflow: hidden;

            .framsLoadingBarIn {
              width: 50%;
              height: 12px;
              top: 0;
              left: 0;
              background-color: #0079c2;
              transition: 1s ease;
            }
          }

          .framsLoadingState {
            margin-top: 6px;
            color: #fff;
            font-weight: bold;
            text-align: center;
          }
        }
      }
    }

    .iframeinWrap {
      height: 430px;
      overflow-y: hidden;

      .frames {
        transform: translateY(-23px);
        width: 100%;
        border: 1px solid #ccc;
        height: 450px;
      }
    }
  }
}


// 클라우드 실습실 생성 모달창
.computation-popup {
  .modal-title {
    margin-bottom: 0;
    line-height: 1.5;
  }

  .font-weight-bold {
    font-weight: 700 !important;
  }
}


.selects {
  margin-right: 10px;
  flex: 1;

  &.long {
    select {
      width: 235px !important;
    }
  }

  select {
    min-width: 235px;
    border: 1px solid #ddd;
    border-radius: 5px;
    height: 40px;
    padding: 0 15px;
    width: auto;
    font-size: 14px;
    color: #666666;
    background: #fff url('#{$image-path}/nav-toggle-down.png') no-repeat calc(100% - 15px) center;
  }
}

//
//.quiz-content {
//  .pointWrap {
//
//  }
//}

//09.14새롭게추가
.activeCourse {
  background-color: #007eff !important;
}

.widthWrap {
  width: 100%;
  margin-top: 35px;
  margin-bottom: 46px;
  display: flex;

  .widthButton {
    width: 100%;
    max-width: 150px;
    margin: 0 auto;

    a {
      display: block;
      padding: 10px 0;
      margin: 0 auto;
      font-size: 0.9rem;
      font-weight: 700;
      background-color: #545454;
      color: #fff;
      border-radius: 13px;
    }
  }
}


//1001 새롭게추가
.popup-content {
  max-width: fit-content !important;
  // max-height: 90%;
  // overflow-y: auto;
  width: 100% !important;
  padding: 0 !important;
  height: fit-content;

  .member_popup {
    width: 100%;
    max-width: 524px;
  }

  .computation-popup {
    width: 100%;
    max-width: 420px;
    height: 100%;
    max-height: 800px;
    overflow-y: auto;
    padding: 5px;
  }

  .browser-popup {
    width: 100%;
    max-width: 1200px;
    height: 100%;
    max-height: 800px;
    overflow-y: auto;
    padding: 5px;
  }
}


//1101 모달
.classList {
  width: 100%;
  margin-bottom: 30px;

  select {
    border: 1px solid #000;
    width: 100% !important;
    padding: 5px 6px;
    border-radius: 3px;
    appearance: auto !important;
  }

}

.btn_submit_wrap {

  display: flex;
  align-items: center;
  justify-content: center;

  .courseSubmit {
    padding: 8px 20px;
    background: #0067f4;
    color: #fff;
    font-size: 1.1rem;
    border-radius: 3px;
    font-weight: 700;
    cursor: pointer;
  }
}


.userApplyCreateForm {
  max-width: 1100px !important;

  .program_popup {
    .modal_body {
      .classList {
        .selectTitle {
          font-size: 1.1rem;
          font-weight: 700;
          margin-bottom: 10px;
        }



      }


      .backendTablesWraps {
        padding: 0;

        .userSelectTitle {
          font-size: 1.1rem;
          font-weight: 700;
          margin-bottom: 10px;

        }

        .backendTables {
          .backendTablesUser {
            thead {
              tr {
                th {
                  text-align: center;
                }
              }
            }

            tbody {
              tr {
                td {
                  text-align: center;
                }
              }
            }
          }
        }


      }


    }
  }
}






// 새로운 디자인으로 변경===========================================================================
// tori 1115
// 공통

.live,
.learning_material,
.etc_material,
.assignment,
.virtual,
.discussion,
.comments,
.quiz,
.test,
.team {
  ._modal__block {

    padding: 0 20px 20px 20px;
    border: none;
    border-left: 1px solid #ddd;
    border-right: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
    border-radius: 0;
    border-bottom-left-radius: 13px;
    border-bottom-right-radius: 13px;

    &.modalcontent {
      border: none;
      border-radius: 0;
      padding: 0;
    }

    .subexplanation {
      display: block;
      // padding: 0 20px;
      font-size: 0.8rem;
      color: #ADADAD;
    }

    .info {

      // 버튼
      button {
        height: 45px;
        font-size: 0.95rem;
        border-radius: 13px;
        color: #333;
        background: #fff;
        border: 1px solid #ddd;
        padding: 10px 20px;

        img {
          margin-right: 5px;
        }

        &.video {
          height: 45px;
          padding: 10px 20px 10px 38px;
          background: url('#{$image-path}/button-video-gj.png') no-repeat 20px center;
          // 이미지 13px x 14px
        }
      }

      p {
        margin: 10px 0;
        font-size: 1rem;
      }
    }

    // modal_block안의 버튼
    button {
      &._btn {
        height: 45px;

        &._btn--uploader {
          height: 45px;
          line-height: unset;
          border-radius: 13px;
          border: 1px solid #ddd;
          padding: 10px 20px 10px 56px;
          background: url('#{$image-path}/etcmaterial-icon-gj.png') no-repeat 20px center;

          &.etc {
            background: url('#{$image-path}/learningmaterial-icon-gj.png') no-repeat 20px center;
          }
        }
      }
    }

    .assign_submit {
      .insertForm {
        .insertForm__content {
          .file-btn {
            .file_name {
              border-radius: 13px;
              margin-right: 10px;
              display: none;
            }

            ._btn {
              border-radius: 13px;
              border: none;
              padding: 10px 20px 10px 41px;
              background: url('#{$image-path}/file-btn-upload-icon.png') no-repeat 20px center #0B94EE;
            }
          }
        }
      }
    }

    // _modal__block안의 quiz
    .quiz {
      .quiz-title {
        >span {
          display: inline-block;
          text-indent: -9999px;
          overflow: hidden;
          background: url('#{$image-path}/assignment-icon-gj.png') no-repeat center;
          width: 31px;
          height: 28px;
          margin-right: 5px;
          vertical-align: middle;
        }

        >p {
          display: inline-block;
          font-size: 0.7rem;
          color: #696969;
          padding: 4px 8px;
          background: #FDDF76;
          border-radius: 13px;
          font-weight: 300;
          vertical-align: middle;
          margin-left: 10px;
        }
      }

      .quiz-content {
        background: #F6F7FB;
        border: 0;
        color: #adadad;
        border-radius: 13px;
      }
    }

    // _modal__block안의 quiz
  }
}

.panelLayout {
  .panelLayout__content {
    .Video {
      &.assignment {
        .learn_submit {
          ._learn__actions {
            .submit {
              width: auto;
              height: 45px;
              line-height: unset;
              background: url('#{$image-path}/submit-btn-icon.png') no-repeat 20px center #55B87A;
              // gj 컬러
              padding: 10px 20px 10px 45px;
              border-radius: 13px;
              border: none;

              &.write {
                background: url('#{$image-path}/submit-write-icon.png') no-repeat 20px center #55B87A;
              }
            }
          }
        }
      }
    }
  }
}

.homework-info,
.quiz-result {
  ._learn__actions {
    button {
      &.submit {
        width: auto;
        height: 45px;
        line-height: unset;
        border-radius: 13px;
        border: none;
        background: url('#{$image-path}/submit-btn-icon.png') no-repeat 20px center #55B87A;
        // gj 컬러
        padding: 10px 20px 10px 45px;

        &.write {
          background: url('#{$image-path}/submit-write-icon.png') no-repeat 20px center #55B87A;
        }
      }
    }
  }
}


// 가상 컴퓨터실
.virtualLab {
  .virualLabInfo {
    .timesCheck {
      margin: 0;
      max-width: unset;

    }
  }

  .virtualLabBuutonWrap {
    display: block;

    .virtualLabBuuton {
      font-size: 1rem;
      border-radius: 13px;
      border: 1px solid #ddd;
      height: 45px;
      padding: 10px 20px 10px 56px;
      color: #696969;
      font-weight: 600;
      background: url('#{$image-path}/virtualLabButton-icon-gj.png') no-repeat 20px center #fff;

      &:hover {
        background: url('#{$image-path}/virtualLabButton-icon-gj.png') no-repeat 20px center #47ca80;
        border: 1px solid #47ca80;
        color: #fff;
      }
    }
  }

  .iframeWrap {
    .iframeinWrap {
      .frames {
        border: 0;
      }
    }

    .framesBack {
      border-radius: 13px;
      border: 0;

      .framesLoading {

        // max-width: unset;
        .framesLoadingTitle {
          font-weight: 400;
          font-size: 1.05rem;
          font-weight: 400;
        }
      }
    }
  }

  .virualLabInfo {
    color: #696969;
    text-align: left;
    margin-top: 20px;
    font-size: 0.9rem;

    .timesCheck {
      padding: 0;
      border: 0;

      .timeCheckStart {
        padding: 0;
        width: unset;
        display: flex;
        align-items: center;

        .timeCheckTitle {
          margin-bottom: 0;
          font-weight: normal;
          font-size: 0.8rem;
          line-height: 2rem;
          // margin-right: 10px;
          color: #47ca80;

          &::after {
            content: '';
            display: inline-block;
            width: 1px;
            height: 0.6rem;
            margin: 0 5px;
            background: #ADADAD;
          }
        }

        .timeCheckTime {
          line-height: 2rem;
          padding: 0;
          box-shadow: none;
          border: 0;
          font-size: 0.8rem;
          margin-right: 20px;
          color: #ADADAD;
        }
      }
    }
  }

  .iframeWrap {
    margin-top: 0;
  }
}

// lnb
.panelLayout {
  .panelLayout__content {
    .PanelContentList {
      .sectionBlock {
        h3 {
          a {
            color: #696969;

            &.active {
              color: #000;
            }
          }
        }
      }
    }
  }
}

// 새로운 디자인으로 변경===========================================================================
// tori 1115


// 팀프로젝트 모달창 틀어진 부분 수정 tori1118
.schedule__tabs {
  button {
    // width: 135px;
    width: 140px;
  }
}

// 팀프로젝트 모달창 틀어진 부분 수정 tori1118

// 버튼 재정의 tori1119
.panelLayout {
  .panelLayout__content {
    .Video {

      &.assignment,
      &.discussion,
      &.comments,
      &.quiz {
        .learn_submit {
          ._learn__actions {
            .submit {
              width: auto;
              height: 45px;
              line-height: unset;
              background: #55B87A;
              // gj 컬러
              padding: 10px 20px;
              border-radius: 13px;
              border: none;

              &::before {
                content: '';
                display: inline-block;
                width: 19px;
                height: 20px;
                margin-right: 10px;
                vertical-align: middle;
                background: url('#{$image-path}/submit-btn-icon.png') no-repeat center;
              }

              &.write {
                &::before {
                  content: '';
                  display: inline-block;
                  width: 17px;
                  height: 16px;
                  margin-right: 10px;
                  vertical-align: middle;
                  background: url('#{$image-path}/submit-write-icon.png') no-repeat center;
                }
              }
            }
          }
        }
      }
    }
  }
}

// 버튼 반응형
@media screen and (max-width: 768px) {
  .panelLayout {
    .panelLayout__content {
      .Video {

        &.assignment,
        &.discussion,
        &.comments,
        &.quiz,
        &.virtualLab,
        &.learning_material,
        &.team,
        &.test {
          .learn_submit {
            ._learn__actions {
              .submit {
                width: 100%;

                &.write {
                  width: 100%;
                }
              }
            }
          }
        }
      }
    }
  }

}

// 버튼 재정의 tori1119


// 버튼 반응형
@media screen and (max-width: 768px) {
  .panelLayout {
    .panelLayout__content {
      .Video {

        &.assignment,
        &.discussion,
        &.comments,
        &.quiz,
        &.virtualLab,
        &.learning_material,
        &.team,
        &.test {
          .learn_submit {
            ._learn__actions {
              .submit {
                width: 100%;

                &.write {
                  width: 100%;
                }
              }
            }
          }
        }
      }
    }
  }

}

// 버튼 재정의 tori1119



// 강의 입력 모달 새 디자인으로 재정의 tori1123==================================
._modal {
  &.upload {
    ._modal__content {
      ._modal__header {
        height: 75px;
        background: #fff;
        padding: 0 30px;
        border-bottom: 0;

        >button {
          text-indent: -9999px;
          overflow: hidden;
          width: 31px;
          height: 31px;
          background: url('#{$image-path}/modal_close_btn.png') no-repeat center;
        }

        h2 {
          // 헤더 모달창 제목 부분
          font-size: 1.125rem;
          font-weight: 400;
          color: #333;
          position: relative;
          padding-left: 31px;

          &::before {
            content: '';
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
          }

          //  헤더 모달창 제목 > 동영상
          &.video {
            &::before {
              width: 21px;
              height: 18px;
              background: url('#{$image-path}/modal-video-icon.png') no-repeat center;
            }
          }

          //  헤더 모달창 제목 > 가상컴퓨터실
          &.virtual {
            &::before {
              width: 19px;
              height: 21px;
              background: url('#{$image-path}/virtual-header-icon-gj.png') no-repeat center;
            }
          }

          //  헤더 모달창 제목 > 실시간수업
          &.live {
            &::before {
              width: 20px;
              height: 16px;
              background: url('#{$image-path}/live-header-icon-gj.png') no-repeat center;
            }
          }

          //  헤더 모달창 제목 > 과제
          &.assignment {
            &::before {
              width: 22px;
              height: 18px;
              background: url('#{$image-path}/lecture-icon-13-gj.png') no-repeat center;
            }
          }

          //  헤더 모달창 제목 > 교안
          &.material {
            &::before {
              width: 18px;
              height: 17px;
              background: url('#{$image-path}/lecture-icon-8-gj.png') no-repeat center;
            }
          }

          //  헤더 모달창 제목 > 자료
          &.etc {
            &::before {
              width: 18px;
              height: 17px;
              background: url('#{$image-path}/lecture-icon-9-gj.png') no-repeat center;
            }
          }

          //  헤더 모달창 제목 > 댓글과 질문
          &.comment {
            &::before {
              width: 22px;
              height: 19px;
              background: url('#{$image-path}/lecture-icon-10-gj.png') no-repeat center;
            }
          }

          //  헤더 모달창 제목 > 토론
          &.discussion {
            &::before {
              width: 21px;
              height: 19px;
              background: url('#{$image-path}/lecture-icon-14-gj.png') no-repeat center;
            }
          }

          //  헤더 모달창 제목 > 팀프로젝트
          &.team {
            &::before {
              width: 18px;
              height: 18px;
              background: url('#{$image-path}/lecture-icon-team-gj.png') no-repeat center;
            }
          }

          //  헤더 모달창 제목 > 시험
          &.test {
            &::before {
              width: 19px;
              height: 21px;
              background: url('#{$image-path}/lecture-icon-test-gj.png') no-repeat center;
            }
          }

          //  헤더 모달창 제목 > 퀴즈
          &.quiz {
            padding-top: 0;

            &::before {
              width: 24px;
              height: 20px;
              background: url('#{$image-path}/lecture-icon-12-gj.png') no-repeat center;
            }
          }
        }
      }

      // 모달 바디
      ._modal__body {
        &._modal__body--bg-none {
          background: #fff;
          padding: 0 30px 10px;

          .insertForm {
            // 입력폼
            margin-left: 0;

            // 파일컨테이너 닫기버튼
            .filelist_container {
              >button {
                background: #55B87A;
                border: 1px solid #55B87A;
                margin-left: 10px;
              }
            }

            // 입력폼 제목
            .insertForm__name {
              width: 155px;

              &.required {
                &::after {
                  color: #55B87A !important;
                }
              }
            }

            // 입력폼 콘텐츠
            .insertForm__content {
              .input {
                line-height: 40px;

                input {
                  border: 0;
                  border-bottom: 1px solid #ddd;
                  border-radius: 0;

                  &:focus {
                    outline: 0;
                  }
                }
              }

              // insertForm안의 row 세로 가운데정렬
              .row {
                align-items: center;

                &.flexstart {
                  // insertForm안의 정렬
                  align-items: flex-start;
                }

                .col {

                  // 파일업로드 옆의 퍼센트
                  .timerBox {
                    margin-left: 10px;
                    ;
                  }
                }
              }

              // insertForm안의 인코딩 현황
              .encodeStateBox {
                line-height: 40px;
              }

              // 파일 업로드 버튼
              .file-btn {
                ._btn {
                  border: 1px solid #55B87A;
                  border-radius: 13px;
                  background: url('#{$image-path}/file-btn-upload-icon.png') no-repeat 15px center #55B87A !important;
                  margin-left: 10px;

                  &._btn--nextcloud {
                    margin-left: 0;
                    background: url('#{$image-path}/file-btn-upload-icon.png') no-repeat 15px center #55B87A !important;
                  }
                }

                // 파일이름
                input {
                  &.file_name {
                    border-radius: 13px;
                  }
                }
              }

              // 팀프로젝트 > 현재 ~명의 수강생이 있습니다.
              .nowStu {
                line-height: 40px;
                height: 40px;
              }

              // 팀프로젝트 > 팀 개수 입력필요 버튼
              .sliceButton {
                .autoDragButton {
                  border-radius: 13px;
                }
              }

              // quix-options
              // 시험 > 시험 옵션창
              .quiz-options {
                .quiz-option-item {
                  border-radius: 13px;

                  // 퀴즈, 시험 보기 모달창
                  display: block;

                  .quiz-option-label {
                    background: none;

                    // border-right: 1px dashed #ddd;
                    .custom-checkbox {
                      &.quizz {
                        display: flex;
                        width: 240px;
                        position: absolute;
                        top: 15px;
                        left: 5px;

                        >span {
                          margin-top: 0;
                          width: 23px;
                          height: 23px;
                          border-radius: 13px;
                        }

                        p {
                          &.quizzinfo {
                            font-size: 0.875rem;
                            color: #55B87A;
                            margin-left: 5px;
                          }
                        }
                      }
                    }
                  }

                  // quiz-remove
                  // 퀴즈 -> 문항 삭제 버튼
                  .quiz-remove {
                    right: 25px;
                    top: 10px;
                  }
                }
              }
            }

            .schedule__tabs {
              border-bottom: 0;

              button {
                width: unset;
                padding: 0 30px;
                height: 30px;
                font-size: 1.05rem;
                color: #adadad;

                &.active {
                  color: #1A9952;
                  border-bottom: 3px solid #1A9952;
                }
              }
            }

            // 모달블럭
            ._modal__block {
              padding: 0;

              // 모달블럭의 버튼
              ._btn {
                &._btn--youtube {
                  // 유튜브버튼
                  line-height: 40px;
                  border-radius: 13px;
                  border: 1px solid #55B87A;
                  background: #55B87A;
                }

                &.small {

                  // 고급문항 버튼
                  &.options {
                    padding: 0 30px;
                    border-radius: 13px;
                    background: #ACDBA4;
                    color: #5C8D72;
                  }
                }

                &.surveyaddbtn {
                  // 시험 > 문항추가하기 버튼
                  height: 40px;
                  padding: 0 30px;
                  border-radius: 13px;
                  background: #55B87A;

                  &::before {
                    content: '';
                    display: inline-block;
                    vertical-align: -6px;
                    margin-right: 5px;
                    width: 19px;
                    height: 21px;
                    background: url('#{$image-path}/survey-add-icon.png') no-repeat center;
                  }
                }

                &.exampleaddbtn {
                  background: #ACDBA4;
                  border: 1px solid #ACDBA4;
                  color: #5C8D72;
                  font-weight: 500;
                  border-radius: 13px;
                  height: 40px;
                  padding: 0 30px;

                  &::before {
                    content: '';
                    display: inline-block;
                    vertical-align: middle;
                    margin-right: 5px;
                    width: 18px;
                    height: 18px;
                    background: url('#{$image-path}/survey-plus.png') no-repeat center;
                  }
                }
              }

              // 모달블럭의 커스텀체크박스
              .custom-checkbox {
                p {
                  &.checkdate {
                    // 동영상-출석인정기간 사용함, 사용안함 
                    width: 100px;
                  }
                }
              }

              // 모달블럭의 헤더
              ._modal__block-header {
                border-bottom: 0;
                height: 40px;

                .modal_date_span {
                  // 생성일
                  color: #999;
                  font-size: 0.85rem;
                }
              }

              .myCloudButton {
                // 내 클라우드에서 가져오기
                z-index: 1;
                right: 0;
                padding: 9px 15px 9px 42px;
                border-radius: 13px;
                background: url('#{$image-path}/mycloud-icon.png') no-repeat 15px center #55B87A;
              }


              ._modal__block-body {
                // 모달블럭의 바디

                .modal-body {
                  padding: 0;
                }

                padding: 0;

                &~._modal__block-body {
                  // 모달블럭 바디 안의 모달블럭에 보더없애기
                  border-top: 0;
                }

                .row {
                  &.rate {
                    // 학습인정 진도율 간격조정
                    margin: 20px 0;
                  }
                }

                .a_div {
                  .a_btn {
                    padding: 8px 15px;
                    border-radius: 13px;
                    background: #55B87A;

                    &.professor {

                      // 교수자로 입장하기
                      &::before {
                        content: '';
                        display: inline-block;
                        width: 17px;
                        height: 20px;
                        margin-right: 5px;
                        vertical-align: -3px;
                        background: url('#{$image-path}/professor-enter-icon.png') no-repeat center;
                      }
                    }
                  }
                }

              }

              // 학습현황 및 평가 모달
              &.scoretablemodal {
                .scorename {
                  // 제출기한, 제출상태
                  display: inline-block;
                  width: 100px;
                  line-height: 20px;
                  padding: 10px 0;
                  font-size: 1rem;
                  font-weight: 500;
                }

                .attendance_date {
                  color: #ADADAD;
                }

                button {
                  &.tatal_assigment {
                    height: 40px;
                    border-radius: 13px;
                    padding: 0 30px;
                    background: #ACDBA4;
                    border: 1px solid #ACDBA4;
                    font-size: 0.95rem;
                    color: #5C8D72;

                    &::before {
                      content: '';
                      width: 20px;
                      height: 18px;
                      display: inline-block;
                      background: url('#{$image-path}/tatal_assigment-btn-icon.png') no-repeat center;
                      vertical-align: middle;
                      margin-right: 5px;
                    }

                    &.down {
                      color: #fff;
                      background: #55B87A;
                      border: 1px solid #55B87A;

                      &::before {
                        background: url('#{$image-path}/tatal_assigment-btn-down-icon.png') no-repeat center;
                      }
                    }
                  }
                }

                .modal_date_span {
                  font-size: 0.85rem;
                  color: #999;
                  margin-left: 110px;
                  line-height: 30px;
                }

                // 학습현황 및 평가의 테이블
                ._dataTable {
                  &._dataTable--border {
                    border: 1px solid #ddd;
                    border-radius: 13px;
                    overflow: hidden;

                    th,
                    td {
                      border-right: 0 !important;
                    }

                    thead {
                      th {
                        background: #fff;
                        color: #333;
                        height: 50px;
                        font-weight: 500;
                      }
                    }

                    tbody {
                      tr:nth-child(odd) {
                        td {
                          background: #F6F7FB;
                        }
                      }

                      td {
                        padding: 5px 10px;
                        height: 20px;

                        p {
                          &.text-danger {
                            color: #0B94EE !important;
                          }
                        }
                      }
                    }
                  }
                }
              }

              // 학습현황 및 평가 모달

              .flex {
                .quiz-true {
                  // 퀴즈가 정답인 경우
                  padding-left: 1.5rem;
                  color: #55B87A;
                  background: url('#{$image-path}/quiz-true-gj.png') no-repeat left center !important;
                }

                .quiz-false {
                  // 퀴즈가 오답인 경우
                  padding-left: 1.5rem;
                  color: #333;
                  background: url('#{$image-path}/quiz-false-gj.png') no-repeat left center !important;
                }
              }

              // 모달블럭의 헬프텍스트
              .help-text {
                margin-top: 0;

                &.discuss {
                  margin-left: 155px;
                  color: #55B87A;
                }
              }
            }

            // 모달블럭
          }
        }

        // 모달 푸터
        ._modal__footer {
          border-top: 0;
          padding: 20px 0;

          ._modal__actions {

            // 푸터 버튼
            button {
              width: 140px;
              height: 40px;
              border-radius: 13px;
              background: #ACDBA4;
              color: #5C8D72;

              &::before {
                content: '';
                width: 17px;
                height: 17px;
                display: inline-block;
                background: url('#{$image-path}/modal-btn-cancel.png') no-repeat center;
                vertical-align: middle;
                margin-right: 5px;
              }

              // 푸터 버튼 - 저장
              &.submit {
                color: #fff;
                background: #55B87A;

                &::before {
                  background: url('#{$image-path}/modal-btn-submit.png') no-repeat center;
                }
              }

              // 푸터 버튼 - 삭제
              &.bg-danger {
                &.text-white {
                  color: #fff;
                  background: #1A9952 !important;

                  &::before {
                    width: 18px;
                    height: 20px;
                    background: url('#{$image-path}/modal-btn-delete.png') no-repeat center;
                  }
                }
              }
            }
          }
        }

        // 헬프 텍스트
        .help-text-block {
          margin: 10px 0 20px 0;
          padding: 20px 30px;
          border-radius: 13px;
          background: #F6F7FB;

          .help-text {
            margin-top: 0;
          }

          .help-red {
            color: #55B87A;
          }
        }
      }
    }

    // quill
    .quill {
      .ql-toolbar {
        &.ql-snow {
          border-top-left-radius: 13px;
          border-top-right-radius: 13px;
        }
      }

      .ql-container {
        &.ql-snow {
          border-bottom-left-radius: 13px;
          border-bottom-right-radius: 13px;
        }
      }
    }

    // selects
    .selects,
    .select {
      select {
        border-radius: 13px;

        &.quiz_name {
          // 퀴즈 > 문제유형
          min-width: 140px;
        }
      }
    }

    // 모달바디 패딩 0
    .modal-body {
      padding: 0;
    }

    // 데이터 테이블 공통
    // scoretablemodal 클래스 추가할 경우
    ._dataTable {
      &.scoretablemodal {
        border: 1px solid #ddd;
        border-radius: 13px;
        overflow: hidden;

        .select_button {
          padding: 10px 20px;
          background: #55B87A;
          border-radius: 10px;
          color: white;
        }

        th,
        td {
          border-right: 0 !important;
        }

        thead {
          th {
            background: #fff;
            color: #333;
            height: 50px;
            font-weight: 500;
          }
        }

        tbody {
          tr:nth-child(odd) {
            td {
              background: #F6F7FB;
            }
          }

          td {
            padding: 5px 10px;
            height: 20px;

            &.incomplete1 {
              color: #0B94EE !important;
            }
          }
        }
      }

      >button {
        // scoretablemodal의 button기본값
        height: 40px;
        background: #55B87A;
        color: #fff;
        padding: 0 30px;
        border-radius: 13px;
        margin-bottom: 10px;
      }
    }

    // 데이터 테이블 공통
    // scoretablemodal 클래스 추가할 경우

    // survey
    // 시험 모달
    .survey {
      .survey__body {
        padding: 20px 0;

        .survey__body-content {
          background: none;
          padding: 20px;
          border-radius: 13px;
          border: 1px solid #ddd;

          .survey__select-type-item {
            border-radius: 13px;
            background: #F6F7FB;
          }

          // 문제형식을 골라주세여
          .survey_pick {
            text-align: center;

            &::before {
              content: '';
              display: inline-block;
              width: 35px;
              height: 25px;
              margin-right: 10px;
              vertical-align: middle;
              background: url('#{$image-path}/test-icon-gj.png') no-repeat center;
            }
          }

          // 문항
          .survey__content-item {
            border-radius: 13px;

            .survey__content-item-header {
              background: #fff;

              h2 {
                position: relative;

                &.question {
                  text-indent: 60px;

                  &::before {
                    content: '';
                    position: absolute;
                    left: 0;
                    top: 50%;
                    transform: translateY(-50%);
                  }

                  &.Mtch {
                    &::before {
                      width: 63px;
                      height: 22px;
                      background: url('#{$image-path}/Group 33808.png') no-repeat center;
                    }
                  }

                  &.Plur {
                    &::before {
                      width: 75px;
                      height: 22px;
                      background: url('#{$image-path}/Group 33806.png') no-repeat center;
                    }
                  }

                  &.Short {
                    &::before {
                      width: 67px;
                      height: 22px;
                      background: url('#{$image-path}/Group 33807.png') no-repeat center;
                    }
                  }
                }
              }

              >button {
                border-radius: 0;
                border: 0;
              }
            }

            .surveyitem {
              border-top: 1px dashed #ddd;

              &.displaynone {
                border-top: 0;
              }
            }
          }
        }
      }

      // 시험 취소추가 버튼
      .answer-button-box {
        .btn {
          background: #ACDBA4;
          border: 1px solid #ACDBA4;
          border-radius: 13px;
          color: #5C8D72;
          min-width: 120px;
          height: 40px;
          line-height: 40px;
          font-size: 0.875rem;

          &::before {
            content: '';
            display: inline-block;
            width: 19px;
            height: 22px;
            margin-right: 10px;
            vertical-align: -5px;
            background: url('#{$image-path}/survey-cancel-icon.png') no-repeat center;
          }

          &.submit {
            border: 1px solid #55B87A;
            background: #55B87A;
            color: #fff;

            &::before {
              width: 19px;
              height: 21px;
              background: url('#{$image-path}/survey-add-icon.png') no-repeat center;
            }
          }
        }
      }
    }

    // 시험 모달

    // 문제 입력 에디터(시험, 퀴즈 동일)
    .editor {
      textarea {
        border-radius: 13px;
      }
    }

    // 팀 프로젝트 -> 팀 구역 생성 팀아이콘 1125
    .dragWrap {
      .dndWrap {
        .sliceStu {
          background: #fff !important;
          border: 1px solid #ddd;
          padding: 20px 20px 12px 20px;
          border-radius: 13px;

          .teamName {
            padding: 0;
            background: none !important;
            color: #696969 !important;

            &::before {
              content: '';
              display: block;
              width: 100%;
              height: 30px;
              margin-bottom: 7px;
              background: url('#{$image-path}/team.png') no-repeat center;
            }

            &.team1,
            &.team5,
            &.team9,
            &.team13 {
              &::before {
                background: url('#{$image-path}/team1.png') no-repeat center;
              }
            }

            &.team2,
            &.team6,
            &.team10,
            &.team14 {
              &::before {
                background: url('#{$image-path}/team2.png') no-repeat center;
              }
            }

            &.team3,
            &.team7,
            &.team11,
            &.team15 {
              &::before {
                background: url('#{$image-path}/team3.png') no-repeat center;
              }
            }

            &.team4,
            &.team8,
            &.team12,
            &.team16 {
              &::before {
                background: url('#{$image-path}/team4.png') no-repeat center;
              }
            }
          }
        }
      }
    }
  }
}


// 강의 입력 모달 반응형 1125
@media screen and (max-width: 1280px) {
  ._modal {
    &.upload {
      ._modal__content {
        border-radius: 0;
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  ._modal {
    &.upload {
      ._modal__content {
        height: 100vh;

        ._modal__body {
          .insertForm {
            ._modal__block {
              .myCloudButton {
                // 내 클라우드에서 가져오기
                top: -33px;
              }

              .flex {
                flex-wrap: wrap;

                .custom-checkbox {
                  min-height: 100%;
                  height: 40px;
                  width: 100%;

                  // 두줄로 내리고 마진 없애기
                  &~.custom-checkbox {
                    margin-left: 0;
                  }
                }

                // 출석인정기간
                &.attendance {
                  h4 {
                    width: 60px
                  }

                  .select {
                    margin: 5px 0;
                    width: calc(33.3% - 30px);

                    select {
                      // 출석인정기간 셀렉트박스
                      min-width: 200px;

                      @media screen and (max-width: 895px) {
                        min-width: 150px;
                      }
                    }

                    // &:nth-child(5){
                    //   margin-left: 70px;
                    // }
                  }
                }
              }

              // 실시간 강의 > 시작시간 종료시간 플렉스 블록으로
              .timeform {
                width: 100%;

                .flex {
                  .select {
                    margin: 5px 0;

                    select {
                      width: 200px;
                    }
                  }
                }

                h4 {
                  display: none;
                }
              }

              // 모달블럭의 셀렉트
              .select {

                // margin: 5px;
                select {
                  width: 200px;
                }
              }
            }

            .insertForm__content {
              .row {
                // 플렉스 풀기
                display: block;

                .col {
                  &.col-auto {
                    margin: 20px 0;
                    padding-left: 0;
                  }
                }
              }

              // 메시지 알림 i 
              .input {
                .custom-checkbox {
                  margin: 5px 0;

                  >p {
                    display: flex;
                    align-items: center;

                    >i {
                      line-height: 1rem;
                      width: calc(100% - 29px);
                      margin-left: 5px;
                    }
                  }
                }
              }

              .file-btn {

                // 파일이름
                input {
                  width: 50%;
                  min-width: 400px;
                }
              }

            }
          }
        }
      }

      .dragWrap {
        // 팀 구역 생성
        margin-left: 0;

        .dndWrap {
          .sliceStu {
            width: 46%;
            margin: 0 2% 20px 2%;
          }
        }
      }

      // 시험 > 문제 1024일때 양옆 패딩 0
      .survey {
        .survey__body {
          .survey__content {
            padding: 0 0;
          }
        }
      }
    }
  }
}


@media screen and (max-width: 768px) {
  ._modal {
    &.upload {
      ._modal__content {
        height: 100vh;

        ._modal__header {

          // 모달 헤더
          h2 {
            text-align: center;

            &::before {
              content: '';
              position: static;
              display: inline-block;
              vertical-align: middle;
              margin-right: 7.5px;
              transform: translateY(0);
            }
          }
        }

        ._modal__body {
          &._modal__body--bg-none {
            .insertForm {
              .insertForm__content {
                .file-btn {

                  // width: 100%;
                  ._btn {
                    // 파일 업로드 버튼
                    width: unset;
                    min-width: 120px;
                  }

                  // 파일이름
                  input {
                    width: 50%;
                    min-width: unset;

                    &.file_name {
                      border-radius: 13px;
                      width: 50%;
                    }
                  }
                }

                .input {
                  // 인풋 텍스트박스
                  width: 100%;
                }

                // 퀴즈
                .quiz-options {
                  .quiz-option-item {
                    .quiz-option-label {
                      .custom-checkbox {
                        &.quizz {
                          left: 0;
                          width: 225px;
                        }
                      }
                    }
                  }
                }
              }

              ._modal__block {
                .myCloudButton {
                  // 내 클라우드에서 가져오기 가로 100으로
                  position: static;
                  width: 100%;
                  background: #55B87A;
                  padding: 0;
                  height: 40px;

                  &::before {
                    content: '';
                    width: 22px;
                    height: 19px;
                    display: inline-block;
                    margin-right: 10px;
                    background: url('#{$image-path}/mycloud-icon.png') no-repeat center;
                    vertical-align: middle;
                  }

                }

                .flex {

                  // 768에서 flex -> block으로 한줄에 한칸씩
                  // display: block;
                  // 출석인정기간
                  &.attendance {
                    h4 {
                      width: 100%;
                      margin: 5px 0;
                    }

                    .select {
                      margin: 5px 0;
                      width: 100%;

                      select {
                        // 출석인정기간 셀렉트박스
                        min-width: 200px;
                        width: 90%;
                        margin: 0 auto;
                      }

                      &:nth-child(5) {
                        margin-left: 0;
                      }
                    }
                  }

                  // 정답인 경우 오답인 경우
                  .quiz-true,
                  .quiz-false {
                    width: 100%;
                    margin-bottom: 10px;
                  }
                }

                // 실시간 강의 > 시작시간 종료시간 플렉스 블록으로
                .timeform {
                  width: 100%;

                  .flex {
                    display: block;

                    .select {
                      width: 100%;
                      margin: 5px 0;

                      select {
                        width: 100%;
                      }
                    }
                  }

                  h4 {
                    display: none;
                  }
                }

                // 768에서 헬프텍스트 가로 100
                .help-red {
                  width: 100%;
                }

                // 768에서 헬프텍스트 간격띄우기
                .help-text {
                  margin-top: 8px;
                }

                // 모달블럭의 고급옵션열기 > 768에서 가로 100
                ._btn {
                  &.small {
                    &.options {
                      width: 100%;
                    }
                  }

                  &._btn--youtube {
                    // 유튜브버튼
                    margin-top: 10px;
                  }

                  &.exampleaddbtn {
                    width: 100%;
                  }

                  &.surveyaddbtn {
                    width: 100%;
                  }
                }

                // 모달블럭의 셀렉트
                .select {
                  margin-right: 0;

                  select {
                    width: 100%;
                  }
                }
              }
            }
          }

          ._modal__block-body {

            // 제목하고 컨텐츠 두줄로 나뉘어서 나오게
            .row {
              display: block;

              .insertForm__name {
                font-size: 1.125rem;
                line-height: 2rem;
                margin-top: 1rem;
              }
            }
          }

          // 모달 푸터
          ._modal__footer {
            ._modal__actions {
              justify-content: space-between;
              flex-wrap: wrap;

              button {
                // 푸터 버튼
                width: 49%;
                margin: 0;

                // 푸터 버튼 - 저장
                &.submit {
                  width: 49%;
                  margin: 0;
                  margin-right: 2%;
                }

                // 푸터 버튼 - 삭제
                &.bg-danger {
                  &.text-white {
                    width: 100%;
                    margin-bottom: 10px;
                  }
                }
              }

              .text-danger {
                &.mr-30 {
                  // 실시간수업은 수정할수없습니다 텍스트
                  width: 100%;
                  margin-left: 10px;
                  margin-bottom: 5px;
                }
              }
            }
          }
        }
      }

      // 시험 모달
      .survey {
        .survey__content-form-actions {
          justify-content: center;

          >button {
            width: 50%;
          }
        }

        .answer-button-box {
          &.end {
            justify-content: center;
          }

          .btn {
            width: 50%;
          }
        }
      }
    }
  }
}

// 강의 입력 모달 반응형 1125
// 강의 입력 모달 새 디자인으로 재정의 tori1123==================================



// 교수자로 입장하기 변경
.live_video {
  .two_live_block {
    ._modal__block {
      margin: 20px 0;
    }

    .a_btn {
      padding: 8px 15px;
      border-radius: 13px;
      background: #55B87A;

      &.professor {
        margin: 20px 0;

        // 교수자로 입장하기
        &::before {
          content: '';
          display: inline-block;
          width: 17px;
          height: 20px;
          margin-right: 5px;
          vertical-align: -3px;
          background: url('#{$image-path}/professor-enter-icon.png') no-repeat center;
        }
      }
    }
  }
}

//1130 실시간수업 디자인 변경
.live_video {
  padding: 0 20px !important;

  .two_live_block {
    .live_title {
      margin-bottom: 4px;
    }

    .live_p {
      margin-bottom: 30px;
      font-size: 0.85rem;
      color: #8a8a8a;
    }

    .live_button_wrap {
      margin-bottom: 30px;
    }

  }
}

._modal__block-body {
  .input_secretDiv {
    display: flex;
    margin: auto 0;
  }

  .input_secret {
    display: inline-block;
    margin-right: 40px;
    vertical-align: sub;
    height: 35px;

    .secret_check {
      border: 1px solid #ddd;
      border-radius: 30px;
      height: 28px;
      width: 28px;
      text-align: center;
    }

    .secret_checkbox:checked+label::after {
      //background: url('#{$image-path}/secret_checked.png') no-repeat center;
      content: '✔';
    }

    .input_span {
      vertical-align: top;
      margin-left: 10px;
    }

    input[type='radio'] {
      display: none;
    }
  }

  .secret_pass {
    display: inline-block;
    vertical-align: middle;
    padding: 10px;

    input[type='password'] {
      width: 218px !important;
      height: 50px;
      padding: 10px;
      border: 1px solid #ddd;
      border-radius: 10px;
    }

    .password.deactive {
      background: #ddd;
      width: 218px;
      height: 50px;
      border: 1px solid #ddd;
      border-radius: 10px;
      padding: 10px;
    }
  }
}

.dash_upload_wrap {
  margin-top: 15px;
}


.del_file_buttons {
  width: auto !important;
  padding: 0 7px 0 5px;
  font-size: 0.85rem;
  font-weight: 500;
  background: #f11e41 !important;
  border: 1px solid #f11e41 !important;
}

// 220415 시험관련
.exam_bt_btn {
  text-align: center;
  min-width: 300px;
  margin-bottom: 30px;

  div {
    display: inline;
  }

  button {
    border: 1px solid #666;
    color: #666666;
    margin-right: 10px;
    line-height: 40px;
    padding: 0 20px;
    border-radius: 5px;
  }
}




.popup-content {
  &.pop_exam-content {
    height: auto;
    border-radius: 13px;
  }

  &.pop_learning_record-content {
    max-height: 800px !important;
  }
}

.exam_pop {
  max-width: 660px !important;
  height: auto;
  overflow: auto;

  .modal_title {
    padding: 0 0 10px;
    font-size: 22px;
    color: #405F25;
    font-weight: 700;
    text-align: center;
  }

  .modal_body {
    >div {
      &:nth-child(2) {
        margin: 15px 0 0;
        padding: 15px 25px;
        border: 1px solid #ddd;
        border-radius: 13px;

        p {
          line-height: 35px;
        }
      }

      &:last-child {
        margin: 20px 0 0;
        display: flex;
        align-items: center;
        justify-content: end;

        button {
          float: none;
          margin: 0 0 0 10px;
          width: 140px;
          height: 40px;
          font-size: 14px;
          color: #fff;
          font-weight: 400;
          background: #55B87A;
          border-radius: 13px;
          text-shadow: none;
          opacity: 1;

          &:first-child {
            background: #1A9952;
          }

          &:hover {
            opacity: 1;
          }
        }
      }
    }

  }
}

// 220524 시험 추가 시작 =====
//1001 새롭게추가
.popup-content {
  max-width: fit-content !important;
  // max-height: 90%;
  // overflow-y: auto;
  width: 100% !important;
  padding: 0 !important;
  height: fit-content;

  .member_popup {
    width: 100%;
    max-width: 524px;
  }

  .computation-popup {
    width: 100%;
    max-width: 420px;
    height: 100%;
    max-height: 800px;
    overflow-y: auto;
    padding: 5px;
  }

  .browser-popup {
    width: 100%;
    max-width: 1200px;
    height: 100%;
    max-height: 800px;
    overflow-y: auto;
    padding: 5px;
  }

  // 220415 시험관련
  &.pop_exam-content {
    width: 100%;
    max-width: 1000px;
    height: 100%;
    max-height: 400px;
    overflow-y: auto;
    padding: 5px;

    // 220517 시험 일시정지
    &.pause-content {
      height: 100% !important;
      max-height: unset;
      overflow-y: unset;
    }
  }
}

// 220415 시험관련
.exam_bt_btn {
  text-align: center;
  min-width: 300px;
  margin-bottom: 30px;

  div {
    display: inline;
  }

  button {
    border: 1px solid #666;
    color: #666666;
    margin-right: 10px;
    line-height: 40px;
    padding: 0 20px;
    border-radius: 5px;
  }
}

.popup-content {
  &.pop_exam-content {
    height: auto;
    border-radius: 13px;
  }
}

.exam_pop {
  max-width: 660px !important;
  height: auto;
  overflow: auto;

  // 220517 시험 일시정지
  &.pause {
    max-width: 1080px !important;
    width: 1080px;
    height: 100%;

    .modal_body {
      margin-top: calc(35%);

      >div {
        display: block !important;
        text-align: center;
      }
    }
  }

  .modal_title {
    padding: 0 0 10px;
    font-size: 22px;
    color: #405F25;
    font-weight: 700;
    text-align: center;
  }

  .modal_body {
    >div {
      &:nth-child(2) {
        margin: 15px 0 0;
        padding: 15px 25px;
        border: 1px solid #ddd;
        border-radius: 13px;

        p {
          line-height: 35px;
        }
      }

      &:last-child {
        margin: 20px 0 0;
        display: flex;
        align-items: center;
        justify-content: end;

        button {
          float: none;
          margin: 0 0 0 10px;
          width: 140px;
          height: 40px;
          font-size: 14px;
          color: #fff;
          font-weight: 400;
          background: #55B87A;
          border-radius: 13px;
          text-shadow: none;
          opacity: 1;

          &:first-child {
            background: #1A9952;
          }

          &:hover {
            opacity: 1;
          }
        }
      }
    }

  }
}

//점수공개여부 저장
.openScoreBox {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin: 18px 0 10px 0;


  .boxLeft {
    display: flex;

    .openScoreBoxLeft {
      margin-right: 15px;
      font-weight: 700;
    }

    .openScoreBoxRight {
      margin-right: 15px;
      display: flex;
      align-items: center;
      justify-content: center;

      .socreRadio {
        .custom-checkbox {
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }

    .saveButton {
      padding: 4px 20px;
      color: #fff;
      font-size: 0.8rem;
      font-weight: 600;
      border-radius: 10px;
      cursor: pointer;
      background-color: #00B852;
    }
  }

  .boxRight {}


}

.learning_record_popup {
  border-radius: 10px;
  max-width: 1800px !important;
  overflow: auto;

  ._dataTable {
    border: 1px solid #ddd;
    border-radius: 13px;
    overflow: hidden;
  }


  .modal_title {
    margin-bottom: 20px;
    font-size: 20px;
  }

  table {
    width: 100%;
    table-layout: fixed;
    border: 1px solid #ddd;
    border-radius: 13px;
    overflow: hidden;

    th,
    td {
      border-right: 0 !important;
    }

    thead {
      th {
        background: #fff;
        color: #333;
        height: 50px;
        font-weight: 500;
      }
    }

    tbody {
      tr:nth-child(odd) {
        td {
          background: #F6F7FB;
        }
      }

      td {
        padding: 5px 10px;
        height: 20px;

        p {
          &.text-danger {
            color: #0B94EE !important;
          }
        }
      }
    }
  }

  .learning_record_close_button_container {
    margin-top: 20px;
    //margin-bottom: 20px;
    text-align: right;

    button {
      font-size: 14px;
      width: 140px;
      height: 40px;
      border-radius: 13px;
      //float: right;
      color: #fff;
      background: #55B87A;
    }
  }
}

.coursePop-overlay {
  .popup_content {
    height: fit-content
  }
}

.infosFlex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;

  .infosRed {

    color: #ff0000;
    font-weight: 700;
    line-height: 1.2rem;
    font-size: 0.9rem;
  }

  .audit_footer {
    padding-top: 0 !important;



  }
}

.content_edit {
  background-color: #2a9150 !important;
  color: #fff !important;
  font-weight: 700 !important;
  padding: 6px 23px !important;
  border-radius: 5px !important;
}



.survey_templete_popup {
  border-radius: 10px;
  max-width: 1800px !important;
  overflow: auto;
  min-width: 900px;
  min-height: 800px;
  padding: 30px;

  .modal_body_top_btn_title {
    display: flex;
    justify-content: space-between;
    align-items: center;


    &>button {
      text-indent: -9999px;
      overflow: hidden;
      width: 31px;
      height: 31px;
      background: url('#{$image-path}/modal_close_btn.png') no-repeat center;
      margin-bottom: 20px;
    }

  }

  ._dataTable {
    border: 1px solid #ddd;
    border-radius: 13px;
    overflow: hidden;
  }

  .modal_title {
    margin-bottom: 20px;
    font-size: 20px;
  }

  table {
    width: 100%;
    table-layout: fixed;
    border: 1px solid #ddd;
    border-radius: 13px;
    overflow: hidden;

    th,
    td {
      border-right: 0 !important;
    }

    thead {
      th {
        background: #fff;
        color: #333;
        height: 50px;
        font-weight: 500;
        // display: flex;
        // justify-content: center;
        // align-items: center;
        text-align: center;
        vertical-align: middle;
      }
    }

    tbody {
      tr:nth-child(odd) {
        td {
          background: #F6F7FB;
        }
      }

      td {
        padding: 5px 10px;
        height: 20px;
        text-align: center;
        vertical-align: middle;

        p {
          &.text-danger {
            color: #0B94EE !important;
          }
        }
      }
    }
  }

  .learning_record_close_button_container {
    margin-top: 20px;
    //margin-bottom: 20px;
    text-align: right;

    button {
      font-size: 14px;
      width: 140px;
      height: 40px;
      border-radius: 13px;
      //float: right;
      color: #fff;
      background: #55B87A;
    }
  }

  .select_button {
    font-size: 14px;
    width: 140px;
    height: 40px;
    border-radius: 13px;
    color: #fff;
    background: #55B87A;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
  }
}


.log_title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 0 20px 0;

  >h5 {
    margin-bottom: 0 !important;
  }


}