.modal-open {
  overflow: hidden;
}

.Myclass_list {
  position: relative;
  margin-top: 67px;

  .myContent_button {
    position: absolute;
    left: 0;
    top: -45px;


    @media screen and (max-width: 1500px) {
      right: auto;
      left: 0;
      top: -42px;
    }

    @media screen and (max-width: 768px) {
      top: -36px;
      right: auto;
      left: 0;
    }

    .left_button {
      float: left;
      background-color: #ececec;
      padding: 10px 50px;
      font-size: 1.1rem;
      cursor: pointer;

      &:hover {
        font-size: 1.1rem;
        background-color: #6eace5;
        color: #fff;
        font-weight: 700;
      }

      @media screen and (max-width: 768px) {
        padding: 10px 20px;
        font-size: 1em;
      }

    }

    .right_button {
      float: left;
      background-color: #ececec;
      padding: 10px 50px;
      margin-left: 10px;
      font-size: 1.1rem;
      cursor: pointer;

      &:hover {
        font-size: 1.1rem;
        background-color: #6eace5;
        color: #fff;
        font-weight: 700;
      }

      @media screen and (max-width: 768px) {
        padding: 10px 20px;
        font-size: 0.9em;
      }
    }

    .is_active {
      font-size: 1.1rem;
      background-color: #6eace5;
      color: #fff;
      font-weight: 700;

      @media screen and (max-width: 768px) {
        font-size: 1em;
      }
    }

  }

  .myContent_button:after {
    display: block;
    visibility: hidden;
    clear: both;
    content: '';
  }

  .programList {
    .programList__list {
      margin: 0;
      position: relative;

      .ended_class {
        position: absolute;
        top: -29px;
        left: 0;
        font-size: 0.9em;
        font-weight: 700;
        color: #1a9952;

        @media screen and (max-width: 1500px) {
          left: auto;
          right: 0;
        }

        @media screen and (max-width: 768px) {
          left: auto;
          right: 0;
          top: -19px;
          font-size: 0.6em;
        }


      }

      .programList__item {
        width: 100%;
        margin: 0;
        margin-bottom: 30px;
        border-radius: 5px;
        border: 1px solid #e9e9e9;

        .Mycontent_pop {
          position: fixed;
          z-index: 10000;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: rgba(0, 0, 0, 0.4);
          opacity: 1;
          visibility: visible;

          .inner_box {
            position: fixed;
            padding: 30px;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            border-radius: 10px;
            width: 90%;
            max-width: 800px;
            height: 600px;
            background-color: #fff;
          }

          .popClose {
            width: 100%;
            max-width: 100px;
            padding: 10px 20px;
            margin: 0 auto;
            background-color: #ce36c4;
            color: #fff;
            font-weight: 700;
            text-align: center;
            border-radius: 5px;
            cursor: pointer;

            &:hover {
              background-color: #9e1495;
            }
          }
        }

        .disnone {
          opacity: 0;
          visibility: hidden;
        }


        .hoverLayout {
          display: none;
        }

        .thumb {
          float: left;
          width: 30%;
          height: 100%;
          padding-top: 0;
          max-width: inherit;
          overflow: inherit;
          padding: 13px;
          background-color: #fff;

          @media screen and (max-width: 768px) {
            width: 100%;
            height: 226px;
          }


          a {
            display: block;

            img {
              height: 100%;
              position: static;
              height: 157px;

              @media screen and (max-width: 768px) {
                height: 226px;
              }

            }
          }


        }

        .info {
          float: left;
          width: 70%;
          position: relative;
          background: #fff;
          border: none;

          @media screen and (max-width: 768px) {
            width: 100%;
          }

          .playTime {
            position: absolute;
            right: 15px;

            .mini_title {
              font-weight: 700;
            }

            .playTime_value {
              display: inline-block;
              font-weight: 700;
              font-size: 2rem;
              color: #6eace5;
            }
          }

          .info_div_one {
            height: auto;
            margin: 0;
            padding: 0 0 0 20px;

            .chapter_prop {
              margin-top: 3px;
              font-size: 1em;
            }

            @media screen and (max-width: 768px) {
              padding-left: 0;
              padding-bottom: 20px;
            }

          }

          h2 {
            height: auto;
            font-weight: 700;

            width: 70%;

            a {
              display: inline;
              font-size: 1.05em;
            }
          }

          .bottom_button_box {
            width: 100%;
            margin-top: 14px;

            .mg12 {
              margin-left: 12px;
            }

            .left_button_confirm {
              float: left;
              border-radius: 100px;
              color: #fff;
              background-color: #489659;
              font-weight: 700;
              padding: 10px 20px;
              margin-top: 0;
              cursor: pointer;
            }

            .left_button_course {
              float: left;
              border-radius: 100px;
              margin-left: 10px;
              margin-top: 0;
              cursor: pointer;

              a {
                border-radius: 100px;
                display: block;
                color: #fff;
                background-color: #6eace5;
                font-weight: 700;
                padding: 10px 20px;
              }
            }

            .left_button_course2 {
              float: left;
              border-radius: 100px;
              margin-top: 0;
              cursor: pointer;

              a {
                border-radius: 100px;
                display: block;
                color: #fff;
                background-color: #6eace5;
                font-weight: 700;
                padding: 10px 20px;
              }
            }

            .left_button {
              float: left;
              border-radius: 100px;
              color: #fff;
              background-color: #489659;
              font-weight: 700;


              a {
                color: #fff;
                display: block;
                padding: 10px 20px;

              }
            }

            .left_active {
              background-color: #489659;
              color: #fff;
              font-weight: 700;
            }

            .right_button {
              float: left;
              margin-left: 12px;
              border-radius: 100px;
              background-color: #fff;
              border: 1px solid #a3a3a3;
              font-weight: 700;

              &.active {
                background: #6eace5;
                border: none;

                a {
                  color: white;
                }
              }
            }

            a {
              color: #a3a3a3;
              display: block;
              padding: 10px 30px
            }

          }

          .bottom_button_box:after {
            display: block;
            visibility: hidden;
            clear: both;
            content: '';
          }

        }

        .info_d {
          display: block;
        }

        a:after {
          display: block;
          visibility: hidden;
          clear: both;
          content: '';
        }


      }
    }
  }
}


.metaverseList {
  .metaverse__title {
    text-align: center;

    h2 {
      font-size: 1.75rem;

      @media screen and (max-width: 1220px) {}

      @media screen and (max-width: 768px) {
        font-size: 1.5rem;
      }
    }

    p {
      font-size: 1.125rem;
      margin-top: 10px;

      @media screen and (max-width: 1220px) {}

      @media screen and (max-width: 768px) {
        font-size: 1rem;
        margin-top: 5px;
      }
    }
  }

  .metaverse__list {
    // margin: 0 -19px;
    display: flex;
    flex-wrap: wrap;
    // margin-top: 2.5em;
    // margin: 0 -35px 0 -35px;
    justify-content: flex-start;
    gap: 15px;

    .null__list {
      margin: 0 auto;
    }

    @media screen and (max-width: 1220px) {
      margin: 0 -10px;
      margin-top: 2em;
      gap: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    @media screen and (max-width: 900px) {
      margin: 0 -10px;
      margin-top: 2em;
    }

    @media screen and (max-width: 768px) {
      margin: 0 -5px;
      margin-top: 2em;
      gap: 0px
    }



    @media screen and (max-width: 500px) {
      margin: 0 0px;
      margin-top: 2em;
    }

    .text-center {
      width: 100%;
    }
  }

  .metaverse__item {
    // margin: 0 19px;
    // width: calc(25% - 38px);
    flex: none;
    position: relative;
    overflow: hidden;
    border: 1px solid #DDDDDD;
    box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.07);
    border-radius: 4px 0 4px 4px;
    // margin: 0px 14px;
    width: 24%;
    margin-top: 14px;
    cursor: pointer;
    margin-bottom: 14px;

    .thumb {
      padding-top: 56.5%;
      max-width: 100%;
      position: relative;
      overflow: hidden;

      img {
        vertical-align: top;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;

      }
    }

    // &:nth-child(n + 5) {
    //   margin-top: 38px;
    // }

    @media screen and (max-width: 900px) {
      margin: 0 5px;
      width: calc(33.333% - 20px);

      &:nth-child(n + 4) {
        // margin-top: 20px;
      }
    }

    @media screen and (max-width: 768px) {
      margin: 0 5px;
      //width: calc(50% - 10px);
      width: calc(33% - 10px);
      margin-top: 5px;

      &:nth-child(n + 3) {
        //margin-top: 10px;
      }
    }

    @media screen and (max-width: 617px) {
      margin: 0 5px;
      //width: calc(50% - 10px);
      width: calc(50% - 10px);
      margin-top: 5px;

    }

    @media screen and (max-width: 500px) {
      margin: 0;
      width: 100%;
      margin-top: 5px;

      &:nth-child(n + 2) {
        // margin-top: 10px;
      }
    }

    .hoverLayout {
      position: absolute;
      left: 0;
      top: 120%;
      width: 100%;
      height: 55%;
      max-height: 160px;
      z-index: 10;
      background: rgba(0, 0, 0, 0.6);
      padding: 2.25em;
      color: $white;
      text-align: center;

      /*transition: all 0.5s;*/
      @media screen and (max-width: 768px) {
        display: none;
      }

      p {
        position: relative;
        top: 40%;
        font-size: 22px;
      }

      .section--business & {
        //background: $theme-red;
      }



      h3 {
        font-size: 1.75rem;
        line-height: 1.5em;
        font-weight: 400;
        word-break: keep-all;
        padding-bottom: 0.75em;
        margin-bottom: 0.75em;
        border-bottom: 1px solid $white;
      }

      li {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        font-size: 1rem;
        line-height: 2em;
      }

      &:after {
        content: '';
        /* background: url('#{$image-path}/hover_search.png') no-repeat center;*/
        background-size: 100% 100%;
        width: 37px;
        height: 37px;
        bottom: 2.25em;
        left: 50%;
        display: block;
        position: absolute;
        transform: translateX(-50%);
      }
    }

    a {
      color: $theme-black-2;
      position: relative;
      display: block;

      &:hover {
        .hoverLayout {
          top: 0;
        }
      }
    }

    .d_day {
      position: absolute;
      top: 0;
      left: 1em;
      z-index: 10;
      white-space: nowrap;
      background: $theme-red;
      color: $white;
      font-size: 1.5rem;
      line-height: 2em;
      padding: 0 1em;

      @media screen and (max-width: 1220px) {}

      @media screen and (max-width: 768px) {
        left: 0;
        font-size: 1.25rem;
      }
    }

    .thumb {
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;

        @media screen and (max-width: 500px) {
          width: 100%;
        }
      }

      .div_one_runing {

        position: absolute;
        right: 0px;
        top: 0px;
        z-index: 1;
        background-color: #00B852;
        width: 42px;
        height: 40px;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 0.85rem;
        font-weight: 700;
        border-radius: 0px;
      }

      .div_one_end {

        position: absolute;
        right: 0px;
        top: 0px;
        z-index: 1;
        background-color: #ddd;
        width: 42px;
        height: 40px;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 0.85rem;
        font-weight: 700;
        border-radius: 0px;
      }

      .div_one_prestart {
        position: absolute;
        right: 0px;
        top: 0px;
        z-index: 1;
        background-color: #FF845E;
        width: 42px;
        height: 40px;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 0.85rem;
        font-weight: 700;
        border-radius: 0px;
      }
    }

    .info {
      background: #fff;
      /*padding: 15px;*/
      // border: 1px solid $theme-gray-1;

      @media screen and (max-width: 1220px) {}

      @media screen and (max-width: 768px) {
        padding: 10px;
      }

      &.text-center {
        text-align: center;
        background: $white;

        // h2 {
        //   font-weight: 600;
        //   overflow: hidden;
        // }

      }

      div {
        // margin-top: 10px;
        // display: block;

      }

      .date_wrap {
        margin: 0px 15px 0px 15px;
        padding: 0 0 10px 0;
        display: flex;
        justify-content: space-between;
        align-items: center;

        &>div {
          display: flex;
          flex-direction: column;
          flex-basis: 100%;

          .date {
            display: flex;
            justify-content: space-between;

            &>span:nth-child(1) {
              color: #757575;
              text-align: left;
              font-size: 14px;
              flex-basis: 35%;
            }

            &>span:nth-child(2) {
              color: #333333;
              text-align: left;
              font-size: 14px;
              flex-basis: 55%;
            }
          }
        }
      }

      .info_div_one {
        .title_wrap {
          display: flex;
          justify-content: start;
          align-items: center;
          padding: 15px;
        }

        // margin: 10px 15px 20px 15px;
        h2 {
          font-size: 18px;
          line-height: 24px;
          height: auto;
          font-weight: 700;
          /* width: 210px; */
          word-break: keep-all;
          // padding: 17px 16px;
          // padding-bottom: 12px;
          overflow: hidden;
          white-space: pre;
          text-overflow: ellipsis;

          @media screen and (max-width: 1220px) {
            font-size: 18px;
            line-height: 25px;
            height: 50px;
          }

          @media screen and (max-width: 768px) {
            font-size: 16px;
          }
        }

        .secret_lock {
          margin-right: 10px;
          margin-left: 5px;
          position: relative;

          top: 2px;

        }

        .max_num {
          min-width: 51px;
          height: 20px;
          font-family: 'Pretendard';
          font-style: normal;
          font-weight: 600;
          font-size: 10px;
          line-height: 10px;
          text-align: center;
          color: #FFFFFF;
          background: #00863C;
          border-radius: 32px;
          padding: 5px;
        }

      }




      img {
        margin-right: 5px;
        position: relative;
        top: 2px;
      }

      .date {
        color: $theme-black-3;
        background-color: white;
        font-size: 15px;
        padding: 2px 0;
      }
    }

    .etc {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 50px;
      border: 1px solid $theme-gray-1;
      border-top: none;
      padding: 0 15px;

      @media screen and (max-width: 1220px) {}

      @media screen and (max-width: 768px) {
        display: block;
        height: auto;
        padding: 10px;
      }


      .status {
        color: $theme-red;
        border: 1px solid $theme-red;
        height: 30px;
        line-height: 30px;
        padding: 0 15px;
        font-size: 14px;
        border-radius: 5px;

        &.disabled {
          background: $theme-gray-9;
          border-color: $theme-gray-9;
          color: $white;
        }

        @media screen and (max-width: 1220px) {}

        @media screen and (max-width: 768px) {
          margin-top: 5px;
          display: block;
          width: 100%;
          text-align: center;
        }
      }
    }
  }
}


.consulting_write_box {
  width: 100%;

  .course_overlay_footer {
    margin: 0 !important;
    margin: 20px 0 35px 0 !important;

    .form-submit {
      background-color: #6eace5 !important;
    }

    .overlay_close {
      background-color: #fff !important;
      color: #3e3e3e !important;
      border: 1px solid #e4e4e4 !important;
    }
  }

  .consulting_write_wrap {
    width: 100%;
    border-top: 1px solid #000;
    border-bottom: 1px solid #ddd;

    .consulting_write {
      width: 100%;
      border-top: 1px solid #dddddd;

      &:after {
        display: block;
        visibility: hidden;
        clear: both;
        content: '';
      }

      .modal_sub_title {
        float: left;
        padding: 25px 30px;
        width: 30%;
        background-color: #f9f9f9;

        &:before {
          display: none;
        }

        @media screen and (max-width: 768px) {
          padding: 21px 9px;
          font-size: 0.9rem;
        }

      }

      .input_container {
        float: left;
        padding: 17px 20px;
        margin: 0 !important;
        width: 70%;
        font-size: 0.9rem;

        .form-control {
          border: 1px solid #000;
          padding: 0;
        }

        @media screen and (max-width: 768px) {
          padding: 13px 9px;
          font-size: 0.85rem;
        }

        .readonly_input {
          background-color: #fff;
          border: 1px solid #fff;
        }

        input {
          width: 100% !important;
          height: 41px;

          @media screen and (max-width: 1024px) {
            height: 35px;
          }
        }
      }

      .input_checkbox_box {
        margin-top: 20px;

        input {
          height: 13px;
        }

        .consulting_check {
          width: auto !important;
          display: inline !important;

          @media screen and (max-width: 768px) {
            transform: translateY(2px);
          }
        }

        label {
          font-weight: 700;
        }
      }
    }
  }

}

.consulting_write:nth-child(6) .modal_sub_title {
  height: 309px;

  @media screen and (max-width: 1024px) {
    height: 258px;
  }

  @media screen and (max-width: 550px) {
    height: 323px;
  }


  @media screen and (max-width: 370px) {
    height: 360px;
  }

}


.metaverse_null {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 100px;

  p {
    margin-top: 40px;
    font-family: 'Pretendard';
    font-style: normal;
    font-weight: 500;
    font-size: 25px;
    line-height: 30px;
    /* identical to box height, or 120% */
    color: #333333;
    text-align: center;
    line-height: 1.3;

    span {
      font-family: 'Pretendard';
      font-style: normal;
      font-weight: 500;
      font-size: 25px;
      line-height: 30px;
      color: #00863C;
    }
  }
}

.ins_group_wrapper {
  background: #FFFFFF;
  border: 1px solid #DDDDDD;
  border-radius: 4px;
  display: flex;
  min-height: 100px;
  flex-wrap: wrap;
  padding: 10px;
  margin-top: 10px;

  .ins_group_div {
    font-family: 'Pretendard';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #696969;
    height: fit-content;
    padding: 10px;
    display: flex;
    align-items: center;
    width: 25%;
    justify-content: center;

    button {
      margin-left: 10px;
      color: #00B852
    }
  }
}