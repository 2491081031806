//// KoreaTech _table.scss 파일
//
//.ptable {
//  td {
//    ul {
//      .sysli {
//        margin-bottom: 5px;
//        display: inline;
//        padding-right: 4px;
//      }
//    }
//  }
//  //border-top: 2px solid #333333;
//  border: 1px solid gainsboro;
//  word-break: keep-all;
//  overflow-x: hidden;
//
//
//  table {
//    width: 100%;
//    position: relative;
//    // border-collapse: unset;
//    //min-width: 1550px;
//  }
//
//  td {
//    font-size: 1rem;
//    border-top: 1px solid #ecf0f1;
//    //padding: 10px 3px;
//    padding: 10px 20px;
//    line-height: 1.4em;
//    color: #333333;
//    letter-spacing: -0.025em;
//    @media screen and (max-width: 1023px) {
//      padding: 0;
//      padding-right: 10px;
//      height: auto;
//
//      &:last-child {
//        border-bottom: 1px solid #ecf0f1;
//      }
//    }
//
//    a {
//      color: #888;
//    }
//
//    //button {
//    //  background: transparent;
//    //  color: #888;
//    //}
//    .default {
//      background: transparent;
//      color: #888;
//    }
//
//  }
//
//  //tr {
//  //  &.hover-effect:hover {
//  //    -webkit-animation: dialog-flyin .5s;
//  //    animation: dialog-flyin .5s;
//  //  }
//  //}
//
//  tr:nth-child(odd) {
//    td {
//
//      @media screen and (max-width: 1023px) {
//        background: #fff;
//      }
//    }
//  }
//
//  tr {
//    &.parent {
//      cursor: pointer;
//
//      td {
//        background: #f7f8fc;
//      }
//
//      &:hover {
//        td {
//          background: #eee;
//        }
//      }
//    }
//
//    &.child {
//      td {
//        background: #fff;
//      }
//    }
//
//    td {
//      &.empty {
//        &:before {
//          content: none;
//        }
//
//        color: #343a40;
//        font-weight: bold;
//        text-align: center;
//        padding: 3rem;
//      }
//    }
//  }
//
//  .bt {
//    tr {
//      &.parent {
//        td {
//          background: #fff;
//        }
//        &:hover {
//          td {
//            background: #eee;
//          }
//        }
//      }
//    }
//  }
//
//  th {
//    background: #fafafa;
//    height: 40px;
//    width: 100%;
//    //padding: 0 5px;
//    padding: 10px 20px;
//    @media screen and (max-width: 1023px) {
//      &:last-child {
//        border-bottom: 1px solid #fafafa;
//      }
//    }
//  }
//
//  .check-td {
//    text-align: center;
//    @media screen and (max-width: 1023px) {
//      height: 0;
//      &:before {
//        display: none;
//      }
//
//      .checkbox {
//        position: absolute;
//        left: 5px;
//        top: 10px;
//      }
//    }
//  }
//  .sysbtn {
//    color: #0079C2 !important;
//    border-color: #0079C2 !important;
//  }
//  .t-btn {
//    padding: 0 5px;
//    font-size: 14px;
//    border: 1px solid #d1d1d1;
//    border-radius: 3px;
//    background: #fff;
//    height: 30px;
//    line-height: 28px;
//    background: url('#{$image-path}/icon-arrow-black.png') #fff no-repeat calc(100% - 5px) center;
//    padding-right: 20px;
//    white-space: nowrap;
//
//    &:hover {
//      background: url('#{$image-path}/icon-arrow-black.png') #f4f4f4 no-repeat calc(100% - 5px) center;
//    }
//
//    &.btn-jupyter {
//      color: #eb6b1d;
//      border-color: #eb6b1d;
//      background: url('#{$image-path}/icon-arrow-orange.png') no-repeat calc(100% - 5px) center;
//
//      &:hover {
//        color: #fff;
//        background: url('#{$image-path}/icon-arrow-white.png') #eb6b1d no-repeat calc(100% - 5px) center;
//      }
//    }
//
//    &.btn-nextcloud {
//      color: #007bff;
//      border-color: #007bff;
//
//      &:hover {
//        color: #fff;
//        background: url('#{$image-path}/icon-arrow-white.png') #007bff no-repeat calc(100% - 5px) center;
//      }
//    }
//
//    @media screen and (max-width: 1023px) {
//      font-size: 12px;
//    }
//  }
//
//
//  .tt-btn {
//    padding: 5px 28px 5px 5px;
//    font-size: 14px;
//    border: 1px solid #b8c6d4;
//    border-radius: 3px;
//    background: #fff;
//    height: 30px;
//    line-height: 28px;
//    background: url('#{$image-path}/icon-arrow-black.png') #fff no-repeat calc(100% - 5px) center;
//    white-space: nowrap;
//
//    &.btn-gephi1{
//      background: url('#{$image-path}/icon_interface.png') no-repeat calc(100% - 5px) center;
//      margin-right: 3px;
//    }
//    &.btn-gephi2 {
//      background: url('#{$image-path}/icon_console.png') no-repeat calc(100% - 5px) center;
//      position: relative;
//    }
//    &.btn-nextcloud {
//      background: url('#{$image-path}/icon_file_manager.png') no-repeat calc(100% - 5px) center;
//    }
//
//    @media screen and (max-width: 1023px) {
//      font-size: 12px;
//    }
//  }
//
//
//
//  .ta-btn {
//    padding: 5px 28px 5px 5px;
//    font-size: 14px;
//    //border: 1px solid #b8c6d4;
//    border-radius: 3px;
//    background: #fff;
//    height: 30px;
//    line-height: 28px;
//    background: url('#{$image-path}/icon-arrow-black.png') #fff no-repeat calc(100% - 5px) center;
//    white-space: nowrap;
//
//    &.btn-nextcloud {
//      background: url('#{$image-path}/icon_file_manager.png') no-repeat calc(100% - 5px) center;
//    }
//
//    &.sysbtn {
//      background: url('#{$image-path}/icon_monitoring.png') no-repeat calc(100% - 5px) center;
//      border: none;
//      color: unset;
//    }
//
//    @media screen and (max-width: 1023px) {
//      font-size: 12px;
//    }
//  }
//
//
//  .label {
//    color: #fff;
//    padding: 0 10px;
//    height: 26px;
//    line-height: 26px;
//    min-width: 70px;
//    display: inline-block;
//    text-align: center;
//    border-radius: 2em;
//    font-size: 0.9rem;
//
//    &.running, &.Running, &.Completed{
//      //background: #1aa874;
//      background: #0079C2;
//    }
//
//    &.WARNINGS, &.InProgress {
//      background: #ffc107
//    }
//    &.stop, &.Pending, &.ERRORS, &.Failed, &.Deleting, &.Removed {
//      background: #e24141;
//    }
//
//    @media screen and (max-width: 1023px) {
//      font-size: 12px;
//    }
//  }
//}
//
//
//// 시스템 공지사항 수정삭제 버튼 스타일 시작 //
//.page-communications-notices .ptable table tbody td {
//  width: 60px;
//  min-width: 60px;
//}
//.page-communications-notices .ptable table tbody td .btn_up {
//  width: 40px;
//  height: 25px;
//  margin-left: 2.5px;
//  background: #0079c2;
//  color: #fff;
//}
//.page-communications-notices .ptable table tbody td .btn_del {
//  margin-left: 2.5px;
//  width: 40px;
//  height: 25px;
//  background: #d3001f;
//  color: #fff;
//}
//@media screen and (max-width: 414px) {
//  .page-communications-notices .ptable table tbody td .btn_up {
//    margin-left: 0px;
//    margin-bottom: 0px;
//  }
//}
//
//@media screen and (max-width: 768px) {
//  .page-communications-notices .ptable table tbody td .btn_up {
//    margin-left: 0px;
//    margin-bottom: 0px;
//  }
//}
//
//@media screen and (max-width: 1024px) {
//  .page-communications-notices table tbody td {
//    text-align: left !important;
//
//    .btn_up {
//      margin-bottom: 5px;
//    }
//  }
//  // word-break: break-all;
//}
//
//
//// 시스템 공지사항 수정삭제 버튼 스타일 끝 //
//
//
//// 이용안내 및 FAQ 수정삭제 스타일 시작 //
//.page-communications-faq {
//  .ptable table tbody td {
//    width: 60px;
//    min-width: 60px;
//  }
//  .ptable table tbody td .btn_up {
//    width: 40px;
//    height: 25px;
//    margin-left: 2.5px;
//    background: #0079c2;
//    color: #fff;
//  }
//  .ptable table tbody td .btn_del {
//    margin-left: 2.5px;
//    width: 40px;
//    height: 25px;
//    background: #d3001f;
//    color: #fff;
//  }
//  @media screen and (max-width: 414px) {
//    .page-communications-faq .ptable table tbody td .btn_up {
//      margin-left: 0px;
//      margin-bottom: 0px;
//    }
//  }
//
//  @media screen and (max-width: 768px) {
//    .page-communications-faq .ptable table tbody td .btn_up {
//      margin-left: 0px;
//      margin-bottom: 0px;
//    }
//  }
//
//  @media screen and (max-width: 1024px) {
//    .page-communications-faq table tbody td {
//      text-align: left !important;
//
//      .btn_up {
//        margin-bottom: 5px;
//      }
//    }
//    // word-break: break-all;
//  }
//}
//
//// 이용안내 및 FAQ 수정삭제 스타일 끝 //
//
//@media screen and (max-width: 1023px) {
//  .page-communications-notices table tbody td {
//    min-width: 181px !important;
//  }
//  // word-break: break-all;
//}
//
//@media screen and (max-width: 1023px) {
//  .page-communications-faq table tbody td {
//    min-width: 181px !important;
//  }
//  // word-break: break-all;
//}
//
//
//.table-volume {
//  &.bt tbody td::before {
//    padding-left: 30px;
//  }
//}
//


// KoreaTech _table.scss 파일

.ptable {
  td {
    ul {
      .sysli {
        margin-bottom: 5px;
        display: inline;
        padding-right: 4px;
      }
    }
  }

  //border-top: 2px solid #333333;
  border: 1px solid gainsboro;
  word-break: keep-all;
  overflow-x: hidden;


  table {
    width: 100%;
    position: relative;
    // border-collapse: unset;
    //min-width: 1550px;
  }

  td {
    font-size: 1rem;
    border-top: 1px solid #ecf0f1;
    //padding: 10px 3px;
    padding: 10px 20px;
    line-height: 1.4em;
    color: #333333;
    letter-spacing: -0.025em;

    @media screen and (max-width: 1023px) {
      padding: 0;
      padding-right: 10px;
      height: auto;

      &:last-child {
        border-bottom: 1px solid #ecf0f1;
      }
    }

    a {
      color: #888;
    }

    //button {
    //  background: transparent;
    //  color: #888;
    //}
    .default {
      background: transparent;
      color: #888;
    }

  }

  //tr {
  //  &.hover-effect:hover {
  //    -webkit-animation: dialog-flyin .5s;
  //    animation: dialog-flyin .5s;
  //  }
  //}

  tr:nth-child(odd) {
    td {

      @media screen and (max-width: 1023px) {
        background: #fff;
      }
    }
  }

  tr {
    &.parent {
      cursor: pointer;

      td {
        background: #f7f8fc;
      }

      &:hover {
        td {
          background: #eee;
        }
      }
    }

    &.child {
      td {
        background: #fff;
      }
    }

    td {
      &.empty {
        &:before {
          content: none;
        }

        color: #343a40;
        font-weight: bold;
        text-align: center;
        padding: 3rem;
      }
    }
  }

  .bt {
    tr {
      &.parent {
        td {
          background: #fff;
        }

        &:hover {
          td {
            background: #eee;
          }
        }
      }
    }
  }

  th {
    background: #fafafa;
    height: 40px;
    // width: 100%;
    //padding: 0 5px;
    padding: 10px 20px;

    @media screen and (max-width: 1023px) {
      &:last-child {
        border-bottom: 1px solid #fafafa;
      }
    }
  }

  .check-td {
    text-align: center;

    @media screen and (max-width: 1023px) {
      height: 0;

      &:before {
        display: none;
      }

      .checkbox {
        position: absolute;
        left: 5px;
        top: 10px;
      }
    }
  }

  .sysbtn {
    color: #0079C2 !important;
    border-color: #0079C2 !important;
  }

  .t-btn {
    padding: 0 5px;
    font-size: 14px;
    border: 1px solid #d1d1d1;
    border-radius: 3px;
    background: #fff;
    height: 30px;
    line-height: 28px;
    background: url('#{$image-path}/icon-arrow-black.png') #fff no-repeat calc(100% - 5px) center;
    padding-right: 20px;
    white-space: nowrap;

    &:hover {
      background: url('#{$image-path}/icon-arrow-black.png') #f4f4f4 no-repeat calc(100% - 5px) center;
    }

    &.btn-jupyter {
      color: #eb6b1d;
      border-color: #eb6b1d;
      background: url('#{$image-path}/icon-arrow-orange.png') no-repeat calc(100% - 5px) center;

      &:hover {
        color: #fff;
        background: url('#{$image-path}/icon-arrow-white.png') #eb6b1d no-repeat calc(100% - 5px) center;
      }
    }

    &.btn-nextcloud {
      color: #007bff;
      border-color: #007bff;

      &:hover {
        color: #fff;
        background: url('#{$image-path}/icon-arrow-white.png') #007bff no-repeat calc(100% - 5px) center;
      }
    }

    @media screen and (max-width: 1023px) {
      font-size: 12px;
    }
  }


  .tt-btn {
    padding: 5px 28px 5px 5px;
    font-size: 14px;
    border: 1px solid #b8c6d4;
    border-radius: 3px;
    background: #fff;
    height: 30px;
    line-height: 28px;
    background: url('#{$image-path}/icon-arrow-black.png') #fff no-repeat calc(100% - 5px) center;
    white-space: nowrap;

    &.btn-gephi1 {
      background: url('#{$image-path}/icon_interface.png') no-repeat calc(100% - 5px) center;
      margin-right: 3px;
    }

    &.btn-gephi2 {
      background: url('#{$image-path}/icon_console.png') no-repeat calc(100% - 5px) center;
      position: relative;
    }

    &.btn-nextcloud {
      background: url('#{$image-path}/icon_file_manager.png') no-repeat calc(100% - 5px) center;
    }

    @media screen and (max-width: 1023px) {
      font-size: 12px;
    }
  }



  .ta-btn {
    padding: 5px 28px 5px 5px;
    font-size: 14px;
    //border: 1px solid #b8c6d4;
    border-radius: 3px;
    background: #fff;
    height: 30px;
    line-height: 28px;
    background: url('#{$image-path}/icon-arrow-black.png') #fff no-repeat calc(100% - 5px) center;
    white-space: nowrap;

    &.btn-nextcloud {
      background: url('#{$image-path}/icon_file_manager.png') no-repeat calc(100% - 5px) center;
    }

    &.sysbtn {
      background: url('#{$image-path}/icon_monitoring.png') no-repeat calc(100% - 5px) center;
      border: none;
      color: unset;
    }

    @media screen and (max-width: 1023px) {
      font-size: 12px;
    }
  }


  .label {
    color: #fff;
    padding: 0 10px;
    height: 26px;
    line-height: 26px;
    min-width: 70px;
    display: inline-block;
    text-align: center;
    border-radius: 2em;
    font-size: 0.9rem;

    &.running,
    &.Running,
    &.Completed {
      //background: #1aa874;
      background: #0079C2;
    }

    &.WARNINGS,
    &.InProgress {
      background: #ffc107
    }

    &.stop,
    &.Pending,
    &.ERRORS,
    &.Failed,
    &.Deleting,
    &.Removed {
      background: #e24141;
    }

    @media screen and (max-width: 1023px) {
      font-size: 12px;
    }
  }
}


// 시스템 공지사항 수정삭제 버튼 스타일 시작 //
.page-communications-notices .ptable table tbody td {
  width: 60px;
  min-width: 60px;
}

.page-communications-notices .ptable table tbody td .btn_up {
  width: 40px;
  height: 25px;
  margin-left: 2.5px;
  background: #0079c2;
  color: #fff;
}

.page-communications-notices .ptable table tbody td .btn_del {
  margin-left: 2.5px;
  width: 40px;
  height: 25px;
  background: #d3001f;
  color: #fff;
}

@media screen and (max-width: 414px) {
  .page-communications-notices .ptable table tbody td .btn_up {
    margin-left: 0px;
    margin-bottom: 0px;
  }
}

@media screen and (max-width: 768px) {
  .page-communications-notices .ptable table tbody td .btn_up {
    margin-left: 0px;
    margin-bottom: 0px;
  }
}

@media screen and (max-width: 1024px) {
  .page-communications-notices table tbody td {
    text-align: left !important;

    .btn_up {
      margin-bottom: 5px;
    }
  }

  // word-break: break-all;
}


// 시스템 공지사항 수정삭제 버튼 스타일 끝 //


// 이용안내 및 FAQ 수정삭제 스타일 시작 //
.page-communications-faq {
  .ptable table tbody td {
    width: 60px;
    min-width: 60px;
  }

  .ptable table tbody td .btn_up {
    width: 40px;
    height: 25px;
    margin-left: 2.5px;
    background: #0079c2;
    color: #fff;
  }

  .ptable table tbody td .btn_del {
    margin-left: 2.5px;
    width: 40px;
    height: 25px;
    background: #d3001f;
    color: #fff;
  }

  @media screen and (max-width: 414px) {
    .page-communications-faq .ptable table tbody td .btn_up {
      margin-left: 0px;
      margin-bottom: 0px;
    }
  }

  @media screen and (max-width: 768px) {
    .page-communications-faq .ptable table tbody td .btn_up {
      margin-left: 0px;
      margin-bottom: 0px;
    }
  }

  @media screen and (max-width: 1024px) {
    .page-communications-faq table tbody td {
      text-align: left !important;

      .btn_up {
        margin-bottom: 5px;
      }
    }

    // word-break: break-all;
  }
}

// 이용안내 및 FAQ 수정삭제 스타일 끝 //

@media screen and (max-width: 1023px) {
  .page-communications-notices table tbody td {
    min-width: 181px !important;
  }

  // word-break: break-all;
}

@media screen and (max-width: 1023px) {
  .page-communications-faq table tbody td {
    min-width: 181px !important;
  }

  // word-break: break-all;
}


.table-volume {
  &.bt tbody td::before {
    padding-left: 30px;
  }
}