.header {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 998;
  //border-bottom: 1px solid $theme-gray-3;
  transition: all 0.5s;

  &:hover .gnb_button_bar_top {
    background-color: #489659;
  }

  @media screen and (max-width: 768px) {
    height: 50px;
  }

  .error {
    max-width: 100%;
    background: red;
    color: white;
    text-align: center;
  }

  .header__logo {
    display: flex;
    align-items: center;
    justify-content: center;

    .simbolLogos {
      display: none;
    }

    .defaultLogos {
      display: block;
    }

    @media screen and (max-width: 1220px) {
      // position: absolute;
      // top: 50%;
      // left: 30px;
      // transform: translateY(-50%);
    }

    @media screen and (max-width: 1024px) {

      .simbolLogos {
        display: block;
        width: 186px;

      }

      .defaultLogos {
        display: none;
      }

      img {
        height: 30px !important;
      }

      position: static;
    }

    img {
      height: 42px;
    }
  }

  .fixed & {
    background: $white;
  }

  .fixed {
    .header {
      .wrap {
        .link {
          color: black;
        }
      }
    }
  }

  &:after {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    top: calc(100%);
    height: 0;
    background: $white;
    z-index: 2;
    width: 100%;
    box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.1);
    transition: all 0.5s;

    @media screen and (max-width: 1024px) {
      display: none;
    }

  }

  .border_g_bottom {
    border-bottom: 1px solid #dee1e6;

    // @media screen and (max-width: 768px) {
    //   border: none;
    // }

  }

  @media screen and (min-width: 768px) {
    &:hover {
      background: $white;


      .header__top {
        .wrap {
          a {
            color: black;

            &~a {
              margin-left: 20px;
              padding-left: 20px;
              border-left: 1px solid black;
            }
          }

          span {
            color: black !important;
          }

          button {
            color: black;

            &~a {
              border-left: 1px solid black;
            }
          }
        }
      }

      .header__bottom {


        .header__gnb {
          .link {
            color: black;

          }

          .bold {
            font-weight: 600;
            color: #489659;
          }

          .active2 {}

          .active1 {
            position: relative;

            &:before {
              content: '';
              position: absolute;
              bottom: -15px;
              left: 0;
              width: 100%;
              height: 3px;
              background-color: #489659;
            }

          }


        }
      }

      &:after {
        height: 350px;
      }

      .depth {
        height: 350px !important;
        padding: 15px 0 !important;
      }
    }
  }
}

.header__top {
  @media screen and (max-width: 768px) {
    display: none;
  }

  .wrap {
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .xpState {
      margin-left: 19px;
      border-left: 1px solid #000;
      padding-left: 20px;

      span.xps {
        color: #489659 !important;
        font-weight: 600;
        font-size: 0.9rem;
      }
    }

    a {
      color: white;
      line-height: 1em;

      &~a {
        margin-left: 20px;
        padding-left: 20px;
        border-left: 1px solid white;
      }
    }

    button {
      color: white;

      &:hover {
        color: #000000 !important;
      }


      &~a {
        margin-left: 20px;
        padding-left: 20px;
        border-left: 1px solid white;
      }
    }

    span {
      &:hover {
        color: #000000 !important;
      }
    }

    .subsLink {
      display: inline-block;
      border-left: none !important;
      position: relative;

      &:hover {
        &+.removesSide {

          width: 148px;
          overflow: hidden;
        }
      }

      &::before {
        content: "";
        position: absolute;
        width: 1px;
        height: 17px;
        background-color: #000;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
      }

      .subsIcon {
        display: flex;
        align-items: center;
        justify-content: start;
        background: #333;
        border-radius: 100px;
        padding: 5px 12px;
        padding-left: 7px;

        &.active {
          background: #00823A;
        }

        .left {
          width: 12px;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 16px;
          border-radius: 100px;
          background-color: #fff;
          margin-right: 5px;
          height: 16px;

          img {
            width: 12px;
          }
        }

        .rights {
          color: #fff;
          font-size: 0.85rem;
          line-height: 0.85rem;
          display: flex;
          align-items: center;
        }

      }
    }

    .removesSide {

      display: inline-block;
      width: 0;
      overflow: hidden;
      transition: 0.8s ease;
      padding-left: 10px;

      .subsIcon {
        display: flex;
        align-items: center;
        justify-content: start;
        background: #333;
        border-radius: 100px;
        padding: 5px 12px;
        padding-left: 7px;

        &.removes {
          cursor: pointer;
          background-color: #DDDDDD;
          padding: 5px 12px;
          padding-left: 7px;

          .rights {
            color: #767676;
            white-space: pre;
          }
        }


        &.active {
          background: #00823A;

        }

        .left {
          width: 12px;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 16px;
          border-radius: 100px;
          background-color: #fff;
          margin-right: 5px;
          height: 16px;

          img {
            width: 12px;
          }
        }

        .rights {
          color: #fff;
          font-size: 0.85rem;
          line-height: 0.85rem;
          display: flex;
          align-items: center;
        }

      }


      &:hover {
        padding-left: 10px;
        width: 148px;
        overflow: hidden;

      }
    }


  }
}

.fixed {
  .header__top {
    .wrap {
      a {
        color: black;

        &~a {
          margin-left: 20px;
          padding-left: 20px;
          border-left: 1px solid black;
        }
      }

      span {
        color: black !important;
      }

      button {
        color: black;

        &~a {
          border-left: 1px solid black;
        }
      }
    }
  }

  .header__bottom {
    .wrap {
      .header__gnb {
        .link {
          color: black;
        }
      }
    }

  }
}

// .page{
.header__top {
  .wrap {
    a {
      color: black;

      &~a {
        margin-left: 20px;
        padding-left: 20px;
        border-left: 1px solid black;
      }
    }

    span {
      color: black !important;
    }

    button {
      color: black;

      &~a {
        border-left: 1px solid black;
      }
    }
  }
}

.header__bottom {
  .wrap {
    .header__gnb {
      .link {
        color: black;
      }


      .active1 {
        position: relative;

        &:before {
          content: '';
          position: absolute;
          bottom: -15px;
          left: 0;
          width: 100%;
          height: 3px;
          background-color: #489659;
        }
      }
    }
  }

}

// }

.border_g_bottom {}

.header__bottom {
  .mobile-button {
    width: 40px;
    height: 50px;
    font-size: 1.5rem;
    display: none;

    @media screen and (max-width: 1024px) {
      display: block;
      color: rgb(59, 59, 59);
    }


  }

  .wrap {
    height: 75px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media screen and (max-width: 1024px) {
      height: 50px;
      flex-wrap: wrap;
    }

    @media screen and (max-width: 550px) {
      padding: 0;
    }


    .mobile_nav_bottom {
      display: none;

      @media screen and (max-width: 1024px) {
        height: 70px;
        display: flex;
        flex-direction: column;
        background: #fff;
        width: 100%;

      }

      .mobile_bottom_top {
        width: 100%;
        overflow-x: auto;
        border-top: 1px solid #ddd;
        border-bottom: 1px solid #ddd;

        .mobile_top_nav {
          display: flex;
          gap: 20px;
          font-size: 16px;
          width: 100%;
          min-width: 450px;
          max-width: 1100px;
          padding: 10px;

          a {
            padding: 3px 5px;
          }
        }
      }


      .mobile_bottom_bottom {
        width: 100%;
        overflow-x: auto;
        border-bottom: 1px solid #ddd;

        .mobile_top_nav {
          display: flex;
          gap: 20px;
          font-size: 16px;
          width: 100%;
          min-width: 500px;
          max-width: 1100px;
          padding: 10px;

          a {
            padding: 3px 5px;
          }
        }
      }

    }
  }




  .header__gnb {
    position: relative;
    display: flex;
    margin-right: 0;
    margin-left: auto;
    padding-right: 30px;

    ._col {
      height: 75px;
      display: flex;
      align-items: center;
      position: relative;
    }



    .gnb_button_new {
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
      width: 25px;
      height: 15px;

      .gnb_button_bar_top {
        margin-top: 2px;
        width: 25px;
        height: 3px;
        background: #fff;
        border-radius: 10px;
      }


      .top_color_ac {
        background-color: #489659;
      }

      .bottom_color_ac {
        background-color: #000 !important;
      }


      .gnb_button_bar_bottom {
        margin-left: 12.5px;
        margin-top: 5px;
        width: 12.5px;
        height: 3px;
        background: #fff;
        border-radius: 10px;
      }


    }

    @media screen and (max-width: 1220px) {
      margin-right: 0;
    }

    @media screen and (max-width: 1024px) {
      display: none;
    }




    .link {
      display: block;
      white-space: nowrap;
      font-size: 1.1rem;
      color: white;
      padding: 0 1.9em;
      line-height: 45px;
      //border-radius: 2em;
      font-weight: 600;

      @media screen and (max-width: 1220px) {
        margin-left: 0.75em;
        font-size: 1.125rem;
        padding: 0 1.2em;
      }


      .active1 {
        border-bottom: 2px solid #489659;
        //border-bottom: 2px solid $theme-black-2;
      }

      //&.active {
      //  background: $theme-black-4;
      // color: $white;
      //}
    }

    .depth {
      position: absolute;
      top: 76px;
      background: $white;
      width: 100%;
      display: block;
      padding: 0;
      z-index: 3;
      height: 0;
      overflow: hidden;
      transition: all 0.5s;
      left: 0;

      a {
        display: block;
        text-align: center;
        line-height: 40px;
        font-size: .95rem;

        &:hover {
          font-weight: 600;
        }
      }

      .top_menu {
        cursor: pointer;
        display: block;
        text-align: center;
        line-height: 40px;
        font-size: .95rem;

        &:hover {
          font-weight: 600;
        }
      }
    }
  }
}

.header__msgcnt {
  background: #489659;
  height: 18px;
  width: 18px;
  display: inline-block;
  border-radius: 10px;
  text-align: center;
  font-size: 12px;
  vertical-align: middle;
}

.studio_popup_close {
  &:after {
    font-family: Helvetica, Arial, sans-serif;
    content: '\00D7';
    font-size: 40px;
    color: #222;
    font-weight: 200;
    line-height: 0px;
  }
}



// .page-home2 {
.header {
  transition: 0;
  background: #fff;
}

.display_none_header_top {
  display: none !important;

}

.header__top {
  @media screen and (max-width: 1024px) {
    display: none !important;
  }
}

// }