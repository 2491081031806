.contentNull {
  padding: 90px 0;
  font-size: 20px;
}

.home {

  .AiContent {
    background: #0A592D;
    display: flex;
    justify-content: space-around;
    color: #fff;
    position: relative;

    .setKeywordBox {
      border-radius: 13px;
      border: 1.5px dashed rgba(255, 255, 255, 0.20);
      background: rgba(59, 123, 87, 0.50);
      box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.10);
      width: 100%;
      position: relative;
      z-index: 1;
      padding: 20px;
      margin-right: 94px;

      @media screen and(max-width:1470px) {
        margin-right: 0;
      }

      @media screen and(max-width:960px) {
        margin-top: 20px;
      }

      .emptyBox {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
      }

      .no_keyword {
        width: 95px;
        margin-bottom: 16px;

        @media screen and (max-width: 550px) {
          width: 70px;
        }
      }

      .nokeyword_message {
        font-size: 1.1rem;
        color: #fff;
        margin-bottom: 30px;

        @media screen and (max-width: 550px) {
          margin-bottom: 20px;
        }


        .rcKeywords {
          font-size: 1.1rem;
          color: #FBC12A;
          font-weight: 700;
        }
      }

      .setKetwordButton {
        width: 270px;
        height: 45px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #FBC12A;
        color: #333;
        border-radius: 10px;
        font-size: 1rem;
        cursor: pointer;
        font-weight: 700;


        @media screen and (max-width: 550px) {
          width: 200px;
          height: 38px;
        }

      }
    }

    @media screen and(max-width:960px) {
      flex-direction: column;
    }

    .aibg {
      position: absolute;
      right: 0;
      bottom: 0;
      z-index: 1;
    }

    .Ai-gaiwrap {
      flex-basis: 20.5%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
      border: none;

      .pp-title {
        font-weight: 500;
        font-size: 22px;

        span {
          font-size: 24px;
          font-weight: 600;
        }
      }

      .title-wrapper {
        display: flex;
        justify-content: center;
        align-items: flex-end;

        h3 {
          font-size: 25px;
        }
      }

      video {
        width: 100%;
        min-width: 210px;
        max-width: 210px;
        -webkit-filter: contrast(101%);
        filter: contrast(101%);

        @media screen and(max-width:768px) {
          display: none;
        }
      }

      p {
        text-align: center;
        font-weight: 600;
        font-size: 18px;

        @media screen and(max-width:768px) {
          margin: 10px 0;
        }
      }

      .tb-si-gun-gu {
        margin-top: 40%;

        p {
          font-size: 1rem;
        }

        &>div {
          display: flex;
          justify-content: center;
          align-items: center;

          select {
            background: #073F1F;
            border-radius: 4px;
            color: #fff;
            padding: 8px;
            border: none;
            width: 50%;
            min-width: 150px;
            margin-top: 6px;

            &:nth-child(2) {
              margin-left: 10px;
            }
          }
        }

      }
    }

    .hidden-pp {
      overflow: hidden;
      position: relative;
    }




    &>div {
      display: flex;
      // flex-basis: 85%;
      width: 85%;
      align-items: center;
      justify-content: space-around;
      // overflow: hidden;

      @media screen and(max-width:1220px) {
        flex-direction: column;
        width: 95%;
      }

      @media screen and(max-width:960px) {
        width: 100%;
      }

      .pp-course-wrap {
        flex-basis: 95%;
        width: 95%;
        z-index: 3;
        min-width: 2350px;
        // margin-left: 960px;

        @media screen and(max-width:500px) {
          min-width: 1690px;
        }
      }

      .online-course-wrap,
      .offline-course-wrap {
        flex-basis: 95%;
        width: 95%;
        z-index: 3;

        @media screen and(max-width:1220px) {
          width: 90%;
        }

        @media screen and(max-width:960px) {
          width: 100%;
        }
      }

      .offline-course-wrap {
        @media screen and(max-width:1220px) {
          margin-top: 15px;
        }
      }

      .mb-si-gun-gu {
        margin-top: 30px;

        p {
          font-size: 1.3rem;
          text-align: center;
        }

        &>div {
          display: flex;
          justify-content: center;
          align-items: center;

          select {
            background: #073F1F;
            border-radius: 4px;
            color: #fff;
            padding: 8px;
            border: none;
            width: 50%;
            min-width: 160px;
            margin-top: 6px;

            &:nth-child(2) {
              margin-left: 10px;
            }
          }
        }
      }

      .online-course-wrap,
      .offline-course-wrap {

        .title-course {
          margin: 0 0 10px 0;
          display: flex;
          justify-content: space-between;
          align-items: flex-end;
          height: 45px;

          h2 {
            font-size: 18px;
          }

          .si-gun-gu-data {
            flex-basis: 50%;

            p {
              font-size: 14px;
            }

            &>div {
              display: flex;
              justify-content: center;
              align-items: center;

              select {
                background: #073F1F;
                border-radius: 4px;
                width: 100%;
                color: #fff;
                padding: 8px;
                border: none;
                width: 50%;
                margin-top: 6px;

                &:nth-child(2) {
                  margin-left: 10px;
                }
              }
            }
          }
        }

        .course-data {
          display: flex;
          justify-content: space-between;
          align-items: center;

          @media screen and(max-width:1024px) {
            flex-wrap: wrap;
            gap: 20px;
          }

          a {
            flex-basis: 15%;
            width: 15%;
            position: relative;

            @media screen and(max-width:1024px) {
              flex-basis: 30%;
              width: 30%;
            }

            @media screen and(max-width:600px) {
              flex-basis: 48%;
              width: 48%;

              &:nth-last-child(1) {
                display: none;
              }
            }


            &>div {
              background-color: #fff;
              color: #000;
              border-radius: 3px;
              overflow: hidden;
              width: 100%;
              height: 100%;
              // min-height: 210px;
              // max-height: 210px;
            }

            .idxCount {
              position: absolute;
              z-index: -1;
              font-size: 120px;
              bottom: -40px;
              left: -24%;
              -webkit-text-stroke: 0.5px #fff;
              color: #0A5A2D;
              font-weight: 600;

              // @media screen and (max-width:768px) {
              //   display: none;
              // }
            }
          }

          .course-data-wrap {

            .course-data-content-wrap {
              padding: 10px 15px;
              display: flex;
              justify-content: space-between;
              flex-direction: column;
              min-height: 100px;

              .course-Tt {
                margin: 3px 0;
                font-size: 15px;
                word-break: keep-all;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
              }

              .course-tx {
                margin: 3px 0;

                p {
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                  width: 100%;
                  height: 20px;

                  span {
                    font-size: 12px;
                  }


                  .course-gray-color {
                    color: #757575;
                    margin-right: 6px;
                  }
                }
              }
            }
          }
        }
      }

      .pp-course-wrap {

        .title-course {
          font-size: 18px;
          margin: 0 0 10px 0;
        }

        .top-course-wrap {
          width: 80%;
          // margin-top: 15px;

          .course-data {
            // display: flex;
            // justify-content: space-between;
            // align-items: center;
            // position: relative;

            .slick-slider {
              position: initial;

              button {
                z-index: 9999;
              }

              .slick-list {
                overflow: visible;
              }
            }

            a {
              flex-basis: 30%;
              width: 30%;
              position: relative;

              &>div {
                background-color: #fff;
                color: #000;
                border-radius: 3px;
                overflow: hidden;
                width: 100%;
                max-width: 195px;
                height: 100%;

                @media screen and(max-width:500px) {
                  max-width: 160px;
                }

                // min-height: 210px;
                // max-height: 210px;
              }

              @for $i from 1 to 100 {
                .idxCount#{$i} {
                  position: absolute;
                  font-size: 100px;
                  top: 110px;
                  right: calc(100% - 18px);
                  -webkit-text-stroke: 0.5px #fff;
                  color: #0A5A2D;
                  font-weight: 600;
                  z-index:#{95 - 2*($i)};

                  @media screen and(max-width:1024px) {
                    font-size: 90px;
                    right: calc(100% - 10px);
                  }

                  @media screen and(max-width:800px) {
                    font-size: 90px;
                    right: calc(100% - 10px);
                  }

                  @media screen and(max-width:500px) {
                    font-size: 80px;
                    right: calc(100% - 5px);
                  }
                }

                .tab#{$i} {
                  position: inherit;
                  z-index: #{95 - 2*($i) - 1};
                }
              }

              .idxCount10 {
                letter-spacing: -13px;
                right: calc(100% - 8px);
                font-size: 85px;
                top: 125px;

                @media screen and(max-width:500px) {
                  font-size: 80px;
                  right: calc(100% - 10px);
                }
              }

            }

            .course-data-wrap {

              .course-data-content-wrap {
                padding: 10px 15px;
                display: flex;
                justify-content: space-between;
                flex-direction: column;
                min-height: 100px;

                .course-Tt {
                  margin: 3px 0;
                  font-size: 15px;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                }

                .course-tx {
                  margin: 3px 0;

                  p {
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    width: 100%;
                    height: 20px;

                    span {
                      font-size: 12px;
                    }


                    .course-gray-color {
                      color: #757575;
                      margin-right: 6px;
                    }
                  }
                }
              }
            }
          }
        }

      }
    }
  }

  .card_slide_top {
    height: 25px;

    .slide_button {
      display: flex;
      position: absolute;
      top: 0;
      left: 5%;
      margin-bottom: 5px;

      @media screen and(max-width:1680px) {
        left: 1%;
      }
    }
  }



  .margin-0px {
    margin: 0 !important;
  }

  .margin-50 {
    margin-top: 5em;

    @media screen and (max-width:768px) {
      margin-top: 20px;
    }

  }

  .padding-30 {
    padding: 3em 10rem 3rem 7rem;

    @media screen and (max-width:768px) {
      padding: 3em 0.5em;
    }
  }

  .padding-3-3 {
    padding: 3em;

    @media screen and (min-width:2250px) {
      padding: 3em 14em;
    }

    @media screen and (max-width:768px) {
      padding: 3em 0.5em;
    }
  }

  .programBlock {
    margin-top: 5em;

    @media screen and (max-width: 768px) {
      padding-top: 30px;
      margin-top: 0;
    }


    &.blockColor {
      background-color: #F6F6F9;
      padding: 5em 0;

      .programList {
        .programList__item {
          .info {
            background: #fff;
          }
        }
      }

      .studyViewDiv {
        .studyViewBtn {
          background-color: #fff;
        }
      }

      @media screen and (max-width: 768px) {
        padding: 30px 0;
      }

    }

    // 공개 강좌 전체 보기
    .studyViewDiv {
      text-align: center;
      margin-top: 40px;

      .studyViewBtn {
        padding: 10px 25px 10px 25px;
        font-weight: bold;
        border: 1px solid #e5e5e5;
        display: inline-block;
      }

    }


  }

  //.intro__section.intro__section-2 {
  //  margin: 0;
  //}

  .intro__section.intro__section-2 {
    margin-top: 50px;
    padding-top: 60px;
    padding-bottom: 100px;

    @media screen and (max-width: 768px) {
      margin-top: 0;
      padding-top: 40px;
      margin: 0 10px;
      padding: 40px 10px;
      padding-bottom: 55px;

    }
  }

  // 메인화면 공지사항 팝업창
  .main_popup_container {
    position: fixed;
    width: fit-content;
    height: fit-content;
    min-width: 300px;
    min-height: 390px;
    z-index: 1000;
    background: white;
    padding: 0 20px 20px 20px;
    top: 70px;
    left: 50px;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
    border: 2px solid #489659;
    overflow: auto;

    @media screen and (max-width: 700px) {
      top: 30px !important;
      width: 90%;
      left: 50% !important;
      transform: translateX(-50%);
    }

    &.close {
      display: none;
    }

    .main_popup_header {
      border-bottom: 1px solid #48965949;
      //height: 10%;
      // position: fixed;
      height: auto;
      width: 100%;
      // max-width: 643px;
      background-color: #fff;

      .popup_title {
        padding: 12px 0;
        font-size: 18px;
        font-weight: 600;
        width: 95%;
        position: relative;
        padding-left: 20px;

        &::after {
          content: "";
          position: absolute;
          width: 15px;
          height: 15px;
          top: 50%;
          transform: translate(0, -50%);
          border-radius: 50%;
          left: 0;
          background: #00863C;
          z-index: 998;
        }

        &::before {
          content: "";
          position: absolute;
          width: 9px;
          height: 9px;
          top: 50%;
          transform: translate(0, -50%);
          border-radius: 50%;
          left: 3px;
          background: #fff;
          z-index: 999;
        }

        @media screen and (max-width: 700px) {
          padding-right: 45px;
        }
      }

      .overlay_close_x {
        position: absolute;
        //top: 25px;
        //right: 25px;
        top: 16px;
        right: 6px;
        background: none;
        width: 26px;
        height: 30px;
        text-decoration: none;

        &:after {
          font-family: Helvetica, Arial, sans-serif;
          content: '\00D7';
          font-size: 40px;
          color: #222;
          font-weight: 200;
          line-height: 0px;
        }
      }

    }

    .main_popup_body {
      //margin-top: 20px;
      margin: 30px 0 30px 0;
    }

    .main_popup_footer {
      padding-top: 20px;
      border-top: 1px solid #48965949;
      display: flex;
      justify-content: space-between;
      align-items: center;

      img {
        width: 25%;
      }

      .not_open_label {
        input {
          margin-right: 5px;
        }
      }
    }
  }


  .section {
    margin-top: 5em;

    @media screen and (max-width: 1200px) {
      margin-top: 3em;
    }

    .wrap {
      padding-top: 5em;
      border-top: 1px solid $theme-gray-1;

      @media screen and (max-width: 1200px) {
        padding-top: 3em;
      }
    }

    &.section--science {
      .wrap {
        padding: 0 10px;
        border: none;
      }
    }

    &.section--relation {
      .wrap {
        padding: 3.5em 0;

        @media screen and (max-width: 1200px) {
          padding-top: 2.5em;
        }
      }
    }
  }

  .programList__title h2 {
    font-size: 1.75rem;
    font-weight: 700;
  }

  .intro__section.intro__section-3 {
    margin-top: 50px;
    padding-top: 50px;
    height: 180px;
    background: linear-gradient(#efefef 50%, white 50%);
    background-size: 100% 5px;


    @media screen and (max-width: 756px) {
      height: 156px;
    }

    .bottom_boxes {
      display: flex;
      margin-right: 5px;
    }

    .univ_arrow {
      margin: 16px 15px;
    }

    // 하단 슬라이드
    .slick-slider {
      display: block;
      margin: 0 auto;
      width: 100%;

      @media screen and (max-width: 1024px) {
        width: 80% !important;
      }




      .slick-next {
        background: url('#{$image-path}/link_right2.png') no-repeat;
        color: #000;
        width: 14px;
        height: 28px;

        @media screen and (max-width: 550px) {
          display: none !important;
        }
      }

      .slick-next:before {
        display: none;
      }

      .slick-prev {
        background: url('#{$image-path}/link_left3.png') no-repeat;
        color: #000;
        width: 14px;
        height: 28px;

        @media screen and (max-width: 550px) {
          display: none !important;
        }
      }

      .slick-prev:before {
        display: none;
      }

      .slick-slide {
        div {
          background-color: white;
          margin: 0px 0px auto 0px;
          /*display: block;*/
          height: 80px;
          border-radius: 5px;
          /*line-height: 100px;
          vertical-align: middle;*/
          display: flex !important;
          align-items: center;


          @media screen and (max-width: 768px) {
            height: 60px;
          }
        }

        a {
          position: relative;
          display: block;
          width: 100%;
          height: 100%;

          img {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);

            @media screen and (max-width: 1024px) {
              max-width: 70% !important;
            }

            &.btm__univ {
              width: 55%;
            }
          }
        }



      }
    }

  }

}



// 로그인 페이지

.bg_login {
  height: 100vh;
  background: url('#{$image-path}/login/login_background.png') no-repeat;
  background-size: cover;
  max-width: 100% !important;
  padding-top: 0px;
  display: flex;
  justify-content: flex-end;

  .logo {
    position: absolute;
    top: 3%;
    left: 3%;
  }

  .gjudec_text {
    flex-basis: 70%;
    display: flex;
    justify-content: center;
    align-items: center;

    .gjudec_text_content {
      display: flex;
      height: 50vh;
      flex-direction: column;
      justify-content: center;

      h2 {
        color: #fff;
        text-align: left;
        font-size: 3.1rem;
        font-weight: 400;

        span {
          font-size: 3.1rem;
          font-weight: 700;
        }
      }

      h4 {
        color: #fff;
        text-align: left;
        font-size: 1.7rem;
        margin-top: 20px;
      }
    }
  }

  .conts_body {
    min-width: 600px !important;
    // flex-basis: 40%;
    height: 100vh !important;
    background: url('#{$image-path}/login/login_form_bg.svg') no-repeat;
    background-size: cover;
    color: #fff !important;
    display: flex;
    justify-content: center !important;
    align-items: center !important;
  }

  .login-main {

    .login_wrap {
      input {
        background-color: #5EB292 !important;
        border: none !important;
        color: #fff !important;

        &::placeholder {
          color: #fff;
        }
      }

      .members {
        padding: 0 !important;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: -20px;

        a {
          color: #fff !important;
          font-size: 14px;
        }
      }

      form {
        .btn_submit_wrap {
          .btn_submit {
            background-color: #282828 !important;
            border-radius: 6px;
          }
        }

        .form-group {
          margin-bottom: 0.5rem;
        }

        .form-checkbox {
          display: flex;
          justify-content: flex-start;
          align-items: center;


          .loginCheckBox {
            flex-basis: 5%;
            background: url('#{$image-path}/login/check.svg') no-repeat;
            height: auto !important;
          }

          label {
            color: #fff !important;
            margin-left: 10px;
          }
        }
      }
    }

    .form-wrapper {
      text-align: center;
      max-width: 280px !important;

      .login_notice {
        display: flex;
        background: #022f276e;
        // opacity: 0.6;
        border-radius: 6px;
        padding: 10px 20px;
        margin-bottom: 15px;

        p {
          font-size: 14px;
          text-align: left;
          padding-left: 15px;
        }
      }
    }

    .logo_simbol {
      text-align: center;
    }

    h2 {
      margin-top: 0 !important;
      color: #fff !important;
    }
  }
}

@media (max-width:1200px) {

  .gjudec_text {
    .gjudec_text_content {

      h2 {
        font-size: 2.4rem !important;

        span {
          font-size: 2.4rem !important;
        }
      }

      h4 {
        font-size: 1.3rem !important;
      }
    }
  }
}

@media (max-width:1000px) {
  .gjudec_text {
    display: none !important;
  }

  .bg_login {

    .conts_body {
      min-width: 100% !important;
      background-size: cover;
    }
  }

}


// 약관 스타일
.privacy_wrap {
  width: 95%;
  max-width: 1100px;
  margin: 150px auto 0 auto;
  // word-break: keep-all;

  .privacy_title {
    padding: 50px 0 20px 0;
    display: flex;
    align-items: center;

    h2 {
      font-size: 1.5rem;
    }

    span {
      width: 20px;
      height: 20px;
      border-radius: 50%;
      background-color: #00863C;
      margin-right: 5px;
      position: relative;

      &::after {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        border-radius: 50%;
        transform: translate(-50%, -50%);
        width: 10px;
        height: 10px;
        background: #fff;
      }
    }
  }

  .privacy_section {
    margin: 20px 0;

    p {
      margin: 5px 0;
      font-size: 1rem;
      word-break: keep-all;
    }

    .section_title {
      display: flex;
      align-items: center;

      h3 {
        font-size: 1.3rem;
      }

      span {
        width: 16px;
        height: 16px;
        border-radius: 50%;
        background-color: #00863C;
        margin-right: 5px;
        position: relative;

        &::after {
          content: "";
          position: absolute;
          top: 50%;
          left: 50%;
          border-radius: 50%;
          transform: translate(-50%, -50%);
          width: 8px;
          height: 8px;
          background: #fff;
        }
      }
    }

    .num_title {
      margin: 20px 0 0 0;
      color: #00863C;
      font-weight: 600;
    }

    .consignment {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 20px;
      border: 1px solid #ddd;
      border-radius: 10px;

      &>div {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;

        &>div {
          &:nth-child(1) {
            border-right: 1px solid #ddd;
          }

          &:nth-child(2) {
            border-right: 1px solid #ddd;
          }
        }
      }

      .consignment_top {
        border-bottom: 1px solid #ddd;

        div {
          flex-basis: 100%;
          height: 100%;
          min-height: 50px;
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: rgba(243, 243, 243, 0.4);
        }
      }

      .consignment_body {
        div {
          flex-basis: 100%;
          height: 100%;
          min-height: 150px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
        }
      }

      // margin-left: 10px;

      // &>div {
      //   display: flex;

      //   &>div {
      //     margin-left: 6px;
      //   }
      // }
    }

    .responsible {
      margin: 20px 0;
      border: 1px solid #ddd;
      border-radius: 10px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      &>div {

        &:nth-child(1) {
          border-right: 1px solid #ddd;
        }

        &:nth-child(2) {
          border-right: 1px solid #ddd;
        }


        &>div {
          border-bottom: 1px solid #ddd;
          min-height: 50px;
          height: 100%;
          width: 100%;

          &:nth-last-child(1) {
            border-bottom: none;
          }
        }
      }

      .responsible_left {
        flex-basis: 20%;

        &>div {
          display: flex;
          justify-content: center;
          align-items: center;

          &:nth-child(1) {
            background-color: rgba(243, 243, 243, 0.4);
          }
        }
      }

      .responsible_center {
        flex-basis: 40%;

        &>div {
          display: flex;
          justify-content: center;
          align-items: center;

          &:nth-child(1) {
            background-color: rgba(243, 243, 243, 0.4);
          }
        }
      }

      .responsible_right {
        flex-basis: 40%;

        &>div {
          display: flex;
          justify-content: center;
          align-items: center;

          &:nth-child(1) {
            background-color: rgba(243, 243, 243, 0.4);
          }
        }
      }
    }


    .salvation {
      margin: 20px 0;
      border: 1px solid #ddd;
      border-radius: 10px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      overflow-x: scroll;

      &>div {
        min-width: 250px;

        &:nth-child(1) {
          border-right: 1px solid #ddd;
        }

        &:nth-child(2) {
          border-right: 1px solid #ddd;
        }

        &:nth-child(3) {
          border-right: 1px solid #ddd;
        }


        &>div {
          border-bottom: 1px solid #ddd;
          min-height: 70px;
          height: 100%;
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 1rem;
          word-break: keep-all;
          padding: 0 10px;
          text-align: center;

          &:nth-last-child(1) {
            border-bottom: none;
          }

          &:nth-child(1) {
            background-color: rgba(243, 243, 243, 0.4);
          }
        }
      }
    }
  }

  .section_content_box_wrap {
    border: 1px solid #ddd;
    border-radius: 10px;
    padding: 20px;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 20px;

    p {
      flex-basis: 50%;
    }
  }
}

input:read-only {
  background-color: #93ffab15;
  border: none;
}



// 장애 접수
.disability_content {
  flex: 1 1;

  .disability_title {
    text-align: center;
    margin: 20px 0;

    span {
      font-size: 2rem;
      line-height: 1.5em;
      padding-bottom: 15px;
      position: relative;
    }
  }
}

.outlineTabWrap {
  display: flex;
  justify-content: center;
  padding: 0 10px;
  padding-top: 20px;
}

.outlineTabWrap--border {
  .outlineTab {
    width: 25%;
    border: none;
    // border-bottom: 1px solid #ddd;
    margin: 0;
    border-radius: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 55px;
    font-size: 18px;
    color: #333;
    word-break: keep-all;

    @media screen and (max-width: 1024px) {
      height: 32px;
      font-size: 0.9rem;
    }

  }

  .outlineTab.active {
    border-bottom: 3px solid #489659;
    background: none;
    color: #333;
    font-weight: 600;
  }
}

.stopBtn {
  position: absolute;
  top: -3px;
  left: 90px;
  width: 22px;
  height: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
}


.section--relation {
  display: none;
}

.swiper-wrapper {
  padding-left: 40px;
}

.course-data-img-wrap {
  img {
    width: 100%;
    height: 100%;
    max-height: 117px;
    min-height: 117px;
  }
}




// faq 스타일
.faq_content_wrap {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  border-radius: 4px;

  span {
    flex-basis: 100%;
    width: 100%;
  }

  &>div {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;

    button {
      &:nth-child(1) {
        background-color: #489659;
        border-radius: 4px;
        color: #fff;
        margin: 0 3px;
        padding: 3px 15px !important;
        width: auto !important;
      }

      &:nth-child(2) {
        background-color: #F46E43;
        border-radius: 4px;
        color: #fff;
        margin: 0 3px;
        padding: 3px 15px !important;
        width: auto !important;
      }
    }
  }
}


.flexCont {

  .access_error,
  .nofind_error {

    .description {
      max-width: 410px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 10px;

      img {
        width: 100%;
        max-width: 205px;
      }
    }

    .title {
      margin-bottom: 20px;
      text-align: center;

      .titleTop {
        color: #1a9952;
        font-size: 1.4rem;
        display: block;
        line-height: 1.4rem;
        margin-bottom: 4px;
        text-align: center;
      }

      .titleBottom {
        color: #696969;
        font-size: 1.2rem;
      }
    }

    .sub_description {
      margin-bottom: 30px;
      color: #696969;
      font-size: 0.9rem;
      text-align: center;
    }

    .link {
      display: flex;
      align-items: center;
      justify-content: center;

      .before_btn {
        border: 1px solid #ddd;
        width: 200px;
        height: 40px;
        color: #e9e9e9;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .home_btn {
        margin-left: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid #1a9952;
        width: 200px;
        height: 40px;
        color: #fff;
        background-color: #1a9952;
      }
    }
  }
}


// .courseHeader {
//   position: sticky;
//   top: -10px;
//   width: 100%;
//   background: #fff;
//   z-index: 9999;

//   .top_submit {
//     position: absolute;
//     width: 100px;
//     right: 75px;
//     top: 5px;
//     padding: 3px 5px;
//     background-color: #489659;
//     color: #fff;
//     border-radius: 4px;
//   }

//   .close {
//     top: 5px !important;
//     display: flex;
//     justify-content: center;
//     align-items: center;
//   }
// }