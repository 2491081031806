$public-url: 'https://gj.udec.kr';
$image-path: '#{$public-url}/assets/images';

@keyframes ripple {
  100% {
    opacity: 0;
    transform: scale(5);
    animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  }
}

@keyframes checkRipple {
  100% {
    opacity: 0.2;
    transform: scale(2);
    animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  }
}

/*applied to the timepicker*/
.jqx-rc-tl-material {
  -moz-border-radius-topleft: 2px;
  -webkit-border-top-left-radius: 2px;
  border-top-left-radius: 2px;
}

.jqx-rc-tr-material {
  -moz-border-radius-topright: 2px;
  -webkit-border-top-right-radius: 2px;
  border-top-right-radius: 2px;
}

.jqx-rc-bl-material {
  -moz-border-radius-bottomleft: 2px;
  -webkit-border-bottom-left-radius: 2px;
  border-bottom-left-radius: 2px;
}

.jqx-rc-br-material {
  -moz-border-radius-bottomright: 2px;
  -webkit-border-bottom-right-radius: 2px;
  border-bottom-right-radius: 2px;
}

.jqx-rc-t-material {
  -moz-border-radius-topleft: 2px;
  -webkit-border-top-left-radius: 2px;
  border-top-left-radius: 2px;
  -moz-border-radius-topright: 2px;
  -webkit-border-top-right-radius: 2px;
  border-top-right-radius: 2px;
}

.jqx-rc-b-material {
  -moz-border-radius-bottomleft: 2px;
  -webkit-border-bottom-left-radius: 2px;
  border-bottom-left-radius: 2px;
  -moz-border-radius-bottomright: 2px;
  -webkit-border-bottom-right-radius: 2px;
  border-bottom-right-radius: 2px;
}

.jqx-rc-r-material {
  -moz-border-radius-topright: 2px;
  -webkit-border-top-right-radius: 2px;
  border-top-right-radius: 2px;
  -moz-border-radius-bottomright: 2px;
  -webkit-border-bottom-right-radius: 2px;
  border-bottom-right-radius: 2px;
}

.jqx-rc-l-material {
  -moz-border-radius-topleft: 2px;
  -webkit-border-top-left-radius: 2px;
  border-top-left-radius: 2px;
  -moz-border-radius-bottomleft: 2px;
  -webkit-border-bottom-left-radius: 2px;
  border-bottom-left-radius: 2px;
}

.jqx-rc-all-material {
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
}

.jqx-widget-material {
  font-family: "Roboto", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 14px;
  font-family: "Roboto", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 14px;
  color: #555;

  input {
    &::selection {
      background: #0077BE;
      color: #fff;
    }
  }

  .jqx-grid-group-expand-material {
    background-image: url('#{$image-path}/jqx.material/material-icon-down.png');
    background-repeat: no-repeat;
    background-position: center;
  }

  .jqx-grid-group-collapse-material {
    background-image: url('#{$image-path}/jqx.material/material-icon-right.png');
    background-repeat: no-repeat;
    background-position: center;
  }
}

.jqx-widget-header-material {
  font-family: "Roboto", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 14px;
  background-color: #fff;
  border-color: #E0E0E0;
  color: #757575;
  font-weight: 500;
  *zoom: 1;
  font-family: "Roboto", "Helvetica Neue", Helvetica, Arial, sans-serif;
  background: #fff;
  color: #757575;
  font-size: 14px;
}

.jqx-fill-state-normal-material {
  font-family: "Roboto", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 14px;
  border-color: #0077BE;
  *zoom: 1;
  background: #fafafa;
}

.jqx-widget-content-material {
  font-family: "Roboto", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 14px;
  font-family: "Roboto", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 14px;
  color: #555;
  background-color: #ffffff;
  border-color: #E0E0E0;

  input {
    &::selection {
      background: #0077BE;
      color: #fff;
    }
  }
}

.jqx-fill-state-hover-material {
  font-family: "Roboto", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 14px;
  text-decoration: none;

  .jqx-editor-toolbar-icon-material {
    background: url('#{$image-path}/jqx.material/html_editor_white.png') no-repeat;
  }

  .jqx-editor-toolbar-icon-fontsize-material {
    background: url('#{$image-path}/jqx.material/html_editor.png') no-repeat;
  }

  .jqx-editor-toolbar-icon-forecolor-material {
    background: url('#{$image-path}/jqx.material/html_editor.png') no-repeat;
  }
}

.jqx-fill-state-pressed-material {
  font-family: "Roboto", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 14px;
  background-image: none;
  outline: 0;

  .jqx-icon-arrow-first-selected-material {
    background-image: url('#{$image-path}/jqx.material/material-icon-first-white.png');
    background-repeat: no-repeat;
    background-position: center;
  }

  .jqx-icon-arrow-last-selected-material {
    background-image: url('#{$image-path}/jqx.material/material-icon-last-white.png');
    background-repeat: no-repeat;
    background-position: center;
  }

  .jqx-icon-arrow-left-selected-material {
    background-image: url('#{$image-path}/jqx.material/material-icon-left-white.png');
    background-repeat: no-repeat;
    background-position: center;
  }

  .jqx-icon-arrow-right-selected-material {
    background-image: url('#{$image-path}/jqx.material/material-icon-right-white.png');
    background-repeat: no-repeat;
    background-position: center;
  }

  .jqx-icon-delete-material {
    background-image: url('#{$image-path}/jqx.material/icon-delete-white.png');
  }

  .jqx-icon-edit-material {
    background-image: url('#{$image-path}/jqx.material/icon-edit-white.png');
  }

  .jqx-icon-save-material {
    background-image: url('#{$image-path}/jqx.material/icon-save-white.png');
  }

  .jqx-icon-cancel-material {
    background-image: url('#{$image-path}/jqx.material/icon-cancel-white.png');
  }

  .jqx-icon-search-material {
    background-image: url('#{$image-path}/jqx.material/search_white.png');
  }

  .jqx-icon-plus-material {
    background-image: url('#{$image-path}/jqx.material/plus_white.png');
  }

  .jqx-menu-minimized-button-material {
    background-image: url('#{$image-path}/jqx.material/icon-menu-minimized-white.png');
  }

  .jqx-editor-toolbar-icon-material {
    background: url('#{$image-path}/jqx.material/html_editor_white.png') no-repeat;
  }

  .jqx-editor-toolbar-icon-fontsize-material {
    background: url('#{$image-path}/jqx.material/html_editor.png') no-repeat;
  }

  .jqx-editor-toolbar-icon-forecolor-material {
    background: url('#{$image-path}/jqx.material/html_editor.png') no-repeat;
  }
}

input.jqx-input-widget-material {
  &::selection {
    background: #0077BE;
    color: #fff;
  }
}

.jqx-toolbar-material {
  border-color: #e0e0e0;
}

.jqx-button-material {
  color: #fff;
  background: #0077BE;
  border-color: #0077BE;
  *zoom: 1;
  outline: none;
  transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1), -webkit-box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
  border-radius: 2px;

  button {
    background: transparent;
    color: inherit;
    border: none;
    outline: none;
  }
}

jqx-button-material.jqx-fill-state-normal-material {
  color: #fff;
  background: #0077BE;
  border-color: #0077BE;
  *zoom: 1;
  outline: none;
  transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1), -webkit-box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
  border-radius: 2px;
}

jqx-button-material {
  input {
    background: transparent;
    color: inherit;
    border: none;
    outline: none;
  }
}

.jqx-slider-button-material {
  padding: 3px;
  background: transparent;
  border: transparent;
  -webkit-transition: background-color 100ms linear;
  -moz-transition: background-color 100ms linear;
  -o-transition: background-color 100ms linear;
  -ms-transition: background-color 100ms linear;
  transition: background-color 100ms linear;
  -moz-border-radius: 100%;
  -webkit-border-radius: 100%;
  border-radius: 100%;
}

.jqx-button-material.float {
  border-radius: 100%;
  min-height: 48px;
  min-width: 48px;
  width: 48px;
  height: 48px;
  max-height: 48px;
  max-width: 48px;
}

.jqx-button-material.outlined {
  background: transparent;
  color: #0077BE;
  border-width: 2px;
}

.jqx-button-material.flat {
  background: transparent;
  color: #0077BE;
  border: none;
}

.jqx-fill-state-focus-material {
  text-decoration: none;
  outline: none;
}

.jqx-expander-header.jqx-fill-state-hover-material {
  background: #fff;
  border-color: #e0e0e0;
  background: #F5F5F5;
}

.jqx-expander-header.jqx-fill-state-normal-material {
  background: #fff;
  border-color: #e0e0e0;
}

.jqx-expander-header.jqx-fill-state-pressed-material {
  background: #fff;
  border-color: #e0e0e0;
}

.jqx-expander-header-material {
  padding: 10px;
  padding-top: 10px;
  padding-bottom: 10px;

  .jqx-icon-arrow-down {
    transform: rotate(0deg);
    transition: transform 0.2s ease-out;
  }

  .jqx-icon-arrow-up {
    transform: rotate(180deg);
    transition: transform 0.2s ease-out;
    background-image: url('#{$image-path}/jqx.material/material-icon-down.png');
  }
}

.jqx-button-material.jqx-fill-state-hover {
  opacity: 0.9;
  cursor: pointer;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  *zoom: 1;
}

.jqx-button-material.jqx-fill-state-hover.outlined {
  color: #0077BE;
  box-shadow: none;
}

.jqx-button-material.jqx-fill-state-hover.flat {
  color: #0077BE;
  box-shadow: none;
}

.jqx-button-material.jqx-fill-state-pressed {
  cursor: pointer;
  background: #0077BE;
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.jqx-button-material.jqx-fill-state-pressed.float {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}

.jqx-slider-button-material.jqx-fill-state-pressed-material {
  background: rgba(179, 229, 252, 0.15);
  box-shadow: none;
  color: #0077BE;
  background: transparent !important;
}

.jqx-button-material.jqx-fill-state-pressed.outlined {
  background: rgba(179, 229, 252, 0.15);
  box-shadow: none;
  color: #0077BE;
}

.jqx-button-material.jqx-fill-state-pressed.flat {
  background: rgba(179, 229, 252, 0.15);
  box-shadow: none;
  color: #0077BE;
}

.jqx-button-material.jqx-fill-state-focus {
  background: #0069A8;
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.jqx-slider-button-material.jqx-fill-state-focus-material {
  background: transparent;
  border-color: transparent;
  box-shadow: none;
}

.jqx-button-material.jqx-fill-state-focus.outlined {
  box-shadow: none;
  background: rgba(99, 0, 238, 0.15);
  color: #0069A8;
}

.jqx-button-material.jqx-fill-state-focus.flat {
  box-shadow: none;
  background: rgba(99, 0, 238, 0.15);
  color: #0069A8;
}

.jqx-dropdownlist-state-normal-material {
  -webkit-transition: background-color 100ms linear;
  -moz-transition: background-color 100ms linear;
  -o-transition: background-color 100ms linear;
  -ms-transition: background-color 100ms linear;
  transition: background-color 100ms linear;
  background: #fff;
  border-color: #fff;
  border-radius: 0;
  box-shadow: none;
  border-bottom: 1px solid #E0E0E0;
  outline: none;
  overflow: visible !important;

  > div {
    overflow: visible !important;

    > div {
      overflow: visible !important;
    }
  }

  .jqx-icon-arrow-down-material {
    transform: rotate(0deg);
    transition: transform 0.2s ease-out;
  }
}

.jqx-dropdownlist-state-hover-material {
  -webkit-transition: background-color 100ms linear;
  -moz-transition: background-color 100ms linear;
  -o-transition: background-color 100ms linear;
  -ms-transition: background-color 100ms linear;
  transition: background-color 100ms linear;
}

.jqx-dropdownlist-state-selected-material {
  -webkit-transition: background-color 100ms linear;
  -moz-transition: background-color 100ms linear;
  -o-transition: background-color 100ms linear;
  -ms-transition: background-color 100ms linear;
  transition: background-color 100ms linear;
  color: #0077BE;

  .jqx-input-label {
    top: -15px;
    font-size: 12px;
    color: #0077BE;
  }

  .jqx-input-bar {
    &:before {
      width: 100%;
    }
  }

  .jqx-icon-arrow-down-material {
    transform: rotate(180deg);
    transition: transform 0.2s ease-out;
    left: -1px;
  }
}

.jqx-scrollbar-button-state-hover-material {
  -webkit-transition: background-color 100ms linear;
  -moz-transition: background-color 100ms linear;
  -o-transition: background-color 100ms linear;
  -ms-transition: background-color 100ms linear;
  transition: background-color 100ms linear;
}

.jqx-scrollbar-button-state-normal-material {
  -webkit-transition: background-color 100ms linear;
  -moz-transition: background-color 100ms linear;
  -o-transition: background-color 100ms linear;
  -ms-transition: background-color 100ms linear;
  transition: background-color 100ms linear;
}

.jqx-scrollbar-button-state-pressed-material {
  -webkit-transition: background-color 100ms linear;
  -moz-transition: background-color 100ms linear;
  -o-transition: background-color 100ms linear;
  -ms-transition: background-color 100ms linear;
  transition: background-color 100ms linear;
  background: #d9d9d9;
  border-color: #b3b3b3;
  box-shadow: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;

  .jqx-icon-arrow-up-selected-material {
    background-image: url('#{$image-path}/jqx.material/material-icon-up.png');
    background-repeat: no-repeat;
    background-position: center;
  }

  .jqx-icon-arrow-down-selected-material {
    background-image: url('#{$image-path}/jqx.material/material-icon-down.png');
    background-repeat: no-repeat;
    background-position: center;
  }

  .jqx-icon-arrow-left-selected-material {
    background-image: url('#{$image-path}/jqx.material/material-icon-left.png');
    background-repeat: no-repeat;
    background-position: center;
  }

  .jqx-icon-arrow-right-selected-material {
    background-image: url('#{$image-path}/jqx.material/material-icon-right.png');
    background-repeat: no-repeat;
    background-position: center;
  }
}

.jqx-scrollbar-thumb-state-normal-horizontal-material {
  -webkit-transition: background-color 100ms linear;
  -moz-transition: background-color 100ms linear;
  -o-transition: background-color 100ms linear;
  -ms-transition: background-color 100ms linear;
  transition: background-color 100ms linear;
  background: #f5f5f5;
  border-color: #b3b3b3;
}

.jqx-scrollbar-thumb-state-hover-horizontal-material {
  -webkit-transition: background-color 100ms linear;
  -moz-transition: background-color 100ms linear;
  -o-transition: background-color 100ms linear;
  -ms-transition: background-color 100ms linear;
  transition: background-color 100ms linear;
  background: #e6e6e6;
  border-color: #b3b3b3;
  box-shadow: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
}

.jqx-scrollbar-thumb-state-pressed-horizontal-material {
  -webkit-transition: background-color 100ms linear;
  -moz-transition: background-color 100ms linear;
  -o-transition: background-color 100ms linear;
  -ms-transition: background-color 100ms linear;
  transition: background-color 100ms linear;
  background: #d9d9d9;
  border-color: #b3b3b3;
  box-shadow: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
}

.jqx-scrollbar-thumb-state-normal-material {
  -webkit-transition: background-color 100ms linear;
  -moz-transition: background-color 100ms linear;
  -o-transition: background-color 100ms linear;
  -ms-transition: background-color 100ms linear;
  transition: background-color 100ms linear;
  background: #f5f5f5;
  border-color: #b3b3b3;
}

.jqx-scrollbar-thumb-state-pressed-material {
  -webkit-transition: background-color 100ms linear;
  -moz-transition: background-color 100ms linear;
  -o-transition: background-color 100ms linear;
  -ms-transition: background-color 100ms linear;
  transition: background-color 100ms linear;
  background: #d9d9d9;
  border-color: #b3b3b3;
  box-shadow: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
}

.jqx-tree-item-hover-material {
  -webkit-transition: background-color 100ms linear;
  -moz-transition: background-color 100ms linear;
  -o-transition: background-color 100ms linear;
  -ms-transition: background-color 100ms linear;
  transition: background-color 100ms linear;
  color: #333 !important;
  border-color: #F5F5F5;
  text-decoration: none;
  background-color: #F5F5F5;
  background-repeat: repeat-x;
  outline: 0;
  background: #F5F5F5;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  background-position: 0 0;
}

.jqx-tree-item-selected-material {
  -webkit-transition: background-color 100ms linear;
  -moz-transition: background-color 100ms linear;
  -o-transition: background-color 100ms linear;
  -ms-transition: background-color 100ms linear;
  transition: background-color 100ms linear;
  color: #0077BE !important;
  border-color: #E1F5FE !important;
  background: #E1F5FE;
  box-shadow: none;
}

.jqx-tree-item-material {
  -webkit-transition: background-color 100ms linear;
  -moz-transition: background-color 100ms linear;
  -o-transition: background-color 100ms linear;
  -ms-transition: background-color 100ms linear;
  transition: background-color 100ms linear;
}

.jqx-menu-item-material {
  -webkit-transition: background-color 100ms linear;
  -moz-transition: background-color 100ms linear;
  -o-transition: background-color 100ms linear;
  -ms-transition: background-color 100ms linear;
  transition: background-color 100ms linear;
}

.jqx-menu-item-hover-material {
  -webkit-transition: background-color 100ms linear;
  -moz-transition: background-color 100ms linear;
  -o-transition: background-color 100ms linear;
  -ms-transition: background-color 100ms linear;
  transition: background-color 100ms linear;
  color: #333 !important;
  border-color: #F5F5F5;
  text-decoration: none;
  background-color: #F5F5F5;
  background-repeat: repeat-x;
  outline: 0;
  background: #F5F5F5;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  background-position: 0 0;
}

.jqx-menu-item-selected-material {
  -webkit-transition: background-color 100ms linear;
  -moz-transition: background-color 100ms linear;
  -o-transition: background-color 100ms linear;
  -ms-transition: background-color 100ms linear;
  transition: background-color 100ms linear;
  color: #0077BE !important;
  border-color: #E1F5FE !important;
  background: #E1F5FE;
  box-shadow: none;
}

.jqx-menu-item-top-material {
  -webkit-transition: background-color 100ms linear;
  -moz-transition: background-color 100ms linear;
  -o-transition: background-color 100ms linear;
  -ms-transition: background-color 100ms linear;
  transition: background-color 100ms linear;
}

.jqx-menu-item-top-hover-material {
  -webkit-transition: background-color 100ms linear;
  -moz-transition: background-color 100ms linear;
  -o-transition: background-color 100ms linear;
  -ms-transition: background-color 100ms linear;
  transition: background-color 100ms linear;
}

.jqx-menu-item-top-selected-material {
  -webkit-transition: background-color 100ms linear;
  -moz-transition: background-color 100ms linear;
  -o-transition: background-color 100ms linear;
  -ms-transition: background-color 100ms linear;
  transition: background-color 100ms linear;
}

.jqx-slider-slider-material {
  -webkit-transition: background-color 100ms linear;
  -moz-transition: background-color 100ms linear;
  -o-transition: background-color 100ms linear;
  -ms-transition: background-color 100ms linear;
  transition: background-color 100ms linear;
  transition: box-shadow 0.3s cubic-bezier(0.4, 0, 0.2, 1), opacity 0.5s ease;

  &:active {
    transform: scale(1.2);
    box-shadow: rgba(0, 0, 0, 0.3) 0 0 10px;
  }
}

.jqx-primary-material.jqx-input-label-material {
  color: #1ca8dd !important;
}

.jqx-primary-material.jqx-input-bar-material {
  &:before {
    background: #1ca8dd !important;
  }
}

.jqx-success-material.jqx-input-label-material {
  color: #5cb85c !important;
}

.jqx-success-material.jqx-input-bar-material {
  &:before {
    background: #5cb85c !important;
  }
}

.jqx-inverse-material.jqx-input-label-material {
  color: #666 !important;
}

.jqx-inverse-material.jqx-input-bar-material {
  &:before {
    background: #666 !important;
  }
}

.jqx-danger-material.jqx-input-label-material {
  color: #d9534f !important;
}

.jqx-danger-material.jqx-input-bar-material {
  &:before {
    background: #d9534f !important;
  }
}

.jqx-warning-material.jqx-input-label-material {
  color: #f0ad4e !important;
}

.jqx-warning-material.jqx-input-bar-material {
  &:before {
    background: #f0ad4e !important;
  }
}

.jqx-info-material.jqx-input-label-material {
  color: #5bc0de !important;
}

.jqx-info-material.jqx-input-bar-material {
  &:before {
    background: #5bc0de !important;
  }
}

.jqx-slider-tooltip-material.jqx-primary-slider {
  border-color: #1ca8dd;
  background: #1ca8dd;

  .jqx-fill-state-normal-material {
    border-color: #1ca8dd;
    background: #1ca8dd;
  }
}

.jqx-slider-tooltip-material.jqx-success-slider {
  border-color: #5cb85c;
  background: #5cb85c;

  .jqx-fill-state-normal-material {
    border-color: #5cb85c;
    background: #5cb85c;
  }
}

.jqx-slider-tooltip-material.jqx-inverse-slider {
  border-color: #666;
  background: #666;

  .jqx-fill-state-normal-material {
    border-color: #666;
    background: #666;
  }
}

.jqx-slider-tooltip-material.jqx-danger-slider {
  border-color: #d9534f;
  background: #d9534f;

  .jqx-fill-state-normal-material {
    border-color: #d9534f;
    background: #d9534f;
  }
}

.jqx-slider-tooltip-material.jqx-warning-slider {
  border-color: #f0ad4e;
  background: #f0ad4e;

  .jqx-fill-state-normal-material {
    border-color: #f0ad4e;
    background: #f0ad4e;
  }
}

.jqx-slider-tooltip-material.jqx-info-slider {
  border-color: #5bc0de;
  background: #5bc0de;

  .jqx-fill-state-normal-material {
    border-color: #5bc0de;
    background: #5bc0de;
  }
}

.jqx-primary-material {
  color: #1ca8dd !important;
  background: #fff !important;
  border-color: #1ca8dd !important;
  text-shadow: none !important;

  &:hover {
    color: #fff !important;
    background: #1ca8dd !important;
    border-color: #1ca8dd !important;
    text-shadow: none !important;
  }

  &:focus {
    color: #fff !important;
    background: #1ca8dd !important;
    border-color: #1ca8dd !important;
    text-shadow: none !important;
  }

  &:active {
    color: #fff !important;
    background: #1ca8dd !important;
    border-color: #1ca8dd !important;
    text-shadow: none !important;
    color: #fff !important;
    background-color: #1ca8dd !important;
    border-color: #1ca8dd !important;
    text-shadow: none !important;
  }
}

.jqx-primary-material.jqx-dropdownlist-state-normal-material {
  color: #fff !important;
  background: #1ca8dd !important;
  border-color: #1ca8dd !important;
  text-shadow: none !important;
}

.jqx-primary-material.jqx-slider-button-material {
  color: #fff !important;
  background: #1ca8dd !important;
  border-color: #1ca8dd !important;
  text-shadow: none !important;
}

.jqx-primary-material.jqx-slider-slider-material {
  color: #fff !important;
  background: #1ca8dd !important;
  border-color: #1ca8dd !important;
  text-shadow: none !important;
}

.jqx-primary-material.jqx-combobox-arrow-normal-material {
  color: #fff !important;
  background: #1ca8dd !important;
  border-color: #1ca8dd !important;
  text-shadow: none !important;
}

.jqx-primary-material.jqx-combobox-arrow-hover-material {
  color: #fff !important;
  background: #1ca8dd !important;
  border-color: #1ca8dd !important;
  text-shadow: none !important;
}

.jqx-primary-material.jqx-action-button-material {
  color: #fff !important;
  background: #1ca8dd !important;
  border-color: #1ca8dd !important;
  text-shadow: none !important;
}

.jqx-primary-material.active {
  color: #fff !important;
  background: #1ca8dd !important;
  border-color: #1ca8dd !important;
  text-shadow: none !important;
  color: #fff !important;
  background-color: #1ca8dd !important;
  border-color: #1ca8dd !important;
  text-shadow: none !important;
}

.jqx-primary-material.disabled {
  color: #fff !important;
  background: #1ca8dd !important;
  border-color: #1ca8dd !important;
  text-shadow: none !important;
}

.jqx-primary-material[disabled] {
  color: #fff !important;
  background: #1ca8dd !important;
  border-color: #1ca8dd !important;
  text-shadow: none !important;
}

.jqx-fill-state-pressed-material.jqx-primary-material {
  color: #fff !important;
  background-color: #1ca8dd !important;
  border-color: #1ca8dd !important;
  text-shadow: none !important;
}

.jqx-success-material {
  color: #5cb85c !important;
  background: #fff !important;
  border-color: #5cb85c !important;
  text-shadow: none !important;

  &:hover {
    color: #fff !important;
    background: #5cb85c !important;
    border-color: #5cb85c !important;
    text-shadow: none !important;
  }

  &:focus {
    color: #fff !important;
    background: #5cb85c !important;
    border-color: #5cb85c !important;
    text-shadow: none !important;
  }

  &:active {
    color: #fff !important;
    background: #5cb85c !important;
    border-color: #5cb85c !important;
    text-shadow: none !important;
    text-shadow: none !important;
    color: #fff !important;
    background: #5cb85c !important;
    border-color: #5cb85c !important;
  }
}

.jqx-success-material.jqx-dropdownlist-state-normal-material {
  color: #fff !important;
  background: #5cb85c !important;
  border-color: #5cb85c !important;
  text-shadow: none !important;
}

.jqx-success-material.jqx-slider-button-material {
  color: #fff !important;
  background: #5cb85c !important;
  border-color: #5cb85c !important;
  text-shadow: none !important;
}

.jqx-success-material.jqx-slider-slider-material {
  color: #fff !important;
  background: #5cb85c !important;
  border-color: #5cb85c !important;
  text-shadow: none !important;
}

.jqx-success-material.jqx-combobox-arrow-normal-material {
  color: #fff !important;
  background: #5cb85c !important;
  border-color: #5cb85c !important;
  text-shadow: none !important;
}

.jqx-success-material.jqx-combobox-arrow-hover-material {
  color: #fff !important;
  background: #5cb85c !important;
  border-color: #5cb85c !important;
  text-shadow: none !important;
}

.jqx-success-material.jqx-action-button-material {
  color: #fff !important;
  background: #5cb85c !important;
  border-color: #5cb85c !important;
  text-shadow: none !important;
}

.jqx-success-material.active {
  color: #fff !important;
  background: #5cb85c !important;
  border-color: #5cb85c !important;
  text-shadow: none !important;
  text-shadow: none !important;
  color: #fff !important;
  background: #5cb85c !important;
  border-color: #5cb85c !important;
}

.jqx-success-material.disabled {
  color: #fff !important;
  background: #5cb85c !important;
  border-color: #5cb85c !important;
  text-shadow: none !important;
}

.jqx-success-material[disabled] {
  color: #fff !important;
  background: #5cb85c !important;
  border-color: #5cb85c !important;
  text-shadow: none !important;
}

.jqx-fill-state-pressed-material.jqx-success-material {
  text-shadow: none !important;
  color: #fff !important;
  background: #5cb85c !important;
  border-color: #5cb85c !important;
}

.jqx-inverse-material {
  text-shadow: none !important;
  color: #666 !important;
  background: #fff !important;
  border-color: #cccccc !important;

  &:hover {
    text-shadow: none !important;
    color: #666 !important;
    background: #cccccc !important;
    border-color: #cccccc !important;
  }

  &:focus {
    text-shadow: none !important;
    color: #666 !important;
    background: #cccccc !important;
    border-color: #cccccc !important;
  }

  &:active {
    text-shadow: none !important;
    color: #666 !important;
    background: #cccccc !important;
    border-color: #cccccc !important;
    text-shadow: none !important;
    color: #666 !important;
    background: #cccccc !important;
    border-color: #cccccc !important;
  }
}

.jqx-inverse-material.jqx-dropdownlist-state-normal-material {
  text-shadow: none !important;
  color: #666 !important;
  background: #cccccc !important;
  border-color: #cccccc !important;
}

.jqx-inverse-material.jqx-slider-button-material {
  text-shadow: none !important;
  color: #666 !important;
  background: #cccccc !important;
  border-color: #cccccc !important;
}

.jqx-inverse-material.jqx-slider-slider-material {
  text-shadow: none !important;
  color: #666 !important;
  background: #cccccc !important;
  border-color: #cccccc !important;
}

.jqx-inverse-material.jqx-combobox-arrow-hover-material {
  text-shadow: none !important;
  color: #666 !important;
  background: #cccccc !important;
  border-color: #cccccc !important;
}

.jqx-inverse-material.jqx-combobox-arrow-normal-material {
  text-shadow: none !important;
  color: #666 !important;
  background: #cccccc !important;
  border-color: #cccccc !important;
}

.jqx-inverse-material.jqx-action-button-material {
  text-shadow: none !important;
  color: #666 !important;
  background: #cccccc !important;
  border-color: #cccccc !important;
}

.jqx-inverse-material.active {
  text-shadow: none !important;
  color: #666 !important;
  background: #cccccc !important;
  border-color: #cccccc !important;
  text-shadow: none !important;
  color: #666 !important;
  background: #cccccc !important;
  border-color: #cccccc !important;
}

.jqx-inverse-material.disabled {
  text-shadow: none !important;
  color: #666 !important;
  background: #cccccc !important;
  border-color: #cccccc !important;
}

.jqx-inverse-material[disabled] {
  text-shadow: none !important;
  color: #666 !important;
  background: #cccccc !important;
  border-color: #cccccc !important;
}

.jqx-fill-state-pressed-material.jqx-inverse-material {
  text-shadow: none !important;
  color: #666 !important;
  background: #cccccc !important;
  border-color: #cccccc !important;
}

.jqx-danger-material {
  text-shadow: none !important;
  color: #d9534f !important;
  background: #fff !important;
  border-color: #d9534f !important;

  &:hover {
    text-shadow: none !important;
    color: #fff !important;
    background: #d9534f !important;
    border-color: #d9534f !important;
  }

  &:focus {
    text-shadow: none !important;
    color: #fff !important;
    background: #d9534f !important;
    border-color: #d9534f !important;
  }

  &:active {
    text-shadow: none !important;
    color: #fff !important;
    background: #d9534f !important;
    border-color: #d9534f !important;
    text-shadow: none !important;
    color: #fff !important;
    background: #d9534f !important;
    border-color: #d9534f !important;
  }
}

.jqx-danger-material.jqx-dropdownlist-state-normal-material {
  text-shadow: none !important;
  color: #fff !important;
  background: #d9534f !important;
  border-color: #d9534f !important;
}

.jqx-danger-material.jqx-slider-button-material {
  text-shadow: none !important;
  color: #fff !important;
  background: #d9534f !important;
  border-color: #d9534f !important;
}

.jqx-danger-material.jqx-slider-slider-material {
  text-shadow: none !important;
  color: #fff !important;
  background: #d9534f !important;
  border-color: #d9534f !important;
}

.jqx-danger-material.jqx-combobox-arrow-hover-material {
  text-shadow: none !important;
  color: #fff !important;
  background: #d9534f !important;
  border-color: #d9534f !important;
}

.jqx-danger-material.jqx-combobox-arrow-normal-material {
  text-shadow: none !important;
  color: #fff !important;
  background: #d9534f !important;
  border-color: #d9534f !important;
}

.jqx-danger-material.jqx-action-button-material {
  text-shadow: none !important;
  color: #fff !important;
  background: #d9534f !important;
  border-color: #d9534f !important;
}

.jqx-danger-material.active {
  text-shadow: none !important;
  color: #fff !important;
  background: #d9534f !important;
  border-color: #d9534f !important;
  text-shadow: none !important;
  color: #fff !important;
  background: #d9534f !important;
  border-color: #d9534f !important;
}

.jqx-danger-material.disabled {
  text-shadow: none !important;
  color: #fff !important;
  background: #d9534f !important;
  border-color: #d9534f !important;
}

.jqx-danger-material[disabled] {
  text-shadow: none !important;
  color: #fff !important;
  background: #d9534f !important;
  border-color: #d9534f !important;
}

.jqx-fill-state-pressed-material.jqx-danger-material {
  text-shadow: none !important;
  color: #fff !important;
  background: #d9534f !important;
  border-color: #d9534f !important;
}

.jqx-validator-error-label-material {
  color: #d9534f !important;
}

.jqx-warning-material {
  text-shadow: none !important;
  color: #f0ad4e !important;
  background: #fff !important;
  border-color: #f0ad4e !important;

  &:hover {
    text-shadow: none !important;
    color: #fff !important;
    background: #f0ad4e !important;
    border-color: #f0ad4e !important;
  }

  &:focus {
    text-shadow: none !important;
    color: #fff !important;
    background: #f0ad4e !important;
    border-color: #f0ad4e !important;
  }

  &:active {
    text-shadow: none !important;
    color: #fff !important;
    background: #f0ad4e !important;
    border-color: #f0ad4e !important;
    text-shadow: none !important;
    color: #fff !important;
    background: #f0ad4e !important;
    border-color: #f0ad4e !important;
  }
}

.jqx-warning-material.jqx-dropdownlist-state-normal-material {
  text-shadow: none !important;
  color: #fff !important;
  background: #f0ad4e !important;
  border-color: #f0ad4e !important;
}

.jqx-warning-material.jqx-slider-button-material {
  text-shadow: none !important;
  color: #fff !important;
  background: #f0ad4e !important;
  border-color: #f0ad4e !important;
}

.jqx-warning-material.jqx-slider-slider-material {
  text-shadow: none !important;
  color: #fff !important;
  background: #f0ad4e !important;
  border-color: #f0ad4e !important;
}

.jqx-warning-material.jqx-combobox-arrow-hover-material {
  text-shadow: none !important;
  color: #fff !important;
  background: #f0ad4e !important;
  border-color: #f0ad4e !important;
}

.jqx-warning-material.jqx-combobox-arrow-normal-material {
  text-shadow: none !important;
  color: #fff !important;
  background: #f0ad4e !important;
  border-color: #f0ad4e !important;
}

.jqx-warning-material.jqx-action-button-material {
  text-shadow: none !important;
  color: #fff !important;
  background: #f0ad4e !important;
  border-color: #f0ad4e !important;
}

.jqx-warning-material.active {
  text-shadow: none !important;
  color: #fff !important;
  background: #f0ad4e !important;
  border-color: #f0ad4e !important;
  text-shadow: none !important;
  color: #fff !important;
  background: #f0ad4e !important;
  border-color: #f0ad4e !important;
}

.jqx-warning-material.disabled {
  text-shadow: none !important;
  color: #fff !important;
  background: #f0ad4e !important;
  border-color: #f0ad4e !important;
}

.jqx-warning-material[disabled] {
  text-shadow: none !important;
  color: #fff !important;
  background: #f0ad4e !important;
  border-color: #f0ad4e !important;
}

.jqx-fill-state-pressed-material.jqx-warning-material {
  text-shadow: none !important;
  color: #fff !important;
  background: #f0ad4e !important;
  border-color: #f0ad4e !important;
}

.jqx-info-material {
  text-shadow: none !important;
  color: #5bc0de !important;
  background: #fff !important;
  border-color: #5bc0de !important;

  &:hover {
    color: #fff !important;
    background: #5bc0de !important;
    border-color: #5bc0de !important;
    text-shadow: none !important;
  }

  &:focus {
    color: #fff !important;
    background: #5bc0de !important;
    border-color: #5bc0de !important;
    text-shadow: none !important;
  }

  &:active {
    color: #fff !important;
    background: #5bc0de !important;
    border-color: #5bc0de !important;
    text-shadow: none !important;
    text-shadow: none !important;
    color: #fff !important;
    background: #5bc0de !important;
    border-color: #5bc0de !important;
  }
}

.jqx-info-material.jqx-dropdownlist-state-normal-material {
  color: #fff !important;
  background: #5bc0de !important;
  border-color: #5bc0de !important;
  text-shadow: none !important;
}

.jqx-info-material.jqx-slider-button-material {
  color: #fff !important;
  background: #5bc0de !important;
  border-color: #5bc0de !important;
  text-shadow: none !important;
}

.jqx-info-material.jqx-slider-slider-material {
  color: #fff !important;
  background: #5bc0de !important;
  border-color: #5bc0de !important;
  text-shadow: none !important;
}

.jqx-info-material.jqx-combobox-arrow-hover-material {
  color: #fff !important;
  background: #5bc0de !important;
  border-color: #5bc0de !important;
  text-shadow: none !important;
}

.jqx-info-material.jqx-combobox-arrow-normal-material {
  color: #fff !important;
  background: #5bc0de !important;
  border-color: #5bc0de !important;
  text-shadow: none !important;
}

.jqx-info-material.jqx-action-button-material {
  color: #fff !important;
  background: #5bc0de !important;
  border-color: #5bc0de !important;
  text-shadow: none !important;
}

.jqx-info-material.active {
  color: #fff !important;
  background: #5bc0de !important;
  border-color: #5bc0de !important;
  text-shadow: none !important;
  text-shadow: none !important;
  color: #fff !important;
  background: #5bc0de !important;
  border-color: #5bc0de !important;
}

.jqx-info-material.disabled {
  color: #fff !important;
  background: #5bc0de !important;
  border-color: #5bc0de !important;
  text-shadow: none !important;
}

.jqx-info-material[disabled] {
  color: #fff !important;
  background: #5bc0de !important;
  border-color: #5bc0de !important;
  text-shadow: none !important;
}

.jqx-fill-state-pressed-material.jqx-info-material {
  text-shadow: none !important;
  color: #fff !important;
  background: #5bc0de !important;
  border-color: #5bc0de !important;
}

.jqx-grid-group-column-material {
  border-color: transparent;
}

.jqx-grid-column-menubutton-material {
  border-width: 0px;
  background-color: transparent;
  border-color: #E0E0E0 !important;
  background-image: url('#{$image-path}/jqx.material/material-icon-down.png');
  background-repeat: no-repeat;
  background-position: center;
  background-image: url('#{$image-path}/jqx.material/material-icon-down.png');
}

.jqx-grid-groups-row-material {
  > span {
    padding-left: 4px;
  }
}

.jqx-grid-cell-material {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;

  .jqx-button-material {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    -webkit-transition: none;
    -moz-transition: none;
    -o-transition: none;
    transition: none;
  }

  .jqx-button-material.jqx-fill-state-hover-material {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    -webkit-transition: none;
    -moz-transition: none;
    -o-transition: none;
    transition: none;
  }

  .jqx-button-material.jqx-fill-state-pressed-material {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    -webkit-transition: none;
    -moz-transition: none;
    -o-transition: none;
    transition: none;
  }
}

.jqx-grid-pager-top-material {
  .jqx-button-material {
    color: rgba(0, 0, 0, .54) !important;
    background-color: transparent;
    border-color: transparent;
    position: relative;
    height: 30px !important;
    width: 30px !important;
    border-radius: 50%;
    top: -4px;

    > div {
      top: 3px;
      position: relative;
      left: 2px;
    }
  }

  .jqx-button-material.jqx-fill-state-hover {
    color: rgba(0, 0, 0, .54) !important;
    background-color: transparent;
    border-color: transparent;
    box-shadow: none;
  }

  .jqx-button-material.jqx-fill-state-pressed {
    color: rgba(0, 0, 0, .54) !important;
    background-color: transparent;
    border-color: transparent;
    box-shadow: none;
  }

  .jqx-grid-pager-number-material {
    background-color: transparent;
    border-color: transparent;
    color: rgba(0, 0, 0, .54) !important;
    font-size: 12px;

    &:hover {
      font-size: 12px;
    }
  }

  .jqx-grid-pager-number-material.jqx-fill-state-pressed-material {
    color: #0077BE !important;
    font-weight: bold !important;
  }
}

.jqx-grid-pager-material {
  .jqx-button-material {
    color: rgba(0, 0, 0, .54) !important;
    background-color: transparent;
    border-color: transparent;
    position: relative;
    height: 30px !important;
    width: 30px !important;
    border-radius: 50%;
    top: -4px;

    > div {
      top: 3px;
      position: relative;
      left: 2px;
    }
  }

  .jqx-button-material.jqx-fill-state-hover {
    color: rgba(0, 0, 0, .54) !important;
    background-color: transparent;
    border-color: transparent;
    box-shadow: none;
  }

  .jqx-button-material.jqx-fill-state-pressed {
    color: rgba(0, 0, 0, .54) !important;
    background-color: transparent;
    border-color: transparent;
    box-shadow: none;
  }

  .jqx-grid-pager-number-material {
    background-color: transparent;
    border-color: transparent;
    color: rgba(0, 0, 0, .54) !important;
    font-size: 12px;

    &:hover {
      font-size: 12px;
    }
  }

  .jqx-grid-pager-number-material.jqx-fill-state-pressed-material {
    color: #0077BE !important;
    font-weight: bold !important;
  }
}

.jqx-grid-pager-input-material {
  padding: 0px !important;
}

.jqx-cell-material {
  font-size: 13px;
}

.jqx-calendar-material {
  > div {
    padding: 10px;
    box-sizing: border-box;
  }
}

.jqx-calendar-row-header-material {
  background-color: #f0f0f0;
  border: 0px solid #f2f2f2;
}

.jqx-calendar-top-left-header-material {
  background-color: #f0f0f0;
  border: 0px solid #f2f2f2;
}

.jqx-calendar-column-header-material {
  background-color: #FFF;
  border-top: 1px solid #FFF;
  border-bottom: 1px solid #e9e9e9;
}

.jqx-ribbon-header-vertical-material {
  background: #fff;
}

.jqx-widget-header-vertical-material {
  background: #fff;
}

.jqx-scrollbar-state-normal-material {
  background-color: #f5f5f5;
  border: 1px solid #f5f5f5;
  border-left-color: #ddd;
}

.jqx-scrollbar-thumb-state-hover-material {
  background: #e6e6e6;
  border-color: #b3b3b3;
  box-shadow: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
}

.jqx-progressbar-material {
  background: #f7f7f7 !important;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

.jqx-progressbar-value-material {
  background: #0077BE;
}

.jqx-splitter-collapse-button-horizontal-material {
  background: #0077BE;
}

.jqx-splitter-collapse-button-vertical-material {
  background: #0077BE;
}

.jqx-progressbar-value-vertical-material {
  background: #0077BE;
}

.jqx-splitter-splitbar-vertical-material {
  background: #d9d9d9;
  border-color: #b3b3b3;
  box-shadow: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
}

.jqx-splitter-splitbar-horizontal-material {
  background: #d9d9d9;
  border-color: #b3b3b3;
  box-shadow: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
}

.jqx-grid-column-sortdescbutton-material {
  background-color: transparent;
  border-style: solid;
  border-width: 0px 0px 0px 0px;
  border-color: #E0E0E0;
  background-image: url('#{$image-path}/jqx.material/material-icon-down.png');
  background-repeat: no-repeat;
  background-position: center;
}

jqx-grid-column-filterbutton-material {
  background-color: transparent;
  border-style: solid;
  border-width: 0px 0px 0px 0px;
  border-color: #E0E0E0;
}

.jqx-grid-column-sortascbutton-material {
  background-color: transparent;
  border-style: solid;
  border-width: 0px 0px 0px 0px;
  border-color: #E0E0E0;
  background-image: url('#{$image-path}/jqx.material/material-icon-up.png');
  background-repeat: no-repeat;
  background-position: center;
}

.jqx-menu-vertical-material {
  background: #ffffff;
  filter: none;

  .jqx-menu-item-top-hover-material {
    color: #333 !important;
    border-color: #F5F5F5;
    text-decoration: none;
    background-color: #F5F5F5;
    background-repeat: repeat-x;
    outline: 0;
    background: #F5F5F5;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    background-position: 0 0;
  }

  .jqx-menu-item-top-selected-material {
    color: #0077BE !important;
    border-color: #E1F5FE !important;
    background: #E1F5FE;
    box-shadow: none;
  }
}

.jqx-grid-bottomright-material {
  background-color: #fafafa;
}

.jqx-panel-bottomright-material {
  background-color: #fafafa;
}

.jqx-listbox-bottomright-material {
  background-color: #fafafa;
}

.jqx-window-material {
  box-shadow: 0 4px 23px 5px rgba(0, 0, 0, 0.2), 0 2px 6px rgba(0, 0, 0, 0.15);
}

.jqx-tooltip-material {
  box-shadow: 0 4px 23px 5px rgba(0, 0, 0, 0.2), 0 2px 6px rgba(0, 0, 0, 0.15);
  background: #6F6F6F;
  border-color: #6F6F6F;
  box-shadow: none;
  color: #fff;

  .jqx-fill-state-normal-material {
    background: #6F6F6F;
    border-color: #6F6F6F;
    box-shadow: none;
    color: #fff;
  }
}

.jqx-tooltip-material.jqx-popup-material {
  background: #6F6F6F;
  border-color: #6F6F6F;
  box-shadow: none;
  color: #fff;
}

.jqx-docking-material {
  .jqx-window-material {
    box-shadow: none;
  }
}

.jqx-docking-panel-material {
  .jqx-window-material {
    box-shadow: none;
  }
}

.jqx-checkbox-material {
  line-height: 20px;
  overflow: visible;

  .ripple {
    overflow: visible;
  }

  .ink {
    transform: scale(0);
    background: #0077BE;
    border-radius: 50%;
  }

  .ink.animate {
    animation: checkRipple 0.3s ease;
    animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  }

  .ink.active {
    opacity: 0.2;
    transform: scale(2);
  }
}

.jqx-radiobutton-material {
  overflow: visible;
  box-shadow: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  background-repeat: no-repeat;
  background: none;
  line-height: 20px;

  .ripple {
    overflow: visible;
  }

  .ink {
    transform: scale(0);
    background: #0077BE;
    border-radius: 50%;
  }

  .ink.animate {
    animation: checkRipple 0.3s ease;
    animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  }

  .ink.active {
    opacity: 0.2;
    transform: scale(2);
  }
}

.jqx-radiobutton-material-material {
  -moz-border-radius: 100%;
  -webkit-border-radius: 100%;
  border-radius: 100%;
  background-repeat: no-repeat;
  transition: background-color ease-in .3s;
}

.jqx-radiobutton-hover-material {
  -moz-border-radius: 100%;
  -webkit-border-radius: 100%;
  border-radius: 100%;
  background-repeat: no-repeat;
  transition: background-color ease-in .3s;
  background-color: #0077BE;
  border-color: #0077BE;
}

.jqx-radiobutton-check-checked-material {
  filter: none;
  background: #fff;
  background-repeat: no-repeat;
  -moz-border-radius: 100%;
  -webkit-border-radius: 100%;
  border-radius: 100%;
}

.jqx-radiobutton-check-indeterminate-material {
  filter: none;
  background: #999;
  -moz-border-radius: 100%;
  -webkit-border-radius: 100%;
  border-radius: 100%;
}

.jqx-radiobutton-check-indeterminate-disabled-material {
  filter: none;
  background: #999;
  -moz-border-radius: 100%;
  -webkit-border-radius: 100%;
  border-radius: 100%;
}

.jqx-checkbox-default-material {
  border-width: 1px;
  border-color: #E0E0E0;
  background-color: #fff;
  overflow: visible;
}

.jqx-radiobutton-default-material {
  border-width: 1px;
  border-color: #E0E0E0;
  background-color: #fff;
  overflow: visible;
}

.jqx-tree-material {
  .jqx-checkbox-material {
    .jqx-checkbox-default-material {
      background-color: #0077BE;
      border-color: #0077BE;
    }
  }

  .jqx-checkbox-check-indeterminate-material {
    width: 12px !important;
    height: 12px !important;
    top: 2px;
    left: 2px;
  }
}

.jqx-checkbox-material[checked] {
  .jqx-checkbox-default-material {
    background-color: #0077BE;
    border-color: #0077BE;
  }
}

.jqx-tree-grid-checkbox[checked].jqx-checkbox-default-material {
  background-color: #0077BE;
  border-color: #0077BE;
}

.jqx-radiobutton-material[checked] {
  .jqx-radiobutton-default-material {
    background-color: #0077BE;
    border-color: #0077BE;
  }
}

.jqx-checkbox-check-checked-material {
  background: transparent url('#{$image-path}/jqx.material/material_check_white.png') center center no-repeat;
}

.jqx-checkbox-check-indeterminate-material {
  width: 14px !important;
  height: 14px !important;
  position: relative;
  top: 1px;
  left: 1px;
  background: white;
}

.jqx-checkbox-hover-material {
  background-color: #0077BE;
  border-color: #0077BE;
}

.jqx-slider-material[discrete] {
  .jqx-slider-slider-material {
    &:active {
      transform: scaleX(0);
    }
  }
}

.jqx-slider-slider-horizontal-material {
  background: #0077BE;
}

.jqx-slider-slider-vertical-material {
  background: #0077BE;
}

.jqx-slider-tooltip-material {
  width: 25px;
  height: 25px;
  transform-origin: 50% 100%;
  border-radius: 50%;
  transform: scale(0) rotate(45deg);
  padding: 0px;
  background: transparent !important;
  border-radius: 15px 15px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #0077BE;
  color: #fff;
  font-size: 11px;
  background: transparent;
  border: none !important;
  box-shadow: none;

  .jqx-tooltip-arrow-t-b {
    display: none;
    visibility: hidden;
  }

  .jqx-tooltip-arrow-l-r {
    display: none;
    visibility: hidden;
  }

  .jqx-fill-state-normal-material {
    border-radius: 15px 15px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #0077BE;
    color: #fff;
    font-size: 11px;
  }

  .jqx-tooltip-main-material {
    top: -7px;
    right: 11px;
  }

  .jqx-tooltip-text {
    background: transparent;
    border: none;
    padding: 0px;
    overflow: visible;

    > span {
      transform: rotate(-45deg);
    }
  }
}

.jqx-slider-tooltip-material.init {
  transform: scale(1) rotate(45deg);
}

.jqx-slider-tooltip-material.hide {
  transition: transform 0.2s ease;
  transform-origin: 50% 100%;
  transform: scale(0) rotate(45deg);
}

.jqx-slider-tooltip-material.show {
  transition: transform 0.2s ease;
  transform: scale(1) rotate(45deg);
}

.jqx-slider-tooltip-material.far {
  border-radius: 0px 15px 15px 15px;

  .jqx-fill-state-normal-material {
    border-radius: 0px 15px 15px 15px;
  }

  .jqx-tooltip-main-material {
    top: 3px;
    right: 4px;
  }
}

.jqx-slider-tooltip-material.vertical {
  border-radius: 15px 0px 15px 15px;

  .jqx-fill-state-normal-material {
    border-radius: 15px 0px 15px 15px;
  }

  .jqx-tooltip-main-material {
    top: -3px;
    right: 3px;
  }
}

.jqx-slider-tooltip-material.vertical.far {
  border-radius: 15px 15px 15px 0px;

  .jqx-fill-state-normal-material {
    border-radius: 15px 15px 15px 0px;
  }
}

.jqx-slider-tooltip-material.range {
  width: 35px;
  height: 35px;
}

.jqx-slider-rangebar-material {
  border-color: #0077BE;
  background: #0077BE;
}

.jqx-slider-track-horizontal-material {
  border-color: #E0E0E0;
  background: #f0f0f0;
}

.jqx-slider-track-vertical-material {
  border-color: #E0E0E0;
  background: #f0f0f0;
}

.jqx-slider-button-material.jqx-fill-state-normal-material {
  background: transparent !important;
}

.jqx-slider-button-material.jqx-fill-state-hover-material {
  background: transparent !important;
}

.jqx-listitem-state-hover-material {
  padding: 5px;
  margin: 0px;
  color: #333 !important;
  border-color: #F5F5F5;
  text-decoration: none;
  background-color: #F5F5F5;
  background-repeat: repeat-x;
  outline: 0;
  background: #F5F5F5;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  background-position: 0 0;
}

.jqx-listitem-state-selected-material {
  padding: 5px;
  margin: 0px;
  color: #0077BE !important;
  border-color: #E1F5FE !important;
  background: #E1F5FE;
  box-shadow: none;
}

.jqx-listitem-state-normal-material {
  padding: 5px;
  margin: 0px;
}

.jqx-scheduler-edit-dialog-label-material {
  line-height: 35px;
  padding-top: 6px;
  padding-bottom: 6px;
}

.jqx-scheduler-edit-dialog-field-material {
  line-height: 35px;
  padding-top: 6px;
  padding-bottom: 6px;

  .jqx-datetimeinput {
    display: none;
  }
}

.jqx-scheduler-edit-dialog-label-rtl-material {
  line-height: 35px;
  padding-top: 6px;
  padding-bottom: 6px;
}

.jqx-scheduler-edit-dialog-field-rtl-material {
  line-height: 35px;
  padding-top: 6px;
  padding-bottom: 6px;
}

.jqx-calendar-cell-hover-material {
  color: #333 !important;
  border-color: #F5F5F5;
  text-decoration: none;
  background-color: #F5F5F5;
  background-repeat: repeat-x;
  outline: 0;
  background: #F5F5F5;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  background-position: 0 0;
}

.jqx-grid-cell-hover-material {
  color: #333 !important;
  border-color: #F5F5F5;
  text-decoration: none;
  background-color: #F5F5F5;
  background-repeat: repeat-x;
  outline: 0;
  background: #F5F5F5;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  background-position: 0 0;
}

.jqx-input-popup-material {
  .jqx-fill-state-hover-material {
    color: #333 !important;
    border-color: #F5F5F5;
    text-decoration: none;
    background-color: #F5F5F5;
    background-repeat: repeat-x;
    outline: 0;
    background: #F5F5F5;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    background-position: 0 0;
  }

  .jqx-fill-state-pressed-material {
    color: #333 !important;
    border-color: #F5F5F5;
    text-decoration: none;
    background-color: #F5F5F5;
    background-repeat: repeat-x;
    outline: 0;
    background: #F5F5F5;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    background-position: 0 0;
  }
}

.jqx-scheduler-cell-hover-material {
  border-color: #F5F5F5 !important;
  background: #F5F5F5 !important;
}

.jqx-calendar-cell-selected-material {
  color: #0077BE !important;
  border-color: #E1F5FE !important;
  background: #E1F5FE;
  box-shadow: none;
}

.jqx-grid-cell-selected-material {
  color: #0077BE !important;
  border-color: #E1F5FE !important;
  background: #E1F5FE;
  box-shadow: none;
}

.jqx-grid-selectionarea-material {
  color: #0077BE !important;
  border-color: #E1F5FE !important;
  background: #E1F5FE;
  box-shadow: none;
}

.jqx-input-button-header-material {
  color: #0077BE !important;
  border-color: #E1F5FE !important;
  background: #E1F5FE;
  box-shadow: none;
}

.jqx-input-button-innerHeader-material {
  color: #0077BE !important;
  border-color: #E1F5FE !important;
  background: #E1F5FE;
  box-shadow: none;
}

.jqx-popup-material {
  border: 1px solid #E0E0E0;
  *border-right-width: 2px;
  *border-bottom-width: 2px;
  -webkit-box-shadow: 0 3px 5px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0 3px 5px rgba(0, 0, 0, 0.15);
}

.jqx-expander-arrow-bottom-material {
  background-image: url('#{$image-path}/jqx.material/material-icon-up.png');
  background-repeat: no-repeat;
  background-position: center;
}

.jqx-window-collapse-button-material {
  background-image: url('#{$image-path}/jqx.material/material-icon-up.png');
  background-repeat: no-repeat;
  background-position: center;
}

.jqx-menu-item-arrow-up-material {
  background-image: url('#{$image-path}/jqx.material/material-icon-up.png');
  background-repeat: no-repeat;
  background-position: center;
}

.jqx-menu-item-arrow-up-selected-material {
  background-image: url('#{$image-path}/jqx.material/material-icon-up.png');
  background-repeat: no-repeat;
  background-position: center;
}

.jqx-menu-item-arrow-top-up-material {
  background-image: url('#{$image-path}/jqx.material/material-icon-up.png');
  background-repeat: no-repeat;
  background-position: center;
}

.jqx-icon-arrow-up-material {
  background-image: url('#{$image-path}/jqx.material/material-icon-up.png');
  background-repeat: no-repeat;
  background-position: center;
}

.jqx-icon-arrow-up-hover-material {
  background-image: url('#{$image-path}/jqx.material/material-icon-up.png');
  background-repeat: no-repeat;
  background-position: center;
}

.jqx-icon-arrow-up-selected-material {
  background-image: url('#{$image-path}/jqx.material/material-icon-up.png');
  background-repeat: no-repeat;
  background-position: center;
}

.jqx-grid-group-expand-material {
  background-image: url('#{$image-path}/jqx.material/material-icon-down.png');
  background-repeat: no-repeat;
  background-position: center;
  padding-right: 0px;
  background-image: url('#{$image-path}/jqx.material/material-icon-down.png');
  background-position: 50% 50%;
  background-repeat: no-repeat;
}

.jqx-expander-arrow-top-material {
  background-image: url('#{$image-path}/jqx.material/material-icon-down.png');
  background-repeat: no-repeat;
  background-position: center;
}

.jqx-window-collapse-button-collapsed-material {
  background-image: url('#{$image-path}/jqx.material/material-icon-down.png');
  background-repeat: no-repeat;
  background-position: center;
}

.jqx-menu-item-arrow-down-material {
  background-image: url('#{$image-path}/jqx.material/material-icon-down.png');
  background-image: url('#{$image-path}/jqx.material/material-icon-down.png');
  background-repeat: no-repeat;
  background-repeat: no-repeat;
  background-position: center;
  background-position: center;
}

.jqx-menu-item-arrow-down-selected-material {
  background-image: url('#{$image-path}/jqx.material/material-icon-down.png');
  background-repeat: no-repeat;
  background-position: center;
}

.jqx-icon-arrow-down-material {
  background-image: url('#{$image-path}/jqx.material/material-icon-down.png');
  background-repeat: no-repeat;
  background-position: center;
}

.jqx-icon-arrow-down-hover-material {
  background-image: url('#{$image-path}/jqx.material/material-icon-down.png');
  background-repeat: no-repeat;
  background-position: center;
}

.jqx-icon-arrow-down-selected-material {
  background-image: url('#{$image-path}/jqx.material/material-icon-down.png');
  background-repeat: no-repeat;
  background-position: center;
}

.jqx-tabs-arrow-left-material {
  background-image: url('#{$image-path}/jqx.material/material-icon-left.png');
  background-repeat: no-repeat;
  background-position: center;
}

.jqx-menu-item-arrow-left-selected-material {
  background-image: url('#{$image-path}/jqx.material/material-icon-left.png');
  background-repeat: no-repeat;
  background-position: center;
  background-image: url('#{$image-path}/jqx.material/material-icon-left.png');
  background-repeat: no-repeat;
  background-position: center;
}

.jqx-menu-item-arrow-top-left {
  background-image: url('#{$image-path}/jqx.material/material-icon-left.png');
  background-repeat: no-repeat;
  background-position: center;
}

.jqx-icon-arrow-left-material {
  background-image: url('#{$image-path}/jqx.material/material-icon-left.png');
  background-repeat: no-repeat;
  background-position: center;
}

.jqx-icon-arrow-down-left-material {
  background-image: url('#{$image-path}/jqx.material/material-icon-left.png');
  background-repeat: no-repeat;
  background-position: center;
}

.jqx-icon-arrow-left-selected-material {
  background-image: url('#{$image-path}/jqx.material/material-icon-left.png');
  background-repeat: no-repeat;
  background-position: center;
}

.jqx-grid-group-collapse-material {
  background-image: url('#{$image-path}/jqx.material/material-icon-right.png');
  background-repeat: no-repeat;
  background-position: center;
  background-image: url('#{$image-path}/jqx.material/material-icon-right.png');
  background-position: 50% 50%;
  background-repeat: no-repeat;
}

.jqx-tabs-arrow-right-material {
  background-image: url('#{$image-path}/jqx.material/material-icon-right.png');
  background-repeat: no-repeat;
  background-position: center;
}

.jqx-menu-item-arrow-right-selected-material {
  background-image: url('#{$image-path}/jqx.material/material-icon-right.png');
  background-repeat: no-repeat;
  background-position: center;
  background-image: url('#{$image-path}/jqx.material/material-icon-right.png');
  background-repeat: no-repeat;
  background-position: center;
}

.jqx-menu-item-arrow-top-right-material {
  background-image: url('#{$image-path}/jqx.material/material-icon-right.png');
  background-repeat: no-repeat;
  background-position: center;
}

.jqx-icon-arrow-right-material {
  background-image: url('#{$image-path}/jqx.material/material-icon-right.png');
  background-repeat: no-repeat;
  background-position: center;
}

.jqx-icon-arrow-right-hover-material {
  background-image: url('#{$image-path}/jqx.material/material-icon-right.png');
  background-repeat: no-repeat;
  background-position: center;
}

.jqx-icon-arrow-right-selected-material {
  background-image: url('#{$image-path}/jqx.material/material-icon-right.png');
  background-repeat: no-repeat;
  background-position: center;
}

.jqx-tree-item-arrow-collapse-rtl-material {
  background-image: url('#{$image-path}/jqx.material/material-icon-left.png');
}

.jqx-tree-item-arrow-collapse-hover-rtl-material {
  background-image: url('#{$image-path}/jqx.material/material-icon-left.png');
}

.jqx-input-button-content-material {
  font-size: 10px;
}

.jqx-widget {
  .jqx-grid-column-header-cell-material {
    padding-top: 8px;
    padding-bottom: 8px;
    height: 30px;
  }

  .jqx-grid-row-cell-material {
    padding-top: 8px;
    padding-bottom: 8px;
    height: 30px;
  }

  .jqx-grid-cell {
    border-color: #E0E0E0;
  }

  .jqx-grid-column-header {
    border-color: #E0E0E0;
  }

  .jqx-grid-group-cell {
    border-color: #E0E0E0;
  }
}

.jqx-combobox-material {
  .jqx-icon-close-material {
    background-image: url('#{$image-path}/jqx.material/close_white.png');
  }

  border-color: #E0E0E0;
  color: #555555;
  background-color: #ffffff;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);

  .jqx-input-label.focused {
    top: -15px;
    font-size: 12px;
    color: #0077BE;
  }

  .jqx-input-bar.focused {
    &:before {
      width: 100%;
    }
  }
}

.jqx-input-material {
  border-color: #E0E0E0;
  color: #555555;
  background-color: #ffffff;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);

  input {
    background: #fff;
    border-color: #fff;
    border-radius: 0;
    box-shadow: none;
    border-bottom: 1px solid #E0E0E0;
    outline: none;
    font-size: 14px;
    resize: none;
    background: #fff;
    border: none;
    border-radius: 0;
    box-sizing: border-box;
    box-shadow: none;
    border-bottom: 1px solid #E0E0E0;

    &:focus {
      border-radius: 0;
      box-shadow: none;
    }
  }
}

.jqx-combobox-content-material {
  border-color: transparent;
}

.jqx-datetimeinput-content-material {
  border-color: transparent;
}

.jqx-combobox-arrow-normal-material {
  background: #fff;
  border-color: transparent;
}

.jqx-combobox-content-focus-material {
  outline: none;
  outline: none;
}

.jqx-combobox-state-focus-material {
  outline: none;
  outline: none;
}

.jqx-numberinput-focus-material {
  outline: none;
  outline: none;
}

.jqx-input-group-material {
  position: relative;
  display: inline-block;
  overflow: visible;
  border: none;
  box-shadow: none;

  input {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
  }

  .studio_timer {
    width: 30%;
  }

  .stuCheck{
    width: 15px;
    height: 15px;
    vertical-align: middle;
  }

  textarea {
    width: 100%;
    height: 100%;
    outline: none;
    resize: none;
    border-left: none;
    border-right: none;
    border-top: none;
    border-bottom-color: #e0e0e0;
  }
}

.jqx-numberinput-material {
  position: relative;
  background: #fff;
  border-color: #fff;
  border-radius: 0;
  box-shadow: none;
  border-bottom: 1px solid #E0E0E0;
  outline: none;
  overflow: visible !important;

  input {
    height: 100% !important;
  }

  > div {
    overflow: visible !important;
  }
}

.jqx-maskedinput-material {
  position: relative;
  background: #fff;
  border-color: #fff;
  border-radius: 0;
  box-shadow: none;
  border-bottom: 1px solid #E0E0E0;
  outline: none;
  overflow: visible !important;

  > div {
    overflow: visible !important;
  }
}

.jqx-input-material.jqx-validator-error-element {
  border-color: transparent !important;
  border-bottom: 1px solid #df2227 !important;
}

.jqx-combobox-state-normal-material {
  background: #fff;
  border-color: #fff;
  border-radius: 0;
  box-shadow: none;
  border-bottom: 1px solid #E0E0E0;
  outline: none;
  overflow: visible !important;

  > div {
    overflow: visible !important;

    > div {
      overflow: visible !important;
    }
  }

  .jqx-icon-arrow-down-material {
    transform: rotate(0deg);
    transition: transform 0.2s ease-out;
  }
}

.jqx-datetimeinput-material {
  background: #fff;
  border-color: #fff;
  border-radius: 0;
  box-shadow: none;
  border-bottom: 1px solid #E0E0E0;
  outline: none;
  overflow: visible !important;

  .jqx-action-button-material {
    background-color: transparent;
    border-color: transparent;
  }

  .jqx-action-button-rtl-material {
    background-color: transparent;
    border-color: transparent;
  }

  > div {
    overflow: visible !important;
  }
}

input[type="text"].jqx-input-material {
  font-size: 14px;
  resize: none;
  background: #fff;
  border: none;
  border-radius: 0;
  box-sizing: border-box;
  box-shadow: none;
  border-bottom: 1px solid #E0E0E0;

  &:-moz-placeholder {
    color: #999999;
  }

  &:-webkit-input-placeholder {
    color: #999999;
  }

  &:-ms-input-placeholder {
    color: #999999;
  }
}

input[type="password"].jqx-input-material {
  font-size: 14px;
  resize: none;
  background: #fff;
  border: none;
  border-radius: 0;
  box-sizing: border-box;
  box-shadow: none;
  border-bottom: 1px solid #E0E0E0;
}

input[type="text"].jqx-widget-content-material {
  font-size: 14px;
  resize: none;
  background: #fff;
  border: none;
  border-radius: 0;
  box-sizing: border-box;
  box-shadow: none;
  border-bottom: 1px solid #E0E0E0;

  &:-moz-placeholder {
    color: #999999;
  }

  &:-webkit-input-placeholder {
    color: #999999;
  }

  &:-ms-input-placeholder {
    color: #999999;
  }
}

input[type="textarea"].jqx-widget-content-material {
  font-size: 14px;
  resize: none;
  background: #fff;
  border: none;
  border-radius: 0;
  box-sizing: border-box;
  box-shadow: none;
  border-bottom: 1px solid #E0E0E0;

  &:-moz-placeholder {
    color: #999999;
  }

  &:-webkit-input-placeholder {
    color: #999999;
  }

  &:-ms-input-placeholder {
    color: #999999;
  }
}

textarea.jqx-input-material {
  font-size: 14px;
  resize: none;
  background: #fff;
  border: none;
  border-radius: 0;
  box-sizing: border-box;
  box-shadow: none;
  border-bottom: 1px solid #E0E0E0;

  &:-moz-placeholder {
    color: #999999;
  }

  &:-webkit-input-placeholder {
    color: #999999;
  }

  &:-ms-input-placeholder {
    color: #999999;
  }
}

.jqx-input-label {
  visibility: inherit;
}

.jqx-input-bar {
  visibility: inherit;
}

input {
  &:focus {
    ~ {
      .jqx-input-label-material {
        top: -15px;
        font-size: 12px;
        color: #0077BE;
      }

      .jqx-input-bar {
        &:before {
          width: 100%;
        }
      }
    }
  }
}

textarea {
  &:focus {
    ~ {
      .jqx-input-label-material {
        top: -15px;
        font-size: 12px;
        color: #0077BE;
      }

      .jqx-input-bar {
        &:before {
          width: 100%;
        }
      }
    }
  }
}

.jqx-input-widget-material[hint=true] {
  .jqx-input-label {
    top: -15px;
    font-size: 12px;
    color: #0077BE;
  }
}

.jqx-text-area-material[hint=true] {
  .jqx-input-label {
    top: -15px;
    font-size: 12px;
    color: #0077BE;
  }
}

.jqx-dropdownlist-state-normal-material[hint=true] {
  .jqx-input-label {
    top: -15px;
    font-size: 12px;
    color: #0077BE;
  }
}

.jqx-combobox-state-normal-material[hint=true] {
  .jqx-input-label {
    top: -15px;
    font-size: 12px;
    color: #0077BE;
  }
}

.jqx-dropdownlist-material {
  .jqx-input-label.focused {
    top: -15px;
    font-size: 12px;
    color: #0077BE;
  }

  .jqx-input-bar.focused {
    &:before {
      width: 100%;
    }
  }
}

.jqx-datetimeinput-material[hint=true] {
  .jqx-input-label {
    top: -15px;
    font-size: 12px;
    color: #0077BE;
  }
}

.jqx-maskedinput-material[hint=true] {
  .jqx-input-label {
    top: -15px;
    font-size: 12px;
    color: #0077BE;
  }
}

.jqx-numberinput-material[hint=true] {
  .jqx-input-label {
    top: -15px;
    font-size: 12px;
    color: #0077BE;
  }
}

.jqx-formattedinput-material[hint=true] {
  .jqx-input-label {
    top: -15px;
    font-size: 12px;
    color: #0077BE;
  }
}

.jqx-dropdownlist-material[default-placeholder="true"] {
  .jqx-input-label {
    visibility: hidden;
  }
}

.jqx-complex-input-group-material {
  .jqx-input-bar.focused {
    &::before {
      width: 100%;
    }
  }

  .jqx-fill-state-normal-material {
    border-color: #fafafa;
  }
}

.jqx-combobox-state-selected-material {
  .jqx-input-bar {
    &:before {
      width: 100%;
    }
  }

  color: #0077BE;

  .jqx-icon-arrow-down-material {
    transform: rotate(180deg);
    transition: transform 0.2s ease-out;
    left: -1px;
    left: -1px;
  }
}

.jqx-input-widget-material.jqx-rtl {
  > input {
    direction: rtl;
  }
}

.jqx-input-label-material {
  color: #E0E0E0;
  font-size: 14px;
  font-weight: normal;
  position: absolute;
  pointer-events: none;
  left: 2px;
  top: 10px;
  top: calc(50% - 7px);
  transition: 300ms ease all;
}

.jqx-input-label.initial {
  transition: none;
}

.jqx-input-bar-material {
  position: relative;
  display: block;
  z-index: 1;

  &:before {
    content: '';
    height: 2px;
    width: 0;
    bottom: 0px;
    position: absolute;
    background: #0077BE;
    transition: 300ms ease all;
    left: 0%;
  }
}

.jqx-formatted-input-spin-button-material {
  border-color: #fff;
  background: #fff;
}

.jqx-input-group-addon-material {
  border-color: #fff;
  background: #fff;
}

.sorticon.descending {
  .jqx-grid-column-sorticon-material {
    transform: rotate(0deg);
    transition: transform 0.2s ease-out;
  }
}

.jqx-tree-item-arrow-expand-material {
  transform: rotate(0deg);
  transition: transform 0.2s ease-out;
}

.jqx-tree-item-arrow-collapse-material {
  transform: rotate(-90deg);
  background-image: url('#{$image-path}/jqx.material/material-icon-down.png');
  background-repeat: no-repeat;
  background-position: center;
  transition: transform 0.2s ease-out;
}

.sorticon.ascending {
  .jqx-grid-column-sorticon-material {
    transform: rotate(180deg);
    transition: transform 0.2s ease-out;
    left: -1px;
  }
}

.jqx-listbox-container {
  margin-top: 1px;
}

.jqx-popup-material.jqx-fill-state-focus-material {
  outline: none;
  border-color: #E0E0E0 !important;
}

.jqx-datetimeinput-content {
  overflow: visible !important;
}

.jqx-datetimeinput-container {
  overflow: visible !important;
}

.jqx-text-area-material {
  overflow: visible !important;

  > div {
    overflow: visible !important;
  }
}

.jqx-text-area-element-material {
  box-sizing: border-box;
}

.jqx-pivotgrid-content-wrapper.jqx-fill-state-normal-material {
  border-color: #E0E0E0;
}

.jqx-grid-cell-material.jqx-grid-cell-selected-material {
  > .jqx-grid-group-expand-material {
    background-image: url('#{$image-path}/jqx.material/material-icon-down.png');
    background-repeat: no-repeat;
    background-position: center;
    background-image: url('#{$image-path}/jqx.material/material-icon-down.png');
    background-repeat: no-repeat;
    background-position: center;
  }

  > .jqx-grid-group-collapse-material {
    background-image: url('#{$image-path}/jqx.material/material-icon-right.png');
    background-repeat: no-repeat;
    background-position: center;
    background-image: url('#{$image-path}/jqx.material/material-icon-right.png');
    background-repeat: no-repeat;
    background-position: center;
  }

  > .jqx-grid-group-collapse-rtl-material {
    background-image: url('#{$image-path}/jqx.material/material-icon-left.png');
    background-repeat: no-repeat;
    background-position: center;
    background-image: url('#{$image-path}/jqx.material/material-icon-left.png');
    background-repeat: no-repeat;
    background-position: center;
  }

  > .jqx-grid-group-expand-rtl-material {
    background-image: url('#{$image-path}/jqx.material/material-icon-down.png');
    background-repeat: no-repeat;
    background-position: center;
    background-image: url('#{$image-path}/jqx.material/material-icon-down.png');
    background-repeat: no-repeat;
    background-position: center;
  }
}

.jqx-tabs-title-selected-top-material {
  border-color: transparent;
  filter: none;
  background: #fff;
  color: #333;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  color: #0077BE;
  font-weight: 500;
  padding-top: 5px;
  padding-bottom: 5px;
}

.jqx-tabs-selection-tracker-top-material {
  border-color: transparent;
  filter: none;
  background: #fff;
  color: #333;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

.jqx-grid-cell-filter-row-material {
  background-color: #fafafa;
}

.jqx-tabs-title-material {
  color: #333;
}

.jqx-ribbon-item-material {
  color: #333;
  cursor: pointer;
}

.jqx-tabs-title-selected-bottom-material {
  color: #0077BE;
  font-weight: 500;
  padding-top: 5px;
  padding-bottom: 5px;
  border-color: transparent;
  border-top: 1px solid #fff;
  filter: none;
  background: #fff;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

.jqx-tabs-title.jqx-fill-state-hover-material {
  border-color: transparent;
}

.jqx-ribbon-item-selected-material {
  color: #0077BE;
  font-weight: 500;
  border-color: transparent;
}

.jqx-ribbon-item-hover-material {
  background: transparent;
}

.jqx-ribbon-header-top-material {
  border-color: transparent;
  border-bottom-color: #E0E0E0;
}

.jqx-ribbon-header-bottom-material {
  border-color: transparent;
  border-top-color: #E0E0E0;
}

.jqx-ribbon-header-right-material {
  border-color: transparent;
  border-left-color: #E0E0E0;
}

.jqx-ribbon-header-left-material {
  border-color: transparent;
  border-right-color: #E0E0E0;
}

.jqx-tabs-selection-tracker-bottom-material {
  border-color: transparent;
  border-top: 1px solid #fff;
  filter: none;
  background: #fff;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

.jqx-tabs-material {
  border-color: transparent;
}

.jqx-ribbon-material {
  border-color: transparent;
}

.jqx-tabs-header-material {
  background: transparent;
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important;
  background: transparent;
}

.jqx-ribbon-header-material {
  background: transparent;
}

.jqx-tabs-position-bottom {
  .jqx-tabs-header-material {
    border-color: transparent;
  }

  .jqx-tabs-bar-material {
    top: 0;
  }
}

.jqx-layout-material {
  .jqx-tabs-header-material {
    background: #fff;
    border-color: #E0E0E0;
  }

  .jqx-ribbon-header-material {
    background: #fff;
    border-color: #E0E0E0;
  }

  background-color: #cccccc;
}

.jqx-tabs-title-bottom {
  border-color: transparent;
}

.jqx-tabs-title-hover-top-material {
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important;
  background: transparent;
}

.jqx-tabs-title-hover-bottom-material {
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important;
  background: transparent;
}

.jqx-tabs-content-material {
  box-sizing: border-box;
  border: 1px solid #E0E0E0;
  border-top-color: transparent;
  padding: 5px;
}

.jqx-tabs-bar-material {
  position: absolute;
  bottom: 0;
  background: #0077BE;
  height: 2px;
  z-index: 10;
  transition: .5s cubic-bezier(.35, 0, .25, 1);
}

.jqx-tabs-bar-material.vertical {
  width: 2px;
}

.jqx-kanban-column-header-collapsed-material {
  background: -moz-linear-gradient(0deg, rgba(248, 248, 248, 1) 0%, rgba(234, 234, 234, 1) 100%);
  background: -webkit-gradient(linear, left top, right top, color-stop(0%, rgba(248, 248, 248, 1)), color-stop(100%, rgba(234, 234, 234, 1)));
  background: -webkit-linear-gradient(0deg, rgba(248, 248, 248, 1) 0%, rgba(234, 234, 234, 1) 100%);
  background: -o-linear-gradient(0deg, rgba(248, 248, 248, 1) 0%, rgba(234, 234, 234, 1) 100%);
  background: -ms-linear-gradient(0deg, rgba(248, 248, 248, 1) 0%, rgba(234, 234, 234, 1) 100%);
  background: linear-gradient(90deg, rgba(248, 248, 248, 1) 0%, rgba(234, 234, 234, 1) 100%);
}

.jqx-calendar-cell-material {
  border-radius: 50%;
  font-size: 12px;
}

.jqx-calendar-cell-year-material {
  border-radius: 25%;
}

.jqx-calendar-cell-decade-material {
  border-radius: 25%;
}

.jqx-calendar-title-content-material {
  font-weight: bold;
}

.jqx-calendar-column-cell-material {
  color: rgba(0, 0, 0, .38);
  font-size: 12px;
}

.jqx-tabs-close-button-material {
  background-image: url('#{$image-path}/jqx.material/close.png');
  background-repeat: no-repeat;
  background-position: center;
}

.jqx-tabs-close-button-selected-material {
  background-image: url('#{$image-path}/jqx.material/close.png');
  background-repeat: no-repeat;
  background-position: center;
}

.jqx-tabs-close-button-hover-material {
  background-image: url('#{$image-path}/jqx.material/close.png');
  background-repeat: no-repeat;
  background-position: center;
}

.jqx-grid-group-collapse-rtl-material {
  padding-right: 0px;
  background-image: url('#{$image-path}/jqx.material/material-icon-left.png');
  background-position: 50% 50%;
  background-repeat: no-repeat;
}

.jqx-grid-group-expand-rtl-material {
  padding-right: 0px;
  background-image: url('#{$image-path}/jqx.material/material-icon-down.png');
  background-position: 50% 50%;
  background-repeat: no-repeat;
}

.jqx-icon-arrow-first-material {
  background-image: url('#{$image-path}/jqx.material/material-icon-first.png');
  background-repeat: no-repeat;
  background-position: center;
}

.jqx-icon-arrow-last-material {
  background-image: url('#{$image-path}/jqx.material/material-icon-last.png');
  background-repeat: no-repeat;
  background-position: center;
}

.jqx-icon-arrow-first-hover-material {
  background-image: url('#{$image-path}/jqx.material/material-icon-first.png');
  background-repeat: no-repeat;
  background-position: center;
}

.jqx-icon-arrow-last-hover-material {
  background-image: url('#{$image-path}/jqx.material/material-icon-last.png');
  background-repeat: no-repeat;
  background-position: center;
}

.jqx-icon-arrow-first-selected-material {
  background-image: url('#{$image-path}/jqx.material/material-icon-first.png');
  background-repeat: no-repeat;
  background-position: center;
}

.jqx-icon-arrow-last-selected-material {
  background-image: url('#{$image-path}/jqx.material/material-icon-last.png');
  background-repeat: no-repeat;
  background-position: center;
}

.jqx-primary {
  .jqx-icon-arrow-down-material {
    background-image: url('#{$image-path}/jqx.material/material-icon-down.png');
  }

  .jqx-icon-arrow-down-selected-material {
    background-image: url('#{$image-path}/jqx.material/material-icon-down.png');
  }

  .jqx-icon-arrow-down-hover-material {
    background-image: url('#{$image-path}/jqx.material/material-icon-down.png');
  }

  .jqx-icon-arrow-up-material {
    background-image: url('#{$image-path}/jqx.material/material-icon-up.png');
  }

  .jqx-icon-arrow-up-selected-material {
    background-image: url('#{$image-path}/jqx.material/material-icon-up.png');
  }

  .jqx-icon-arrow-up-hover-material {
    background-image: url('#{$image-path}/jqx.material/material-icon-up.png');
  }

  .jqx-icon-arrow-left-material {
    background-image: url('#{$image-path}/jqx.material/material-icon-left.png');
  }

  .jqx-icon-arrow-left-selected-material {
    background-image: url('#{$image-path}/jqx.material/material-icon-left.png');
  }

  .jqx-icon-arrow-left-hover-material {
    background-image: url('#{$image-path}/jqx.material/material-icon-left.png');
  }

  .jqx-icon-arrow-right-material {
    background-image: url('#{$image-path}/jqx.material/material-icon-right.png');
  }

  .jqx-icon-arrow-right-selected-material {
    background-image: url('#{$image-path}/jqx.material/material-icon-right.png');
  }

  .jqx-icon-arrow-right-hover-material {
    background-image: url('#{$image-path}/jqx.material/material-icon-right.png');
  }
}

.jqx-warning {
  .jqx-icon-arrow-down-material {
    background-image: url('#{$image-path}/jqx.material/material-icon-down.png');
  }

  .jqx-icon-arrow-down-selected-material {
    background-image: url('#{$image-path}/jqx.material/material-icon-down.png');
  }

  .jqx-icon-arrow-down-hover-material {
    background-image: url('#{$image-path}/jqx.material/material-icon-down.png');
  }

  .jqx-icon-arrow-up-material {
    background-image: url('#{$image-path}/jqx.material/material-icon-up.png');
  }

  .jqx-icon-arrow-up-selected-material {
    background-image: url('#{$image-path}/jqx.material/material-icon-up.png');
  }

  .jqx-icon-arrow-up-hover-material {
    background-image: url('#{$image-path}/jqx.material/material-icon-up.png');
  }

  .jqx-icon-arrow-left-material {
    background-image: url('#{$image-path}/jqx.material/material-icon-left.png');
  }

  .jqx-icon-arrow-left-selected-material {
    background-image: url('#{$image-path}/jqx.material/material-icon-left.png');
  }

  .jqx-icon-arrow-left-hover-material {
    background-image: url('#{$image-path}/jqx.material/material-icon-left.png');
  }

  .jqx-icon-arrow-right-material {
    background-image: url('#{$image-path}/jqx.material/material-icon-right.png');
  }

  .jqx-icon-arrow-right-selected-material {
    background-image: url('#{$image-path}/jqx.material/material-icon-right.png');
  }

  .jqx-icon-arrow-right-hover-material {
    background-image: url('#{$image-path}/jqx.material/material-icon-right.png');
  }
}

.jqx-danger {
  .jqx-icon-arrow-down-material {
    background-image: url('#{$image-path}/jqx.material/material-icon-down.png');
  }

  .jqx-icon-arrow-down-selected-material {
    background-image: url('#{$image-path}/jqx.material/material-icon-down.png');
  }

  .jqx-icon-arrow-down-hover-material {
    background-image: url('#{$image-path}/jqx.material/material-icon-down.png');
  }

  .jqx-icon-arrow-up-material {
    background-image: url('#{$image-path}/jqx.material/material-icon-up.png');
  }

  .jqx-icon-arrow-up-selected-material {
    background-image: url('#{$image-path}/jqx.material/material-icon-up.png');
  }

  .jqx-icon-arrow-up-hover-material {
    background-image: url('#{$image-path}/jqx.material/material-icon-up.png');
  }

  .jqx-icon-arrow-left-material {
    background-image: url('#{$image-path}/jqx.material/material-icon-left.png');
  }

  .jqx-icon-arrow-left-selected-material {
    background-image: url('#{$image-path}/jqx.material/material-icon-left.png');
  }

  .jqx-icon-arrow-left-hover-material {
    background-image: url('#{$image-path}/jqx.material/material-icon-left.png');
  }

  .jqx-icon-arrow-right-material {
    background-image: url('#{$image-path}/jqx.material/material-icon-right.png');
  }

  .jqx-icon-arrow-right-selected-material {
    background-image: url('#{$image-path}/jqx.material/material-icon-right.png');
  }

  .jqx-icon-arrow-right-hover-material {
    background-image: url('#{$image-path}/jqx.material/material-icon-right.png');
  }
}

.jqx-success {
  .jqx-icon-arrow-down-material {
    background-image: url('#{$image-path}/jqx.material/material-icon-down.png');
  }

  .jqx-icon-arrow-down-selected-material {
    background-image: url('#{$image-path}/jqx.material/material-icon-down.png');
  }

  .jqx-icon-arrow-down-hover-material {
    background-image: url('#{$image-path}/jqx.material/material-icon-down.png');
  }

  .jqx-icon-arrow-up-material {
    background-image: url('#{$image-path}/jqx.material/material-icon-up.png');
  }

  .jqx-icon-arrow-up-selected-material {
    background-image: url('#{$image-path}/jqx.material/material-icon-up.png');
  }

  .jqx-icon-arrow-up-hover-material {
    background-image: url('#{$image-path}/jqx.material/material-icon-up.png');
  }

  .jqx-icon-arrow-left-material {
    background-image: url('#{$image-path}/jqx.material/material-icon-left.png');
  }

  .jqx-icon-arrow-left-selected-material {
    background-image: url('#{$image-path}/jqx.material/material-icon-left.png');
  }

  .jqx-icon-arrow-left-hover-material {
    background-image: url('#{$image-path}/jqx.material/material-icon-left.png');
  }

  .jqx-icon-arrow-right-material {
    background-image: url('#{$image-path}/jqx.material/material-icon-right.png');
  }

  .jqx-icon-arrow-right-selected-material {
    background-image: url('#{$image-path}/jqx.material/material-icon-right.png');
  }

  .jqx-icon-arrow-right-hover-material {
    background-image: url('#{$image-path}/jqx.material/material-icon-right.png');
  }
}

.jqx-info {
  .jqx-icon-arrow-down-material {
    background-image: url('#{$image-path}/jqx.material/material-icon-down.png');
  }

  .jqx-icon-arrow-down-selected-material {
    background-image: url('#{$image-path}/jqx.material/material-icon-down.png');
  }

  .jqx-icon-arrow-down-hover-material {
    background-image: url('#{$image-path}/jqx.material/material-icon-down.png');
  }

  .jqx-icon-arrow-up-material {
    background-image: url('#{$image-path}/jqx.material/material-icon-up.png');
  }

  .jqx-icon-arrow-up-selected-material {
    background-image: url('#{$image-path}/jqx.material/material-icon-up.png');
  }

  .jqx-icon-arrow-up-hover-material {
    background-image: url('#{$image-path}/jqx.material/material-icon-up.png');
  }

  .jqx-icon-arrow-left-material {
    background-image: url('#{$image-path}/jqx.material/material-icon-left.png');
  }

  .jqx-icon-arrow-left-selected-material {
    background-image: url('#{$image-path}/jqx.material/material-icon-left.png');
  }

  .jqx-icon-arrow-left-hover-material {
    background-image: url('#{$image-path}/jqx.material/material-icon-left.png');
  }

  .jqx-icon-arrow-right-material {
    background-image: url('#{$image-path}/jqx.material/material-icon-right.png');
  }

  .jqx-icon-arrow-right-selected-material {
    background-image: url('#{$image-path}/jqx.material/material-icon-right.png');
  }

  .jqx-icon-arrow-right-hover-material {
    background-image: url('#{$image-path}/jqx.material/material-icon-right.png');
  }
}

.ripple {
  position: relative;
  transform: translate3d(0, 0, 0);
  overflow: hidden;
}

.ink {
  display: block;
  position: absolute;
  pointer-events: none;
  border-radius: 0%;
  transform: scaleX(0);
  background: rgba(0, 119, 190, 0.5);
  opacity: 0.25;
}

.outlined {
  .ink {
    background: rgba(0, 119, 190, 0.5);
    overflow: hidden;
  }
}

.flat {
  .ink {
    background: rgba(0, 119, 190, 0.5);
    overflow: hidden;
  }
}

.ink.animate {
  animation: ripple .7s ease;
  animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}

.jqx-checkbox-default-material.active {
  .ink {
    opacity: 0.2;
    transform: scale(2);
  }
}

.jqx-radiobutton-default-material.active {
  .ink {
    opacity: 0.2;
    transform: scale(2);
  }
}

.buttonRipple {
  background-position: center;
  transition: background 0.8s;

  &:hover {
    background: #0077BE radial-gradient(circle, transparent 1%, #0077BE 1%) center/15000%;
  }

  &:active {
    background-color: #B3E5FC;
    background-size: 100%;
    transition: background 0s;

    &:not(:hover) {
      color: #0077BE;
    }
  }
}

.jqx-editor-toolbar-button-material {
  border-color: #ddd;
  box-shadow: none !important;
  color: #333;
}

.jqx-needle-central-circle-material {
  fill: rgb(0, 119, 190);
}

.jqx-needle-material {
  fill: rgb(0, 119, 190);
}

.jqx-time-picker {
  .jqx-header {
    .jqx-selected-material {
      &:focus {
        outline: 2px solid rgba(0, 119, 190, 0.5);
        box-shadow: 0px 0px 4px 2px rgba(0, 119, 190, 0.125);
      }
    }
  }
}

.jqx-svg-picker-material {
  &:focus {
    border: 1px solid rgb(0, 119, 190) !important;
  }
}


.jqx-scheduler-work-time-cell{
  background: #fff;
  border: #fff;
}
