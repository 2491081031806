.intro {
  position: relative;

  .content-top {
    @media screen and (max-width: 1024px) {
      order: 2;
      margin-top: 3em;
    }

    .wrap {
      display: flex;

      @media screen and (max-width: 1024px) {
        display: block;
      }
    }

    .col {
      padding: 0;
      width: calc(50% - 75px);
      flex: none;

      @media screen and (max-width: 1200px) {
        width: calc(50% - 25px);
      }

      @media screen and (max-width: 1024px) {
        width: 100%;
      }

      &~.col {
        margin-left: 150px;

        @media screen and (max-width: 1200px) {
          margin-left: 50px;
        }

        @media screen and (max-width: 1024px) {
          margin: 0;
          margin-top: 20px;
        }
      }
    }
  }

  &:after {
    width: 240px;
    height: 190px;
    content: '';
    background: url('#{$image-path}/back-deco-1.png') no-repeat center top;
    background-size: 100% auto;
    position: absolute;
    right: 0;
    top: 0;
    display: block;
    opacity: .2;
    z-index: 0;

    @media screen and (max-width: 1024px) {
      width: 35%;
      max-width: 200px;
    }
  }

  &:before {
    width: 276px;
    height: 210px;
    content: '';
    background: url('#{$image-path}/back-deco-2.png') no-repeat center top;
    background-size: 100% auto;
    position: absolute;
    left: 0;
    top: 290px;
    display: block;
    z-index: 0;

    @media screen and (max-width: 1024px) {
      width: 40%;
      max-width: 230px;
    }
  }
}

.intro__header {
  display: flex;
  align-items: center;
  width: 100%;
  height: 200px;
  justify-content: center;

  @media screen and (max-width: 1200px) {
    height: 150px;

    img {
      height: 80px;
    }
  }

  @media screen and (max-width: 1024px) {
    height: 70px;
    justify-content: flex-start;
    padding: 0 10px;

    img {
      height: 50px;
    }
  }

  @media screen and (max-width: 768px) {
    &.scrolled {
      position: fixed;
      left: 0;
      top: 0;
      width: 100%;
      background: #fff;
      z-index: 3000;
      box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.1);

    }
  }

  .mobile-home {
    margin-left: auto;
    display: none;
    position: relative;
    z-index: 10;

    @media screen and (max-width: 768px) {
      display: block;
    }

    a {
      display: flex;
      align-items: center;
      font-weight: 600;
      line-height: 16px;
      font-size: 14px;

      span {
        height: 45px;
        width: 45px;
        flex: none;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #f6f6f6;
        box-shadow: 0px 5px 10px rgba(68, 68, 68, .2);
        margin-right: 10px;
      }

      img {
        height: 20px;
      }

    }
  }
}



.intro__quick {
  margin-top: 4em;
  position: relative;
  z-index: 10;

  @media screen and (max-width: 1024px) {
    order: 1;
    margin-top: 3em;
  }

  @media screen and (max-width: 768px) {}

  .wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media screen and (max-width: 768px) {
      flex-wrap: wrap;
    }
  }

  a {
    width: 160px;
    flex: none;
    position: relative;
    top: 0;
    box-shadow: none;
    transition: all .5s;

    @media screen and (max-width: 1200px) {
      width: 140px;
    }

    @media screen and (max-width: 1024px) {
      width: 120px;
    }

    @media screen and (max-width: 768px) {
      width: 50%;
      flex: none;

      &:last-child {
        display: none;
      }
    }


    &:hover {
      top: -5px;
    }

    &:nth-child(n + 3) {
      margin-top: 20px;
    }

    p {
      font-size: 1rem;
      margin-top: 1.5em;
      text-align: center;
      letter-spacing: -.04em;

      @media screen and (max-width: 768px) {
        font-size: 16px;
        margin-top: 1em;
      }
    }

    div {
      width: 100%;
      padding-bottom: 100%;
      border-radius: 2em;
      position: relative;

      @media screen and (max-width: 768px) {
        width: 140px;
        margin: 0 auto;
        height: 140px !important;
        padding-bottom: 0;
      }

      @media screen and (max-width: 450px) {
        width: 120px;
        height: 120px !important;
      }

      img {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }


    }

    &.q1 {
      div {
        background: #eff8f1;
        box-shadow: 0px 5px 10px rgba(34, 87, 46, .2);
      }
    }

    &.q2 {
      div {
        background: #fffae3;
        box-shadow: 0px 5px 10px rgba(142, 150, 72, .2);
      }
    }

    &.q3 {
      div {
        background: #eff8fd;
        box-shadow: 0px 5px 10px rgba(72, 136, 150, .2);
      }
    }

    &.q4 {
      div {
        background: #fff5f5;
        box-shadow: 0px 5px 10px rgba(88, 13, 14, .2);
      }
    }

    &.q5 {
      div {
        background: #f6f6f6;
        box-shadow: 0px 5px 10px rgba(68, 68, 68, .2);
      }
    }
  }
}

.intro__board {
  .intro__board-header {
    display: flex;
    align-items: center;
    height: 50px;

    @media screen and (max-width: 768px) {}

    .more {
      font-size: 1.5rem;
    }
  }

  .intro__board-tabs {
    display: flex;
    align-items: center;

    button {
      color: #999999;
      font-size: 1.25rem;
      letter-spacing: -.04em;
      padding: 0 .5em;

      @media screen and (max-width: 768px) {
        padding: 0 .25em;
      }

      &~button {
        margin-left: 1em;

        @media screen and (max-width: 768px) {
          margin-left: .5em;
        }
      }

      &.active {
        position: relative;
        font-weight: 700;
        color: #333;

        &:after {
          content: '';
          background: #489659;
          border-radius: 2em;
          height: 5px;
          position: absolute;
          left: 0;
          bottom: -8px;
          width: 100%;
          box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
        }
      }
    }
  }

  .more {
    margin-left: auto;
  }

  .intro__board-body {
    margin-top: 25px;

    @media screen and (max-width: 768px) {
      margin-top: 15px;
    }

    a {
      display: flex;
      justify-content: space-between;
      align-items: center;
      line-height: 2em;

      p {
        flex: 1;
        max-width: calc(100% - 80px);
        color: #333;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        &:before {
          content: '·';
          margin-right: 5px;
        }
      }

      span {
        color: #666;
      }
    }
  }
}

.intro__popupzone {

  .slide-controller {
    display: flex;
    align-items: center;
    margin-left: auto;
    justify-content: center;

    span {
      line-height: 30px;
      position: relative;
      top: 3px;
      margin-right: 10px;
      font-weight: 600;
    }

    .arrow {
      font-size: 1.25rem;
    }

    .auto {
      margin: 0 10px;
    }
  }

  .intro__popupzone-header {
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    h3 {
      font-size: 1.25rem;
      font-weight: 700;
      color: #333;
    }
  }

  .intro__popupzone-body {
    margin-top: 10px;

    img {
      display: block;
      width: 100%;
    }
  }
}

.intro__section-1 {
  @media screen and (max-width: 1024px) {
    display: flex;
    flex-direction: column;
  }
}

.intro__section-2 {
  background: #fff;
  margin-top: 40px;
  padding: 0;



  @media screen and (max-width: 1024px) {
    margin-top:0;
  }


}

.section-title {
  font-size: 1.75rem;
  font-weight: 700;
  color: #333;
  text-align: center;

  @media screen and (max-width: 768px) {
    font-size: 1.3rem;
  }
}

.intro__collage {
  display: grid;
  grid-template-columns: 24% 24% 24% 24%;
  justify-content: space-between;
  margin-top: 2.5em;
  background-color: #fff;
  grid-row-gap:15px;


  @media screen and (max-width: 1024px) {
    grid-template-columns: 32% 32% 32%;
    grid-row-gap:15px;
    margin-top:10px;
    .collage-item{
      max-width:100%;
    }
  }

  @media screen and (max-width: 768px) {
    grid-template-columns: 32% 32% 32%;
    grid-row-gap:15px;

    .collage-item{
      max-width:100%;
    }
  }

  @media screen and (max-width: 568px) {
    grid-template-columns: 49% 49%;
    grid-row-gap:15px;

    .collage-item{
      max-width:100%;
    }
  }

  .slick-slider {
    display: block;
    margin: 0 auto;
    width: 100%;
    max-width: 900px;

    .slick-next {
      background: url('#{$image-path}/main_slide_right_button.png') no-repeat;
      color: #000;
      width: 14px;
      height: 28px;

      @media screen and (max-width: 768px) {
        display: none !important;
      }

    }

    .slick-next:before {
      display: none;
    }

    .slick-prev {
      background: url('#{$image-path}/main_slide_left_button.png') no-repeat;
      color: #000;
      width: 14px;
      height: 28px;

      @media screen and (max-width: 768px) {
        display: none !important;
      }
    }

    .slick-prev:before {
      display: none;
    }

    .slick-dots {
      bottom: -60px;

      @media screen and (max-width: 768px) {
        bottom: -40px;
      }

      li {
        width: auto;
        height: auto;
      }

      .slick-active {
        div {
          background-color: #5f616e;
          color: #fff !important;
          border-radius: 5px;
        }
      }
    }


    .slick-slide {
      div {
        height: 138px;
        display: flex;
        align-items: center;

        @media screen and (max-width: 768px) {
          height:64px;
        }

        .collage-item {
          margin: 0 auto;
          display: block !important;

          .thumb {
            img {
              margin: 0 auto;
              display: block;
            }
          }

        }
      }
    }
  }
}

.collage-item {
  background: #fff;
  border: 1px solid #ddd;
  width: 100%;
  max-width: 220px;
  position: relative;
  top: 0;
  box-shadow: none;
  transition: all .5s;
  max-height: 133px;
  border-radius: 5px;

  //margin-top: 25px;
  &:nth-child(n + 6) {
    margin-top: 1.5em;
  }

  // &:hover {
  //   border: 1px solid #00325a;
  //   border-radius: 1em;
  // }


  .info {
    padding: 1.25em 1em;

    .summery {
      text-align: center;
      color: #555;
      font-size: 14px;
      margin-bottom: 15px;
    }

    ul {
      li {
        line-height: 20px;
        padding-left: 2em;
        font-size: 14px;
        color: #333333;
        font-weight: 600;

        a {
          font-size: 14px;
          color: #333333;
          font-weight: 600;
        }

        &~li {
          margin-top: 8px;
        }
      }

      .name {
        background: url('#{$image-path}/col-icon-1.png') no-repeat left center;
      }

      .number {
        background: url('#{$image-path}/col-icon-2.png') no-repeat left center;
      }
    }
  }

  .intro_thumb {
    padding: unset !important;
  }

  .thumb {
    //border-bottom: 1px solid #eee;
    line-height: 130px;
    text-align: center;
    padding: 0 1em;

    @media screen and (max-width: 768px) {
      line-height: 100px;
    }

    img {
      vertical-align: middle;
    }

    span {
      font-size: 14px;
    }
  }
}


.intro__pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 50px;

  @media screen and (max-width: 768px) {
    margin-top: 30px;
  }

  button {
    margin: 0 5px;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;

    span {
      font-size: 16px;
      line-height: 16px;
    }
  }

  .numbers {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 10px;

    @media screen and (max-width: 768px) {
      margin: 0 5px;
    }
  }

  .arrow {
    text-indent: -9999px;
    overflow: hidden;

    &.arrow--prev {
      background:url('#{$image-path}/intro-paging-arrow-left.png') no-repeat center;
    }

    &.arrow--next {
      background:url('#{$image-path}/intro-paging-arrow-right.png') no-repeat center;
    }
  }

  .number {
    color: #3a3a4a;


    &.active {
      background: #3a3a4a;
      color: #fff;
      border-radius: 5px;
    }
  }
}

.intro__footer {
  background: #333333;
  padding: 0 0 40px;
  padding-top:40px;
  position: relative;

  @media screen and (max-width: 1024px) {
    padding: 30px 0;
  }

  @media (max-width: 768px) {
    padding: 0;
    padding-bottom:30px;
  }

  

  .wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;

    .top_btn {
      width: 50px;
      height: 50px;
      background: #040404;
      text-align: center;
      position: absolute;
      right: -52px;
      top: -40px;
  
      // top: -50px;
      img {
        margin-top: 40%;
      }
      
      @media screen and (max-width: 1024px) {
        right: 30px;
        top:-30px;
      }

      @media screen and (max-width: 768px) {
        right: 0;
        top:0;
      }


      @media screen and (max-width: 360px) {
        display: none;
      }
  
    }

    @media screen and (max-width: 1280px) {
      flex-wrap: wrap;
      width:90%
    }

    @media screen and (max-width: 768px) {
      display: block;
      text-align: center;


      // img {
      //   width: 70px;
      // }
    }

    a {
      // flex: none;

      img {
        // margin-top: 13px;
      }
    }

    em {
      font-style: normal;
    }

    p {
      //flex: 1;
      color: #888888;

      // padding-left:2.5em;
      // padding-top: 44px;
      @media screen and (max-width: 768px) {
        text-align: left;

        .bottom_span{
          margin-top:10px;
        }
        em {
          display: block;
          opacity: .5;
          font-size: 12px;
        }
      }

      span {
        color: #aaa;
        line-height: 1rem;
        // margin-bottom: 1em;
        display: block;
        font-size: 12px;

        @media (max-width: 424px) {
          letter-spacing: -0.3px;
        }

        i {
          font-style: normal;
          font-size: 13px;

          @media (max-width: 510px) {
            display: block;
          }
        }

        u {
          text-decoration: none;

          @media (max-width: 510px) {
            display: none;
          }
        }

        &.bottom_span {
          font-size: 13px;

          @media (max-width: 424px) {
            font-size: 12px;
            letter-spacing: -0.3px;
          }
        }
      }
    }

    .visit_container {
      color: #d1d1d1;
      // width: 100%;
      width: 30%;
      // max-width: 480px;
      // margin-left: 50px;
      // padding: 0 50px;
      font-weight: 200;

      @media (max-width: 1280px) {
        flex-basis: 35%;
        width: 35%;
        text-align: right;
      }

      @media (max-width: 768px) {
        max-width: 100%;
        width: 100%;
        margin: 20px 0 0 0;
        padding: 20px 20px 0 20px;
        border-top: 2px solid #676767;
      }

      div {
        display: inline-block;
      }

      span {
        font-size: 14px;

      }

      .visit_left {
        padding-right: 10px;

        @media screen and (max-width:1280px) {
          width: 70%;
          text-align: center;
          padding-bottom: 10px;
        }

        @media screen and (max-width:768px) {
          width: 45%;
          text-align: center;
          padding-bottom: 0;
          padding-right: 10px;
        }
      }

      .visit_right {
        width: 52%;
        border-left: 2px solid #676767;
        padding-left: 35px;

        @media screen and (max-width:1280px) {
          border-top: 2px solid #676767;
          padding-top: 10px;
          border-left: none;
          width: 70%;
          padding-left: 0px;
          text-align: center;
        }

        @media screen and (max-width:768px) {
          border-top: none;
          padding-top: 10px;
          border-left: 2px solid #676767;
          padding-left: 35px;
          width: 45%;
          padding-left: 0px;
          text-align: center;
        }
      }

      .visit_title {
        display: block;
        text-align: center;
        margin-bottom: 25px;

        @media screen and (max-width:1280px) {
          display: none;
        }

        @media screen and (max-width:768px) {
          display: block;
        }

        @media (max-width: 424px) {
          font-size: 13px;
          letter-spacing: -0.3px;
        }

      }

      .visit_img {
        margin-right: 10px;
        vertical-align: sub;

        img {
          max-width: 50px;
        }

        &+div {
          @media (max-width: 460px) {
            display: block;
          }
        }
      }

      .visit_num {
        font-size: 18px;
        font-weight: 400;
        display: block;

        @media (max-width: 460px) {
          font-size: 17px;
        }
      }
    }
  }
}

.logo_footer {
  min-height: 100px;
  display: flex;
  justify-content: center;

  @media screen and (max-width:1280px) {
    flex-basis: 100%;
    justify-content: flex-start;
  }
}

.footer_center {
  min-height: 100px;
  height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media screen and (max-width:1280px) {
    flex-basis: 60%;
    height: auto;
  }


  ul {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-bottom: 25px;


    @media screen and (max-width:768px) {
      margin-bottom:10px;
    }

    span {
      color: #565656;
      font-weight: 700;
      padding: 5px 0;
    }

    li {
      a {
        color: #DADADA;
        font-size: 13px;
      }
    }
  }
}