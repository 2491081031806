@charset "utf-8";
.scbd {
  font-size: 16px;
  font-family: 'Apple SD Gothic Neo', NanumGothic, Helvetica, Arial, Dotum, sans-serif;
  text-align: left;

  h1 {
    // font-family: 'Malgun Gothic', sans-serif;
  }

  h2 {
    // font-family: 'Malgun Gothic', sans-serif;
  }

  h3 {
    // font-family: 'Malgun Gothic', sans-serif;
  }

  h4 {
    // font-family: 'Malgun Gothic', sans-serif;
  }

  h5 {
    // font-family: 'Malgun Gothic', sans-serif;
  }

  h6 {
    // font-family: 'Malgun Gothic', sans-serif;
  }

  p {
    // font-family: 'Malgun Gothic', sans-serif;
    // -webkit-margin-before: .5em;
    // -webkit-margin-after: .5em;
  }

  div {
    // font-family: 'Malgun Gothic', sans-serif;
  }

  li {
    // font-family: 'Malgun Gothic', sans-serif;
  }

  dt {
    // font-family: 'Malgun Gothic', sans-serif;
  }

  dd {
    // font-family: 'Malgun Gothic', sans-serif;
  }

  input {
    // font-family: 'Malgun Gothic', sans-serif;
  }

  textarea {
    // font-family: 'Malgun Gothic', sans-serif;
  }

  button {
    // font-family: 'Malgun Gothic', sans-serif;
    cursor: pointer;
    vertical-align: middle;
  }

  form {
    margin: 0;
  }

  fieldset {
    margin: 0;
    padding: 0;
    border: 0;
  }

  a {
    text-decoration: none;
  }

  label {
    cursor: pointer;
    vertical-align: middle;
  }

  select {
    border: 1px solid #999;
    margin-left: 0;
    margin-right: 0;
  }

  table {
    border-collapse: collapse;
    border-spacing: 0;
  }

  th {
    font-size: 1em;
    font-family: inherit;
    color: inherit;
    padding: 0;
  }

  td {
    font-size: 1em;
    font-family: inherit;
    color: inherit;
    padding: 0;
  }

  img {
    border: 0;
  }

  input[type="radio"] {
    vertical-align: middle;
  }

  input[type="checkbox"] {
    vertical-align: middle;
  }

  .blind {
    overflow: hidden;
    width: 0;
    height: 0;
    font-size: 0;
    line-height: 0;
    visibility: hidden;
  }

  .block {
    display: block;
  }

  .clear {
    clear: both;
  }

  .clr {
    *zoom: 1;

    &:after {
      content: '';
      display: block;
      clear: both;
    }
  }

  .ui-input {
    display: inline-block;
    margin: .1em 0;
    padding: .1em .4em;
    vertical-align: middle;

    input {
      margin: 0;
      padding: 0;
      background: transparent;
      border: 0;
      font-size: 14px;
      height: 28px;
      line-height: 28px;
    }

    textarea {
      margin: 0;
      background: transparent;
      border: 0;
      font-size: 14px;
      padding: .2em 0;
    }
  }

  .ipt-block {
    display: block;

    input {
      width: 100%;
    }

    textarea {
      width: 100%;
    }
  }

  .ipt-sml {
    input {
      height: 20px;
      line-height: 20px;
      font-size: 12px;
    }
  }

  .ui-btn {
    display: inline-block;
    padding: .6em 1em;
    margin: .1em 0;
    cursor: pointer;
    vertical-align: middle;
    font-size: 14px;
    background: #ddd;
  }

  button.ui-btn {
    border: 0;
  }

  .btn-block {
    display: block !important;
    text-align: center;
  }

  button.btn-block {
    width: 100%;
    display: block;
  }

  .btn-bold {
    font-weight: bold;
  }

  .btn-sml {
    font-size: 12px;
    padding: .45em .8em .3em;
  }

  .ui-ico {
    //background-image: url('/img_up/board_tpl/rwd/pc/img/sp_basic_1x.png');
    background-repeat: no-repeat;
  }

  .ui-ico.reply {
    display: inline-block;
    width: 10px;
    height: 9px;
    background-position: -165px 0;
    overflow: hidden;
    text-indent: -9999px;
  }

  .empty {
    display: block !important;
    padding: 2em 0 !important;
    width: auto !important;
    float: none !important;
    font-size: .875em;
    text-align: center;
    border-bottom: 1px solid #ccc;
  }

  .noneLst {
    padding: 0;
    margin: 0;
    list-style: none;
  }

  .sns {
    span {
      padding-right: .8em;
    }

    .ui-ico {
      display: inline-block;
      width: 16px;
      height: 16px;
      text-indent: -9999px;
    }

    .ui-ico.twitter {
      background-position: -76px -40px;
    }

    .ui-ico.me2day {
      background-position: -96px -40px;
    }

    .ui-ico.cyworld {
      background-position: -116px -40px;
    }

    .ui-ico.facebook {
      background-position: -136px -40px;
    }

    .ui-ico.mypage {
      background-position: -156px -40px;
    }
  }

  .ui-toggle {
    .hd {
      position: relative;
      border-bottom: 1px solid #ccc;
      cursor: pointer;

      h1 {
        margin: 0;
        font-weight: normal;
        font-size: .875em;
        padding: .6em;
      }

      p {
        position: absolute;
        margin: -7px 0 0 0;
        top: 50%;
        right: .5em;

        span {
          font-size: .75em;

          i {
            display: inline-block;
            width: 10px;
            height: 6px;
            margin: 0 0 0 .3em;
            vertical-align: middle;
          }
        }

        span.open {
          display: block;

          i {
            background-position: -103px 0;
          }
        }

        span.close {
          display: none;

          i {
            background-position: -123px 0;
          }
        }
      }
    }

    .bd {
      display: none;
    }
  }

  .ui-toggle.active {
    .hd {
      p {
        span.close {
          display: block;
        }

        span.open {
          display: none;
        }
      }
    }

    .bd {
      display: block;
    }
  }

  .paginate {
    margin: 0;
    padding: 1em 0;
    text-align: center;

    a {
      display: inline-block;
      width: 36px;
      height: 36px;
      line-height: 36px;
      vertical-align: middle;
      font-size: .875em;
      font-family: Arial, sans-serif;
      margin: 0 .2em;
    }

    strong {
      display: inline-block;
      width: 36px;
      height: 36px;
      line-height: 36px;
      vertical-align: middle;
      font-size: .875em;
      font-family: Arial, sans-serif;
      margin: 0 .2em;
      font-weight: normal;
    }

    .dir {
      font-family: Tahoma, sans-serif;
      font-size: 1em;
    }
  }

  .conbody {
    overflow: hidden;
    margin: .5em;
    font-size: 14px;
    word-break: break-all;

    img {
      width: auto;
      max-width: 100%;
      vertical-align: top;
    }

    p {
      word-break: break-all;
    }
  }

  .qnabox {
    .box {
      margin: .5em;
      padding: .5em;
      border: 3px solid #000;
    }

    strong {
      display: block;
      font-size: .875em;
      padding: 0 .3em .5em;
      border-bottom: 1px dashed #000;
    }

    .conbody {
      margin: .5em .3em;
    }
  }

  .hd_select {
    padding: .4em;

    span {
      display: inline-block;
      margin: .1em 0;
    }

    label {
      margin-right: 1em;
      font-size: .75em;
      font-weight: bold;
    }

    select {
      height: 24px;
      line-height: 24px;
      font-size: 14px;
    }

    i {
      font-style: normal;
    }
  }

  .hd_list {
    .bd {
      ul {
        margin: 0;
        padding: 0;
        list-style: none;

        li {
          a {
            display: block;
            padding: .6em .5em;
            font-size: .75em;
            border-bottom: 1px solid #000;
          }
        }
      }
    }
  }

  .lay_hd {
    padding-bottom: 2px;

    .hgroup {
      position: relative;
      padding: .7em 0 .6em;
      min-height: 18px;
      *height: 18px;

      h1 {
        margin: 0;
        font-weight: bold;
        font-size: 1em;
        padding-left: .5em;
      }

      ul {
        position: absolute;
        right: 0;
        top: 0;
        height: 100%;
        margin: 0;
        padding: 0;
        list-style: none;

        &:after {
          content: '';
          display: block;
          clear: both;
        }

        li {
          float: left;
          height: 100%;

          a {
            position: relative;
            display: block;
            height: 100%;
            width: 42px;
            text-indent: -9999px;

            i {
              position: absolute;
              left: 50%;
              top: 50%;
              margin: -9px 0 0 -9px;
              display: block;
              width: 19px;
              height: 18px;
            }

            i.search {
              background-position: 0 0;
            }

            i.write {
              background-position: -38px 0;
            }
          }
        }
      }
    }
  }

  .toggleSearch {
    padding: .4em .5em;
    display: none;

    div {
      position: relative;
      border: 1px solid #000;
      padding: .2em 2.5em .2em .4em;

      input {
        background: transparent;
        border: 0;
        margin: 0;
        padding: 0;
        width: 100%;
        height: 24px;
        line-height: 24px;
        font-size: .875em;
      }

      button {
        position: absolute;
        right: 6px;
        top: 50%;
        margin-top: -11px;
        border: 0;
        display: block;
        width: 23px;
        height: 23px;
        text-indent: -9999px;
        background-color: transparent;
        background-position: -74px 2px;
      }
    }
  }

  .lst-board {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  .lst-board.lst-head {
    display: none;
    font-size: .75em;

    li {
      div {
        padding: 1em 0;
        font-weight: bold;
      }
    }
  }

  .lst-board.lst-body {
    li {
      padding: .5em;
      border-bottom: 1px solid #000;

      .col_no {
        display: none;
      }

      .col_subject {
        a {
          display: block;
          font-size: .875em;
          font-weight: bold;
          padding-bottom: .2em;
        }

        span {
          vertical-align: middle;
        }

        img {
          vertical-align: middle;
        }
      }

      .col_name {
        img {
          vertical-align: middle;
          height: 16px;
        }

        b {
          vertical-align: middle;
          font-weight: normal;
        }
      }

      .inf {
        float: left;
        font-size: .813em;
        padding-right: .5em;
        margin-right: .4em;
        border-right: 1px solid #ccc;

        &:last-child {
          border-right: 0;
        }
      }
    }
  }

  .lst-board.lst-body.lay-notice {
    li {
      position: relative;
      padding-right: 2em;

      .falseNotice {
        position: absolute;
        right: .5em;
        top: 50%;
        margin-top: -8px;
        width: 16px;
        height: 16px;
        overflow: hidden;
        text-indent: -9999px;
        background-position: -141px 2px;
      }
    }
  }

  .lst-board.lst-body.lay-reply {
    margin: .5em 0;
  }

  .lst-photo {
    margin: 0;
    list-style: none;
    padding: .8em;
    text-align: left;

    li {
      display: inline-block;
      margin: .8em .4em;
      text-align: center;
      vertical-align: top;

      a {
        display: block;
        font-size: .813em;
        text-decoration: none;
      }

      .thum {
        margin-bottom: .4em;

        span {
          display: block;
          border: 1px solid #000;

          i {
            display: block;
            font-family: Arial, sans-serif;
          }
        }

        .img {
          overflow: hidden;
          border: 0;
          background: transparent;

          img {
            width: 100%;
            height: auto;
            display: block;
          }
        }
      }

      .title {
        display: block;
        margin-bottom: .2em;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .category {
        padding: 0 0 .2em;
      }

      .sm {
        font-size: .769em;
        font-family: Tahoma;
      }

      .name {
        img {
          height: 16px;
          vertical-align: middle;
        }
      }

      .noimg {
        min-height: 80px;
        line-height: 80px;
      }
    }
  }

  .lst-web {
    margin: 0;
    padding: 0;
    list-style: none;

    li {
      &:after {
        content: '';
        display: block;
        clear: both;
      }
    }

    dl {
      margin: 0;
    }

    dt {
      float: left;
      margin-right: .5em;
      margin-bottom: .3em;
      overflow: hidden;

      img {
        width: 100%;
      }
    }

    dd {
      margin: 0;

      .inf {
        display: inline-block;
        vertical-align: top;
        float: none !important;
      }
    }

    .conbody {
      margin-top: .8em;
      margin-left: 0;
      line-height: 140%;
      margin-bottom: 0;
    }
  }

  .lst-tab {
    margin: 0;
    padding: 0;
    list-style: none;

    li {
      .tab {
        cursor: pointer;
        padding: .5em;
      }

      .conbody {
        display: none;
        margin-top: 0;
        padding: .5em;

        img {
          max-width: 100%;
        }

        .btn {
          margin-top: 1em;
        }
      }
    }

    li.active {
      .tab {
        border-bottom: 1px dashed #000;
      }

      .conbody {
        display: block;
      }
    }
  }

  .lst-tab.lst-body {
    li {
      padding: 0 !important;
    }
  }

  .lst-cal {
    .nav {
      position: relative;

      strong {
        display: block;
        padding: .6em 2.5em .5em;
        overflow: hidden;
        text-align: center;
        font-size: 1.125em;
        font-weight: normal;
      }

      a {
        display: block;
        overflow: hidden;
        text-indent: -9999px;
        margin-top: -13px;
        position: absolute;
        top: 50%;
        width: 25px;
        height: 26px;
      }

      a.prev {
        background-position: -180px 2px;
        left: .8em;
      }

      a.next {
        background-position: -202px 2px;
        right: .8em;
      }
    }

    table {
      width: 100%;

      td {
        border: 1px solid #000;
        vertical-align: top;
      }

      .lst-board {
        display: none;
      }

      .day {
        text-align: center;
        padding: .7em 0 .6em;
      }

      .exist {
        .day {
          cursor: pointer;
          position: relative;

          .ui-ico {
            position: absolute;
            width: 14px;
            height: 14px;
            left: 0;
            top: 0;
            display: block;
            background-position: -231px 0;
          }
        }
      }
    }

    .docList {
      li {
        &:after {
          content: '';
          display: block;
          clear: both;
        }
      }
    }
  }

  .list_blog {
    margin: 0;
    padding: 0;
    list-style: none;

    .det {
      margin: 0;
      border-top: 2px solid #000;

      &:last-child {
        padding-bottom: .8em;
        margin-bottom: 0;
      }
    }
  }

  .list_reply {
    margin: 0;
  }

  .write {
    dl {
      margin: 0;
      padding: .5em 0;
      border-bottom: 1px solid #000;

      dt {
        font-size: .875em;
        font-weight: bold;
        margin-bottom: .2em;

        label {
          padding-left: .5em;
        }
      }

      dd {
        margin: 0;
        padding: 0 .5em;
        font-size: .75em;

        p {
          margin: 0;
        }
      }
    }

    ul {
      margin: 0 .5em;
      padding: .3em;
      list-style: none;

      li {
        font-size: .875em;
        margin: .3em 0;
      }
    }

    .multiSelect {
      width: 100%;
    }

    .editor {
      border: 1px solid #000;
      display: block;
      margin-top: .2em;
      font-size: 12px;
    }

    .attachFileList {
      margin-top: 1em;

      strong {
        display: block;
        margin-bottom: .2em;
      }

      div {
        position: relative;
        padding-right: 80px;
        margin-bottom: .3em;

        select {
          margin: 0;
          height: 70px;
        }

        a {
          position: absolute;
          width: 70px;
          height: 70px;
          line-height: 70px;
          right: 0;
          top: 0;
          margin: 0;
          padding: 0;
          text-align: center;
          font-size: 1em;
          background: gray;
        }
      }

      p {
        span {
          vertical-align: middle;
        }
      }
    }

    .etc {
      font-size: .875em;
      margin: 0;
    }

    .question {
      margin: .5em 0;
      padding: .5em .5em;
      border: 3px solid #000;

      strong {
        display: block;
        padding: .3em .3em .3em;
        margin-bottom: .5em;
        font-size: 1.167em;
        border-bottom: 1px dashed #000;
      }
    }
  }

  .det {
    .hgroup {
      padding: .8em .5em .7em;
      margin-bottom: .5em;
      border-bottom: 1px dashed #000;

      strong {
        font-size: 1em;

        span {
          vertical-align: middle;
        }

        img {
          vertical-align: middle;
        }
      }

      dl {
        margin: .3em 0 0 0;
        font-size: .75em;

        &:after {
          content: '';
          display: block;
          clear: both;
        }

        dt {
          float: left;

          span {
            display: block;
            float: left;
            padding-right: .5em;
            margin-right: .5em;
            border-right: 1px solid #ccc;

            b {
              vertical-align: middle;
              font-weight: normal;
            }

            img {
              vertical-align: middle;
              height: 16px;
            }
          }
        }

        dd {
          float: right;
        }
      }
    }

    .bgm {
      font-size: .75em;
      padding: .3em .5em;
      text-align: right;

      img {
        vertical-align: middle;
      }

      span {
        vertical-align: middle;
      }

      marquee {
        vertical-align: middle;
      }

      embed {
        vertical-align: middle;
      }
    }

    .copyurl {
      padding: .3em .5em;
      text-align: right;
      font-size: .75em;
      font-family: arial, sans-serif;

      a {
        display: inline-block;
        vertical-align: middle;
      }

      .btn {
        display: inline-block;
        padding: .5em .8em .45em;
        margin-left: .3em;
        font-family: Arial;
        font-size: 10px;
        text-align: center;
        font-weight: bold;
      }
    }

    .vote {
      text-align: center;
      margin: .5em;
    }

    .snsShare {
      margin: 0 .5em .5em .5em;
      padding: .5em;

      strong {
        display: block;
        font-size: .875em;
        margin-bottom: .3em;
      }

      .ui-ico {
        display: inline-block;
        width: 25px;
        height: 25px;
        text-indent: -9999px;
      }

      .twitter {
        background-position: -76px -80px;
      }

      .me2day {
        background-position: -111px -80px;
      }

      .cyworld {
        background-position: -146px -80px;
      }

      .facebook {
        background-position: -181px -80px;
      }

      .mypage {
        background-position: -216px -80px;
      }
    }

    .mid_design {
      margin: .5em;
    }

    .attachFiles {
      ul {
        margin: 0;
        padding: 0;
        list-style: none;

        li {
          padding: .6em .5em;
          font-size: .75em;
        }
      }
    }

    .sbtns {
      margin: 1em .5em .5em .6em;
      text-align: right;
      float: right;

      a {
        display: block;
        float: left;
        font-size: .75em;
        padding-left: .8em;
        margin-left: .8em;
        font-weight: bold;

        &:first-child {
          border: 0;
        }

        &:hover {
          text-decoration: underline;
        }
      }
    }

    .btngroup {
      border-top-width: 1px;
      border-top-style: solid;
    }

    .poll {
      margin: .5em .5em 1em;
      border-radius: .5em;
      padding: .8em;
      border: 3px solid #000;

      strong {
        display: block;
        border-bottom: 1px dashed #000;
        padding: 0 .2em .2em;
      }

      ul {
        margin: .5em 0 0 0;
        padding: 0;
        list-style: none;
      }

      li {
        label {
          font-size: .875em;
        }
      }

      .btns {
        margin: .5em 0 0;
        text-align: center;
      }

      .input {
        li {
          padding: .2em 0;
        }
      }

      .result {
        ul {
          margin-top: .8em;
        }

        li {
          margin: .5em 0;

          &:last-child {
            margin: 0;
          }

          .graph {
            position: relative;
            display: block;
            height: 4px;
            background: gray;
            margin: 0 0 .1em;
          }

          .txt {
            font-family: Tahoma, sans-serif;
            font-size: .75em;
            margin: .2em 0;
          }
        }
      }
    }
  }

  .cmtList {
    margin: 0;
    padding: 0;
    list-style: none;

    li {
      padding: .5em .5em;
      border-bottom: 1px dashed #000;
      position: relative;
    }

    .ireply {
      position: absolute;
      top: 50%;
      left: 0;
      margin-top: -5px;
    }

    .cmtbody {
      font-size: .875em;
    }

    dl {
      margin: .4em 0 0 0;
      display: inline-block;
      width: 100%;

      dt {
        float: left;

        span {
          display: block;
          float: left;
          font-size: .75em;
          padding-right: .5em;
          margin-right: .7em;
          border-right: 1px solid #ccc;

          &:last-child {
            margin-right: 0;
            padding-right: 0;
            border-right: 0;
          }
        }
      }

      dd {
        float: right;
        margin: 0;

        a {
          display: block;
          float: left;
          font-size: .75em;
          padding: 0 0 0 .5em;
          margin: 0 0 0 .5em;
        }
      }
    }
  }

  .cmtWrite {
    margin: 1em .5em .5em;

    strong {
      display: block;
      margin-bottom: .4em;
      font-size: .875em;
    }

    label {
      display: inline-block;
      font-size: .875em;
      margin-right: .5em;
    }

    dl {
      margin: 0;
      position: relative;
      padding-right: 85px;
      margin-top: .2em;

      dd {
        position: absolute;
        right: 0;
        top: 0;
        width: 78px;
        height: 76px;
        line-height: 76px;

        a {
          display: block;
          width: 100%;
          height: 100%;
          font-size: 12px;
          font-weight: bold;
          text-align: center;
        }
      }
    }
  }
}

.scbd.mo {
  -webkit-text-size-adjust: none;
  overflow-x: hidden;
}

.co-red {
  color: #f00;
}

.btngroup {
  padding: 1em 0;
  text-align: center;
}

@media screen and (-webkit-min-device-pixel-ratio: 1) {
  .scbd {
    .ui-ico {
      //background-image: url('/img_up/board_tpl/rwd/pc/img/sp_basic_2x.png');
      -webkit-background-size: 400px 150px;
    }
  }
}

@media screen and (max-width: 1220px) {
  .scbd {
    .lst-photo {
      text-align: center;
    }
  }
}
