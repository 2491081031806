.playerLayout {
  display: flex;
  position: relative;


  &.showRightTrack {

    .vjs-text-track-display,
    .vjs-tech {
      width:calc(100% - 250px);
    }
  }

  .trackLayout {
    width: 250px;
    flex: none;
    padding: 10px;
    overflow: auto;
    position: absolute;
    right: 0;
    top: 0;
    height: calc(100%);
    background:#fff;
  }
  .trackText {
    display: block;
    font-size: 14px !important;
    text-align: left;
    margin-bottom: .5em;
    outline-width: 0;
    color:#000;
    &.active {
      color:#f00;
    }
  }
}

.playerWrapper {
  font-size: 10px;
  padding-bottom: 60px;
  border: 1px solid #f8f8f8;
  // @include theme('border-color', 'header-text');
  .vjs-control-bar * {
    font-size: 10px !important;
    color:#000;

    &.vjs-menu-item-text {
      color:#fff;
    }
  }

  .vjs-volume-control {
    z-index: 100;
  }

  .vjs-menu-item.vjs-selected {
    background: #fff !important;
    .vjs-menu-item-text {
      color:#000;
    }
  }
  track {
  }

  .videobox {
    flex: 1;
  }

  .vjs-current-time-display {
    font-weight: 700;
  }

  .vjs-subs-caps-button {
    display: none !important;
  }

  .vjs-icon-subtitles,
  .vjs-icon-hd {
    &:before {
      font-size: 20px;
    }
    &:focus:before ,
    &:hover:before {
      text-shadow: none !important;
    }
  }

  .vjs-big-play-button {
    display: none !important;
  }
  .vjs-control-bar {
    position: relative !important;
    padding: 20px 10px 10px;
    opacity: 1 !important;
    height: 60px !important;
    display:flex !important;

    &.visible {
      position: absolute !important;
    }
  }



  .vjs-playback-rate .vjs-playback-rate-value {
    line-height: 30px;
  }

  .vjs-time-control {
    display: block !important;
    padding:0 !important;
    text-align: center !important;
  }

  .vjs-remaining-time {
    display: none !important;
  }


  .vjs-play-control {
    order: 1;
  }
  .vjs-current-time {
    order: 2;
  }
  .vjs-time-divider {
    order: 3;
    width: 10px !important;
    min-width: auto !important;
  }
  .vjs-duration {
    order: 4;
  }

  .vjs-menu-button {
    order: 5;
  }



  .vjs-control {
    &:focus {
      text-shadow: none !important;
    }
  }
  .vjs-button-icon-theme {
    order: 9;
    background:
            url('#{$image-path}/video-button-toggle-theme.png') no-repeat center !important;
    background-size: auto 14px !important;
    margin-left: auto !important;

  }
  .vjs-button-icon-hd {
    order: 10;
    background: url('#{$image-path}/video-button-hd-black.png') no-repeat center !important;
    background-size: auto 14px !important;

    .vjs-selected {
      background:#eee;
      color:#fff;
    }
    .vjs-menu {
      z-index: 3000;
    }
  }
  .vjs-button-icon-subtitles {
    order: 11;
    background: url('#{$image-path}/video-button-bottom-track-black.png') no-repeat center !important;
    background-size: auto 14px !important;
  }
  .vjs-button-icon-right-track {
    order: 12;
    background: url('#{$image-path}/video-button-right-track-black.png') no-repeat center !important;
    background-size: auto 14px !important;
  }
  .vjs-picture-in-picture-control {
    order: 18;
  }
  .vjs-volume-panel {
    order: 19;
  }
  .vjs-fullscreen-control {
    order: 20;
  }

  .video-js .vjs-control {
    width: 3em !important;
  }
  .video-js .vjs-progress-control {
    position: absolute;
    height: auto;
    left: 10px;
    top: 10px;
    margin-top: -3px;
    width: calc(100% - 20px) !important;
    border-radius: 2em;
    overflow: hidden;

    .vjs-progress-holder {
      margin: 0;
      height: 5px;
      background:#e5eaf1;
    }
  }

  .video-js .vjs-load-progress div {
    background:#e5eaf1;
  }

  .video-js .vjs-play-progress:before{
    display: none;
  }

  .video-js .vjs-play-progress {
    border-radius: 2em;
    overflow: hidden;
    background:#84bae3;
  }

  // theme
  .video-js .vjs-control-bar {
    background:#fff;
  }

  &.dark {
    .video-js .vjs-play-progress {
      background:#84bae3;
    }
    .video-js .vjs-load-progress div {
      background:#505662;
    }
    .vjs-progress-holder {
      background:#505662 !important;
    }


    .video-js .vjs-control-bar {
      background:#1d1f22;
    }

    .vjs-button-icon-theme {
      background: url('#{$image-path}/video-button-toggle-theme2.png') no-repeat center !important;
      background-size: auto 14px !important;
    }
    .vjs-button-icon-hd {
      background: url('#{$image-path}/video-button-hd.png') no-repeat center !important;
      background-size: auto 14px !important;
    }
    .vjs-button-icon-subtitles {
      background: url('#{$image-path}/video-button-bottom-track.png') no-repeat center !important;
      background-size: auto 14px !important;
    }
    .vjs-button-icon-right-track {
      background: url('#{$image-path}/video-button-right-track.png') no-repeat center !important;
      background-size: auto 14px !important;
    }

    .vjs-control-bar * {
      color:#fff;
    }
  }

}
