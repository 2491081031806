.breadcrumb {
  //border-bottom: 1px solid $theme-gray-1;
  background: none;
  margin: 0;
  padding: 0;

  @media screen and (max-width: 768px) {
    display: none;
  }

  .wrap {
    // height: 55px;
    height: 30px; // 여백 수정
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding:0 10px;
    @media screen and (max-width: 768px) {
      height: 40px;
    }
  }

  a {
    // color: $theme-gray-8;
    color: #767676;
    line-height: 2em;
    height: 1.75em;
    font-size: 0.85rem;
    & ~ a {
      margin-left: 0.75em;
      padding-left: 1em;
      background: url('#{$image-path}/location_arrow.png') no-repeat left 9px;
    }

    // home
    &:first-child {
      // padding: 0 0.5em;
      padding: 0;
      background: #fff;
      border-radius: 3px;
    }

    &:last-child {
      font-weight: 600;
      // color: $theme-black-2;
      color: #333;
    }
  }
}
