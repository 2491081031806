.calendar {
  position: relative;
  margin-bottom:0 !important;

  .refresh {
    color: #182343;
    height: 24px;
    position: absolute;
    display: block;
    top: 10px;
    right: 10px;
    bottom: 0;
    // margin: auto;
    min-width: 24px;
    text-align: center;
    line-height: 24px;
    padding: 0;
    border-radius: 24px;
    background: url("#{$image-path}/icon-refresh.png") no-repeat center center #f2f3f8;

    @media (max-width: 1024px) {
      top: 8px;
    }
  }

  .loading-text {
    position: absolute;
    right: 50px;
    top: 0;
    height: 25px;
    bottom: 0;
    margin: auto;
  }

  .calendar-status {
    position: absolute;
    right: 50px;
    bottom: 0;
    top: 2px;
    margin: auto;
    width: 60px;

    @media (max-width: 1024px) {
      top: 3px;
    }

    .calendar-status-confirmed {
      display: block;
      width: 100%;
      background: #25A0DA;
      color: #fff;
      font-size: 0.785rem;
      text-align: center;
      // padding-left: 10px;
      line-height: 1rem;
    }
    .calendar-status-disprove {
      display: block;
      width: 100%;
      background: #FF7515;
      color: #fff;
      font-size: 0.785rem;
      text-align: center;
      // padding-left: 10px;
      line-height: 1rem;
      margin-top: 5px;
    }
  }

  .calendar__title {
    line-height: 50px;
    font-size: 1.75rem;
    position: absolute;
    left: 50%;
    top: 0;
    transform: translateX(-50%);
    @media screen and (max-width: 1220px) {
      position: static;
      text-align: left;
      width: 100%;
      transform: none;
    }
    @media screen and (max-width: 768px) {
      font-size: 1.5rem;
    }
  }

  .linkbox {
    position: absolute;
    right: 0;
    top: 15px;
  }

  .fc-header-toolbar {
    @media screen and (max-width: 1220px) {
      margin-top: 1em;
    }
    @media screen and (max-width: 768px) {
    }

    .fc-toolbar-chunk {
      // left
      &:first-child {
        display: flex;
        align-items: center;
        height: 50px;
        border: 1px solid $theme-black-3;
        border-radius: 2em;
      }
    }

    .fc-toolbar-title {
      font-size: 1.5rem !important;
      margin: 0 !important;
      font-weight: 400;
      width: 150px;
      text-align: center;
      @media screen and (max-width: 1220px) {
        font-size: 1.25rem !important;
        width: 120px;
        line-height: 32px;
      }
      @media screen and (max-width: 768px) {
      }
    }

    .fc-button {
      margin: 0 !important;
      background: none;
      border: none !important;
      border-color: transparent !important;
      box-shadow: none !important;
      color: $theme-black-2;

      &:active,
      &:hover {
        outline-width: 0 !important;
        background: none !important;
        color: $theme-black-2 !important;
        border: none !important;
        border-color: transparent !important;
        box-shadow: none !important;
      }
    }
  }

  .fc .fc-daygrid-day-frame {
    padding: 5px;
  }

  .fc .fc-daygrid-day.fc-day-today {
    background: none !important;
    border: 3px solid $theme-red;

    .fc-daygrid-day-number {
      color: $white;
      background: $theme-red;
      border-radius: 50%;
    }
  }

  .fc .fc-daygrid-day-top {
    flex-direction: row;
  }

  .fc .fc-daygrid-day-number {
    padding: 0;
    width: 2.5em;
    height: 2.5em;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .fc-event-main {
    p {
      font-style: normal;
      font-size: 0.875rem;
      line-height: 26px;
      padding: 0 10px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      display: block;
    }
  }
}
