/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@100;300;400;500;700;900&display=swap');

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 1rem;
  vertical-align: baseline;
  font-family: 'Pretendard', san-serif;
  //letter-spacing: -0.02em;
  box-sizing: border-box;

  @media screen and (max-width: 1024px) {
    font-size: 0.875rem;
  }
}


.inputReadOnly:read-only {
  background-color: #e9ecef !important;
  opacity: 1;
}

.intro__footer {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 1rem;
  vertical-align: baseline;
  font-family: 'Pretendard', san-serif;
  //letter-spacing: -0.09em;
  box-sizing: border-box;
  font-weight: 300;

  @media screen and (max-width: 1024px) {
    font-size: 0.875rem;
  }

  .bottom_em {
    font-weight: 100;
    font-size: 13px;
  }

  .bottom_span {
    //font-weight: 500;
    font-size: 13px;
    //letter-spacing: -0.09em;
  }
}


/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}

table {
  table-layout: fixed;
  border-collapse: collapse;
  border-spacing: 0;
}

.wrap {
  width: 100%;
  max-width: 1202px;
  margin: 0 auto;
  padding: 0;
}

button {
  outline-width: 0;
  border: none;
  cursor: pointer;
  background: none;
  padding: 0;
}

a,
a:hover {
  text-decoration: none;
  color: $black;
}

.content {
  padding-top: 130px;
  min-height: calc(100vh - 210px);

  &.flexCont {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .main-wrap & {
    padding-top: 0;
  }

  @media screen and (max-width: 1226px) {
    padding-top: 82px;
  }


  @media screen and (max-width: 1024px) {
    min-height: calc(100vh - 153px);
    padding-top: 50px;
  }

  @media screen and (max-width: 768px) {
    min-height: calc(100vh - 247.16px);
    padding-top: 50px;

    .main-wrap & {
      padding-top: 60px;
    }
  }
}

img {
  max-width: 100%;
}

.wrapper {
  overflow-x: hidden;
}

// sub
.sub {
  padding-bottom: 8em;

  .wrap {
    display: flex;

    @media screen and (max-width: 768px) {
      display: block;
    }
  }

  .sub__content {
    flex: 1;
    padding-left: 70px;
    padding-top: 4em;
    max-width: calc(100% - 200px);

    @media screen and (max-width: 1220px) {
      padding-left: 40px;
      padding-top: 55px;
      max-width: calc(100% - 180px);
    }

    @media screen and (max-width: 768px) {
      padding-left: 0;
      padding-top: 40px;
      max-width: 100%;
    }
  }

  .sub__description {
    margin-top: 4rem;
    font-size: 1.125rem;
    text-align: center;
    color: $theme-black-3;
  }

  .sub__title {
    text-align: center;

    span {
      font-size: 2.5rem;
      font-weight: 400;
      position: relative;

      @media screen and (max-width: 1220px) {
        font-size: 2rem;
      }

      @media screen and (max-width: 768px) {
        font-size: 1.75rem;
      }

      &:after {
        content: '';
        width: 0.75rem;
        height: 0.75rem;
        display: block;
        background: $theme-black-2;
        position: absolute;
        right: -0.75rem;
        top: -0.75rem;

        @media screen and (max-width: 1220px) {
          width: 0.5rem;
          height: 0.5rem;
          right: -0.5rem;
          top: -0.5rem;
        }
      }

      &.active {
        &:after {
          right: auto;
          left: -0.75rem;
          background: $theme-red;
        }
      }
    }
  }
}

.circle_title {
  font-size: 1.25rem;
  font-weight: 600;
  margin-bottom: 0.5em;
  position: relative;
  margin-left: 1.5em;

  &:after {
    content: '';
    display: block;
    width: 0.9em;
    height: 0.9em;
    border: 5px solid #489659;
    border-radius: 50%;
    position: absolute;
    left: -23px;
    top: 50%;
    transform: translate(0, -50%);
  }
}


select {
  -webkit-appearance: none;
  -webkit-border-radius: 0;
  color: #333;
  background-color: #fff;
}




// fonts
// pretendard
@font-face {
  font-family: 'Pretendard';
  src: url('https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Thin.woff') format('woff');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Pretendard';
  src: url('https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-ExtraLight.woff') format('woff');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Pretendard';
  src: url('https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Pretendard';
  src: url('https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Pretendard';
  src: url('https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Pretendard';
  src: url('https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Pretendard';
  src: url('https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Pretendard';
  src: url('https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-ExtraBold.woff') format('woff');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Pretendard';
  src: url('https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Black.woff') format('woff');
  font-weight: 900;
  font-style: normal;
}