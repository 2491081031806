.mainBoard {
  position: relative;
  z-index: 100;
  padding: 0 10px;
  background: #fff;


  .ql-container {
    border: none !important;
    padding: 0 !important;
  }


  @media screen and (max-width: 450px) {

    padding: 0 5px;
  }


  .wrap {
    display: flex;
    padding: 0;
    padding-top: 55px;
    position: relative;

    //top: -60px;
    //max-width: calc(100% - 20px);
    @media screen and (max-width: 1220px) {
      display: block;
      top: 0;
      padding-top: 0;
    }


  }

  .col {
    width: 100%;
    flex: none;
    background: $white;
    max-width: 828px;
    padding-right: 0;

    &:first-child {
      @media screen and (max-width: 1220px) {
        border-bottom-left-radius: unset !important;
        border: 1px solid$theme-gray-2;
        max-width: 100%;
      }

      @media screen and (max-width: 550px) {
        border: none;
        padding: 0 !important;
      }
    }


    @media screen and (max-width: 1220px) {
      width: 100%;
      padding: 20px 10px !important;
      //border-radius: 1em !important;
    }

    &.col--right {
      background: url('#{$image-path}/main-quick-deco_2.png') 100% repeat-y;
      background-size: cover;
      position: relative;
      max-width: 318px;
      margin-left: 50px;
      padding: 20px;
      height: 313px;
      border-radius: 4px;

      @media screen and (max-width: 1220px) {
        max-width: 100%;
        margin-left: 0;
        margin-top: 20px;
        height: 200px;
      }

      @media screen and (max-width: 768px) {
        height: auto;
        margin-top: 5px;
        padding: 20px 0 !important;
      }

      .top {
        position: relative;
        border-top: none !important;

        h1 {
          padding-top: 7px;
          font-size: 1.1em;
          color: #ffffff;
          font-weight: 700;
          text-align: center;

          @media screen and (max-width: 1220px) {
            font-size: 1.3rem;
            text-align: center;
            width: 100%;
          }


          @media screen and (max-width: 768px) {
            display: none;
          }

        }


      }

      .contbox {
        padding-top: 30px;
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        word-break: keep-all;

        @media screen and (max-width: 768px) {
          padding-top: 0;
        }

        @media screen and (max-width: 550px) {
          padding-top: 0;
        }

        a {
          width: 50%;
          flex: none;
          text-align: center;

          @media screen and (max-width: 1220px) {
            width: 25%;
            margin-top: 0;
          }

          &:nth-child(1) {
            margin-bottom: 15px;

            @media screen and (max-width: 768px) {
              margin-bottom: 0px;
            }
          }

          &:nth-child(2) {
            margin-bottom: 15px;

            @media screen and (max-width: 768px) {
              margin-bottom: 0px;
            }

          }

        }

        div {
          border: 1px solid #fff;
          border-radius: 10px;
          width: 60px;
          height: 60px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 0 auto;
          margin-bottom: 10px;

          img {
            max-width: 65%;
          }

          @media screen and (max-width: 550px) {
            width: 55px;
            height: 55px;

            img {
              width: 50%;
            }
          }
        }

        p {
          color: #fff;
          font-size: 14px;
          line-height: 17px;
          //margin-top: 1em;

          @media screen and (max-width: 550px) {
            font-size: 12px;
          }

        }
      }


    }
  }

  .top {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-around;

    .top_button_line {
      position: absolute;
      border-top: 1px solid #1A9952;
      width: 100%;
      height: 1px;
      top: 50%;
    }

    .top_board_link_button {
      position: absolute;
      top: -5px;
      right: 0;
      width: 30px;
      height: 30px;
      background: #959593;
      border-radius: 5px;

      @media screen and (max-width: 768px) {
        display: none;
      }

      a {
        display: block;
        width: 100%;
        height: 100%;

        img {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }

    button {
      color: #8e8e8e;
      width: 25%;
      font-size: 1rem;
      font-weight: 700;
      position: relative;
      padding: 20px;
      transform: translateY(25px);
      transition: ease 0.2s;

      &.active {
        color: #fff;
        font-size: 1.1em;
        border-radius: 4px;
        background: #1A9952;
        transform: translateY(0);

        //border-bottom: 2px solid $theme-black-2;
        //&:after {
        //  content: '';
        //  background:#489659;
        //  border-radius: 2em;
        //  height: 6px;
        //  position: absolute;
        //  left: 0;
        //  bottom: -10px;
        //  width: 100%;
        //  box-shadow: 0 2px 5px rgba(0,0,0,0.2);
        //}
        @media screen and (max-width: 768px) {
          font-size: 1.3em;
        }

        @media screen and (max-width: 550px) {
          font-size: 1.3em;
          padding-top: 10px;
        }

      }

      @media screen and (max-width: 768px) {
        font-size: 1.3em;
        width: 50%;
        margin-right: 0;
        padding: 10px;
      }


      @media screen and (max-width: 550px) {
        font-size: 1.3em;
        padding-top: 0px;
        transition: 0.3s ease;
      }
    }
  }

  .boardbox {
    margin-top: 2em;
  }

  .contbox {
    display: flex;
    align-items: flex-end;

    //margin-top: 2em;
    @media screen and (max-width: 1220px) {
      display: block;
    }

    @media screen and (max-width: 768px) {}
  }

  .thumb {
    height: 195px;
    width: 360px;
    flex: none;
    background: $theme-gray-8;
    margin-left: auto;
    position: relative;

    @media screen and (max-width: 1220px) {
      width: 100%;
      margin-top: 20px;
    }

    @media screen and (max-width: 768px) {}

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      position: relative;
      z-index: 1;
    }

    &:after {
      content: '';
      display: block;
      width: 70px;
      height: 70px;
      position: absolute;
      z-index: 2;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      background: url('#{$image-path}/icon_play.png') no-repeat center;
      background-size: 100% 100%;
    }
  }

  .list {
    flex: 1;
    max-width: 100%;
    height: fit-content;
    overflow: hidden;


    @media screen and (max-width: 550px) {
      height: fit-content;
    }


    .link {
      width: 32%;
      float: left;
      text-align: left;
      font-size: 19px;
      display: block;
      white-space: normal;
      overflow: hidden;
      text-overflow: ellipsis;
      color: $black;
      background-color: #f6f6f6;
      height: fit-content;
      margin: 0 1%;
      padding: 30px;
      max-height: 216px;
      min-height: 216px;
      border-radius: 4px;

      &:nth-child(1) {
        margin-left: 0;
      }

      &:nth-child(3) {
        margin-right: 0;
      }

      &:hover {
        text-decoration: underline;
      }


      @media screen and (max-width: 1220px) {
        font-size: 16px;

        &~.link {
          margin-top: 0px;
        }
      }

      @media screen and (max-width: 768px) {
        width: 32%;
        padding: 20px;


        &~.link {
          margin-top: 0px;
        }
      }


      @media screen and (max-width: 550px) {
        width: 100%;
        padding: 15px;
        height: 100%;
        min-height: 68px;
        max-height: 68px;
        margin: 10px auto;

        h1 {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        p {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }


      & .main_list_cate {
        margin-bottom: 10px;
        font-size: 14px;
        font-weight: 700;
        color: #3e4d97;
      }

      & .main_list_title {
        margin-bottom: 5px;
        font-size: 16px;
        font-weight: 700;
        height: 40px;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;

        @media screen and (max-width: 550px) {
          font-size: 14px;
          height: auto;
        }

      }

      & .main_list_date {
        font-size: 13px;
      }

      & .quill {
        margin-bottom: 10px;


        .ql-toolbar.ql-snow {
          display: none;
        }

        .ql-container {
          margin: 0px 0 40px !important;
        }

        .ql-container.ql-snow {
          border: none;

          .ql-editor {
            padding: 0;
            height: fit-content;
            max-height: 60px;
            min-height: 60px;
            overflow: hidden;
            color: #777777;

            @media screen and (max-width: 550px) {
              min-height: auto;
              max-height: 55px;
            }

            p {
              font-size: 14px !important;
              text-align: left !important;

              @media screen and (max-width: 550px) {
                font-size: 0.9rem !important;
                margin: 2px 0 11px 0;

                br {
                  display: none;
                }

                img {
                  display: none;
                }
              }


              span {
                font-size: 14px !important;
                text-align: left !important;

                @media screen and (max-width: 550px) {
                  font-size: 13px !important;
                }
              }

              strong {
                font-size: 14px !important;

                @media screen and (max-width: 550px) {
                  font-size: 13px !important;
                }
              }
            }
          }
        }

      }
    }
  }
}


.content {


  @media screen and (max-width: 1220px) {
    &.program {
      .sub__content {
        margin-left: 10px;
        margin-right: 10px;

      }
    }
  }



  @media screen and (max-width: 768px) {

    &.program {
      .sub__content {
        padding: 0 !important;


        .sub__title {
          // margin-left:10px;
          // margin-right:10px;
        }

        .board__search {
          padding: 0;
        }

        .board__list {
          .board__list-body {
            border-top: 1px solid #000;
            border-bottom: 1px solid #ddd;

            .tr {
              margin-bottom: 0;
              border: none;
              padding: 15px;

              .col-title {
                line-height: 16px;
                width: 70px;
              }


              &~.tr {
                border-top: 1px solid #ddd;
              }
            }
          }
        }

        .boardDetail {
          padding: 0 10px;
        }
      }
    }

    &.onair {
      .sub__content {
        padding: 0 !important;


        .sub__title {
          margin-left: 10px;
          margin-right: 10px;
        }

        .board__search {
          padding: 0 10px;
        }

        .board__list {
          .board__list-body {
            border-top: 1px solid #000;
            border-bottom: 1px solid #ddd;

            .tr {
              margin-bottom: 0;
              border: none;
              padding: 15px;

              .col-title {
                line-height: 16px;
                width: 70px;
              }


              &~.tr {
                border-top: 1px solid #ddd;
              }
            }
          }
        }

        .boardDetail {
          padding: 0 5px;
        }

      }
    }





  }


  &.res {

    .sub__content {

      @media screen and (max-width: 1220px) {
        padding-left: 40px;
      }


      @media screen and (max-width: 768px) {
        padding-left: 10px !important;
        padding-right: 10px !important;

        .audittab {
          margin-top: 0;
        }
      }

      .sub__title {
        @media screen and (max-width: 768px) {
          margin-left: 0 !important;
          margin-right: 0 !important;
        }
      }


    }

  }


  &.quali {

    .sub__content {

      @media screen and (max-width: 1220px) {
        margin-left: 10px !important;
        margin-right: 10px !important;
      }


      @media screen and (max-width: 768px) {
        .audittab {
          margin-top: 0;
        }
      }

      .sub__title {
        @media screen and (max-width: 768px) {
          margin-left: 0 !important;
          margin-right: 0 !important;
        }
      }


    }

  }

  &.meta {

    .sub__content {
      @media screen and (max-width: 1220px) {
        // padding-left:10px;
      }
    }

    .sub__title {
      @media screen and (max-width: 768px) {
        margin-left: 0 !important;
        margin-right: 0 !important;
      }
    }

    .sub__content {
      @media screen and (max-width: 768px) {
        // padding-left: 10px !important;
        // padding-right:10px !important;
      }
    }

  }

  .resource {

    padding: 0 10px;
  }

  .sub__content {
    padding-top: 0;
    min-height: 500px;
    padding: 0 10px;
    padding-left: 70px;

    &.wide{
      padding-left:0;
      max-width:100% !important;
    }

    @media screen and (max-width: 1220px) {
      padding-left: 40px;
    }


    @media screen and (max-width: 768px) {
      padding: 0 10px;
    }

    ///////////////////
    /// 



    .cleanTest_title {
      margin-bottom: 3rem;
      line-height: 4.3rem;
      text-align: left;
      font-size: 1.5rem;
      font-weight: 600;
      margin-top: 38px;
      position: unset;
      border-bottom: 2px solid gray;
    }

    .ment_box {
      background-color: #fafafb;
      width: 100%;
      height: 260px;
      display: block;

      /*.clean_left{
        background-color: white;
        width: 30%;
        box-sizing: border-box;
        float: left;
        height: inherit;
        .cleanTest_img{
          width: calc(100%);
          height: calc(100%);
        }
      }*/
      .clean_right {
        width: 100%;
        box-sizing: border-box;
        float: right;
        height: inherit;

        h3 {
          font-size: 1.3rem;
          font-weight: 600;
          border-bottom: 1px solid #d7d7d7;
          margin: 30px;
          line-height: 4.3rem;
        }

        .cleanTest_ment {
          margin: 30px;
        }
      }
    }

    /////////////
    .sub__title {
      margin-bottom: 0;
      line-height: 4.3rem;
      border-bottom: 2px solid grey;
      text-align: left;

      &.title_content_wrap {
        margin-bottom: 15px;
      }

      span {
        font-size: 1.5rem;
        font-weight: 600;
        position: unset;


      }
    }

    .mb_0 {
      //margin-top: 4rem;
      text-align: left;

      span {
        font-size: 1.5rem;
        font-weight: 600;
        position: unset;
      }
    }
  }
}

/////////////////////////
.sub__clean_btn {
  margin-top: 19px;
  position: relative;

  @media screen and(max-width:768px) {
    padding: 5px;
  }

  .btn_write {
    text-align: center;
    background: #489659;
    color: white;
    height: 40px;
    border-radius: 3px;
    width: 145px;
    margin-right: 10px;
  }

  .btn_check {
    text-align: center;
    background: #f7f7f7;
    color: black;
    height: 40px;
    border-radius: 3px;
    width: 90px;
    border: 1px solid #d6d6d6;
  }
}

////////////////////////