.page-sublms {
  .quick {
    display: none;
  }

  .quick_sublms {
    position: absolute;
    left: 0;
    top: 0;
    width: 180px;
    height: 100vh;
    z-index: 3000;
    background: $white;
    box-shadow: 5px 10px 10px rgba(0, 0, 0, 0.1);
    transition: all 0.5s;
    &.active {
      .quick__toggle {
        img {
          margin-top: 6px;
          transform: none;
        }
      }
    }

    @media screen and (max-width: 768px) {
      display: none !important;
    }

    .quick__toggle {
      position: absolute;
      //right: -50px;
      left: -50px;
      top: 50%;
      transform: translateY(-50%);
      width: 50px;
      height: 50px;
      background: $theme-black-4;
      img {
        margin-top: 6px;
        transform: rotate(45deg);
      }
    }

    .quick__header {
      //background: #1a9952;
      //background: $theme-blue-1;
      color: $white;
      text-align: center;
      font-size: 17px;
      padding: 25px 0;
      line-height: 26px;
      img {
        //height: 50px;
        width: 80%;
      }
    }

    .quick__menu {
      line-height: 50px;
      text-align: center;
      background: #337842;
      //background: $theme-blue-2;
      color: $white;
    }

    .quick__body {
      border-top: 1px solid $theme-gray-1;
      padding: 15px;
      border-bottom: 1px solid $theme-gray-1;
      a {
        height: 45px;
        display: flex;
        align-items: center;
        font-size: 15px;
        color: $theme-black-2;
        white-space: nowrap;
        font-weight: 600;
        & ~ a {
          margin-top: 10px;
        }

        span {
          width: 45px;
          height: 45px;
          margin-right: 10px;
          //border: 1px solid $theme-gray-1;
          border-radius: 10px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }

    .quick__foot {
      padding: 15px;
      text-align: center;

      h2 {
        margin-top: 5px;
        font-size: 17px;
      }
      h3 {
        margin-top: 3px;
        font-size: 15px;
      }
      h4 {
        margin-top: 1em;
        font-size: 13px;
      }
      p {
        margin-top: 5px;
        font-size: 12px;
        line-height: 18px;
        color: $theme-black-3;
      }
    }
  }


  .mainSlider__item {
    .wrap {
      padding-top: unset;
      padding-bottom: unset;
    }
  }
  .header {
    position: unset;
    height: 0px;
    left: unset;
    top: unset;
    height: unset;
    @media screen and (min-width: 768px) {
      &:hover {
        &:after {
          height: 0px;
        }
      }
    }
  }
  .header__bottom {
    display: none;
  }
  .header__top {
    .wrap_sublms {
      height: 45px;
      display: flex;
      align-items: center;
      //justify-content: flex-end;
      justify-content: space-between;
      width: 100%;
      max-width: 100%;
      max-width: 1220px;
      margin: 0 auto;
      padding: 0 10px;
      button{
        color: white;
        margin-right:10px;
      }
      a ~ a {
        margin-left: 20px;
        padding-left: 20px;
        //border-left: 1px solid #ffffff;
      }
      a {
        color: white;
        line-height: 1em;
      }
    }
    background: #489659;
    .wrap {
      display: none;
    }
  }

  .mainBoard_sublms {
    position: relative;
    z-index: 100;
    padding:0 10px;
    background:#fafafa;
    @media screen and (max-width: 1220px) {
    }
    .wrap {
      display: flex;
      //background: #fafafa;
      //border-radius: 1em;
      //overflow: hidden;
      //padding: 0;
      //position: relative;
      //top: -60px;
      //max-width: calc(100% - 20px);
      @media screen and (max-width: 1220px) {
      }
      @media screen and (max-width: 768px) {
        display: block;
        top: -30px;
      }
    }
    .col1 {
      padding-right: 30px;
    }
    .col2 {
      padding-left: 30px;
    }
    .col {
      width: 50%;
      flex: none;
      padding-top: 40px;
      padding-bottom: 40px;
      //padding: 30px;
      //background: $white;
      &:first-child {
        //border: 1px solid$theme-gray-2;
        //border-right: 0;
        //border-top-left-radius: 1em;
        //border-bottom-left-radius: 1em;

        @media screen and (max-width: 768px) {
          border-bottom-left-radius: unset !important;
          border: 1px solid$theme-gray-2;
        }
      }
      @media screen and (max-width: 1220px) {
        padding: 20px;
      }
      @media screen and (max-width: 768px) {
        width: 100%;
        //border-radius: 1em !important;
      }

      &.col--right {
        background: url('#{$image-path}/main-quick-deco.png') no-repeat center;
        background-size: cover;
        position: relative;


        .top button {
          color: $white !important;
          &:after {
            background: #fff !important;
          }
        }

        .contbox {
          display: flex;
          flex-wrap: wrap;

          a {
            width: 33.333%;
            flex: none;
            text-align: center;

            &:nth-child(n + 4) {
              margin-top: 2em;
            }
            @media screen and (max-width: 768px) {
              width: 50%;
              &:nth-child(n + 3) {
                margin-top: 2em;
              }
            }
          }

          div {
            //border: 1px solid #fff;
            //border-radius: 10px;
            width: 70px;
            height: 70px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin:0 auto;
          }
          p {
            color:#fff;
            font-size: 16px;
            //margin-top: 1em;
          }
        }


      }
    }

    .top {
      h1 {
        border-bottom: 1px solid gray;
        text-align: left;
        width: 100%;
        padding-bottom: 15px;
        //color: $theme-gray-11;
        color: $theme-black-2;
        font-size: 1.5rem;
        margin-right: 1em;
        position: relative;
        //padding:0 .5em;

        &.active {
          //color: $theme-black-2;
          //border-bottom: 2px solid $theme-black-2;
          //&:after {
          //  content: '';
          //  background:#489659;
          //  border-radius: 2em;
          //  height: 6px;
          //  position: absolute;
          //  left: 0;
          //  bottom: -10px;
          //  width: 100%;
          //  box-shadow: 0 2px 5px rgba(0,0,0,0.2);
          //}
        }
      }
      button {
        span {
          font-size: 30px;
          color: black;
        }
      }
    }
    .boardbox {
      margin-top: 2em;
    }
    .contbox {
      display: flex;
      align-items: flex-end;
      //margin-top: 2em;
      @media screen and (max-width: 1220px) {
        display: block;
      }
      @media screen and (max-width: 768px) {
      }
    }

    .thumb {
      height: 195px;
      width: 360px;
      flex: none;
      background: $theme-gray-8;
      margin-left: auto;
      position: relative;
      @media screen and (max-width: 1220px) {
        width: 100%;
        margin-top: 20px;
      }
      @media screen and (max-width: 768px) {
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        position: relative;
        z-index: 1;
      }

      &:after {
        content: '';
        display: block;
        width: 70px;
        height: 70px;
        position: absolute;
        z-index: 2;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        background: url('#{$image-path}/icon_play.png') no-repeat center;
        background-size: 100% 100%;
      }
    }

    .list {
      flex: 1;
      max-width: 100%;
      .link {
        p {
          color: gray;
        }
        font-weight: bold;
        width: 100%;
        text-align: left;
        font-size: 15px;
        //display: block;
        display: flex;
        justify-content: space-between;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        color: $black;
        & ~ .link {
          margin-top: 10px;
        }
        @media screen and (max-width: 1220px) {
          font-size: 16px;
          & ~ .link {
            margin-top: 15px;
          }
        }
        @media screen and (max-width: 768px) {
          & ~ .link {
            margin-top: 10px;
          }
        }
      }
    }
  }


  .content{
    .sub__content{
      padding-top:0;
      .sub__title{
        margin-bottom: 5rem;
        line-height: 4.3rem;
        //border-bottom: 2px solid grey;
        text-align: left;
        span{
          font-size: 1.5rem;
          font-weight: 600;
          position: unset;
        }
      }
      .mb_0 {
        margin-top: 4rem;
        text-align: left;
        span{
          font-size: 1.5rem;
          font-weight: 600;
          position: unset;
        }
      }
    }
  }

}