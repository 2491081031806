.page-resource-studio,
.page-resource-studio2,
.page-resource-studio_admin,
.page-resource-studio3 {
  .jqx-group-button-normal {
    display: none;
  }

  .sub {
    .wrap {
      display: block;
    }

    .sub__content {
      width: 100%;
      max-width: 100%;
      padding: 0;
    }
  }

  .section {
    display: inline-block;
    vertical-align: top;
    height: 570px;
    /*border:1px solid #e4e4e4;*/

    @media screen and (max-width: 1220px) {
      height: auto;
    }


    &.info {
      width: 49%;

      @media screen and (max-width: 1220px) {
        width: 100%;
      }

    }

    &.calander {
      width: 50%;
      //border-left: 0;
      // border: 1px solid #e4e4e4;
      // padding: 5px;

      @media screen and (max-width: 1220px) {
        width: 100%;
      }
    }
  }

  @media screen and (max-width: 768px) {
    .section {
      display: block;

      &.info {
        width: unset;
      }

      &.calander {
        width: unset;
      }
    }
  }

  .part_footer_list {
    list-style-type: disc;
    padding-left: 25px;
    padding-top: 5px;
  }

  .footer_title {
    margin-bottom: 5px;
  }

  .part_info {
    @media screen and (max-width: 500px) {
      height: auto !important;
    }

    .part {
      @media screen and (max-width: 500px) {
        height: 450px !important;
        overflow-y: auto;
      }
    }

  }

  .part_select {
    @media screen and (max-width: 500px) {
      margin-bottom: 40px;
      height: 300px !important;
      overflow: hidden;
    }
  }

  .part_info,
  .part_select {
    width: 48%;
    display: inline-block;
    vertical-align: top;
    height: 570px;
    // border:1px solid #e4e4e4;
    margin-right: 10px;

    @media screen and (max-width: 1220px) {
      width: 50%;
      margin-right: 0;
    }

    @media screen and (max-width: 500px) {
      width: 100%;
    }

    .part {
      height: 562px;
      padding: 0 10px;
    }

    .part_footer {
      padding: 10px;
      border-top: 1px solid #e4e4e4;

      .footer_title {
        font-weight: bold;
        margin-bottom: 5px;
      }

      .footer_info {
        font-size: 0.85rem;
        padding-left: 5px;
      }
    }
  }

  .part_select {
    // border-right: 1px solid #e4e4e4;

    .col {
      padding: 0;
      height: 80%;
    }
  }
}


.columnFlexStudio {
  display: flex;
  flex-direction: column;

  &>div {
    border: 1px solid #DDDDDD;
    border-radius: 4px;
    height: 100%;
  }
}

.part_title {
  margin: 10px 0;
  padding: 10px;
  font-weight: bold;
  font-size: 1.1rem;
  // background-color: #f0f0f0;
  // border-radius: 20px;
  background: #FFFFFF;
  border: 1px solid #DDDDDD;
  border-radius: 4px;
  text-align: center;
}

.rentalHeader {
  position: relative;

  h2 {
    font-size: 1.75rem;
    text-align: center;

    @media screen and (max-width: 1220px) {}

    @media screen and (max-width: 768px) {
      font-size: 1rem;
      text-align: left;
    }
  }
}

.rentalHeader__bottom {
  //margin-top: 1em;
  //display: flex;
  align-items: center;
  justify-content: space-between;

  @media screen and (max-width: 1220px) {}

  @media screen and (max-width: 768px) {
    .rsvlink {
      position: absolute;
      right: 0;
      top: 0px;
      line-height: 29px;
    }
  }

  .rentalHeader__tabs {
    display: flex;

    @media screen and (max-width: 1220px) {}

    @media screen and (max-width: 768px) {
      width: 100%;
    }

    button {
      //width: 33%;
      width: 50%;
      border-bottom: 1px solid #ddd;
      font-size: 17px;
      color: 000;
      font-weight: 600;
      padding: 10px;
      /*border: 1px solid $theme-gray-11;
      border-left: 0;*/


      @media screen and (max-width: 1220px) {
        width: 180px;
      }

      @media screen and (max-width: 768px) {
        font-size: 1rem;
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 50px;
        line-height: 1.25em;
        padding: 0 10px;

        &~button {
          margin-left: 5px;
        }
      }

      &:first-child {
        // border-left: 1px solid $theme-gray-11;
      }

      &.active {
        border-bottom: 3px solid #489659;

        /*border-top: 1px solid $theme-black-4;
        border-right: 1px solid $theme-black-4;
        border-left: 1px solid $theme-black-4;
        border-bottom: 0;*/
      }
    }
  }
}

#createAppointment {
  display: none;
}

.jqx-input-disabled-material {

  .jqx-icon-calendar,
  .jqx-icon-time {
    display: none;
  }
}

.jqx-input {
  border: none !important;
}

.jqx-icon {
  top: 49% !important;
}

.jqx-cell {
  font-size: 13px !important;
}

.jqx-cell:hover {
  border: none !important;
}




//스튜디오예약 테이블 스타일 변경

.jqx-scheduler-appointment-inner-content {

  .small {
    font-size: 70% !important;
  }
}

.jqx-grid {
  border-radius: 4px;
  margin-top: 8px !important;
}

.jqx-grid-table {
  border-collapse: collapse !important;

  .jqx-grid-cell {
    border-width: 1px !important;
  }

  .jqx-scheduler-month-weekend-cell {
    border-left-width: 1px !important;
    background-color: #F8F8F8;
  }

  .jqx-scheduler-cell-selected {
    background-color: #fff !important;
    border: 0.1px solid #e0e0e0 !important;
  }

  .jqx-grid-cell-selected-material {
    background-color: #fff !important;
    border: 0.1px solid #e0e0e0 !important;
  }

  .jqx-scheduler-cell-focus {
    background-color: #fff !important;
    border: 0.1px solid #e0e0e0 !important;
  }

  // .jqx-cell:hover {
  //   background-color: #fff !important;
  //   border: 1px solid #e0e0e0 !important;
  //   padding-top: -1px !important;
  // }

  .jqx-scheduler-month-weekend-cell:hover {
    background-color: #eeeeee !important;
    border: 0.1px solid #e0e0e0 !important;
  }

  .jqx-scheduler-month-weekend-cell.jqx-scheduler-cell-selected {
    background-color: #eeeeee !important;
    border: 0.1px solid #e0e0e0 !important;
  }

  .jqx-scheduler-month-weekend-cell.jqx-grid-cell-selected-material {
    background-color: #eeeeee !important;
    border: 0.1px solid #e0e0e0 !important;
  }

  .jqx-scheduler-month-weekend-cell.jqx-scheduler-cell-focus {
    background-color: #eeeeee !important;
    border: 0.1px solid #e0e0e0 !important;
  }
}

.jqx-scheduler-toolbar {
  &>div {
    top: 0 !important;
    display: flex;
    justify-content: center;
    align-items: center;

  }
}

.jqx-scheduler-month-cell {
  background: none !important;
  border: none !important;
}

.jqx-scheduler-month-outer-cell {
  color: #949494 !important;
}

.jqx-scheduler-month-outer-cell {
  color: #696969 !important;
}

.jqx-widget-content {
  height: 492px !important;
  // margin-top: 8px !important;
}

.jqx-grid-cell {
  border-right: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  border-top: none !important;
  border-left: none !important;
}


.jqx-scheduler .jqx-grid-column-header,
.jqx-scheduler.jqx-widget .jqx-grid-column-header {
  border-bottom: 0px !important;
}

.jqx-grid-column-header {
  border: none !important;

  &>div {
    margin: 0 !important;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    background-color: #1A9952;
    color: #fff;
  }
}

.studio_reservation_management {
  float: right;
  margin-top: 15px;
  border-radius: 3px;
  padding: 10px;
  background: #3e3e3e;
  color: white;
}

.jqx-fill-state-normal {
  background: none !important;
}

.calendar-status {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 8px;
  width: 100%;
  margin: 10px 0;

  .calendar-status-non {
    background-color: #DDDDDD;
    border-radius: 4px;
    padding: 2px 7px;
    text-align: center;
    color: #696969;
    width: 70px;
    font-size: 14px;
  }

  .calendar-status-confirmed {
    background-color: #00B852;
    border-radius: 4px;
    padding: 2px 7px;
    text-align: center;
    color: #fff;
    width: 70px;
    font-size: 14px;
  }

  .calendar-status-disprove {
    background-color: #FF7245;
    border-radius: 4px;
    padding: 2px 7px;
    text-align: center;
    color: #fff;
    width: 70px;
    font-size: 14px;
  }
}