.loading-container {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  background: rgba(0, 0, 0, 0.3);
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 999;
}

.popup-overlay {
  .popup-content{
    padding: unset;
    border: unset;
  }
}