.boardDetail__content,
.result_form {
  >.quill {
    overflow: hidden;

    >.ql-toolbar {
      display: none !important;
      background-color: red;
    }

    .ql-container {
      border: none !important;

      .ql-editor {
        padding: 0 !important;
        overflow: hidden;
      }
    }
  }

  p {
    color: #696969 !important;
    margin-top: 0 !important;
    font-size: 0.95em !important;
    //background : url('#{$image-path}/stripe_image.jpg') repeat !important;
    //padding:30px !important;
  }

  // margin-top: 20px;
}

.con_detail {
  >.quill {
    .ql-container {
      .ql-editor {
        h4 {
          margin: 45px 0 30px;
          font-size: 24px;
          word-break: keep-all;
        }

        h4 {
          margin: 45px 0 30px;
          font-size: 24px;
          color: #00863e;
          word-break: keep-all;
        }

        h5 {
          position: relative;
          margin: 45px 0 20px;
          padding-left: 25px;
          word-break: keep-all;
          font-size: 18px;
          line-height: 22px;
          color: #111;
          font-weight: bold;

          &:after {
            content: '';
            display: block;
            position: absolute;
            left: 0;
            top: 3px;
            width: 9px;
            height: 16px;
            background: #00863e;
            transform: skewX(-35deg);
            transform-origin: left bottom;
          }
        }

        p {
          font-size: 14px;
          line-height: 24px;
          color: #666;
          word-break: keep-all;
          position: relative;
          margin-top: 10px;
          font-size: 14px;
          line-height: 20px;
          padding: 30px;
          color: #666;
          word-break: keep-all;

          //&:after {
          //  content: '';
          //  position: absolute;
          //  left: 0;
          //  top: 8.5px;
          //  width: 8px;
          //  height: 3px;
          //  background: #c2c2c2;
          //}
        }

        .div {
          position: relative;
          padding: 13px 10px 13px 45px;
          border: 1px solid #e0e0e0;
        }

        i {
          position: absolute;
          left: 19px;
          top: 18px;
          width: 18px;
          height: 18px;
          border-radius: 50%;
          line-height: 13px;
          font-size: 12px;
          text-align: center;
          color: #ffffff;
          background: #b3b3b3;
          font-style: normal;
        }

        b {
          display: inline-block;
          width: 1px;
          height: 13px;
          margin: 0 30px;
          background: #e0e0e0;
          vertical-align: middle;
        }

        span {
          display: inline-block;
          line-height: 20px;
          vertical-align: middle;
          color: #666;
        }
      }
    }
  }
}

//quilljs 폰트 사이즈
.ql-snow {
  .ql-picker {
    &.ql-size {

      .ql-picker-label,
      .ql-picker-item {
        &::before {
          content: attr(data-value) !important;
        }
      }

    }
  }
}

.ql-picker-label {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.ql-formats {
  @media screen and (max-width:500px) {
    margin-right: 0 !important;
  }
}

.modal_body {
  .quill {
    //overflow: auto;
    margin: 0;
    height: 100%;
    width: 100%;

    //background: red;
    .ql-toolbar {
      border-radius: 5px 5px 0 0;
      border: 1px solid rgb(118, 118, 118);

      .ql-color-picker {
        line-height: 1;
      }
    }

    .ql-container {
      height: 200px;
      border-radius: 0 0 5px 5px;
      border: 1px solid rgb(118, 118, 118);
    }
  }
}

.quill {
  .ql-editor {
    word-break: break-all;

    .ql-video {
      width: 100%;
      height: 50vmin;
      //max-height: calc(800px / 16 * 9);
    }
  }
}

.notice__quill {
  overflow: hidden;
}



.wrap {
  .left.banner {
    .ql-editor {
      padding: 0;

      p {
        word-break: keep-all;
      }
    }

    .ql-toolbar {
      display: none;
    }

    .ql-container {
      border: none !important;
      overflow: visible;

      .ql-editor {
        overflow: visible;

        p {
          overflow: visible;
        }
      }
    }
  }

}


.course_Table_Wrapper {
  .td_num {
    height: 30px !important;

    .ql-editor {
      padding: 3px !important;
    }

    p {
      font-size: 14px !important;
      padding: 0px !important;
    }
  }

  .tb_li {
    height: 30px !important;

    .ql-editor {
      padding: 3px !important;
    }

    p {
      font-size: 14px !important;
      padding: 0px !important;
    }
  }
}