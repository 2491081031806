.gai_icon{
    position: fixed;
    bottom: 30px;
    left: 50px;
    z-index: 10000;
    width:75px;

    @media (max-width: 550px) {
        width: 55px;
        bottom: 20px;
        left: 24px;
    }
}


.resizable-box-gai{
    z-index: 999;
    position: fixed !important;
    max-width:100% !important;
    max-height: 100vh !important;
    border-radius: 15px;
    box-shadow: 0 0 18px rgba(0,0,0,0.25);

    .chatting_warning{
        position: fixed;
        top: auto;
        bottom: 65px;
        left: 50%;
        transform: translateX(-30%);

        background: #bcecff;
        border-radius: 100px;
        font-size: 0.95rem;
        padding: 2px 14px;
        color: #0904c7 !important;
        white-space: pre;
    }
   

    .mini_width{

        
        .chatting_warning{
            // top:9px;
            top:auto;
            bottom: 65px;
            transform: translateX(-40%);
            font-size: 0.6rem;
        }

        .popup_content{
            .backImg{
                top: 19px !important;
                left: -67px !important;
                width: 168px !important;

                &.rights{
                    left: auto !important;
                    right: -75px !important;
                    top: auto !important;
                    bottom: 111px !important;
                }
            }
            .gai_content{
                padding:0 20px;
                height: calc(100% - 88px) !important;

                .content_title{
                    margin-bottom:5px;
                }

                .content_title_sub{
                    font-size: 0.9rem !important;
                    line-height: 1.2rem;
                    text-align: center;

                    .blues{
                        font-size:  0.95rem !important; 
                    }

                    .minerBr{
                        display:block !important;
                    }
                }

                .content_container {
                    overflow: auto;

                    .content_buttons{
                        flex-direction: column;
                        gap: 10px !important;
                        justify-content: flex-start !important;
                        button {
                                width:100% !important;
                                height:142px !important;
                                min-height: 142px;

                                .iconBox{
                                    height:43px !important;
                                    margin-bottom:6px !important;
                                }

                                img{
                                    width:46px !important;

                                    &.manual_icons{
                                        width:40px !important;
                                    }
                                }
            
                                p {
                                    font-size: 1rem !important;
                                    line-height: 1.35rem !important;

                                    &.inflex{
                                        display: flex;
                                        align-items: center;
                                        justify-content: center;
    
                                        & > img {
                                            width:9px !important;
                                            margin-bottom: 0 !important;
                                            margin-left: 5px !important;
                                        }
                                    }


                                    &.gpt_version{
                                        font-size: 0.75rem !important;
                                        font-weight: 300 !important;
                                    }
                                }
                            }
                        }
                    }
                  
            }

            .gai_bottom{
                height:53px;

                & > img {
                    top: -49px !important;
                    right: 15px !important;
                    width: 133px !important;
                }
            }
            .gai_top{
                height:36px;

                h3{
                    padding-left:13px;

                    p{
                        font-size: 0.85rem;
                    }

                }

                .top_button_container {
                    padding-right:13px;

                    button{
                        margin-right:0;
                        width:11px; 

                        &.backButton{
                            width:12px !important;
                        }

                        & ~ button{
                            margin-left:14px;
                        }
                    }
                }
            }
        }
    }


    &.react-draggable-dragging{

       

        .gai_top{
            cursor: move;
        }
    }


    @media (max-width: 1500px) {
        transform: translate(0, 0) !important;
        height: 100% !important;
    }

    @media (max-width: 1038px) {
        .popup_content{
            border-radius: 0 !important;
        }
    }

    @media (max-width: 575px) {
        .popup_content{
            .chat_form{
                .left_container{
                    width: 100%;
                    position: absolute;
                    height: 100%;
                    background: white;
                    z-index: 1;
                    .chat_list_container{
                        .chat_list{
                            overflow: auto;
                            height: calc(100% - 50px);
                            background: white;
                        }
                        height: calc(100% - 40px);
                        background: white;
                    }
                }
            }
        }
    }
}
.gai_top{
    height: 50px;
    top: 0;
    left: 0;
    color: #333333;
    display: flex;
    justify-content: space-between;
    width: 100%;
    background-color: #fff;
    position: relative;
    z-index: 10;

    .chatting_warning{
        display: flex;
        justify-content: center;
        align-items: center;
        color: #999;
    }

    &.drag{
        cursor: grabbing;
    }
    .sub_title_container{
        display: flex;
        align-items: center;
        gap: 5px;
    }
    .top_button_container{
        display: flex;
        justify-content: center;
        align-items: center;
        gap : 10px;

        button{
            width:16px; 
            height:17px;
            display: flex;
            justify-content: center;
            align-items: center;

            
            &.backButton{
                width:18px;
            }

            & ~ button{
                margin-left:0;
            }

            img{
                width:100%;
            }
        }

        .usage_container{

            position: relative;

            .usage_text{
                font-size: 12pt;
                color: #696969;
            }

            .usage_number{
                font-size: 12pt;
                color: #696969;
            }
        }
    }
    button{
        margin-right: 22px;
    }
    h3{
        height: 100%;
        padding-left: 25px;
        display: flex;
        align-items: center;
        gap: 5px;

        .popup_title_point{
            width: 4px;
            height: 4px;
            flex-shrink: 0;
            background: rgba(1, 113, 187, 0.60);
            border-radius: 50px;
            transform: translateY(2px);
        }

        p{
            font-size: 1rem;
            transform: translateY(1px);

            &.pointer{
                cursor: pointer;
                font-size: 1rem;
                &:hover{
                    color: #0D6B37;
                }
            }

            &.green{
                cursor: default;
                color: #014F82;
                font-size: 1rem;
            }
        }
    }
}
.popup_overlay{
    &.gai{
        padding:0;
        position: absolute;
        top: 0px;
        bottom: 0px;
        left: 0px;
        right: 0px;
        display: flex;
        z-index: 999;
        animation: dialog-fadein 0.5s;
        background: none;
        width: 100%;
        height: 100%;

        &.chat,&.method{

            .backImg{
                display: none;
            }

            .gai_top{
                border-bottom:1px solid #ddd;
            }

            //화면이 줄어들었을때.
            &.mini_width{
                .popup_content{


                    .gai_top{
                        height:36px;

                        .sub_title_container{
                            width: 100%;
                            flex: 1;
                            
                            p{
                                overflow: hidden;
                                text-overflow: ellipsis;
                                width: 90px ;
                            }
                        }

                        h3{
                            padding-left:13px; 
                            overflow: hidden;
                            width:calc(100% - 0px);
                            text-overflow: ellipsis;
                            overflow: hidden;

                            p{
                                font-size: 0.75rem;
                                white-space: pre;
                            }

                        }

                        .top_button_container {
                            padding-right:13px;

                            button{
                                margin-right:0;
                                width:11px; 

                                &.backButton{
                                    width:14px;
                                }

                                & ~ button{
                                    margin-left:14px;
                                }
                            }

                            .usage_container{
                                
                                position: relative;

                                &:hover{
                                    .usage_number{
                                        display: block;
                                    }
                                }

                                .usage_text{

                                    font-size: 10pt;
                                    color: #fff;
                                    background-color: #0171BB;
                                    padding: 5px 10px;
                                    border-radius: 11px;
                                    
                                }

                                .usage_number{
                                    display: none;
                                    position: absolute;
                                    font-size: 10pt;
                                    color: #0171bb;
                                    border: 1px solid #0171bb;
                                    background-color: #e5eefa;
                                    padding: 5px 10px;
                                    min-width: fit-content;
                                    text-wrap: nowrap;
                                    top: 25px;
                                    border-radius: 0 11px 11px 11px;
                                }
                            }
                        }
                    }

                    .chat_form{
                        height:calc(100% - 36px);

                        .right_container{
                            width:calc(100% - 48px);
                            left:48px;
                           

                            .chatting_container{
                                padding-left: 15px;
                                padding-top: 15px;



                                .user_chat {
                                    .chat_data{
                                        padding-right:15px;
                                    }
                                }

                                .ai_chat {
                                    display: block;

                                    & > img{
                                        width:44px;
                                        transform: none;
                                    }

                                    .chat_data {
                                        .chat {
                                            margin-right:15px;
                                            width: fit-content;
                                        }
                                    }
                                }
                            }

                        }


                        .left_container{
                            width: 48px;
                            position: absolute;
                            height: 100%;
                            background: white;
                            z-index: 1;
                            padding-top:10px;

                            .chat_list_container{
                                h3{
                                    padding:0;
                                    display: block;
                                    text-align: center;
                                    padding-bottom:6px;
                                }
                            }


                            .open_container{
                                top:10px;
                                right: -31px;

                                .open_button{
                                    width:30px;
                                    height:30px;

                                    img{
                                        width:8px;
                                    }
                                }
                            }

                            &.deactive{
                                left:-48px;
                            }

                            .chat_button_container{
                                display: flex;
                                flex-direction: column;
                                align-items: center;
                                margin-bottom:10px;

                                .add_button{
                                    width:30px;
                                    height:30px;

                                    .pluses{
                                        margin-right:0;
                                    }

                                   .inText{
                                    display: none;
                                   }
                                }

                                .close_button{
                                    width:30px;
                                    height:30px;

                                    img{
                                        width:8px;
                                    }
                                }
                            }

                            .chat_list_container{
                                border-top:1px solid #ddd;
                                height: calc(100% - 80px);

                                .chat_list{
                                    overflow: auto;
                                    background: white;
                                    padding-top:0;
                                    height: 100%;
                                    .chat_list_item{
                                        padding: 9px 8px;
                                        height:37px;
                                        flex-direction: column;
                                        justify-content: space-between;

                                        input{
                                            width: 100%;
                                        }

                                        .edit_button_container{
                                            width: 100%;
                                            align-items: center;
                                            justify-content: end;

                                            button{
                                                & ~ button{
                                                    margin-left:10px;
                                                }
                                            }
                                        }
                                        
                                        & ~ .chat_list_item{
                                            border-top:1px dashed #E6ECE5;
                                        }

                                         p{
                                            padding-right:0;
                                            text-align: center;
                                            font-size: 0.8rem;
                                         }

                                        .chatroom_button_container{
                                            justify-content: end;
                                            gap:6px;

                                            button {
                                                width:12px;
                                            }
                                        }

                                        small{
                                            color:#d7d7d7;
                                        }

                                    }
                                }
                            }
                        }

                        .only_container{
                            .chatting_container {
                                padding:15px;
                                padding-right:10px;
                                padding-bottom:10px;

                                .ai_chat{
                                    flex-direction: column;
                                    align-items: start;
                                }
                                .user_chat {
                                    .chat_data{
                                        padding-right:0;
                                    }
                                }
                            }
                        }
                    }
                }
            }


            .popup_content{
                background: #fff;
                //min-height: 800px;


                .chat_form{
                    display: flex;
                    width: 100%;
                    height: calc(100% - 50px);
                    position: relative;

                    .left_container{
                        width: 256px;
                        padding-top: 20px;
                        border-right: 1px solid #ddd;
                        position: absolute;
                        height:100%;
                        left:0;
                        transition: 0.7s ease;

                        .open_container{
                            position: absolute;
                            right: -41px;
                            top: 19px;
                            z-index: 1;
                            cursor: pointer;

                            .open_button{
                                border-radius: 0 4px 4px 0;
                                width: 40px;
                                height: 40px;
                                flex-shrink: 0;
                                border: 1px solid #ddd;
                                background-color: white;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                background-color: #fff;
                                z-index: 2;

                                img{
                                    width:9px;
                                }
                            }
                        }


                        // ::-webkit-scrollbar {
                        //     width: 10px;
                        //     background-color: black;
                        // }

                        &.deactive{
                            left:-257px;
                        }

                        .chat_button_container{
                            display: flex;
                            gap: 10px;
                            justify-content: center;
                            margin-bottom:25px;

                            .add_button{
                                width: 175px;
                                height: 40px;
                                border-radius: 4px;
                                font-size: 0.85rem;
                                background: #25385E;
                                color: white;
                                display: flex;
                                align-items: center;
                                justify-content: center;

                                .pluses{
                                    width:14px;
                                    margin-right:3px;
                                }

                                .inText{
                                    font-size: 0.85rem;
                                }
                            }
                            .close_button{
                                border-radius: 4px;
                                width: 40px;
                                height: 40px;
                                flex-shrink: 0;
                                border: 1px solid #ddd;
                                display: flex;
                                align-items: center;
                                justify-content: center;

                                img{
                                    width:9px;
                                }
                            }
                        }
                        .chat_list_container{
                            .chat_list{
                                overflow: auto;
                                height: calc(100% - 50px);
                                background: white;
                                padding-top: 6px;
                                padding-bottom: 30px;
                            
                                ::-webkit-scrollbar {
                                    background-color: #e9e9e9;
                                    border-radius: 13px;
                                    width: 5px;
                                }
                                ::-webkit-scrollbar-thumb {
                                    background: #afafaf; /* 스크롤바 막대 색상 */
                                    //border: 2px solid #9b6a2f; /* 스크롤바 막대 테두리 설정  */
                                    border-radius: 13px;
                                }
                            }
                            height: calc(100% - 40px);
                            h3{
                                display: flex;
                                justify-content: start;
                                align-items: center;
                                border-bottom: 1px solid #DDD;
                                padding-left: 13px;
                                color: #25385E;
                                font-size: 13px;
                                font-weight: 600;
                                padding-bottom:9px;
                            }

                            .chat_list_item{

                                cursor: pointer;
                                display: flex;
                                justify-content: space-between;
                                padding: 8px 13px;
                                gap: 5px;
                                height: 37px;

                                input{
                                    height: 22px;
                                    font-size: 0.75rem;
                                    width: 155px;
                                    border: 1px solid #ddd;
                                    padding: 5px;
                                    border-radius: 5px;
                                }

                                button{
                                    font-size: 14px;

                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                }
                                .chatroom_button_container{
                                    display: none;
                                }
                                p{
                                    font-size: 0.85rem;
                                    color: #696969;
                                    width: 100%;
                                    text-overflow: ellipsis;
                                    white-space: nowrap;
                                    overflow: hidden;
                                    align-items: center;
                                    padding-right:10px;
                                }
                                small{
                                    font-size: 0.7rem;
                                    line-height: 0.7rem;
                                    color: #999;
                                    display: flex;
                                    align-items: center;
                                    min-width: 75px;
                                    justify-content: flex-end;
                                }

                                &:hover{
                                    p{
                                        color: #014F82;
                                    }
                                    small{
                                        display: none;
                                        color: #668DBC;
                                    }
                                    .chatroom_button_container{
                                        height: 21px;
                                        display: flex;
                                        max-height: 24px;
                                        justify-content: center;
                                        align-items: center;
                                        gap: 10px;

                                        button{
                                            display: flex;
                                            justify-content: center;
                                            align-items: center;
                                            width:14px;
                                        }
                                    }
                                }
                                &.active{
                                    background-color: #E5EEFA;;
                                    p{
                                        color: #014F82;
                                    }
                                    small{
                                        color: #668DBC !important;
                                    }
                                }
                                .edit_button_container{
                                    width: 75px;
                                    display: flex;
                                    justify-content: space-around;

                                    button {
                                        font-size: 0.8rem;
                                    }
                                }
                            }
                        }
                    }


                    .right_container{
                        position: absolute;
                        width: calc(100% - 256px);
                        display: flex;
                        flex-flow: column;
                        justify-content: stretch;
                        left:256px;
                        height:100%;
                        transition: 0.7s ease;
                        background-color: #F2F2F2;
                        position: relative;

                        .version{
                            position: absolute;
                            top: 7px;
                            right: 10px;
                            font-size: 0.75rem;
                            color: #cdcdcd;
                        }

                        ::-webkit-scrollbar {
                            background-color:transparent;
                            border-radius: 13px;
                            width: 5px;
                        }
                        ::-webkit-scrollbar-thumb {
                            background: #afafaf; /* 스크롤바 막대 색상 */
                            //border: 2px solid #9b6a2f; /* 스크롤바 막대 테두리 설정  */
                            border-radius: 13px;
                        }
                        &.wide{
                            width: 100%;
                            left:0;
                        }

                        .chatting_container{
                            overflow-y: scroll;
                            width: 100%;
                            height: calc(100% - 50px);
                            padding-left: 30px;
                            padding-top: 40px;
                            position: relative;
                            z-index: 0;

                           

                            &.empty{
                                padding:0;
                            }

                           
                            .empty_chat{
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                height: 100%;
                                text-align: center;
                                color:#014F82;
                            }

                            .ai_chat{
                                align-items: center;
                                display: flex;
                                margin-bottom: 15px;

                                img{
                                    align-self: flex-start;
                                    margin-right:5px;
                                    transform: translateY(-8px);
                                    width:48px;
                                }
                                .chat_data{
                                    //position: relative;
                                    .chat{
                                        border-radius: 15px;
                                        background: #fff;
                                        padding: 6px 15px;
                                        margin-right: 30px;
                                        white-space: pre-line;
                                        font-size: 0.85rem;
                                        line-height: 1.24rem;

                                        .dot-flashing {
                                            position: relative;
                                            width: 10px;
                                            height: 10px;
                                            border-radius: 5px;
                                            background-color: #0171BB;
                                            color: #0171BB;
                                            animation: dot-flashing 1s infinite linear alternate;
                                            animation-delay: 0.5s;
                                            margin: 5px 10px;
                                          }
                                          .dot-flashing::before, .dot-flashing::after {
                                            content: "";
                                            display: inline-block;
                                            position: absolute;
                                            top: 0;
                                          }
                                          .dot-flashing::before {
                                            left: -15px;
                                            width: 10px;
                                            height: 10px;
                                            border-radius: 5px;
                                            background-color: #0171BB;
                                            color: #0171BB;
                                            animation: dot-flashing 1s infinite alternate;
                                            animation-delay: 0s;
                                          }
                                          .dot-flashing::after {
                                            left: 15px;
                                            width: 10px;
                                            height: 10px;
                                            border-radius: 5px;
                                            background-color: #0171BB;
                                            color: #0171BB;
                                            animation: dot-flashing 1s infinite alternate;
                                            animation-delay: 1s;
                                          }
                                          
                                          @keyframes dot-flashing {
                                            0% {
                                              background-color: #0171BB;
                                            }
                                            50%, 100% {
                                              background-color: rgba(152, 128, 255, 0.2);
                                            }
                                          }
                                    }
                                    small{
                                        display: block;
                                        text-align: left;
                                        color: #999;
                                        font-family: Pretendard;
                                        font-size: 12px;
                                        font-style: normal;
                                        font-weight: 300;
                                        line-height: normal;
                                        margin-top: 5px;
                                        //position: absolute;
                                        padding-left:11px;
                                    }
                                }
                            }
                            .user_chat{
                                display: flex;
                                justify-content: flex-end;
                                margin-bottom: 15px;
                                .chat_data{
                                    //position: relative;
                                    
                                    padding-right: 30px;
                                    display: flex;
                                    align-items: end;
                                    justify-content: end;
                                    flex-direction: column;

                                    .chat{
                                        border-radius: 15px;
                                        background: #0171BB;
                                        width: fit-content;
                                        padding: 6px 15px;
                                        color: white;
                                        white-space: pre-line;
                                        font-size: 0.85rem;
                                        line-height: 1.35rem;
                                    }

                                    small{
                                        color: #999;
                                        font-family: Pretendard;
                                        font-size: 12px;
                                        font-style: normal;
                                        font-weight: 300;
                                        line-height: normal;
                                        margin-top: 5px;
                                        text-align: right;
                                        display: flex;
                                        justify-content: end;
                                        margin-right:10px;
                                    }
                                }
                            }
                        }
                        .chatting_input_container{
                            height: fit-content;
                            display: flex;
                            padding: 10px;
                            gap: 10px;
                            border-top:1px solid #ddd;
                            background-color: #fff;

                            .chat_text_length{
                                display: flex;
                                align-items: center;
                                color: #b7b7b7;
                                font-size: 0.75rem;
                            }
                            button{
                                width: 30px;
                                align-items: center;
                                display: flex;
                                justify-content: center;
                                &:disabled{
                                    cursor: default;
                                }
                            }
                            textarea{
                                width: 100%;
                                border-radius: 15px;
                                background: #E5EEFA;
                                border: none;
                                padding: 5px 10px;
                                height: 42px;
                                max-height: 100px;
                                line-height: 20px;
                                font-size: 0.95rem;
                                color:#25385E;
                                min-height: 35px;
                                padding-top: 7px;

                                ::-webkit-scrollbar {
                                    background-color: transparent;
                                    border-radius: 13px;
                                    width: 5px;
                                }
                            }
                        }
                    }

                    

                    .only_container{
                        width: 100%;
                        display: flex;
                        flex-flow: column;
                        justify-content: stretch;
                        height: 100%;
                        transition: 0.7s ease;
                        background-color: #F2F2F2;
                        position: relative;

                        .version{
                            position: absolute;
                            top: 7px;
                            right: 10px;
                            font-size: 0.75rem;
                            color: #cdcdcd;
                        }

                        ::-webkit-scrollbar {
                            background-color:transparent;
                            border-radius: 13px;
                            width: 5px;
                        }
                        ::-webkit-scrollbar-thumb {
                            background: #afafaf; /* 스크롤바 막대 색상 */
                            //border: 2px solid #9b6a2f; /* 스크롤바 막대 테두리 설정  */
                            border-radius: 13px;
                        }
                        &.wide{
                            width: 100%;
                            left:0;
                        }

                        .chatting_container{
                            overflow-y: scroll;
                            width: 100%;
                            height: calc(100% - 50px);
                            padding-left: 30px;
                            padding-top: 40px;
                            position: relative;
                            z-index: 0;

                           

                            &.empty{
                                padding:0;
                            }

                           
                            .empty_chat{
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                height: 100%;
                                text-align: center;
                                color:#014F82;
                                
                                .dot-flashing {
                                    position: relative;
                                    width: 10px;
                                    height: 10px;
                                    border-radius: 5px;
                                    background-color: #0171BB;
                                    color: #0171BB;
                                    animation: dot-flashing 1s infinite linear alternate;
                                    animation-delay: 0.5s;
                                    margin: 5px 10px;
                                  }
                                  .dot-flashing::before, .dot-flashing::after {
                                    content: "";
                                    display: inline-block;
                                    position: absolute;
                                    top: 0;
                                  }
                                  .dot-flashing::before {
                                    left: -15px;
                                    width: 10px;
                                    height: 10px;
                                    border-radius: 5px;
                                    background-color: #0171BB;
                                    color: #0171BB;
                                    animation: dot-flashing 1s infinite alternate;
                                    animation-delay: 0s;
                                  }
                                  .dot-flashing::after {
                                    left: 15px;
                                    width: 10px;
                                    height: 10px;
                                    border-radius: 5px;
                                    background-color: #0171BB;
                                    color: #0171BB;
                                    animation: dot-flashing 1s infinite alternate;
                                    animation-delay: 1s;
                                  }
                                  
                                  @keyframes dot-flashing {
                                    0% {
                                      background-color: #0171BB;
                                    }
                                    50%, 100% {
                                      background-color: rgba(152, 128, 255, 0.2);
                                    }
                                }
                            }

                            .ai_chat{
                                align-items: center;
                                display: flex;
                                margin-bottom: 15px;

                                img{
                                    align-self: flex-start;
                                    margin-right:5px;
                                    transform: translateY(-8px);
                                    width:48px;
                                }
                                .chat_data{
                                    
                                    .guide_button_container{
                                        .guide_button{
                                            background: white;
                                            border-radius: 30px;
                                            padding: 5px 15px;
                                            margin-top: 10px;
                                            font-size: 12px;
                                            background-color: #eea112;
                                            color:#fff;

                                            & ~ .guide_button{
                                                margin-left:4px;
                                            }
                                            &:hover{
                                                background: #ff7300;
                                            }
                                        }
                                    }
                                    //position: relative;
                                    .chat{
                                        border-radius: 15px;
                                        background: #fff;
                                        padding: 6px 15px;
                                        margin-right: 30px;
                                        white-space: pre-line;
                                        font-size: 0.85rem;
                                        line-height: 1.24rem;
                                        max-width: fit-content;
                                        .dot-flashing {
                                            position: relative;
                                            width: 10px;
                                            height: 10px;
                                            border-radius: 5px;
                                            background-color: #0171BB;
                                            color: #0171BB;
                                            animation: dot-flashing 1s infinite linear alternate;
                                            animation-delay: 0.5s;
                                            margin: 5px 10px;
                                          }
                                          .dot-flashing::before, .dot-flashing::after {
                                            content: "";
                                            display: inline-block;
                                            position: absolute;
                                            top: 0;
                                          }
                                          .dot-flashing::before {
                                            left: -15px;
                                            width: 10px;
                                            height: 10px;
                                            border-radius: 5px;
                                            background-color: #0171BB;
                                            color: #0171BB;
                                            animation: dot-flashing 1s infinite alternate;
                                            animation-delay: 0s;
                                          }
                                          .dot-flashing::after {
                                            left: 15px;
                                            width: 10px;
                                            height: 10px;
                                            border-radius: 5px;
                                            background-color: #0171BB;
                                            color: #0171BB;
                                            animation: dot-flashing 1s infinite alternate;
                                            animation-delay: 1s;
                                          }
                                          
                                          @keyframes dot-flashing {
                                            0% {
                                              background-color: #0171BB;
                                            }
                                            50%, 100% {
                                              background-color: rgba(152, 128, 255, 0.2);
                                            }
                                        }
                                    }
                                    small{
                                        display: block;
                                        text-align: left;
                                        color: #999;
                                        font-family: Pretendard;
                                        font-size: 12px;
                                        font-style: normal;
                                        font-weight: 300;
                                        line-height: normal;
                                        margin-top: 5px;
                                        //position: absolute;
                                        padding-left:11px;
                                    }
                                }
                            }
                            .user_chat{
                                display: flex;
                                justify-content: flex-end;
                                margin-bottom: 15px;
                                .chat_data{
                                    //position: relative;
                                    
                                    padding-right: 30px;
                                    display: flex;
                                    align-items: end;
                                    justify-content: end;
                                    flex-direction: column;

                                    .chat{
                                        border-radius: 15px;
                                        background: #0171BB;
                                        width: fit-content;
                                        padding: 6px 15px;
                                        color: white;
                                        white-space: pre-line;
                                        font-size: 0.85rem;
                                        line-height: 1.35rem;
                                    }

                                    small{
                                        color: #999;
                                        font-family: Pretendard;
                                        font-size: 12px;
                                        font-style: normal;
                                        font-weight: 300;
                                        line-height: normal;
                                        margin-top: 5px;
                                        text-align: right;
                                        display: flex;
                                        justify-content: end;
                                        margin-right:10px;
                                    }
                                }
                            }
                        }
                        .chatting_input_container{
                            height: fit-content;
                            display: flex;
                            padding: 10px;
                            gap: 10px;
                            border-top:1px solid #ddd;
                            background-color: #fff;

                            .chat_text_length{
                                display: flex;
                                align-items: center;
                                color: #b7b7b7;
                                font-size: 0.75rem;
                            }
                            button{
                                width: 30px;
                                align-items: center;
                                display: flex;
                                justify-content: center;
                                &:disabled{
                                    cursor: default;
                                }
                            }
                            textarea{
                                width: 100%;
                                border-radius: 15px;
                                background: #E5EEFA;
                                border: none;
                                padding: 5px 10px;
                                height: 42px;
                                max-height: 100px;
                                line-height: 20px;
                                font-size: 0.95rem;
                                color:#25385E;
                                min-height: 35px;
                                padding-top: 7px;

                                ::-webkit-scrollbar {
                                    background-color: transparent;
                                    border-radius: 13px;
                                    width: 5px;
                                }
                            }
                        }
                    }
                }
            }
        }
        .popup_content{
            width: 100%;
            max-width: none;
            max-height: none;
            border-radius: 13px;
            background: #E5EEFA;
            overflow: hidden;
            position: relative;
            padding: 0;
            margin: 0;
            display: flex;
            flex-flow: column;
            border:none;

            .backImg{
                position: absolute;
                top: 114px;
                left: -7px;
                width: 222px;

                &.rights{
                    left: auto;
                    right: -74px;
                    top: auto;
                    bottom: 123px;
                    transform: rotateY(180deg);
                }
            }

            .gai_content{
                display: flex;
                justify-content: center;
                align-items: center;
                height: calc(100% - 125px);
                flex-direction: column;
                position: relative;
                z-index: 2;
                height:calc(100% - 125px);
                overflow: hidden;

                .content_title_sub{
                    color:#333;
                    font-size: 1.1rem;
                    margin-bottom:30px;

                    .minerBr{
                        display:none;
                    }

                    .blues{
                        font-size: 1.1rem;
                        color:#0171BB;
                        font-weight: 700;
                    }
                }


                .content_title{
                    display: flex;
                    align-items: center;
                    color: #333;
                    text-align: center;
                    font-size: 23px;
                    font-style: normal;
                    font-weight: 500;
                    // grid-gap: 5px;
                    // gap: 5px;
                    width: 100%;
                    justify-content: center;
                    // max-height: 140px;
                    // height: 100%;
                    
                    .imgWrap{
                        margin-right:12px;
                        width:54px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        position: relative;
                        transform: translateY(-2px);

                        img{
                            z-index: 2;
                            position: relative;
                        }

                        &::before{
                            content: "";
                            width:48px;
                            height:48px;
                            border-radius: 1000px;
                            z-index: 1;
                            box-shadow: 0 5px 10px rgba(0,0,0,0.15);
                            position: absolute;
                            top:50%;
                            left:50%;
                            transform: translate(-50%,-50%);
                        }
                    }

                    .title_first{
                        font-size: 1.4rem;
                        display:inline-block;
                        margin-right:10px;
                        color:#014F82;
                        font-weight: 700;
                    }
                    .title_second{
                        font-size: 1.4rem;
                        display:inline-block;
                        color:#014F82;
                        font-weight: 700;
                    }

                    .logo{
                        width: 70px;
                        margin-right:2px;
                        transform: translateY(-10px);
                    }
                    .green{
                        color: #0D6B37;
                        font-size: 23px;
                        font-style: normal;
                        font-weight: 500;
                    }
                }
                .content_container{    
                    // height: 100%;
                    // max-height: 450px;
                    width: 100%;

                    .content_buttons{
                        display: flex;
                        gap: 30px;
                        width: 90%;
                        justify-content: center;
                        align-items: center;
                        height: 100%;
                        margin:0 auto;
                        min-width: 250px;

                        button{
                            width: 377px;
                            height: 228px;
                            border-radius: 10px;
                            background: #0B94EE;
                            color: white;
                            transition: 0.7s ease;


                            .iconBox{
                                height:74px;
                                margin:0 auto;
                                margin-bottom:8px;
                            }


                            &.dark {
                                background: #0065ad;

                                &:hover{
                                    color: #fff;
                                    background: #237ebf;
                                }
                            }

                            img{
                                display: inline-block;
                                width:74px;

                                &.manual_icons{
                                    width:67px;
                                }
                            }

                            p {
                                font-size: 1.3rem;
                                line-height: 1.5rem;
                                font-weight: 700;

                                &.inflex{
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;

                                    & > img {
                                        width:10px;
                                        margin-bottom: 0;
                                        margin-left: 7px;
                                    }
                                }
                                &.gpt_version{
                                    font-weight: 400;
                                    font-size: 0.85rem;
                                }
                            }

                            &:hover{
                                color: #fff;
                                background: #71BAEB;
                            }
                        }
                    }
                }
                
            }
            .gai_bottom{
                width: 100%;
                height: 75px;
                background:linear-gradient(270deg, #40B16F 1.52%, #0171BB 91.86%);
                position: absolute;
                bottom: 0;
                left: 0;
                position: relative;
                z-index: 3;

                img{
                    position: absolute;
                    top: -100px;
                    right: 50px;
                }
            }
        }
    }
}

#gai_popup{
    
    ::-webkit-scrollbar {
        background-color: transparent;
        border-radius: 13px;
        width: 4px;
    }
    ::-webkit-scrollbar-thumb {
        background: #afafaf; /* 스크롤바 막대 색상 */
        //border: 2px solid #9b6a2f; /* 스크롤바 막대 테두리 설정  */
        border-radius: 13px;
    }

    select{
        border: 1px solid #dddddd;
        border-radius: 3px;
        font-size: 10pt;
        appearance: none;
        text-overflow: ellipsis;
        width: 210px;
        height: 30px;
        padding: 5px 50px 5px 10px;
        background: #fff url('#{$image-path}/select_arrow.svg') no-repeat calc(100% - 10px) center !important;
    }
}