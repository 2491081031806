.page-resource-consulting {
  .content {
    .sub__content {
      .sub__title {
        margin-bottom: 0;
      }
    }
  }
  .introduction {
    .introduction_content {
      .introduction_title {
        border-bottom: 0;
      }
    }
  }
}

.introduction {
  .introduction_content {
    flex: 1;
    padding-left: 70px;
    max-width: calc(100% - 200px);
    padding-top: 0px;

    @media screen and (max-width: 1220px) {
      padding-left: 40px;
      //padding-top: 55px;
      max-width: calc(100% - 180px);
    }

    @media screen and (max-width: 768px) {
      padding-left: 0;
      padding-top: 40px;
      max-width: 100%;
    }
    .introduction_title {
      margin-bottom: 2rem;
      line-height: 4.3rem;
      border-bottom: 2px solid gray;
      text-align: left;


      span {
        font-size: 1.5rem;
        font-weight: 600;
        position: unset;
      }
    }
  }
  .introduction__title {
    text-align: center;
    font-size: 1.5rem;
    font-weight: 600;
    margin-top: 0.5em;
    margin-bottom: 1em;
    word-break: keep-all;
    span {
      color: $theme-red;
      font-size: 1em;
    }
    @media screen and (max-width: 1220px) {
      font-size: 1.25rem;
    }
  }
  .consulting_desc {
    background: aliceblue;
    padding: 2em 3em;
    display: flex;
    align-items: center;
    h1 {
      font-size: 1.25rem;
    }
    .consulting_title {
      margin-left: 80px;
    }
    .first_p {
      font-size: 0.9rem;
      color: deepskyblue;
    }
    p {
      font-size: 1rem;
    }
    span {
      margin-left: 100px;
      font-size: 0.9rem;
      color: gray;
    }
  }
  .introduction__desc {
    //background: $theme-white-2;
    margin-top:0;
    display: flex;
    align-items: center;

    &.orgWrap{
      margin-top:78px;
    }
    //padding: 2em 3em;
    //@media screen and (max-width: 1220px) {
    //  padding: 1.5em 2em;
    //}

    @media screen and (max-width: 650px) {
      display: block;
      text-align: center;
      margin-top:0;
      img {
        margin: 0 auto;
      }
    }

    p {
      flex: 1;
      order: 0;
      word-break: keep-all;
      margin-right: 2em;
      font-size: 1.25rem;
      line-height: 1.5em;

      @media screen and (max-width: 1220px) {
        font-size: 1.125rem;
      }
      @media screen and (max-width: 650px) {
        margin-right: 0;
        margin-top: 1.5em;
      }
    }
    img {
      order: 2;
    }
  }

  .introduction__info {
    margin-top: 2.5em;
    word-break: keep-all;
    //text-align: center;
    margin-bottom: 3em;
    .info_3 {
      text-align: center;
      font-size: 1.5rem;
    }
    h3 {
      font-size: 1.25rem;
      font-weight: 600;
      margin-bottom: 0.5em;
    }
    p {
      font-size: 1rem;
    }
    .row {
      margin: 0;
      margin-top: 1em;
      .col {
        background: $theme-white-3;
        padding: 1.5em 1em;
        & ~ .col {
          margin-left: 10px;
        }

        p {
          margin-top: 0.5em;
        }
      }
    }
  }

  .introduction__result {
    text-align: center;
    border: 1px solid$theme-red;
    font-size: 1.25rem;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.75em 0;
    border-radius: 2em;
    margin-top: 2em;
    span {
      color: $theme-red;
      vertical-align: middle;
      font-size: 1.25rem;
    }
  }

  .introduction__graph {
    background: url('#{$image-path}/intro-img8.png') no-repeat top center;
    padding-top: 7em;
    margin-top: 1em;
    display: flex;
    align-items: flex-start;
    justify-content: center;

    @media screen and (max-width: 650px) {
      display: block;
      background-size: 80% auto;
      padding-top: 20%;
    }

    ._col {
      margin: 0 20px;
      flex: 1;

      @media screen and (max-width: 650px) {
        & ~ ._col {
          margin-top: 2em;
        }
      }
      .img {
        max-width: 80%;
        margin: 0 auto;
        @media screen and (max-width: 650px) {
          max-width: 150px;
        }
      }
      h4 {
        font-size: 1.25rem;
        font-weight: 600;
        margin: 0.5em 0;
      }
      strong {
        color: $theme-red;
        font-size: 1.25rem;
        display: block;
        margin-top: 2em;
      }
      .info {
        border: 1px solid$theme-gray-2;
        border-radius: 3px;
        padding: 1.5em 2em 1.5em 2.5em;
        text-align: left;
        position: relative;

        @media screen and (max-width: 1220px) {
          padding: 1em 1em 1em 2em;
        }
        &:after {
          content: '';
          display: block;
          width: 34px;
          height: 40px;
          background: url('#{$image-path}/intro-img9.png') no-repeat top center;
          position: absolute;
          bottom: -30px;
          left: 50%;
          transform: translateX(-50%);
        }
        p {
          line-height: 1.25em;
          position: relative;
          &:before {
            content: '-';
            position: absolute;
            line-height: 1.25em;
            left: -0.75em;
            top: 0;
          }
          & ~ p {
            margin-top: 1em;
          }
        }
      }
    }
  }
  .introduction__future {
    text-align: center;
    max-width: 1024px;
    margin: 0 auto;
    h3 {
      display: inline-block;
      border-radius: 2em;
      font-size: 1.75rem;
      line-height: 2em;
      color: $theme-red;
      border: 2px solid $theme-red;
      width: 10em;
    }
  }
}

.map {
  .map__content {
    @media screen and (max-width: 1220px) {
    }

    .mapbox {
      iframe {
        width: 100%;
        height: 30em;
        @media screen and (max-width: 1220px) {
          height: 20em;
        }
      }
    }
    .map__info {
      margin-top: 2.5em;

      @media screen and (max-width: 768px) {
        margin-top:15px;
      }


      ul {
        border: 1px solid $theme-gray-1;
        padding: 0 2rem;

        @media screen and (max-width: 768px) {
          padding: 0;
          border: none;
        }


        li {
          padding: 2rem 0;

          @media screen and (max-width: 768px) {
            padding: 0;
          }

          & ~ li {
            padding-top:10px;
            margin-top:10px;
            border-top: 1px solid $theme-gray-1;
          }

        }
        h3,
        p {
          padding-left: 2.5em;

          @media screen and (max-width: 768px) {
            padding-left:24px;
          }


          .wayInfo {
            padding-left:12px;
            position: relative;

            .waydash {
              position: absolute;
              left:1px;
            }
          }
        }
        h3 {
          line-height: 1.5em;

          @media screen and (max-width: 1220px) {
            font-size: 1.25rem;
            padding-left: 2em;
          }

         


          &.i1 {
            background: url('#{$image-path}/map_icon_1.png') no-repeat left
              center;
          }
          &.i2 {
            background: url('#{$image-path}/map_icon_5.png') no-repeat left
              center;
          }
          &.i3 {
            background: url('#{$image-path}/map_icon_3.png') no-repeat left
              center;
          }
          &.i4 {
            background: url('#{$image-path}/map_icon_2.png') no-repeat left
              center;
          }
          &.i5 {
            background: url('#{$image-path}/map_icon_4.png') no-repeat left
            center;
          }
          &.i6 {
            background: url('#{$image-path}/map_icon_6.png') no-repeat left
            center;
          }


          @media screen and (max-width: 768px) {
            font-size: 1rem;
            padding-left: 24px;

            &.i1, &.i2, &.i3, &.i4, &.i5,&.i6{
              background: url('#{$image-path}/disc_green.svg') no-repeat left
              center;
              background-size: 18px;
            }
          }
          
        }
        p {
          margin-top: 1em;

          @media screen and (max-width: 768px) {
            margin-top:4px;
          }
        }
      }
    }
    .map__info-header {
      margin-bottom: 1.5em;

       @media screen and (max-width: 768px) {
          margin-top :1px;
          margin-bottom: 20px;
        }

      h2 {
        font-size: 1.5rem;
        font-weight: 400;

        @media screen and (max-width: 768px) {
          font-size: 1.3rem;
        }

      }
      p {
        margin-top: 0.5rem;
        font-size: 1rem;

        @media screen and (max-width: 768px) {
          margin-top:3px;
        }

      }
    }
  }
}

.membership {
  .membership__content {
    margin-top: 3.5em;
    @media screen and (max-width: 1220px) {
      margin-top: 2em;
    }
  }
  .membership__register {
    margin-top: 3em;
    display: flex;
    justify-content: center;
    a {
      width: 15rem;
      line-height: 50px;
      color: $white;
      background: $theme-red;
      border-radius: 2em;
      text-align: center;
      font-size: 1.25rem;
    }
  }
  .membership__list {
    margin: 0 -10px;
    display: flex;
    flex-wrap: wrap;
    @media screen and (max-width: 768px) {
      margin: 0;
      display: block;
    }
  }
  .membership__item {
    width: calc(50% - 20px);
    margin: 0 10px;
    border: 1px solid $theme-gray-1;

    &:nth-child(n + 3) {
      margin-top: 20px;
    }
    @media screen and (max-width: 1220px) {
    }
    @media screen and (max-width: 768px) {
      width: 100%;
      margin: 0;
      &:nth-child(n + 2) {
        margin-top: 10px;
      }
    }

    .thumb {
      height: 180px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: $theme-white-3;

      @media screen and (max-width: 768px) {
        height: 150px;
      }
    }

    .info {
      text-align: center;
      padding: 1.5em 0;
      h3 {
        font-size: 1.5rem;
        @media screen and (max-width: 768px) {
          font-size: 1.25em;
        }
      }
      p {
        font-size: 1rem;
        line-height: 2em;
        margin-top: 1em;

        span {
          color: $theme-red;
        }
      }
    }
  }
}
