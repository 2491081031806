.mainSlider {
  // background: $theme-gray-8;
  position: relative;
  margin: 0 auto;
  max-width: 1200px;
  padding-top:120px;


  @media (max-width: 1226px) {
    margin-bottom: 25px;
    padding-top: 76px;
  }


  @media (max-width: 1024px) {
    margin-bottom: 10px;
    padding-top: 50px;
  }



  .slick-arrow {
   width: 40px;
   height: 40px;
   background:#fff;
   position: absolute;
   top: 50%;
   z-index: 1;
   transform: translateY(-50%);

   @media screen and (max-width: 1220px) {
    //  width: 30px;
    //  height: 60px;
    //  top: 110px;
    //  display: none !important;
   }
  
   @media screen and (max-width: 768px) {
    width: 30px;
    height: 30px;
   }
   &:before {
     display: none;
   }
   &.slick-prev {
     background: rgba(0,0,0,0.3) url('#{$image-path}/right_wh_slide.svg') no-repeat center;
     background-size: auto;
     border-radius: 50%;
     left:20px;

     @media screen and (max-width: 1220px) {
       left: 10px;
       margin-left: 0;
     }
     @media screen and (max-width: 768px) {
     }
   }
   &.slick-next {
     background:rgba(0,0,0,0.3)  url('#{$image-path}/left_wh_slide.svg') no-repeat center;
     background-size: auto;
     border-radius: 50%;
     right:20px;

     @media screen and (max-width: 1220px) {
       right: 10px;
       margin-right: 0;
     }
     @media screen and (max-width: 768px) {
     }
   }
  }

  .slick-dots {
    width: fit-content;
    max-width: 1200px;
    position: absolute;
    background: none;
    bottom: 25px;
    left: auto;
    z-index: 50;
    text-align: right;
    padding: 13px;
    right: 20px;
    border-radius: 100px;
    background: rgba(0,0,0,0.4);
    display:flex !important;
    align-items: center;
    justify-content: center;

    @media screen and (max-width: 1220px) {
      // bottom: 100px;
    }
    @media screen and (max-width: 768px) {
      text-align: center;
      right: 50%;
      transform: translateX(50%);
      bottom: 20px;
      padding: 6px;

    }

    li {
      margin: 0;
      width: auto;
      padding: 0 4px;
      height: auto;
      vertical-align: top;

      &.slick-active button {
        background: #00CB59;
        width:30px;

        @media (max-width: 768px) {
          background: #00CB59;
        }
      }
    }
    button {
      transition: all 0.5s;
      background: #fff;
      width: 10px;
      height: 10px;
      padding: 0;
      border-radius: 100px;

      @media (max-width: 768px) {
           width: 7px;
            height: 7px;
            background-color: #fff;
      }
    }
  }
  .slick-dots li button:before {
    display: none;
  }
}

.mainSlider__item {
  // background-size: cover;
  // background: linear-gradient(180deg,  #6eace5, #aedaf3) no-repeat center;

  @media screen and (max-width: 1220px) {
  }
  @media screen and (max-width: 768px) {
  }

  .wrap {
    padding-top: 135px;
    //padding-bottom: 42px;
    //padding-bottom: 30px;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    word-break: keep-all;
    height: 600px;

    @media screen and (max-width: 1220px) {
      height: 550px;
    }

    @media screen and (max-width: 760px) {
      padding-top: 60px;
      height: auto;
      //height: 480px;
      display: block;
    }
    .left {
      padding-bottom:198px;
      @media screen and (max-width: 760px) {
        margin-top: 2em;
        text-align: center;
        margin-bottom: 2em;
        padding-bottom:34px;
      }

    }
    .right {
      margin-right: -5em;
      align-items: flex-end;

      @media screen and (max-width: 760px) {
        margin: 0;
        text-align: center;

        img {
          max-width: 500px;
          margin:0 auto;
          width: 100%;
        }
      }
    }

    p {
      font-size: 20px;
      white-space: pre-line;
      color:white;
      @media screen and (max-width: 1220px) {
        font-size: 18px;
      }
      @media screen and (max-width: 768px) {
        font-size: 14px;
        text-align: left;
        width: 245px;
        display: inline-block;
        letter-spacing: -0.8px;
      }
    }
    h2 {
      font-size: 38px;
      margin-top: .5em;
      font-weight: 600;
      white-space: pre-line;
      color: white;
      @media screen and (max-width: 1220px) {
        font-size: 30px;
      }
      @media screen and (max-width: 768px) {
        font-size: 24px;
        width: 250px;
        text-align: left;
        display: inline-block;
      }
    }
  }
}


.page-home2 {
}
.visual_wrap {
  padding-top: 145px;

  @media (max-width: 768px) {
    padding-top: 60px;
  }
}