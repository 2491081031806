.relationorg {
  padding: 0 20px;
  .slick-arrow {
    &:before {
      display: none;
    }
  }
  .slick-prev {
    background: url('#{$image-path}/link_left.png') no-repeat center;
  }
  .slick-next {
    background: url('#{$image-path}/link_right.png') no-repeat center;
  }
  .relationorg__item {
    padding: 0 10px;
  }
  .inner_item {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 80px;
    border: 1px solid $theme-gray-11;
    border-radius: 3px;
  }
  img {
    display: inline-block;
    vertical-align: top;
  }
}
