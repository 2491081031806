//@font-face {
//  font-family: 'ChosunGs';
//  src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_20-04@1.0/ChosunGs.woff') format('woff');
//  font-weight: normal;
//  font-style: normal;
//}


.th_padding_none {
  th {
    padding: 0 !important;
  }
}

.myProgram {
  .myProgramLayout {
    width: 100%;
    overflow: auto;
  }

  .board__list {
    .col1 {
      width: 40px;
    }

    .col2 {
      flex: 1;
      max-width: 100%;

      &.text-center {
        text-align: center;
      }
    }

    .col3 {
      width: 130px;
    }

    .col4 {
      width: 70px;
    }

    .col5 {
      width: 100px;
    }

    .col6 {
      width: 100px;
    }

    .col7 {
      width: 100px;
    }

    .col8 {
      width: 100px;
    }
  }

  .show-process {
    color: $white;
    background: $theme-red;
    font-size: 14px;
    height: 25px;
    padding: 0 10px;
    border-radius: 3px;
    display: block;
    margin: 0 auto;
  }

  .btn {
    display: inline-block;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 150px;
    overflow: hidden;
    border-radius: 3px;
    line-height: 25px;
    padding: 0 10px;
    font-size: 14px;
    color: $white;
    background: $theme-black-5;
    margin: 0 2px;

    //display: block;
    &~.btn {
      margin-top: 5px;
    }
  }

  .board__list-body {
    .tr {
      text-align: center;
      min-height: 64px;
      height: auto;
      padding: 10px 0;
    }

    @media screen and (max-width: 1024px) {
      .tr {
        padding: 10px !important;
      }

      .col3 {
        br {
          display: none;
        }
      }

      .show-process {
        display: inline-block;
        margin: 0;
        margin-left: 10px;
      }

      .btn {
        margin: 0;
        margin-right: 5px;
      }
    }
  }
}

.page-member {
  .close {
    position: fixed;
    color: black;
    font-size: 2rem;
    top: 3%;
    right: 3%;
    line-height: 0;

    &::before {
      content: '\00D7';
      display: block;
    }

    .invisible-btn {
      position: absolute !important;
      width: 1px;
      height: 1px;
      overflow: hidden;
      clip: rect(1px 1px 1px 1px);
      clip: rect(1px, 1px, 1px, 1px);
      padding: 0;
      margin: -1px;
      border: 0;
    }
  }
}

// 회원가입 약관 모달창 //
@media screen and (max-width: 960px) {
  .pop_member-overlay .pop_member-content {
    width: 90% !important;

    .member_popup {
      height: 450px !important;
    }
  }
}

.pop_member-overlay {
  background: rgba(0, 0, 0, 0.5);

  .pop_member-content {
    width: 50%;

    .member_popup {
      background-color: white;
      padding: 30px;
      overflow-y: scroll;
      height: 800px;

      .pop_close {
        &:after {
          content: '\00D7';
          font-size: 30px;
        }

        float: right;
        font-weight: 700;
        line-height: 0.4;
        opacity: .5;
        display: block !important;
        font-size: 0;
        outline: none;
        border: none;
      }

      .modal_content .basic_page_content {
        .title {
          font-size: 1.2rem;
          font-weight: 800;
        }

        .modal_content {
          h3 {
            font-size: 1rem;
            font-weight: 800;
          }

          padding-top: 60px;
          white-space: pre-line;
          word-break: keep-all;
          line-height: 1.614;
        }

        .content-title {
          font-size: 1.1rem;
          padding-top: 60px;
        }

        .modal_content_first h3 {
          font-size: 1rem;
          font-weight: 800;
        }
      }
    }
  }
}


.page-member-intergratedlogin {
  form {
    width: 50%;
    margin: auto;
  }

  label {
    display: block;
  }

  .form-group .jd_input {

    width: calc(100% - 110px);
    display: inline-block;
    height: calc(1.5em + .75rem + 2px);
    margin-bottom: 10px;
    padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  }

  .form-group .btn_id_ch {
    box-sizing: border-box;
    display: inline-block;
    text-align: center;
    min-width: 100px;
    height: calc(1.5em + .75rem + 2px);
    line-height: 35px;
    padding: 0 5px;
    text-align: center;
    background: #272b37;
    color: #fff;
    outline: none;
    border: none;
    border-radius: .25rem;
    vertical-align: top;
    margin-left: 10px;
  }

  .btn_submit {
    display: block;
    width: 100%;
    height: 46px;
    line-height: 46px;
    text-align: center;
    background: #272b37;
    color: #fff;
    outline: none;
    border: none;
    border-radius: 0;
  }
}



// 회원가입 약관 모달창 끝 //

.title_text {
  text-align: center;
  margin-top: -20px;
  margin-bottom: 50px;
}

.registerWrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 50px 0;
  position: relative;

  label {
    flex-basis: 30%;
  }

  input {
    width: 100% !important;
    margin-bottom: 0 !important;
  }

  small {
    position: absolute;
    bottom: -25px;
    right: 0;
  }
}

.registerWrapfirst {
  margin: 40px 0 50px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;

  label {
    flex-basis: 30%;
  }

  small {
    position: absolute;
    bottom: -25px;
    right: 0;
  }

  .registerWrap_cont_wrap {
    display: flex;
    width: 100%;

    input {
      margin-bottom: 0 !important;
    }
  }
}

.logo_green {
  position: absolute;
  top: 3%;
  left: 3%;
}

.page-member-register {
  .intro__footer {
    display: none;
  }

  .form-group .text-danger {
    display: block;
  }

  .space-between {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;

    small {
      position: absolute;
      right: 100px;
    }
  }

  .form-group .jd_input {

    width: calc(100% - 110px);
    display: inline-block;
    height: calc(1.5em + .75rem + 2px);
    margin-bottom: 10px;
    padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  }

  .form-group .btn_id_ch {
    box-sizing: border-box;
    display: inline-block;
    text-align: center;
    min-width: 100px;
    height: calc(1.5em + .75rem + 2px);
    line-height: 35px;
    padding: 0 5px;
    text-align: center;
    background: #272b37;
    color: #fff;
    outline: none;
    border: none;
    border-radius: .25rem;
    vertical-align: top;
    margin-left: 10px;
  }

  input[type=radio] {
    vertical-align: middle;
  }

  .btn_submit_wrap {
    margin-bottom: 100px;
  }

  .btn_submit_wrap button.btn_submit {
    display: block;
    width: 100%;
    height: 46px;
    line-height: 46px;
    text-align: center;
    background: #272b37;
    color: #fff;
    outline: none;
    border: none;
    border-radius: 0;
  }

  .react-datepicker-wrapper {
    width: 100%;
  }

  .radiolabel {
    margin-right: 5px;

    .ipradio {
      margin-right: 5px;
    }
  }

  .header,
  .footer,
  .mobileUserMenu,
  .mobileNav,
  .quick {
    display: none;
  }

  input {
    font-family: auto;
  }

  .content {
    padding-right: 10px;
    padding-left: 10px;
    padding-top: 60px !important;
    width: 100%;
    max-width: 890px;
    margin: 0 auto;
    position: relative;
    top: calc(50% - 240px);

    .page_title {
      font-size: 36px;
      font-weight: bold;
      margin: 0 0 35px 0;
      text-align: center;
    }
  }


  .col-form-label {
    display: block;
  }

  .zip {
    .btn_inline {
      box-sizing: border-box;
      display: inline-block;
      text-align: center;
      min-width: 100px;
      height: calc(1.5em + .75rem + 2px);
      line-height: 35px;
      padding: 0 5px;
      text-align: center;
      background: #272b37;
      color: #fff;
      outline: none;
      border: none;
      border-radius: .25rem;
      vertical-align: top;
      margin-left: 10px;
    }

    .frm_ipt {
      display: inline-block;
      height: calc(1.5em + .75rem + 2px);
      margin-bottom: 10px;
      padding: .375rem .75rem;
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.5;
      color: #495057;
      background-color: #fff;
      background-clip: padding-box;
      border: 1px solid #ced4da;
      border-radius: .25rem;
      transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;

      &.frm_100 {
        width: 100%;
      }
    }
  }

  .join_info {
    margin: 0 0 20px 0;
    /*color: #aaa;*/
    color: #747474;
  }

  .join_desc {
    margin: 0 0 10px 0;
    color: #727272;
    display: block;
    /*회원가입 lable*/
  }

  .all_agree {
    width: 100%;
    padding: 0 0 10px 0;
    border-bottom: 2px solid #D2D4D6;
    font-size: 1.12em;
    font-weight: 600;
  }

  .label_chk input {
    margin-right: 5px;
  }

  .label_chk span.required {
    color: #ff0000;
  }

  .contentsopen {
    color: #999;
    margin-left: 5px;
    display: inline-block;
    margin-top: 1px;
    vertical-align: middle;
    cursor: pointer;
  }

  .btn_join_go {
    margin: 0 0 10px 0;

    a,
    button {
      display: block;
      background: #5d5757;
      border: 1px solid #5d5757;
      color: #fff;
      line-height: 46px;
      height: 46px;
      text-align: center;
      border-radius: 4px;

      i {
        margin-right: 6px;
      }

      &.naver {
        background: #3eaf0e;
        width: 100%;
      }

      &.facebook {
        background: #3d62b3;
        width: 100%;
      }

      &.kakao {
        background: #f5e006;
        color: #231f20;
        width: 100%;
      }

      &.google {
        background: #ea4335;
        width: 100%;
      }
    }
  }
}

.react-datepicker__day.react-datepicker__day--outside-month {
  color: gray;
}

.page-member-login {

  .header,
  .mobileUserMenu,
  .mobileNav,
  .quick,
  .footer,
  .intro__footer {
    display: none;
  }

  .content {
    width: 100%;
    max-width: 450px;
    margin: 0 auto;
    position: relative;
    top: calc(50% - 240px);

    .conts_body {
      text-align: left;
      margin-left: 0;
      max-width: none;
      padding: 0 3rem;

      .logo_link {
        margin-top: 20px;
        position: absolute;
        margin-left: 20px;

        img {
          display: inline-block;
          height: 5vh;
          width: auto;
          top: 0;
        }
      }

      .sub_title {
        width: 605px;
        height: calc(100vh - 0px);
        float: left;
        background: lightgray;
        padding: 3rem;

        .left_container {
          top: calc(50% - 280px);
          ;
          width: 100%;
          margin: 0;
          position: relative;

          .mainBoard {
            margin-top: 0;

            .col {
              width: 100%;

              &.col--right {
                display: none;
              }
            }
          }

          .link_container {
            margin: 15px 10px 0 10px;
            padding: 17px 0px 17px 10px;
            background-color: rgba(255, 255, 255, 0.9);

            a {
              border-radius: 3px;
              width: 45%;
              display: inline-block;
              margin: 0 2%;
              margin-bottom: 5px;
              padding-left: 30px;

              span {
                vertical-align: middle;
              }
            }
          }
        }

      }

      .login-main {

        //float: right;
        //width: calc(100% - 605px);
        //height: calc(100vh - 120px);
        //padding: 3rem;
        //background-color: #fff;
        .form-wrapper {
          width: 100%;
          max-width: 450px;
          margin: 0 auto;
          position: relative;
          top: calc(50% - 320px);
          min-width:280px;
        }

        h2 {
          margin-top: 25px;
          margin-bottom: 25px !important;
          font-size: 2.5rem;
          font-weight: 400;
          text-align: center;
          color: #000;
        }

        .userlogtitle {
          //font-size: 1.063rem;
          font-size: 0.93rem;
          margin-bottom: 35px;
          text-align: center;
        }

        .sub_title_userlog {
          margin-top: 35px;
          margin-bottom: 15px;
          font-weight: 500;
          font-size: 1.063rem;
        }

        .login_wrap {
          label {
            font-size: .875rem;
            color: #67666e;
            margin-bottom: 0;
            display: block;
            line-height: 17px !important;
          }

          input {
            display: block;
            width: 100%;
            outline: 0 !important;
            box-shadow: none !important;
            height: 50px;
            padding: 6px 13px;
            font-size: 14px;
            line-height: 1.42857143;
            margin-bottom: 5px;
            border: 1px solid #ccc;
            margin-top: 6px;
            font-family: auto;
          }

          .btn_submit_wrap {
            position: relative;
            margin: 1rem 0;

            .btn_submit {
              margin-left: 0px;
              height: 50px;
              padding-left: 2.5rem;
              padding-right: 2.5rem;
              box-shadow: 0px 4px 16px 0px rgba(88, 103, 221, 0.15);
              background-color: #272b37;
              border-color: #272b37;
              color: #fff;
              outline: 0;
              //position: relative;
              width: 100%;
              margin-bottom: 10px;
            }
          }

          .members {
            text-align: right;
            word-spacing: 5px;
            color: #000 !important;
            //border-bottom: 1px solid #dddddd;
            padding-top: 15px;
            padding-bottom: 60px;
          }
        }

      }

      @media (max-width: 1024px) {
        .sub_title {
          float: left;
          padding: 2rem;
          width: 100%;
          height: auto;

          .mainBoard {
            .top {
              button {
                font-size: 1.2rem;
              }
            }
          }
        }

        //.login-main{
        //  width:100%;
        //  padding-top: 6rem;
        //  height: auto;
        //}
      }

      @media (max-width: 768px) {
        .sub_title {
          float: left;
          padding: 2rem;
          width: 100%;
          height: auto;

          .mainBoard {
            .top {
              button {
                font-size: 1.2rem;
              }
            }
          }
        }

        //.login-main{
        //  width:100%;
        //  padding-top: 6rem;
        //  height: auto;
        //}
      }

      @media (max-width: 375px) {
        .link_container {
          a {
            padding-left: 12px;
          }
        }
      }

      .sns_sub_title_userlog {
        margin-bottom: 15px;
        margin-top: 40px;
        font-size: 1.063rem;
        font-weight: 500;
      }

      .sns_log_wrap {
        margin-left: -5px;
        margin-right: -5px;

        .sns_sub_wrap {
          width: 25%;
          display: inline-block;
          padding: 5px 5px 0px;

          .sns_btn {
            cursor: pointer;
            width: 100%;
            margin: 0px;

            .login-sns-title {
              margin-top: 10px;
            }
          }
        }
      }
    }
  }
}

// 아이디.비밀번호 찾기 페이지 //
.page-member-find-password {

  .header,
  .footer,
  .mobileUserMenu,
  .mobileNav,
  .quick,
  .intro__footer {
    display: none;
  }

  .conts_body {
    min-height: 140px;
    padding-bottom: 50px;
  }

  .page_title {
    margin-bottom: 35px;
  }

  h2.page_title {
    font-size: 36px;
    font-weight: bold;
    margin: 0 0 35px 0;
    text-align: center;
  }

  .form_div {
    text-align: center;
    margin-bottom: 30px;

    .find_id_form {
      width: 335px;
      margin: auto;
      border: 3px solid #eeeeee;

      .title {
        font-size: 15px;
        color: #242424;
        background: #eeeeee;
        height: 36px;
        text-align: left;
        padding-left: 15px;
        padding-top: 11px;
        font-weight: 500;
        margin-bottom: 15px;
      }

      .fm_cont {
        padding: 0 15px;

        input {
          font-size: 15px;
          height: 40px;
          padding-left: 11px;
        }
      }
    }
  }

  input {
    &.frm_100 {
      width: 100%;
    }

    &.frm_ipt {
      border: 1px solid #ccc;
      background: #fff;
      color: #000;
      vertical-align: middle;
      border-radius: 0px;
      padding: 5px;
      margin-bottom: 10px;
      height: 35px;
      line-height: 35px;
    }
  }

  .form_div .find_id_form .info {
    font-size: 13px;
    color: #717171;
    margin: 15px;
    text-align: left;
  }

  .btn_submit_wrap {
    padding: 0px 15px;
    text-align: center;
    margin-bottom: 10px;

    .btn_submit {
      background: #272b37;
    }

    button.btn_submit {
      display: block;
      width: 100%;
      height: 46px;
      line-height: 46px;
      text-align: center;
      color: #fff;
      outline: none;
      border: none;
      border-radius: 0;
    }
  }

  .form_div .find_pass_form {
    width: 335px;
    margin: auto;
    border: 3px solid #eeeeee;

    .title {
      font-size: 15px;
      color: #242424;
      background: #eeeeee;
      height: 36px;
      text-align: left;
      padding-left: 15px;
      padding-top: 11px;
      font-weight: 500;
      margin-bottom: 15px;
    }

    .fm_cont {
      padding: 0 15px;

      input {
        font-size: 15px;
        height: 40px;
        padding-left: 11px;
      }
    }

    .info {
      font-size: 13px;
      color: #717171;
      margin: 15px;
      text-align: left;
    }
  }

  .link_container {
    margin: 0 auto;
    margin-top: 20px;
    width: 335px;

    .find_page_login {
      width: 50%;
      text-align: left;
      color: #272b37;
      font-size: 15px;
      display: inline-block;
    }

    .find_page_regist {
      width: 50%;
      text-align: right;
      color: #272b37;
      font-size: 15px;
      display: inline-block;
    }
  }
}

// 아이디.비밀번호 찾기 스타일 끝 //

//비밀번호 변경 페이지 //
.page-member-reset-password {
  .form-control {
    font-family: auto;
  }

  .header,
  .intro__footer,
  .mobileUserMenu,
  .mobileNav,
  .quick {
    display: none;
  }

  .conts_body {
    padding-right: 10px;
    padding-left: 10px;
    width: 100%;
    max-width: 420px;
    margin: 0 auto;
    position: relative;
    top: calc(50% - 240px);
    padding-top: 160px;
    min-height: calc(100vh - 210px);
  }

  .page_title {
    font-size: 36px;
    font-weight: bold;
    margin: 0 0 35px 0;
    text-align: center;
    margin-bottom: 35px;
  }

  .btn_submit_wrap {
    text-align: center;
    margin-bottom: 10px;

    button.btn_submit {
      background: #272b37;
      display: block;
      width: 100%;
      height: 46px;
      line-height: 46px;
      text-align: center;
      color: #fff;
      outline: none;
      border: none;
      border-radius: 0;
    }
  }
}

//비밀번호 변경 페이지 끝 //

//마이페이지 공간.예약 확인하기 스타일 //
.page-member-myresource .myProgram .board__list-body .tr {
  text-align: center;
}

.page-member-myresource {
  .sub__title {
    margin-bottom: 1.25rem;
  }

  .board__header {
    margin-top: 2.5rem;
  }
}

.page-member-myresource .board__list .board__list-body .tr {
  .schedule_name {
    width: calc(56% - 190px);
    min-width: inherit;
    padding: 0px 15px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;

    @media screen and (max-width: 1024px) {
      width: 100%;
      min-width: 100%;
      padding: 5px 10px 5px 0;
    }
  }

  .schedule_date {
    width: inherit;
    min-width: 200px;
    text-align: center;
  }

  .schedule_state {
    width: calc(35% - 190px);
    min-width: inherit;
    text-align: center;

    @media screen and (max-width: 1024px) {
      width: 100%;
    }
  }

  .schedule_username {
    width: inherit;
    min-width: 100px;
    text-align: center;
  }

  .schedule_file {
    width: inherit;
    min-width: 100px;
    text-align: center;

    button {
      background: #2b2f3b;
      height: 40px;
      color: #fff;
      border-radius: 3px;
      width: 80px;
      //margin-left: 10px;
    }
  }
}

//마이페이지 공간.예약 확인하기 끝//

//마이페이지 프로그램 신청 확인 //

.react-datepicker {
  select {
    background: none;
    border: none
  }

  .left_button {
    margin-right: 15px;
  }

  .right_button {
    margin-left: 15px;
  }
}

.page-member-changeinfo {
  .intro__footer {
    display: block;
  }

  .content {
    .sub__content {
      max-width: 700px;
      margin: 0 auto;

      @media screen and (max-width : 768px) {
        width: 90%;

      }

      .sub__title {
        margin-bottom: 1rem;
      }

      .tab-con {
        width: 100%;

        .tab {
          border: 1px solid;
          width: 50%;
          height: 50px;
        }
      }
    }

    .form-group {
      .form-control[readonly] {
        background: white;
      }
    }

    .form-group .text-danger {
      display: block;
    }

    .form-group .jd_input {
      display: inline-block;
      height: calc(1.5em + .75rem + 2px);
      margin-bottom: 10px;
      padding: .375rem .75rem;
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.5;
      color: #495057;
      background-color: #fff;
      background-clip: padding-box;
      border: 1px solid #ced4da;
      border-radius: .25rem;
      transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    }

    .form-group .btn_id_ch {
      box-sizing: border-box;
      display: inline-block;
      text-align: center;
      min-width: 100px;
      height: calc(1.5em + .75rem + 2px);
      line-height: 35px;
      padding: 0 5px;
      text-align: center;
      background: #272b37;
      color: #fff;
      outline: none;
      border: none;
      border-radius: .25rem;
      vertical-align: top;
      margin-left: 10px;
    }

    input[type=radio] {
      vertical-align: middle;
    }

    .btn_submit_wrap {
      margin-bottom: 100px;
    }

    .btn_submit_wrap button.btn_submit {
      display: block;
      width: 100%;
      max-width: 160px;
      height: 40px;
      line-height: 40px;
      text-align: center;
      background: #00863C;
      color: #fff;
      outline: none;
      border: none;
      border-radius: 4px;
    }

    .react-datepicker-wrapper {
      width: 100%;
    }

    .radiolabel {
      margin-right: 5px;

      .ipradio {
        margin-right: 5px;
      }
    }

    .page_title {
      font-size: 36px;
      font-weight: bold;
      margin: 0 0 35px 0;
      text-align: center;
    }
  }

  .col-form-label {
    display: block;
  }

  .zip {
    .btn_inline {
      box-sizing: border-box;
      display: inline-block;
      text-align: center;
      min-width: 100px;
      height: calc(1.5em + .75rem + 2px);
      line-height: 35px;
      padding: 0 5px;
      text-align: center;
      background: #272b37;
      color: #fff;
      outline: none;
      border: none;
      border-radius: .25rem;
      vertical-align: top;
      margin-left: 10px;
    }

    .frm_ipt {
      display: inline-block;
      height: calc(1.5em + .75rem + 2px);
      margin-bottom: 10px;
      padding: .375rem .75rem;
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.5;
      color: #495057;
      background-color: #fff;
      background-clip: padding-box;
      border: 1px solid #ced4da;
      border-radius: .25rem;
      transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;

      &.frm_100 {
        width: 100%;
      }
    }
  }

  .join_info {
    margin: 0 0 20px 0;
    /*color: #aaa;*/
    color: #747474;
  }

  .join_desc {
    margin: 0 0 10px 0;
    color: #727272;
    display: block;
    /*회원가입 lable*/
  }

  .all_agree {
    width: 100%;
    padding: 0 0 10px 0;
    border-bottom: 1px solid #dcdcdc;
    font-size: 1.12em;
    font-weight: 600;
  }

  .label_chk input {
    margin-right: 5px;
  }

  .label_chk span.required {
    color: #ff0000;
  }
}

//회원탈퇴
.page-member-withdrawal {
  .intro__footer {
    display: block;
  }

  .content {
    .sub__content {
      max-width: 620px;
      margin: 0 auto;

      .sub__title {
        margin-bottom: 1rem;
      }

      .tab-con {
        width: 100%;

        .tab {
          border: 1px solid;
          width: 50%;
          height: 50px;
        }
      }
    }

    .form-group {
      .form-control[readonly] {
        background: rgb(221, 218, 218);
        outline: none;

      }

      .form-control[readonly]:focus {
        outline: none;

      }

      //체크
      .sub__title {
        margin-top: 5rem;

        margin-bottom: 1rem;
        padding-bottom: 15px;
        line-height: 1.3rem;
        border-bottom: 1px solid #e5e5e5;
        text-align: left;

        @media screen and (max-width: 768px) {
          margin-bottom: 1em;
        }

        span {
          font-size: 1rem;
          font-weight: 700;
        }
      }
    }

    .form-group .text-danger {
      display: block;
    }

    .form-group .jd_input {
      display: inline-block;
      height: calc(1.5em + .75rem + 2px);
      margin-bottom: 10px;
      padding: .375rem .75rem;
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.5;
      color: #495057;
      background-color: #fff;
      background-clip: padding-box;
      border: 1px solid #ced4da;
      border-radius: .25rem;
      transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    }

    .form-group .btn_id_ch {
      box-sizing: border-box;
      display: inline-block;
      text-align: center;
      min-width: 100px;
      height: calc(1.5em + .75rem + 2px);
      line-height: 35px;
      padding: 0 5px;
      text-align: center;
      background: #272b37;
      color: #fff;
      outline: none;
      border: none;
      border-radius: .25rem;
      vertical-align: top;
      margin-left: 10px;
    }

    input[type=radio] {
      vertical-align: middle;
    }

    .btn_submit_wrap {
      margin-top: 5rem;
      margin-bottom: 100px;
      display: flex;
      justify-content: center;
    }

    .btn_submit_wrap button.btn_submit {
      display: block;
      width: 50%;
      height: 46px;
      line-height: 46px;
      text-align: center;
      background: #00863C;
      color: #fff;
      outline: none;
      border: none;
      border-radius: 0;

    }

    .react-datepicker-wrapper {
      width: 100%;
    }

    .radiolabel {
      margin-right: 5px;

      .ipradio {
        margin-right: 5px;
      }
    }

    .page_title {
      font-size: 36px;
      font-weight: bold;
      margin: 0 0 35px 0;
      text-align: center;
    }
  }

  .col-form-label {
    display: block;
  }

  .zip {
    .btn_inline {
      box-sizing: border-box;
      display: inline-block;
      text-align: center;
      min-width: 100px;
      height: calc(1.5em + .75rem + 2px);
      line-height: 35px;
      padding: 0 5px;
      text-align: center;
      background: #272b37;
      color: #fff;
      outline: none;
      border: none;
      border-radius: .25rem;
      vertical-align: top;
      margin-left: 10px;
    }

    .frm_ipt {
      display: inline-block;
      height: calc(1.5em + .75rem + 2px);
      margin-bottom: 10px;
      padding: .375rem .75rem;
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.5;
      color: #495057;
      background-color: #fff;
      background-clip: padding-box;
      border: 1px solid #ced4da;
      border-radius: .25rem;
      transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;

      &.frm_100 {
        width: 100%;
      }
    }
  }

  .join_info {
    margin: 0 0 20px 0;
    /*color: #aaa;*/
    color: #747474;
  }

  .join_desc {
    margin: 0 0 10px 0;
    color: #727272;
    display: block;
    /*회원가입 lable*/
  }

  .all_agree {
    width: 100%;
    padding: 0 0 10px 0;
    border-bottom: 1px solid #dcdcdc;
    font-size: 1.12em;
    font-weight: 600;
  }

  .label_chk input {
    margin-right: 5px;
  }

  .label_chk span.required {
    color: #ff0000;
  }

  .guide__text {
    color: #616161;
    margin-bottom: 0.5rem;
    font-weight: 400;
  }
}



////////////// 강좌만족도 & 워크숍 만족도 페이지//////////////
.page-mycontent-workshopsurvey,
.page-mycontent-studysurvey,
.page-member-myconsultingsurvey {
  .content .sub__content .sub__title {
    margin-bottom: 1rem;
  }

  .sub__title2 {
    margin-bottom: 4rem;
    padding-bottom: 15px;
    line-height: 1.3rem;
    border-bottom: 1px solid #e5e5e5;
    text-align: left;

    @media screen and (max-width: 768px) {
      margin-bottom: 1em;
    }

    span {
      font-size: 1.4rem;
      font-weight: 600;
      position: unset;
    }
  }

  .myContent_button {
    position: absolute;
    right: 359px;
    top: 352px;

    .left_button {
      padding: 10px 50px;
      float: left;
      margin-right: 10px;
      font-size: 1.2rem;
      background-color: #ececec;
    }

    .right_button {
      padding: 10px 50px;
      float: left;
      font-size: 1.2rem;
      background-color: #6eace5;
      color: #fff;
      font-weight: 700;
    }

  }

  .myContent_button:after {
    display: block;
    visibility: hidden;
    clear: both;
    content: '';
  }



  .sub_survey_title {
    width: 100%;
    height: 20%;
    background-color: #f8f8f8;
    padding: 30px;
    border-radius: 5px;

    h1 {
      font-size: 1.4rem;
      font-weight: 600;
      position: unset;
      margin-bottom: 10px;
    }
  }

  .sub_survey_explain {
    border: 1px solid #bedaf4;
    border-radius: 5px;
    padding: 20px;
    margin: 20px 0 40px 0;
  }

  .survey_question {
    padding: 0 30px 15px 30px;
    border: 1px solid #e5e5e5;
    border-radius: 5px;
    margin: 20px 0 20px 0;

    & h1 {
      margin-top: 0.3rem;
      margin-bottom: 2.3rem;
      line-height: 4.3rem;
      border-bottom: 0.5px solid #e5e5e5;
      font-size: 1.2rem;
      font-weight: 600;
      position: unset;
      letter-spacing: -1.5px;
    }
  }

  //radio button style
  div.radio-wrap {
    display: inline-flex;
    align-items: center;
  }

  input[type='radio'],
  input[type='radio']:checked {
    appearance: none;
    width: 0.9rem;
    height: 0.9rem;
    border-radius: 100%;
    //margin-right: 0.1rem;
  }

  input[type='radio'] {
    background-color: #ececec;
    border: 7px solid #ececec;
    width: 30px;
    height: 30px;
    display: inline-block;
    vertical-align: middle;
    //margin-bottom: 15px;
    margin-right: 15px;

    &:checked {
      border: 7px solid #92c1ec;
      width: 30px;
      height: 30px;
    }
  }

  label {
    vertical-align: middle;
    font-size: 17px;
    padding: 0px 0px 15px 10px;
    font-weight: 500;
  }

  input[type='text'] {
    width: 100%;
    border: 1px solid #e5e5e5;
    height: 40px;
  }

  button {
    &.survey_btn {
      background-color: #489659;
      color: white;
      width: 170px;
      padding: 15px 28px;
      border-radius: 50px;
      font-size: 17px;
      font-weight: 500;
      float: right;
    }
  }

  a {
    &.survey_btn {
      background-color: #6eace5;
      color: white;
      width: 170px;
      padding: 15px 28px;
      border-radius: 50px;
      font-size: 17px;
      font-weight: 500;
      float: right;
    }
  }

}


/////////////MyCertificate 이수증 페이지
.page-mycontent-mycertificate {
  header {
    display: none;
  }

  .content {
    padding-top: 16px;
  }

  .quick {
    display: none;
  }

  .sub {
    display: block;
    background-color: #f8f8fa;
  }

  .wrap {
    max-width: 100%;
    display: block;
    padding: 0;
  }

  .certi_head_div {
    text-align: center;

    .certi_head {
      padding-top: 17px;
    }
  }

  .certi_sub_wrap {
    background-color: #363636;

    .certi_sub_one {
      width: 90%;
      height: 20%;
      background-color: #363636;
      color: white;
      max-width: 963px;
      margin: 0 auto;
      padding: 30px 0;



      .sub_h1 {
        display: block;
        font-size: 1.2rem;
        font-weight: 600;
      }

      .sub_h2 {
        display: inline-block;
        margin-top: 13px;
        font-size: 1.1rem;
      }
    }
  }

  .certi_sub_two_wrap {
    background-color: #f2f2f6;

    .certi_sub_two {
      width: 90%;
      height: 20%;
      margin: 0 auto;
      max-width: 730px;
      background-color: #f2f2f6;
      font-size: 1.3rem;
      font-weight: 600;
      padding: 30px 0;
      //padding: 30px 350px 75px 350px;
    }
  }


  .btn_div {
    width: 99%;
    margin: 5px;
    text-align: center;

    &:after {
      display: block;
      visibility: hidden;
      clear: both;
      content: '';
    }

    .print_btn {
      border: 1px solid #686868;
      padding: 10px 30px;
      color: white;
      border-radius: 3px;
      font-weight: 600;
      vertical-align: middle;
      margin-right: 5px;

      img {
        vertical-align: middle;
        display: inline-block;
        margin-right: 5px;
      }

    }

    .check_btn {
      border: 1px solid #686868;
      padding: 10px 30px;
      color: white;
      margin-left: 20px;
      border-radius: 3px;
      font-weight: 600;
      float: right;
    }
  }

  .certi_sub_three {
    background-color: #f8f8fa;
    padding: 5px 0;
    overflow-x: auto;


    .certificate {
      margin: 0 auto;
      background: white;
      padding: 20px;
      letter-spacing: 1px !important;
      width: 660px;

      .certi_badge {
        position: absolute;
        right: calc(34%);
        top: calc(42vh);
      }

      .certificate_back {
        background: url('#{$image-path}/certi_back_logo.png') no-repeat;
        background-position-x: 50%;
        background-position-y: 48%;
      }

      .certificate_inner {
        border: 3px solid #e0e0e0;
        padding: 55px 55px 0 55px;

        .print_one {
          margin-bottom: 70px;
        }

        .print_title {
          font-size: 48px;
          font-weight: 600;
          margin-bottom: 58px;
          letter-spacing: 15px;
          text-align: center;
          font-family: "궁서" !important;
        }

        .print_two {
          margin-bottom: 72px;

          p {
            margin-bottom: 7px;
            font-size: 1.1rem;
            line-height: 2rem;
            font-family: "궁서" !important;

            .print_blocks {
              width: 100%;
              font-size: 1.1rem;

              &:after {
                display: block;
                visibility: hidden;
                clear: both;
                content: '';
              }

              .leftBox {
                float: left;
                width: 24%;
              }

              .rightBox {
                position: relative;
                float: left;
                width: 76%;
                padding-left: 21px;
                text-align: left;
                text-align-last: left;

                &:before {
                  position: absolute;
                  content: ":";
                  left: 5px;
                }
              }
            }

            div {
              display: inline-block;
              width: 120px;
              text-align: justify;
              text-align-last: justify;
              //해당 단어에 띄어쓰기가 있어야 양쪽정렬이 됨
              vertical-align: top;
              font-family: "궁서" !important;

              &.print_box {
                width: 100%;
              }

              &.print_center {
                width: auto;
                margin: 0 10px;
              }

              &.print_data {
                width: calc(100% - 150px);
                text-align: left;
                text-align-last: left;
              }
            }
          }
        }

        .print_three {
          font-size: 30px;
          font-weight: 600;
          word-break: break-all;
          width: 100%;
          line-height: 2.8rem;
          //letter-spacing: -0.1rem;
          margin: auto;
          text-align: center;
          font-family: "궁서" !important;
        }

        .print_day {
          margin: 40px auto;
          text-align: center;

          //margin: 44px 0 116px 0;
          p {
            //float: right;
            font-size: 25px;
            font-weight: 600;
            //letter-spacing: -0.1rem;
            font-family: "궁서" !important;
          }
        }

        .print_num {
          font-family: "궁서" !important;
        }

        .print_footer {
          margin-top: 80px;
          display: block;
          min-height: 160px;
          position: relative;

          h1 {
            font-size: 29px;
            font-weight: 600;
            letter-spacing: -0.2rem;
            margin-left: 5px;
            z-index: 1;
            position: relative;
            text-align: center;
            font-family: "궁서" !important;
          }

          img {
            position: absolute;
            //position: relative;
            left: calc(82%);
            // bottom: 78px;
            bottom: 85px;
            width: 120px;
            z-index: 0;
          }
        }
      }
    }
  }

  .intro__footer {
    .wrap {
      width: 100%;
      max-width: 1202px;
      margin: 0 auto;
      padding: 0 10px;
    }
  }

  // 특정영역 print
  @media print {

    #notPrint,
    .notPrint,
    .intro__footer {
      display: none;
    }

    .sub {
      padding-bottom: 0;
      margin: 0;
    }

    @page {
      size: A3;
      height: 335mm;
      width: 235mm;
      margin: 0;
      padding: 0;
    }

    zoom: 116%;

    * {
      box-sizing: border-box;
      -moz-box-sizing: border-box;
      -webkit-print-color-adjust: exact !important;
      color-adjust: exact !important;
    }

    #print_area {
      /*transform: scale(1.2);*/
      margin: 0;
      padding: 0;
      size: A3;
      height: 335mm;
      width: 238mm;
      page-break-after: avoid !important;
      page-break-inside: avoid !important;
      page-break-before: avoid !important;
      font-family: 'ChosunGs' !important;
      overflow: hidden;

      .certificate_back {
        height: 100%;
        width: 100%;
        background: url('#{$image-path}/certi_back_logo.png') no-repeat;
        background-position-x: 50%;
        background-position-y: 48%;
        background-size: 70%;

      }

      .certificate_inner {
        height: 100%;

      }

      .print_one {
        margin-bottom: 88px;
        font-family: "궁서" !important;
      }

      .print_two {
        font-size: 28px;
        margin-bottom: 120px;

        p {
          margin: 33px 0;
          font-size: 28px;
          line-height: 50px;
          font-family: "궁서" !important;

          .print_blocks {
            width: 100%;

            &:after {
              display: block;
              visibility: hidden;
              clear: both;
              content: '';
            }

            .leftBox {
              float: left;
              width: 25.5%;
              min-width: 200px;
            }

            .rightBox {
              position: relative;
              float: left;
              width: 70%;
              padding-left: 32px;
              text-align: left;
              text-align-last: left;


              &.courseTitle {
                word-break: keep-all;
                line-height: 40px;
              }


              &:before {
                position: absolute;
                content: ":";
                left: 10px;
              }
            }
          }

          div {
            font-size: 28px;
            width: 200px;
            font-family: "궁서" !important;
            vertical-align: top;

            &.print_box {
              width: 100%;
            }

            &.print_center {
              width: auto;
              margin: 0 10px;
            }

            &.print_data {
              width: calc(100% - 250px);
            }
          }
        }
      }

      .print_three {
        font-size: 42px;
        font-weight: 400;
        line-height: 58px;
        font-family: "궁서" !important;
      }

      .print_day {
        margin-top: 70px;
        margin-bottom: 90px;

        p {
          font-size: 30px;
          font-family: "궁서" !important;
        }
      }

      .print_footer {
        margin-top: 0px;
        min-height: 160px;
        position: relative;

        h1 {
          font-size: 45px;
          font-family: "궁서" !important;
        }

        img {
          width: 140px;
          bottom: 70px;
          position: absolute;
        }
      }

      .print_num {
        font-family: "궁서" !important;
      }

      .print_title {
        font-size: 85px;
        font-weight: 700;
        margin-bottom: 100px;
        letter-spacing: 15px;
        text-align: center;
        font-family: "궁서" !important;
      }
    }
  }
}


.member_popup_survey {
  background-color: white;
  padding: 30px;

}

.member_popup_survey_table {
  border: 1px solid gainsboro;
  word-break: keep-all;
  border-radius: 15px;
  overflow-x: hidden;
  max-height: 600px;

  thead {
    background-color: fafafa;
  }

  tr {
    margin-bottom: 10px;
    border-bottom: 1px solid #fafafa;
  }
}

.ptable {
  td {
    ul {
      .sysli {
        margin-bottom: 5px;
        display: inline;
        padding-right: 4px;
      }
    }
  }

  //border-top: 2px solid #333333;
  border: 1px solid gainsboro;
  word-break: keep-all;
  overflow-x: hidden;


  table {
    width: 100%;
    position: relative;
    // border-collapse: unset;
    //min-width: 1550px;
  }

  td {
    font-size: 1rem;
    border-top: 1px solid #ecf0f1;
    //padding: 10px 3px;
    padding: 10px 20px;
    line-height: 1.4em;
    color: #333333;
    letter-spacing: -0.025em;

    @media screen and (max-width: 1023px) {
      padding: 0;
      padding-right: 10px;
      height: auto;

      &:last-child {
        border-bottom: 1px solid #ecf0f1;
      }
    }

    a {
      color: #888;
    }

    .default {
      background: transparent;
      color: #888;
    }

  }



  tr:nth-child(odd) {
    td {

      @media screen and (max-width: 1023px) {
        background: #fff;
      }
    }
  }

  tr {
    &.parent {
      cursor: pointer;

      td {
        background: #f7f8fc;
      }

      &:hover {
        td {
          background: #eee;
        }
      }
    }

    &.child {
      td {
        background: #fff;
      }
    }

    td {
      &.empty {
        &:before {
          content: none;
        }

        color: #343a40;
        font-weight: bold;
        text-align: center;
        padding: 3rem;
      }
    }
  }

  .bt {
    tr {
      &.parent {
        td {
          background: #fff;
        }

        &:hover {
          td {
            background: #eee;
          }
        }
      }
    }
  }

  th {
    background: #fafafa;
    height: 40px;
    // width: 100%;
    //padding: 0 5px;
    padding: 10px 20px;

    @media screen and (max-width: 1023px) {
      &:last-child {
        border-bottom: 1px solid #fafafa;
      }
    }
  }

  .check-td {
    text-align: center;

    @media screen and (max-width: 1023px) {
      height: 0;

      &:before {
        display: none;
      }

      .checkbox {
        position: absolute;
        left: 5px;
        top: 10px;
      }
    }
  }

  .sysbtn {
    color: #0079C2 !important;
    border-color: #0079C2 !important;
  }

  .label {
    color: #fff;
    padding: 0 10px;
    height: 26px;
    line-height: 26px;
    min-width: 70px;
    display: inline-block;
    text-align: center;
    border-radius: 2em;
    font-size: 0.9rem;

    &.running,
    &.Running,
    &.Completed {
      //background: #1aa874;
      background: #0079C2;
    }

    &.WARNINGS,
    &.InProgress {
      background: #ffc107
    }

    &.stop,
    &.Pending,
    &.ERRORS,
    &.Failed,
    &.Deleting,
    &.Removed {
      background: #e24141;
    }

    @media screen and (max-width: 1023px) {
      font-size: 12px;
    }
  }
}


.survey__search__section {

  margin-bottom: 20px;

  button {
    margin-left: 5px;
    background-color: #489659;
    width: 50px;
    height: 30px;
    border-radius: 12px;
    color: white
  }
}

.survey__search____result__section {
  margin-top: 10px;
  font-weight: 700;

}

.survey__res__button {
  display: flex;
  justify-content: end;

  button {
    margin: 10px;
    padding: 10px 20px;
    color: white;
    background-color: #489659;
    border-radius: 12px;
  }
}


.popup-content {
  &.survey-member-pop-content {
    max-width: 1600px !important;

  }
}

.surveylist-overlay{
  background: rgba(1, 1, 1, 0.5);
  .popup-content {
    &.survey-member-pop-content {
      max-width: 650px !important;
      select{
        height: 30px;
        border-radius: 3px;
        width: 100px;
        margin-right: 5px;
        padding: 0 10px;
      }
    }
  }
}

// 나의 메타버스

.meta_list {
  display: flex;
  // flex-direction: column;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-top: 15px !important;
  gap: 18px;
}

.meta_wrap {
  width: 32%;
  margin: 0;
  margin-bottom: 30px;
  border-radius: 5px;
  border: 1px solid #e9e9e9;
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding: 13px;
  box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.07);

  @media screen and(max-width:1024px) {
    width: 49%;
  }

  @media screen and(max-width:500px) {
    width: 100%;
  }



  .meta_img {
    img {
      width: 100%;
      // max-width: 250px;
      border-radius: 4px;
      height: 156px;
    }
  }

  .meta_content {
    margin: 15px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 3px;

    h2 {
      font-size: 18px;
      font-weight: 700;
      margin-bottom: 10px;
    }

    p {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      .nametag {
        color: #757575;
        width: 25%;
      }

      span {
        color: #333333;
      }
    }
  }



  .move_meta_page {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px 0 0 0;

    button {
      color: #fff;
      width: 100%;
      background-color: #489659;
      font-weight: 700;
      padding: 10px 20px;
      border-radius: 4px;
    }
  }
}

.nullCont {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;

  .contBox {
    img {
      max-width: 400px;
      min-width: 400px;
      display: block;
      margin: 0 auto;
      margin-top: 40px;

      @media screen and(max-width:500px) {
        max-width: 200px;
      }

    }

    .null__list {
      margin-top: 35px !important;
      text-align: center;
      font-size: 1.8rem;
      font-weight: 500;

      @media screen and(max-width:500px) {
        font-size: 1.3rem;
        margin-top: 15px !important;
      }


      .high {
        color: #00863C;
        font-size: 1.8rem;
        font-weight: 700;

        @media screen and(max-width:500px) {
          font-size: 1.3rem;
        }
      }
    }
  }
}


.change_password_btn {
  border-radius: 4px;
  padding: 10px;

  display: flex;
  text-align: center;
  background: #489659;
  color: #fff;
  outline: none;
  border: none;
}

.popup_content {
  &.change_pw {
    max-width: 600px;
    max-height: 500px;

  }
}

.form-group {
  &.change_info {

    margin-bottom: 1rem;

    >div {
      display: flex;
      justify-content: start;
      align-items: flex-start;
      flex-direction: column;

      >label {
        padding-right: 10px;
        width: 35%;

      }

      >input {
        margin-bottom: 0;
      }
    }


  }
}

.btn_submit_password {
  display: block;
  width: 100%;
  text-align: center;
  background: #489659;
  color: #fff;
  outline: none;
  border: none;
  border-radius: 4px;
  height: 46px;
  line-height: 46px;
}



.letterForm{
  padding-top:80px;

  @media screen and (max-width: 660px) {
        padding:80px 10px 20px 10px
  }

  .after{
    border: 1px solid #ddd;
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
    border-radius: 5px;
    padding: 30px;
    margin-bottom: 40px;

    .message{
      font-size: 1rem;
      text-align: center;
      margin-bottom:10px;
    }

    .subsTime{
      display: flex;
      align-items: center;
      justify-content: center;

      .timesTitle{
        font-size: 0.85rem;
        color:#1a9952;
        font-weight: 600;
      }

      .times{
        color:#333;
        font-size: 0.85rem;
        padding-left:15px;
      }
    }

  }

  .buttonsBox{
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .submitButtons{
    width:180px;
    height:50px;
    background-color: #1a9952;
    color:#fff;
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1rem;
    border-radius: 5px;
    cursor: pointer;

    &.deletes{
      background-color: #db0000;
    }
  }

  .watchList{
    width:195px;
    height:50px;
    background-color: #454545;
    color:#fff;
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1rem;
    border-radius: 5px;
    margin-left:10px;
    cursor: pointer;

    &.solo{
      margin: 0 auto;
      margin-top: 30px;
    }
  }

  .letterTitle{
    font-size: 1.6rem;
    text-align: left;
    font-weight: 600;
    margin-bottom:10px;
    color:#00863C;

    .grrens{
      color:#333;
      font-size: 1.6rem;
      font-weight: 600;
    }
  }

  .letterInfo{
    font-size: 1rem;
    text-align: left;
    margin-bottom:30px;
  }

  .customChecks{
    display: flex;
    align-items: center;
    justify-content: start;
    margin-bottom:50px;

    label{
      display: flex;
      align-items: center;
      justify-content: start;
  
      input{
        display: none;
      }
      

      span{
        display: block;
        width:20px;
        height:20px;
        border:1px solid #ddd;
        border-radius: 3px;
        cursor:pointer;
      }
      
      input[type="checkbox"]:checked + span{
        background: url('#{$image-path}/login/check.svg') #5EB292 no-repeat 3px center;
        border:1px solid #5EB292;
        width:20px;
        height:20px;
      }

      p {
        margin-left:8px;
        color:#1a9952;
        font-weight: 700;
      }
    }
  
  }


  .submiForm{
    width:100%;
    max-width: 600px;
    margin:0 auto;
    border-radius: 5px;
    margin-bottom:40px;


    .inputText{

      & ~ .inputText{
        margin-top:10px;
      }

      .leftTitle{
        width:66px;
        padding-top:10px;
        line-height: 1rem;
        margin-bottom: 8px;
      }

      .rightInput{
        width:100%;

        input {
          height:40px;
          font-size: 0.85rem;
          color:#575757;
          background-color: #ddd;
          padding:8px;
          border-radius: 5px;
          border: 1px solid rgb(73, 73, 73)161;
          width:100%;
        }

        .smallText{
          color:#FF7235;
          font-size: 0.8rem;
          font-weight: 400;
          margin-top:6px;
        }

      }
    }

   
    
  }
}

.orange{
  background-color: #ff6b18 !important;
  &:hover{
    background-color: #d55005 !important;
  }
}

.nots{
  background-color: #454545 !important;
  &:hover{
    background-color: #2b2b2b !important;
  }
}

.flexForms{
  display: flex;
  align-items: center;
  justify-content: center;


  .chart{

    border-radius: 10px;
    margin-bottom:15px;



    .chartinfo{
      margin-bottom:33px;

      .charTitle{
        color: #333;
        margin-bottom: 16px;
        border-bottom:1px solid #ddd;
        padding-bottom:4px;
      }

      .chartBody{
        font-size: 0.85rem;
        line-height: 1.2rem;
        color: #7d7d7d;
        font-weight: 300;
      }
    }


    .tableSet{
      .chartSetTitle{
        font-size: 0.85rem;
        color: #4e8507;
        margin-bottom: 6px;
        text-align: center;
      }

      .chartSetBody{
        font-size: 0.9rem;
        line-height: 1.3rem;
        color: #7d7d7d;
        font-weight: 300;
        border-bottom: 1px solid #ddd;
        padding-bottom: 16px;
        margin-bottom: 23px;
      }

      & > table {
        width: 100%;
        margin-bottom:20px;

        thead{
          tr {
            th {
              text-align: center;
              padding:10px;
              border-bottom:1px solid #ddd;
              border-top: 2px solid #333;
              font-size: 0.9rem;
              vertical-align: middle;
              background-color: #F6F6F6;

              & ~ th {
                border-left:1px solid #ddd;
              }

            }
          }
        }

        tbody{
          tr {
            td {
              text-align: center;
              padding:10px;
              font-weight: 400;
              border-bottom: 2px solid #333;
              font-size: 0.85rem;
              line-height: 1.2rem;
              vertical-align: middle;

              .greens_red{
                color:#FF7235;
                font-size: 0.85rem;
                font-weight: 600;
              }

              & ~ td {
                border-left:1px solid #ddd;
                
              }

            }
          }
        }
      }
    }

  }

  .leftImg{
    width:310px;

    img{
      width:100%;
    }
  }

  .letterForm{
    width:calc(100% - 310px);
    padding-left:65px;
  }

  @media screen and (max-width: 1024px) {
    flex-direction: column;

    .leftImg{
      padding-top:30px;
      width:230px;
  
      img{
        width:100%;
      }
    }

    .letterForm{
      padding:0 10px;
      padding-top:10px;
      width: 100%;

      .letterTitle{
        text-align: center;
      }

      .letterInfo{
        text-align: center;
        margin-bottom: 30px;
      }

      .customChecks{
        justify-content: center;
      }
    }
  }


  @media screen and (max-width: 450px) {
    .leftImg {
      width:150px;
    }

    .letterForm{
      .customChecks{
        margin-bottom:32px;
      }

      .submitButtons{
        width: 117px;
        height: 40px;
        font-size: 0.85rem;
      }

      .watchList{
        width: 130px;
        height: 40px;
        font-size: 0.85rem;
      }

    }
  }
}