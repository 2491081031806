// 회원가입 완료 페이지
// URL: /members/register/complete
.page-register-complete {
  .close {
    position: fixed;
    color: black;
    font-size: 2rem;
    top: 3%;
    right: 3%;
    line-height: 0;
    &::before {
      content: '\00D7';
      display: block;
    }
    .invisible-btn {
      position: absolute !important;
      width: 1px;
      height: 1px;
      overflow: hidden;
      clip: rect(1px 1px 1px 1px);
      clip: rect(1px, 1px, 1px, 1px);
      padding: 0;
      margin: -1px;
      border: 0;
    }
  }

  .conts {
    text-align: center;
    padding: 0;

    .conts_body {
      margin-left: 0;
      width: unset;
      max-width: none;
      //padding: 0 3rem 8.75rem 3rem;

      // 제목 영역
      .page_title {
        margin-bottom: 1.75rem;
        font-size: 2.5rem;
      }

      .join_result_info {
        font-weight: 400;
        font-size: 1.25rem;
        margin-bottom: 2.5rem;
      }
      // 제목 영역

      // 본문 영역 start
      .join_result {
        clear: both;
        max-width: 1050px;
        margin: 0 auto;

        // 상자 start
        .item-complete {
          height: 480px;
          //float: left;
          position: relative;

          // 이미지 크기 및 위치 start
          .pad-complete {
            padding-bottom: 75px;
          }

          .img {
            text-align: center;
            margin: 20px 0;

            img {
              max-width: 100%;
            }
          }
          // 이미지 크기 및 위치 end

          // 가입 메시지 영역 start
          .join_result_txt {
            line-height: 1.6;
            font-size: 1.063rem;
            margin-bottom: 2rem;
            color: #222222;
            font-weight: 300;
          }

          .join_result_txt_small {
            line-height: 1.6;
            font-size: 0.938rem;
            color: #666666;
            font-weight: 300;
          }

          .join_result_txt_center {
            text-align: left;
          }
          // 가입 메시지 영역 end

          // 버튼
          a.btn_submit_complete {
            font-size: 1.063rem;
            display: block;
            //width: calc(100% - 4.688rem);
            line-height: 60px;
            background: #272b37;
            border-color: #272b37;
            height: 60px;
            color: #fff;
            bottom: 1.5rem;
            //position: absolute;
          }
        }
        // 상자 end

        // 왼쪽 상자
        //.complete-left {
        //  padding-right: 4.688rem;
        //}

        // 오른쪽 상자
        .complete-box {
          padding-left: 4.688rem;
          border-left: 1px solid #dddddd;
        }
      }
      // 본문 영역 end
    }
    // conts_body end

    // 줄바꿈 숨김
    @media screen and (max-width: 1024px) {
      .no_break {
        display: none;
      }
    }

    // 박스 높이 조정
    @media screen and (max-width: 876px) {
      .conts_body {

        .join_result {

          .item-complete {
            height: 520px;
          }
        }
      }
    }

    // 모바일 반응형 start
    @media screen and (max-width: 768px) {
      .conts_body {
        //max-width: 24.125rem;
        padding: 0 1.125rem 8.75rem 1.125rem !important;

        .join_result {

          .item-complete {
            width: 100%;
            height: unset;
            margin: 0 0px 20px 0;
            float: unset;

            .join_result_txt {
              text-align: center;
            }

            .img.pad-complete {
              padding: 20px 30px;
            }

            a.btn_submit_complete {
              width: 100%;
              position: unset;
            }
          }

          .complete-left {
            padding-right: unset;
          }

          .complete-box {
            padding-left: unset;
            border-left: unset;
          }
        }
      }
    }

    @media screen and (max-width: 415px) {
      .conts_body {

        .join_result_info {
          //text-align: left;
        }
      }
    }
    // 모바일 반응형 end
  }
  // conts end
}