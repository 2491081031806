.intro_sub_text {
  font-size: 20px;
  margin-top:40px;
  margin-bottom: 35px;
  text-align: center;
  span {
    font-size: 20px;
    font-weight: bold;
  }
}

.intro_evaluate_box {
  margin-top: 15px;
  justify-content: space-between;
  flex-wrap: unset;
  border: none;


  @media screen and (max-width: 550px) {
    grid-template-columns: 100%;
  }

  .collage-item {

    // @media screen and (max-width: 768px) {
    //   width:calc(48% - 10px);
    //   margin-bottom:15px !important;
    // }

    // @media screen and (max-width: 420px) {
    //   width:calc(100% - 10px);
    // }
  }
  .intro_collage_item {
    border: 2px solid #eee;
    .intro_box_size {
      padding: 20px 0px 20px 0px !important;
      line-height: unset;
      border-bottom: unset;
      .intro_text {
        font-size: 15px;
        font-weight: bold;
      }
      .intro_bold {
        font-size: 20px;
        font-weight: bold;
        line-height: 20px
      }
      button {
        font-size: 13px;
        margin-top: 12px;
        color: #ffffff;
        background: gray;
        height: 23px;
        width: 80px;
        border-radius: 20px;
      }
      .arrows1{
        background: url('#{$image-path}/ban1.png') no-repeat center;
        height: 100px;
        margin-bottom:16px;
      }
      .arrows2{
        background: url('#{$image-path}/ban2.png') no-repeat center;
        height: 100px;
        margin-bottom:16px;
      }
      .arrows3{
        background: url('#{$image-path}/ban3.png') no-repeat center;
        height: 100px;
        margin-bottom:16px;
      }
      .arrows4{
        background: url('#{$image-path}/ban4.png') no-repeat center;
        height: 100px;
        margin-bottom:16px;
      }
      img {
        padding-top: 40px;
      }
    }
    &:hover {
      border: 2px solid #489659;
      .arrows1{
        background: url('#{$image-path}/ban1_on.png') no-repeat center;
        height: 100px;
      }
      .arrows2{
        background: url('#{$image-path}/ban2_on.png') no-repeat center;
        height: 100px;
      }
      .arrows3{
        background: url('#{$image-path}/ban3_on.png') no-repeat center;
        height: 100px;
      }
      .arrows4{
        background: url('#{$image-path}/ban4_on.png') no-repeat center;
        height: 100px;
      }
      button {
        background: #489659;
        color: #ffffff;
      }
      img {
        background: url('#{$image-path}/on_arrow.png') no-repeat left
        center;
      }
    }
  }

}

.start_button_container {
  margin-top: 45px;
  text-align: center;
  a {
    font-weight: bold;
    background: #489659;
    color: white;
    border-radius: 3px;
    padding: 10px 80px;
  }
}
.dc_way {
  @media screen and (max-width: 768px) {
    font-size: 10px;
  }

  background-color: #f8f9fa;
  vertical-align: middle;
  color: #333333;
  font-size: 17px;
  font-weight: bold;
  border: 1px solid #ddd;
  text-align: center;
  height:65px;
  word-break: keep-all;
  &.elemental {
    padding: 8px;
  }
}

.caution_p {
  color: gray;
  margin-top: 10px;
  font-size: 14px;
}

.table_answer1 {
  min-height: 330px !important;
}

.start_p {
  margin-top: -30px;
  line-height: 35px;
  padding: 15px;
  background-color: #f8f9fa;
  white-space: normal;
  span{
    white-space: break-spaces;
  }
}

.evaluate_table {
  border-top: 2px solid;
  width: 100%;
  .th_header {
    background-color: #f8f9fa !important;
    vertical-align: middle;
    color: #333333;
    font-size: 14px;
    font-weight: bold;
    border: 1px solid #ddd;
    text-align: center;
    width: 40px;
    padding: 0px 5px 0px 5px;
  }
  .th_number {
    padding: 12px 8px !important;
    width: 45px !important;
  }
  .th_title {
    padding: unset !important;
    width: 480px !important;
  }
  tbody {
    tr {
      td {

        @media screen and (max-width: 768px) {
          font-size: 10px;
        }

        background-color: #fff !important;
        vertical-align: middle;
        text-align: center;
        border: 1px solid #ddd;
        font-size: 16px;
        padding: 10px;
      }
      .question_list_title {
        text-align: unset !important;
      }
    }
  }

  &.elemental {
    margin-bottom: 140px;
    & td {
      @media screen and (max-width: 768px) {
        font-size: 10px;
      }

      background-color: #fff !important;
      vertical-align: middle;
      text-align: center;
      border: 1px solid #ddd;
      font-size: 16px;
      padding: 8px;
    }
  }
}


.result_content {
  padding-top: 55px;
  padding-left: unset !important;
  max-width: unset !important;

  .introduction__info {
    text-align: center;
  }
}

.sub_filter {
  display: flex;
  justify-content: center;
  border-bottom: 1px solid #dddddd;
  .tab {
    width: 33.33%;
    text-align: center;
    cursor: pointer;
    padding: 10px 20px;
    border-radius: 5px 5px 0 0;
    &.active {
      background: #489659;
      color: #ffffff;
    }
  }
}

.eval_que_container_one {
  @media screen and (max-width: 768px) {
    overflow-x: auto;
  }

  display: block;
}
.eval_que_container_two {
  display: none;
}


.dr_wrapper {
  @media screen and (max-width: 768px) {
    overflow-x: auto;
  }

  overflow: visible;
  position: relative;
  width: 100%;
  min-width: 260px;
  min-height: 600px;
  background-color: #fff;

  .title {
    color: #2c5c36;
    margin: 30px 0px 15px 0px;
    font-size: 20px;
  }
  .writer_input{
    background: #fafafb;
    border:1px solid #dddddd;
    margin-top:35px;
    padding:17px 10px;
    width: calc(100% - 230px);
    display: inline-block;

    @media screen and (max-width: 768px) {
      display: block;
      width: 100%;
    }

    p, input{
      display: inline-block;
      font-size:16px;
      color:#666666;

      &.input_title{
        @media screen and (max-width: 768px) {
          width: 20%;
          margin-right: 0;
        }

        margin-right: 15px;
      }
      &.bar{
        @media screen and (max-width: 768px) {
          display: none;
        }

        margin:0 20px;
        font-size: 12px;
      }
      &.name{
        @media screen and (max-width: 768px) {
          width: 80%;
          margin-bottom: 10px;
        }

        width: 80px;
        height: 28px;
      }
      &.organize{
        @media screen and (max-width: 768px) {
          width: 80%;
          margin-bottom: 10px;
        }

        width: 110px;
        height:28px;
      }
      &.email{
        @media screen and (max-width: 768px) {
          width: 80%;
        }

        width:200px;
        height:28px;
      }
    }
    input{
      border:1px solid #dddddd;
    }
  }
  .checkbox_input{
    background: #777;
    display: inline-block;
    padding:20px 10px;
    min-width: 230px;

    @media screen and (max-width: 768px) {
      min-width: 100%;
    }

    input{
      display: inline-block;
      margin-right: 10px;
    }
    p{
      display: inline-block;
      color: white;
    }
  }
}

.ta_div {
  .ta_start {
    display: table;
    margin: 0 0 0 auto;
    width: calc(-400px + 100%);

    #ta_low {
      font-size: 0.9rem;
      text-align: center;
      display: table-cell;
      width: 120px;
    }
  }

  .ta_row {
    display: table;
    margin: 0 auto;
    width: 100%;
    margin-bottom: 10px;

    .ta_question {
      display: table-cell;
      font-size: 16px;
      vertical-align: middle;
      width: 400px;
      text-align: left;
    }

    .ta_radio {
      display: table;
      width: 100%;
    }

    .form-item {
      display: table-cell;
      width: 120px;
      text-align: center !important;
      .control-label{
        display:none;
      }
    }
  }
}


// 역량진단평가 새로운 설문 추가
.eval_new_info{
  border: 1px solid;
  padding: 15px;
  margin-bottom: 30px;
  margin-top: 30px;
}
.eval_new_que {
  width: 100%;
  margin-bottom: 30px;

  select option:hover {
    background: #ff9500 -webkit-linear-gradient(bottom, #ff9500 0%, #ff9500 100%);
    color: #fff;
  }

  /*.eval_select_div:hover{
    background: #ff00ff;
    color: #fff;
  }*/

  & select {
    @media screen and (max-width: 768px) {
      width: 100%;
      margin-left: 0;
      height: 50px;
    }

    margin-left: 100px;
    width: 200px;
    height: 30px;
    padding-left: 25px;
    /*font-size: 18px;*/
    color: black;
    border: 1px solid #dddddd;
    border-radius: 3px;
    -webkit-appearance: none; /* 화살표 없애기 for chrome*/
    -moz-appearance: none; /* 화살표 없애기 for firefox*/
    appearance: none; /* 화살표 없애기 공통*/
    background: url('#{$image-path}/select-arrow.png') no-repeat 100% 50%;
    background-size: 50px;
    &select::-ms-expand {
      display: none; /* 화살표 없애기 for IE10, 11*/
    }
    /*&.is-focused {
      box-shadow: 0px 0px 10px #FFEB3B;
    }*/
  }
}




.eval_q_title + label, .eval_q_title + input{
  @media screen and (max-width: 768px) {
    margin-left: 0;
  }

  margin-left: 100px;
}

.eval_q_title {
  @media screen and (max-width: 768px) {
    display: block;
    margin-bottom: 15px;
  }

  width: 100%;
  margin-right: -1px;
  text-align: center;
  margin-bottom: 30px;
  font-size: 17px;
  display: inline-block;

  &.sub {
    @media screen and (max-width: 768px) {
      width: 100%;
    }

    width: 10%;
    padding: 13px;
    background-color: black;
    color: white;
    display: inline-block;
    margin-bottom: 0;
    &.six {
      padding-top: 38px;
      div{
        position: relative;
        top: -12px;
      }
    }
  }

  &.qu {
    @media screen and (max-width: 768px) {
      width: 100%;
    }

    margin-bottom: 0;
    padding: 12px 12px 12px 20px;
    background-color: white;
    color: black;
    width: calc(91% - 10px);
    font-weight: bold;
    border: 1px solid;
    margin-right: 0;
    text-align: left;

    p {
      width: 28%;
      font-weight: 500;
      display: inline-block;
    }

    input[type=text] {
      border-top: 1px;
      border-left: 1px;
      border-right: 1px;
    }
  }
}

.eval_input_text{
  border-top: 1px;
  border-left: 1px;
  border-right: 1px;
  width: 10%;
  text-align: center;
}

.eval_new_label {
  @media screen and (max-width: 768px) {
    display: block;
  }

  margin-right: 15px;
  font-size: 17px;
  input {
    margin-right: 5px;
  }
}
/*

.modal_csv_box {
  width: 100%;
  margin-top: 42px;
  border-top: 1px solid #bfbfbf;
  padding-top: 14px;
  .csv_button {
    margin-bottom: 10px;
    color: #015044;
    font-weight: 700;
    border: 1px solid #015044;
    border-radius: 5px;
    padding: 8px 25px;
    display: block;
  }
  .csv_button_area {
    position: absolute;
    padding: 8px 25px;
    display: block;
    top: 71px;
    background-color: #015044;
    color: #fff;
    font-weight: 700;
    border-radius: 5px;
  }
}
*/

// 211008 역량진단평가 제출 후 페이지 추가
.page-evaluate-fin{
  .sub__content.introduction_content{
    div {
      text-align: center;
    }
  }
  .content_top{
    font-size: 2rem;
    margin-top: 80px;
    margin-bottom: 40px;
  }
  .content_btn{
    text-align: center;
    button {
      margin: 0 10px;
      font-weight: 300;
    }
  }
  .btn_evalFin{
    border: 1px solid;
    background: #489659;
    color: #fff;
    padding: 10px 32px;
    font-size: 20px;
  }
  .content_bottom {
    text-align: center;
    margin: 45px 0;
  }
}

//211012 역량진단평가관리 버튼 추가
.eval_manage_container{
  text-align: center;
  margin-top: 40px;
  .eval_manage{
    background: #489659;
    color: #fff;
    border-radius: 5px;
    padding: 10px 15px;
  }
}

