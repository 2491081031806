.checkboxItem {

    .checkboxWrap {
        display: flex !important;
        align-items: center;
        cursor: pointer;
        position: relative;

        .hidden {
            position: absolute;
            width: 21px !important;
            height: 21px !important;
            outline: none;

            &:focus {
                border: 3px solid #5EB292;
                clip-path: inherit !important;
            }
        }

        input[type=checkbox] {
            position: absolute;
            width: 1px;
            height: 1px;
            margin: -1px;
            overflow: hidden;
            clip-path: polygon(0 0, 0 0, 0 0);
        }
    }

    .checkbox__icon {
        width: 20px;
        height: 20px;
        border: none;
        background-color: #fff;
        border-radius: 3px;
        flex: none;

    }

    input:checked+.checkbox__icon {
        background: url('#{$image-path}/login/check.svg') #5EB292 no-repeat 1px center;
        border-color: #137bbd;
    }

    .checkbox__description {
        font-size: 1rem;
        color: #fff;
        margin-left: 7px;

        @media screen and (min-width: 1600px) {
            letter-spacing: 0.5px;
        }

        @media screen and (max-width: 1600px) {
            font-size: 0.9rem;
        }

        @media screen and (max-width: 768px) {
            // 모바일 폰트크기조정 tori1116
            font-size: 1rem;
        }
    }

    input:checked+.checkbox__icon {
        background: url('#{$image-path}/login/check.svg') #5EB292 no-repeat 1px center;
        background-size: 18px;
        border-color: #137bbd;
    }
}

.checkbox_desc_info {
    margin: 5px 0 0;
    padding: 0 0 0 30px;

    @media (max-width: 768px) {
        padding: 0 0 0 25px;
    }
}

.custom-checkbox {
    display: inline-flex;
    align-items: center;
    margin-top: 0;
    min-width: 85px;
    min-height: 40px;
    height: auto;

    input {
        display: none !important;

        &:checked+span {
            background: url('#{$image-path}/login/check.svg') #5EB292 no-repeat 1px center;
            background-size: 18px;
            border-color: #5EB292;
        }
    }

    p {
        font-size: 1rem;
        color: #000;
    }

    span {
        width: 18px;
        height: 18px;
        flex: none;
        margin-right: 5px;
        border: 1px solid #ddd;
    }

    input[type='radio']+span {
        border-radius: 50%;
    }

    input[type='radio']:checked+span {
        background: none !important;
        border: 5px solid #5EB292;
    }
}