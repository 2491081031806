 .rcInputFile {
   margin-bottom: 10px;
 }

 .thumbSave_container button {
   background: #489659;
   margin-top: 10px;
   color: white;
   height: 40px;
   border-radius: 3px;
   width: 144px;
 }

 button.thumbBtn {
   background: #9da2b6;
   height: 31px;
   color: #fff;
   border-radius: 3px;
   width: 96px;
   margin: 5px 8px 8px 0px;
   font-size: 0.9rem;
   line-height: 0.9rem;
 }

 .cropRange {
   display: flex;
 }

 canvas {
   width: 100%;
   height : 100%;
 }

 .canvas {
   width : 220px;
   height: 165px;
   margin-left : 10px;
 }