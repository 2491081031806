.content--video {
  max-width: 100%;
  padding-bottom: 0;
}

.ContentListPopup {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  max-height: 95vh;
  overflow: auto;
  border: 1px solid #000;
  border-radius: 5px;
  background:#f0f0f0;
  width: 100%;
  max-width: 800px;
  z-index: 9999;
  padding:0 20px 20px 20px;
  .theme-dark & {
    background:#2c3038;
  }

  .ContentListPopup__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    h2 {
      line-height: 65px;
      font-size: 1.5rem;
      // @include theme('color', 'nav-block-name');
      font-weight: 600;
    }
    button {
      svg {
        // @include theme('color', 'nav-block-name');
        font-size: 1.25rem;
      }
    }
  }
}

.videoWrapper__header {
  width: 100%;
  border-bottom: 1px solid #ddd;
  // @include theme('border-color', 'border');
  position: relative;

  .header {
    width: 100%;
  }

  .header__left {
    padding-left: 30px;
    @media screen and (max-width: 768px) {
      padding: 0;
    }

    a {
      background:#555555;
      color:#fff;
      height: 35px;
      padding:0 10px;
      border-radius: 3px;
      text-decoration: none;
      line-height: 35px;
      display: block;

    @media screen and (max-width: 768px) {
        .theme-dark & {
          background: none;
        }
        .theme-light & {
          background: none;
        }
        text-align: left;
        padding:0;
      }
      span {
        font-size: 14px;
        padding-left: 1.5em;
        background: url('#{$image-path}/back-icon.png') no-repeat left center;
        display: block;
        @media screen and (max-width: 768px) {
          text-indent: -9999px;
          overflow: hidden;

          .theme-light & {
            background: url('#{$image-path}/back-icon2.png') no-repeat left center;
            background-size: auto 12px;
          }
        }
      }

      @media screen and (max-width: 768px) {
        width: 35px;
      }
    }
  }

  .header__controller {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    height: 60px;
    display: flex;
    align-items: center;

    & > span {
      font-size: 12px;
      color:#999999;
      @media screen and (max-width: 768px) {
        display: none;
      }
    }

    .cntl {
      margin:0 10px;
      border: 1px solid #ddd;
      border-radius: 3px;
      display: flex;
      // @include theme('border-color', 'nav-block-name');
      @media screen and (max-width: 768px) {
        width: 200px;
      }
      button {
        height: 40px;
        @media screen and (max-width: 768px) {
          height: 35px;
        }
        & ~ button {
          border-left: 1px solid #ddd;
          // @include theme('border-color', 'nav-block-name');
        }
      }
      .cntl__current {
        width: 180px;
        // @include theme('color', 'nav-block-name');
        @media screen and (max-width: 768px) {
          flex: 1;
        }

        svg {
          margin-right: .75em;
        }
      }
      .cntl__prev,
      .cntl__next {
        width: 40px;
        @media screen and (max-width: 768px) {
          width: 30px;
          flex: none;
        }
        svg {
          // @include theme('color', 'nav-block-name');
          vertical-align: middle;
          font-size: 20px;
          @media screen and (max-width: 768px) {
            font-size: 1rem;
          }
        }
      }
    }
  }
}
