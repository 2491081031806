.spaceInfo {
  .spaceInfo__thumb {
    //position: absolute;
    //left: 2.5em;
    //top: 0;
    width: calc(100% - 5em);
    //height: 100%;
    //background: $theme-gray-3;
    z-index: 20;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .spaceInfo__content {
    margin-top: 1.5em;
    border: 1px solid $theme-gray-1;
    border-radius: 10px;
    padding: 2.5em 0;
    display: flex;

    @media screen and (max-width: 1220px) {
      display: block;
      padding: 0;
    }

    @media screen and (max-width: 768px) {}

    .col {
      flex: 1;
      padding: 0 2.5em;
      position: relative;

      @media screen and (max-width: 1220px) {
        padding: 2em;
      }

      @media screen and (max-width: 768px) {
        padding: 1.5em;
      }

      &.col--download {
        padding-bottom: 3em;

        @media screen and (max-width: 768px) {
          padding-bottom: 2em;
        }
      }

      .download {
        position: absolute;
        bottom: 0;
        right: 2.5em;
        background: $theme-red;
        color: $white;
        width: 15em;
        line-height: 50px;
        border-radius: 2em;
        text-align: center;
        z-index: 10;

        @media screen and (max-width: 1220px) {
          right: 2em;
          bottom: 2em;
        }

        @media screen and (max-width: 768px) {}
      }

      &:first-child {
        border-right: 1px solid $theme-gray-1;

        @media screen and (max-width: 1220px) {
          border: none;
          border-bottom: 1px solid $theme-gray-1;
        }

        @media screen and (max-width: 768px) {}
      }
    }
  }

  .infotext {
    font-size: 0.840rem;
  }
}

.spaceInfo__operation {
  margin: 10px 0;

  &>img {
    border-radius: 4px;
  }

  //padding: 0 0 1em 0;
  .space_info {
    text-align: left;
    margin: 20px 0;
    color: #333333;

    p {
      display: inline;
      font-size: 1rem;
      font-weight: normal;
      vertical-align: top;
      max-width: 190px;
      word-break: keep-all;

      &.space_info_title {
        font-size: 1rem;
        font-weight: bold;
      }
    }
  }

  @media screen and (max-width: 1220px) {
    padding: 0 0 60px;
  }

  @media screen and (max-width: 768px) {}

  p {
    display: inline-block;

    strong {
      font-weight: 400;
      width: 5em;
    }
  }

  ._title {
    margin-bottom: 0.5em;
    display: flex;
    align-items: center;
    line-height: 1.5em;
    font-size: 2rem;
  }

  ._date {
    background: url('#{$image-path}/icon_spacedate.png') no-repeat left center;
  }

  ._time {
    background: url('#{$image-path}/icon_spacetime.png') no-repeat left center;
  }

  ._noti {
    background: url('#{$image-path}/icon_spacenoti.png') no-repeat left center;
  }

  .empty {
    margin-bottom: 0.5em;
    display: flex;
    align-items: center;
    line-height: 1.5em;
    font-size: 1rem;
  }
}

.spaceInfo__floor {
  //margin-bottom: 2em;
  height: 100%;

  &~.spaceInfo__floor {
    margin-top: 1.5em;
  }

  .item {
    //display: flex;
    align-items: flex-start;
    height: 100%;

    &~.item {
      margin-top: 1.5em;

      @media screen and (max-width: 1220px) {
        margin-top: 1em;
      }

      @media screen and (max-width: 768px) {}
    }
  }

  h3 {
    font-size: 3.125rem;
    line-height: 1em;
    width: 100px;
    flex: none;

    @media screen and (max-width: 1220px) {
      font-size: 2.75rem;
    }

    @media screen and (max-width: 768px) {
      font-size: 2.2rem;
      line-height: 40px;
    }
  }

  .roomlist::-webkit-scrollbar {
    display: none;
  }

  .roomlist {
    height: 100%;
    overflow-y: scroll;
    -ms-overflow-style: none;

    p {
      line-height: 2.5em;
      font-size: 1.25rem;
      color: $theme-gray-11;

      @media screen and (max-width: 1220px) {}

      @media screen and (max-width: 768px) {
        font-size: 1rem;
      }
    }

    button {
      width: 100%;
      // margin-top: 5px;
      padding: 10px;
      padding-left: 11px;
      text-align: left;
      font-weight: bold;

      &.active {
        background: #1A9952;
        color: $white;
      }

      //&:nth-child(n + 4) {
      //  margin-top: 10px;
      //}
      @media screen and (max-width: 1220px) {
        width: 100%;
        padding: 10px;
        min-width: 100px;
      }

      @media screen and (max-width: 768px) {}
    }
  }
}

.floorButton {
  padding: 5px;
  padding-top: 16px;
  margin-bottom: 15px;
  // box-shadow: 5px 5px 5px lightgrey;
  border-radius: 20px;

  &.active {
    // box-shadow: inset 5px 5px 5px lightgrey;
  }

  &:hover {
    // box-shadow: inset 5px 5px 5px lightgrey;
  }
}