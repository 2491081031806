.tabs {
  margin-top: 2em;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  //overflow-x: scroll;
  display: block;
  @media screen and (max-width: 550px) {
    display: block;
    border: 1px solid$theme-gray-4;
  }

  a,button {
    width: 200px;
    text-align: center;
    border-right: 1px solid $theme-gray-3;
    padding: 0 1em;
    @media screen and (max-width: 1220px) {
      width: 180px;
      padding: 0 10px;
    }
    @media screen and (max-width: 768px) {
      width: 130px;
    }
    @media screen and (max-width: 550px) {
      border-right: 0;
      padding: 0;
      display: block;
      width: 100%;
      & ~ a {
        border-top: 1px solid$theme-gray-4;
      }
    }
    span {
      font-size: 1rem;
      display: block;
      text-align: center;
      border-radius: 2em;
      line-height: 45px;
      transition: all 0.3s;
      @media screen and (max-width: 550px) {
        width: 100%;
        border-radius: 0;
      }

      &:hover {
        background: $theme-gray-3;
      }
    }
    &.active {
      span {
        //background: $theme-red;
        background: #489659;
        color: $white;
      }
    }

    &:last-child {
      border-right: 0;
    }
  }
}
