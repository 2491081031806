$black: #333;
$white: #fff;

$theme-red: #e72e35;
$theme-green: #489659;

$theme-gray-1: #e5e5e5;
$theme-gray-2: #d2d2d2;
$theme-gray-3: #eee;
$theme-gray-4: #ddd;
$theme-gray-5: #666;
$theme-gray-6: #999;
$theme-gray-7: #626262;
$theme-gray-8: #cecece;
$theme-gray-9: #959595;
$theme-gray-10: #eaeaea;
$theme-gray-11: #bfbfbf;

$theme-black-2: #000;
$theme-black-3: #535353;
$theme-black-4: #272b37;
$theme-black-5: #2b2f3b;
$theme-black-6: #434343;
$theme-black-7: #3e3e3e;

$theme-white-1: #fafafb;
$theme-white-2: #fff4f4;
$theme-white-3: #f8f8f8;

$theme-blue-1: #4a63ae;
$theme-blue-2: #3e5599;

$color-facebook: #2970e5;
$color-google: #ef4239;
$color-twitter: #39a9e0;

$primary: #489659;