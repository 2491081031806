/////// course 새 강좌 작성 모달창 시작 //////
.course_add_form_wrap {
    width: 100%;

    .reg_form {

        .rightBox{
            display: flex;
            align-items: center;
            justify-content: flex-end;
            height:0px;
            position: sticky;
            top: 75px;
            left: 0;
            z-index: 2;

            .submitButtons{
                width:20px;
                height:20px;
                margin-right:30px;
                position: sticky;
                top:30px;
                transform: translateY(-50px);
                right:0;

                @media screen and (max-width: 768px) {
                   margin-right:34px;
                }
            }
        }
        /*.form_left_box,.form_right_box {
        width:50%;
        display: inline-block;
        select {
          width: 100%;
        }
      }*/
        .files_container {
            padding: 20px 10px 10px 10px;
            border: 1px solid black;
            margin-top: 20px;
            display: block;
            position: relative;

            .files_title {
                position: absolute;
                top: -10px;
                background: white;
                border: 1px solid black;
                padding: 0 5px;
                text-align: center;
            }

            .image_container {
                display: inline-block;

            }

            .title_container {
                display: inline-block;
            }
        }

        .input_box {
            &:after {
                display: block;
                visibility: hidden;
                clear: both;
                content: '';
            }

            width:100%;
            margin-bottom:12px;
            display: flex;
            flex-direction: column;

            .modal_sub_title {
                float: left;
                padding-top: 3px;
                width: 20%;
                font-weight: bold;
                font-size: 1rem;
                color: #222;
            }

            .input_container {
                float: left;
                padding-left: 10px;
                width: 75%;
                padding-right: 10px;
                margin-top: 0 !important;

                select {
                    width: 35%;
                }

                input {
                    width: auto;
                    display: inline-block;
                    border: 1px solid #ddd !important;
                    padding: 10px !important;
                    border-radius: 13px !important;


                    &.secret_checkbox {
                        display: inline-block;
                        width: auto;
                        margin-left: 10px;
                    }
                }

                input[type='file'] {
                    width: 20%;
                    padding: 5px;
                }

                .cal_box {
                    &:after {
                        display: block;
                        visibility: hidden;
                        clear: both;
                        content: '';
                    }

                    .react-datepicker-wrapper {
                        width: 47%;
                        float: left;
                    }

                    .cal_ir {
                        float: left;
                        width: 6%;
                        height: 37px;
                        padding-top: 7px;
                        text-align: center;
                    }
                }


                // datepicker 디자인
                .react-datepicker {
                    select {
                        background: none;
                        border: none;
                        word-wrap: normal;
                        appearance: auto;
                    }

                    .left_button {
                        margin-right: 15px;
                    }

                    .right_button {
                        margin-left: 15px;
                    }

                    .react-datepicker__header.react-datepicker__header--time {
                        height: 87.28px;
                        padding-top: calc(50% - 12px);
                    }

                    .react-datepicker__tab-loop {
                        display: inline-block;
                    }

                    .react-datepicker__day.react-datepicker__day--outside-month {
                        color: gray;
                    }
                }



                button,
                input,
                optgroup,
                select,
                textarea {
                    margin: 0;
                    font-family: inherit;
                    font-size: inherit;
                    line-height: inherit;
                }


                input[type='number']::-webkit-inner-spin-button,
                input[type='number']::-webkit-outer-spin-button {
                    -webkit-appearance: none;
                    margin: 0;
                }

                .check_box_wrap {
                    margin-bottom: 8px;

                    &:after {
                        display: block;
                        visibility: hidden;
                        clear: both;
                        content: '';
                    }

                    .input_check {
                        float: left;
                        width: auto !important;
                        margin-right: 6px;
                        transform: translateY(7px);
                        height: 14px;

                        @media screen and (max-width: 785px) {
                            transform: translateY(3px);
                        }

                    }


                    label {
                        float: left;
                    }
                }
            }
        }
    }

}

.course_add_select {
    width: 100% !important;
    border: 1px solid #ddd !important;
    border-radius: 13px;
    height: 35px;
}

.course_add_file {
    border: none !important;
}