.modal-open {
  overflow: hidden;
}

.Myclass_list {
  position: relative;
  margin-top: 67px;

  @media screen and (max-width: 768px) {
    padding: 0 10px;
  }

  &.myPageList {
    margin-top: 0;

    .myContent_button {
      position: relative;
      top: 0;
      margin-bottom: 15px !important;
      margin-top: 15px;

      @media screen and (max-width: 768px) {
        margin-bottom: 10px !important;
      }

    }

  }


  .myContent_button {
    position: absolute;
    left: 0;
    top: -45px;


    @media screen and (max-width: 1500px) {
      right: auto;
      left: 0;
      top: -42px;
    }

    @media screen and (max-width: 768px) {
      top: -36px;
      right: auto;
      left: 0;
    }

    .left_button {
      float: left;
      background-color: #ececec;
      padding: 10px 50px;
      font-size: 1rem !important;
      cursor: pointer;
      border-radius: 5px;

      &:hover {
        font-size: 1rem !important;
        background-color: #00863C !important;
        color: #fff;
        font-weight: 700;

        @media screen and (max-width: 550px) {
          font-size: 0.9em !important;
          font-weight: 400 !important;
        }

      }

      @media screen and (max-width: 768px) {
        padding: 10px 20px;
        font-size: 1em;
      }

      @media screen and (max-width: 550px) {
        padding: 5px 35px !important;
        font-size: 0.9em !important;
      }

    }

    .right_button {
      float: left;
      background-color: #ececec;
      padding: 10px 50px;
      margin-left: 10px;
      font-size: 1rem !important;
      cursor: pointer;
      border-radius: 5px;

      &:hover {
        font-size: 1rem !important;
        background-color: #00863C !important;
        color: #fff;
        font-weight: 700;

        @media screen and (max-width: 550px) {
          font-size: 0.9em !important;
          font-weight: 400 !important;
        }
      }

      @media screen and (max-width: 768px) {
        padding: 10px 20px;
        font-size: 0.9em;
      }

      @media screen and (max-width: 550px) {
        padding: 5px 35px !important;
        font-size: 0.9em !important;
      }
    }

    .is_active {
      font-size: 1rem !important;
      background-color: #00863C !important;
      color: #fff;
      font-weight: 700;

      @media screen and (max-width: 768px) {
        font-size: 1em;
      }

      @media screen and (max-width: 550px) {
        font-size: 0.9em !important;
        font-weight: 400;
      }
    }

  }

  .myContent_button:after {
    display: block;
    visibility: hidden;
    clear: both;
    content: '';
  }

  .programList {
    .programList__list {
      margin: 0;
      position: relative;

      .ended_class {
        position: absolute;
        top: -29px;
        left: 0;
        font-size: 0.9em;
        font-weight: 700;
        color: #1a9952;

        @media screen and (max-width: 1500px) {
          left: auto;
          right: 0;
        }

        @media screen and (max-width: 768px) {
          left: auto;
          right: 0;
          top: -19px;
          font-size: 0.6em;
        }


      }

      .programList__item {
        width: 100%;
        margin: 0;
        margin-bottom: 15px !important;
        border-radius: 4px;
        border: 1px solid #e9e9e9;
        background-color: #fff;
        height: fit-content;
        display: flex;
        align-items: center;

        @media screen and (max-width: 1024px) {

          .info {
            .playTime {
              top: -14px;
            }
          }
        }

        @media screen and (max-width: 768px) {
          display: block;
          width: 100% !important;

        }


        .Mycontent_pop {
          position: fixed;
          z-index: 10000;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: rgba(0, 0, 0, 0.4);
          opacity: 1;
          visibility: visible;

          .inner_box {
            position: fixed;
            padding: 30px;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            border-radius: 10px;
            width: 90%;
            max-width: 800px;
            height: 600px;
            background-color: #fff;
          }

          .popClose {
            width: 100%;
            max-width: 100px;
            padding: 10px 20px;
            margin: 0 auto;
            background-color: #ce36c4;
            color: #fff;
            font-weight: 700;
            text-align: center;
            border-radius: 5px;
            cursor: pointer;

            &:hover {
              background-color: #9e1495;
            }
          }
        }

        .disnone {
          opacity: 0;
          visibility: hidden;
        }


        // .hoverLayout {
        //   display: none;
        // }

        .thumb {
          width: 30%;
          height: 100%;
          padding-top: 0;
          max-width: inherit;
          overflow: inherit;
          padding: 15px;
          background-color: #fff;
          float: none !important;

          @media screen and (max-width: 1024px) {
            width: 100% !important;
            height: auto !important;
            max-width: 222px !important;
          }

          @media screen and (max-width: 768px) {
            max-width: 100% !important;
            padding: 0 !important;
          }


          a {
            display: block;
            border-radius: 10px;
            overflow: hidden;

            @media screen and (max-width: 768px) {
              border-radius: 0 !important;
            }

            img {
              height: 100%;
              position: static;
              height: 157px;

              @media screen and (max-width: 1024px) {
                height: 157px !important;
              }


            }
          }


        }

        .info {
          width: 70%;
          position: relative;
          background: #fff;
          border: none;
          float: none !important;
          padding-right: 10px;

          @media screen and (max-width: 1024px) {
            width: 100% !important;
          }


          @media screen and (max-width: 768px) {
            padding-right: 0;
          }

          .playTime {
            position: absolute;
            right: 15px;
            top: -18px;

            @media screen and (max-width: 1024px) {
              &.tops {
                top: 0;
              }

            }


            &.mobiles {
              display: none;

              @media screen and (max-width: 768px) {

                &.mobiles {
                  margin-top: 10px;
                }
              }

            }

            @media screen and (max-width: 768px) {
              display: none;

              &.mobiles {
                display: flex;
                align-items: end;
                justify-content: space-between;
                position: relative;
                right: auto;
                top: 0;


              }
            }




            .mini_title {
              font-weight: 400 !important;
              font-size: 0.85rem;
              text-align: right;
              line-height: 0.85rem;
              margin-bottom: 3px;

              @media screen and (max-width: 768px) {
                font-weight: 700 !important;
                font-size: 0.95rem !important;
              }


            }

            .playTime_value {
              display: flex !important;
              align-items: flex-end;
              justify-content: end;
              font-weight: 700;
              font-size: 2rem !important;
              color: #00863C !important;
              line-height: 1.5rem;


              .rightNum {
                color: #696969;
                font-size: 0.9rem;
                line-height: 0.9rem;
                font-weight: 400;
                display: flex;
                align-items: flex-end;
                justify-content: end;
              }
            }
          }

          .info_div_one {
            height: auto;
            margin: 0;
            padding-left: 5px !important;


            .chapter_prop {
              margin-top: 3px;
              font-size: 1em;
            }

            @media screen and (max-width: 1024px) {
              padding-left: 0 !important;
              padding-bottom: 20px;
            }

            @media screen and (max-width: 768px) {
              padding: 10px !important;
            }


          }

          h2 {
            height: auto;
            font-weight: 700;
            width: 70%;
            margin-bottom: 10px;
            overflow: hidden;
            white-space: pre;
            text-overflow: ellipsis;

            @media screen and (max-width: 768px) {
              width: 100% !important;
            }



            a {
              display: inline;
              font-size: 1.05em;
              color: #333333;
              margin-bottom: 10px;
            }
          }

          .bottom_button_box {
            width: 100%;
            margin-top: 14px;
            display: flex;

            @media screen and (max-width: 768px) {
              display: block;

              .left_button_course {
                margin-left: 0 !important;
                margin-top: 5px !important;

                a {
                  padding: 5px 10px !important;
                }
              }
            }

            &.flexBox {

              @media screen and (max-width: 1024px) {
                display: grid !important;
                justify-content: space-between;
                grid-template-columns: 49% 49%;
                grid-row-gap: 5px;

                .left_button_confirm {
                  width: 100% !important;
                  padding: 5px 10px !important;
                }

                .mg12 {
                  width: 100% !important;
                  margin-left: 0 !important;

                  a {
                    padding: 5px 10px !important;
                  }
                }


                .right_button {
                  width: 100% !important;
                  margin-left: 0 !important;

                  a {
                    padding: 5px 10px !important;
                    width: 100% !important;
                    margin-left: 0 !important;
                  }
                }
              }






              @media screen and (max-width: 768px) {
                display: grid !important;
                justify-content: space-between;
                grid-template-columns: 100%;
                grid-row-gap: 9px;

              }


            }

            .left_button_confirm {
              float: none !important;
            }

            .left_button_course {
              float: none !important
            }

            .left_button {
              float: none !important;
            }

            .right_button {
              float: none !important
            }

            .mg12 {
              margin-left: 12px;
              background-color: #00B852 !important;

              a {
                border-radius: 5px !important;
                background-color: #00B852 !important;
                font-size: 0.9rem !important;
                font-weight: 400 !important;
              }
            }

            .left_button_confirm {
              float: left;
              border-radius: 100px;
              color: #fff;
              background-color: #489659;
              font-weight: 700;
              padding: 10px 20px;
              margin-top: 0;
              cursor: pointer;

              @media screen and (max-width: 768px) {
                padding: 5px 10px !important;
              }

            }

            .left_button_course {
              float: left;
              border-radius: 100px;
              margin-left: 10px;
              margin-top: 0;
              cursor: pointer;

              a {
                border-radius: 100px;
                display: block;
                color: #fff;
                background-color: #6eace5;
                font-weight: 700;
                padding: 10px 20px;
                text-align: center;
              }
            }

            .left_button_course2 {
              float: left;
              border-radius: 100px;
              margin-top: 0;
              cursor: pointer;

              a {
                border-radius: 100px;
                display: block;
                color: #fff;
                background-color: #6eace5;
                font-weight: 700;
                padding: 10px 20px;
              }
            }

            .left_button {
              float: left;
              border-radius: 5px !important;
              color: #fff;
              background-color: #489659;
              font-weight: 700;


              a {
                color: #fff;
                display: block;
                padding: 10px 20px;

              }
            }

            .left_active {
              background-color: #489659;
              color: #fff;
              font-weight: 700;
            }

            .right_button {
              float: left;
              margin-left: 12px;
              border-radius: 5px !important;
              background-color: #fff;
              border: 1px solid #a3a3a3;
              font-weight: 700;
              font-size: 0.9rem !important;
              font-weight: 400 !important;


              &.active {
                background: #179659 !important;
                border: none;

                a {
                  color: white;
                  font-size: 0.9rem;
                }
              }
            }

            a {
              color: #a3a3a3;
              display: block;
              padding: 10px 30px
            }

          }

          .bottom_button_box:after {
            display: block;
            visibility: hidden;
            clear: both;
            content: '';
          }

        }

        .info_d {
          display: block;
        }

        a:after {
          display: block;
          visibility: hidden;
          clear: both;
          content: '';
        }


      }
    }
  }
}


.programList {
  .programList__title {
    text-align: center;

    h2 {
      font-size: 1.75rem;

      @media screen and (max-width: 1220px) {}

      @media screen and (max-width: 768px) {
        font-size: 1.5rem;
        width: 230px;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }

    p {
      font-size: 1.125rem;
      margin-top: 10px;

      @media screen and (max-width: 1220px) {}

      @media screen and (max-width: 768px) {
        font-size: 1rem;
        margin-top: 5px;
      }
    }
  }

  .programList__list {
    margin: 0;
    display: grid;
    margin-top: 2.5em;
    grid-template-columns: 24% 24% 24% 24%;
    justify-content: space-between;
    grid-row-gap: 15px;


    &.myPage_list {
      grid-template-columns: 100%;
      grid-row-gap: 0;


      @media screen and (max-width: 768px) {
        grid-template-columns: 32% 32% 32%;
      }

      @media screen and (max-width: 550px) {
        grid-template-columns: 49% 49%;
      }

      @media screen and (max-width: 370px) {
        grid-template-columns: 100%;
      }

      .programList__item {
        .info_div_one {


          &>div {

            .propsWrap {
              display: flex;

              @media screen and (max-width: 1024px) {
                display: block;
              }


              // @media screen and (max-width: 768px) {
              //   display: block;

              //   .props {

              //     &~.props {
              //       margin-left: 0px;
              //     }

              //   }

              // }



              @media screen and (max-width: 768px) {
                display: block;

                .props {

                  height: 1rem;
                  overflow: hidden;
                  white-space: pre;
                  text-overflow: ellipsis;

                  &~.props {
                    margin-top: 3px;
                  }

                }

              }


              .props {
                font-size: 0.85rem;
                color: #696969;

                &~.props {
                  margin-left: 0px;
                }

              }
            }

          }

          .bottom_button_box {

            .left_button_confirm {
              border-radius: 5px !important;
              background-color: #00863C;
              font-size: 0.9rem !important;
              font-weight: 400 !important;

              @media screen and (max-width: 768px) {
                width: 100%;
              }



            }

            .left_button_course {
              border-radius: 5px !important;
              background-color: #00B852;
              font-size: 0.9rem !important;
              font-weight: 400 !important;

              @media screen and (max-width: 768px) {
                width: 100%;
              }

              a {
                border-radius: 5px !important;
                background-color: #00B852 !important;
                font-size: 0.9rem !important;
                font-weight: 400 !important;


                @media screen and (max-width: 768px) {
                  width: 100%;
                  height: 29px;
                }


              }
            }
          }
        }
      }
    }

    .null__list {
      margin: 0 auto;
    }

    @media screen and (max-width: 1220px) {
      grid-template-columns: 32.5% 32.5% 32.5%;
      grid-row-gap: 8px;
    }

    @media screen and (max-width: 768px) {
      grid-template-columns: 100%;
      grid-row-gap: 8px;
    }

    // @media screen and (max-width: 550px) {
    //   grid-template-columns: 49% 49%;
    //   grid-row-gap: 10px;
    // }

    .text-center {
      width: 100%;
    }
  }

  .programList__item {
    flex: none;
    position: relative;
    overflow: hidden;
    border: 1px solid #DDDDDD;
    box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.07);
    border-radius: 4px 0 4px 4px;
    height: fit-content;
    max-height: 286px;
    min-height: 286px;

    @media screen and (max-width: 1200px) {
      max-height: none;
      min-height: auto;
    }

    background-color: #fff;

    .thumb {
      padding-top: 56.5%;
      max-width: 100%;
      position: relative;
      overflow: hidden;

      @media screen and (max-width:768px) {
        padding-left: 35%;
        padding-top: 0;
        height: 100%;
        min-height: 110px;
      }

      .div_one_p {
        position: absolute;
        right: 0px;
        top: 0px;
        z-index: 1;
        background-color: #F46E43;
        width: 42px;
        height: 40px;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 0.85rem;
        font-weight: 700;
        border-radius: 0px;
      }

      .div_one_courseType {
        position: absolute;
        right: 0px;
        top: 0px;
        z-index: 1;
        background-color: #4A4A4A;
        width: 42px;
        height: 40px;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 0.85rem;
        font-weight: 700;
        border-radius: 0px;
      }

      img {
        vertical-align: top;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;

      }
    }



    @media screen and (max-width: 1220px) {}

    @media screen and (max-width: 768px) {}

    @media screen and (max-width: 550px) {}

    .hoverLayout {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      max-height: auto;
      z-index: 0;
      background: rgba(0, 0, 0, 0.6);
      color: $white;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;


      /*transition: all 0.5s;*/
      @media screen and (max-width: 768px) {
        display: none;
      }

      p {
        position: relative;
        top: 40%;
        font-size: 22px;
      }

      .section--business & {
        //background: $theme-red;
      }

      h3 {
        font-size: 1.75rem;
        line-height: 1.5em;
        font-weight: 400;
        word-break: keep-all;
        padding-bottom: 0.75em;
        margin-bottom: 0.75em;
        border-bottom: 1px solid $white;
      }

      li {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        font-size: 0.9rem;
        line-height: 2em;
      }

      &:after {
        content: '';
        /* background: url('#{$image-path}/hover_search.png') no-repeat center;*/
        background-size: 100% 100%;
        width: 37px;
        height: 37px;
        bottom: 2.25em;
        left: 50%;
        display: block;
        position: absolute;
        transform: translateX(-50%);
      }
    }

    a {
      color: $theme-black-2;
      position: relative;
      display: block;

      @media screen and(max-width:768px) {
        display: flex;
        height: 120px;
        align-items: center;
      }

      &:hover {
        .hoverLayout {
          z-index: 10;
        }
      }
    }

    .d_day {
      position: absolute;
      top: 0;
      left: 1em;
      z-index: 10;
      white-space: nowrap;
      background: $theme-red;
      color: $white;
      font-size: 1.5rem;
      line-height: 2em;
      padding: 0 1em;

      @media screen and (max-width: 1220px) {}

      @media screen and (max-width: 768px) {
        left: 0;
        font-size: 1.25rem;
      }
    }

    .thumb {
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;

        @media screen and (max-width: 500px) {
          width: 100%;
        }
      }
    }

    .info {
      background: #fff;
      /*padding: 15px;*/
      // border: 1px solid $theme-gray-1;

      @media screen and (max-width: 1220px) {}

      @media screen and (max-width: 768px) {
        // padding: 10px;
      }

      &.text-center {
        text-align: center;
        background: $white;

        h2 {
          font-weight: 600;
          overflow: hidden;
        }
      }

      div {
        // margin-top: 10px;
        display: block;

      }

      .date_wrap {
        margin: 0px 15px;
        display: block;

        @media screen and (max-width: 768px) {
          margin: 0 10px;
        }

        .blocks {
          display: flex;
          align-items: center;

          @media screen and (max-width: 550px) {
            display: block;
          }


          &~.blocks {
            margin-top: 8px;
            padding-bottom: 20px;

            @media screen and (max-width: 768px) {
              padding-bottom: 13px;
            }


          }

          .left_info {
            font-size: 0.95rem;
            width: 60px;
            height: 1.1rem;
            color: #757575;
          }

          .right_info {
            font-size: 0.95rem;
            height: 1.1rem;
            overflow: hidden;
            white-space: pre;
            text-overflow: ellipsis;
            width: calc(100% - 70px);

            @media screen and (max-width: 550px) {
              width: 100%;
            }

          }

        }

        // &>div {
        //   display: flex;
        //   flex-direction: column;
        //   flex-basis: 70%;

        //   .date {
        //     display: flex;
        //     justify-content: space-between;

        //     &>span:nth-child(1) {
        //       color: #757575;
        //       text-align: left;
        //       font-size: 14px;
        //       flex-basis: 35%;
        //     }

        //     &>span:nth-child(2) {
        //       color: #333333;
        //       text-align: left;
        //       font-size: 14px;
        //       flex-basis: 55%;
        //     }
        //   }
        // }

      }

      .info_div_one {
        // margin: 10px 15px 10px 15px;
      }









      .div_one_p {
        background-color: #FF7575;
        color: white;
        border-radius: 50%;
        font-size: 14px;
        padding: 10px;
        font-weight: 700;
        display: inline;
      }

      h2 {
        font-size: 18px;
        line-height: 24px;
        height: auto;
        font-weight: 700;
        /*width: 210px;*/
        word-break: keep-all;

        @media screen and (max-width: 1220px) {
          font-size: 18px;
          line-height: 25px;
          height: 50px;
        }

        @media screen and (max-width: 768px) {
          font-size: 14px;
        }
      }

      img {
        margin-right: 5px;
        position: relative;
        top: 2px;
      }

      .date {
        color: $theme-black-3;
        background-color: white;
        font-size: 15px;
        padding: 5px 0;
      }
    }

    .etc {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 50px;
      border: 1px solid $theme-gray-1;
      border-top: none;
      padding: 0 15px;

      @media screen and (max-width: 1220px) {}

      @media screen and (max-width: 768px) {
        display: block;
        height: auto;
        padding: 10px;
      }


      .status {
        color: $theme-red;
        border: 1px solid $theme-red;
        height: 30px;
        line-height: 30px;
        padding: 0 15px;
        font-size: 14px;
        border-radius: 5px;

        &.disabled {
          background: $theme-gray-9;
          border-color: $theme-gray-9;
          color: $white;
        }

        @media screen and (max-width: 1220px) {}

        @media screen and (max-width: 768px) {
          margin-top: 5px;
          display: block;
          width: 100%;
          text-align: center;
        }
      }
    }
  }
}


.consulting_write_box {
  width: 100%;

  .course_overlay_footer {
    margin: 0 !important;
    margin: 20px 0 35px 0 !important;

    .form-submit {
      background-color: #6eace5 !important;
    }

    .overlay_close {
      background-color: #fff !important;
      color: #3e3e3e !important;
      border: 1px solid #e4e4e4 !important;
    }
  }

  .consulting_write_wrap {
    width: 100%;
    border-top: 1px solid #000;
    border-bottom: 1px solid #ddd;

    .consulting_write {
      width: 100%;
      border-top: 1px solid #dddddd;

      &:after {
        display: block;
        visibility: hidden;
        clear: both;
        content: '';
      }

      .modal_sub_title {
        float: left;
        padding: 25px 30px;
        width: 30%;
        background-color: #f9f9f9;

        &:before {
          display: none;
        }

        @media screen and (max-width: 768px) {
          padding: 21px 9px;
          font-size: 0.9rem;
        }

      }

      .input_container {
        float: left;
        padding: 17px 20px;
        margin: 0 !important;
        width: 70%;
        font-size: 0.9rem;

        .form-control {
          border: 1px solid #000;
          padding: 0;
        }

        @media screen and (max-width: 768px) {
          padding: 13px 9px;
          font-size: 0.85rem;
        }

        .readonly_input {
          background-color: #fff;
          border: 1px solid #fff;
        }

        input {
          width: 100% !important;
          height: 41px;

          @media screen and (max-width: 1024px) {
            height: 35px;
          }
        }
      }

      .input_checkbox_box {
        margin-top: 20px;

        input {
          height: 13px;
        }

        .consulting_check {
          width: auto !important;
          display: inline !important;

          @media screen and (max-width: 768px) {
            transform: translateY(2px);
          }
        }

        label {
          font-weight: 700;
        }
      }
    }
  }

}

.consulting_write:nth-child(6) .modal_sub_title {
  height: 309px;

  @media screen and (max-width: 1024px) {
    height: 258px;
  }

  @media screen and (max-width: 550px) {
    height: 323px;
  }


  @media screen and (max-width: 370px) {
    height: 360px;
  }

}


.info_div_one {

  .card_title {
    font-size: 18px;
    padding: 17px 16px;
    padding-bottom: 12px;
    height: 48px;
    line-height: 24px;
    height: 48px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 280px;
    font-weight: 700;

    @media screen and (max-width:768px) {
      padding: 10px;
      height: 44px !important;
      padding-bottom: 4px;
      width: 230px !important;
      word-break: keep-all;
      text-overflow: ellipsis;
    }

  }
}

.roomName_4 {
  color: #254658;
  background-color: #DDEAEE;
  font-size: 12px;
  border-radius: 23px;
  padding: 4px 6px;
}

.roomName_5 {
  color: #25583C;
  background-color: #DDEEE4;
  font-size: 12px;
  border-radius: 23px;
  padding: 4px 6px;
}

.state1_wks {
  background: #00B852;
  color: #fff;
  min-width: 40px;
  padding: 4px 33px;
  border-radius: 23px;
  font-size: 13px;
}

.state2_wks {
  background: #696969;
  color: #fff;
  min-width: 40px;
  padding: 4px 33px;
  border-radius: 23px;
  font-size: 13px;
}

.state3_wks {
  background: #FF7245;
  color: #fff;
  min-width: 40px;
  padding: 4px 25px;
  border-radius: 23px;
  font-size: 13px;
}

.state4_wks {
  background: #DDDDDD;
  color: #696969;
  min-width: 40px;
  padding: 4px 33px;
  border-radius: 23px;
  font-size: 13px;
}

.dayTag {
  font-size: 12px;
}