.paginationLayout {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2.5em;
}

.pagination {
  justify-content: center;
  width: 100%;
  max-width: 300px;

  .page-item {
    margin: 0 2px;

    a {
      line-height: 35px;
      display: block;
      border: none;
      width: 35px;
      height: 35px;
      padding: 0;
      text-align: center;
      border-radius: 50%;
      overflow: hidden;
      color: $theme-black-2;
      font-size: 0.8rem;

      @media (max-width:1200px) {
        width: 25px;
        height: 25px;
        line-height: 25px;
      }

      &.active {
        color: $white;
        background: #00863C !important;
      }
    }
  }

  .page-item--arrow {
    a {
      // background: $theme-gray-8;
    }
  }
}