.mobileUserMenu {
  position: fixed;
  right: -250px;
  top: 0;
  background: $white;
  width: 250px;
  height: 100%;
  z-index: 6000;
  transition: all 0.5s;
  &.active {
    right: 0;
  }
}

.mobileUserMenu__header {
  //background: $theme-red;
    background: #1A9952;
  .wrap {
    line-height: 70px;
    text-align: center;
    position: relative;
    color: $white;
    font-size: 1.25rem;

    button {
      position: absolute;
      right: 10px;
      top: 50%;
      transform: translateY(-50%);
      width: 40px;
      height: 50px;
      line-height: 50px;
      color: $white;
      z-index: 2;
      font-size: 2rem;
    }
  }
}

.mobileUserMenu__links {
  a {
    display: flex;
    align-items: center;
    justify-content: space-between;
    line-height: 50px;
    padding: 0 1em;
    font-size: 16px;
    border-bottom: 1px solid $theme-gray-3;
  }
  .mobileUserMenu__usercontainer{
    border-bottom: 1px solid $theme-gray-3;
    padding: 14px 0;
    text-align: center;
    .username{
      display: inline-block;
      padding: 0 20px 0 0;
    }
    .logout{
      border: none;
      outline: none;
      background: $theme-gray-3;
      display: inline-block;
      padding: 3px 10px;
      border-radius: 15px;
    }
  }
}

.dim {
  display: none;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  z-index: 4000;
  &.active {
    display: block;
  }
}
