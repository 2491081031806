.form__head {
    h3 {
        padding-bottom: 10px;
        border-bottom: 3px solid rgb(77, 77, 77);
    }
}

.popup_footer {
    background-color: #fff;
    border-radius: 0 0 10px 10px;
}


.email_btn_wrap {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 30px 0 10px 0;

    img {
        max-width: 80px;
    }
}

.basic_page_content {
    margin-top: 50px;

    h2 {
        font-weight: 600 !important;
    }

    &>div {
        h3 {
            font-weight: 500 !important;
            margin: 10px 0 5px 0;
        }
    }
}

.authPopup {
    // label{
    //   position: absolute;
    //   width: 1px;
    //   height: 1px;
    //   margin: -1px;
    //   overflow: hidden;
    //   clip-path: polygon(0 0, 0 0, 0 0);
    // }
    // 웹접근성을 위해 추가한 label안보이게 tori
    display: none;
    position: fixed;
    left: 0;
    top: 0;
    //width: 100vw;
    height: 100%;
    //height: 100%;
    width: 100%;
    z-index: 998;
    background: #fff;
    //overflow: hidden;
    overflow: auto;
    padding: 0 10px;

    &::-webkit-scrollbar {
        display: none;
        /* Chrome, Safari, Opera*/
    }

    &.open {
        display: block;
        // border-top: 5px solid #137abd;
    }


    .authPopWrap {
        height: 100%;

    }

    .form__input {
        .input {
            overflow: visible;
        }
    }

    .closeBtn {
        width: 44px;
        height: 44px;
        background: url('#{$image-path}/login/i-close.svg') no-repeat center;
        overflow: hidden;
        position: absolute;
        right: 40px;
        top: 40px;
        background-size: contain;

        @media screen and (max-width: 1024px) {
            width: 25px;
            height: 25px;
            right: 20px;
            top: 20px;
        }
    }

    .signup__certi {
        position: absolute;
        top: 45%;
        left: 50%;
        transform: translate(-49%, -50%);
    }

    .signup__next {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 200px;
        height: 50px;
        background: #00863C;
        font-size: 18px;
        color: #fff;
        border-radius: 6px;
        margin: 20px auto;
    }

    .signup__next:focus {
        outline: 3px solid black;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 200px;
        height: 50px;
        background: #00863C;
        font-size: 18px;
        color: #fff;
        border-radius: 6px;
        margin: 20px auto;
    }

    .inn {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
    }

    .auth__head {
        margin-bottom: 30px;

        h2 {
            font-size: 40px;
            color: #00863C;
            font-weight: 400;
            margin-bottom: 10px;
        }

        p {
            margin: 0 0 10px;
            font-size: 20px;
            color: #5e5e5e;
            word-break: keep-all;
            font-size: 20px;

            u {
                font-size: 20px;
                font-weight: 600;
            }
        }

        .box {
            position: relative;
            padding: 20px 20px 20px 50px;
            line-height: 22px;
            font-size: 16px;
            color: #fff;
            font-weight: 400;
            text-align: left;
            background: #477ca8 url('#{$image-path}/i-caution.svg') no-repeat 18px center;
            border-radius: 8px;
            max-width: 445px;

            p {
                margin: 0;
                font-size: 16px;
                color: #fff;
            }

            span {
                font-size: 16px;
                font-weight: 700;
                border-bottom: 1px solid #ddd;
            }

            button {
                position: absolute;
                top: 10px;
                right: 10px;
                width: 25px;
                height: 25px;
                background: url('#{$image-path}/i-caution-close.svg') no-repeat center;
                background-size: 14px;
                text-indent: -9999px;
                cursor: pointer;
            }
        }

        @media screen and (max-width: 1024px) {
            margin-top: 20px;

            h2 {
                font-size: 28px;
                margin-bottom: 15px;
            }

            .box {
                font-size: 15px;

                p {
                    font-size: 15px;
                }

                span {
                    font-size: 15px;
                    font-weight: 500;
                }
            }
        }

        @media screen and (max-width: 768px) {
            h2 {
                font-size: 25px;
            }

            p {
                margin: 0 0 6px;
                font-size: 18px;
                line-height: 1;

                u {
                    font-size: 18px;
                }
            }
        }

        @media (max-width: 425px) {
            p {
                font-size: 16px;

                u {
                    font-size: 16px;
                }
            }

            .box {
                font-size: 14px;
                letter-spacing: -0.3px;
                word-break: keep-all;

                p {
                    font-size: 14px;
                }

                span {
                    font-size: 14px;
                }
            }
        }

        @media (max-width: 424px) {
            .box {
                p {
                    display: inline;
                    margin: 0 4px 0 0;
                }
            }
        }
    }

    .input {
        input {
            &::placeholder {
                color: #696969;
            }
        }

        select {
            color: #767676;
        }
    }

    .login {
        .auth__head {
            text-align: center;
        }

        .auth__body {
            max-width: 445px;
            margin: 0 auto;

            @media screen and (max-width: 768px) {
                max-width: 100%;
            }
        }

        .form__row {
            margin-bottom: 20px;
        }

        .input {
            margin-bottom: 0;
        }

        .formBtnWrap {
            flex: 1;

            a {
                width: 100%;
                background: #00863C;
            }
        }

        .flexBox {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
        }

        .authLiks {
            a {
                font-size: 14px;
                color: #696969;
            }

            .bar {
                font-size: 12px;
                color: #dddddd;
                margin: 0 15px;
            }
        }

        .snsLinks {
            button {
                font-size: 14px;
                color: #696969;
                margin: 0 25px;
                width: 55px;
                padding-top: 55px;
                text-align: center;

                &.naver {
                    background: url('#{$image-path}/i-naver.svg') no-repeat top center;
                    background-size: 44px;
                }

                &.kakao {
                    background: url('#{$image-path}/i-kakao.svg') no-repeat top center;
                    background-size: 44px;
                }

                &.facebook {
                    background: url('#{$image-path}/i-facebook.svg') no-repeat top center;
                    background-size: 44px;
                }
            }
        }
    }

    .bill {
        padding: 90px 0;
        max-width: 1200px;
        margin: 0 auto;

        .auth__head {
            margin-bottom: 50px;
        }

        @media screen and (max-width: 1024px) {
            padding: 40px 0;
        }
    }

    .signup {
        padding: 90px 0;
        max-width: 1200px;
        margin: 0 auto;

        .auth__head {
            margin-bottom: 50px;
        }

        @media screen and (max-width: 1024px) {
            padding: 40px 0;
        }

        .form__item {
            padding: 25px 0 !important;
        }

        .form__head {
            &.type2 {
                display: flex;
                align-items: center;

                span {
                    font-size: 14px;
                    color: #696969;
                    margin-left: 10px;

                    i {
                        color: #d44d53;
                        margin-right: 5px;
                    }
                }

                @media screen and (max-width: 768px) {
                    display: block;

                    span {
                        margin-left: 0;
                    }
                }
            }
        }

        .authentication {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 30px;

            label {
                display: none;
            }

            .authenticationButtonWrap {
                flex: 1 1;
                margin-left: -1px;

                button {
                    flex: 1;
                    height: 100px;
                    margin: 30px auto 5px auto;
                    // border: 1px solid #dddddd;
                    display: flex;
                    align-items: center;
                    font-size: 18px;
                    color: #333;
                    justify-content: center;

                    // &:nth-child(2) {
                    //   margin: 0 15px;
                    // }

                    span {
                        font-size: 18px;
                        margin: 10px 0;
                        color: #333;
                    }

                    &.phone {
                        span {
                            height: 55px;
                            line-height: 55px;
                            background: url('#{$image-path}/i-phone.svg') no-repeat left center;
                            background-size: contain;
                        }
                    }

                    &.ipin {
                        span {
                            height: 40px;
                            line-height: 40px;
                            background: url('#{$image-path}/i-mac.svg') no-repeat left center;
                            background-size: contain;
                        }
                    }


                    &:hover {
                        border-color: #00863C;

                        span {
                            color: #00863C;
                        }

                        &.phone {
                            span {
                                height: 55px;
                                line-height: 55px;
                                background: url('#{$image-path}/i-phone-active.svg') no-repeat left center;
                                background-size: contain;
                            }
                        }

                        &.ipin {
                            span {
                                height: 40px;
                                line-height: 40px;
                                background: url('#{$image-path}/i-mac-active.svg') no-repeat left center;
                                background-size: contain;
                            }
                        }

                        &.email {
                            span {
                                // height: 52px;
                                // line-height: 52px;
                                background: url('#{$image-path}/i-email-active.svg') no-repeat left center;
                                background-size: contain;

                                p {
                                    color: #00863C;
                                }
                            }
                        }
                    }
                }

            }



            @media screen and (max-width: 768px) {
                display: block;
                margin-bottom: 20px;

                a {
                    &:nth-child(2) {
                        margin: 15px 0;
                    }

                    height: 80px;

                    span {
                        padding-left: 60px;
                    }

                    &.phone {
                        span {
                            height: 40px;
                            line-height: 40px;
                        }
                    }

                    &.ipin {
                        span {
                            height: 30px;
                            line-height: 30px;
                        }
                    }

                    &.email {
                        span {
                            height: 40px;
                            line-height: 40px;
                        }
                    }
                }
            }
        }

        .authentication__txt {
            p {
                font-size: 14px;
                color: #696969;
                position: relative;
                line-height: 25px;
                padding-left: 5px;

                &::after {
                    content: '';
                    width: 3px;
                    height: 3px;
                    background: #696969;
                    border-radius: 50%;
                    position: absolute;
                    left: 0;
                    top: .8em;
                }
            }
        }

        .checkboxWrap {
            @media screen and (max-width: 1024px) {
                .form__row {
                    margin-bottom: 10px;
                }
            }
        }

        .checkboxItem {
            .checkbox__description {
                font-size: 20px;

                @media screen and (max-width: 1024px) {
                    font-size: 18px;
                }

                @media screen and (max-width: 768px) {
                    font-size: 16px;
                }

                @media screen and (max-width: 767px) {
                    font-size: 14px;
                }
            }
        }

        .flexNone {
            display: block;
        }

        .flexBox {
            display: flex;
            align-items: center;
            width: 100%;
        }

        .more {
            margin-left: auto;
            font-size: 20px;
            color: #333;
            padding-right: 20px;
            flex: none;
            background: url('#{$image-path}/arrow-right.svg') no-repeat right center;

            @media screen and (max-width: 1024px) {
                font-size: 18px;
            }

            @media screen and (max-width: 768px) {
                font-size: 16px;
            }
        }
    }

    .withdrawer {
        max-width: 1200px;
        margin: 0 auto;
        padding: 90px 0;

        @media screen and (max-width: 1024px) {
            padding: 40px 0;
        }

        .form__item {
            padding: 15px 0;
        }

        .txtType {
            p {
                font-size: 18px;
                line-height: 2em;
                color: #696969;
            }

            @media screen and (max-width: 768px) {
                p {
                    font-size: 16px;
                    word-break: keep-all;
                }
            }
        }

        button {
            &.withdrawerbtn {
                width: 225px;
                height: 60px;
                background: #137abd;
                border-radius: 6px;
                font-size: 20px;
                color: #fff;

                @media screen and (max-width: 768px) {
                    margin: 0 auto;
                    width: 120px;
                    height: 45px;
                    font-size: 17px;
                }

                &.borderStyle {
                    border: 1px solid #00863C;
                    color: #00863C;
                    background: #fff;
                    margin-right: 15px;
                }
            }
        }
    }


}

.form__tit {
    &.type {
        display: block;
        line-height: 1em;
        padding-top: 5px;

        &>div {
            font-size: 18px;
        }

        small {
            font-size: 14px;
            color: #333;
        }

        @media screen and (max-width: 768px) {
            margin-bottom: 10px;
        }
    }
}

.dashType {
    align-items: center;

    .input {
        margin-right: 0 !important;
    }

    .dash {
        font-size: 15px;
        color: #a5a5a5;
        margin: 0 5px;
        margin-bottom: 10px;
    }
}


.organization_popup-content,
.organization_popup-overlay,
.email_popup-content,
.email_popup-overlay {
    z-index: 10000 !important;
    padding: 0 !important;
}

.organization_popup-content,
.email_popup-content {
    .modal__header {
        display: block;
        height: 50px;
        border-bottom: 1px solid #666;
        padding: 15px;

        button {
            width: 25px;
            height: 25px;
        }
    }

    .modal__body {
        display: block;
        padding: 15px;
    }

    .form__input {
        margin-right: 30px;
    }

    .form__tit {
        width: 200px;
    }

    .formBtn {
        width: 160px;
        height: 54px;
        background: #00863C;
        border-radius: 6px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 20px;
        color: #fff;
    }
}

.reg_logo {
    margin: 20px;
}


.authPopup {
    .inn {
        .login {

            //아이디저장 체크박스 변경
            input[type=checkbox]+.checkbox__icon {
                position: relative;
                margin: 0 5px 0 0;
                // padding: 0px 9.5px;
                color: #333;
            }

            // input[type=checkbox] + .checkbox__icon:after {
            //   content: "";
            //   position: absolute;
            //   top: 1px;
            //   left: 0;
            //   width: 17px;
            //   height: 17px;
            //   background: #fff;
            //   border-radius: 3px;
            //   border: 1px solid #ddd;
            // }

            // input:checked + .checkbox__icon {
            //   background: url('#{$image-path}/i-checkbox.png') #137bbd no-repeat 1px center;
            //   border-color: #137bbd;
            // }


            .checkboxItem {

                display: flex;
                align-items: center;

                .checkBoxName {
                    margin-left: 6px;
                }

                input[type='checkbox'] {
                    // display: none;
                    appearance: auto !important;
                    width: 18px;
                    height: 18px;
                }

                input[type='checkbox']+span {
                    display: inline-block;
                    position: relative;
                    cursor: pointer;
                    border: 1px solid #ddd;
                    width: 19px;
                    height: 19px;
                    border-radius: 50%;
                }


                input[type='checkbox']:checked+span {
                    display: inline-block;
                    position: relative;
                    cursor: pointer;
                    border: 1px solid #000;
                    width: 19px;
                    height: 19px;
                    border-radius: 50%;
                }

            }



        }
    }
}

.passwordFindBottom {

    margin-top: 15px;

    .flexBox {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
    }

    .authLiks {
        a {
            font-size: 14px;
            color: #696969;
        }

        .bar {
            font-size: 12px;
            color: #dddddd;
            margin: 0 15px;
        }
    }

}



// 이메일 인증 팝업

.email_popup-content {
    max-width: 600px;
    border-radius: 10px;

    .modal_header {
        // font-size: 20px;
        padding: 1rem;
        height: auto;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;

        h5 {
            position: relative;
            padding: 0 0 0 10px;
            line-height: 1.5;
            font-size: 1.15rem;

            @media (max-width: 768px) {
                font-size: 16px;
            }

            &:before {
                content: "";
                position: absolute;
                top: 4px;
                left: 0;
                width: 4px;
                height: 20px;
                background: $primary;

                @media (max-width: 768px) {
                    top: 2px;
                    height: 19px;
                }
            }
        }

        .overlay_close_x {
            &:after {
                font-family: Helvetica, Arial, sans-serif;
                content: '\00D7';
                font-size: 40px;
                color: #222;
                font-weight: 200;
                line-height: 0px;
            }

            position: absolute;
            top: 25px;
            right: 35px;
            background: none;
            width: 26px;
            height: 30px;
            text-decoration: none;
            opacity: .8;

            @media screen and (max-width: 768px) {
                right: 15px;
                top: 20px;
            }

            span {
                position: absolute;
                clip: rect(1px, 1px, 1px, 1px);
                overflow: hidden;
                height: 1px;
            }
        }
    }

    .body {
        padding: 1rem;

        .email_title {
            font-size: 1rem;
            color: #222;
            // padding-bottom: 10px;
            // border-bottom: 1px solid black;
            width: 100%;
            margin-bottom: 10px;

            @media (max-width: 767px) {
                font-size: 15px;
            }

            &.auth {
                margin-top: 10px;
            }
        }

        .input_group {
            margin: 20px 0 0;

            &:first-child {
                margin: 0;
            }

            .input_email_flex {
                display: flex;

                @media (max-width: 768px) {
                    flex-wrap: wrap;
                }
            }

            input {
                background: #fff;
                width: 100%;
                height: 100%;
                font-size: 17px;
                color: #333333;
                padding: 10px 20px;

                &#email_input {
                    max-width: calc(100% - 200px);
                    display: inline-block;

                    @media (max-width:768px) {
                        max-width: 100%;
                    }
                }
            }

            .code_send {
                // color: red;
                color: $primary;
                margin-top: 10px;
            }

            .validation_email_code {
                margin: 5px 0 0;
                color: $primary;

                label {
                    color: $primary;
                    vertical-align: middle;
                }
            }

            .inform {
                margin: 7px 0 0;
                font-size: 14px;
                color: #696969;

                &.email_info,
                &.pw_info {
                    padding: 0 0 0 24px;
                    line-height: 20px;
                    background: url('#{$image-path}/notiicon.svg') no-repeat left 1px;
                    background-size: 19px;
                }
            }
        }

        button {
            margin-left: 10px;
            background: #489659;
            border-radius: 6px;
            font-size: 1rem;
            color: #fff;
            padding: 10px 20px;

            @media (max-width:768px) {
                font-size: 15px;
                padding: 5px 10px;
                margin: 0;
                margin-top: 10px;
                width: 100%;
                min-height: 35px;
            }
        }

        .modifyAuth {
            margin-left: 10px;
            background: #339adc;
            border-radius: 6px;
            font-size: 1rem;
            color: #fff;
            padding: 3px 20px;
            width: 12rem;

            @media (max-width:768px) {
                font-size: 15px;
                padding: 5px 10px;
                margin: 0;
                margin-top: 10px;
                width: 100%;
            }
        }
    }

    .footer {
        padding: 10px;
        text-align: right;

        .btn {
            width: 135px;
            height: 45px;
            border-radius: 6px;

            @media (max-width: 425px) {
                width: 48%;
                height: 40px;
                font-size: 13px;
            }

            &.complete {
                background: $primary;
                color: white;
            }

            &.cancle {
                // background: #ca4e4e;
                // color: white;
                margin-left: 10px;
                color: $primary;
                border: 2px solid $primary;

                @media (max-width: 425px) {
                    margin-left: 4%;
                }
            }
        }
    }

    @media (max-width:768px) {
        width: 80% !important;

        .organization_popup-content {
            .body {
                #email_input {
                    max-width: 100%;
                }

                button {}
            }
        }
    }
}

.email_button_wrap {
    border: 1px solid #ddd;
    padding: 20px 0;

}

.email_check_notice {
    color: rgb(133, 133, 133);
    margin: 10px 0;
}

.email_input_case {
    text-transform: lowercase;
}

.code_send_red {
    color: rgb(255, 78, 78) !important;
    margin-top: 10px;
}