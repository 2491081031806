.displaynone {
  display: none !important;
}

.page-content-repository,
.page-content-metaverse,
.page-content-auditcontent,
.page-content-exellentcontent,
.programDetail {
  .lnb {
    display: none;
  }
}

.page-resource-evaluate {
  .collage-item {
    max-height: none;
  }
}

.program {
  .lnb {
    @media screen and (max-width: 768px) {
      display: block;
    }
  }

  // &:after {
  //   content: '';
  //   display: block;
  //   height: 20%;
  //   width: 100%;
  //   position: absolute;
  //   left: 0;
  //   top: 0;
  //   z-index: 1;
  //   /*background: linear-gradient(
  //     180deg,
  //     rgba( 237, 241, 209, 1) 0%,
  //     rgba(237, 241, 209, 0) 100%
  //   );*/
  // }

  .sub .sub__content {
    padding-left: 0;
    padding-right: 0;
    max-width: 100%;
  }
}


.programTable_wrap {
  border-radius: 4px;
  border: 1px solid #ddd;
  overflow: hidden;
}

.mobileChart {
  display: none;
  padding: 10px 15px;

  @media screen and (max-width: 1024px) {
    display: block;
  }

  .divBox {
    display: flex;
    padding: 8px 0;

    .leftBox {
      width: 80px;
      display: flex;
      align-items: center;
      justify-content: start;
      font-weight: 700;
      font-size: 1rem;
    }

    .rightBox {
      width: calc(100% - 90px);
      display: flex;
      align-items: center;
      justify-content: start;
      font-size: 1rem;

      .Notsubmit {
        background-color: #ABABAB;
        font-size: 0.85rem;
        line-height: 0.85rem;
        padding: 10px 15px;
        border-radius: 4px;
        color: #fff;
      }

      .submit {
        background-color: #00863C;
        font-size: 0.85rem;
        line-height: 0.85rem;
        padding: 10px 15px;
        border-radius: 4px;
        color: #fff;
      }

      .class_link {
        display: inline-block;
        background-color: #00863C;
        font-size: 0.85rem;
        line-height: 0.85rem;
        padding: 10px 15px;
        border-radius: 4px;
        color: #fff;
      }

      .cansubmit {
        background-color: #F46E43;
        font-size: 0.85rem;
        line-height: 0.85rem;
        padding: 10px 15px;
        border-radius: 4px;
        color: #fff;
      }

    }
  }
}



.propsWrap {
  flex-wrap: wrap;

  .p100_wrapper {
    width: 100%;
    flex-basis: 100%;
    margin: 5px 0;
    font-size: 16px;
  }
}




.programTable {
  width: 100%;
  text-align: center;
  // border: 1px solid #DDDDDD;

  @media screen and (max-width: 1024px) {
    display: none;
  }

  thead {
    padding: 10px 0;
    height: 50px;

    tr {
      line-height: 50px;
      border-bottom: 1px solid #333333;

      th {
        font-size: 0.9rem;

        &.title {
          width: 40%;
        }

        &.applyZone {
          width: 144px;

          &.max {
            width: 215px;
          }
        }

        &.studyZone {
          width: 130px;
        }

      }
    }
  }

  tbody {
    padding: 10px 0;
    height: 50px;

    tr {
      line-height: 50px;

      td {
        font-size: 0.9rem;
        padding: 10px;
        line-height: 1.1rem;
        vertical-align: middle;

        .Notsubmit {
          background-color: #ABABAB;
          font-size: 0.85rem;
          line-height: 0.85rem;
          padding: 10px 15px;
          border-radius: 4px;
          color: #fff;
        }

        .submit {
          background-color: #00B852;
          font-size: 0.85rem;
          line-height: 0.85rem;
          padding: 10px 15px;
          border-radius: 4px;
          color: #fff;
        }

        .cansubmit {
          background-color: #F46E43;
          font-size: 0.85rem;
          line-height: 0.85rem;
          padding: 10px 15px;
          border-radius: 4px;
          color: #fff;
        }

        .class_link {
          display: inline-block;
          background-color: #00863C;
          font-size: 0.85rem;
          line-height: 0.85rem;
          padding: 10px 15px;
          border-radius: 4px;
          color: #fff;
        }

        .preview_video {
          background-color: #fff;
          padding: 10px;
          border: 11px;
          background-color: black;
          color: white
        }
      }
    }
  }
}

.swal-modal {
  .swal-footer {
    text-align: center;
  }
}

.programDetail__teacher {
  display: flex;
  align-items: flex-start;

  @media screen and (max-width: 1220px) {}

  @media screen and (max-width: 768px) {
    display: block;
  }

  .thumb {
    width: 350px;
    flex: none;



    @media screen and (max-width: 1220px) {
      width: 200px;
    }

    @media screen and (max-width: 768px) {}
  }

  .info {
    flex: 1;
    padding-left: 2em;

    @media screen and (max-width: 1220px) {}

    @media screen and (max-width: 768px) {
      padding: 0;
      margin-top: 1em;
    }

    h4 {
      font-size: 1.875rem;
      font-weight: 400;

      @media screen and (max-width: 1220px) {
        font-size: 1.325rem;
      }

      @media screen and (max-width: 768px) {}

      strong {
        font-size: 1em;
        font-weight: 600;
      }
    }

    .history {
      margin-top: 1.5em;

      @media screen and (max-width: 1220px) {}

      @media screen and (max-width: 768px) {
        margin-top: 1em;
      }

      p {
        font-size: 1.125rem !important;
        line-height: 1.5em !important;
        font-weight: 600;

        @media screen and (max-width: 1220px) {}

        @media screen and (max-width: 768px) {
          font-size: 1rem;
        }
      }
    }

    .award {
      margin-top: 1.5em;

      @media screen and (max-width: 1220px) {}

      @media screen and (max-width: 768px) {
        margin-top: 1em;
      }

      li {
        display: flex;
        align-items: flex-start;
        line-height: 1.25em;
        font-size: 1.125rem;

        @media screen and (max-width: 1220px) {}

        @media screen and (max-width: 768px) {
          font-size: 1rem;
        }

        svg {
          margin-right: 0.5em;
        }

        &~li {
          margin-top: 0.5em;
        }
      }
    }
  }
}

.programDetail__process {
  margin-top: 1em;
}

.programDetail__processItem {
  background: $theme-white-3;
  border: 1px solid $theme-gray-10;
  padding: 0 2em;

  &~.programDetail__processItem {
    margin-top: 1em;
  }

  @media screen and (max-width: 1220px) {}

  @media screen and (max-width: 768px) {
    padding: 0 1em;
  }

  header {
    padding: 2em 0;
    display: flex;
    align-items: flex-start;

    @media screen and (max-width: 1220px) {}

    @media screen and (max-width: 768px) {
      display: block;
      padding: 1em 0;
    }

    .text {
      flex: 1;

      h1 {
        font-size: 1.375rem;
        font-weight: 600;

        @media screen and (max-width: 1220px) {
          margin-bottom: 5px;
          font-size: 1.25rem;
          line-height: 26px;
        }

        @media screen and (max-width: 768px) {}
      }

      p {
        font-size: 1rem !important;
      }
    }
  }

  ._progress {
    width: 350px;
    margin-left: 20px;
    flex: none;
    display: flex;
    height: 26px;
    align-items: center;

    @media screen and (max-width: 1220px) {
      width: 250px;
    }

    @media screen and (max-width: 768px) {
      margin-left: 0;
      margin-top: 0.5em;
      width: 100% !important;
    }

    .bar {
      flex: 1;
      height: 7px;
      background: $theme-gray-4;
      border-radius: 2em;
      overflow: hidden;

      span {
        display: block;
        height: 100%;
        width: 0px;
        background: $theme-red;
        overflow: hidden;
        border-radius: 2em;
      }
    }

    .per {
      font-size: 1.25rem;
      line-height: 1em;
      padding: 0 10px;
      flex: none;

      @media screen and (max-width: 1220px) {
        font-size: 1.125rem;
      }

      @media screen and (max-width: 768px) {}
    }

    img {
      vertical-align: top;
    }
  }

  .foot {
    margin: 0 -2em;

    @media screen and (max-width: 1220px) {}

    @media screen and (max-width: 768px) {
      margin: 0 -1em;
    }

    button {
      height: 40px;
      width: 100%;
      background: $theme-gray-3;
      color: $theme-black-3;
    }
  }

  .inner {
    padding: 2em 0;
    //display: none;
    border-top: 1px solid $theme-gray-10;

    @media screen and (max-width: 1220px) {}

    @media screen and (max-width: 768px) {
      padding: 1em 0;
    }

    .section {
      &~.section {
        margin-top: 2em;
      }

      h2 {
        font-size: 1.25rem;
        font-weight: 600;
        padding-left: 35px;

        @media screen and (max-width: 1220px) {
          line-height: 30px;
          font-size: 1.125rem;
        }

        @media screen and (max-width: 768px) {}
      }

      ul {
        margin-top: 1em;

        @media screen and (max-width: 1220px) {}

        @media screen and (max-width: 768px) {}
      }

      li {
        padding: 10px 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid $theme-gray-10;
        position: relative;

        @media screen and (max-width: 1220px) {}

        @media screen and (max-width: 768px) {
          display: block;
        }

        &.disabled {
          &:after {
            opacity: 0.2;
          }
        }

        p {
          font-size: 1.125rem;
          padding-left: 50px;
          line-height: 40px;
        }

        ._progress {
          width: 250px;
        }

        .bar {
          span {
            background: $theme-black-2;
          }
        }

        &:after {
          width: 40px;
          height: 40px;
          content: '';
          position: absolute;
          left: 0;
          top: 10px;
        }

        &:nth-child(1):after {
          background: url('#{$image-path}/program-detail-i2.png') no-repeat left center;
          background-size: 100% 100%;
        }

        &:nth-child(2):after {
          background: url('#{$image-path}/program-detail-i3.png') no-repeat left center;
          background-size: 100% 100%;
        }

        &:nth-child(3):after {
          background: url('#{$image-path}/program-detail-i4.png') no-repeat left center;
          background-size: 100% 100%;
        }
      }

      &.step1 h2 {
        background: url('#{$image-path}/program-detail-i1.png') no-repeat left center;
      }

      &.step2 h2 {
        background: url('#{$image-path}/program-detail-i5.png') no-repeat left center;
      }

      &.step3 h2 {
        background: url('#{$image-path}/program-detail-i6.png') no-repeat left center;
      }

      &.step4 h2 {
        background: url('#{$image-path}/program-detail-i14.png') no-repeat left center;
      }
    }
  }
}

.programDetail {

  .sub_content_Wrap {

    margin-top: 20px;

    .historyZone {
      display: flex;
      justify-content: flex-end;

      @media screen and (max-width: 1220px) {
        padding-right: 10px;
      }


    }
  }


  .sub .sub__content {
    padding-top: 10px;
    border-top: 1px solid $theme-black-2;
    margin-top: 10px;
  }

  .bg_gray_color {
    &>div {
      &>div {
        // padding: 10px 20px;
        font-size: 15px;
        // background: #F6F6F6;
        color: #696969;
      }
    }


    .programDetail__body {
      ._row.tablebt {
        margin-bottom: 65px;
      }

      border-top: 1px solid $theme-gray-1;
      padding: 4em 0;

      @media screen and (max-width: 1220px) {
        padding: 3em 0;
      }

      @media screen and (max-width: 768px) {
        padding: 2em 0;
      }

    }

    ._row {
      margin-bottom: 2em;

      @media screen and (max-width: 1220px) {}

      @media screen and (max-width: 768px) {}

      .img_btn_green {
        border-radius: 4px;
        width: 100%;
        padding: 10px;
        color: white;
        background-color: #489659;
      }

      .img_btn_gray {
        border-radius: 4px;
        width: 100%;
        padding: 10px;
        color: white;
        background-color: gray;
        margin-bottom: 10px;
      }

      .open_img {
        display: block;
        max-width: 100%;
        margin: auto;
        background-color: #fff;
        padding: 0;


        @media (max-width: 768px) {
          max-width: 100%;
          overflow-y: auto;
        }

        >div {
          @media (max-width: 768px) {
            min-width: 650px;
          }
        }
      }

      .close_img {
        display: none;
      }

      h5 {
        font-size: 1.375rem;
        font-weight: 600;
        margin-top: 1em;

        @media screen and (max-width: 1220px) {
          font-size: 1.25rem;
        }

        @media screen and (max-width: 768px) {}
      }

      h3 {
        font-size: 1.1rem;
        font-weight: 600;
        padding-left: 1em;
        position: relative;
        margin-bottom: 15px;
        margin-top: 25px;

        @media screen and (max-width: 1220px) {}

        @media screen and (max-width: 768px) {
          font-size: 1.3rem;
        }

        &:after {
          content: '';
          display: block;
          width: 0.7em;
          height: 0.7em;
          border: 3px solid #489659;
          border-radius: 50%;
          position: absolute;
          left: 0;
          top: 5px;
        }
      }

      p {
        font-size: 1rem;
        color: $theme-black-3;
        line-height: 1.4rem;
        padding-bottom: 25px;
        word-break: keep-all;
        text-align: justify;
        border-radius: 4px;
        padding: 20px;
        background: #F6F6F6;

        @media screen and (max-width: 1220px) {
          font-size: 1rem;
          line-height: 1.5em;
        }

        @media screen and (max-width: 768px) {}

        br {
          display: none;

          :after {
            content: '';
            display: block;
          }
        }
      }
    }
  }

  .programDetail__tab {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: 55px;

    @media screen and (max-width: 1220px) {
      margin-top: 40px;
    }

    @media screen and (max-width: 768px) {
      margin-top: 30px;
    }

    button {
      height: 50px;
      background: $theme-white-1;
      color: $black;
      border-top-left-radius: 3px;
      border-top-right-radius: 3px;
      width: 210px;
      font-size: 1.25rem;
      margin-right: 5px;

      &.active {
        color: $white;
        background: $theme-black-4;
        border: 2px solid $theme-black-4;
      }

      @media screen and (max-width: 1220px) {
        height: 40px;
        font-size: 1.125rem;
      }

      @media screen and (max-width: 768px) {
        width: auto;
        flex: 1;

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }

  .programDetail__top {
    border-bottom: 1px solid $theme-gray-1;
    border-radius: 5px;
    display: flex;
    align-items: center;
    padding: 0;
    padding-bottom: 10px;

    &.metaHeight {
      .info {
        min-height: 217px;

        @media screen and (max-width: 1220px) {
          min-height: auto !important;
        }

      }

      .infoDetail {
        @media screen and (max-width: 1220px) {
          padding-top: 15px !important;
        }
      }

      .info_cont {
        @media screen and (max-width: 1220px) {
          margin-bottom: 0 !important;
        }
      }
    }

    .bottomLogin {
      display: none;

      @media screen and (max-width: 768px) {
        display: block;
        grid-column-end: span 2;
        margin-top: 10px;
      }

      .addGreen {
        display: block;
        grid-column-end: span 2;
        font-size: 0.85rem;
        color: #00863C;
        padding: 6px 0;
        font-weight: 700;
        background-color: #fff;
        border: 1px solid #00863c;
        margin-bottom: 0;
        width: 100%;
        text-align: center;
        border-radius: 4px;
        margin-top: 12px;

        @media screen and (max-width: 550px) {
          margin-top: 0;
        }

      }

    }

    @media screen and (max-width: 1220px) {}

    @media screen and (max-width: 768px) {
      padding-top: 20px;
      display: grid !important;
      grid-template-columns: 260px calc(100% - 260px);

      .addGreen {
        display: block;
        grid-column-end: span 2;
        font-size: 0.85rem;
        color: #00863C;
        padding: 6px 0;
        font-weight: 700;
        background-color: #fff;
        border: 1px solid #00863c;
        margin-bottom: 0;
        width: 100%;
        text-align: center;
        border-radius: 5px;
        margin-top: 0 !important;
      }

      .actions {
        display: none !important;
      }
    }

    @media screen and (max-width: 550px) {
      display: block !important;
      padding-top: 10px;
    }

    .thumb {
      width: 386px;
      flex: none;
      margin-right: 30px;
      position: relative;

      &:hover {
        .preview_box {
          background: rgba(0, 0, 0, 0.50);
          align-items: center;
          justify-content: center;
        }
      }

      img {
        width: 100%;
        border-radius: 4px;
        object-fit: contain;
        display: flex;
      }

      .preview_box {
        position: absolute;
        cursor: pointer;
        z-index: 1;
        width: 100%;
        height: 100%;
        background: transparent;
        border-radius: 4px;
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;



        .preview_icon {
          width: 50px;
          height: 50px;
          margin:10px;

          >img {
            object-fit: contain;
            height: fit-content;
            width: fit-content;
          }
        }
      }

      @media screen and (max-width: 1220px) {
        width: 324px;
      }

      @media screen and (max-width: 768px) {
        width: 245px;
        margin-right: 15px;
        margin-left: 0;
      }

      @media screen and (max-width: 550px) {
        width: 100%;
        margin: 0;

        img {
          width: 100%;
          height: 200px;
          object-fit: cover;
        }
      }
    }

    .info {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      min-height: 215px;
      height: auto;

      @media screen and (max-width:1220px) {
        min-height: auto;
      }


      @media screen and (max-width: 550px) {
        margin-top: 10px;
      }

      h2 {
        font-size: 1.3rem;
        font-weight: 600;
        line-height: 1.125em;

        @media screen and (max-width: 1220px) {}

        @media screen and (max-width: 768px) {
          font-size: 1.25rem;
        }
      }

      p {
        font-size: 1.125rem;
        color: $theme-black-3;
        margin-top: 1em;

        @media screen and (max-width: 1220px) {}

        @media screen and (max-width: 768px) {
          font-size: 1rem;
        }
      }

      .actions {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        word-break: keep-all;


        @media screen and (max-width: 1220px) {
          margin-bottom: 0;

        }

        .addGreen {
          font-size: 0.85rem;
          color: #00863C;
          padding: 10px 0;
          font-weight: 700;
          background-color: #fff;
          border: 1px solid #00863c;
          margin-bottom: 0;
          width: 100%;
          text-align: center;
          border-radius: 5px;
          margin-top: 5px;

          @media screen and (max-width: 768px) {
            margin-top: 13px;
          }

        }


        .enrolled_study {
          height: 70px;
          background-color: #3ba3a3;
          color: $white;
          font-size: 1.5rem;
          border-radius: 3px;
          width: 150px;
          margin-right: 20px;

          @media screen and (max-width: 1220px) {
            height: 50px;
            font-size: 1.25rem;
          }

          @media screen and (max-width: 768px) {
            width: 25%;
            margin-right: 10px;
            height: 40px;
            font-size: 1.125rem;
          }
        }

        .class_link {
          height: 40px;
          margin-right: 10px;
          background: rgb(75, 128, 88);
          color: $white;
          //font-size: 1.48rem;
          font-size: 1rem;
          border-radius: 3px;
          padding: 8px 35px;
          vertical-align: middle;
          text-align: center;
          width: 140px;
          display: flex;
          align-items: center;
          justify-content: center;

          @media screen and (max-width: 1220px) {
            padding: 0;
            height: 40px;
          }

          @media screen and (max-width: 768px) {
            width: 50%;
            height: 36px;
            font-size: 1rem;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }


        .submit_alink {
          color: #fff;
          background: #489659;
          font-size: 1.2rem;
          border-radius: 3px;
          padding: 10px;
        }

        .submit {
          //height: 70px;
          height: 40px;
          //background: $theme-red;
          color: #fff;
          background: #489659;
          //font-size: 1.48rem;
          font-size: 1rem;
          border-radius: 3px;
          //width: 150px;
          width: 140px;

          &.submitCancle {
            background-color: #F46E43;
          }

          @media screen and (max-width: 1220px) {}

          @media screen and (max-width: 768px) {
            width: 50%;
            height: 35px;
            font-size: 1rem;
          }

          &:disabled,
          &[disabled] {
            background-color: #999999;
            color: white;
            cursor: default;
          }
        }

        .share {
          margin-left: 20px;
          border: 1px solid $theme-gray-1;
          border-radius: 3px;
          width: 70px;
          height: 70px;
          background: $theme-white-3 url('#{$image-path}/program-detail-i13.png') no-repeat center;
          text-indent: -9999px;
          overflow: hidden;

          @media screen and (max-width: 1220px) {
            height: 50px;
            width: 50px;
            background-size: auto 20px;
            margin-left: 10px;
          }

          @media screen and (max-width: 768px) {
            height: 40px;
            width: 40px;
            background-size: auto 15px;
          }

        }

        .download {
          margin-right: 10px;
          height: 70px;
          background: #e72e35;
          color: #fff;
          font-size: 1.5rem;
          border-radius: 3px;
          width: 150px;
        }
      }

      .infoDetail {
        padding-top: 30px;

        @media screen and (max-width: 1220px) {
          padding-top: 20px;
        }

        @media screen and (max-width: 550px) {}


        .total_1 {
          display: flex;
        }

        //margin-top: 5em;
        //border-bottom: 1px solid $theme-gray-1;




        .top_row {
          padding-bottom: 20px;
        }

        td {
          vertical-align: top;
        }

        ._row {
          display: flex;
          align-items: flex-start;
          font-size: 1.25rem;



          &.text_box {
            &.orange {
              text-align: center;
              background-color: #fd7e14;
              border-radius: 3px;
              color: white;
              display: block;
              padding: 1px;
              margin-bottom: 20px;
              width: 100%;
            }



          }

          .sub_row {
            margin-right: 50px;
            padding-top: 1px;
          }

          &.error {
            color: red;
          }

          @media screen and (max-width: 1220px) {
            font-size: 1rem;
          }

          @media screen and (max-width: 768px) {}

          &~._row {

            //margin-top: 1em;
            @media screen and (max-width: 1220px) {}

            @media screen and (max-width: 768px) {
              //margin-top: 0.5em;
            }
          }

          span,
          strong {
            font-size: 1.25rem;

            @media screen and (max-width: 1220px) {
              font-size: 1rem;
            }

            @media screen and (max-width: 768px) {}
          }

          span {
            color: $theme-red;
          }

          strong {
            width: 110px;
            flex: none;

            @media screen and (max-width: 1220px) {
              width: 70px;
            }

            @media screen and (max-width: 768px) {}
          }
        }

        &.info_cont {
          display: flex;
          flex-wrap: wrap;
          margin-bottom: 10px;

          @media (max-width: 1220px) {
            margin-bottom: 10px;
          }

          @media (max-width: 767px) {
            justify-content: flex-start;
          }

          @media (max-width: 550px) {
            margin-bottom: 10px;
          }

          dl {
            display: flex;
            margin: 0 0 10px;
            width: 49%;

            @media (max-width:1220px) {
              width: 100%;
              margin-bottom: 4px;
            }

            dt {
              width: 88px;
              line-height: 24px;
              font-size: 16px;


              @media (max-width: 758px) {
                font-size: 0.95rem;

              }

              @media (max-width: 550px) {
                line-height: 22px;
                width: 90px;
                font-size: 15px;
              }
            }

            dd {
              width: calc(100% - 88px);
              line-height: 24px;
              font-size: 16px;
              word-break: keep-all;


              @media (max-width: 1220px) {
                font-size: 0.95rem;
                line-height: 1.25rem;

              }


              @media (max-width: 550px) {
                width: calc(100% - 90px);
                line-height: 22px;
                font-size: 16px;
              }

              div {
                line-height: 24px;
                font-size: 16px;
                padding-right: 20px;

                @media (max-width: 1220px) {
                  font-size: 0.95rem;
                  line-height: 1.25rem;

                }

                @media (max-width: 550px) {
                  line-height: 22px;
                  font-size: 16px;
                }
              }
            }
          }
        }


      }
    }
  }
}

.graybtn {
  text-align: center;
  background-color: #DDD;
  border-radius: 4px;
  color: #696969;
  display: block;
  padding: 5px;
  font-size: 15px;
  width: 80%
}

// workshopDetail 디자인
.page-resource-competencyworkshop {
  margin-top: 10px;

  h2 {
    font-size: 1.5rem;
    font-weight: 600;
    position: unset;
  }

  .programDetail__body {
    border-top: 1px solid $theme-gray-1;
    padding: 0;

    @media screen and (max-width: 1220px) {
      padding: 3em 0;
    }

    @media screen and (max-width: 768px) {
      padding: 2em 0;
    }

    ._row {
      // margin-bottom: 2em;

      @media screen and (max-width: 1220px) {}

      @media screen and (max-width: 768px) {}

      // 소개
      h3+div {
        // border: 1px solid #e5e5e5;
        // padding: 40px 25px;
        word-break: keep-all;

        p {
          font-weight: bold;
        }
      }

      // 수강대상
      h3+p {
        background-color: #f7f7f7;
        padding: 40px 25px;
        word-break: keep-all;
        font-size: 16px;
      }

      .detail_intro {
        border: 1px solid #e5e5e5;
      }

      .img_btn_green {
        border: 1px solid #489659;
        border-radius: 5px;
        width: 100%;
        padding: 10px;
        color: #489659;
        font-weight: bold;
        background-color: white;
        font-size: 18px;

        .toggle_arrow {
          margin-left: 10px;
        }
      }

      .img_btn_gray {
        border: 1px solid gray;
        border-radius: 5px;
        width: 100%;
        padding: 10px;
        color: gray;
        /*background-color: gray;*/
        font-weight: bold;
        margin-bottom: 10px;
        font-size: 18px;

        @media screen and (max-width: 900px) {
          font-size: 14px;
        }

        .toggle_arrow {
          margin-left: 10px;
          transform: rotate(180deg);
        }
      }

      .open_img {
        display: block;

        span {
          p {
            img {
              border-radius: 4px;
            }
          }
        }
      }

      .close_img {
        display: none;
      }

      h5 {
        font-size: 1.375rem;
        font-weight: 600;
        margin-top: 1em;

        @media screen and (max-width: 1220px) {
          font-size: 1.25rem;
        }

        @media screen and (max-width: 768px) {}
      }

      h3 {
        font-size: 1.5rem;
        font-weight: 600;
        padding-left: 1em;
        position: relative;
        margin-bottom: 0.8em;
        margin-top: 60px;

        @media screen and (max-width: 1220px) {
          font-size: 1.325rem;
        }

        @media screen and (max-width: 768px) {}

        &:after {
          content: '';
          display: block;
          width: 0.7em;
          height: 0.7em;
          border: 5px solid #489659;
          border-radius: 50%;
          position: absolute;
          left: 0;
          top: 5px;
          //margin-top: 25px;
        }
      }

      p {
        font-size: 1.125rem;
        color: $theme-black-3;
        line-height: 1.75em;
        padding-bottom: 25px;

        @media screen and (max-width: 1220px) {
          font-size: 1rem;
          line-height: 1.5em;
        }

        @media screen and (max-width: 768px) {}
      }

      // 상세일정 및 신청하기
      .detail__body {
        width: 100%;
        height: 150px;
        // border-top: 1px solid;
        border-bottom: 1px solid #ddd;
        display: flex;

        .detail_one {
          width: 50%;

          .detail_title {
            @media screen and(max-width:768px) {
              border-top: 1px solid #ddd;
              border-bottom: 1px solid #ddd;

              &:nth-child(1) {
                border-top: none;
              }
            }
          }
        }

        .detail_two {
          width: 30%;
          // border-left: 1px solid #e5e5e5;
          // border-right: 1px solid #e5e5e5;
        }

        .detail_three {
          width: 20%;
        }

        .detail_title {
          // background-color: #f9f9f9;
          padding: 20px 0;
          text-align: center;
          font-weight: bold;
          border-bottom: 1px solid #333;

          @media screen and(max-width:820px) {
            border-top: 1px solid #ddd;
            border-bottom: 1px solid #ddd;
          }
        }

        .detail_info {
          text-align: center;
          padding: 10px 0;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          height: 80px;

          p {
            font-size: 15px;
            font-weight: 400;
            padding-bottom: 0;
            color: #333333;
          }

          span {
            font-size: 15px;
            font-weight: 400;
            color: #333333;
          }

          p span {
            font-size: 15px;
            font-weight: 400;
            color: #333333;
          }

          span img {
            margin-right: 3px;
          }
        }
      }

    }
  }

  ._row.error.text_box.orange {
    text-align: center;
    background-color: #fd7e14;
    border-radius: 3px;
    color: white;
    display: block;
    padding: 10px;
    //margin-bottom: 20px;
    margin-right: 5px;
    font-size: 0.9rem !important;
  }




  .workshop_detail_wrap {
    width: 100%;


    .introduction_content {
      max-width: 100% !important;

      h2 {
        @media screen and (max-width: 768px) {
          font-size: 1.2rem;
        }
      }

      .boardDetail {
        .consulting_title {
          @media screen and (max-width: 1220px) {
            padding: 25px 20px;
          }

          @media screen and (max-width: 768px) {
            padding: 13px 8px;
            font-size: 0.9rem;
          }
        }

        .consulting_sub {
          @media screen and (max-width: 768px) {
            width: 80%;
          }

          p {
            width: 100%;

            @media screen and (max-width: 1220px) {
              font-size: 13px;
              word-break: break-all;
            }
          }
        }
      }

      .programDetail__body {

        @media screen and (max-width: 1220px) {
          padding: 0;

        }

        @media screen and (max-width: 768px) {
          padding-top: 20px;

        }

        ._row {
          @media screen and (max-width: 768px) {
            // margin-bottom: 3em;

            h3 {
              @media screen and (max-width: 768px) {
                font-size: 1.05rem;
              }
            }
          }

          .img_btn_green {
            @media screen and (max-width: 900px) {
              font-size: 14px;
            }
          }
        }

        .detail__body {
          height: auto;
          padding: 0;
          border: 1px solid #ddd;
          border-radius: 4px;

          @media screen and (max-width: 900px) {
            padding: 0;
            display: block;
          }

          .detail_one {
            @media screen and (max-width: 900px) {
              width: 100%;
            }

            .detail_title {
              @media screen and (max-width: 900px) {
                font-size: 1.05em;
              }

              @media screen and (max-width: 768px) {
                padding: 10px 0;
              }
            }

            .detail_info {
              p {
                @media screen and (max-width: 900px) {
                  font-size: 1.05em;
                }
              }
            }
          }

          .detail_two {
            @media screen and (max-width: 900px) {
              width: 100%;
            }

            .detail_title {
              @media screen and (max-width: 900px) {
                font-size: 1.05em;
              }

              @media screen and (max-width: 768px) {
                padding: 10px 0;
              }
            }

            .detail_info {
              p {
                @media screen and (max-width: 900px) {
                  font-size: 1.05em;
                }
              }
            }
          }

          .detail_three {
            @media screen and (max-width: 900px) {
              width: 100%;
            }

            .detail_title {
              @media screen and (max-width: 900px) {
                font-size: 1.05em;
              }

              @media screen and (max-width: 768px) {
                padding: 10px 0;
              }
            }

            .detail_info {
              p {
                @media screen and (max-width: 900px) {
                  font-size: 1.05em;
                }
              }
            }
          }

        }
      }

    }

    @media screen and (max-width: 768px) {
      padding-top: 30px;
      padding-left: 10px;
      padding-right: 10px;
    }

    .workshop_detail_info {
      .programDetail__body {
        ._row {
          h3 {
            @media screen and (max-width: 768px) {
              margin-top: 10px;
            }
          }
        }
      }
    }

  }


  .detail_bottom {
    position: relative;
    text-align: center;
    margin-top: 40px;
  }

  .application_button {
    background-color: #1a9952;
    color: white;
    border-radius: 4px;
    display: block;
    padding: 5px;
    font-size: 15px;
    width: 80%;

    @media screen and (max-width: 768px) {
      font-size: 1.1rem;
      padding: 9px 40px;
    }
  }


  //csvbutton 데이터 보기
  .csv_button {
    position: absolute;
    left: 0;
    color: #fff;
    font-weight: 700;
    background: #00863C;
    border-radius: 5px;
    padding: 8px 25px;
  }

  .csv_button_area {
    position: absolute;
    padding: 8px 25px;
    display: block;
    top: 71px;
    background-color: #3E3E3E;
    color: #fff;
    font-weight: 700;
    border-radius: 5px;

    &:hover {
      background-color: rgb(65, 65, 65);
      color: #fff;
    }
  }

  .apply_user_data_wrap {
    width: 100%;
    margin-bottom: 40px;

    &:after {
      display: block;
      visibility: hidden;
      clear: both;
      content: '';
    }

    .button_container {
      display: block;
      width: 100%;
      text-align: center;
      float: left;

      .change_button {
        background-color: white;
        color: #696969;
        padding: 10px 30px;
        font-size: 16px;
        font-weight: bold;
        border: 1px solid #696969;
        border-radius: 4px;
        margin-top: 30px;
      }

      .survey_result_button {
        background-color: #3E3E3E;
        color: white;
        padding: 10px 30px;
        font-size: 16px;
        font-weight: bold;
        border-radius: 4px;
        margin-top: 30px;
        margin-left: 10px;
      }
    }

    .table_wrap {
      width: 100%;
      overflow-x: auto;
    }

    .apply_user_data_table {

      // margin-top: 30px;
      border: 1px solid #ddd;
      margin-right: 0.5%;
      // border: 1px solid #e9e9e9;
      width: 100%;
      border-bottom: 1px solid #e9e9e9;
      min-width: 600px;


      @media screen and (max-width: 768px) {
        width: 100%;
        margin-right: 0;
      }

      .email_sec {
        width: 200px;

        @media screen and (max-width: 768px) {
          width: auto;
        }
      }

      thead tr td {
        font-weight: 700;
        // background-color: #ddefff;
        border: none;
        border-bottom: 1px solid #ddd;
        // border-top: 1px solid #3c8fd6;
        padding: 14px 6px;
        font-size: 0.9rem;
        color: #333333;
      }

      tr td {
        border-bottom: 1px solid #ddd;
        padding: 10px;
        font-size: 0.8rem;
        vertical-align: middle;
        word-break: break-all;
        color: #696969;
      }

      tr td:nth-child(1) {
        width: 36px;
      }

    }
  }

  .workshop_edit_zone {
    position: relative;
    display: block;
    margin-top: 70px;
    padding-top: 20px;
    border-top: 1px solid #929292;
    text-align: right;

    .workshop_form_bodify_button {
      padding: 5px 20px;
      background-color: #F46E43;
      color: #fff;
      margin-right: 8px;
      border-radius: 4px;
    }

    .workshopform_delete_button {
      padding: 5px 20px;
      background-color: #F46E43;
      color: #fff;
      border-radius: 4px;
    }

  }


  .survey_info {
    font-size: 1rem;
    text-align: right;
    margin-top: 5px;
  }

  a {
    &.none {
      cursor: none;
      pointer-events: none;
      padding: 5px;
      font-size: 15px;
      width: 80%;
      border-radius: 3px;
      background-color: #d0d0d0;

      .work_survey_btn {
        background: #d0d0d0;
        cursor: none;
        pointer-events: none;
      }
    }

    &.work_survey_btn_link {
      background-color: #6eace5;
      display: block;
      padding: 5px;
      font-size: 15px;
      width: 80%;
      border-radius: 3px;
    }
  }

  .work_survey_btn {
    color: white;
    font-weight: bold;

    @media screen and (max-width: 768px) {
      font-size: 1.1rem;
      padding: 9px 40px;
    }

  }

  .work_certi_btn {
    background-color: #489659;
    color: white;
    font-weight: bold;
    display: block;
    padding: 5px;
    font-size: 15px;
    width: 80%;
    border-radius: 3px;

    @media screen and (max-width: 768px) {
      font-size: 1.1rem;
      padding: 9px 40px;
    }

    &.disabled {
      background-color: #d0d0d0;
    }
  }

  .apply_cancel_btn {
    background-color: #6eace5;
    color: white;
    padding: 15px 60px;
    font-size: 18px;
    font-weight: bold;
    border-radius: 3px;

    @media screen and (max-width: 768px) {
      font-size: 1.1rem;
      padding: 9px 40px;
    }
  }

  .workshop_cancel_btn {
    background-color: white;
    color: black;
    padding: 15px 60px;
    font-size: 18px;
    font-weight: bold;
    border: 1px solid #e4e4e4;
    border-radius: 3px;
    margin-left: 10px;
    margin-top: 20px;

    @media screen and (max-width: 768px) {
      font-size: 1.1rem;
      padding: 9px 40px;
    }
  }

}

// 수강신청 취소 swal 디자인

/*.swal-overlay--show-modal .swal-modal {
  padding: 30px;

  .swal-title:not(:last-child) {
    border-bottom: 1px solid #e5e5e5;
    text-align: left;
    padding : 20px 0;
    margin: 0 15px;
  }

  .swal-button-container {
    margin: 0;
    display: inline-block;
    position: relative;
  }
  .swal-footer{
    padding: 20px 16px;
  }

  .swal_ok {
    width: 180px;
    background: #6eace5;
    border: 1px solid #6eace5;
    color: white;
    font-weight: 600;
    font-size: 18px;
    padding: 10px 24px;
    margin-right: 20px;
  }

  .swal_close {
    width: 180px;
    background: white;
    border: 1px solid #e4e4e4;
    color: black;
    font-weight: 600;
    font-size: 18px;
    padding: 10px 24px;
  }
}*/

//역량강화워크숍 디자인
.workshop_action {

  .actions_item {
    display: flex;
    margin: 0 auto;
    align-items: center;

    .submit {
      height: 42px;
      background: darkblue;
      color: #fff;
      font-size: 0.95rem;
      font-weight: 700;
      border-radius: 3px;
      width: 140px;
    }

  }

  ._row {
    margin-bottom: 2em;

    @media screen and (max-width: 1220px) {}

    @media screen and (max-width: 768px) {}


    .img_btn_green {
      border-radius: 5px;
      width: 100%;
      padding: 10px;
      color: white;
      background-color: #489659;
    }

    .img_btn_gray {
      border-radius: 5px;
      width: 100%;
      padding: 10px;
      color: white;
      background-color: gray;
      margin-bottom: 10px;
    }

    &.text_box {
      &.orange {
        text-align: center;
        background-color: #fd7e14;
        border-radius: 4px;
        color: white;
        display: block;
        padding: 5px;
        font-size: 15px;
      }

    }

    .open_img {
      display: block;
      border-radius: 4px;
    }

    .close_img {
      display: none;
    }

    h5 {
      font-size: 1.375rem;
      font-weight: 600;
      margin-top: 1em;

      @media screen and (max-width: 1220px) {
        font-size: 1.25rem;
      }

      @media screen and (max-width: 768px) {}
    }

    h3 {
      font-size: 1.5rem;
      font-weight: 600;
      padding-left: 1em;
      position: relative;
      margin-bottom: 0.5em;
      margin-top: 25px;

      @media screen and (max-width: 1220px) {
        font-size: 1.325rem;
      }

      @media screen and (max-width: 768px) {}

      &:after {
        content: '';
        display: block;
        width: 0.7em;
        height: 0.7em;
        border: 3px solid #489659;
        border-radius: 50%;
        position: absolute;
        left: 0;
        top: 5px;
        //margin-top: 25px;
      }
    }

    p {
      font-size: 1.125rem;
      color: $theme-black-3;
      line-height: 1.75em;
      padding-bottom: 25px;

      @media screen and (max-width: 1220px) {
        font-size: 1rem;
        line-height: 1.5em;
      }

      @media screen and (max-width: 768px) {}
    }
  }


  .actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    word-break: keep-all;

    .enrolled_study {
      height: 70px;
      background-color: #3ba3a3;
      color: $white;
      font-size: 1.5rem;
      border-radius: 3px;
      width: 150px;
      margin-right: 20px;

      @media screen and (max-width: 1220px) {
        height: 50px;
        font-size: 1.25rem;
      }

      @media screen and (max-width: 768px) {
        width: 100%;
        max-width: 300px;
        margin-right: 10px;
        height: 40px;
        font-size: 1.125rem;
      }


    }
  }

  .class_link {
    height: 40px;
    margin-right: 10px;
    background: #00863C;
    color: $white;
    //font-size: 1.48rem;
    font-size: 1rem;
    border-radius: 3px;
    padding: 17px 35px;
    vertical-align: middle;
    text-align: center;
    width: 140px;

    @media screen and (max-width: 1220px) {
      height: 50px;
      font-size: 1.2rem;
    }

    @media screen and (max-width: 768px) {
      width: 100%;
      max-width: 130px;
      height: 40px;
      font-size: 1.125rem;
      line-height: 0.5rem;
    }
  }

  .submit {
    //height: 70px;
    height: 60px;
    //background: $theme-red;
    background: darkblue;
    color: $white;
    //font-size: 1.48rem;
    font-size: 1.2rem;
    border-radius: 3px;
    //width: 150px;
    width: 140px;

    @media screen and (max-width: 1220px) {
      height: 50px;
      font-size: 1.2rem;
    }

    @media screen and (max-width: 768px) {
      width: 100%;
      max-width: 130px;
      height: 40px;
      font-size: 1.125rem;
      line-height: 0.5rem;
    }

    &:disabled,
    &[disabled] {
      background-color: #999999;
      color: white;
      cursor: default;
    }
  }

  .share {
    margin-left: 20px;
    border: 1px solid $theme-gray-1;
    border-radius: 3px;
    width: 70px;
    height: 70px;
    background: $theme-white-3 url('#{$image-path}/program-detail-i13.png') no-repeat center;
    text-indent: -9999px;
    overflow: hidden;

    @media screen and (max-width: 1220px) {
      height: 50px;
      width: 50px;
      background-size: auto 20px;
      margin-left: 10px;
    }

    @media screen and (max-width: 768px) {
      height: 40px;
      width: 80px;
      background-size: auto 15px;
    }
  }
}

//역량강화워크숍 내부 정보페이지
.workshop_detail_info {
  .consulting_detail_button li {
    cursor: default;

    @media screen and (max-width: 768px) {
      line-height: 40px;
      height: 43px;
      font-size: 1.1em;
    }
  }

  .consulting_detail_button li.active {
    border-bottom: 1px solid #cccccc;
  }

  .boardDetail {
    border-top: none;

    .boardDetail__content {
      margin: 8px 0 20px 0;
      padding: 20px !important;
      background-color: #f4f4f4;
    }
  }

}

.workshop_board {
  padding-left: 70px !important;

  @media screen and (max-width: 1220px) {
    padding-left: 30px !important;

    .sub__title {
      // margin-left:0 !important;
      // margin-right:0 !important;
    }
  }


  @media screen and (max-width: 768px) {
    padding-left: 0 !important;
  }

}

.workshop_detail_wrap {}




// 신청하기 모달창 //
//.header {
//  z-index: 50 !important;
//}
.popup_overlay {
  position: fixed;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  z-index: 999;
  -webkit-animation: dialog-fadein 0.5s;
  animation: dialog-fadein 0.5s;

  .popup_content {
    //-webkit-animation: dialog-flyin .5s;
    max-width: 80%;
    animation: dialog-fadein 0.5s;
    position: relative;
    background: #fff;
    width: 100%;
    margin: auto;
    border: 1px solid rgb(187, 187, 187);
    max-width: 1280px;
    overflow: auto;
    height: 100%;
    max-height: 840px;
    border-radius: 20px;

    &.keywordPop {
      max-width: 660px;
      max-height: 564px;

      .modal_body {
        padding-bottom: 20px !important;

        .formBox {
          position: relative;

          .formTitle {
            color: #868686;
            font-size: 0.95rem;
            margin-bottom: 4px;
            display: flex;
            align-items: center;
            justify-content: start;

            .closeButton {
              width: 16px;
              height: 16px;
              cursor: pointer;
              margin-left: 8px;
              transform: translateY(-2px);

              img {
                width: 100%;
              }
            }
          }


          .formInput {
            input {
              width: 100%;
              border: 1px solid #D2D4D6;
              border-radius: 5px;
              height: 40px;
              padding: 10px;
            }

            .errorMessage {
              font-size: 0.85rem;
              margin-top: 4px;
              color: #ff155f;
            }
          }



          &~.formBox {
            margin-top: 15px;
          }
        }


        .customAddButto {

          width: 197px;
          height: 40px;
          background-color: #FBC12A;
          color: #333333;
          font-size: 0.9rem;
          font-weight: 600;
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 0 auto;
          margin-top: 30px;
          border-radius: 10px;
          cursor: pointer;
        }


        .submitButton {
          margin-left: auto;
          width: 160px;
          height: 40px;
          background-color: #00863C;
          color: #fff;
          font-size: 0.9rem;
          font-weight: 600;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-top: 30px;
          border-radius: 10px;
        }

      }
    }

    &.survey {
      // max-width: 100%;
      // max-height: 100%;
      max-width: calc(100% - 10px);
      max-height: calc(100% - 10px);
    }

    &.coursePop {
      height: fit-content;
    }

    @media screen and (max-width: 768px) {
      max-height: 480px;
      min-width: 330px;
    }

    &.sub {
      max-height: 410px;
    }

    &.workshop {
      max-height: 853px;
    }

    &.worksurvey_height {
      max-height: 735px;
    }

    &.survey {
      // max-width: 100%;
      // max-height: 100%;

      ._modal__footer {
        border-top: 0;
        padding: 20px 0;

        ._modal__actions {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          margin: 0;
          position: relative;

          // 푸터 버튼
          button {
            width: 140px;
            height: 40px;
            border-radius: 3px;
            background: #ACDBA4;
            color: #5C8D72;
            margin-left: 10px;

            // &::before {
            //   content: '';
            //   width: 17px;
            //   height: 17px;
            //   display: inline-block;
            //   background: url('#{$image-path}/modal-btn-cancel.png') no-repeat center;
            //   vertical-align: middle;
            //   margin-right: 5px;
            // }

            // 푸터 버튼 - 저장
            // &.submit {
            //   color: #fff;
            //   background: #55B87A;

            //   &::before {
            //     background: url('#{$image-path}/modal-btn-submit.png') no-repeat center;
            //   }
            // }

            // 푸터 버튼 - 삭제
            &.bg-danger {
              &.text-white {
                color: #fff;
                background: #1A9952 !important;

                &::before {
                  width: 18px;
                  height: 20px;
                  background: url('#{$image-path}/modal-btn-delete.png') no-repeat center;
                }
              }
            }
          }
        }
      }

      .survey {

        .survey_del_btn,
        .survey-remove,
        .survey_arrow {
          img {
            width: 11px;
            height: 11px;
          }
        }

        .survey__select-type {
          display: flex;
          padding: 40px 0;

          .survey__select-type-item {
            flex: 1 1;
            border-radius: 5px;
            padding: 40px 0;
            height: 250px;
            text-align: center;
            background: #f2f2f2;
            width: 25%;
          }
        }

        ._btn {
          background: #ACDBA4;
          border: 1px solid #ACDBA4;
          color: #5C8D72;
          font-weight: 500;
          border-radius: 13px;
          height: 40px;
          padding: 0 30px;
          margin: auto;
        }

        .survey__content-item {

          border: 1px solid #ddd;
          border-radius: 5px;
          overflow: hidden;

          &.active {
            border: 2px solid #00B852 !important;
            border-radius: 4px !important;
          }

          ._modal__block-body {
            padding: 20px !important;

            &.survey_type,
            &.survey_content {
              .insertForm__name {
                font-family: 'Pretendard';
                font-style: normal;
                font-weight: 500;
                font-size: 13px;
                line-height: 37px;
                /* identical to box height, or 285% */

                display: flex;
                align-items: center;

                /* 696969 */

                color: #696969;
              }

              .insertForm__content {
                .survey_type_div {
                  background: #F8F8F8;
                  border: 1px solid #DDDDDD;
                  border-radius: 4px;
                  padding: 9px;
                  font-family: 'Pretendard';
                  font-style: normal;
                  font-weight: 400;
                  font-size: 15px;
                  line-height: 18px;
                  color: #696969;
                }
              }
            }


            &.survey_options {
              //옵션 보기 입력
              border-top: 1px dashed #DDDDDD;
              background: #F8F8F8;

            }
          }

          &.draggable {}

          &~.survey__content-item {
            margin-top: 10px;
          }
        }

        .survey__select-type-item {
          flex: 1;
          border-radius: 5px;
          padding: 40px 0;
          text-align: center;
          background: #f2f2f2;

          &~.survey__select-type-item {
            margin-left: 10px;
          }

          .img {
            height: 60px;
            display: flex;
            align-items: center;
            justify-content: center;
          }

          p {
            margin-top: 10px;
          }

          .custom-checkbox {
            width: 18px;
            margin: 0 auto;
            margin-top: 20px;

            span {
              margin: 0;
              background: #fff;
            }
          }
        }

        .survey__header {
          height: 80px;
          border-bottom: 1px solid #ddd;
          display: flex;
          align-items: center;
          justify-content: space-between;
        }

        .survey__title {
          font-size: 1.5rem;
          font-weight: 600;
        }

        .survey__info {
          margin-left: auto;
          display: flex;
          align-items: center;

          .block {
            display: flex;
            align-items: center;
            color: #666666;
          }

          .block--clock {
            margin-left: 40px;

            strong {
              padding-left: 2em;
              background: url('#{$image-path}/i-clock.png') no-repeat left center !important;
            }

            .clock {
              display: flex;
              align-items: center;

              i {
                font-style: normal;
                margin: 0 5px;
              }

              span {
                width: 45px;
                height: 35px;
                line-height: 33px;
                border-radius: 5px;
                text-align: center;
                border: 1px solid #ddd;
              }
            }

          }

          strong {
            color: #000000;
            margin-right: 5px;
          }

        }

        .survey__state {
          display: flex;
          align-items: center;
          height: 100px;
          padding: 0 30px;
          position: relative;

          .btn {
            display: flex;
            align-items: center;
            color: #666666;
            line-height: 40px;
            padding: 0 20px;
            border-radius: 5px;
            border: 1px solid #666;

            svg {
              margin-right: 5px;
            }
          }

          .right {
            display: flex;
            align-items: center;

            .btn {
              margin-left: 10px;
            }
          }

          .left {
            margin-right: auto;
            display: flex;
            align-items: center;

            .btn {
              border-color: #2e8bc5;
              color: #2e8bc5;
              margin-right: 10px;

              &.active {
                background: #2e8bc5;
                color: #fff;
              }
            }
          }

          .center {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            display: flex;
            align-items: center;

            .survey__progress {
              display: flex;
              align-items: center;

              strong {
                font-weight: 600;
                font-size: 1.25rem;
              }

              .bar {
                width: 200px;
                background: #e5eaf1;
                border-radius: 2em;
                overflow: hidden;

                span {
                  border-radius: 2em;
                  display: block;
                  width: 0;
                  background: #2e8bc5;
                  height: 5px;
                }
              }

              .state {
                display: flex;
                margin: 0 10px 0 20px;

                span {
                  color: #2e8bc5;
                  ;
                }
              }
            }

            p {
              padding: 0 20px;
              line-height: 1em;
              font-size: 1.25rem;

              &~p {
                border-left: 1px solid #ddd;
              }

              strong {
                margin-right: 10px;
                font-size: 1.25rem;
              }

              span {
                color: #2e8bc5;
                ;
              }
            }
          }
        }

        .survey__body {
          padding-bottom: 30px;

          .survey__body-content {
            background: #999;
            padding: 30px;
            display: flex;
          }

          .survey__content-block-list {
            padding: 30px 0 100px;
            min-height: 100%;
            position: relative;

            .survey__controller {
              display: flex;
              align-items: center;
              justify-content: space-between;
              position: absolute;
              left: 0;
              bottom: 30px;
              width: 100%;

              .control-btn {
                display: flex;
                align-items: center;

                span {
                  width: 40px;
                  height: 40px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  border: 1px solid #ddd;
                  border-radius: 5px;
                }

                p {
                  color: #666;
                  font-size: 14px;
                  margin: 0 10px;
                }
              }
            }
          }

          .survey__content-block {
            h2 {
              font-size: 1rem;
              font-weight: 600;
              margin-bottom: 15px;
            }

            &~.survey__content-block {
              margin-top: 40px;
            }
          }

          .survey__body-answer {
            background: #fff;
            border-radius: 5px;
            overflow: hidden;
            flex: 1;
            width: 320px;
            flex: none;
            margin-left: 10px;
          }

          .survey__body-inner {
            display: flex;
            border-radius: 5px;
            overflow: hidden;
            flex: 1;
          }

          .survey__content {
            flex: 1;
            background: #fff;
            min-height: 530px;
            position: relative;
            padding: 0 40px;
          }

          .survey__init {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);

            .img {
              text-align: center;
              margin-bottom: 1.5em;
            }


          }

          .survey__nav {
            width: 80px;
            flex: none;
            background: #dfeaf2;

            &.displaynone {
              display: none;
            }
          }

          .survey__nav-state-img {
            height: 60px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 40px;
            color: #adbfcc;
          }

          .survey__nav-list {
            .survey__nav-item {
              position: relative;
              width: 40px;
              height: 40px;
              display: block;
              margin: 0 auto;

              i {
                position: absolute;
                z-index: 5;
                background: #fff;
                color: #2e8bc5;
                width: 15px;
                height: 15px;
                border-radius: 3px;
                right: -3px;
                top: -3px;

                svg {
                  vertical-align: top;
                }
              }

              &~.survey__nav-item {
                margin-top: 20px;
              }

            }

            button {
              width: 100%;
              height: 100%;
              background: #2e8bc5;
              color: #fff;
              border-radius: 50%;
              font-size: 12px;
              position: relative;

              &.number {
                background: none;
                border: 3px solid #2e8bc5;
                color: #2e8bc5;

                &:disabled {
                  color: #adbfcc;
                  border-color: #adbfcc;
                }
              }

              span {
                font-size: 20px;
              }

              &.more {
                background: #adbfcc;
              }

            }
          }
        }
      }


      .display {
        display: block;

        .reg_form {
          .survey {
            .survey__body {
              padding: 20px 0;

              .survey__body-content {
                background: none;
                padding: 20px;
                border-radius: 13px;
                border: 1px solid #ddd;

                .survey__select-type-item {
                  border-radius: 13px;
                  background: #F6F7FB;
                }

                // 문제형식을 골라주세여
                .survey_pick {
                  text-align: center;
                  margin-top: 30px;

                  &::before {
                    content: '';
                    display: inline-block;
                    width: 35px;
                    height: 25px;
                    margin-right: 10px;
                    vertical-align: middle;
                    background: url('#{$image-path}/test-icon-gj.png') no-repeat center;
                  }
                }

                .flexBox {
                  display: flex;
                  overflow: auto;

                  .survey-option-item2 {
                    position: relative;
                    padding: 20px;
                    max-width: 180px;
                    // border-right: 1px solid #ddd;

                    // input {
                    //   display: block;
                    //   margin: 0 auto;
                    //   width: 90%;
                    //   height: 30px;
                    //   font-size: 13px;
                    //   color: #696969;
                    //   text-align: center;
                    //   border: 1px solid #ddd;
                    //   border-radius: 5px;
                    // }
                    .top_flex_wrap {
                      display: flex;
                      justify-content: space-between;
                      align-items: center;
                      width: 100%;
                      height: 30px;
                      font-size: 13px;
                      color: #696969;
                      text-align: center;
                      background: #E7F0EA;
                      border: 1px solid #ddd;
                      border-bottom: none;
                      border-radius: 3px 3px 0px 0px;
                    }

                    .text {
                      display: block;
                      // margin: 0 auto;
                      width: 100%;
                      height: 30px;
                      font-size: 13px;
                      color: #696969;
                      text-align: center;
                      background: #fff;
                      border: 1px solid #ddd;
                      border-bottom: none;
                      border-radius: 0px 0px 0px 0px;
                    }

                    .number {
                      display: block;
                      // margin: 0 auto;
                      width: 100%;
                      height: 30px;
                      font-size: 13px;
                      color: #696969;
                      text-align: center;
                      background: #ffffff;
                      border-radius: 0px 0px 3px 3px;
                      border: 1px solid #dddddd;

                    }

                    .survey-remove {

                      margin: 0 0 0 10px
                    }

                    .survey-delete {
                      margin: 0 10px 0 0;
                    }

                    // .survey-delete {
                    //   position: absolute;
                    //   top: 8px;
                    //   right: 90px;
                    //   margin: 0 0 0 10px;
                    // }

                    &.none {
                      display: block;

                      // background-color: #ff000080;
                      .top_flex_wrap {

                        background-color: #DDDDDD;
                      }
                    }
                  }

                  .survey-buttons {
                    margin-left: auto;

                    button {
                      &::before {
                        content: "+";
                        display: block;
                        height: 20px;
                        line-height: 20px;
                      }
                    }

                    ._btn.bg-none {
                      background: none;
                      border: none;
                      font-size: 15px;
                    }
                  }
                }

                .survey_ex_wrap {
                  .insertForm__content {
                    .flexBox {
                      align-items: center;
                      margin: 0 0 25px;
                      border: 1px solid #ddd;
                      // border-bottom: none;
                      border-radius: 4px;
                      background-color: #ffffff;
                    }

                    .columnBar {
                      width: 1px;
                      height: 70px;
                      background-color: #ddd;
                    }

                    .add_row_btn {
                      height: 80px;
                      display: flex;
                      border: 1px dashed #DDDDDD;
                      border-radius: 4px;
                      margin-right: 20px;
                    }
                  }

                  .flex {

                    &.flexWrap {
                      padding: 12px;
                      border: 1px solid #ddd;
                      border-radius: 13px 13px 0 0;

                      h3 {
                        margin: 0 10px 0 0 !important;
                        padding: 0;
                        width: 70px;
                        line-height: 25px;
                        font-size: 13px;
                        color: #fff;
                        font-weight: 600;
                        text-align: center;
                        background: #ACDBA4;
                        border-radius: 200px;
                      }

                      input {
                        padding: 0 10px;
                        width: 100%;
                        height: 40px;
                        border: 1px solid #ddd;
                        border-radius: 5px;
                      }

                      &+div {
                        display: flex;
                        border: 1px solid #ddd;
                        border-top: 0;
                        border-radius: 0 0 4px 4px;

                        &.none {
                          display: none;
                        }

                        >div {
                          padding: 20px 0;
                          min-width: 120px;
                          text-align: center;
                          display: flex;
                          justify-content: center;
                        }

                        label {
                          display: block;
                          margin: 0 0 0 10px;
                          line-height: 25px;
                          font-size: 13px;
                          color: #696969;
                        }
                      }
                    }

                    h3 {
                      margin: 0 10px 0 0 !important;
                      padding: 0;
                      width: 70px;
                      line-height: 25px;
                      font-size: 13px;
                      color: #fff;
                      font-weight: 600;
                      text-align: center;
                      background: #ACDBA4;
                      border-radius: 200px;
                    }

                    input {
                      padding: 0 10px;
                      width: 100%;
                      height: 40px;
                      border: 1px solid #ddd;
                      border-radius: 5px;
                    }
                  }

                  .edit_wrap {
                    width: 100%;

                    .editor {
                      justify-content: center;
                      display: flex;
                    }

                    div {
                      textarea {
                        padding: 10px 15px;
                        height: 64px;
                        resize: none;
                        width: 100%;
                        border: 1px solid #ddd;
                        border-radius: 5px;
                        font-size: 14px;
                        color: #666666;
                      }
                    }
                  }

                  .survey-remove {
                    margin: 0 10px 0 10px;
                    width: 20px;
                  }

                  .survey-option-item {
                    margin: 15px 0;

                    &.none {
                      display: block;
                      // background-color: #ff000080;
                      // border-radius: 20px;
                    }

                    .survey-option-body {
                      display: -webkit-flex;
                      display: flex;
                      -webkit-align-items: center;
                      align-items: center;
                      padding: 15px;


                      .survey-table-item-title-wrap {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;

                        .survey-table-item-title {
                          font-family: 'Pretendard';
                          font-style: normal;
                          font-weight: 500;
                          font-size: 13px;
                          line-height: 37px;
                          /* identical to box height, or 285% */

                          display: flex;
                          align-items: center;

                          /* 696969 */

                          color: #696969;

                          &.required {
                            &::after {
                              content: '*';
                              color: #55B87A !important;
                            }
                          }

                        }
                      }



                      .flex {
                        width: 100%;
                        height: auto;
                        justify-content: center;
                        padding: 15px;
                      }

                      >div {
                        width: 100%;

                        .survey-table-item-container {
                          &.none {
                            display: block;
                            // background-color: #ff000080;
                            // border-radius: 13px;

                            .flex.flexWrap {
                              background-color: #DDDDDD;

                            }
                          }
                        }
                      }

                      .survey-table-item-container {
                        background: white;

                        .flex {
                          background: #E7F0EA;
                          border-radius: 4px 4px 0px 0px;
                        }
                      }
                    }
                  }
                }

                .survey__content-item-header {
                  height: 55px;
                  // background:#f6f8fb;
                  padding: 0 20px 0 40px;
                  display: flex;
                  align-items: center;
                  justify-content: flex-end;
                  background: url('#{$image-path}/admin/menu_on.svg') no-repeat 20px center;
                  background-size: 10px;

                  h2 {
                    margin-right: auto;
                    padding: 0;
                    line-height: 2.5;
                    font-size: 1rem;
                    font-weight: 600;

                    // &.Mtch {
                    //   padding-left: 43px;
                    //   background: url('#{$image-path}/admin/mtch_title_icon.svg') no-repeat left 8px;
                    //   background-size: 38px;
                    // }

                    // &.Plur {
                    //   padding-left: 59px;
                    //   background: url('#{$image-path}/admin/plur_title_icon.svg') no-repeat left 8px;
                    //   background-size: 54px;
                    // }

                    // &.Short {
                    //   padding-left: 48px;
                    //   background: url('#{$image-path}/admin/short_title_icon.svg') no-repeat left 8px;
                    //   background-size: 43px;
                    // }

                    // &.table_tit {
                    //   padding-left: 59px;
                    //   background: url('#{$image-path}/admin/table_title_icon.svg') no-repeat left 7px;
                    //   background-size: 54px;
                    // }
                  }

                  button {
                    width: 30px;
                    height: 30px;
                    // border: 1px solid #ddd;
                    // background:#fff;
                    // border-radius: 50%;
                    margin-left: 10px;

                    svg {
                      vertical-align: middle;
                      margin-top: -5px;
                    }
                  }
                }

                .survey__content-form {
                  .survey__content-form-actions {
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;

                    button {
                      height: 60px;
                      font-size: 18px;
                      width: 135px;
                      color: #999;
                      border-bottom: 4px solid #999999;
                      display: flex;
                      align-items: center;
                      justify-content: center;
                    }

                    .active {
                      color: #1A9952;
                      border-bottom: 3px solid #1A9952;
                    }
                  }

                  .answer-button-box {
                    display: flex;
                    justify-content: flex-end;

                    .btn {
                      margin-left: 10px;
                    }
                  }

                }

                // 문항
                .survey__content-item {
                  border-radius: 4px;

                  .survey__content-item-header {
                    background: #fff;

                    label.checkbox {
                      background: url('https://lms.gjudec.com/assets/images/active-arrow.png') no-repeat center;
                      background-color: #696969;
                      border-radius: 10px;
                      height: 25px;
                      width: 25px;
                      cursor: pointer;
                    }

                    .checked {
                      background-color: #00B852 !important;


                    }


                    h2 {
                      position: relative;

                      &.question {
                        // text-indent: 60px;

                        &::before {
                          content: '';
                          position: absolute;
                          left: 0;
                          top: 50%;
                          transform: translateY(-50%);
                        }

                        &.Mtch {
                          &::before {
                            content: '';
                            display: block;
                            height: 1.3em;
                            border: 3px solid #00B852;
                            /* border-radius: 50%; */
                            position: absolute;
                            left: -19px;
                            top: 20px;
                            // width: 63px;
                            // height: 22px;
                            // background: url('#{$image-path}/Group 33808.png') no-repeat center;
                          }
                        }

                        &.Plur {
                          &::before {
                            content: '';
                            display: block;
                            height: 1.3em;
                            border: 3px solid #00B852;
                            background-color: #00B852;
                            width: 3px;
                            /* border-radius: 50%; */
                            position: absolute;
                            left: -19px;
                            top: 20px;
                            // width: 75px;
                            // height: 22px;
                            // background: url('#{$image-path}/Group 33806.png') no-repeat center;
                          }
                        }

                        &.Short {
                          &::before {
                            content: '';
                            display: block;
                            height: 1.3em;
                            border: 3px solid #00B852;
                            /* border-radius: 50%; */
                            position: absolute;
                            left: -19px;
                            top: 20px;
                            background-color: #00B852;
                            width: 3px;
                            // width: 67px;
                            // height: 22px;
                            // background: url('#{$image-path}/Group 33807.png') no-repeat center;
                          }
                        }

                        &.table_tit {
                          &::before {
                            content: '';
                            display: block;
                            height: 1.3em;
                            border: 3px solid #00B852;
                            /* border-radius: 50%; */
                            position: absolute;
                            left: -19px;
                            top: 20px;
                            background-color: #00B852;
                            width: 3px;
                            // width: 67px;
                            // height: 22px;
                            // background: url('#{$image-path}/Group 33807.png') no-repeat center;
                          }
                        }

                        &.Mtch2 {
                          &::before {
                            content: '';
                            display: block;
                            height: 1.3em;
                            border: 3px solid #868686;
                            /* border-radius: 50%; */
                            position: absolute;
                            left: -19px;
                            top: 20px;
                            background-color: #868686;
                            width: 3px;
                            // width: 63px;
                            // height: 22px;
                            // background: url('#{$image-path}/Group 33808.png') no-repeat center;
                          }
                        }

                        &.Plur2 {
                          &::before {
                            content: '';
                            display: block;
                            height: 1.3em;
                            border: 3px solid #868686;
                            /* border-radius: 50%; */
                            position: absolute;
                            left: -19px;
                            top: 20px;
                            background-color: #868686;
                            width: 3px;
                            // width: 75px;
                            // height: 22px;
                            // background: url('#{$image-path}/Group 33806.png') no-repeat center;
                          }
                        }

                        &.Short2 {
                          &::before {
                            content: '';
                            display: block;
                            height: 1.3em;
                            border: 3px solid #868686;
                            /* border-radius: 50%; */
                            position: absolute;
                            left: -19px;
                            top: 20px;
                            background-color: #868686;
                            width: 3px;
                            // width: 67px;
                            // height: 22px;
                            // background: url('#{$image-path}/Group 33807.png') no-repeat center;
                          }
                        }

                        &.table_tit2 {
                          &::before {
                            content: '';
                            display: block;
                            height: 1.3em;
                            border: 3px solid #868686;
                            /* border-radius: 50%; */
                            position: absolute;
                            left: -19px;
                            top: 20px;
                            background-color: #868686;
                            width: 3px;
                            // width: 67px;
                            // height: 22px;
                            // background: url('#{$image-path}/Group 33807.png') no-repeat center;
                          }
                        }
                      }
                    }

                    >button {
                      border-radius: 0;
                      border: 0;
                    }
                  }

                  .surveyitem {
                    border-top: 1px dashed #ddd;

                    &.displaynone {
                      border-top: 0;
                    }
                  }
                }

                .survey__content-lists {
                  .surveyaddbtn {
                    display: block;
                    color: #fff;
                    font-size: 14px;
                    flex: none;
                    white-space: nowrap;
                    height: 40px;
                    padding: 0 30px;
                    border-radius: 3px;
                    background: #55B87A;
                    margin: 0 auto;
                    margin-top: 20px;
                  }
                }
              }
            }

            // 시험 취소추가 버튼
            .answer-button-box {
              .btn {
                background: #ACDBA4;
                border: 1px solid #ACDBA4;
                border-radius: 3px;
                color: #5C8D72;
                min-width: 120px;
                height: 40px;
                //line-height: 40px;
                font-size: 0.875rem;

                // &::before {
                //   content: '';
                //   display: inline-block;
                //   width: 19px;
                //   height: 22px;
                //   margin-right: 10px;
                //   vertical-align: -5px;
                //   background: url('#{$image-path}/survey-cancel-icon.png') no-repeat center;
                // }

                // &.submit {
                //   border: 1px solid #55B87A;
                //   background: #55B87A;
                //   color: #fff;

                //   &::before {
                //     width: 19px;
                //     height: 21px;
                //     background: url('#{$image-path}/survey-add-icon.png') no-repeat center;
                //   }
                // }
              }
            }
          }
        }

        // .preview {
        //   .sub_survey_title {
        //     width: 100%;
        //     height: 20%;
        //     background-color: #f8f8f8;
        //     padding: 30px;
        //     border-radius: 5px;

        //     .survey_popup_date,
        //     .survey_popup_user {
        //       display: flex;
        //       font-family: 'Pretendard';
        //       font-style: normal;
        //       font-weight: 400;
        //       font-size: 15px;
        //       line-height: 18px;
        //       padding: 5px;

        //       /* identical to box height */
        //       &>span:nth-child(1) {
        //         font-family: 'Pretendard';
        //         font-style: normal;
        //         font-weight: 400;
        //         font-size: 15px;
        //         line-height: 14px;
        //         flex-basis: 8%;
        //         /* or 93% */

        //         color: #333333;
        //       }

        //       &>span:nth-last-child(1) {
        //         font-family: 'Pretendard';
        //         font-style: normal;
        //         font-weight: 400;
        //         font-size: 15px;
        //         line-height: 14px;
        //         flex-basis: 10%;
        //         /* or 93% */

        //         color: #696969;
        //       }

        //     }

        //     h1 {
        //       font-size: 1.4rem;
        //       font-weight: 600;
        //       position: unset;
        //       margin-bottom: 10px;
        //     }

        //     h3 {
        //       margin-bottom: 10px;
        //       padding: 5px;
        //     }
        //   }

        //   .ql-container {
        //     height: auto;
        //     border: 1px solid #bedaf4 !important;
        //     border-radius: 5px;
        //     padding: 20px;
        //     margin: 20px 0 40px;
        //   }

        //   .survey_question {
        //     padding: 0 30px 15px;
        //     border: 1px solid #e5e5e5;
        //     border-radius: 5px;
        //     margin: 20px 0;

        //     h1 {
        //       margin-top: 0.3rem;
        //       margin-bottom: 2.3rem;
        //       line-height: 4.3rem;
        //       border-bottom: 0.5px solid #e5e5e5;
        //       font-size: 1.2rem;
        //       font-weight: 600;
        //       position: unset;
        //       letter-spacing: -1.5px;
        //     }

        //     label {
        //       vertical-align: middle;
        //       font-size: 17px;
        //       padding: 0 0 15px 10px;
        //       font-weight: 500;

        //       input[type=radio] {
        //         background-color: #ececec;
        //         border: 7px solid #ececec;
        //         width: 30px;
        //         height: 30px;
        //         display: inline-block;
        //         vertical-align: middle;
        //         margin-right: 15px;
        //         appearance: none;
        //         border-radius: 50px;

        //         &:checked {
        //           border: 7px solid #92c1ec;
        //           width: 30px;
        //           height: 30px;
        //         }
        //       }
        //     }

        //     .evaluate_table {

        //       input[type=radio] {
        //         background-color: #ececec;
        //         border: 7px solid #ececec;
        //         width: 20px;
        //         height: 20px;
        //         display: inline-block;
        //         vertical-align: middle;
        //         appearance: none;
        //         border-radius: 50px;

        //         &:checked {
        //           border: 5px solid #92c1ec;
        //           width: 20px;
        //           height: 20px;
        //         }
        //       }
        //     }
        //   }

        //   .modal_full_body {
        //     padding: 1rem 1rem;

        //     .modal_sub_title {
        //       display: flex;

        //       h4 {
        //         margin-right: 20px;
        //       }
        //     }

        //     .modal_body {

        //       .tab {
        //         display: flex;

        //         .tab_btn {
        //           display: flex;
        //           margin-left: 15px;
        //           margin-top: 28px;
        //           padding: 0 20px;
        //           height: 45px;
        //           font-size: 16px;
        //           border: #bdbdbd 1px solid;
        //           border-radius: 6px;
        //           -webkit-align-items: center;
        //           align-items: center;
        //           -webkit-justify-content: center;
        //           justify-content: center;
        //           cursor: pointer;

        //           &:first-child {
        //             margin-left: 0;
        //           }
        //         }

        //         .excel_btn {
        //           margin-left: auto;
        //         }


        //         .active {
        //           background: #0471b6;
        //           color: #fff !important;
        //         }

        //         .inactive {
        //           background-color: #d0d4d9;
        //         }

        //       }

        //       input {

        //         &#filter-text-box {
        //           width: 100%;
        //         }
        //       }

        //       .result_outer {
        //         display: flex;
        //         border: 1px solid #ddd;
        //         border-radius: 6px;
        //         overflow: hidden;

        //         #user_stu_info,
        //         #question_survey {
        //           border-right: 1px solid #ddd;
        //         }

        //         .survey_display {
        //           white-space: nowrap;
        //           overflow: auto;

        //           &::-webkit-scrollbar {
        //             height: 12px;
        //           }

        //           &::-webkit-scrollbar-thumb {
        //             background-color: #c8cbdb;
        //           }

        //           &::-webkit-scrollbar-track {
        //             background-color: #f2f3f7;
        //           }

        //           .student_list {
        //             border-left: none !important;

        //             .question {
        //               width: 15rem !important;
        //             }

        //             .question_count {
        //               width: 7rem !important;
        //             }
        //           }

        //           .stu_content_title {
        //             width: 30rem !important;
        //             height: 55px;
        //             line-height: 55px;
        //             border-left: 1px solid #ddd;

        //             &:first-child {
        //               border-left: 0;
        //             }
        //           }

        //           .stu_content {
        //             width: 30rem !important;
        //             border-left: 1px solid #ddd;

        //             &:first-child {
        //               border-left: 0;
        //             }
        //           }

        //           .notSurvey {
        //             width: 100% !important;
        //             justify-content: flex-start !important;
        //           }
        //         }

        //         .result_table {

        //           .student_list {
        //             display: flex;
        //             // border-left: 1px solid black;

        //             &:nth-child(even) {
        //               background: #F6F8FB;
        //             }

        //             .listIdx {
        //               width: 4.5rem !important;
        //             }

        //             .stu_content {
        //               display: flex;
        //               padding: 5px 5px;
        //               width: 8rem;
        //               height: 55px;
        //               line-height: 55px;
        //               align-items: center;
        //               justify-content: center;
        //               /*
        //               border-right: 1px solid black;
        //               border-bottom: 1px solid black;
        //               */
        //               border-collapse: collapse;
        //             }

        //             .stu_content_title {
        //               display: flex;
        //               padding: 5px 5px;
        //               width: 8rem;
        //               height: 55px;
        //               line-height: 55px;
        //               color: #696969;
        //               font-weight: 500;
        //               align-items: center;
        //               justify-content: center;
        //               border-bottom: 1px solid #ddd;
        //               /*
        //               border-right: 1px solid black;
        //               border-bottom: 1px solid black;
        //               border-top: 1px solid black;
        //               */
        //               border-collapse: collapse;
        //               // background-color: #eef7ff;
        //             }

        //             .question {
        //               width: 30rem;
        //             }

        //             .q-title {
        //               justify-content: flex-start !important;
        //             }
        //           }

        //           .result_table_header {
        //             // margin-top: 20px;
        //           }

        //           tbody>tr:hover {
        //             background-color: #E0E9F7 !important;
        //             color: #0471B6 !important;
        //           }
        //         }
        //       }
        //     }
        //   }
        // }
      }

      .none {
        display: none;
      }

      form {
        margin-top: 40px;
      }

      .input_containerBox {
        display: flex !important;

        .insertForm__name {
          flex: none;
          width: 155px;
          padding: 10px 0;
        }

        .dateWraps {
          .react-datepicker__tab-loop {
            display: inline-block;
          }
        }

        input {
          border: 0;
          border-bottom: 1px solid #ddd;
          border-radius: 0;
          height: 40px;
          width: 100%;
          padding: 0 15px;
          font-size: 14px;
          color: #666;

        }

        .survey_desc {
          outline: none;
        }

        .insertForm__content {
          display: -webkit-flex;
          display: flex;
          -webkit-flex-direction: column;
          flex-direction: column;
          -webkit-justify-content: center;
          justify-content: center;
          -webkit-flex: 1 1;
          flex: 1 1;

          .in_detail {
            select {
              border: none;
              max-width: 120px;
              width: 100%;
              height: 100%;
              padding: 0 10px;
              font-size: 0.9rem;
            }
          }

          .custom-checkbox_container {
            height: auto;
            min-height: 40px;
            display: flex;
            align-items: center;

            .custom-checkbox {
              margin-top: 0 !important;
              min-width: 85px;
              display: -webkit-flex;
              display: flex;
              -webkit-align-items: center;
              align-items: center;

              input {
                display: none;
              }

              input[type=radio]+span {
                width: 18px;
                height: 18px;
                flex: none;
                border: 1px solid #ddd;
                border-radius: 50%;
                margin-right: 5px;
              }

              input[type=radio]:checked+span {
                background: none !important;
                border: 5px solid #55b87a;
              }
            }
          }
        }
      }

    }

    &.popup-close-content {
      -webkit-animation: dialog-flyout .5s !important;
      animation: dialog-flyout .5s !important;
    }

    .dialog-close {
      border: none;
      background: transparent;
      cursor: pointer;
      position: absolute;
      right: 0;
      top: 0;
      outline: none;

      &:before {
        display: block;
        padding: 3px;
        background: transparent;
        color: #bbb;
        content: '\00D7';
        font-size: 26px;
        font-weight: 400;
        line-height: 26px;
        text-align: center;
      }
    }

    .banner-dialog-close {
      border: none;
      background: transparent;
      cursor: pointer;
      position: absolute;
      right: 0;
      top: 0;
      outline: none;
      font-size: 2rem;
      color: #fff;
      padding: 5px;
    }

    .program_popup {
      background: #fff;

      .modal_header {
        display: flex;
        align-items: center;
        height: 50px;
        justify-content: space-between;
        padding: 1rem 1rem;
        border-bottom: 1px solid #dee2e6;
        border-top-left-radius: calc(.3rem - 1px);
        border-top-right-radius: calc(.3rem - 1px);
        position: sticky;
        top:0;
        left:0;
        z-index: 1;
        background-color: #fff;

        h5 {
          margin-left: 5px;
          padding-left: 12px;
          font-size: 1.25rem;
          margin-bottom: 0;
          line-height: 1.5;
          position: relative;

          &::after {
            // content: '';
            // display: block;
            // width: 0.8em;
            // height: 0.8em;
            // border: 5px solid #489659;
            // border-radius: 50%;
            // position: absolute;
            // left: -19px;
            // top: 7px;
            content: '';
            display: block;
            FONT-WEIGHT: 100;
            height: 1em;
            border: 2px solid #489659;
            /* border-radius: 50%; */
            position: absolute;
            left: 0px;
            top: 5px;
            width: 3px;
            background-color: #489659
          }
        }

        .overlay_close_x {
          &:after {
            font-family: Helvetica, Arial, sans-serif;
            content: '\00D7';
            font-size: 40px;
            color: #222;
            font-weight: 200;
            line-height: 0px;
          }

          position: absolute;
          top: 18px;
          right: 13px;
          background: none;
          width: 26px;
          height: 30px;
          text-decoration: none;


          @media screen and (max-width: 768px) {
            right: 6px;
            top: 17px;
          }
        }

        .element-invisible {
          position: absolute !important;
          clip: rect(1px 1px 1px 1px);
          clip: rect(1px, 1px, 1px, 1px);
          overflow: hidden;
          height: 1px;
        }

        &.button {
          background-color: transparent;
          border: 0;
        }
      }

      .modal_header.metaverse {
        display: flex;
        align-items: center;
        height: 75px;
        justify-content: space-between;
        padding: 1rem 1rem 0 1rem;
        border-bottom: 0px solid #dee2e6;
        border-top-left-radius: calc(.3rem - 1px);
        border-top-right-radius: calc(.3rem - 1px);

        h5 {
          margin-left: 20px;
          font-size: 1.25rem;
          margin-bottom: 0;
          line-height: 1.5;
          position: relative;

          &::after {
            content: '';
            display: block;
            width: 0.2em;
            height: 0.8em;
            border: 5px solid #489659;
            border-radius: 0%;
            position: absolute;
            left: -19px;
            top: 7px;
          }
        }

        .overlay_close_x {
          &:after {
            font-family: Helvetica, Arial, sans-serif;
            content: '\00D7';
            font-size: 40px;
            color: #222;
            font-weight: 200;
            line-height: 0px;
          }

          position: absolute;
          top: 25px;
          right: 35px;
          background: none;
          width: 26px;
          height: 30px;
          text-decoration: none;


          @media screen and (max-width: 768px) {
            right: 15px;
            top: 20px;
          }
        }

        .element-invisible {
          position: absolute !important;
          clip: rect(1px 1px 1px 1px);
          clip: rect(1px, 1px, 1px, 1px);
          overflow: hidden;
          height: 1px;
        }

        &.button {
          background-color: transparent;
          border: 0;
        }
      }

      .reg_form {

        .modal_sub_title {
          margin-top: 10px;
        }

        .course_overlay_footer {
          margin-top: 40px !important;
        }

        .input_container {
          margin: 0 !important;
          display: block !important;
          margin-top: 10px !important;
          margin-left: 0 !important;
          padding-bottom: 30px;
          border-bottom: 1.5px solid #ddd;
          margin-bottom: 10px;

          &.keywordBody{
            border: 2px dotted #00B852;
            padding: 10px;
            border-radius: 15px;  
            display: flex !important;
            flex-wrap: wrap;
            gap: 5px 20px;

            span {
              font-size: 0.85rem;
              display: inline-block;
              color:#00B852;
            }
        
          }

          &.flex_inputs {
            display: flex !important;
            align-items: center;
            justify-content: start;
          }

          .deleteButtons {
            border: none;
            background-color: #de3b04;
            color: #fff;
            padding: 0 13px;
            height: 46px;
            border-radius: 5px;
            margin-left: 4px !important;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 80%;
            font-weight: 400;
          }

          .loadMessage {
            margin-top: 4px;
            color: #ff0000;
            font-size: 0.9rem;
            margin-left: 6px;
          }

          .flexBox {
            display: flex;

            &~.flexBox {
              margin-top: 10px;
            }

            .leftName {
              width: 110px;
            }
          }

          ._btn--youtube {
            border: none;
            background-color: #489659;
            color: #fff;
            padding: 0 13px;
            height: 46px;
            border-radius: 5px;
            margin-left: 4px !important;
          }

          .size_box {
            width: 100%;

            &:after {
              display: block;
              visibility: hidden;
              clear: both;
              content: '';
            }

            .size_box_left {
              float: left;
              width: 50%;
              text-align: right;
              padding-right: 10px;

              .size_box_title {
                display: inline-block;
                margin-right: 10px;
              }

              .x_input {
                display: inline-block;
                width: 100%;
                max-width: 100px;
              }

            }

            .size_box_right {
              float: left;
              width: 50%;
              padding-left: 10px;

              .size_box_title {
                display: inline-block;
                margin-right: 10px;
              }

              .x_input {
                display: inline-block;
                width: 100%;
                max-width: 100px;
              }
            }

          }

          &.date_container {
            text-align: center;
          }

          .react-datepicker__header.react-datepicker__header--time {
            height: 87.28px;
            padding-top: calc(50% - 12px);
          }

          .react-datepicker__tab-loop {
            display: inline-block;
          }

          .time_input {
            width: 210px !important;
            margin-right: 5px;
          }

          label.main_popup_container {
            width: 70px;
            display: inline-block;
            margin-left: 3px;

            span {
              padding-left: 10px;
            }
          }

          input {
            width: 100% !important;

            &.main_popup {
              width: 15px !important;
            }
          }

          input[type=text] {
            border: 1px solid #767676;
            border-radius: 5px;
          }

          .input_secret {
            display: inline-block;
            margin-right: 40px;
            vertical-align: sub;

            .secret_check {
              border: 1px solid #ddd;
              border-radius: 30px;
              height: 28px;
              width: 28px;
            }

            .secret_checkbox:checked+label {
              background: url('#{$image-path}/secret_checked.png') no-repeat center;
            }

            .input_span {
              vertical-align: top;
              margin-left: 10px;
            }

            input[type='radio'] {
              display: none;
            }
          }

          .secret_pass {
            display: inline-block;
            vertical-align: middle;
            padding: 10px;

            input[type='password'] {
              width: 218px !important;
              height: 50px;
              padding: 10px;
              border: 1px solid #ddd;
              border-radius: 10px;
            }

            .password.deactive {
              background: #ddd;
              width: 218px;
              height: 50px;
              border: 1px solid #ddd;
              border-radius: 10px;
              padding: 10px;
            }
          }
        }

      }

      .modal_body {
        padding: 20px 20px 30px 20px;
        //margin-top: 20px;
        height: 100%;

        .button_container {
          button {
            width: unset;
            padding: 0 30px;
            height: 30px;
            font-size: 1.05rem;
            color: #adadad;

          }

          .active {
            color: #1A9952;
            border-bottom: 3px solid #1A9952;
          }
        }

        .survey_button_box {
          width: 100%;
          margin-top: 42px;
          border-top: 1px solid #bfbfbf;
          padding-top: 14px;

          &:after {
            display: block;
            visibility: hidden;
            clear: both;
            content: '';
          }

          .survey_button_box_left {
            float: left;

            .csv_button {
              margin-bottom: 10px;
              color: #FFF;
              font-weight: 700;
              background: #00863C;
              border-radius: 4px;
              padding: 8px 25px;
              display: block;
            }

            .csv_button_area {
              top: 50px;
              left: 0;
              padding: 8px 25px;
              font-weight: 700;
              border-radius: 4px;
              background-color: #3E3E3E;
              color: #fff;
              display: block;

              &:hover {
                background-color: rgb(65, 65, 65);
                color: #FFF;
              }
            }
          }

          .survey_button_box_right {
            float: right;

            .csv_button {
              margin-bottom: 10px;
              color: #FFF;
              font-weight: 700;
              background: #00863C;
              border-radius: 4px;
              padding: 8px 25px;
              display: block;
            }

            .csv_button_area {
              top: 50px;
              left: 0;
              padding: 8px 25px;
              font-weight: 700;
              border-radius: 4px;
              background-color: #3E3E3E;
              color: #fff;
              display: block;

              &:hover {
                background-color: rgb(65, 65, 65);
                color: #FFF;
              }
            }
          }


        }

        @media screen and (max-width: 768px) {
          padding: 18px 6px;
        }

        // 워크숍 설문조사 결과보기 모달창
        &.worksurvey {
          padding: 20px 30px 30px 30px;
        }

        .survey_que_div {
          margin-bottom: 30px;
          border: 1px solid #ddd;
          // padding: 10px;
          border-radius: 4px;
        }

        .work_survey_title {
          padding: 10px 20px;
          border-bottom: 1px solid #ddd;
          color: #696969;
        }

        .work_survey {
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;

          &.q {
            padding: 0 30px;
            margin: 5px 0;

            span {
              color: #696969;
            }
          }
        }

        .aItemWrap {
          padding: 5px 0;

          &:nth-child(odd) {
            background-color: #FAFAFA;
          }


          span {
            color: #696969;
            padding: 0 30px;

            @media screen and (max-width:768px) {
              padding: 0 10px;
            }
          }

          .aitemcolor {
            color: #00B852;
          }
        }

        .input_container {
          margin: 15px 3px 20px 15px;
          display: inline;
          margin-left: 72px;

          .cal_ir {
            display: inline-block;
            margin: 0 10px;
            width: 6%;
            height: 37px;
            padding-top: 7px;
            text-align: center;
          }

          input {
            width: auto;
            display: inline-block;
            border: 1px solid #ddd;
            padding: 10px;
            border-radius: 13px;

            &[type='password'] {
              font-family: auto;
            }

            &.secret_checkbox {
              display: inline-block;
              width: auto;
              margin-left: 10px;
            }
          }

          textarea {
            width: 100%;
            height: 100px;
            padding: 10px;
            box-sizing: border-box;
            border-radius: 5px;
            font-size: 16px;
            resize: both;
          }

          &.video {
            .secret_check {
              border: 1px solid #ddd;
              border-radius: 30px;
              height: 28px;
              width: 28px;
            }

            .secret_checkbox:checked+label {
              background: url('#{$image-path}/secret_checked.png') no-repeat center;
            }

            span {
              vertical-align: top;
              margin-left: 10px;
            }

            input[type='radio'] {
              display: none;
            }
          }
        }

        .img_container {
          margin-top: 20px;
          margin-left: 20px;

          div {
            min-height: 130px;
            margin-top: 15px;

            .img_info_box {
              margin-top: 0px;
              display: inline-block;
              vertical-align: top;
              margin-left: 50px;

              input {
                margin-top: 15px;
              }
            }
          }
        }

        .complate_title {
          text-align: center;
          margin-top: 30px;
          margin-bottom: 30px;

          .compalte_modeTitle {
            color: red;
          }
        }

        .information_complate {
          margin-top: 20px;

          span {
            display: block;
          }
        }

        .files_container {
          padding: 20px 10px 10px 10px;
          border: 1px solid black;
          margin-top: 20px;
          display: block;
          position: relative;

          .files_title {
            position: absolute;
            top: -10px;
            background: white;
            border: 1px solid black;
            padding: 0 5px;
            text-align: center;
          }

          .file_once {
            display: block;
            margin-bottom: 5px;
            width: unset;

            .files_item {
              display: inline-block;
              width: 100%;
              overflow: hidden;
              text-overflow: ellipsis;
              vertical-align: middle;
              white-space: pre;
              // width: unset;
            }

            .files_delete {
              display: inline-block;
              cursor: pointer;
              background-color: #272b37;
              color: white;
              padding: 2px 10px;
              text-align: center;
              float: right;
            }
          }
        }

      }

      .modal_sub_title {
        font-weight: bold;
        font-size: 1rem;
        color: #222;


        &:before {
          content: '';
          display: inline-block;
          width: 4px;
          height: 14px;
          margin: 0 6px 0 4px;
          background-color: #00863e;
        }

      }

      ////////////  CleanWrite form Style

      .clean_dash {
        display: inline-block;
        width: 20px;
        text-align: center;
      }

      .clean_dash2 {
        display: inline-block;
        width: 26px;
        padding-left: 2px;
      }

      .clean_form_left {
        width: calc(100%);
        height: auto;
        margin-bottom: 10px;
        display: inline-block;

        select {
          margin-right: 5px;
        }
      }

      .clean_form_right {
        width: calc(100%);
        height: auto;
        float: right;
        display: inline-block;

        select {
          margin-right: 5px;
        }
      }

      .clean_form_item {
        width: 100%;
        height: calc(40%);
        display: block;
        margin-bottom: 10px;
      }

      .input_container2 {
        margin: 10px 3px 20px 15px;

        input {
          width: 100%;
          height: 39px;

          &[type='password'] {
            font-family: auto;
          }

          &.secret_checkbox {
            display: inline-block;
            width: auto;
            margin-left: 10px;
          }
        }

        select {
          width: 20.333%;
          margin: 0 3px 0 0;
          height: 39px;
        }

        textarea {
          width: 100%;
          height: 100px;
          padding: 10px;
          box-sizing: border-box;
          border-radius: 5px;
          font-size: 16px;
          resize: both;
        }
      }

      .input_container3 {
        margin: 10px 3px 20px 15px;

        input {
          width: 20.333%;
          margin: 0px 3px 0px 3px;
          height: 39px;

          &[type='password'] {
            font-family: auto;
          }

          &.secret_checkbox {
            display: inline-block;
            width: auto;
            margin-left: 10px;
          }
        }

        select {
          width: 22.333%;
          margin-left: 5px;
          height: 39px;
        }
      }

      .input_container4 {
        margin: 10px 3px 20px 15px;

        input {
          width: 20.333%;
          margin: 0px 3px 0px 3px;
          height: 39px;

          &[type='password'] {
            font-family: auto;
          }

          &.secret_checkbox {
            display: inline-block;
            width: auto;
            margin-left: 10px;
          }
        }

        select {
          width: 20.333%;
          height: 39px;
        }
      }



      .modal_sub_title2 {
        font-weight: bold;
        font-size: 1rem;
        color: #222;

        &:before {
          content: '';
          display: inline-block;
          width: 4px;
          height: 14px;
          margin: 0 6px 0 4px;
          background-color: #00863e;
        }

      }

      ///////////////////////////////////


      .modal_body .table_responsive {
        table thead tr th {
          border: 1px solid #e6e6e6;
          font-weight: bold;
          text-align: center;
          vertical-align: middle;
          height: 35px;
          padding: 22px 0;
        }

        .table_modal {
          width: 100%;
          max-width: 100%;
          justify-content: center;
          align-items: center;

          @media screen and (max-width: 768px) {
            margin-bottom: 0;
          }
        }

        table {
          tbody {
            tr {
              td {



                h1 {
                  padding: 5px 0 10px 0;
                  font-size: 12px;
                  width: 100%;
                  height: 48px;
                  display: flex;
                  justify-content: center;
                  overflow: hidden;
                  align-items: center;

                }

                border: 1px solid #e6e6e6;
                color: #222;
                font-weight: 400;
                text-align: center;
                vertical-align: middle;
                letter-spacing: -.05rem;
                height: 35px;
                padding-top: 10px;
                padding-bottom:10px;
                line-height: 1.42857;
                font-size: 16px;
                font-weight: 700;
              }

              background-color: transparent !important;
            }

            background-color: #fff;
          }

          word-break: keep-all;
          margin: 0;
        }

        //margin: 15px 3px 0 15px;
        font-size: .8125rem;
        border-top: 2px solid #272b37;
        overflow-x: auto;
        min-height: 0.01%;
      }
    }

    &::-webkit-scrollbar {
      width: 23px;
    }

    /* 스크롤바 막대 설정*/
    &::-webkit-scrollbar-thumb {
      background-color: #00863c;
      border-radius: 20px;
      border: 8px solid rgb(255, 255, 255);
    }

    &::-webkit-scrollbar-track {
      background-color: rgba(0, 0, 0, 0);
      /* 스크롤바 뒷 배경을 투명 처리한다 */
    }
  }
}

table.sticky_header {
  background-color: #fff;
  margin-top: 0;
}

.modal_body {
  .course-enroll-result {
    margin-top: 45px;
    font-size: 1.5rem;
    text-align: center;
    line-height: 1.25rem;
    color: #222;

    .blue {
      font-size: 1.5rem;
      color: #4f87cd;
    }
  }
}

.modal_body {
  .course-applicant {}
}

.popup_overlay .popup_content .program_popup {
  .metaverse {
    form {
      margin-top: 0px
    }
  }

  .modal_body .table_responsive {
    margin-top: 20px;

    table thead {
      background-color: #edf3fa;
    }

    .sticky_header {
      table {
        word-break: keep-all;
        margin: 0;
      }

      position: fixed;
      top: 0px;
      left: 110.5px;
      visibility: hidden;
    }
  }

  .course_overlay_description_work {
    margin-top: 20px;
    font-size: .875rem;
    text-align: center;
    line-height: 1.25rem;
    color: #222;
    margin-bottom: 30px;

    span {
      font-size: .875rem;
      display: block;
    }
  }

  .course_pop_bottom {
    margin-bottom: 50px !important;
  }

  .course_overlay_description {
    font-size: .875rem;
    text-align: center;
    line-height: 1.25rem;
    color: #222;
    margin-bottom: 20px;

    span {
      font-size: .875rem;
      display: block;
    }
  }

  .course_overlay_footer {
    //margin-top: 20px;
    padding-bottom: 20px;
    text-align: center;
    //margin-bottom: 25px;
    font-size: 0;

    .overlay_close {
      border-radius: 3px;
      padding: 0;
      border-radius: 3px;
      width: 120px;
      height: 40px;
      display: inline-block;
      border: 1px solid #272b37;
      font-size: 1rem;
      vertical-align: middle;
      text-align: center;
      line-height: 38px;
      background-color: transparent;
      color: #2c4353;
      margin-left: 5px;

      &.consulting {
        background-color: #3e5599;
        color: white;
      }
    }

    button {
      &.form-submit {
        border: none;
        background-color: #489659;
        color: #fff;

        &.workshop {
          background-color: #6eace5;
          color: white;
        }
      }

      &.cancel_submit {
        background-color: #6eace5;
        border: 1px solid #92c1ec;
        color: white;
        margin-left: 5px;
      }

      &.overlay_close {
        /*border: 1px solid $theme-red;*/
        background-color: white;
        border: 1px solid #ececec;
        color: black;
        margin-left: 5px;
      }

      border-radius: 3px;
      padding: 0;
      border-radius: 3px;
      width: 120px;
      height: 40px;
      display: inline-block;
      margin: 0;
      border: 1px solid #272b37;
      font-size: 1rem;
      vertical-align: middle;
      text-align: center;
      line-height: 38px;
    }
  }
}

@media screen and (max-width: 845px) {
  .popup_overlay {
    padding: 20px;

    .popup_content {
      .program_popup {
        .modal_body {
          .files_container {}

          .table_responsive {
            .table_modal {
              tbody {
                tr {
                  td {
                    overflow: hidden;
                    text-overflow: ellipsis;
                  }
                }
              }
            }
          }

        }
      }
    }
  }
}

// 모달창 끝 //
// sns 공유 팝업창 //
.popup_sns {
  position: absolute;
  display: none;
  opacity: 0;
  z-index: 2;
  background-color: #fff;
  padding: 15px;
  border-radius: 3px;
  border: 1px solid #666;
  box-shadow: 1px 5px 10px rgba(0, 0, 0, 0.2);
  transition: opacity .2s;
  left: 50%;
  margin-top: 25px;
  margin-left: -248px;

  &.active {
    opacity: 1;
    display: block;
    transition: opacity .2s;
  }

  .popup_title .title {
    font-size: .9375rem;
    white-space: nowrap;
    color: #222;
    padding: 7px 23px 14px;
    border-bottom: 1px solid #eaeaea;
  }

  .popup_icon {
    text-align: center;
    padding: 20px;

    a {
      margin-right: 5px;
    }
  }

  .link_copy {
    input {
      width: 100%;
      padding: 10px 12px;
      font-size: .8125rem;
      line-height: 10px;
      height: 33px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      //padding-right: 72px;
      background-color: #eee;
      border: 1px solid #ddd;
    }

    button {
      position: absolute;
      width: 70px;
      height: 33px;
      top: 0;
      right: 0;
      background: #fff;
      box-shadow: none;
      border: 1px solid #ddd;
      font-size: .8125rem;
      white-space: nowrap;
      cursor: pointer;
    }

    position: relative;
    width: 100%;
  }
}

// 끝 //
.programDetail__comment {
  border-top: 2px solid $theme-black-2;
  margin-top: 2em;

  header {
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid$theme-gray-4;
    margin-bottom: 1em;

    @media screen and (max-width: 1220px) {}

    @media screen and (max-width: 768px) {
      height: 60px;
    }

    h2 {
      font-size: 1.25rem;
      font-weight: 600;

      @media screen and (max-width: 1220px) {}

      @media screen and (max-width: 768px) {
        font-size: 1.125rem;
      }
    }

    button {
      font-size: 1.125rem;
      width: 120px;
      height: 40px;
      background: $theme-gray-3;
      border-radius: 5px;

      @media screen and (max-width: 1220px) {}

      @media screen and (max-width: 768px) {
        font-size: 1rem;
      }
    }
  }
}

.programDetail__slide {
  .slick-dots {
    margin-top: 1em;
    position: static;
    width: auto;
    height: 6px;

    @media screen and (max-width: 1220px) {}

    @media screen and (max-width: 768px) {
      bottom: 60px;
    }

    li {
      margin: 0;
      width: auto;
      padding: 0 4px;
      height: auto;
      vertical-align: top;

      &.slick-active button {
        background: $theme-black-4;
      }
    }

    button {
      transition: all 0.5s;
      background: $theme-gray-2;
      width: 36px;
      height: 6px;
      padding: 0;
    }
  }

  .slick-dots li button:before {
    display: none;
  }

  .item {
    background: $theme-white-1;
    border: 1px solid $theme-gray-4;
    border-top-left-radius: 2em;
    border-top-right-radius: 2em;
    padding: 2em;

    @media screen and (max-width: 1220px) {
      padding: 1.5em 1em;
    }

    @media screen and (max-width: 768px) {}
  }

  p {
    font-size: 1.125rem;
    line-height: 1.5em;
    margin-top: 10px;

    @media screen and (max-width: 1220px) {
      font-size: 1rem;
      line-height: 1.375em;
    }

    @media screen and (max-width: 768px) {}
  }

  h3 {
    font-size: 1.375rem;
    color: $theme-black-3;
    font-weight: 600;
    background: url('#{$image-path}/program-detail-i14.png') no-repeat left -1px;
    padding-left: 1.5em;
    margin-bottom: 0.5em;
    line-height: 1.25em;

    @media screen and (max-width: 1220px) {
      font-size: 1.25rem;
      background-size: auto 1em;
    }

    @media screen and (max-width: 768px) {}
  }
}

.comments {}

.comments__item {
  background: $theme-white-3;
  padding: 2em;

  &~.comment__item {
    margin-top: 1em;
  }

  @media screen and (max-width: 1220px) {
    padding: 1em;
  }

  @media screen and (max-width: 768px) {}

  h3 {
    font-size: 1.125rem;
    font-weight: 600;
    padding-left: 1.5em;

    &.active {
      background: url('#{$image-path}/program-detail-i9.png') no-repeat left center;
      margin-bottom: 15px;
    }
  }

  p {
    font-size: 1rem;
    line-height: 1.5em;
    color: $theme-black-3;
  }

  .etc {
    margin-top: 10px;
    line-height: 1em;

    span {
      font-size: 14px;
      color: $black;
    }
  }

  .comments__form {
    background: $white;
    border: 1px solid $theme-gray-4;
    height: 40px;
    border-radius: 3px;
    overflow: hidden;
    display: flex;

    input {
      border: none;
      flex: 1;
      font-size: 14px;
      outline-width: 0;
    }

    button {
      width: 38px;
      height: 38px;
      text-indent: -9999px;
      overflow: hidden;

      &.action {
        background: url('#{$image-path}/program-detail-i10.png') no-repeat center;
      }

      &.submit {
        background: url('#{$image-path}/program-detail-i8.png') no-repeat center;
        background-size: auto 16px;
      }
    }
  }
}

.reCommend {
  margin-top: 1em;

  .recommend__item {
    padding: 1em 0 1em 2em;
    border-top: 1px solid $theme-gray-4;
    background: url('#{$image-path}/program-detail-i11.png') no-repeat left 1em;

    h4 {
      font-weight: 600;
      font-size: 1rem;
      margin-bottom: 10px;
    }
  }
}

.page-content-auditcontent {
  .tabs {
    li {
      display: inline-block;
      padding: 5px 33px;
      cursor: pointer;
      margin-bottom: 40px;

      span {
        font-size: 1.15rem;
      }

      &.active {
        border-bottom: 3px solid #2a9150;
        color: #2a9150;
        font-weight: 700;
      }
    }
  }

  .search_container {
    border: 1px solid #dddddd;
    border-radius: 4px;
    padding: 15px 10px;
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
    align-items: center;

    @media screen and(max-width:768px) {
      flex-direction: column;
    }

    @media screen and (max-width: 700px) {
      margin-bottom: 10px;
    }

    &:after {
      display: block;
      visibility: hidden;
      clear: both;
      content: '';
    }

    .leftBox {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 50%;
      font-size: 1rem;


      @media screen and (max-width: 1310px) {
        width: 100%;
      }


      .search_select {
        width: 100%;
        font-weight: 700;

        &~.search_select {
          margin-left: 20px;
        }

        @media screen and (max-width: 1310px) {
          max-width: 180px;
        }

        @media screen and (max-width: 650px) {
          max-width: 100%;

          &~.search_select {
            margin-left: 10px;
          }
        }

        &:after {
          display: block;
          visibility: hidden;
          clear: both;
          content: '';
        }

        .rightBox {
          float: left;
          width: 100%;
          padding-left: 0;
          font-size: 1rem;

          @media screen and (max-width: 650px) {
            width: 100%;
          }

          @media screen and (max-width: 450px) {
            width: 100%;
          }
        }

        select {
          border: 1px solid #dddddd;
          border-radius: 4px;
          padding: 5px;
          width: 100%;
          color: #696969;
          height: 40px;
        }
      }

    }

    .rightBox {
      display: flex;
      width: 40%;
      justify-content: center;
      align-items: center;

      &.bottomRight {
        @media screen and(max-width:768px) {
          padding-top: 10px;
        }
      }

      &:after {
        display: block;
        visibility: hidden;
        clear: both;
        content: '';
      }

      @media screen and (max-width: 1310px) {
        width: 100%;
        padding-left: 0;
      }

      .search_radio {
        display: flex;
        font-weight: 700;

        &:after {
          display: block;
          visibility: hidden;
          clear: both;
          content: '';
        }

        @media screen and (max-width: 1300px) {
          margin-left: 0 !important;
          max-width: 296px;

        }

        @media screen and (max-width: 650px) {
          max-width: 100%;
          min-width: fit-content;
        }


        @media screen and (max-width: 570px) {
          margin-right: 0;
          max-width: 100%;
        }

        .leftBox {
          float: left;
          width: 26%;

          @media screen and (max-width: 1300px) {
            width: 32%;
          }

          @media screen and (max-width: 650px) {
            width: 17%;
          }

          @media screen and (max-width: 450px) {
            width: 23%;
          }
        }

        .rightBox {
          width: 100%;


          &:after {
            display: block;
            visibility: hidden;
            clear: both;
            content: '';
          }

          label {
            font-weight: 400;
            font-size: 1rem;
            margin: 0 5px;
            display: flex;
            align-items: center;

            input[type='radio'] {
              margin-left: 20px;
              margin-right: 5px;
              background:url('#{$image-path}/system_check.png') 50% no-repeat;

              @media screen and (max-width: 570px) {
                margin-left: 0;
              }
            }
          }
        }

      }


      .search_form {
        display: inline-block;

        @media screen and (max-width: 650px) {
          width: 100%;
          padding-left: 10px;
        }

        input {
          padding: 5px;
          border: 1px solid #ddd;
          // width: 160px;
          border-radius: 5px;
          height: 100%;
          position: relative;
          height: 45px;


          @media screen and (max-width: 650px) {
            width: 100%;
          }
        }

        button {
          margin-left: 8px;
          transform: translateY(11px);
        }

        .reset__btn {
          border-radius: 3px;
          color: #2a9150;
          background: #fff;
          border: 1px solid #2a9150;
          padding: 8px;
          padding: 8px;
          margin-left: 30px;
          transform: none;

          @media screen and (max-width: 700px) {
            margin-left: 10px;
          }

        }
      }

    }




  }

  .search_radio {
    display: inline-block;
    margin-left: 10px;
    margin-right: 20px;
    font-weight: 700;

    label {
      font-weight: 400;
      font-size: 1rem;

      input[type='radio'] {
        margin-left: 0 !important;
        margin-right: 5px;
        background:url('#{$image-path}/system_check.png') 50% no-repeat;
      }
    }

  }

  .search_form {
    display: inline-block;

    @media screen and (max-width: 600px) {
      width: 100%;
    }

    input {
      padding: 5px;
      border: 1px solid #ddd;
    }

    button {
      margin-left: 8px;
      transform: translateY(11px);
    }

    .reset__btn {
      border-radius: 3px;
      color: #2a9150;
      background: #fff;
      border: 1px solid #2a9150;
      padding: 8px;
      margin-left: 30px;
      transform: none;
    }
  }




  .write_container {
    text-align: right;
    margin-bottom: 20px;

    @media screen and (max-width: 700px) {
      margin-bottom: 10px;
    }

    .loading {
      position: fixed;
      z-index: 1000000;
      background: rgba(0, 0, 0, 0.5);
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
    }

    .write_button {
      padding: 10px 15px;
      color: white;
      background: #00B852;
      border-radius: 5px;
      font-weight: 700;
    }
  }
}

.audit_popup {
  border: 1px solid #020202 !important;
  border-radius: 10px;
  // background-color: #e6e6e6 !important;
  max-width: fit-content;

  .program_popup {
    .modal_header {
      border-bottom: none !important;
    }

    .modal_body {
      padding: 0 !important;
    }
  }

  h5 {
    font-weight: 600;
    font-size: 1.4rem;
  }

  .audit_top,
  .audit_middle,
  .audit_bottom {
    background: white;
    //border: 1px solid #999999;
    border-radius: 5px;
    padding: 20px;
    margin-bottom: 20px;

    small {
      font-size: 13px;
      display: block;
    }
  }

  .audit_bottom {
    span {
      display: inline-block;
      width: 150px;
      font-weight: 700;

      @media screen and (max-width: 775px) {
        width: 90px;
      }

    }

    .input_container {
      margin: 0 !important;
      display: inline-block !important;

      @media screen and (max-width: 775px) {
        width: calc(100% - 90px);
      }

    }

  }

  .num_container {
    max-width: 25%;

    .audit_num_title {
      text-align: center;
    }
  }


  .audit_top {
    .write_title {
      font-size: 1.1rem;
      font-weight: bold;
      border-bottom: 1px solid #dddddd;
      padding: 10px 0;
      padding-bottom: 15px;
    }

    .write_info {
      padding: 10px 0;
      display: block;
    }
  }

  .row_audit {
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;

    &.design,
    &.development,
    &.audit {
      display: none;

      .result_radio {
        width: 20%;
        vertical-align: middle;

        &.disabled {
          pointer-events: none;
        }
      }

      &.display {
        display: block;
      }
    }

    &.design {
      .result_radio {
        //width: 25%;
        //vertical-align: middle;
      }
    }

    .ptable {
      width: 100%;
      overflow-x: auto;
      border-radius: 4px;

      table {
        width: 100%;
        position: relative;
        text-align: center;
        // border: 2px solid #f0f0f0;

        tbody {
          .bottom_sub_select {
            color: #2a9150;
            font-size: 1em;
          }

          .td_p {
            text-align: left;
            // border: 2px solid #f0f0f0;
          }

          .p_result {
            padding: 10px;

            .data_use {
              margin-left: 5px;
            }
          }

          td {
            vertical-align: middle;
            border-left: 1px solid #f0f0f0;
            background: white;
            color: #696969 !important;
            font-size: 0.9em;
            padding: 9px;

            button {
              //color: #ffffff;
              //width: 70px;
              //height: 32px;
              //border-radius: 5px;
              //background-color: #2a9150;
              margin-left: 10px;
              vertical-align: middle;


              &.btn_comment {
                vertical-align: baseline;
              }
            }

            p {
              border: 1px solid gray;
              border-radius: 5px;
              padding: 20px;
              text-align: left;
              margin-top: 15px;
              margin-bottom: 10px;
            }

            .span_1 {
              text-align: center;
              display: inline-block;
              width: 70px;
              margin-right: 15px;
              border: 1px solid gray;
              border-radius: 15px;
              color: gray;
            }

            .span_2 {
              color: gray;
            }
          }
        }
      }

      .th_s_result_s {
        width: 55px;
        border-left: 1px solid #f0f0f0;
      }

      .th_m_result {
        width: 100px;
        border-left: 1px solid #f0f0f0;
      }

      .th_s_result {
        width: 95px;
        border-left: 1px solid #f0f0f0;
        padding: 9px;

        th {
          height: 40px;
          vertical-align: middle;
          // background-color: rgba(238, 238, 238, 0.3);
          font-size: 15px;
          color: #696969;
          font-weight: 400;
          width: 100px
        }

        .last_td {
          border-right: unset;
        }
      }

      .th_s {
        width: 100px;
      }

      .th_m {
        width: 150px;
      }

      .th_l_result {
        width: 340px;
        // border: 1px solid #222;
      }

      .th_l {
        width: 450px;
      }

      th {
        vertical-align: middle;
        // background-color: rgba(238, 238, 238, 0.3);
        height: 30px;
        font-size: 16px;
        letter-spacing: 0px;
        color: #696969;
        font-weight: 400;
      }
    }
  }

  .filter_button {
    margin-bottom: 20px;
    // border-bottom: 1px solid #b7b7b7;

    li {
      cursor: pointer;
      border-bottom: 1px #ddd;
      font-size: 18px;
      letter-spacing: 0px;
      padding: 5px 37px;
      display: inline-block;
      //&:last-child{
      //  border-right: 1px solid gray;
      //}

      @media screen and (max-width: 1024px) {
        font-size: 14px;
      }

      &.active {
        color: #2a9150;
        font-weight: 700;
        border-bottom: 4px solid #2a9150;

      }
    }
  }

  .bottom_select {
    select {
      padding: 7px;
      border-radius: 5px;
      width: 100px;
      margin-bottom: 5px;
    }
  }

  .audit_middle {
    .bar_box {
      fill: #F44336;
    }

    .sub_audit_form {
      border-bottom: 1px solid gray;
      padding-bottom: 20px;
    }

    .Audit_other_box {
      display: flex;
      width: calc(100%);
      //width: calc( 100% - 400px);
    }

    .part {
      margin-right: 10px;
      max-width: 48%;
      width: 47%;
      background: #eee;
      border-radius: 4px;
      padding: 15px 10px;


      .audit_text {
        width: 100% !important;


        &:after {
          display: block;
          visibility: hidden;
          clear: both;
          content: '';
        }

        span {
          width: auto;
          display: inline-block;
        }

        span:nth-child(1) {
          float: left;
          font-weight: 700;

          @media screen and (max-width: 625px) {
            float: none;
            display: block;
            width: 100%;
            text-align: center;
          }

        }

        span:nth-child(2) {
          float: right;
          font-size: 0.9rem;

          @media screen and (max-width: 625px) {
            float: none;
            display: block;
            width: 100%;
            text-align: center;
          }
        }


      }

      display: inline-block;
    }

    .Audit_Form {
      margin-bottom: 25px;
      height: auto;
      position: relative;
      display: flex;
      flex-direction: column;

      &:last-child {
        margin-bottom: 0;
      }

      &.result {
        display: inline-block;
        position: relative;
        vertical-align: top;
        height: 142px;
        width: 186px;

        .audit_text {
          width: 100% !important;
          height: 100%;
          vertical-align: middle;
          position: relative;
          display: inline-block;

          .ql-editor {
            p {
              span {
                color: #696969 !important;
              }
            }
          }


          span {
            text-align: right;
            width: 80px;
            display: block;
            margin: 0;
          }
        }
      }

      .auditName {
        display: inline-block;
        width: 80px;
        font-weight: 500;
        font-size: 0.9rem;
        color: #696969;
        margin-bottom: 5px;

      }

      .filetitle {
        display: inline-block;
        width: 80px;
        font-weight: bold;

        @media screen and (max-width: 625px) {
          display: block;
        }


      }

      .bottom_audit_text {
        width: 40% !important;
        padding: 20px !important;

        .auditName {
          margin-left: 35px;

        }
      }

      .mainQuill {
        background: none !important;
        padding: 0 !important;
      }

      .audit_text {
        border-radius: 4px;
        //border: 1px solid gray;
        width: 100%;
        display: inline-block;
        padding: 10px;
        background-color: #F8F8F8;
        color: #696969;
        font-weight: 400;
        font-size: 15px;

        .ql-editor {
          p {
            span {
              color: #696969 !important;
              font-size: 14px;
            }
          }
        }

        .filedown {
          margin: 3px 0;
          font-weight: 400;
          color: #696969;
          font-size: 14px;
          display: block;

          @media screen and (max-width: 625px) {
            font-size: 13px;
          }
        }

        .filedown:hover {
          color: #2a9150
        }

        @media screen and (max-width: 625px) {
          width: 100%;
        }

      }

      .calander_select {
        display: flex;
        width: 100%;

        &:after {
          display: block;
          visibility: hidden;
          clear: both;
          content: '';
        }

        .Audit_form_box_left {
          // float: left;
          width: 50%;
          padding-right: 15px;
          margin-right: 10px;
          display: flex;
          flex-direction: column;

          @media screen and (max-width: 625px) {
            // width: 50%;
            padding-right: 0;
          }

          .auditName {
            @media screen and (max-width: 625px) {
              display: block;
            }
          }

          .audit_subtext {
            border-radius: 5px;
            //border: 1px solid gray;
            width: 100%;
            display: inline-block;
            padding: 10px;
            display: inline-block;
            background-color: #f8f8f8;
            color: #696969;
            font-weight: 400;
            font-size: 15px;


            @media screen and (max-width: 625px) {
              width: 100%;
            }


          }
        }



        .Audit_form_box_right {
          // float: left;
          display: flex;
          flex-direction: column;
          width: 50%;
          padding-left: 15px;

          @media screen and (max-width: 625px) {
            // width: 50%;
            padding-left: 0;
            // margin-top: 10px;
          }

          .auditName {
            @media screen and (max-width: 625px) {
              display: block;
            }
          }

          .audit_subtext {
            border-radius: 5px;
            //border: 1px solid gray;
            width: 100%;
            display: inline-block;
            padding: 10px;
            display: inline-block;
            background: #f8f8f8;
            color: #696969;
            font-weight: 400;
            font-size: 15px;

            @media screen and (max-width: 625px) {
              width: 100%;
            }
          }
        }

        .subspan {
          margin-left: 17px;
        }
      }
    }

    .insert_Form {
      margin-bottom: 10px;

      span {
        font-weight: bold;
        width: 150px;
        display: inline-block;

        @media screen and (max-width: 775px) {
          width: 90px;
        }

      }

      input {
        width: calc(100% - 150px);
        padding: 8px;
        border: 1px solid #dddddd;

        @media screen and (max-width: 775px) {
          width: calc(100% - 90px);
        }
      }

      select {
        width: calc(100% - 150px);
        padding: 8px;
        border: 1px solid #dddddd;
      }

      .calander_select {
        width: 100%;
        display: inline-block;

        @media screen and (max-width: 775px) {
          width: calc(100% - 90px);
        }

        select {
          width: 45%;

          @media screen and (max-width: 775px) {
            width: 100%;
          }

          &.select_date {
            float: right;
          }
        }
      }
    }
  }

  .audit_footer {
    // border-top: 1px solid #999999;
    padding-top: 20px;

    &:after {
      display: block;
      visibility: hidden;
      clear: both;
      content: '';
    }

    button {
      border-radius: 3px;
      padding: 5px 15px;
      background-color: #fff;

      &.submit_button {
        float: right;
        color: white;
        background: #00B852;
        // border: 1px solid #2a9150;
        margin-right: 10px;
        width: 10%;
        min-width: 120px;

        &.save {
          color: #fff;
          background: #00863C;
          width: 10%;
          min-width: 120px;
        }
      }

      &.send_button {
        float: right;
        background: #00B852;
        color: white;
        // border: 1px solid #39396a;
        margin-right: 10px;
        width: 10%;
        min-width: 120px;
      }

      &.delete_button {
        float: right;
        color: white;
        background: #F46E43;
        // border: 1px solid #F46E43;
        margin-right: 10px;
        width: 10%;
        min-width: 120px;
      }
    }
  }
}

.auditcontent_table {
  width: 100%;
  table-layout: auto;
  // border: 1px solid #ddd;


  .th_header {
    vertical-align: middle;
    color: #696969;
    font-size: 14px;
    // border: 3px solid #fff;
    text-align: center;
    padding: 18px;

    &.top {
      background-color: rgba(238, 238, 238, 0.30);
      font-size: 1.05rem;
      border-bottom: 1px solid #ddd;
      color: #333;
      padding: 14px 0;

      @media screen and (max-width: 650px) {
        padding: 7px 0;
      }

    }

    &.right_border {
      border-right: 1px solid #ddd;
    }

    &.bottom {
      background-color: #fff;
      border-bottom: 1px solid #ddd;
      color: #333;
      font-size: 1.0rem;
      padding: 12px;

      @media screen and (max-width: 650px) {
        padding: 7px 0;
      }
    }
  }

  tbody {
    tr {
      // border-bottom: 1px solid #ddd;

      &:nth-child(even) {
        background-color: rgba(238, 238, 238, 0.25);
      }

      &:hover {
        background-color: #eee;
      }

      td {
        text-align: center;
        vertical-align: middle;
        padding: 10px;
        font-size: 0.93rem;

        span {
          font-size: 0.93rem;
        }

        .result_state {
          color: #2a9150;
          font-weight: 700;
        }

        &.course_title {
          text-align: left;

          span {
            display: block;
            font-size: 0.93rem;

            &.prop_name {
              color: #999999;
              font-size: 0.93rem;
            }
          }
        }
      }
    }
  }

  .result_check {
    border-radius: 4px;
    color: #00B852;
    background: #fff;
    border: 1px solid #00B852;
    padding: 7px 15px;
    font-weight: 700;



    &.active {
      background-color: #00B852;
      color: #fff;
      font-weight: 700;

      &:hover {
        background: #014d1d;
        border: 1px solid #014d1d;
      }
    }

    &.deactive {
      cursor: default;
      background: #dddddd;
      border: none;
      color: #fff;
    }
  }

  .resultSend {
    border-radius: 4px;
    color: #fff;
    background: #0085da;
    border: 1px solid #0085da;
    padding: 10px;
    font-weight: 700;

    &:hover {
      color: #fff;
      background: #005992;
      border: 1px solid #005992;
    }
  }

  .resultConfirm {
    color: #2a9150;
    font-weight: 700;
  }

  .examineRequest {
    color: #ff0000;
    font-weight: 700;
  }

  // .check_butt {
  //   border-radius: 3px;
  //   color: #fff;
  //   background: #0c70c4;
  //   padding: 10px;
  //   font-weight: 700;
  // }

  // .modify_butt {
  //   border-radius: 3px;
  //   color: #005298;
  //   background: #fff;
  //   border: 1px solid #005298;
  //   padding: 10px;
  //   font-weight: 700;
  // }
}




.progress_table_box_mycontent {
  width: 100%;

  .Myclass_progressrate_table {
    width: 100%;

    thead {
      tr {
        th {
          padding: 10px 0;
        }

        .th_title {
          padding: 10px 0 !important;
          background-color: #fff;
          font-size: 15px;

        }
      }
    }

    tbody {
      tr {
        td {
          padding: 0 !important;
          font-size: 16px;

          .intable {
            width: 100%;
            table-layout: auto;

            td {
              width: 25%;

              .play_time {
                font-size: 14px;
              }
            }

            .progressrate_top {
              padding: 8px;
              background-color: #edf3fa;
              font-size: 14px;
              border-left: none !important;
            }
          }

        }
      }
    }
  }
}

///////////////통합컨텐츠 내부 구성 Table 디자인 (공백없이 해야 함)  //////////////////

// 대학생의 자기이해와 진로탐색
.page-content {
  .desc_wrapperrrrrr {
    border: 1px solid #ddd;
    border-radius: 13px;
    overflow: hidden;
  }

  .detail_table_wrap {
    overflow-x: scroll;
  }

  .detail_table {
    border: 1px solid #d2d2d2;
    text-align: center;
    width: 100%;
    min-width: 825px;
    table-layout: auto;

    >thead {
      background: #f6f6f6;
    }

    th,
    td,
    tr {
      border-bottom: 0.5px solid #d2d2d2;
      vertical-align: middle;
      padding: 5px 10px;
      font-size: 1rem;
    }

    th {
      // background-color: #00b050;
      color: #333333;
      font-weight: 500;
      height: 50px;
    }

    th:nth-child(4) {
      // width: 30%;
    }

    td {
      height: 60px;
      font-size: 0.9rem;
      border: 0.5px solid #d2d2d2;


      @media screen and (max-width: 775px) {
        height: 52px;
      }

    }

    .td_week,
    .td_num {
      width: 60px;
    }

    /*.td_num+td {
        font-weight: bold;
        font-size: 1rem;
      }*/
    .tb_li {
      text-align: center;
    }

    .tb_li.quill_wrap {
      .quill {
        .ql-toolbar.ql-snow {
          border: none;
          padding: 0px;
          height: 0;

        }

        .ql-container {
          border: none !important;
          padding: 0px;
          margin: 0px;
          border-radius: 0px;

          &>.ql-editor {
            &>p {
              background-color: white !important;
              text-align: initial;
            }
          }
        }
      }
    }

    li {
      list-style: none;
      font-size: 0.9rem;
    }

    li:before {
      content: "- "
    }

    ;
  }

  &.page-content-60938af9ddd5526f6c1d7dda {
    .td_subject {
      width: 10%;
    }
  }

  &.page-content-60938bf6ddd5526f6c1d7ddc {
    .td_subject {
      width: 16%;
    }
  }

  &.page-content-6111113da26d1e77cd86d98f {
    .td_subject {
      width: 15%;
    }

    .td_p {
      width: 10%;
    }
  }


}




.erasebutton_wrap {
  margin-top: 20px;

  .course_overlay_footer {
    margin-bottom: 0 !important;
  }
}

.delete_height {
  height: 397px !important;
}

.state_change_height {
  height: 390px !important;
}


.workshop_add_form_wrap {
  width: 100%;

  .reg_form {
    .input_box {
      &:after {
        display: block;
        visibility: hidden;
        clear: both;
        content: '';
      }

      width:100%;
      margin-bottom:12px;

      .modal_sub_title {
        float: left;
        padding-top: 3px;
        width: 20%;
      }

      .input_container {
        float: left;
        padding-left: 10px;
        width: 75%;
        padding-right: 10px;
        margin-top: 0 !important;

        input {
          height: 35px;
          margin-left: 0;
        }

        .cal_box {
          &:after {
            display: block;
            visibility: hidden;
            clear: both;
            content: '';
          }

          .react-datepicker-wrapper {
            width: 47%;
            float: left;
          }

          .cal_ir {
            float: left;
            width: 6%;
            height: 37px;
            padding-top: 7px;
            text-align: center;
          }
        }

        input[type='number']::-webkit-inner-spin-button,
        input[type='number']::-webkit-outer-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }

        .check_box_wrap {
          margin-bottom: 8px;

          &:after {
            display: block;
            visibility: hidden;
            clear: both;
            content: '';
          }

          .input_check {
            float: left;
            width: auto !important;
            margin-right: 6px;
            transform: translateY(7px);
            height: 14px;

            @media screen and (max-width: 785px) {
              transform: translateY(3px);
            }

          }


          label {
            float: left;
          }
        }
      }
    }
  }

}




// 워크숍 신청하기 모달창
.workshop_apply_form_wrap {
  width: 100%;
  // border-top: 2px solid;


  .modal_sub_work_title {
    font-size: 1rem;
    color: #696969;
    position: relative;
    margin-left: 25px;

    &::after {
      content: "";
      position: absolute;
      top: 50%;
      left: -10px;
      transform: translate(0, -50%);
      width: 4px;
      height: 50%;
      background-color: #00863C;
    }
  }

  .privacy {}

  .info_privacy {
    margin-bottom: 10px;
  }

  .reg_form {
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;

    .input_box {
      // border-bottom: 1px solid #dfdfdf;

      &:after {
        display: block;
        visibility: hidden;
        clear: both;
        content: '';
      }

      width:100%;

      .modal_sub_title {
        float: left;
        padding-top: 14px;
        width: 25%;


      }

      .input_container {
        // float: left;
        padding-left: 10px;
        width: 100%;
        margin-top: 0 !important;
        border: none !important;

        input {
          color: #696969;
          padding: 10px;
        }

        input[type=text] {
          height: 35px;
          margin-left: 0;
          border: 1px solid #ddd !important;
          border-radius: 4px;
        }

        input:read-only {
          background-color: #F8F8F8 !important;
        }

        .check_box_wrap {
          margin-bottom: 8px;

          &:after {
            display: block;
            visibility: hidden;
            clear: both;
            content: '';
          }

          .input_check {
            float: left;
            width: auto !important;
            margin-right: 6px;
            transform: translateY(7px);
            height: 14px;

            @media screen and (max-width: 1024px) {
              transform: translateY(3px);
            }
          }

          label {
            float: left;
          }
        }
      }
    }

    .box50 {
      flex-basis: 48%;
      width: 48%;
    }

    .privacy_wrapper {
      padding: 20px 0 0 0;
      border-top: 1px solid #ddd;

      .privacy {
        color: #696969;

        .greenTextPrivacy {
          color: #00B852;
          margin-left: 10px;
        }
      }

      .input_container {
        .info_privacy {
          padding: 20px;
          background-color: #F8F8F8;
          color: #696969;
          border-radius: 4px;
        }

        .check_box_wrap {
          display: flex;
          align-items: center;
          margin-top: 10px;

          label {
            float: none !important;
          }

          input[type="checkbox"] {
            display: none;
          }

          input[type="checkbox"]+label {
            display: inline-block;
            width: 20px;
            height: 20px;
            border: 1px solid #ddd;
            border-radius: 3px;
            position: relative;
          }

          input[id="check1"]:checked+label::after {
            content: '✔';
            font-size: 13px;
            color: #fff;
            background-color: #00863C;
            text-align: center;
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
          }

          span {
            margin-left: 5px;
          }
        }
      }
    }
  }

  .course_overlay_footer {
    display: flex;
    width: 100%;
    justify-content: flex-end;

    button:nth-child(1) {
      background-color: #00863C !important;
      color: #fff !important;
      border-radius: 4px;
    }

    button:nth-child(2) {
      background-color: #F46E43 !important;
      color: #fff !important;
      border-radius: 4px;
      border: none;
    }
  }
}


.auditPop {
  .auditSlide {
    margin-bottom: 20px;

    .auditVideoWrap {
      width: 100%;
    }

    .auditSlideTitle {
      margin: 10px 0;
      font-weight: 700;
    }
  }

  .course_pop_bottom {
    margin-top: 10px;
  }

}


.couseVideoLink {
  cursor: pointer;

  &:hover {
    font-weight: 700;
  }
}

.popResize {
  max-width: 900px !important;
  max-height: 900px !important;
}

.input_container_bottom {
  display: inline-block;
  width: calc(100% - 150px);

  @media screen and (max-width: 775px) {
    width: calc(100% - 90px);
  }

  .input_file {
    display: block;
    margin: 10px 0;
  }

  .files_container {
    margin-top: 35px;
  }
}

.fileTitleNew {
  display: inline-flex;
  vertical-align: top;
  font-weight: 700;
  width: 150px;

  @media screen and (max-width: 775px) {
    width: 90px;
  }

}

.progressBox {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999999999;

  .progressBar {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
    max-width: 850px;
    height: 40px;
    border-radius: 100px;
    background-color: #818a91;
    background-size: contain;
    border: 3px solid #fff;
    overflow: hidden;
    box-shadow: 0 0 10px 3px rgba(0, 0, 0, 0.2);


    .progressNum {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      display: block;
      color: #fff;
      font-weight: 700;
      z-index: 99999999999;
      text-shadow: 0px 0px 5x rgba(0, 0, 0, 0.7);
    }

    .progressLoading {
      position: absolute;
      top: 0;
      left: 0;
      height: 40px;
      width: 1%;
      //background-color: #38c538;
      background:url('#{$image-path}/progressBack.png') no-repeat;

    }

  }

}

.auditBoxCont {
  width: 100%;
  overflow-x: auto;
  border-radius: 4px;
  border: 1px solid #ddd;

  .auditcontent_table {
    width: 100%;
    min-width: 1000px;
  }
}

.audittab {

  @media screen and (max-width: 550px) {
    border: none !important;
    margin-bottom: 8x;
  }

  .auditcate {
    margin-bottom: 20px !important;

    @media screen and (max-width: 550px) {
      border: none !important;
      padding: 6px 0px !important;
      margin-bottom: 0 !important;
    }

  }
}


//211022 품질관리시스템 스타일 수정

.audit_comment {
  font-size: 0.9rem;
  background: #cecece;
  width: auto;
  display: inherit;
  position: absolute;
  padding: 10px;
  border-radius: 3px;

  span {
    margin-left: 5px;
  }

  .back-white {
    background: white;
    padding: 7px;
    border-radius: 5px;
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .filedown {
    margin: 3px 0;
    font-weight: 700;
    color: #2a9150;
    font-size: 15px;
    display: block;
    margin-top: 5px;

    &:hover {
      color: #000000;
    }
  }

  &.none {
    display: none;
  }

  div {
    font-size: 0.9rem;
  }
}


// 리눅스 시스템 이해와 활용(초급)
.page-content-61c55674192e57261ef25238 {
  .detail_table th:nth-child(4) {
    width: 60%;
  }
}

.page-community-survey {
  .sub_survey_title {
    width: 100%;
    background-color: #f8f8f8;
    padding: 30px;
    border-radius: 5px;

    h1 {
      font-size: 1.4rem;
      font-weight: 600;
      position: unset;
      margin-bottom: 10px;
    }
  }

  .ql-container {
    height: auto;
    border: 1px solid #bedaf4 !important;
    border-radius: 5px;
    padding: 20px;
    margin: 20px 0 40px;
  }

  .survey_question {
    padding: 0 30px 15px;
    border: 1px solid #e5e5e5;
    border-radius: 5px;
    margin: 20px 0;

    .boardDetail__content {
      input[type=text] {
        width: 100%;
        border: 1px solid #e5e5e5;
        height: 40px;
      }
    }

    h1 {
      margin-top: 0.3rem;
      margin-bottom: 2.3rem;
      line-height: 4.3rem;
      border-bottom: 0.5px solid #e5e5e5;
      font-size: 1.2rem;
      font-weight: 600;
      position: unset;
      letter-spacing: -1.5px;
    }

    label {
      vertical-align: middle;
      font-size: 17px;
      padding: 0 0 15px 10px;
      font-weight: 500;

      input[type=radio] {
        background-color: #ececec;
        border: 7px solid #ececec;
        width: 30px;
        height: 30px;
        display: inline-block;
        vertical-align: middle;
        margin-right: 15px;
        appearance: none;
        border-radius: 50px;

        &:checked {
          border: 7px solid #92c1ec;
          width: 30px;
          height: 30px;
        }
      }

      input[type=checkbox] {
        background-color: #ececec;
        border: 5px solid #ececec;
        width: 20px;
        height: 20px;
        display: inline-block;
        vertical-align: middle;
        margin-right: 15px;
        appearance: none;

        &:checked {
          border: 7px solid #92c1ec;
          width: 20px;
          height: 20px;
        }
      }
    }

    .evaluate_table {

      input[type=radio] {
        background-color: #ececec;
        border: 7px solid #ececec;
        width: 20px;
        height: 20px;
        display: inline-block;
        vertical-align: middle;
        appearance: none;
        border-radius: 50px;

        &:checked {
          border: 5px solid #92c1ec;
          width: 20px;
          height: 20px;
        }
      }
    }
  }
}

.reset_btn {
  background-color: #00863C !important;
  color: #fff !important;
  border-radius: 5x !important;
}

.survey-remove {
  display: inline-block;
  border-radius: 16px;
  width: 28px !important;
  height: 12px;
  vertical-align: middle;
  transition: background 0.25s;
  background: #56c080;
  position: relative;
  margin: 0 10px;

  &::before {
    content: '';
    left: 14px;
    background: linear-gradient(to bottom, #fff 0%, #fff 100%);
    display: block;
    background: linear-gradient(to bottom, #fff 0%, #eee 100%);
    border-radius: 50%;
    width: 14px;
    height: 14px;
    top: -0.5px;
    transition: left 0.25s;
    position: absolute;
  }
}

.none {
  .survey-remove {
    background: #ccc;

    &::before {
      content: '';
      left: 3px;
    }
  }

}

.checkbox-center {
  justify-content: center;
}

// 설문조사 생성 css 추가

.survey__detail {
  ._modal__block-body {
    padding: '20px 0';

  }

  .survey_input_containerBox {
    margin-bottom: 10px;
    // display: flex;

    .searchUser {
      background: #00B852;
      border-radius: 4px;
      color: white;
      padding: 5px 50px;
    }




    input.survey_desc {
      border: 0;
      border-bottom: 1px solid #ddd;
      border-radius: 0;
      outline: none;
    }

    select.survey_select {
      min-width: 100px;
      max-width: 200px;
      height: 40px;
      padding: 0 15px;
      width: 100%;
      font-size: 14px;
      color: #333333;
      background: #fff url(https://lms.gjudec.com/assets/images/nav-toggle-down2.png) no-repeat calc(100% - 15px) center;
      appearance: none;
      border: 1px solid #D2D4D6;
      border-radius: 4px;
    }

    &:focus {
      outline: 0;
    }

    .insertForm__name {
      font-family: 'Pretendard';
      font-style: normal;
      font-weight: 600;
      font-size: 15px;
      line-height: 40px;
      /* identical to box height, or 267% */

      display: flex;
      align-items: center;

      color: #868686;


    }
  }

}

.survey__footer {
  border-top: 0;
  padding: 20px 0;

  .survey__actions {
    justify-content: flex-end;
    margin: 0;
    display: flex;

    .survey__preview {
      width: 140px;
      height: 40px;
      border-radius: 13px;
      color: #fff;
      background: #62816d;

      &::before {
        content: '';
        width: 17px;
        height: 17px;
        display: inline-block;
        background: url(https://lms.gjudec.com/assets/images/modal-btn-submit.png) no-repeat center;
        vertical-align: middle;
        margin-right: 5px;
      }
    }

    .survey__submit {
      width: 140px;
      height: 40px;
      border-radius: 13px;
      color: #fff;
      background: #55B87A;
      margin-left: 10px;

      &::before {
        content: '';
        width: 17px;
        height: 17px;
        display: inline-block;
        background: url(https://lms.gjudec.com/assets/images/modal-btn-submit.png) no-repeat center;
        vertical-align: middle;
        margin-right: 5px;
      }
    }



    .survey__cancel {
      width: 140px;
      height: 40px;
      border-radius: 13px;
      background: #ACDBA4;
      color: #5C8D72;
      margin-left: 10px;

      &::before {
        content: '';
        width: 17px;
        height: 17px;
        display: inline-block;
        background: url(https://lms.gjudec.com/assets/images/modal-btn-cancel.png) no-repeat center;
        vertical-align: middle;
        margin-right: 5px;
      }
    }


  }
}

.content_wrapper_programs {
  display: flex;
  justify-content: space-between;
  width: 100%;


  @media screen and (max-width:1200px) {
    flex-direction: column;
  }

  .selectTabs {
    flex-basis: 21%;
    width: 21%;
    background: #FFFFFF;
    border: 1px solid #DDDDDD;
    border-radius: 4px;
    overflow: hidden;
    // margin-top: 2.5rem;
    display: flex;
    justify-content: start;
    align-items: center;
    flex-direction: column;
    height: 100%;
    min-height: 980px;
    max-height: 980px;

    @media screen and (max-width:1200px) {
      height: 100%;
      min-height: auto;
      max-height: auto;
    }

    @media screen and (max-width:550px) {
      border-radius: 0;
      overflow: auto;
    }

    .selectFlex {
      @media screen and (max-width:1200px) {
        display: flex;
        gap: 11px;
      }

      @media screen and (max-width:550px) {
        gap: 0;
      }
    }

    .toggleSwitch {
      display: inline-block;
      width: 35px;
      height: 20px;
      position: relative;
      border-radius: 2rem;
      background-color: #fff;
      border: 2px solid #D04B4B;
      //box-shadow: 0 0 1rem 3px rgba(0 0 0 15);
      cursor: pointer;
    }

    /* 토글 버튼 */
    .toggleSwitch .toggleButton {
      width: 13px;
      height: 13px;
      position: absolute;
      top: 50%;
      left: 2px;
      transform: translateY(-50%);
      border-radius: 50%;
      background: #D04B4B;
    }

    #toggle:checked~.toggleSwitch {
      border: 2px solid #fff;
      background: #fff;
    }

    #toggle:checked~.toggleSwitch .toggleButton {
      /* 100% -> 끝위치, 2.8rem -> 버튼 크기 */
      left: auto;
      right: 2px;
      background: #38AA6B;
    }

    .toggleSwitch,
    .toggleButton {
      transition: all 0.2s ease-in;
    }

    @media screen and (max-width:1200px) {
      flex-basis: 100%;
      width: 100%;
      margin-top: 0;
    }

    .tabWrap {
      padding-bottom: 15px;
      width: 100%;
      position: relative;
      z-index: 997;

      .inToggle {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 12px 17px;
        border-bottom: 1px solid #ddd;
        margin-bottom: 15px;
        transition: 0.3s ease;

        @media screen and (max-width:550px) {
          margin-bottom: 8px;
          padding: 9px 17px;
        }

        &.actives {
          background-color: #38AA6B;

          .leftBox {
            color: #fff;
          }
        }

        .leftBox {
          font-size: 1rem;
          font-weight: 700;
          color: #00863C;
        }
      }

      &.disnone {
        display: block;
      }

      @media screen and (max-width:1200px) {
        width: 100%;
        padding-bottom: 0;

        &.disnone {
          display: none;
        }
      }

    }

    .selectTabs_header {
      background: #00863C;
      color: #fff;
      width: 100%;
      text-align: center;
      padding: 0 15px;
      position: relative;
      z-index: 997;

      .openToggle {
        display: none;
      }

      @media screen and (max-width:1200px) {
        display: flex;
        justify-content: space-between;
        align-items: center;


        .openToggle {
          display: block;
          width: 16px;
          height: 42px;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
        }
      }

      p {
        padding: 10px 0;
        font-size: 17px;
        font-weight: 500;

        @media screen and (max-width:1200px) {
          font-size: 1rem;
        }

      }
    }

    .selectTabs_01,
    .selectTabs_02,
    .selectTabs_03,
    .selectTabs_04 {
      width: 100%;
      text-align: left;

      @media screen and (max-width:1200px) {
        width: 50%;
      }

      p {
        color: #00863C;
        font-size: 16px;
        font-weight: 600;
        padding: 0 15px;

        @media screen and (max-width:1200px) {
          font-size: 1rem;
        }
      }
    }

    .selectTabs_01 {

      &~.selectTabs_02 {
        margin-top: 20px;

        @media screen and (max-width:1200px) {
          margin-top: 0;
        }
      }

      .selectTabs_01_body {
        display: block;
        overflow-y: auto;
        padding: 0;
        margin-top: 8px;
        padding-right: 15px;
        padding-left: 15px;
        max-height: 340px;

        @media screen and (max-width:1200px) {
          max-height: 130px;
          padding-bottom: 20px;
          padding-right: 5px;
        }


        &::-webkit-scrollbar {
          width: 3px;
          margin-left: 10px;
        }

        &::-webkit-scrollbar-thumb {
          height: 30%;
          background: #00863C;

          border-radius: 10px;
        }

        &::-webkit-scrollbar-track {
          background: #e9fff3;
        }

        .selectTabs_01_checkbox {
          display: flex;
          justify-content: space-between;
          align-items: center;

          div {
            font-size: 0.9rem;
          }

          &.disabled {
            .selectTabs_01_checkbox_Wrap {
              label {
                background-color: #ddd;

                &.org_name {
                  background-color: transparent;
                }
              }
            }
          }

          &~.selectTabs_01_checkbox {
            margin-top: 12px;
          }

          .selectTabs_01_checkbox_Wrap {
            padding-right: 5px;



            input[type="checkbox"] {
              display: none;
            }

            input[type="checkbox"]:checked+label {
              &:after {
                content: '✔';
                font-size: 15px;
                color: #fff;
                width: 20px;
                height: 20px;
                background-color: #00863C;
                text-align: center;
                position: absolute;
                left: 0;
                bottom: 0;
              }

            }



            label {
              word-break: keep-all;
              width: 20px;
              min-width: 20px;

              &.org_name {
                margin-left: 10px;
                width: 100%;
                font-size: 0.95rem;
                line-height: 1.2rem;

                @media screen and (max-width:550px) {
                  display: none;
                }

                &.mobiles {
                  display: none;

                  @media screen and (max-width:550px) {
                    display: block;
                  }

                  .marquee-container {

                    @media screen and (max-width:550px) {
                      max-width: 150px;
                      width: fit-content;
                    }

                    @media screen and (max-width:480px) {
                      max-width: 110px;
                    }

                    @media screen and (max-width:390px) {
                      max-width: 90px;
                    }

                    @media screen and (max-width:365px) {
                      max-width: 56px;
                    }

                  }
                }
              }
            }
          }

          .lengths {
            font-size: 0.9rem;
            color: #949494;
          }
        }
      }
    }

    .selectTabs_02 {


      &~.selectTabs_03 {
        margin-top: 20px;
      }

      .selectTabs_02_body {
        display: block;
        padding: 0;
        overflow-y: auto;
        margin-top: 8px;
        padding-right: 15px;
        padding-left: 15px;
        max-height: 415px;

        @media screen and (max-width:1200px) {
          max-height: 130px;
          padding-bottom: 20px;
        }


        &::-webkit-scrollbar {
          width: 3px;
        }

        &::-webkit-scrollbar-thumb {
          height: 30%;
          background: #00863C;

          border-radius: 10px;
        }

        &::-webkit-scrollbar-track {
          background: #e9fff3;
        }

        .selectTabs_02_checkbox {
          display: flex;
          justify-content: space-between;

          div {
            font-size: 0.9rem;
          }

          &.disabled {
            .selectTabs_02_checkbox_Wrap {
              label {
                background-color: #ddd;

                &.topic_name {
                  background-color: transparent;
                }
              }
            }
          }

          &~.selectTabs_02_checkbox {
            margin-top: 10px;
          }

          .selectTabs_02_checkbox_Wrap {


            input[type="checkbox"] {
              display: none;
            }

            input[type="checkbox"]:checked+label {
              &:after {
                content: '✔';
                font-size: 15px;
                color: #fff;
                width: 20px;
                height: 20px;
                background-color: #00863C;
                text-align: center;
                position: absolute;
                left: 0;
                bottom: 0;
              }

            }


            label {
              word-break: keep-all;
              min-width: 20px;
              width: 20px;

              &.topic_name {
                margin-left: 10px;
                width: 100%;
                font-size: 0.95rem;
                line-height: 1rem;
              }
            }
          }

          .lengths {
            font-size: 0.95rem;
            color: #949494;
          }

        }
      }
    }

    .selectTabs_03 {


      &~.selectTabs_04 {
        margin-top: 20px;
      }

      .selectTabs_03_body {
        display: block;
        padding: 0;
        overflow-y: auto;
        margin-top: 8px;
        padding-right: 15px;
        padding-left: 15px;
        max-height: 230px;

        @media screen and (max-width:1200px) {
          max-height: 130px;
        }


        &::-webkit-scrollbar {
          width: 3px;
        }

        &::-webkit-scrollbar-thumb {
          height: 30%;
          background: #00863C;

          border-radius: 10px;
        }

        &::-webkit-scrollbar-track {
          background: #e9fff3;
        }

        .selectTabs_03_checkbox {
          display: flex;
          justify-content: space-between;

          div {
            font-size: 0.9rem;
          }


          &~.selectTabs_03_checkbox {
            margin-top: 10px;
          }

          .selectTabs_03_checkbox_Wrap {

            input[type="checkbox"] {
              display: none;
            }

            input[type="checkbox"]:checked+label {
              &:after {
                content: '✔';
                font-size: 15px;
                color: #fff;
                width: 20px;
                height: 20px;
                background-color: #00863C;
                text-align: center;
                position: absolute;
                left: 0;
                bottom: 0;
              }

            }


            label {
              word-break: keep-all;
              min-width: 20px;
              width: 20px;

              &.org_name {
                margin-left: 10px;
                width: 100%;
                font-size: 0.95rem;
                line-height: 1rem;
              }
            }
          }

          .lengths {
            font-size: 0.95rem;
            color: #949494;
          }

        }
      }
    }



    .selectTabs_04 {


      .selectTabs_04_body {
        display: block;
        padding: 0;
        overflow-y: auto;
        margin-top: 8px;
        padding-right: 15px;
        padding-left: 15px;
        max-height: 230px;

        @media screen and (max-width:1200px) {
          max-height: 130px;
        }


        &::-webkit-scrollbar {
          width: 3px;
        }

        &::-webkit-scrollbar-thumb {
          height: 30%;
          background: #00863C;

          border-radius: 10px;
        }

        &::-webkit-scrollbar-track {
          background: #e9fff3;
        }

        .selectTabs_04_checkbox {
          display: flex;
          justify-content: space-between;

          div {
            font-size: 0.9rem;
          }


          &~.selectTabs_04_checkbox {
            margin-top: 10px;
          }

          .selectTabs_04_checkbox_Wrap {

            input[type="checkbox"] {
              display: none;
            }

            input[type="checkbox"]:checked+label {
              &:after {
                content: '✔';
                font-size: 15px;
                color: #fff;
                width: 20px;
                height: 20px;
                background-color: #00863C;
                text-align: center;
                position: absolute;
                left: 0;
                bottom: 0;
              }

            }


            label {
              word-break: keep-all;
              min-width: 20px;
              width: 20px;

              &.org_name {
                margin-left: 10px;
                width: 100%;
                font-size: 0.95rem;
                line-height: 1rem;
              }
            }








          }

          .lengths {
            font-size: 0.95rem;
            color: #949494;
          }

        }
      }
    }


  }

  //0916
  .contentTabs {
    // flex-basis: 100%;
    // width: 100%;
    flex-basis: 77.5%;
    width: 77.5%;

    // margin-top: 2.5rem;
    .content_input_wrap {
      display: flex;
      justify-content: end;

      .contents_select {
        border-radius: 4px;
        padding-right: 12px;

        @media screen and (max-width:500px) {
          select{
            padding-right: 17px !important;
            background-size: 16px !important;
            background-position-x: calc(100% - 7px)!important;
          }
        }
        select {
          border: 1px solid #DDDDDD !important;
          background: url('#{$image-path}/select_arrow.svg') no-repeat right;
          border-radius: 4px;
          padding-right: 45px;
          background-size: 20px;
          background-position-x: calc(100% - 10px);

        }
      }
    }

    input {
      width: 200px;
    }

    @media screen and (max-width:1200px) {
      flex-basis: 100%;
      width: 100%;
      margin-top: 10px;
    }

    .wrapcontentlist {
      @media screen and (max-width:768px) {
        max-height: 520px !important;
        overflow: unset !important;
      }

      .programList__list {
        @media screen and (max-width:768px) {
          grid-template-columns: 100% !important;
          grid-row-gap: 13px;

          .info {
            h2 {
              font-size: 14px !important;
            }
          }
        }
      }
    }

    .programList {
      margin-top: 10px;

      @media screen and (max-width:1200px) {
        max-height: 500px;
        overflow-y: scroll;
      }

      .programList__list {
        margin: 0;
        justify-content: space-between;
        grid-template-columns: 32% 32% 32%;
        grid-row-gap: 15px;

        &.myPage_list {
          grid-template-columns: 100%;
          grid-row-gap: 15px;
        }

        // @media screen and (max-width:550px) {
        //   grid-template-columns: 100%;
        //   grid-row-gap: 10px;
        // }

        .programList__item {
          width: 100%;

        }

        .metaverseList__item {
          margin: 7px 7px;
          width: 29%;


          @media screen and (max-width:768px) {
            width: 45%;
          }

          @media screen and (max-width:500px) {
            width: 85%;
          }
        }
      }
    }
  }
}

.title_content_wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .tabs {
    margin: 0;

    button {
      width: 150px;

      span {
        font-size: 0.8rem !important;
        font-weight: 400 !important;
      }
    }
  }
}

@media screen and (max-width:768px) {
  .pc_tabs {
    display: none !important;
  }

  .mobile_tabs {
    display: block !important;
  }
}

@media screen and (min-width:769px) {
  .pc_tabs {
    display: flex !important;
  }

  .mobile_tabs {
    display: none !important;
  }
}

.content_input_wrap {
  border: none !important;
  width: 100% !important;
  position: relative;
  display: flex;

  input {
    background: #FFFFFF;
    border: 1px solid #DDDDDD !important;
    border-radius: 4px;
  }

  button {
    position: relative;
    right: 0;
    background: #333 !important;
    color: #fff !important;
  }
}

.program_reset_btn {
  background: none !important;
  border: 1px solid #ddd;
  border-radius: 10px !important;
  color: #333 !important;
}



.selectTabs_01_checkbox_Wrap,
.selectTabs_02_checkbox_Wrap,
.selectTabs_03_checkbox_Wrap,
.selectTabs_04_checkbox_Wrap {
  display: flex;
  align-items: center;

  input[type="checkbox"] {
    display: none;
  }

  input[type="checkbox"]+label {
    display: inline-block;
    width: 20px;
    height: 20px;
    overflow: hidden;
    border-radius: 7px;
    border: 1px solid #ddd;
    position: relative;
  }


  input[type="checkbox"] {
    display: none;
  }

  input[type="checkbox"]+label {
    display: inline-block;
    width: 20px;
    height: 20px;
    overflow: hidden;
    border-radius: 7px;
    border: 1px solid #ddd;
    position: relative;
  }

  @for $i from 0 to 100 {
    input[id="checkitem#{$i}"]:checked+label::after {
      content: '✔';
      font-size: 15px;
      color: #fff;
      width: 20px;
      height: 20px;
      background-color: #00863C;
      text-align: center;
      position: absolute;
      left: 0;
      bottom: 0;
    }

    input[id="check#{$i}"]:checked+label::after {
      content: '✔';
      font-size: 15px;
      color: #fff;
      width: 20px;
      height: 20px;
      background-color: #00863C;
      text-align: center;
      position: absolute;
      left: 0;
      bottom: 0;
    }


    // input[id="checkinout#{$i}"]:checked+label::after {
    //   content: '✔';
    //   font-size: 15px;
    //   color: #fff;
    //   width: 20px;
    //   height: 20px;
    //   background-color: #00863C;
    //   text-align: center;
    //   position: absolute;
    //   left: 0;
    //   bottom: 0;
    // }


    // input[id="checkonoff#{$i}"]:checked+label::after {
    //   content: '✔';
    //   font-size: 15px;
    //   color: #fff;
    //   width: 20px;
    //   height: 20px;
    //   background-color: #00863C;
    //   text-align: center;
    //   position: absolute;
    //   left: 0;
    //   bottom: 0;
    // }

  }






  input[id="target_all"]:checked+label::after {
    content: '✔';
    font-size: 15px;
    color: #fff;
    width: 20px;
    height: 20px;
    background-color: #00863C;
    text-align: center;
    position: absolute;
    left: 0;
    bottom: 0;
  }

  input[id="target_ins"]:checked+label::after {
    content: '✔';
    font-size: 15px;
    color: #fff;
    width: 20px;
    height: 20px;
    background-color: #00863C;
    text-align: center;
    position: absolute;
    left: 0;
    bottom: 0;
  }

  input[id="target_stu"]:checked+label::after {
    content: '✔';
    font-size: 15px;
    color: #fff;
    width: 20px;
    height: 20px;
    background-color: #00863C;
    text-align: center;
    position: absolute;
    left: 0;
    bottom: 0;
  }
}

.title_wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 15px;


  @media screen and (max-width: 1220px) {
    padding-top: 10px;
  }


  @media screen and (max-width: 550px) {
    align-items: center;
  }


  button {
    height: 40px;
    color: #fff;
    background: #489659;
    font-size: 0.95rem;
    border-radius: 3px;
    width: 100px;
  }
}

.btnnn_wrapper {
  display: flex;
  justify-content: flex-end;

  .moveBtnGreen {
    margin-top: 20px;
    height: 40px;
    color: #949494;
    background: #fff;
    border: 1px solid #ddd;
    font-size: 0.9rem;
    border-radius: 3px;
    width: 100px;
  }

  .moveBtnRed {
    margin-top: 20px;
    margin-right: 10px;

    height: 40px;
    color: #fff;
    background: #FF845E;
    font-size: 1rem;
    border-radius: 3px;
    width: 100px;

  }
}


.title_workshop {
  text-align: left;
  margin: 20px 0 0 0;
  border-top: 1px solid #ddd;

  .title_design {
    font-size: 1.5rem;
    font-weight: 600;
    padding-left: 1em;
    position: relative;
    margin-bottom: 0.8em;
    margin-top: 60px;

    &::after {
      content: '';
      display: block;
      width: 0.7em;
      height: 0.7em;
      border: 5px solid #489659;
      border-radius: 50%;
      position: absolute;
      left: 0;
      top: 5px;
    }
  }
}

.file_down_btn {
  border: none;
  background-color: #489659;
  color: #fff;
  padding: 3px 20px;
  border-radius: 3px;
}

.atag_container {
  margin-top: 10px !important;
}


// 메타버스 생성 css 추가
.metaverse_detail {
  ._modal__block-body {
    padding: 0 !important;

  }

  .metaverse_input_containerBox {
    margin-bottom: 0px;

    // display: flex;
    .insertForm__name {
      font-family: 'Pretendard';
      font-style: normal;
      font-weight: 500;
      font-size: 13px;
      line-height: 37px;
      /* identical to box height, or 285% */

      display: flex;
      align-items: center;

      /* 696969 */

      color: #696969;
      padding: 0;


    }

    .metaverse_detail_info {
      .insertForm__content_row {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        flex: 1 1;
      }
    }

    .insertForm__content {
      .scene_wrapper {
        display: flex;
        overflow-x: auto;

        .scene_contents {
          margin-left: 15px;
          min-width: 160px;
          min-height: 90px;
          height: 100%;

          width: 100%;
          max-width: 200px;

          img {
            width: 100%;
          }

          .custom-checkbox_container {
            height: auto;
            min-height: 40px;
            display: flex;
            align-items: center;
            justify-content: center;

            .custom-checkbox {
              margin-top: 0 !important;
              min-width: 85px;
              display: -webkit-flex;
              display: flex;
              -webkit-align-items: center;
              align-items: center;
              justify-content: center;

              input {
                display: none;
              }

              input[type=radio]+span {
                width: 18px;
                height: 18px;
                flex: none;
                border: 1px solid #ddd;
                border-radius: 50%;
                margin-right: 5px;
              }

              input[type=radio]:checked+span {
                background: none !important;
                border: 5px solid #55b87a;
              }
            }
          }
        }


      }
    }


    input.title_input {
      background: #FFFFFF;
      /* ddd */

      border: 1px solid #DDDDDD;
      border-radius: 4px;
    }

    select.survey_select {
      min-width: 100px;
      max-width: 200px;
      border: 1px solid #ddd;
      border-radius: 5px;
      height: 40px;
      padding: 0 15px;
      width: 100%;
      font-size: 14px;
      color: #666666;
      background: #fff url(https://lms.gjudec.com/assets/images/nav-toggle-down.png) no-repeat calc(100% - 15px) center;
      border-radius: 14px;
      appearance: none
    }

    &:focus {
      outline: 0;
    }
  }

}

.qna_q {
  border: 1px solid #767676;
  padding: 20px 10px;
  border-radius: 3px;
  margin: 10px 0;
}

.ok_Status {
  background: #489659;
  color: white;
  padding: 4px 0;
  border-radius: 3px;
}

.nonok_Status {
  background: #a8a8a8;
  color: white;
  padding: 4px 0;
  border-radius: 3px;
}


.history_buttons {
  color: #949494;
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 7px 30px;
  font-size: 0.9rem;
}

//메타버스 리스트 스타일 추가함
.content_wrapper_metaverse {
  display: flex;
  justify-content: space-between;
  width: 100%;

  @media screen and (max-width:1200px) {
    flex-direction: column;
  }

  .contentTabs {
    flex-basis: 100%;
    width: 100%;
    margin-top: 0 !important;
  }

  .selectTabs {
    flex-basis: 21%;
    width: 21%;
    background: #FFFFFF;
    border: 1px solid #DDDDDD;
    border-radius: 10px;
    overflow: hidden;
    margin-top: 2.5rem;
    display: flex;
    justify-content: start;
    align-items: center;
    flex-direction: column;


    @media screen and (max-width:1200px) {
      flex-basis: 100%;
      width: 100%;
      margin-top: 0;
    }

    .tabWrap {
      padding-bottom: 15px;
      width: 100%;




      .inToggle {
        display: flex;
        align-items: center;
        justify-content: center;


      }

      &.disnone {
        display: block;
      }

      @media screen and (max-width:1200px) {
        width: 100%;

        &.disnone {
          display: none;
        }
      }

    }

    .selectTabs_header {
      background: #00863C;
      color: #fff;
      width: 100%;
      text-align: center;
      padding: 0 15px;

      .openToggle {
        display: none;
      }

      @media screen and (max-width:1200px) {
        display: flex;
        justify-content: space-between;
        align-items: center;


        .openToggle {
          display: block;
          width: 16px;
          height: 40px;
          cursor: pointer;
        }
      }

      p {
        padding: 10px 0;
        font-size: 17px;
        font-weight: 500;

        @media screen and (max-width:1200px) {
          font-size: 1rem;
        }

      }
    }

    .selectTabs_01,
    .selectTabs_02 {
      width: 100%;
      text-align: left;

      p {
        color: #00863C;
        font-size: 16px;
        font-weight: 600;
        padding: 0 15px;
      }
    }

    .selectTabs_01 {

      &~.selectTabs_02 {
        margin-top: 20px;
      }

      .selectTabs_01_body {
        display: block;
        overflow-y: auto;
        padding: 0;
        margin-top: 8px;
        padding-right: 15px;
        padding-left: 15px;

        &::-webkit-scrollbar {
          width: 3px;
          margin-left: 10px;
        }

        &::-webkit-scrollbar-thumb {
          height: 30%;
          background: #00863C;

          border-radius: 10px;
        }

        &::-webkit-scrollbar-track {
          background: #e9fff3;
        }

        .selectTabs_01_checkbox {
          display: flex;
          justify-content: space-between;
          align-items: center;

          &~.selectTabs_01_checkbox {
            margin-top: 12px;
          }

          .selectTabs_01_checkbox_Wrap {
            padding-right: 5px;

            label {
              word-break: keep-all;
              width: 20px;
              min-width: 20px;

              &.org_name {
                margin-left: 10px;
                width: 100%;
                font-size: 0.95rem;
                line-height: 1.2rem;
              }
            }
          }

          .lengths {
            font-size: 0.95rem;
            color: #949494;
          }
        }
      }
    }

    .selectTabs_02 {

      .selectTabs_02_body {
        display: block;
        padding: 0;
        overflow-y: auto;
        margin-top: 8px;
        padding-right: 15px;
        padding-left: 15px;

        &::-webkit-scrollbar {
          width: 3px;
        }

        &::-webkit-scrollbar-thumb {
          height: 30%;
          background: #00863C;

          border-radius: 10px;
        }

        &::-webkit-scrollbar-track {
          background: #e9fff3;
        }

        .selectTabs_02_checkbox {
          display: flex;
          justify-content: space-between;

          &~.selectTabs_02_checkbox {
            margin-top: 10px;
          }

          .selectTabs_02_checkbox_Wrap {
            padding-right: 5px;

            label {
              word-break: keep-all;
              min-width: 20px;
              width: 20px;

              &.topic_name {
                margin-left: 10px;
                width: 100%;
                font-size: 0.9rem;
                line-height: 1rem;
              }
            }
          }

          .lengths {
            font-size: 0.9rem;
            color: #949494;
          }

        }
      }
    }
  }

  //0916
  .contentTabs {
    // flex-basis: 100%;
    // width: 100%;
    flex-basis: 100%;
    width: 100%;
    margin-top: 2.5rem;

    @media screen and (max-width:1200px) {
      flex-basis: 100%;
      width: 100%;
      margin-top: 10px;
    }



    .programList {
      margin-top: 20px;

      .programList__list {
        margin: 0;
        justify-content: space-between;
        grid-template-columns: 32% 32% 32%;
        grid-row-gap: 15px;

        &.myPage_list {
          grid-template-columns: 100%;
          grid-row-gap: 15px;
        }

        @media screen and (max-width:768px) {
          grid-template-columns: 49% 49%;
          grid-row-gap: 13px;
        }

        @media screen and (max-width:550px) {
          grid-template-columns: 100%;
          grid-row-gap: 10px;
        }

        .programList__item {
          width: 100%;
        }

        .metaverseList__item {
          margin: 7px 7px;
          width: 29%;


          @media screen and (max-width:768px) {
            width: 45%;
          }

          @media screen and (max-width:500px) {
            width: 85%;
          }
        }
      }
    }
  }
}

.select_ins {
  width: 140px;
  height: 40px;
  border-radius: 13px;
  color: #fff;
  background: #55B87A;
}


.paramsResult {
  color: #696969;
  font-size: 0.85rem;
  margin-top: 10px;
}


.searchResult {
  display: flex;
  margin-top: 20px;
  align-items: center;
  line-height: 0.9rem;
  font-size: 0.9rem;

  .totals {
    color: #00863C;
    font-size: 1.1rem;
    line-height: 1.1rem;
    font-weight: 600;
    margin-left: 6px;
    margin-right: 2px;
    display: inline-block;
  }
}


.open_img {
  @media screen and (max-width: 990px) {}
}

.tableTrWs {
  input[type="checkbox"] {
    display: none;
  }

  input[type="checkbox"]+label {
    display: inline-block;
    width: 15px;
    height: 15px;
    position: relative;
    border: 1px solid #BCBCBC;
    border-radius: 4px;
    overflow: hidden;
  }

  @for $i from 0 to 1000 {
    input[id="checkitem#{$i}"]:checked+label::after {
      content: '✔';
      font-size: 12px;
      color: #fff;
      width: 15px;
      height: 15px;
      background-color: #00863C;
      text-align: center;
      position: absolute;
      left: 0;
      bottom: 0;
    }

  }

  input[id="check"]:checked+label::after {
    content: '✔';
    font-size: 12px;
    color: #fff;
    width: 15px;
    height: 15px;
    background-color: #00863C;
    text-align: center;
    position: absolute;
    left: 0;
    bottom: 0;
  }
}






.popup_overlay.survey_use {
  position: fixed;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  z-index: 999;
  -webkit-animation: dialog-fadein 0.5s;
  animation: dialog-fadein 0.5s;

  .popup_content {
    //-webkit-animation: dialog-flyin .5s;
    animation: dialog-fadein 0.5s;
    position: relative;
    background: #fff;
    width: 100%;
    margin: auto;
    border: 1px solid rgb(187, 187, 187);
    padding: 10px;
    max-width: calc(100% - 10px);
    overflow: auto;
    height: 100%;
    max-height: calc(100% - 10px);
    border-radius: 3px;

    &.survey {
      // max-width: 100%;
      // max-height: 100%;
      max-width: calc(100% - 10px);
      max-height: calc(100% - 10px);
    }

    &.coursePop {
      height: fit-content;
    }

    @media screen and (max-width: 768px) {
      max-height: 480px;
      min-width: 330px;
    }

    &.sub {
      max-height: 410px;
    }

    &.workshop {
      max-height: 853px;
    }

    &.worksurvey_height {
      max-height: 735px;
    }

    &.survey {
      // max-width: 100%;
      // max-height: 100%;

      ._modal__footer {
        border-top: 0;
        padding: 20px 0;

        ._modal__actions {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          margin: 0;
          position: relative;

          // 푸터 버튼
          button {
            width: 140px;
            height: 40px;
            border-radius: 3px;
            background: #ACDBA4;
            color: #5C8D72;
            margin-left: 10px;

            // &::before {
            //   content: '';
            //   width: 17px;
            //   height: 17px;
            //   display: inline-block;
            //   background: url('#{$image-path}/modal-btn-cancel.png') no-repeat center;
            //   vertical-align: middle;
            //   margin-right: 5px;
            // }

            // 푸터 버튼 - 저장
            // &.submit {
            //   color: #fff;
            //   background: #55B87A;

            //   &::before {
            //     background: url('#{$image-path}/modal-btn-submit.png') no-repeat center;
            //   }
            // }

            // 푸터 버튼 - 삭제
            &.bg-danger {
              &.text-white {
                color: #fff;
                background: #1A9952 !important;

                &::before {
                  width: 18px;
                  height: 20px;
                  background: url('#{$image-path}/modal-btn-delete.png') no-repeat center;
                }
              }
            }
          }
        }
      }

      .survey {

        .survey_del_btn,
        .survey-remove,
        .survey_arrow {
          img {
            width: 11px;
            height: 11px;
          }
        }

        .survey__select-type {
          display: flex;
          padding: 40px 0;

          .survey__select-type-item {
            flex: 1 1;
            border-radius: 5px;
            padding: 40px 0;
            height: 250px;
            text-align: center;
            background: #f2f2f2;
            width: 25%;
          }
        }

        ._btn {
          background: #ACDBA4;
          border: 1px solid #ACDBA4;
          color: #5C8D72;
          font-weight: 500;
          border-radius: 13px;
          height: 40px;
          padding: 0 30px;
          margin: auto;
        }

        .survey__content-item {

          border: 1px solid #ddd;
          border-radius: 5px;
          overflow: hidden;

          &.active {
            border: 2px solid #00B852 !important;
            border-radius: 4px !important;
          }

          ._modal__block-body {
            padding: 20px !important;

            &.survey_type,
            &.survey_content {
              .insertForm__name {
                font-family: 'Pretendard';
                font-style: normal;
                font-weight: 500;
                font-size: 13px;
                line-height: 37px;
                /* identical to box height, or 285% */

                display: flex;
                align-items: center;

                /* 696969 */

                color: #696969;
              }

              .insertForm__content {
                .survey_type_div {
                  background: #F8F8F8;
                  border: 1px solid #DDDDDD;
                  border-radius: 4px;
                  padding: 9px;
                  font-family: 'Pretendard';
                  font-style: normal;
                  font-weight: 400;
                  font-size: 15px;
                  line-height: 18px;
                  color: #696969;
                }
              }
            }


            &.survey_options {
              //옵션 보기 입력
              border-top: 1px dashed #DDDDDD;
              background: #F8F8F8;

            }
          }

          &.draggable {}

          &~.survey__content-item {
            margin-top: 10px;
          }
        }

        .survey__select-type-item {
          flex: 1;
          border-radius: 5px;
          padding: 40px 0;
          text-align: center;
          background: #f2f2f2;

          &~.survey__select-type-item {
            margin-left: 10px;
          }

          .img {
            height: 60px;
            display: flex;
            align-items: center;
            justify-content: center;
          }

          p {
            margin-top: 10px;
          }

          .custom-checkbox {
            width: 18px;
            margin: 0 auto;
            margin-top: 20px;

            span {
              margin: 0;
              background: #fff;
            }
          }
        }

        .survey__header {
          height: 80px;
          border-bottom: 1px solid #ddd;
          display: flex;
          align-items: center;
          justify-content: space-between;
        }

        .survey__title {
          font-size: 1.5rem;
          font-weight: 600;
        }

        .survey__info {
          margin-left: auto;
          display: flex;
          align-items: center;

          .block {
            display: flex;
            align-items: center;
            color: #666666;
          }

          .block--clock {
            margin-left: 40px;

            strong {
              padding-left: 2em;
              background: url('#{$image-path}/i-clock.png') no-repeat left center !important;
            }

            .clock {
              display: flex;
              align-items: center;

              i {
                font-style: normal;
                margin: 0 5px;
              }

              span {
                width: 45px;
                height: 35px;
                line-height: 33px;
                border-radius: 5px;
                text-align: center;
                border: 1px solid #ddd;
              }
            }

          }

          strong {
            color: #000000;
            margin-right: 5px;
          }

        }

        .survey__state {
          display: flex;
          align-items: center;
          height: 100px;
          padding: 0 30px;
          position: relative;

          .btn {
            display: flex;
            align-items: center;
            color: #666666;
            line-height: 40px;
            padding: 0 20px;
            border-radius: 5px;
            border: 1px solid #666;

            svg {
              margin-right: 5px;
            }
          }

          .right {
            display: flex;
            align-items: center;

            .btn {
              margin-left: 10px;
            }
          }

          .left {
            margin-right: auto;
            display: flex;
            align-items: center;

            .btn {
              border-color: #2e8bc5;
              color: #2e8bc5;
              margin-right: 10px;

              &.active {
                background: #2e8bc5;
                color: #fff;
              }
            }
          }

          .center {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            display: flex;
            align-items: center;

            .survey__progress {
              display: flex;
              align-items: center;

              strong {
                font-weight: 600;
                font-size: 1.25rem;
              }

              .bar {
                width: 200px;
                background: #e5eaf1;
                border-radius: 2em;
                overflow: hidden;

                span {
                  border-radius: 2em;
                  display: block;
                  width: 0;
                  background: #2e8bc5;
                  height: 5px;
                }
              }

              .state {
                display: flex;
                margin: 0 10px 0 20px;

                span {
                  color: #2e8bc5;
                  ;
                }
              }
            }

            p {
              padding: 0 20px;
              line-height: 1em;
              font-size: 1.25rem;

              &~p {
                border-left: 1px solid #ddd;
              }

              strong {
                margin-right: 10px;
                font-size: 1.25rem;
              }

              span {
                color: #2e8bc5;
                ;
              }
            }
          }
        }

        .survey__body {
          padding-bottom: 30px;

          .survey__body-content {
            background: #999;
            padding: 30px;
            display: flex;
          }

          .survey__content-block-list {
            padding: 30px 0 100px;
            min-height: 100%;
            position: relative;

            .survey__controller {
              display: flex;
              align-items: center;
              justify-content: space-between;
              position: absolute;
              left: 0;
              bottom: 30px;
              width: 100%;

              .control-btn {
                display: flex;
                align-items: center;

                span {
                  width: 40px;
                  height: 40px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  border: 1px solid #ddd;
                  border-radius: 5px;
                }

                p {
                  color: #666;
                  font-size: 14px;
                  margin: 0 10px;
                }
              }
            }
          }

          .survey__content-block {
            h2 {
              font-size: 1rem;
              font-weight: 600;
              margin-bottom: 15px;
            }

            &~.survey__content-block {
              margin-top: 40px;
            }
          }

          .survey__body-answer {
            background: #fff;
            border-radius: 5px;
            overflow: hidden;
            flex: 1;
            width: 320px;
            flex: none;
            margin-left: 10px;
          }

          .survey__body-inner {
            display: flex;
            border-radius: 5px;
            overflow: hidden;
            flex: 1;
          }

          .survey__content {
            flex: 1;
            background: #fff;
            min-height: 530px;
            position: relative;
            padding: 0 40px;
          }

          .survey__init {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);

            .img {
              text-align: center;
              margin-bottom: 1.5em;
            }


          }

          .survey__nav {
            width: 80px;
            flex: none;
            background: #dfeaf2;

            &.displaynone {
              display: none;
            }
          }

          .survey__nav-state-img {
            height: 60px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 40px;
            color: #adbfcc;
          }

          .survey__nav-list {
            .survey__nav-item {
              position: relative;
              width: 40px;
              height: 40px;
              display: block;
              margin: 0 auto;

              i {
                position: absolute;
                z-index: 5;
                background: #fff;
                color: #2e8bc5;
                width: 15px;
                height: 15px;
                border-radius: 3px;
                right: -3px;
                top: -3px;

                svg {
                  vertical-align: top;
                }
              }

              &~.survey__nav-item {
                margin-top: 20px;
              }

            }

            button {
              width: 100%;
              height: 100%;
              background: #2e8bc5;
              color: #fff;
              border-radius: 50%;
              font-size: 12px;
              position: relative;

              &.number {
                background: none;
                border: 3px solid #2e8bc5;
                color: #2e8bc5;

                &:disabled {
                  color: #adbfcc;
                  border-color: #adbfcc;
                }
              }

              span {
                font-size: 20px;
              }

              &.more {
                background: #adbfcc;
              }

            }
          }
        }
      }


      .display {
        display: block;

        .reg_form {
          .survey {
            .survey__body {
              padding: 20px 0;

              .survey__body-content {
                background: none;
                padding: 20px;
                border-radius: 13px;
                border: 1px solid #ddd;

                .survey__select-type-item {
                  border-radius: 13px;
                  background: #F6F7FB;
                }

                // 문제형식을 골라주세여
                .survey_pick {
                  text-align: center;
                  margin-top: 30px;

                  &::before {
                    content: '';
                    display: inline-block;
                    width: 35px;
                    height: 25px;
                    margin-right: 10px;
                    vertical-align: middle;
                    background: url('#{$image-path}/test-icon-gj.png') no-repeat center;
                  }
                }

                .flexBox {
                  display: flex;
                  overflow: auto;

                  .survey-option-item2 {
                    position: relative;
                    padding: 20px;
                    max-width: 180px;
                    // border-right: 1px solid #ddd;

                    // input {
                    //   display: block;
                    //   margin: 0 auto;
                    //   width: 90%;
                    //   height: 30px;
                    //   font-size: 13px;
                    //   color: #696969;
                    //   text-align: center;
                    //   border: 1px solid #ddd;
                    //   border-radius: 5px;
                    // }
                    .top_flex_wrap {
                      display: flex;
                      justify-content: space-between;
                      align-items: center;
                      width: 100%;
                      height: 30px;
                      font-size: 13px;
                      color: #696969;
                      text-align: center;
                      background: #E7F0EA;
                      border: 1px solid #ddd;
                      border-bottom: none;
                      border-radius: 3px 3px 0px 0px;
                    }

                    .text {
                      display: block;
                      // margin: 0 auto;
                      width: 100%;
                      height: 30px;
                      font-size: 13px;
                      color: #696969;
                      text-align: center;
                      background: #fff;
                      border: 1px solid #ddd;
                      border-bottom: none;
                      border-radius: 0px 0px 0px 0px;
                    }

                    .number {
                      display: block;
                      // margin: 0 auto;
                      width: 100%;
                      height: 30px;
                      font-size: 13px;
                      color: #696969;
                      text-align: center;
                      background: #ffffff;
                      border-radius: 0px 0px 3px 3px;
                      border: 1px solid #dddddd;

                    }

                    .survey-remove {

                      margin: 0 0 0 10px
                    }

                    .survey-delete {
                      margin: 0 10px 0 0;
                    }

                    // .survey-delete {
                    //   position: absolute;
                    //   top: 8px;
                    //   right: 90px;
                    //   margin: 0 0 0 10px;
                    // }

                    &.none {
                      display: block;

                      // background-color: #ff000080;
                      .top_flex_wrap {

                        background-color: #DDDDDD;
                      }
                    }
                  }

                  .survey-buttons {
                    margin-left: auto;

                    button {
                      &::before {
                        content: "+";
                        display: block;
                        height: 20px;
                        line-height: 20px;
                      }
                    }

                    ._btn.bg-none {
                      background: none;
                      border: none;
                      font-size: 15px;
                    }
                  }
                }

                .survey_ex_wrap {
                  .insertForm__content {
                    .flexBox {
                      align-items: center;
                      margin: 0 0 25px;
                      border: 1px solid #ddd;
                      // border-bottom: none;
                      border-radius: 4px;
                      background-color: #ffffff;
                    }

                    .columnBar {
                      width: 1px;
                      height: 70px;
                      background-color: #ddd;
                    }

                    .add_row_btn {
                      height: 80px;
                      display: flex;
                      border: 1px dashed #DDDDDD;
                      border-radius: 4px;
                      margin-right: 20px;
                    }
                  }

                  .flex {

                    &.flexWrap {
                      padding: 12px;
                      border: 1px solid #ddd;
                      border-radius: 13px 13px 0 0;

                      h3 {
                        margin: 0 10px 0 0 !important;
                        padding: 0;
                        width: 70px;
                        line-height: 25px;
                        font-size: 13px;
                        color: #fff;
                        font-weight: 600;
                        text-align: center;
                        background: #ACDBA4;
                        border-radius: 200px;
                      }

                      input {
                        padding: 0 10px;
                        width: 100%;
                        height: 40px;
                        border: 1px solid #ddd;
                        border-radius: 5px;
                      }

                      &+div {
                        display: flex;
                        border: 1px solid #ddd;
                        border-top: 0;
                        border-radius: 0 0 4px 4px;

                        &.none {
                          display: none;
                        }

                        >div {
                          padding: 20px 0;
                          min-width: 120px;
                          text-align: center;
                          display: flex;
                          justify-content: center;
                        }

                        label {
                          display: block;
                          margin: 0 0 0 10px;
                          line-height: 25px;
                          font-size: 13px;
                          color: #696969;
                        }
                      }
                    }

                    h3 {
                      margin: 0 10px 0 0 !important;
                      padding: 0;
                      width: 70px;
                      line-height: 25px;
                      font-size: 13px;
                      color: #fff;
                      font-weight: 600;
                      text-align: center;
                      background: #ACDBA4;
                      border-radius: 200px;
                    }

                    input {
                      padding: 0 10px;
                      width: 100%;
                      height: 40px;
                      border: 1px solid #ddd;
                      border-radius: 5px;
                    }
                  }

                  .edit_wrap {
                    width: 100%;

                    .editor {
                      justify-content: center;
                      display: flex;
                    }

                    div {
                      textarea {
                        padding: 10px 15px;
                        height: 64px;
                        resize: none;
                        width: 100%;
                        border: 1px solid #ddd;
                        border-radius: 5px;
                        font-size: 14px;
                        color: #666666;
                      }
                    }
                  }

                  .survey-remove {
                    margin: 0 10px 0 10px;
                    width: 20px;
                  }

                  .survey-option-item {
                    margin: 15px 0;

                    &.none {
                      display: block;
                      // background-color: #ff000080;
                      // border-radius: 20px;
                    }

                    .survey-option-body {
                      display: -webkit-flex;
                      display: flex;
                      -webkit-align-items: center;
                      align-items: center;
                      padding: 15px;


                      .survey-table-item-title-wrap {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;

                        .survey-table-item-title {
                          font-family: 'Pretendard';
                          font-style: normal;
                          font-weight: 500;
                          font-size: 13px;
                          line-height: 37px;
                          /* identical to box height, or 285% */

                          display: flex;
                          align-items: center;

                          /* 696969 */

                          color: #696969;

                          &.required {
                            &::after {
                              content: '*';
                              color: #55B87A !important;
                            }
                          }

                        }
                      }



                      .flex {
                        width: 100%;
                        height: auto;
                        justify-content: center;
                        padding: 15px;
                      }

                      >div {
                        width: 100%;

                        .survey-table-item-container {
                          &.none {
                            display: block;
                            // background-color: #ff000080;
                            // border-radius: 13px;

                            .flex.flexWrap {
                              background-color: #DDDDDD;

                            }
                          }
                        }
                      }

                      .survey-table-item-container {
                        background: white;

                        .flex {
                          background: #E7F0EA;
                          border-radius: 4px 4px 0px 0px;
                        }
                      }
                    }
                  }
                }

                .survey__content-item-header {
                  height: 55px;
                  // background:#f6f8fb;
                  padding: 0 20px 0 40px;
                  display: flex;
                  align-items: center;
                  justify-content: flex-end;
                  background: url('#{$image-path}/admin/menu_on.svg') no-repeat 20px center;
                  background-size: 10px;

                  h2 {
                    margin-right: auto;
                    padding: 0;
                    line-height: 2.5;
                    font-size: 1rem;
                    font-weight: 600;

                    // &.Mtch {
                    //   padding-left: 43px;
                    //   background: url('#{$image-path}/admin/mtch_title_icon.svg') no-repeat left 8px;
                    //   background-size: 38px;
                    // }

                    // &.Plur {
                    //   padding-left: 59px;
                    //   background: url('#{$image-path}/admin/plur_title_icon.svg') no-repeat left 8px;
                    //   background-size: 54px;
                    // }

                    // &.Short {
                    //   padding-left: 48px;
                    //   background: url('#{$image-path}/admin/short_title_icon.svg') no-repeat left 8px;
                    //   background-size: 43px;
                    // }

                    // &.table_tit {
                    //   padding-left: 59px;
                    //   background: url('#{$image-path}/admin/table_title_icon.svg') no-repeat left 7px;
                    //   background-size: 54px;
                    // }
                  }

                  button {
                    width: 30px;
                    height: 30px;
                    // border: 1px solid #ddd;
                    // background:#fff;
                    // border-radius: 50%;
                    margin-left: 10px;

                    svg {
                      vertical-align: middle;
                      margin-top: -5px;
                    }
                  }
                }

                .survey__content-form {
                  .survey__content-form-actions {
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;

                    button {
                      height: 60px;
                      font-size: 18px;
                      width: 135px;
                      color: #999;
                      border-bottom: 4px solid #999999;
                      display: flex;
                      align-items: center;
                      justify-content: center;
                    }

                    .active {
                      color: #1A9952;
                      border-bottom: 3px solid #1A9952;
                    }
                  }

                  .answer-button-box {
                    display: flex;
                    justify-content: flex-end;

                    .btn {
                      margin-left: 10px;
                    }
                  }

                }

                // 문항
                .survey__content-item {
                  border-radius: 4px;

                  .survey__content-item-header {
                    background: #fff;

                    label.checkbox {
                      background: url('https://lms.gjudec.com/assets/images/active-arrow.png') no-repeat center;
                      background-color: #696969;
                      border-radius: 10px;
                      height: 25px;
                      width: 25px;
                      cursor: pointer;
                    }

                    .checked {
                      background-color: #00B852 !important;


                    }


                    h2 {
                      position: relative;

                      &.question {
                        // text-indent: 60px;

                        &::before {
                          content: '';
                          position: absolute;
                          left: 0;
                          top: 50%;
                          transform: translateY(-50%);
                        }

                        &.Mtch {
                          &::before {
                            content: '';
                            display: block;
                            height: 1.3em;
                            border: 3px solid #00B852;
                            /* border-radius: 50%; */
                            position: absolute;
                            left: -19px;
                            top: 20px;
                            // width: 63px;
                            // height: 22px;
                            // background: url('#{$image-path}/Group 33808.png') no-repeat center;
                          }
                        }

                        &.Plur {
                          &::before {
                            content: '';
                            display: block;
                            height: 1.3em;
                            border: 3px solid #00B852;
                            /* border-radius: 50%; */
                            position: absolute;
                            left: -19px;
                            top: 20px;
                            // width: 75px;
                            // height: 22px;
                            // background: url('#{$image-path}/Group 33806.png') no-repeat center;
                          }
                        }

                        &.Short {
                          &::before {
                            content: '';
                            display: block;
                            height: 1.3em;
                            border: 3px solid #00B852;
                            /* border-radius: 50%; */
                            position: absolute;
                            left: -19px;
                            top: 20px;
                            // width: 67px;
                            // height: 22px;
                            // background: url('#{$image-path}/Group 33807.png') no-repeat center;
                          }
                        }

                        &.table_tit {
                          &::before {
                            content: '';
                            display: block;
                            height: 1.3em;
                            border: 3px solid #00B852;
                            /* border-radius: 50%; */
                            position: absolute;
                            left: -19px;
                            top: 20px;
                            // width: 67px;
                            // height: 22px;
                            // background: url('#{$image-path}/Group 33807.png') no-repeat center;
                          }
                        }

                        &.Mtch2 {
                          &::before {
                            content: '';
                            display: block;
                            height: 1.3em;
                            border: 3px solid #868686;
                            /* border-radius: 50%; */
                            position: absolute;
                            left: -19px;
                            top: 20px;
                            // width: 63px;
                            // height: 22px;
                            // background: url('#{$image-path}/Group 33808.png') no-repeat center;
                          }
                        }

                        &.Plur2 {
                          &::before {
                            content: '';
                            display: block;
                            height: 1.3em;
                            border: 3px solid #868686;
                            /* border-radius: 50%; */
                            position: absolute;
                            left: -19px;
                            top: 20px;
                            // width: 75px;
                            // height: 22px;
                            // background: url('#{$image-path}/Group 33806.png') no-repeat center;
                          }
                        }

                        &.Short2 {
                          &::before {
                            content: '';
                            display: block;
                            height: 1.3em;
                            border: 3px solid #868686;
                            /* border-radius: 50%; */
                            position: absolute;
                            left: -19px;
                            top: 20px;
                            // width: 67px;
                            // height: 22px;
                            // background: url('#{$image-path}/Group 33807.png') no-repeat center;
                          }
                        }

                        &.table_tit2 {
                          &::before {
                            content: '';
                            display: block;
                            height: 1.3em;
                            border: 3px solid #868686;
                            /* border-radius: 50%; */
                            position: absolute;
                            left: -19px;
                            top: 20px;
                            // width: 67px;
                            // height: 22px;
                            // background: url('#{$image-path}/Group 33807.png') no-repeat center;
                          }
                        }
                      }
                    }

                    >button {
                      border-radius: 0;
                      border: 0;
                    }
                  }

                  .surveyitem {
                    border-top: 1px dashed #ddd;

                    &.displaynone {
                      border-top: 0;
                    }
                  }
                }

                .survey__content-lists {
                  .surveyaddbtn {
                    display: block;
                    color: #fff;
                    font-size: 14px;
                    flex: none;
                    white-space: nowrap;
                    height: 40px;
                    padding: 0 30px;
                    border-radius: 3px;
                    background: #55B87A;
                    margin: 0 auto;
                    margin-top: 20px;
                  }
                }
              }
            }

            // 시험 취소추가 버튼
            .answer-button-box {
              .btn {
                background: #ACDBA4;
                border: 1px solid #ACDBA4;
                border-radius: 3px;
                color: #5C8D72;
                min-width: 120px;
                height: 40px;
                //line-height: 40px;
                font-size: 0.875rem;

                // &::before {
                //   content: '';
                //   display: inline-block;
                //   width: 19px;
                //   height: 22px;
                //   margin-right: 10px;
                //   vertical-align: -5px;
                //   background: url('#{$image-path}/survey-cancel-icon.png') no-repeat center;
                // }

                // &.submit {
                //   border: 1px solid #55B87A;
                //   background: #55B87A;
                //   color: #fff;

                //   &::before {
                //     width: 19px;
                //     height: 21px;
                //     background: url('#{$image-path}/survey-add-icon.png') no-repeat center;
                //   }
                // }
              }
            }
          }
        }


      }

      .none {
        display: none;
      }

      form {
        margin-top: 40px;
      }

      .input_containerBox {
        display: flex !important;

        .insertForm__name {
          flex: none;
          width: 155px;
          padding: 10px 0;
        }

        .dateWraps {
          .react-datepicker__tab-loop {
            display: inline-block;
          }
        }

        input {
          border: 0;
          border-bottom: 1px solid #ddd;
          border-radius: 0;
          height: 40px;
          width: 100%;
          padding: 0 15px;
          font-size: 14px;
          color: #666;

        }

        .survey_desc {
          outline: none;
        }

        .insertForm__content {
          display: -webkit-flex;
          display: flex;
          -webkit-flex-direction: column;
          flex-direction: column;
          -webkit-justify-content: center;
          justify-content: center;
          -webkit-flex: 1 1;
          flex: 1 1;

          .in_detail {
            select {
              border: none;
              max-width: 120px;
              width: 100%;
              height: 100%;
              padding: 0 10px;
              font-size: 0.9rem;
            }
          }

          .custom-checkbox_container {
            height: auto;
            min-height: 40px;
            display: flex;
            align-items: center;

            .custom-checkbox {
              margin-top: 0 !important;
              min-width: 85px;
              display: -webkit-flex;
              display: flex;
              -webkit-align-items: center;
              align-items: center;

              input {
                display: none;
              }

              input[type=radio]+span {
                width: 18px;
                height: 18px;
                flex: none;
                border: 1px solid #ddd;
                border-radius: 50%;
                margin-right: 5px;
              }

              input[type=radio]:checked+span {
                background: none !important;
                border: 5px solid #55b87a;
              }
            }
          }
        }
      }

    }

    &.popup-close-content {
      -webkit-animation: dialog-flyout .5s !important;
      animation: dialog-flyout .5s !important;
    }

    .dialog-close {
      border: none;
      background: transparent;
      cursor: pointer;
      position: absolute;
      right: 0;
      top: 0;
      outline: none;

      &:before {
        display: block;
        padding: 3px;
        background: transparent;
        color: #bbb;
        content: '\00D7';
        font-size: 26px;
        font-weight: 400;
        line-height: 26px;
        text-align: center;
      }
    }

    .banner-dialog-close {
      border: none;
      background: transparent;
      cursor: pointer;
      position: absolute;
      right: 0;
      top: 0;
      outline: none;
      font-size: 2rem;
      color: #fff;
      padding: 5px;
    }

    .program_popup {
      background: #fff;

      .modal_header {
        display: flex;
        align-items: center;
        height: 75px;
        justify-content: space-between;
        padding: 1rem 1rem;
        border-bottom: 1px solid #dee2e6;
        border-top-left-radius: calc(.3rem - 1px);
        border-top-right-radius: calc(.3rem - 1px);

        h5 {
          margin-left: 20px;
          font-size: 1.25rem;
          margin-bottom: 0;
          line-height: 1.5;
          position: relative;

          &::after {
            // content: '';
            // display: block;
            // width: 0.8em;
            // height: 0.8em;
            // border: 5px solid #489659;
            // border-radius: 50%;
            // position: absolute;
            // left: -19px;
            // top: 7px;
            content: '';
            display: block;
            FONT-WEIGHT: 100;
            height: 1em;
            border: 3px solid #489659;
            /* border-radius: 50%; */
            position: absolute;
            left: -19px;
            top: 5px;
          }
        }

        .overlay_close_x {
          &:after {
            font-family: Helvetica, Arial, sans-serif;
            content: '\00D7';
            font-size: 40px;
            color: #222;
            font-weight: 200;
            line-height: 0px;
          }

          position: absolute;
          top: 25px;
          right: 35px;
          background: none;
          width: 26px;
          height: 30px;
          text-decoration: none;


          @media screen and (max-width: 768px) {
            right: 15px;
            top: 20px;
          }
        }

        .element-invisible {
          position: absolute !important;
          clip: rect(1px 1px 1px 1px);
          clip: rect(1px, 1px, 1px, 1px);
          overflow: hidden;
          height: 1px;
        }

        &.button {
          background-color: transparent;
          border: 0;
        }
      }

      .modal_header.metaverse {
        display: flex;
        align-items: center;
        height: 75px;
        justify-content: space-between;
        padding: 1rem 1rem 0 1rem;
        border-bottom: 0px solid #dee2e6;
        border-top-left-radius: calc(.3rem - 1px);
        border-top-right-radius: calc(.3rem - 1px);

        h5 {
          margin-left: 20px;
          font-size: 1.25rem;
          margin-bottom: 0;
          line-height: 1.5;
          position: relative;

          &::after {
            content: '';
            display: block;
            width: 0.2em;
            height: 0.8em;
            border: 5px solid #489659;
            border-radius: 0%;
            position: absolute;
            left: -19px;
            top: 7px;
          }
        }

        .overlay_close_x {
          &:after {
            font-family: Helvetica, Arial, sans-serif;
            content: '\00D7';
            font-size: 40px;
            color: #222;
            font-weight: 200;
            line-height: 0px;
          }

          position: absolute;
          top: 25px;
          right: 35px;
          background: none;
          width: 26px;
          height: 30px;
          text-decoration: none;


          @media screen and (max-width: 768px) {
            right: 15px;
            top: 20px;
          }
        }

        .element-invisible {
          position: absolute !important;
          clip: rect(1px 1px 1px 1px);
          clip: rect(1px, 1px, 1px, 1px);
          overflow: hidden;
          height: 1px;
        }

        &.button {
          background-color: transparent;
          border: 0;
        }
      }

      .reg_form {

        .modal_sub_title {
          margin-top: 10px;
        }

        .course_overlay_footer {
          margin-top: 40px !important;
        }

        .input_container {
          margin: 0 !important;
          display: block !important;
          margin-top: 10px !important;
          margin-left: 0 !important;
          padding-bottom: 30px;
          border-bottom: 1.5px solid #ddd;
          margin-bottom: 10px;

          .size_box {
            width: 100%;

            &:after {
              display: block;
              visibility: hidden;
              clear: both;
              content: '';
            }

            .size_box_left {
              float: left;
              width: 50%;
              text-align: right;
              padding-right: 10px;

              .size_box_title {
                display: inline-block;
                margin-right: 10px;
              }

              .x_input {
                display: inline-block;
                width: 100%;
                max-width: 100px;
              }

            }

            .size_box_right {
              float: left;
              width: 50%;
              padding-left: 10px;

              .size_box_title {
                display: inline-block;
                margin-right: 10px;
              }

              .x_input {
                display: inline-block;
                width: 100%;
                max-width: 100px;
              }
            }

          }

          &.date_container {
            text-align: center;
          }

          .react-datepicker__header.react-datepicker__header--time {
            height: 87.28px;
            padding-top: calc(50% - 12px);
          }

          .react-datepicker__tab-loop {
            display: inline-block;
          }

          .time_input {
            width: 210px !important;
            margin-right: 5px;
          }

          label.main_popup_container {
            width: 70px;
            display: inline-block;
            margin-left: 3px;

            span {
              padding-left: 10px;
            }
          }

          input {
            width: 100% !important;

            &.main_popup {
              width: 15px !important;
            }
          }

          input[type=text] {
            border: 1px solid #767676;
            border-radius: 5px;
          }

          .input_secret {
            display: inline-block;
            margin-right: 40px;
            vertical-align: sub;

            .secret_check {
              border: 1px solid #ddd;
              border-radius: 30px;
              height: 28px;
              width: 28px;
            }

            .secret_checkbox:checked+label {
              background: url('#{$image-path}/secret_checked.png') no-repeat center;
            }

            .input_span {
              vertical-align: top;
              margin-left: 10px;
            }

            input[type='radio'] {
              display: none;
            }
          }

          .secret_pass {
            display: inline-block;
            vertical-align: middle;
            padding: 10px;

            input[type='password'] {
              width: 218px !important;
              height: 50px;
              padding: 10px;
              border: 1px solid #ddd;
              border-radius: 10px;
            }

            .password.deactive {
              background: #ddd;
              width: 218px;
              height: 50px;
              border: 1px solid #ddd;
              border-radius: 10px;
              padding: 10px;
            }
          }
        }

      }

      .modal_body {
        padding: 20px 20px 30px 20px;
        //margin-top: 20px;
        height: 100%;

        .button_container {
          button {
            width: unset;
            padding: 0 30px;
            height: 30px;
            font-size: 1.05rem;
            color: #adadad;

          }

          .active {
            color: #1A9952;
            border-bottom: 3px solid #1A9952;
          }
        }

        .survey_button_box {
          width: 100%;
          margin-top: 42px;
          border-top: 1px solid #bfbfbf;
          padding-top: 14px;

          &:after {
            display: block;
            visibility: hidden;
            clear: both;
            content: '';
          }

          .survey_button_box_left {
            float: left;

            .csv_button {
              margin-bottom: 10px;
              color: #FFF;
              font-weight: 700;
              background: #00863C;
              border-radius: 4px;
              padding: 8px 25px;
              display: block;
            }

            .csv_button_area {
              top: 50px;
              left: 0;
              padding: 8px 25px;
              font-weight: 700;
              border-radius: 4px;
              background-color: #3E3E3E;
              color: #fff;
              display: block;

              &:hover {
                background-color: rgb(65, 65, 65);
                color: #FFF;
              }
            }
          }

          .survey_button_box_right {
            float: right;

            .csv_button {
              margin-bottom: 10px;
              color: #FFF;
              font-weight: 700;
              background: #00863C;
              border-radius: 4px;
              padding: 8px 25px;
              display: block;
            }

            .csv_button_area {
              top: 50px;
              left: 0;
              padding: 8px 25px;
              font-weight: 700;
              border-radius: 4px;
              background-color: #3E3E3E;
              color: #fff;
              display: block;

              &:hover {
                background-color: rgb(65, 65, 65);
                color: #FFF;
              }
            }
          }


        }

        @media screen and (max-width: 768px) {
          padding: 18px 6px;
        }

        // 워크숍 설문조사 결과보기 모달창
        &.worksurvey {
          padding: 20px 30px 30px 30px;
        }

        .survey_que_div {
          margin-bottom: 30px;
          border: 1px solid #ddd;
          // padding: 10px;
          border-radius: 4px;
        }

        .work_survey_title {
          padding: 10px 20px;
          border-bottom: 1px solid #ddd;
          color: #696969;
        }

        .work_survey {
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;

          &.q {
            padding: 0 30px;
            margin: 5px 0;

            span {
              color: #696969;
            }
          }
        }

        .aItemWrap {
          padding: 5px 0;

          &:nth-child(odd) {
            background-color: #FAFAFA;
          }


          span {
            color: #696969;
            padding: 0 30px;

            @media screen and (max-width:768px) {
              padding: 0 10px;
            }
          }

          .aitemcolor {
            color: #00B852;
          }
        }

        .input_container {
          margin: 15px 3px 20px 15px;
          display: inline;
          margin-left: 72px;

          .cal_ir {
            display: inline-block;
            margin: 0 10px;
            width: 6%;
            height: 37px;
            padding-top: 7px;
            text-align: center;
          }

          input {
            width: auto;
            display: inline-block;

            &[type='password'] {
              font-family: auto;
            }

            &.secret_checkbox {
              display: inline-block;
              width: auto;
              margin-left: 10px;
            }
          }

          textarea {
            width: 100%;
            height: 100px;
            padding: 10px;
            box-sizing: border-box;
            border-radius: 5px;
            font-size: 16px;
            resize: both;
          }

          &.video {
            .secret_check {
              border: 1px solid #ddd;
              border-radius: 30px;
              height: 28px;
              width: 28px;
            }

            .secret_checkbox:checked+label {
              background: url('#{$image-path}/secret_checked.png') no-repeat center;
            }

            span {
              vertical-align: top;
              margin-left: 10px;
            }

            input[type='radio'] {
              display: none;
            }
          }
        }

        .img_container {
          margin-top: 20px;
          margin-left: 20px;

          div {
            min-height: 130px;
            margin-top: 15px;

            .img_info_box {
              margin-top: 0px;
              display: inline-block;
              vertical-align: top;
              margin-left: 50px;

              input {
                margin-top: 15px;
              }
            }
          }
        }

        .complate_title {
          text-align: center;
          margin-top: 30px;
          margin-bottom: 30px;

          .compalte_modeTitle {
            color: red;
          }
        }

        .information_complate {
          margin-top: 20px;

          span {
            display: block;
          }
        }

        .files_container {
          padding: 20px 10px 10px 10px;
          border: 1px solid black;
          margin-top: 20px;
          display: block;
          position: relative;

          .files_title {
            position: absolute;
            top: -10px;
            background: white;
            border: 1px solid black;
            padding: 0 5px;
            text-align: center;
          }

          .file_once {
            display: block;
            margin-bottom: 5px;
            width: unset;

            .files_item {
              display: inline-block;
              width: 100%;
              overflow: hidden;
              text-overflow: ellipsis;
              vertical-align: middle;
              white-space: pre;
              // width: unset;
            }

            .files_delete {
              display: inline-block;
              cursor: pointer;
              background-color: #272b37;
              color: white;
              padding: 2px 10px;
              text-align: center;
              float: right;
            }
          }
        }

      }

      .modal_sub_title {
        font-weight: bold;
        font-size: 1rem;
        color: #222;


        &:before {
          content: '';
          display: inline-block;
          width: 4px;
          height: 14px;
          margin: 0 6px 0 4px;
          background-color: #00863e;
        }

      }

      ////////////  CleanWrite form Style

      .clean_dash {
        display: inline-block;
        width: 20px;
        text-align: center;
      }

      .clean_dash2 {
        display: inline-block;
        width: 26px;
        padding-left: 2px;
      }

      .clean_form_left {
        width: calc(100%);
        height: auto;
        margin-bottom: 10px;
        display: inline-block;

        select {
          margin-right: 5px;
        }
      }

      .clean_form_right {
        width: calc(100%);
        height: auto;
        float: right;
        display: inline-block;

        select {
          margin-right: 5px;
        }
      }

      .clean_form_item {
        width: 100%;
        height: calc(40%);
        display: block;
        margin-bottom: 10px;
      }

      .input_container2 {
        margin: 10px 3px 20px 15px;

        input {
          width: 100%;
          height: 39px;

          &[type='password'] {
            font-family: auto;
          }

          &.secret_checkbox {
            display: inline-block;
            width: auto;
            margin-left: 10px;
          }
        }

        select {
          width: 20.333%;
          margin: 0 3px 0 0;
          height: 39px;
        }

        textarea {
          width: 100%;
          height: 100px;
          padding: 10px;
          box-sizing: border-box;
          border-radius: 5px;
          font-size: 16px;
          resize: both;
        }
      }

      .input_container3 {
        margin: 10px 3px 20px 15px;

        input {
          width: 20.333%;
          margin: 0px 3px 0px 3px;
          height: 39px;

          &[type='password'] {
            font-family: auto;
          }

          &.secret_checkbox {
            display: inline-block;
            width: auto;
            margin-left: 10px;
          }
        }

        select {
          width: 22.333%;
          margin-left: 5px;
          height: 39px;
        }
      }

      .input_container4 {
        margin: 10px 3px 20px 15px;

        input {
          width: 20.333%;
          margin: 0px 3px 0px 3px;
          height: 39px;

          &[type='password'] {
            font-family: auto;
          }

          &.secret_checkbox {
            display: inline-block;
            width: auto;
            margin-left: 10px;
          }
        }

        select {
          width: 20.333%;
          height: 39px;
        }
      }



      .modal_sub_title2 {
        font-weight: bold;
        font-size: 1rem;
        color: #222;

        &:before {
          content: '';
          display: inline-block;
          width: 4px;
          height: 14px;
          margin: 0 6px 0 4px;
          background-color: #00863e;
        }

      }

      ///////////////////////////////////


      .modal_body .table_responsive {
        table thead tr th {
          border: 1px solid #e6e6e6;
          font-weight: bold;
          text-align: center;
          vertical-align: middle;
          height: 35px;
          padding: 22px 0;
        }

        .table_modal {
          width: 100%;
          max-width: 100%;
          justify-content: center;
          align-items: center;

          @media screen and (max-width: 768px) {
            margin-bottom: 0;
          }
        }

        table {
          tbody {
            tr {
              td {



                h1 {
                  padding: 5px 0 10px 0;
                  font-size: 12px;
                  width: 100%;
                  height: 48px;
                  display: flex;
                  justify-content: center;
                  overflow: hidden;
                  align-items: center;

                }

                border: 1px solid #e6e6e6;
                color: #222;
                font-weight: 400;
                text-align: center;
                vertical-align: middle;
                letter-spacing: -.05rem;
                height: 35px;
                padding-top: 10px;
                padding-bottom:10px;
                line-height: 1.42857;
                font-size: 16px;
                font-weight: 700;
              }

              background-color: transparent !important;
            }

            background-color: #fff;
          }

          word-break: keep-all;
          margin: 0;
        }

        //margin: 15px 3px 0 15px;
        font-size: .8125rem;
        border-top: 2px solid #272b37;
        overflow-x: auto;
        min-height: 0.01%;
      }
    }
  }
}




.sub_survey_title {
  width: 100%;
  background-color: #f8f8f8;
  padding: 30px;
  border-radius: 5px;

  h1 {
    font-size: 1.4rem;
    font-weight: 600;
    position: unset;
    margin-bottom: 10px;
  }
}

// .ql-container {
//   height: auto;
//   border: 1px solid #bedaf4 !important;
//   border-radius: 5px;
//   padding: 20px;
//   margin: 20px 0 40px;
// }



.survey_use_popup {
  .btn_container {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    >button {
      background: #00863C;
      border-radius: 4px;
      padding: 15px 30px;
      color: white;

    }
  }

  .survey_question {
    padding: 0 30px 15px;
    border: 1px solid #ddd;
    border-radius: 4px;
    margin: 20px 0;

    .survey_popup_title {
      font-family: 'Pretendard';
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 20px;
      /* identical to box height, or 111% */


      /* 기본색 333 */

      color: #333333;
      padding: 20px;


    }


    .survey_popup_date,
    .survey_popup_user {
      display: flex;
      font-family: 'Pretendard';
      font-style: normal;
      font-weight: 400;
      font-size: 15px;
      line-height: 18px;
      padding: 5px;

      /* identical to box height */
      &>span:nth-child(1) {
        font-family: 'Pretendard';
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 14px;
        flex-basis: 8%;
        /* or 93% */

        color: #333333;
      }

      &>span:nth-last-child(1) {
        font-family: 'Pretendard';
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 14px;
        flex-basis: 10%;
        /* or 93% */

        color: #696969;
      }

    }



    .ta_div {
      .evaluate_table {
        background: #FFFFFF;
        border: 1px solid #DDDDDD;
        border-radius: 4px;

        .th_header {
          width: 60px;
          border: none;
          border-bottom: 1px solid #ddd;
          font-family: 'Pretendard';
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 14px;
          /* identical to box height, or 88% */


          /* 기본색 333 */

          color: #333333;
          background: none !important;
        }

        tbody {


          tr {
            &:nth-child(odd) {
              >td {
                background: #F8F8F8 !important;

              }
            }

            td {
              padding: 0 !important;

              @media screen and (max-width: 768px) {
                font-size: 10px;
              }

              background-color: #fff !important;
              vertical-align: middle;
              text-align: center;
              border: none;
              font-size: 16px;
              padding: 10px;
              font-family: 'Pretendard';
              font-style: normal;
              font-weight: 400;
              font-size: 15px;
              line-height: 40px;
              /* or 300% */


              /* 696969 */

              color: #696969;
            }

            .question_list_title {
              text-align: unset !important;
            }
          }
        }
      }
    }



    .boardDetail__content {
      background: #F3F3F3;
      border-radius: 4px;
      font-family: 'Pretendard';
      font-style: normal;
      font-weight: 400;
      font-size: 15px;
      line-height: 14px;
      /* identical to box height, or 93% */
      margin: 0px 0 10px 0;

      color: #696969;


      >.quill {
        >.ql-container {
          height: auto !important;

          >.ql-editor {
            >p {
              font-family: 'Pretendard';
              font-style: normal;
              font-weight: 400;
              font-size: 15px !important;
              line-height: 14px;
              color: #696969 !important;
              padding: 20px !important;

            }
          }
        }
      }



      input[type=text] {
        width: 100%;
        border: 1px solid #e5e5e5;
        height: 130px;
      }
    }

    h1 {
      font-family: 'Pretendard';
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 14px;
      color: #333333;
      position: relative;
      padding: 30px 20px 20px 30px;

      &::before {
        content: '';
        display: block;
        height: 1.3em;
        border: 3px solid #00B852;
        width: 3px;
        background-color: #00B852;

        /* border-radius: 50%; */
        position: absolute;
        left: 16px;
        top: 26px;
      }
    }

    label {
      vertical-align: middle;
      padding: 0 0 15px 10px;
      font-family: 'Pretendard';
      font-style: normal;
      font-weight: 400;
      font-size: 15px;
      line-height: 35px;
      /* or 233% */

      display: flex;
      align-items: center;

      color: #696969;

      input[type=radio] {
        background-color: #ececec;
        border: 7px solid #ececec;
        width: 30px;
        height: 30px;
        display: inline-block;
        vertical-align: middle;
        margin-right: 15px;
        appearance: none;
        border-radius: 50px;

        &:checked {
          border: 7px solid #00B852;
          width: 30px;
          height: 30px;
        }
      }

      input[type=checkbox] {
        background-color: #ececec;
        border: 5px solid #ececec;
        width: 20px;
        height: 20px;
        display: inline-block;
        vertical-align: middle;
        margin-right: 15px;
        appearance: none;

        &:checked {
          border: 7px solid #00B852;
          width: 20px;
          height: 20px;
        }
      }
    }

    .evaluate_table {

      input[type=radio] {
        background-color: #ececec;
        border: 7px solid #ececec;
        width: 20px;
        height: 20px;
        display: inline-block;
        vertical-align: middle;
        appearance: none;
        border-radius: 50px;

        &:checked {
          border: 5px solid #00B852;
          width: 20px;
          height: 20px;
        }
      }
    }
  }
}




//미리보기
.preview {

  //결과보기
  .modal_full_body {
    padding: 1rem 1rem;



    .modal_sub_title {
      display: flex;

      h4 {
        margin-right: 20px;
      }
    }

    .modal_body {
      padding: 0 !important;

      .tab {
        display: flex;
        margin-bottom: 35px;

        .tab_btn {
          display: flex;
          margin-left: 15px;
          margin-top: 28px;
          padding: 0 20px;
          height: 45px;
          font-size: 16px;
          border: #bdbdbd 1px solid;
          border-radius: 6px;
          -webkit-align-items: center;
          align-items: center;
          -webkit-justify-content: center;
          justify-content: center;
          cursor: pointer;

          &:first-child {
            margin-left: 0;
          }
        }

        .excel_btn {
          margin-left: auto;
        }


        .active {
          background: #00863C;
          border-radius: 4px;
          color: white;
          border: none
        }

        .inactive {
          background: #ffffff;
          border-radius: 4px;
          color: #949494;
          border: none
        }

      }

      input {

        &#filter-text-box {
          width: 100%;
        }
      }

      .result_outer {
        display: flex;
        border: 1px solid #ddd;
        border-radius: 6px;
        overflow: hidden;

        #question_survey {
          width: 30%
        }

        .three_tab#question_survey {
          width: 80% !important;
          border-right: none;

          &+.result_table.survey_display {
            width: 20% !important;

          }



          >thead {
            >tr {
              text-align: left;

              >th {
                padding-left: 40px;
              }
            }
          }


          >tbody {
            >tr {
              text-align: left;

              >td {
                padding-left: 40px;

              }
            }
          }
        }

        #user_stu_info {
          width: 30%;
        }

        #user_stu_info,
        #question_survey {
          border-right: 1px solid #ddd;

          // >thead {
          //   font-family: 'Pretendard';
          //   font-style: normal;
          //   font-weight: 400;
          //   font-size: 15px;
          //   line-height: 14px;
          //   /* or 93% */


          //   /* 기본색 333 */

          //   color: #333333;

          //   >tbody {
          //     >tr {
          //       td {
          //         font-family: 'Pretendard';
          //         font-style: normal;
          //         font-weight: 400;
          //         font-size: 15px;
          //         line-height: 25px;
          //         /* identical to box height, or 167% */


          //         color: #696969;
          //       }
          //     }
          //   }
          //}
        }

        // .survey_display {
        //   white-space: nowrap;
        //   overflow: auto;
        //   width: 70%;

        //   #user_survey_info {
        //     width: 100%;
        //   }

        //   &::-webkit-scrollbar {
        //     height: 12px;
        //   }

        //   &::-webkit-scrollbar-thumb {
        //     background-color: #c8cbdb;
        //   }

        //   &::-webkit-scrollbar-track {
        //     background-color: #f2f3f7;
        //   }

        //   .student_list {
        //     border-left: none !important;

        //     .question {
        //       width: 15rem !important;
        //     }

        //     .question_count {
        //       width: 7rem !important;
        //     }
        //   }

        //   .stu_content_title {
        //     padding: 10px;
        //     border-bottom: 1px solid #ddd;

        //     font-family: 'Pretendard';
        //     font-style: normal;
        //     font-weight: 400;
        //     font-size: 15px;
        //     line-height: 14px;
        //     /* or 93% */


        //     /* 기본색 333 */
        //     display: flex;
        //     justify-content: center;
        //     align-items: center;
        //     color: #333333;
        //     width: auto !important;
        //     height: 55px;
        //     line-height: 55px;
        //     // border-left: 1px solid #ddd;

        //     // &:first-child {
        //     //   border-left: 0;
        //     // }
        //     // &:first-child {
        //     //   max-width: 100px;
        //     // }
        //   }

        //   .stu_content {
        //     padding: 10px;

        //     width: 30rem !important;
        //     // border-left: 1px solid #ddd;
        //     display: flex;
        //     justify-content: center;
        //     align-items: center;
        //     // &:first-child {
        //     //   border-left: 0;
        //     // }
        //     font-family: 'Pretendard';
        //     font-style: normal;
        //     font-weight: 400;
        //     font-size: 15px;
        //     line-height: 25px;
        //     /* identical to box height, or 167% */


        //     color: #696969;

        //     // &:first-child {
        //     //   max-width: 100px;
        //     // }
        //   }

        //   .notSurvey {
        //     width: 100% !important;
        //     justify-content: flex-start !important;
        //   }
        // }

        // .result_table {

        //   .student_list {
        //     display: flex;
        //     // border-left: 1px solid black;

        //     &:nth-child(even) {
        //       background: #F6F8FB;
        //     }

        //     .listIdx {
        //       width: 4.5rem !important;
        //     }

        //     .stu_content_title {
        //       padding: 10px;

        //       border-bottom: 1px solid #ddd;

        //       font-family: 'Pretendard';
        //       font-style: normal;
        //       font-weight: 400;
        //       font-size: 15px;
        //       line-height: 14px;
        //       /* or 93% */
        //       display: flex;
        //       justify-content: center;
        //       align-items: center;

        //       /* 기본색 333 */

        //       color: #333333;
        //       width: auto !important;
        //       height: 55px;
        //       line-height: 55px;
        //       // border-left: 1px solid #ddd;

        //       // &:first-child {
        //       //   max-width: 100px;
        //       // }
        //     }

        //     .stu_content {
        //       padding: 10px;

        //       width: auto !important;
        //       // border-left: 1px solid #ddd;
        //       display: flex;
        //       justify-content: center;
        //       align-items: center;
        //       // &:first-child {
        //       //   border-left: 0;
        //       // }
        //       font-family: 'Pretendard';
        //       font-style: normal;
        //       font-weight: 400;
        //       font-size: 15px;
        //       line-height: 25px;
        //       /* identical to box height, or 167% */


        //       color: #696969;

        //       // &:first-child {
        //       //   max-width: 100px;
        //       // }
        //     }

        //     .question {
        //       width: 30rem;
        //     }

        //     .q-title {
        //       justify-content: flex-start !important;
        //     }
        //   }

        //   .result_table_header {
        //     // margin-top: 20px;
        //   }

        //   tbody>tr:hover {
        //     background-color: #E0E9F7 !important;
        //     color: #0471B6 !important;
        //   }
        // }

        .result_table {
          &.survey_display {
            width: 70%;

          }

          >thead {
            >tr {
              // display: flex;
              text-align: center;
              border-bottom: 1px solid #ddd;

              >th {
                padding: 15px 0;
                font-family: 'Pretendard';
                font-style: normal;
                font-weight: 400;
                font-size: 15px;
                line-height: 14px;
                /* or 93% */


                /* 기본색 333 */

                color: #333333;

                &:nth-child(1) {
                  width: 20%;
                }

                &:nth-child(2) {
                  width: 30%;

                }

                &:nth-child(3) {
                  width: 50%;

                }
              }
            }
          }

          >tbody {
            >tr {
              text-align: center;

              &:nth-child(odd) {
                background: #F8F8F8;
              }

              td {
                font-family: 'Pretendard';
                font-style: normal;
                font-weight: 400;
                font-size: 15px;
                line-height: 40px;
                height: 40px;
                /* identical to box height, or 93% */


                color: #696969;

                &:nth-child(1) {
                  width: 20%;
                }

                &:nth-child(2) {
                  width: 30%;

                }

                &:nth-child(3) {
                  width: 50%;

                }
              }
            }
          }
        }




        #user_survey_info,
        #question_summary_survey {

          //왼쪽 하단 테이블 정보
          >thead {
            >tr {
              // display: flex;
              text-align: center;
              border-bottom: 1px solid #ddd;

              >th {
                padding: 15px 0;
                font-family: 'Pretendard';
                font-style: normal;
                font-weight: 400;
                font-size: 15px;
                line-height: 14px;
                /* or 93% */


                /* 기본색 333 */

                color: #333333;
                min-width: 200px;
                width: auto;
                // &:nth-child(1) {
                //   width: 20%;
                // }

                // &:nth-child(2) {
                //   width: 30%;

                // }

                // &:nth-child(3) {
                //   width: 50%;

                // }
              }
            }
          }

          >tbody {
            >tr {
              text-align: center;

              &:nth-child(odd) {
                background: #F8F8F8;
              }

              td {
                font-family: 'Pretendard';
                font-style: normal;
                font-weight: 400;
                font-size: 15px;
                line-height: 40px;
                height: 40px;
                /* identical to box height, or 93% */


                color: #696969;

                // &:nth-child(1) {
                //   width: 20%;
                // }

                // &:nth-child(2) {
                //   width: 30%;

                // }

                // &:nth-child(3) {
                //   width: 50%;

                // }
              }
            }
          }
        }

      }
    }
  }

  .btn_container {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    >button {
      background: #00863C;
      border-radius: 4px;
      padding: 15px 30px;
      color: white;

    }
  }

  .survey_question {
    padding: 0 30px 15px;
    border: 1px solid #ddd;
    border-radius: 4px;
    margin: 20px 0;





    .surveyDescription {
      >.quill {
        >.ql-toolbar.ql-snow {
          display: none;
        }

        >.ql-container {
          border: none !important;
          height: auto;
          background: #F3F3F3;
          border-radius: 4px;
          font-family: 'Pretendard';
          font-style: normal;
          font-weight: 400;
          font-size: 15px;
          line-height: 14px;
          margin: 0px 0 10px 0;
          color: #696969;
        }
      }
    }




    .survey_popup_title {
      font-family: 'Pretendard';
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 20px;
      /* identical to box height, or 111% */


      /* 기본색 333 */

      color: #333333;
      padding: 20px;


    }


    .survey_popup_date,
    .survey_popup_user {
      display: flex;
      font-family: 'Pretendard';
      font-style: normal;
      font-weight: 400;
      font-size: 15px;
      line-height: 18px;
      padding: 5px;

      /* identical to box height */
      &>span:nth-child(1) {
        font-family: 'Pretendard';
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 14px;
        flex-basis: 8%;
        /* or 93% */

        color: #333333;
      }

      &>span:nth-last-child(1) {
        font-family: 'Pretendard';
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 14px;
        flex-basis: 10%;
        /* or 93% */

        color: #696969;
      }

    }



    .ta_div {
      .evaluate_table {
        background: #FFFFFF;
        border: 1px solid #DDDDDD;
        border-radius: 4px;

        .th_header {
          width: 60px;
          border: none;
          border-bottom: 1px solid #ddd;
          font-family: 'Pretendard';
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 14px;
          /* identical to box height, or 88% */


          /* 기본색 333 */

          color: #333333;
          background: none !important;
        }

        tbody {


          tr {
            &:nth-child(odd) {
              >td {
                background: #F8F8F8 !important;

              }
            }

            td {
              padding: 0 !important;

              @media screen and (max-width: 768px) {
                font-size: 10px;
              }

              background-color: #fff !important;
              vertical-align: middle;
              text-align: center;
              border: none;
              font-size: 16px;
              padding: 10px;
              font-family: 'Pretendard';
              font-style: normal;
              font-weight: 400;
              font-size: 15px;
              line-height: 40px;
              /* or 300% */


              /* 696969 */

              color: #696969;
            }

            .question_list_title {
              text-align: unset !important;
            }
          }
        }
      }
    }



    .boardDetail__content {
      background: #F3F3F3;
      border-radius: 4px;
      font-family: 'Pretendard';
      font-style: normal;
      font-weight: 400;
      font-size: 15px;
      line-height: 14px;
      /* identical to box height, or 93% */
      margin: 0px 0 10px 0;

      color: #696969;


      >.quill {
        >.ql-container {
          height: auto !important;
          border: none !important;

          >.ql-editor {
            >p {
              font-family: 'Pretendard';
              font-style: normal;
              font-weight: 400;
              font-size: 15px !important;
              line-height: 14px;
              color: #696969 !important;
              padding: 20px !important;

            }
          }
        }
      }



      input[type=text] {
        width: 100%;
        border: 1px solid #e5e5e5;
        height: 130px;
      }
    }

    h1 {
      font-family: 'Pretendard';
      font-style: normal;
      font-weight: 500 !important;
      font-size: 16px !important;
      line-height: 14px !important;
      color: #333333 !important;
      position: relative !important;
      padding: 30px 20px 20px 30px;

      &::before {
        content: '';
        display: block;
        height: 1.3em;
        border: 3px solid #00B852;
        /* border-radius: 50%; */
        position: absolute;
        left: 16px;
        top: 26px;
        width: 3px;
        background: #00B852;
      }
    }

    label {
      vertical-align: middle;
      padding: 0 0 15px 10px;
      font-family: 'Pretendard';
      font-style: normal;
      font-weight: 400;
      font-size: 15px;
      line-height: 35px;
      /* or 233% */

      display: flex;
      align-items: center;

      color: #696969;

      input[type=radio] {
        background-color: #ececec;
        border: 7px solid #ececec;
        width: 30px;
        height: 30px;
        display: inline-block;
        vertical-align: middle;
        margin-right: 15px;
        appearance: none;
        border-radius: 50px;

        &:checked {
          border: 7px solid #00B852;
          width: 30px;
          height: 30px;
        }
      }

      input[type=checkbox] {
        background-color: #ececec;
        border: 5px solid #ececec;
        width: 20px;
        height: 20px;
        display: inline-block;
        vertical-align: middle;
        margin-right: 15px;
        appearance: none;

        &:checked {
          border: 7px solid #00B852;
          width: 20px;
          height: 20px;
        }
      }
    }

    .evaluate_table {

      input[type=radio] {
        background-color: #ececec;
        border: 7px solid #ececec;
        width: 20px;
        height: 20px;
        display: inline-block;
        vertical-align: middle;
        appearance: none;
        border-radius: 50px;

        &:checked {
          border: 5px solid #00B852;
          width: 20px;
          height: 20px;
        }
      }
    }
  }
}



//강좌 config 추가를 위한 css kisti에서 가져옴... eric... 20221223

.course_config_wrap {
  .newIndexForm {
    border: 1px solid #ddd;
    padding: 8px;
    border-radius: 5px;
    height: auto !important;
  }

  margin-top: 7px !important;

  &.martopzero {
    margin-top: 0 !important;
  }

  &.martop {
    margin-top: 20px !important;
    border: 0;
    padding: 0;

    &.martops {
      margin-top: 10px !important;
      position: relative;

      .calWrapBody {
        .quiz-remove {
          right: 15px;
          top: 18px;
        }


        .calWrapBodyRight {
          .quiz-remove {
            top: 15px;
          }
        }
      }
    }


    .quiz-buttons {
      display: flex;

      .buttonLeft {
        width: 20%;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .buttonRight {
        width: 80%;
      }
    }

    .calWrapBody {
      margin-top: 0;
      box-shadow: none;
      padding: 0;
      position: relative;
      margin-bottom: 20px !important;

      .inremove {
        top: 15px;
        right: 15px;
      }


      .topTitles {
        display: flex;

        .topTitlesLeft {
          width: 20%;
          border-bottom: 1px solid #ddd;
          border-right: 1px solid #ddd;
          padding: 15px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 1.1rem;
          font-weight: 600;

          button {
            background-color: #00B852;
            padding: 3px 10px;
            border-radius: 13px;
            color: white;
            margin-left: 10px;
          }
        }

        .topTitlesRight {
          width: 80%;
          border-bottom: 1px solid #ddd;
          padding: 15px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 1.1rem;
          font-weight: 600;
        }
      }

      .bottomCont {
        display: flex;

        .calWrapBodyLeft {
          width: 20%;
          border-right: 1px solid #ddd;
          padding: 20px;

          ul {
            height: 100%;

            li {
              display: flex;
              align-items: center;
              justify-content: center;
              height: 100%;

              .notFlex {
                display: block;

                .red_state {
                  width: 100%;
                  text-align: center;
                  margin-top: 7px;
                }
              }

              .leftDay {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                margin-bottom: 5px;

                label {
                  width: 45px;
                  height: 100%;
                  display: flex;
                  align-items: center;
                }

                .calIndex {
                  width: calc(100% - 45px) !important;
                }

                &.dp_none {
                  display: none !important;
                }
              }



            }
          }


        }

        .calWrapBodyRight {
          width: 80%;
          padding: 20px;
          position: relative;

          .addbtnwrap {
            .addcontbtn {
              background: #43B4FF;
              color: #fff;
              height: 45px;
              border: 0;
            }
          }

          .quiz-option-body {
            margin: 0;
            background: rgb(235, 244, 233);
            padding-top: 40px;

            .reg_thd_wrap {
              li {
                width: 100%;
                margin: 0;


                &.short {
                  display: flex;
                  align-items: start;
                  justify-content: center;
                  flex-direction: column;

                  label {
                    width: 100%;
                    padding-right: 10px;
                    margin-bottom: 10px;

                    button {
                      background-color: #00B852;
                      padding: 3px 10px;
                      border-radius: 13px;
                      color: white;
                      margin-left: 10px;
                    }
                  }

                  .newIndexForm {
                    width: 100% !important;
                    margin-top: 10px;
                  }


                  .scheduleBoxIn {
                    width: calc(100% - 120px);

                    .red_state {
                      margin-top: 5px;
                    }

                    .newIndexForm {
                      width: 100% !important;
                    }
                  }

                }

                &.long {
                  display: flex;
                  align-items: start;
                  justify-content: center;
                  flex-direction: column;

                  label {
                    width: 120px;
                    padding-right: 10px;
                  }

                  .newIndexForm {
                    width: calc(100% - 120px) !important;
                  }

                  .scheduleBoxIn {
                    width: calc(100% - 120px);

                    .red_state {
                      margin-top: 5px;
                    }

                    .newIndexForm {
                      width: 100% !important;
                    }
                  }
                }

                &.wide {
                  display: flex;
                  align-items: start;
                  justify-content: center;
                  flex-direction: column;

                  .modal_sub_title {
                    width: 120px;
                    padding-right: 10px;
                  }

                  .input_container {
                    width: 100% !important;

                    .quill {
                      .ql-toolbar.ql-snow {
                        border: 1px solid #ddd
                      }

                      .ql-container.ql-snow {
                        border: 1px solid #ddd
                      }
                    }
                  }

                  .scheduleBoxIn {
                    width: calc(100% - 120px);

                    .red_state {
                      margin-top: 5px;
                    }

                    .newIndexForm {
                      width: 100% !important;
                    }
                  }
                }


                .newIndexTitle {
                  margin-top: 0;

                  button {
                    background-color: #00B852;
                    padding: 3px 10px;
                    border-radius: 13px;
                    color: white;
                    margin-left: 10px;
                  }

                }

                .quill {
                  .ql-toolbar {
                    background: #fff;
                  }

                  .ql-container {
                    background: #fff;
                  }

                }


                &~li {
                  padding-top: 20px;
                }
              }



            }
          }
        }
      }
    }
  }


  .quiz-option-body {
    width: 100%;
    margin: 20px 0 0;
    padding: 30px;
    border-radius: 6px;
    border: 1px solid #ddd;
    box-shadow: 0 20px 15px rgba(0, 0, 0, .05);
    position: relative;

    &.newCont {
      margin-top: 15px;
    }

    &~.quiz-option-body {
      margin-top: 30px;
    }

    &.newchortClass {
      box-shadow: none;
      border-radius: 10px;
      margin-top: 0;

      .reg_thd_wrap {
        &~.reg_thd_wrap {
          margin-top: 20px !important;
        }

        &~.martop {
          margin-top: 30px;
        }

        li {
          width: 100%;
          margin: 0;

          &~li {
            padding-top: 20px;
          }

          .modal_sub_title {
            margin-top: 0;
          }

          .flexWrap {
            display: flex;

            .flexBox {
              width: 50%;
              padding-right: 10px;

              &.minFlex {
                width: 90%;
              }

              &.minFlexs {
                width: 10%;
              }

              .cal_wrap {
                width: 100%;
              }

              &~.flexBox {
                padding-right: 0;
                padding-left: 10px;
              }


              .modal_sub_title {
                margin-top: 0;
              }
            }
          }

        }
      }


      &~.csTop {
        margin-top: 30px;
      }
    }

    .quiz-option-body {
      box-shadow: none;
    }

    .edit_line {
      border-right: 1px solid #ddd;

      @media (max-width: 1364px) {
        border-right: 0;
      }
    }

    .quiz-remove {
      position: absolute;
      right: 30px;
      top: 20px;
      width: 13px;
      cursor: pointer;

      &.discountBtn {
        top: 50%;
        transform: translateY(-50%);
      }
    }

    .hide {
      display: none;
    }

    .show {
      display: block;
    }

    .dsctitle {
      display: flex;
      align-items: center;
      margin-top: 0%;

      input {
        margin: 15px 20px;
        width: 20% !important;
      }

    }

    .bodyTop {
      width: 100%;
      display: flex;
      align-items: flex-end;
      justify-content: end;
      margin-top: 30px;



      .quiz-edit {
        padding: 8px 0;
        width: 100%;
        max-width: 145px;
        color: #fff;
        background-color: #0471B6;
        border-radius: 5px;
        margin: 0;
        margin-right: 10px;

        .reg_icon_01 {
          position: relative;
          top: 2px;
          margin: 0 3px 0 0;
          width: 18px;
        }
      }

      .quiz-setting {
        padding: 8px 0;
        width: 100%;
        max-width: 145px;
        color: #fff;
        background-color: #0D4B85;
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: center;

        a {
          color: #fff;
        }

        .reg_icon_02 {
          position: relative;
          top: 2px;
          margin: 0 3px 0 0;
          width: 16px;
        }
      }

      @media (max-width: 1024px) {
        margin-bottom: 20px;
      }

    }

    .bodyMiddle {
      display: flex;
      margin-top: 0;

      &.applyMiddle {
        justify-content: space-between;
      }

      .flex {
        display: block;
        width: 100%;

        &.lay_fir {
          width: 32%;
        }

        &.lay_sec {
          width: 66%;
        }

        &.editorLeft {
          padding-right: 20px;
          width: 30%;
        }

        &.editorRight {
          width: 70%;
        }


        .flexTitle {
          font-size: 1rem;
        }

        .flexCon {
          margin-top: 9px;

          .editor {
            width: 100%;

            &.contWraps {
              display: flex;

              .searchBtn {
                padding: 8px 0;
                width: 100%;
                max-width: 140px;
                background-color: #43B4FF;
                color: #fff;
                border-radius: 5px;

                .reg_search_icon {
                  position: relative;
                  top: 2px;
                  margin: 0 3px 0 0;
                  width: 15px;
                }
              }

              .classWraps {
                width: calc(100% - 140px);
                padding-left: 10px;
                display: flex;
                align-items: center;
                justify-content: center;

                .classInfoLeft {
                  margin-right: 10px;
                }


              }

            }

            .flexInput {
              width: 100%;
              padding: 14px 8px;
              border-radius: 10px;
            }
          }
        }


        @media (max-width: 1024px) {
          &.lay_fir {
            width: 100%;
          }

          &.lay_sec {
            margin: 20px 0 0;
            width: 100%;
          }
        }

      }

      @media (max-width: 1024px) {
        flex-wrap: wrap;
      }
    }

    .bodyBottom {
      display: flex;
      margin-top: 20px;

      .flex {
        display: block;
        width: 100%;

        &.bottomCon {
          margin-right: 2%;

          .editor {
            width: 100%;
            margin-top: 10px;

            .flexInput {
              border: 1px solid #ddd;
              padding: 8px;
              border-radius: 5px;
            }

          }
        }

        .editor {
          width: 100%;
          margin-top: 10px;

          .flexInput {
            border: 1px solid #ddd;
            padding: 8px;
            border-radius: 5px;
          }

        }

      }


      @media (max-width: 1024px) {
        display: block;
      }
    }

    .lm_tb_wrap {
      position: relative;
      margin: 20px 0;
      border: 1px solid #ddd;
      border-radius: 10px;
      overflow: hidden;

      .quiz-remove {
        top: 17px;
        height: 17px;
      }

      .lm_sub_tit {
        padding: 15px 25px;
        font-size: 16px;
        color: #43B4FF;
        font-weight: 700;
      }

      .lm__table {
        margin: 0;
        border: 0;
        border-top: 1px solid #ddd;
      }

      .lm_chk {
        justify-content: center;

        span {
          position: relative;
          margin: 0;
          width: 21px;
          height: 21px;
          border-radius: 6px;
          background: #fff;
          cursor: pointer;
        }

        input:checked+span {
          background: #43B4FF !important;

          &:after {
            content: "";
            position: absolute;
            top: 4px;
            left: 7px;
            width: 6px;
            height: 10px;
            border-right: 2px solid #fff;
            border-bottom: 2px solid #fff;
            transform: rotate(45deg);
          }
        }
      }
    }

    .lm__table {
      width: 100%;
      margin-top: 20px;
      text-align: center;
      border: 1px solid #ddd;
      border-radius: 5px !important;

      td.lm_none {
        padding: 10px;
      }
    }

    tr:nth-child(odd) {
      background-color: #F6F8FB;
    }

    .lm__th {
      min-width: 100px;
      padding: 20px;
      background-color: #fff !important;
      font-weight: 400;
    }

    .lm__td {
      min-width: 100px;
      padding: 10px 20px;
      vertical-align: middle;
    }
  }

  .add_course_config_btn {
    width: 100%;
    max-width: 160px;
    padding: 12px 0;
    color: #fff;
    background: #00B852;
    text-align: center;
    border-radius: 13px;
  }
}


.course_update_buttons {
  color: #00863C;
  background-color: #fff;
  text-align: center;

  border: 1px solid #00863C;
  border-radius: 5px;
  padding: 7px 30px;
  font-size: 0.9rem;
  margin-right: 10px;
}




.new_survey__detail {
  ._modal__block-body {
    padding: '20px 0';

  }


  .survey_input_containerBox_flex_wrap {
    display: flex;
    gap: 5%;

    .survey_input_containerBox {
      .insertForm__content.date {
        flex-direction: row !important;
      }

      .custom-checkbox {
        margin-top: 0px !important;
      }
    }
  }



  .survey_input_containerBox {
    margin-bottom: 10px;
    // display: flex;


    &.title {
      .insertForm__content {


        // border: 1px solid #DDDDDD;
        border-radius: 11px;
      }
    }


    &.description {
      .ql-container {
        height: 70px !important;
        padding: 10px !important;
      }

    }

    .searchUser {
      background: #00B852;
      border-radius: 4px;
      color: white;
      padding: 5px 50px;
    }




    // input.survey_desc {
    //   border: 0;
    //   border-bottom: 1px solid #ddd;
    //   border-radius: 0;
    //   outline: none;
    // }

    select.survey_select {
      min-width: 245px;
      max-width: 245px;
      height: 40px;
      padding: 0 15px;
      width: 100%;
      font-size: 14px;
      color: #333333;
      background: #fff url(https://lms.gjudec.com/assets/images/nav-toggle-down2.png) no-repeat calc(100% - 15px) center;
      appearance: none;
      border: 1px solid #D2D4D6;
      border-radius: 11px;
    }

    &:focus {
      outline: 0;
    }

    .insertForm__name {
      font-family: 'Pretendard';
      font-style: normal;
      font-weight: 600;
      font-size: 15px;
      line-height: 40px;
      /* identical to box height, or 267% */

      display: flex;
      align-items: center;

      color: #868686;


    }
  }

}














// // survey
// // 시험 모달
// .new_survey {
//   .survey__body {
//     padding: 20px 0;

//     .survey__body-content {
//       background: none;
//       padding: 20px;
//       border-radius: 13px;
//       border: none;

//       .survey__select-type-item {
//         border-radius: 13px;
//         background: #F6F7FB;
//       }

//       // 문제형식을 골라주세여
//       .survey_pick {
//         text-align: center;

//         &::before {
//           content: '';
//           display: inline-block;
//           width: 35px;
//           height: 25px;
//           margin-right: 10px;
//           vertical-align: middle;
//           background: url('#{$image-path}/test-icon-gj.png') no-repeat center;
//         }
//       }

//       // 문항
//       .survey__content-item {
//         border-radius: 13px;

//         .survey__content-item-header {
//           background: #fff;

//           h2 {
//             position: relative;

//             &.question {
//               text-indent: 60px;

//               &::before {
//                 content: '';
//                 position: absolute;
//                 left: 0;
//                 top: 50%;
//                 transform: translateY(-50%);
//               }

//               &.Mtch {
//                 &::before {
//                   width: 63px;
//                   height: 22px;
//                   background: url('#{$image-path}/Group 33808.png') no-repeat center;
//                 }
//               }

//               &.Plur {
//                 &::before {
//                   width: 75px;
//                   height: 22px;
//                   background: url('#{$image-path}/Group 33806.png') no-repeat center;
//                 }
//               }

//               &.Short {
//                 &::before {
//                   width: 67px;
//                   height: 22px;
//                   background: url('#{$image-path}/Group 33807.png') no-repeat center;
//                 }
//               }
//             }
//           }

//           >button {
//             border-radius: 0;
//             border: 0;
//           }
//         }

//         .surveyitem {
//           border-top: 1px dashed #ddd;

//           &.displaynone {
//             border-top: 0;
//           }
//         }
//       }
//     }
//   }

//   // 시험 취소추가 버튼
//   .answer-button-box {
//     .btn {
//       background: #ACDBA4;
//       border: 1px solid #ACDBA4;
//       border-radius: 13px;
//       color: #5C8D72;
//       min-width: 120px;
//       height: 40px;
//       line-height: 40px;
//       font-size: 0.875rem;

//       &::before {
//         content: '';
//         display: inline-block;
//         width: 19px;
//         height: 22px;
//         margin-right: 10px;
//         vertical-align: -5px;
//         background: url('#{$image-path}/survey-cancel-icon.png') no-repeat center;
//       }

//       &.submit {
//         border: 1px solid #55B87A;
//         background: #55B87A;
//         color: #fff;

//         &::before {
//           width: 19px;
//           height: 21px;
//           background: url('#{$image-path}/survey-add-icon.png') no-repeat center;
//         }
//       }
//     }
//   }
// }


.display {
  display: block;

  .reg_form {
    .new_survey__detail {

      .ql-toolbar.ql-snow {

        border: 1px solid #ccc !important;
        border-radius: 4px 4px 0 0;
        // border-bottom: none !important;

      }

      .ql-container.ql-snow {

        border: 1px solid #ccc !important;
        border-radius: 0 0 4px 4px;
        border-top: none !important;
      }
    }

    .new_survey {
      &._modal__block {
        border: 1px solid #D2D4D6;
        border-radius: 11px;
        padding: 0 15px !important;


      }




      .survey_del_btn,
      .survey-remove,
      .survey_arrow {
        img {
          width: 11px;
          height: 11px;
        }
      }

      .survey__select-type {
        display: flex;
        padding: 40px 0;

        .survey__select-type-item {
          flex: 1 1;
          border-radius: 5px;
          padding: 40px 0;
          height: 250px;
          text-align: center;
          background: #f2f2f2;
          width: 25%;
        }
      }

      ._btn {
        background: #ACDBA4;
        border: 1px solid #ACDBA4;
        color: #5C8D72;
        font-weight: 500;
        border-radius: 13px;
        height: 40px;
        padding: 0 30px;
        margin: 5px auto;
      }

      .survey__content-item {

        border: 1px solid #ddd;
        border-radius: 5px;
        overflow: hidden;

        &.active {
          border: 2px solid #00B852 !important;
          border-radius: 4px !important;
        }

        ._modal__block-body {
          padding: 5px 20px !important;
          border: none;

          &.survey_type {
            display: flex;
            gap: 1%;

            >div:nth-child(1) {
              flex-basis: 20%;
            }

            >div:nth-child(2) {
              flex-basis: 75%;
            }
          }

          &.survey_type,
          &.survey_content {
            .insertForm__name {
              font-family: 'Pretendard';
              font-style: normal;
              font-weight: 500;
              font-size: 13px;
              line-height: 37px;
              /* identical to box height, or 285% */

              display: flex;
              align-items: center;

              /* 696969 */

              color: #696969;
              padding: 0px;
            }

            .insertForm__content {



              .survey_type_div {
                background: #F8F8F8;
                border: 1px solid #DDDDDD;
                border-radius: 4px;
                padding: 9px;
                font-family: 'Pretendard';
                font-style: normal;
                font-weight: 400;
                font-size: 15px;
                line-height: 18px;
                color: #696969;
              }

              .ql-toolbar.ql-snow {
                height: auto;
                border: 1px solid #DDDDDD;
                border-bottom: none;
                border-radius: 4px 4px 0 0;
              }

              .ql-container {
                padding: 5px !important;

                &.ql-snow {
                  height: auto;
                  border: 1px solid #DDDDDD !important;
                  border-top: none;
                  border-radius: 0 0 4px 4px;
                }
              }
            }
          }


          &.survey_options {
            //옵션 보기 입력
            // border-top: 1px dashed #DDDDDD;
            // background: #F8F8F8;

            .survey-options {
              display: flex;
              flex-wrap: wrap;
              justify-content: space-between;
            }

          }
        }

        &.draggable {}

        &~.survey__content-item {
          margin-top: 10px;
        }
      }

      .survey__select-type-item {
        flex: 1;
        border-radius: 5px;
        padding: 40px 0;
        text-align: center;
        background: #f2f2f2;

        &~.survey__select-type-item {
          margin-left: 10px;
        }

        .img {
          height: 60px;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        p {
          margin-top: 10px;
        }

        .custom-checkbox {
          width: 18px;
          margin: 0 auto;
          margin-top: 20px;

          span {
            margin: 0;
            background: #fff;
          }
        }
      }

      .survey__header {
        height: 80px;
        border-bottom: 1px solid #ddd;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      .survey__title {
        font-size: 1.5rem;
        font-weight: 600;
      }

      .survey__info {
        margin-left: auto;
        display: flex;
        align-items: center;

        .block {
          display: flex;
          align-items: center;
          color: #666666;
        }

        .block--clock {
          margin-left: 40px;

          strong {
            padding-left: 2em;
            background: url('#{$image-path}/i-clock.png') no-repeat left center !important;
          }

          .clock {
            display: flex;
            align-items: center;

            i {
              font-style: normal;
              margin: 0 5px;
            }

            span {
              width: 45px;
              height: 35px;
              line-height: 33px;
              border-radius: 5px;
              text-align: center;
              border: 1px solid #ddd;
            }
          }

        }

        strong {
          color: #000000;
          margin-right: 5px;
        }

      }

      .survey__state {
        display: flex;
        align-items: center;
        height: 100px;
        padding: 0 30px;
        position: relative;

        .btn {
          display: flex;
          align-items: center;
          color: #666666;
          line-height: 40px;
          padding: 0 20px;
          border-radius: 5px;
          border: 1px solid #666;

          svg {
            margin-right: 5px;
          }
        }

        .right {
          display: flex;
          align-items: center;

          .btn {
            margin-left: 10px;
          }
        }

        .left {
          margin-right: auto;
          display: flex;
          align-items: center;

          .btn {
            border-color: #2e8bc5;
            color: #2e8bc5;
            margin-right: 10px;

            &.active {
              background: #2e8bc5;
              color: #fff;
            }
          }
        }

        .center {
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          display: flex;
          align-items: center;

          .survey__progress {
            display: flex;
            align-items: center;

            strong {
              font-weight: 600;
              font-size: 1.25rem;
            }

            .bar {
              width: 200px;
              background: #e5eaf1;
              border-radius: 2em;
              overflow: hidden;

              span {
                border-radius: 2em;
                display: block;
                width: 0;
                background: #2e8bc5;
                height: 5px;
              }
            }

            .state {
              display: flex;
              margin: 0 10px 0 20px;

              span {
                color: #2e8bc5;
                ;
              }
            }
          }

          p {
            padding: 0 20px;
            line-height: 1em;
            font-size: 1.25rem;

            &~p {
              border-left: 1px solid #ddd;
            }

            strong {
              margin-right: 10px;
              font-size: 1.25rem;
            }

            span {
              color: #2e8bc5;
              ;
            }
          }
        }
      }

      .survey__body {
        padding-bottom: 30px;

        .survey__body-content {
          background: #999;
          // padding: 30px;
          display: flex;
        }

        .survey__content-block-list {
          padding: 30px 0 100px;
          min-height: 100%;
          position: relative;

          .survey__controller {
            display: flex;
            align-items: center;
            justify-content: space-between;
            position: absolute;
            left: 0;
            bottom: 30px;
            width: 100%;

            .control-btn {
              display: flex;
              align-items: center;

              span {
                width: 40px;
                height: 40px;
                display: flex;
                align-items: center;
                justify-content: center;
                border: 1px solid #ddd;
                border-radius: 5px;
              }

              p {
                color: #666;
                font-size: 14px;
                margin: 0 10px;
              }
            }
          }
        }

        .survey__content-block {
          h2 {
            font-size: 1rem;
            font-weight: 600;
            margin-bottom: 15px;
          }

          &~.survey__content-block {
            margin-top: 40px;
          }
        }

        .survey__body-answer {
          background: #fff;
          border-radius: 5px;
          overflow: hidden;
          flex: 1;
          width: 320px;
          flex: none;
          margin-left: 10px;
        }

        .survey__body-inner {
          display: flex;
          border-radius: 5px;
          overflow: hidden;
          flex: 1;
        }

        .survey__content {
          flex: 1;
          background: #fff;
          min-height: 530px;
          position: relative;
          // padding: 0 40px;
        }

        .survey__init {
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);

          .img {
            text-align: center;
            margin-bottom: 1.5em;
          }


        }

        .survey__nav {
          width: 80px;
          flex: none;
          background: #dfeaf2;

          &.displaynone {
            display: none;
          }
        }

        .survey__nav-state-img {
          height: 60px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 40px;
          color: #adbfcc;
        }

        .survey__nav-list {
          .survey__nav-item {
            position: relative;
            width: 40px;
            height: 40px;
            display: block;
            margin: 0 auto;

            i {
              position: absolute;
              z-index: 5;
              background: #fff;
              color: #2e8bc5;
              width: 15px;
              height: 15px;
              border-radius: 3px;
              right: -3px;
              top: -3px;

              svg {
                vertical-align: top;
              }
            }

            &~.survey__nav-item {
              margin-top: 20px;
            }

          }

          button {
            width: 100%;
            height: 100%;
            background: #2e8bc5;
            color: #fff;
            border-radius: 50%;
            font-size: 12px;
            position: relative;

            &.number {
              background: none;
              border: 3px solid #2e8bc5;
              color: #2e8bc5;

              &:disabled {
                color: #adbfcc;
                border-color: #adbfcc;
              }
            }

            span {
              font-size: 20px;
            }

            &.more {
              background: #adbfcc;
            }

          }
        }
      }















      .survey__body {
        padding: 20px 0;

        .survey__body-content {
          background: none;
          // padding: 20px;
          border-radius: 13px;
          border: none;

          .survey__select-type-item {
            border-radius: 13px;
            background: #F6F7FB;
          }

          // 문제형식을 골라주세여
          .survey_pick {
            text-align: center;
            margin-top: 30px;

            &::before {
              content: '';
              display: inline-block;
              width: 35px;
              height: 25px;
              margin-right: 10px;
              vertical-align: middle;
              background: url('#{$image-path}/test-icon-gj.png') no-repeat center;
            }
          }

          .flexBox {
            display: flex;
            overflow: auto;

            .survey-option-item2 {
              position: relative;
              padding: 5px 20px;
              max-width: 180px;

              .top_flex_wrap {
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: 100%;
                height: 30px;
                font-size: 13px;
                color: #696969;
                text-align: center;
                background: #E7F0EA;
                border: 1px solid #ddd;
                border-bottom: none;
                border-radius: 3px 3px 0px 0px;
              }

              .text {
                display: block;
                // margin: 0 auto;
                width: 100%;
                height: 30px;
                font-size: 13px;
                color: #696969;
                text-align: center;
                background: #fff;
                border: 1px solid #ddd;
                border-bottom: none;
                border-radius: 0px 0px 0px 0px;
              }

              .number {
                display: block;
                // margin: 0 auto;
                width: 100%;
                height: 30px;
                font-size: 13px;
                color: #696969;
                text-align: center;
                background: #ffffff;
                border-radius: 0px 0px 3px 3px;
                border: 1px solid #dddddd;

              }

              .survey-remove {

                margin: 0 0 0 10px
              }

              .survey-delete {
                margin: 0 10px 0 0;
              }



              &.none {
                display: block;

                // background-color: #ff000080;
                .top_flex_wrap {

                  background-color: #DDDDDD;
                }
              }
            }

            .survey-buttons {
              margin-left: auto;

              button {
                &::before {
                  content: "+";
                  display: block;
                  height: 20px;
                  line-height: 20px;
                }
              }

              ._btn.bg-none {
                background: none;
                border: none;
                font-size: 15px;
              }
            }
          }

          .survey_ex_wrap {
            .insertForm__content {
              flex: none;
              width: 100%;

              .flexBox {
                align-items: center;
                margin: 0 0 25px;
                border: 1px solid #ddd;
                // border-bottom: none;
                border-radius: 4px;
                background-color: #ffffff;
              }

              .columnBar {
                width: 1px;
                height: 70px;
                background-color: #ddd;
              }

              .add_row_btn {
                height: 80px;
                display: flex;
                border: 1px dashed #DDDDDD;
                border-radius: 4px;
                margin-right: 20px;
              }

              .survey-options {
                display: grid;
                grid-template-columns: 1fr 1fr 1fr;
              }
            }

            .flex {

              &.flexWrap {
                padding: 12px;
                border: 1px solid #ddd;
                border-radius: 13px 13px 0 0;

                h3 {
                  margin: 0 10px 0 0 !important;
                  padding: 0;
                  width: 70px;
                  line-height: 25px;
                  font-size: 13px;
                  color: #fff;
                  font-weight: 600;
                  text-align: center;
                  background: #ACDBA4;
                  border-radius: 200px;
                }

                input {
                  padding: 0 10px;
                  width: 100%;
                  height: 40px;
                  border: 1px solid #ddd;
                  border-radius: 5px;
                }

                &+div {
                  display: flex;
                  border: 1px solid #ddd;
                  border-top: 0;
                  border-radius: 0 0 4px 4px;
                  justify-content: space-around;

                  &.none {
                    display: none;
                  }

                  >div {
                    padding: 20px 0;
                    // min-width: 120px;
                    text-align: center;
                    display: flex;
                    justify-content: center;
                  }

                  label {
                    display: block;
                    margin: 0 0 0 10px;
                    line-height: 25px;
                    font-size: 13px;
                    color: #696969;
                  }
                }
              }

              h3 {
                margin: 0 10px 0 0 !important;
                padding: 0;
                width: 70px;
                line-height: 25px;
                font-size: 13px;
                color: #fff;
                font-weight: 600;
                text-align: center;
                background: #ACDBA4;
                border-radius: 200px;
              }

              input {
                padding: 0 10px;
                width: 100%;
                height: 40px;
                border: 1px solid #ddd;
                border-radius: 5px;
              }
            }

            .edit_wrap {
              width: 100%;

              .editor {
                justify-content: center;
                display: flex;
              }

              div {
                textarea {
                  // padding: 10px 15px;
                  height: 45px;
                  resize: none;
                  width: 100%;
                  border: 1px solid #ddd;
                  border-radius: 5px;
                  font-size: 14px;
                  color: #666666;
                }
              }
            }

            .survey-remove {
              margin: 0 10px 0 10px;
              width: 20px;
            }

            .survey-option-item {
              // margin: 15px 0;
              flex-basis: 33%;

              &.none {
                display: block;
                // background-color: #ff000080;
                // border-radius: 20px;
              }

              .survey-option-body {
                display: -webkit-flex;
                display: flex;
                -webkit-align-items: center;
                align-items: center;
                padding: 5px 3px;


                .survey-table-item-title-wrap {
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                  height: 100%;
                  max-height: 20px;

                  .survey-table-item-title {
                    font-family: 'Pretendard';
                    font-style: normal;
                    font-weight: 500;
                    font-size: 13px;
                    line-height: 37px;
                    /* identical to box height, or 285% */

                    display: flex;
                    align-items: center;

                    /* 696969 */

                    color: #696969;

                    &.required {
                      &::after {
                        content: '*';
                        color: #55B87A !important;
                      }
                    }

                  }
                }



                .flex {
                  width: 100%;
                  height: auto;
                  justify-content: center;
                  padding: 15px;
                }

                >div {
                  width: 100%;

                  .survey-table-item-container {
                    &.none {
                      display: block;
                      // background-color: #ff000080;
                      // border-radius: 13px;

                      .flex.flexWrap {
                        background-color: #DDDDDD;

                      }
                    }
                  }
                }

                .survey-table-item-container {
                  background: white;

                  .flex {
                    background: #E7F0EA;
                    border-radius: 4px 4px 0px 0px;
                  }
                }
              }
            }
          }

          .survey__content-item-header {
            height: 55px;
            // background:#f6f8fb;
            padding: 0 20px 0 40px;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            background: url('#{$image-path}/admin/menu_on.svg') no-repeat 20px center;
            background-size: 10px;

            h2 {
              margin-right: auto;
              padding: 0;
              line-height: 2.5;
              font-size: 1rem;
              font-weight: 600;


            }

            button {
              width: 30px;
              height: 30px;
              // border: 1px solid #ddd;
              // background:#fff;
              // border-radius: 50%;
              margin-left: 10px;

              svg {
                vertical-align: middle;
                margin-top: -5px;
              }
            }
          }

          .survey__content-form {
            .survey__content-form-actions {
              display: flex;
              align-items: center;
              justify-content: space-between;
              padding: 5px 0;

              >div {
                display: flex;

                button {
                  height: 60px;
                  font-size: 15px;
                  width: 135px;
                  color: #999;
                  // border-bottom: 4px solid #999999;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                }

                .active {
                  color: #1A9952;
                  border-bottom: 3px solid #1A9952;
                }
              }



              >h3 {

                font-family: 'Pretendard';
                font-style: normal;
                font-weight: 500;
                font-size: 13px;
                line-height: 35px;
                /* identical to box height, or 269% */

                display: flex;
                align-items: center;

                color: #868686;

              }

            }

            .survey__content-lists {
              .survey__content-lists-btn-group {
                display: flex;
                gap: 10px;
                justify-content: center;
                align-items: center;
              }


              .surveyaddbtn {
                display: block;
                color: #fff;
                font-size: 14px;
                flex: none;
                white-space: nowrap;
                height: 40px;
                padding: 0 30px;
                border-radius: 4px;
                background: #00B852;
                margin: 0;
                margin-top: 20px;
              }

              .templeteaddbtn {
                display: block;
                color: #fff;
                font-size: 14px;
                flex: none;
                white-space: nowrap;
                height: 40px;
                padding: 0 30px;
                border-radius: 4px;
                background: #00863C;
                margin: 0;
                margin-top: 20px;
              }
            }

            .answer-button-box {
              display: flex;
              justify-content: flex-end;

              .btn {
                margin-left: 10px;
              }
            }

          }

          // 문항
          .survey__content-item {
            border-radius: 4px;

            .survey__content-item-header {
              background: #fff;

              label.checkbox {
                background: url('https://lms.gjudec.com/assets/images/active-arrow.png') no-repeat center;
                background-color: #696969;
                border-radius: 10px;
                height: 25px;
                width: 25px;
                cursor: pointer;
              }

              .checked {
                background-color: #00B852 !important;


              }


              h2 {
                position: relative;

                &.question {
                  // text-indent: 60px;

                  &::before {
                    content: '';
                    position: absolute;
                    left: 0;
                    top: 50%;
                    transform: translateY(-50%);
                  }

                  &.Mtch {
                    &::before {
                      content: '';
                      display: block;
                      height: 1.3em;
                      border: 3px solid #00B852;
                      /* border-radius: 50%; */
                      position: absolute;
                      left: -19px;
                      top: 20px;
                      // width: 63px;
                      // height: 22px;
                      // background: url('#{$image-path}/Group 33808.png') no-repeat center;
                    }
                  }

                  &.Plur {
                    &::before {
                      content: '';
                      display: block;
                      height: 1.3em;
                      border: 3px solid #00B852;
                      background-color: #00B852;
                      width: 3px;
                      /* border-radius: 50%; */
                      position: absolute;
                      left: -19px;
                      top: 20px;
                      // width: 75px;
                      // height: 22px;
                      // background: url('#{$image-path}/Group 33806.png') no-repeat center;
                    }
                  }

                  &.Short {
                    &::before {
                      content: '';
                      display: block;
                      height: 1.3em;
                      border: 3px solid #00B852;
                      /* border-radius: 50%; */
                      position: absolute;
                      left: -19px;
                      top: 20px;
                      background-color: #00B852;
                      width: 3px;
                      // width: 67px;
                      // height: 22px;
                      // background: url('#{$image-path}/Group 33807.png') no-repeat center;
                    }
                  }

                  &.table_tit {
                    &::before {
                      content: '';
                      display: block;
                      height: 1.3em;
                      border: 3px solid #00B852;
                      /* border-radius: 50%; */
                      position: absolute;
                      left: -19px;
                      top: 20px;
                      background-color: #00B852;
                      width: 3px;
                      // width: 67px;
                      // height: 22px;
                      // background: url('#{$image-path}/Group 33807.png') no-repeat center;
                    }
                  }

                  &.Mtch2 {
                    &::before {
                      content: '';
                      display: block;
                      height: 1.3em;
                      border: 3px solid #868686;
                      /* border-radius: 50%; */
                      position: absolute;
                      left: -19px;
                      top: 20px;
                      background-color: #868686;
                      width: 3px;
                      // width: 63px;
                      // height: 22px;
                      // background: url('#{$image-path}/Group 33808.png') no-repeat center;
                    }
                  }

                  &.Plur2 {
                    &::before {
                      content: '';
                      display: block;
                      height: 1.3em;
                      border: 3px solid #868686;
                      /* border-radius: 50%; */
                      position: absolute;
                      left: -19px;
                      top: 20px;
                      background-color: #868686;
                      width: 3px;
                      // width: 75px;
                      // height: 22px;
                      // background: url('#{$image-path}/Group 33806.png') no-repeat center;
                    }
                  }

                  &.Short2 {
                    &::before {
                      content: '';
                      display: block;
                      height: 1.3em;
                      border: 3px solid #868686;
                      /* border-radius: 50%; */
                      position: absolute;
                      left: -19px;
                      top: 20px;
                      background-color: #868686;
                      width: 3px;
                      // width: 67px;
                      // height: 22px;
                      // background: url('#{$image-path}/Group 33807.png') no-repeat center;
                    }
                  }

                  &.table_tit2 {
                    &::before {
                      content: '';
                      display: block;
                      height: 1.3em;
                      border: 3px solid #868686;
                      /* border-radius: 50%; */
                      position: absolute;
                      left: -19px;
                      top: 20px;
                      background-color: #868686;
                      width: 3px;
                      // width: 67px;
                      // height: 22px;
                      // background: url('#{$image-path}/Group 33807.png') no-repeat center;
                    }
                  }
                }
              }

              >button {
                border-radius: 0;
                border: 0;
              }
            }

            .surveyitem {
              border-top: 1px dashed #ddd;

              &.displaynone {
                border-top: 0;
              }
            }
          }

          .survey__content-lists {
            .surveyaddbtn {
              display: block;
              color: #fff;
              font-size: 14px;
              flex: none;
              white-space: nowrap;
              height: 40px;
              padding: 0 30px;
              border-radius: 3px;
              background: #55B87A;
              margin: 0 auto;
              margin-top: 20px;
            }
          }
        }
      }

      // 시험 취소추가 버튼
      .answer-button-box {
        .btn {
          background: #ACDBA4;
          border: 1px solid #ACDBA4;
          border-radius: 3px;
          color: #5C8D72;
          min-width: 120px;
          height: 40px;
          font-size: 0.875rem;


        }
      }
    }
  }


}

.img_wrapper_edit_sub {
  display: flex !important;
  flex-wrap: wrap !important;
  align-items: flex-start !important;
  gap: 20px !important;
  max-width: 1100px !important;

  @media screen and(max-width:1149px) {
    max-width: 800px !important;
    justify-content: center;
    align-items: center;
  }

  &>.sub__title {
    width: 100%;
  }

  &>div {
    width: 48%;

    &>img {
      width: 95%;
    }

    @media screen and(max-width:1149px) {
      width: 100%;
    }
  }

  &>div:nth-last-child(1) {
    width: 48%;

    @media screen and(max-width:1149px) {
      width: 100%;
    }
  }
}



.display {
  display: block;

  .reg_form {
    .new_survey__detail {

      .ql-toolbar.ql-snow {

        border: 1px solid #ccc !important;
        border-radius: 4px 4px 0 0;
        // border-bottom: none !important;

      }

      .ql-container.ql-snow {

        border: 1px solid #ccc !important;
        border-radius: 0 0 4px 4px;
        border-top: none !important;
      }
    }

    .new_survey {
      &._modal__block {
        border: 1px solid #D2D4D6;
        border-radius: 11px;
        padding: 0 15px !important;


      }




      .survey_del_btn,
      .survey-remove,
      .survey_arrow {
        img {
          width: 11px;
          height: 11px;
        }
      }

      .survey__select-type {
        display: flex;
        padding: 40px 0;

        .survey__select-type-item {
          flex: 1 1;
          border-radius: 5px;
          padding: 40px 0;
          height: 250px;
          text-align: center;
          background: #f2f2f2;
          width: 25%;
        }
      }

      ._btn {
        background: #ACDBA4;
        border: 1px solid #ACDBA4;
        color: #5C8D72;
        font-weight: 500;
        border-radius: 13px;
        height: 40px;
        padding: 0 30px;
        margin: 5px auto;
      }

      .survey__content-item {

        border: 1px solid #ddd;
        border-radius: 5px;
        overflow: hidden;

        &.active {
          border: 2px solid #00B852 !important;
          border-radius: 4px !important;
        }

        ._modal__block-body {
          padding: 5px 20px !important;
          border: none;

          &.survey_type {
            display: flex;
            gap: 1%;

            >div:nth-child(1) {
              flex-basis: 20%;
            }

            >div:nth-child(2) {
              flex-basis: 75%;
            }
          }

          &.survey_type,
          &.survey_content {
            .insertForm__name {
              font-family: 'Pretendard';
              font-style: normal;
              font-weight: 500;
              font-size: 13px;
              line-height: 37px;
              /* identical to box height, or 285% */

              display: flex;
              align-items: center;

              /* 696969 */

              color: #696969;
              padding: 0px;
            }

            .insertForm__content {



              .survey_type_div {
                background: #F8F8F8;
                border: 1px solid #DDDDDD;
                border-radius: 4px;
                padding: 9px;
                font-family: 'Pretendard';
                font-style: normal;
                font-weight: 400;
                font-size: 15px;
                line-height: 18px;
                color: #696969;
              }

              .ql-toolbar.ql-snow {
                height: auto;
                border: 1px solid #DDDDDD;
                border-bottom: none;
                border-radius: 4px 4px 0 0;
              }

              .ql-container {
                padding: 5px !important;

                &.ql-snow {
                  height: auto;
                  border: 1px solid #DDDDDD !important;
                  border-top: none;
                  border-radius: 0 0 4px 4px;
                }
              }
            }
          }


          &.survey_options {
            //옵션 보기 입력
            // border-top: 1px dashed #DDDDDD;
            // background: #F8F8F8;

            .survey-options {
              display: flex;
              flex-wrap: wrap;
              justify-content: space-between;
            }

          }
        }

        &.draggable {}

        &~.survey__content-item {
          margin-top: 10px;
        }
      }

      .survey__select-type-item {
        flex: 1;
        border-radius: 5px;
        padding: 40px 0;
        text-align: center;
        background: #f2f2f2;

        &~.survey__select-type-item {
          margin-left: 10px;
        }

        .img {
          height: 60px;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        p {
          margin-top: 10px;
        }

        .custom-checkbox {
          width: 18px;
          margin: 0 auto;
          margin-top: 20px;

          span {
            margin: 0;
            background: #fff;
          }
        }
      }

      .survey__header {
        height: 80px;
        border-bottom: 1px solid #ddd;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      .survey__title {
        font-size: 1.5rem;
        font-weight: 600;
      }

      .survey__info {
        margin-left: auto;
        display: flex;
        align-items: center;

        .block {
          display: flex;
          align-items: center;
          color: #666666;
        }

        .block--clock {
          margin-left: 40px;

          strong {
            padding-left: 2em;
            background: url('#{$image-path}/i-clock.png') no-repeat left center !important;
          }

          .clock {
            display: flex;
            align-items: center;

            i {
              font-style: normal;
              margin: 0 5px;
            }

            span {
              width: 45px;
              height: 35px;
              line-height: 33px;
              border-radius: 5px;
              text-align: center;
              border: 1px solid #ddd;
            }
          }

        }

        strong {
          color: #000000;
          margin-right: 5px;
        }

      }

      .survey__state {
        display: flex;
        align-items: center;
        height: 100px;
        padding: 0 30px;
        position: relative;

        .btn {
          display: flex;
          align-items: center;
          color: #666666;
          line-height: 40px;
          padding: 0 20px;
          border-radius: 5px;
          border: 1px solid #666;

          svg {
            margin-right: 5px;
          }
        }

        .right {
          display: flex;
          align-items: center;

          .btn {
            margin-left: 10px;
          }
        }

        .left {
          margin-right: auto;
          display: flex;
          align-items: center;

          .btn {
            border-color: #2e8bc5;
            color: #2e8bc5;
            margin-right: 10px;

            &.active {
              background: #2e8bc5;
              color: #fff;
            }
          }
        }

        .center {
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          display: flex;
          align-items: center;

          .survey__progress {
            display: flex;
            align-items: center;

            strong {
              font-weight: 600;
              font-size: 1.25rem;
            }

            .bar {
              width: 200px;
              background: #e5eaf1;
              border-radius: 2em;
              overflow: hidden;

              span {
                border-radius: 2em;
                display: block;
                width: 0;
                background: #2e8bc5;
                height: 5px;
              }
            }

            .state {
              display: flex;
              margin: 0 10px 0 20px;

              span {
                color: #2e8bc5;
                ;
              }
            }
          }

          p {
            padding: 0 20px;
            line-height: 1em;
            font-size: 1.25rem;

            &~p {
              border-left: 1px solid #ddd;
            }

            strong {
              margin-right: 10px;
              font-size: 1.25rem;
            }

            span {
              color: #2e8bc5;
              ;
            }
          }
        }
      }

      .survey__body {
        padding-bottom: 30px;

        .survey__body-content {
          background: #999;
          // padding: 30px;
          display: flex;
        }

        .survey__content-block-list {
          padding: 30px 0 100px;
          min-height: 100%;
          position: relative;

          .survey__controller {
            display: flex;
            align-items: center;
            justify-content: space-between;
            position: absolute;
            left: 0;
            bottom: 30px;
            width: 100%;

            .control-btn {
              display: flex;
              align-items: center;

              span {
                width: 40px;
                height: 40px;
                display: flex;
                align-items: center;
                justify-content: center;
                border: 1px solid #ddd;
                border-radius: 5px;
              }

              p {
                color: #666;
                font-size: 14px;
                margin: 0 10px;
              }
            }
          }
        }

        .survey__content-block {
          h2 {
            font-size: 1rem;
            font-weight: 600;
            margin-bottom: 15px;
          }

          &~.survey__content-block {
            margin-top: 40px;
          }
        }

        .survey__body-answer {
          background: #fff;
          border-radius: 5px;
          overflow: hidden;
          flex: 1;
          width: 320px;
          flex: none;
          margin-left: 10px;
        }

        .survey__body-inner {
          display: flex;
          border-radius: 5px;
          overflow: hidden;
          flex: 1;
        }

        .survey__content {
          flex: 1;
          background: #fff;
          min-height: 530px;
          position: relative;
          // padding: 0 40px;
        }

        .survey__init {
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);

          .img {
            text-align: center;
            margin-bottom: 1.5em;
          }


        }

        .survey__nav {
          width: 80px;
          flex: none;
          background: #dfeaf2;

          &.displaynone {
            display: none;
          }
        }

        .survey__nav-state-img {
          height: 60px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 40px;
          color: #adbfcc;
        }

        .survey__nav-list {
          .survey__nav-item {
            position: relative;
            width: 40px;
            height: 40px;
            display: block;
            margin: 0 auto;

            i {
              position: absolute;
              z-index: 5;
              background: #fff;
              color: #2e8bc5;
              width: 15px;
              height: 15px;
              border-radius: 3px;
              right: -3px;
              top: -3px;

              svg {
                vertical-align: top;
              }
            }

            &~.survey__nav-item {
              margin-top: 20px;
            }

          }

          button {
            width: 100%;
            height: 100%;
            background: #2e8bc5;
            color: #fff;
            border-radius: 50%;
            font-size: 12px;
            position: relative;

            &.number {
              background: none;
              border: 3px solid #2e8bc5;
              color: #2e8bc5;

              &:disabled {
                color: #adbfcc;
                border-color: #adbfcc;
              }
            }

            span {
              font-size: 20px;
            }

            &.more {
              background: #adbfcc;
            }

          }
        }
      }















      .survey__body {
        padding: 20px 0;

        .survey__body-content {
          background: none;
          // padding: 20px;
          border-radius: 13px;
          border: none;

          .survey__select-type-item {
            border-radius: 13px;
            background: #F6F7FB;
          }

          // 문제형식을 골라주세여
          .survey_pick {
            text-align: center;
            margin-top: 30px;

            &::before {
              content: '';
              display: inline-block;
              width: 35px;
              height: 25px;
              margin-right: 10px;
              vertical-align: middle;
              background: url('#{$image-path}/test-icon-gj.png') no-repeat center;
            }
          }

          .flexBox {
            display: flex;
            overflow: auto;

            .survey-option-item2 {
              position: relative;
              padding: 5px 20px;
              max-width: 180px;

              .top_flex_wrap {
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: 100%;
                height: 30px;
                font-size: 13px;
                color: #696969;
                text-align: center;
                background: #E7F0EA;
                border: 1px solid #ddd;
                border-bottom: none;
                border-radius: 3px 3px 0px 0px;
              }

              .text {
                display: block;
                // margin: 0 auto;
                width: 100%;
                height: 30px;
                font-size: 13px;
                color: #696969;
                text-align: center;
                background: #fff;
                border: 1px solid #ddd;
                border-bottom: none;
                border-radius: 0px 0px 0px 0px;
              }

              .number {
                display: block;
                // margin: 0 auto;
                width: 100%;
                height: 30px;
                font-size: 13px;
                color: #696969;
                text-align: center;
                background: #ffffff;
                border-radius: 0px 0px 3px 3px;
                border: 1px solid #dddddd;

              }

              .survey-remove {

                margin: 0 0 0 10px
              }

              .survey-delete {
                margin: 0 10px 0 0;
              }



              &.none {
                display: block;

                // background-color: #ff000080;
                .top_flex_wrap {

                  background-color: #DDDDDD;
                }
              }
            }

            .survey-buttons {
              margin-left: auto;

              button {
                &::before {
                  content: "+";
                  display: block;
                  height: 20px;
                  line-height: 20px;
                }
              }

              ._btn.bg-none {
                background: none;
                border: none;
                font-size: 15px;
              }
            }
          }

          .survey_ex_wrap {
            .insertForm__content {
              flex: none;
              width: 100%;

              .flexBox {
                align-items: center;
                margin: 0 0 25px;
                border: 1px solid #ddd;
                // border-bottom: none;
                border-radius: 4px;
                background-color: #ffffff;
              }

              .columnBar {
                width: 1px;
                height: 70px;
                background-color: #ddd;
              }

              .add_row_btn {
                height: 80px;
                display: flex;
                border: 1px dashed #DDDDDD;
                border-radius: 4px;
                margin-right: 20px;
              }

              .survey-options {
                display: grid;
                grid-template-columns: 1fr 1fr 1fr;
              }
            }

            .flex {

              &.flexWrap {
                padding: 12px;
                border: 1px solid #ddd;
                border-radius: 13px 13px 0 0;

                h3 {
                  margin: 0 10px 0 0 !important;
                  padding: 0;
                  width: 70px;
                  line-height: 25px;
                  font-size: 13px;
                  color: #fff;
                  font-weight: 600;
                  text-align: center;
                  background: #ACDBA4;
                  border-radius: 200px;
                }

                input {
                  padding: 0 10px;
                  width: 100%;
                  height: 40px;
                  border: 1px solid #ddd;
                  border-radius: 5px;
                }

                &+div {
                  display: flex;
                  border: 1px solid #ddd;
                  border-top: 0;
                  border-radius: 0 0 4px 4px;
                  justify-content: space-around;

                  &.none {
                    display: none;
                  }

                  >div {
                    padding: 20px 0;
                    // min-width: 120px;
                    text-align: center;
                    display: flex;
                    justify-content: center;
                  }

                  label {
                    display: block;
                    margin: 0 0 0 10px;
                    line-height: 25px;
                    font-size: 13px;
                    color: #696969;
                  }
                }
              }

              h3 {
                margin: 0 10px 0 0 !important;
                padding: 0;
                width: 70px;
                line-height: 25px;
                font-size: 13px;
                color: #fff;
                font-weight: 600;
                text-align: center;
                background: #ACDBA4;
                border-radius: 200px;
              }

              input {
                padding: 0 10px;
                width: 100%;
                height: 40px;
                border: 1px solid #ddd;
                border-radius: 5px;
              }
            }

            .edit_wrap {
              width: 100%;

              .editor {
                justify-content: center;
                display: flex;
              }

              div {
                textarea {
                  // padding: 10px 15px;
                  height: 45px;
                  resize: none;
                  width: 100%;
                  border: 1px solid #ddd;
                  border-radius: 5px;
                  font-size: 14px;
                  color: #666666;
                }
              }
            }

            .survey-remove {
              margin: 0 10px 0 10px;
              width: 20px;
            }

            .survey-option-item {
              // margin: 15px 0;
              flex-basis: 33%;

              &.none {
                display: block;
                // background-color: #ff000080;
                // border-radius: 20px;
              }

              .survey-option-body {
                display: -webkit-flex;
                display: flex;
                -webkit-align-items: center;
                align-items: center;
                padding: 5px 3px;


                .survey-table-item-title-wrap {
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                  height: 100%;
                  max-height: 20px;

                  .survey-table-item-title {
                    font-family: 'Pretendard';
                    font-style: normal;
                    font-weight: 500;
                    font-size: 13px;
                    line-height: 37px;
                    /* identical to box height, or 285% */

                    display: flex;
                    align-items: center;

                    /* 696969 */

                    color: #696969;

                    &.required {
                      &::after {
                        content: '*';
                        color: #55B87A !important;
                      }
                    }

                  }
                }



                .flex {
                  width: 100%;
                  height: auto;
                  justify-content: center;
                  padding: 15px;
                }

                >div {
                  width: 100%;

                  .survey-table-item-container {
                    &.none {
                      display: block;
                      // background-color: #ff000080;
                      // border-radius: 13px;

                      .flex.flexWrap {
                        background-color: #DDDDDD;

                      }
                    }
                  }
                }

                .survey-table-item-container {
                  background: white;

                  .flex {
                    background: #E7F0EA;
                    border-radius: 4px 4px 0px 0px;
                  }
                }
              }
            }
          }

          .survey__content-item-header {
            height: 55px;
            // background:#f6f8fb;
            padding: 0 20px 0 40px;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            background: url('#{$image-path}/admin/menu_on.svg') no-repeat 20px center;
            background-size: 10px;

            h2 {
              margin-right: auto;
              padding: 0;
              line-height: 2.5;
              font-size: 1rem;
              font-weight: 600;


            }

            button {
              width: 30px;
              height: 30px;
              // border: 1px solid #ddd;
              // background:#fff;
              // border-radius: 50%;
              margin-left: 10px;

              svg {
                vertical-align: middle;
                margin-top: -5px;
              }
            }
          }

          .survey__content-form {
            .survey__content-form-actions {
              display: flex;
              align-items: center;
              justify-content: space-between;
              padding: 5px 0;

              >div {
                display: flex;

                button {
                  height: 60px;
                  font-size: 15px;
                  width: 135px;
                  color: #999;
                  // border-bottom: 4px solid #999999;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                }

                .active {
                  color: #1A9952;
                  border-bottom: 3px solid #1A9952;
                }
              }



              >h3 {

                font-family: 'Pretendard';
                font-style: normal;
                font-weight: 500;
                font-size: 13px;
                line-height: 35px;
                /* identical to box height, or 269% */

                display: flex;
                align-items: center;

                color: #868686;

              }

            }

            .survey__content-lists {
              .survey__content-lists-btn-group {
                display: flex;
                gap: 10px;
                justify-content: center;
                align-items: center;
              }


              .surveyaddbtn {
                display: block;
                color: #fff;
                font-size: 14px;
                flex: none;
                white-space: nowrap;
                height: 40px;
                padding: 0 30px;
                border-radius: 4px;
                background: #00B852;
                margin: 0;
                margin-top: 20px;
              }

              .templeteaddbtn {
                display: block;
                color: #fff;
                font-size: 14px;
                flex: none;
                white-space: nowrap;
                height: 40px;
                padding: 0 30px;
                border-radius: 4px;
                background: #00863C;
                margin: 0;
                margin-top: 20px;
              }
            }

            .answer-button-box {
              display: flex;
              justify-content: flex-end;

              >button {
                display: flex;
                justify-content: center;
                align-items: center;
              }

              .btn {
                margin-left: 10px;
              }
            }

          }

          // 문항
          .survey__content-item {
            border-radius: 4px;

            .survey__content-item-header {
              background: #fff;

              label.checkbox {
                background: url('https://lms.gjudec.com/assets/images/active-arrow.png') no-repeat center;
                background-color: #696969;
                border-radius: 10px;
                height: 25px;
                width: 25px;
                cursor: pointer;
              }

              .checked {
                background-color: #00B852 !important;


              }


              h2 {
                position: relative;

                &.question {
                  // text-indent: 60px;

                  &::before {
                    content: '';
                    position: absolute;
                    left: 0;
                    top: 50%;
                    transform: translateY(-50%);
                  }

                  &.Mtch {
                    &::before {
                      content: '';
                      display: block;
                      height: 1.3em;
                      border: 3px solid #00B852;
                      /* border-radius: 50%; */
                      position: absolute;
                      left: -19px;
                      top: 20px;
                      // width: 63px;
                      // height: 22px;
                      // background: url('#{$image-path}/Group 33808.png') no-repeat center;
                    }
                  }

                  &.Plur {
                    &::before {
                      content: '';
                      display: block;
                      height: 1.3em;
                      border: 3px solid #00B852;
                      background-color: #00B852;
                      width: 3px;
                      /* border-radius: 50%; */
                      position: absolute;
                      left: -19px;
                      top: 20px;
                      // width: 75px;
                      // height: 22px;
                      // background: url('#{$image-path}/Group 33806.png') no-repeat center;
                    }
                  }

                  &.Short {
                    &::before {
                      content: '';
                      display: block;
                      height: 1.3em;
                      border: 3px solid #00B852;
                      /* border-radius: 50%; */
                      position: absolute;
                      left: -19px;
                      top: 20px;
                      background-color: #00B852;
                      width: 3px;
                      // width: 67px;
                      // height: 22px;
                      // background: url('#{$image-path}/Group 33807.png') no-repeat center;
                    }
                  }

                  &.table_tit {
                    &::before {
                      content: '';
                      display: block;
                      height: 1.3em;
                      border: 3px solid #00B852;
                      /* border-radius: 50%; */
                      position: absolute;
                      left: -19px;
                      top: 20px;
                      background-color: #00B852;
                      width: 3px;
                      // width: 67px;
                      // height: 22px;
                      // background: url('#{$image-path}/Group 33807.png') no-repeat center;
                    }
                  }

                  &.Mtch2 {
                    &::before {
                      content: '';
                      display: block;
                      height: 1.3em;
                      border: 3px solid #868686;
                      /* border-radius: 50%; */
                      position: absolute;
                      left: -19px;
                      top: 20px;
                      background-color: #868686;
                      width: 3px;
                      // width: 63px;
                      // height: 22px;
                      // background: url('#{$image-path}/Group 33808.png') no-repeat center;
                    }
                  }

                  &.Plur2 {
                    &::before {
                      content: '';
                      display: block;
                      height: 1.3em;
                      border: 3px solid #868686;
                      /* border-radius: 50%; */
                      position: absolute;
                      left: -19px;
                      top: 20px;
                      background-color: #868686;
                      width: 3px;
                      // width: 75px;
                      // height: 22px;
                      // background: url('#{$image-path}/Group 33806.png') no-repeat center;
                    }
                  }

                  &.Short2 {
                    &::before {
                      content: '';
                      display: block;
                      height: 1.3em;
                      border: 3px solid #868686;
                      /* border-radius: 50%; */
                      position: absolute;
                      left: -19px;
                      top: 20px;
                      background-color: #868686;
                      width: 3px;
                      // width: 67px;
                      // height: 22px;
                      // background: url('#{$image-path}/Group 33807.png') no-repeat center;
                    }
                  }

                  &.table_tit2 {
                    &::before {
                      content: '';
                      display: block;
                      height: 1.3em;
                      border: 3px solid #868686;
                      /* border-radius: 50%; */
                      position: absolute;
                      left: -19px;
                      top: 20px;
                      background-color: #868686;
                      width: 3px;
                      // width: 67px;
                      // height: 22px;
                      // background: url('#{$image-path}/Group 33807.png') no-repeat center;
                    }
                  }
                }
              }

              >button {
                border-radius: 0;
                border: 0;
              }
            }

            .surveyitem {
              border-top: 1px dashed #ddd;

              &.displaynone {
                border-top: 0;
              }
            }
          }

          .survey__content-lists {
            .surveyaddbtn {
              display: block;
              color: #fff;
              font-size: 14px;
              flex: none;
              white-space: nowrap;
              height: 40px;
              padding: 0 30px;
              border-radius: 3px;
              background: #55B87A;
              margin: 0 auto;
              margin-top: 20px;
            }
          }
        }
      }

      // 시험 취소추가 버튼
      .answer-button-box {
        .btn {
          background: #ACDBA4;
          border: 1px solid #ACDBA4;
          border-radius: 3px;
          color: #5C8D72;
          min-width: 120px;
          height: 40px;
          font-size: 0.875rem;


        }
      }
    }
  }


}



//미리보기
.preview {

  //결과보기
  .modal_full_body {
    padding: 1rem 1rem;



    .modal_sub_title {
      display: flex;

      h4 {
        margin-right: 20px;
      }
    }

    .modal_body {
      padding: 0 !important;

      .tab {
        display: flex;
        margin-bottom: 35px;

        .tab_btn {
          display: flex;
          margin-left: 15px;
          margin-top: 28px;
          padding: 0 20px;
          height: 45px;
          font-size: 16px;
          border: #bdbdbd 1px solid;
          border-radius: 6px;
          -webkit-align-items: center;
          align-items: center;
          -webkit-justify-content: center;
          justify-content: center;
          cursor: pointer;

          &:first-child {
            margin-left: 0;
          }
        }

        .excel_btn {
          margin-left: auto;
        }


        .active {
          background: #00863C;
          border-radius: 4px;
          color: white;
          border: none
        }

        .inactive {
          background: #ffffff;
          border-radius: 4px;
          color: #949494;
          border: none
        }

      }

      input {

        &#filter-text-box {
          width: 100%;
        }
      }

      .result_outer {
        display: flex;
        border: 1px solid #ddd;
        border-radius: 6px;
        overflow: hidden;

        #question_survey {
          width: 30%
        }

        .three_tab#question_survey {
          width: 80% !important;
          border-right: none;

          &+.result_table.survey_display {
            width: 20% !important;

          }



          >thead {
            >tr {
              text-align: left;

              >th {
                padding-left: 40px;
              }
            }
          }


          >tbody {
            >tr {
              text-align: left;

              >td {
                padding-left: 40px;

              }
            }
          }
        }

        #user_stu_info {
          width: 30%;
        }

        #user_stu_info,
        #question_survey {
          border-right: 1px solid #ddd;

          // >thead {
          //   font-family: 'Pretendard';
          //   font-style: normal;
          //   font-weight: 400;
          //   font-size: 15px;
          //   line-height: 14px;
          //   /* or 93% */


          //   /* 기본색 333 */

          //   color: #333333;

          //   >tbody {
          //     >tr {
          //       td {
          //         font-family: 'Pretendard';
          //         font-style: normal;
          //         font-weight: 400;
          //         font-size: 15px;
          //         line-height: 25px;
          //         /* identical to box height, or 167% */


          //         color: #696969;
          //       }
          //     }
          //   }
          //}
        }

        // .survey_display {
        //   white-space: nowrap;
        //   overflow: auto;
        //   width: 70%;

        //   #user_survey_info {
        //     width: 100%;
        //   }

        //   &::-webkit-scrollbar {
        //     height: 12px;
        //   }

        //   &::-webkit-scrollbar-thumb {
        //     background-color: #c8cbdb;
        //   }

        //   &::-webkit-scrollbar-track {
        //     background-color: #f2f3f7;
        //   }

        //   .student_list {
        //     border-left: none !important;

        //     .question {
        //       width: 15rem !important;
        //     }

        //     .question_count {
        //       width: 7rem !important;
        //     }
        //   }

        //   .stu_content_title {
        //     padding: 10px;
        //     border-bottom: 1px solid #ddd;

        //     font-family: 'Pretendard';
        //     font-style: normal;
        //     font-weight: 400;
        //     font-size: 15px;
        //     line-height: 14px;
        //     /* or 93% */


        //     /* 기본색 333 */
        //     display: flex;
        //     justify-content: center;
        //     align-items: center;
        //     color: #333333;
        //     width: auto !important;
        //     height: 55px;
        //     line-height: 55px;
        //     // border-left: 1px solid #ddd;

        //     // &:first-child {
        //     //   border-left: 0;
        //     // }
        //     // &:first-child {
        //     //   max-width: 100px;
        //     // }
        //   }

        //   .stu_content {
        //     padding: 10px;

        //     width: 30rem !important;
        //     // border-left: 1px solid #ddd;
        //     display: flex;
        //     justify-content: center;
        //     align-items: center;
        //     // &:first-child {
        //     //   border-left: 0;
        //     // }
        //     font-family: 'Pretendard';
        //     font-style: normal;
        //     font-weight: 400;
        //     font-size: 15px;
        //     line-height: 25px;
        //     /* identical to box height, or 167% */


        //     color: #696969;

        //     // &:first-child {
        //     //   max-width: 100px;
        //     // }
        //   }

        //   .notSurvey {
        //     width: 100% !important;
        //     justify-content: flex-start !important;
        //   }
        // }

        // .result_table {

        //   .student_list {
        //     display: flex;
        //     // border-left: 1px solid black;

        //     &:nth-child(even) {
        //       background: #F6F8FB;
        //     }

        //     .listIdx {
        //       width: 4.5rem !important;
        //     }

        //     .stu_content_title {
        //       padding: 10px;

        //       border-bottom: 1px solid #ddd;

        //       font-family: 'Pretendard';
        //       font-style: normal;
        //       font-weight: 400;
        //       font-size: 15px;
        //       line-height: 14px;
        //       /* or 93% */
        //       display: flex;
        //       justify-content: center;
        //       align-items: center;

        //       /* 기본색 333 */

        //       color: #333333;
        //       width: auto !important;
        //       height: 55px;
        //       line-height: 55px;
        //       // border-left: 1px solid #ddd;

        //       // &:first-child {
        //       //   max-width: 100px;
        //       // }
        //     }

        //     .stu_content {
        //       padding: 10px;

        //       width: auto !important;
        //       // border-left: 1px solid #ddd;
        //       display: flex;
        //       justify-content: center;
        //       align-items: center;
        //       // &:first-child {
        //       //   border-left: 0;
        //       // }
        //       font-family: 'Pretendard';
        //       font-style: normal;
        //       font-weight: 400;
        //       font-size: 15px;
        //       line-height: 25px;
        //       /* identical to box height, or 167% */


        //       color: #696969;

        //       // &:first-child {
        //       //   max-width: 100px;
        //       // }
        //     }

        //     .question {
        //       width: 30rem;
        //     }

        //     .q-title {
        //       justify-content: flex-start !important;
        //     }
        //   }

        //   .result_table_header {
        //     // margin-top: 20px;
        //   }

        //   tbody>tr:hover {
        //     background-color: #E0E9F7 !important;
        //     color: #0471B6 !important;
        //   }
        // }

        .result_table {
          &.survey_display {
            width: 70%;

          }

          >thead {
            >tr {
              // display: flex;
              text-align: center;
              border-bottom: 1px solid #ddd;

              >th {
                padding: 15px 0;
                font-family: 'Pretendard';
                font-style: normal;
                font-weight: 400;
                font-size: 15px;
                line-height: 14px;
                /* or 93% */


                /* 기본색 333 */

                color: #333333;

                &:nth-child(1) {
                  width: 20%;
                }

                &:nth-child(2) {
                  width: 30%;

                }

                &:nth-child(3) {
                  width: 50%;

                }
              }
            }
          }

          >tbody {
            >tr {
              text-align: center;

              &:nth-child(odd) {
                background: #F8F8F8;
              }

              td {
                font-family: 'Pretendard';
                font-style: normal;
                font-weight: 400;
                font-size: 15px;
                line-height: 40px;
                height: 40px;
                /* identical to box height, or 93% */


                color: #696969;

                &:nth-child(1) {
                  width: 20%;
                }

                &:nth-child(2) {
                  width: 30%;

                }

                &:nth-child(3) {
                  width: 50%;

                }
              }
            }
          }
        }




        #user_survey_info,
        #question_summary_survey {

          //왼쪽 하단 테이블 정보
          >thead {
            >tr {
              // display: flex;
              text-align: center;
              border-bottom: 1px solid #ddd;

              >th {
                padding: 15px 0;
                font-family: 'Pretendard';
                font-style: normal;
                font-weight: 400;
                font-size: 15px;
                line-height: 14px;
                /* or 93% */


                /* 기본색 333 */

                color: #333333;
                min-width: 200px;
                width: auto;
                // &:nth-child(1) {
                //   width: 20%;
                // }

                // &:nth-child(2) {
                //   width: 30%;

                // }

                // &:nth-child(3) {
                //   width: 50%;

                // }
              }
            }
          }

          >tbody {
            >tr {
              text-align: center;

              &:nth-child(odd) {
                background: #F8F8F8;
              }

              td {
                font-family: 'Pretendard';
                font-style: normal;
                font-weight: 400;
                font-size: 15px;
                line-height: 40px;
                height: 40px;
                /* identical to box height, or 93% */


                color: #696969;

                // &:nth-child(1) {
                //   width: 20%;
                // }

                // &:nth-child(2) {
                //   width: 30%;

                // }

                // &:nth-child(3) {
                //   width: 50%;

                // }
              }
            }
          }
        }

      }
    }
  }

  .btn_container {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    >button {
      background: #00863C;
      border-radius: 4px;
      padding: 15px 30px;
      color: white;

    }
  }

  .survey_question {
    padding: 0 30px 15px;
    border: 1px solid #ddd;
    border-radius: 4px;
    margin: 20px 0;





    .surveyDescription {
      >.quill {
        >.ql-toolbar.ql-snow {
          display: none;
        }

        >.ql-container {
          border: none !important;
          height: auto;
          background: #F3F3F3;
          border-radius: 4px;
          font-family: 'Pretendard';
          font-style: normal;
          font-weight: 400;
          font-size: 15px;
          line-height: 14px;
          margin: 0px 0 10px 0;
          color: #696969;
        }
      }
    }




    .survey_popup_title {
      font-family: 'Pretendard';
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 20px;
      /* identical to box height, or 111% */


      /* 기본색 333 */

      color: #333333;
      padding: 20px;


    }


    .survey_popup_date,
    .survey_popup_user {
      display: flex;
      font-family: 'Pretendard';
      font-style: normal;
      font-weight: 400;
      font-size: 15px;
      line-height: 18px;
      padding: 5px;

      /* identical to box height */
      &>span:nth-child(1) {
        font-family: 'Pretendard';
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 14px;
        flex-basis: 8%;
        /* or 93% */

        color: #333333;
      }

      &>span:nth-last-child(1) {
        font-family: 'Pretendard';
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 14px;
        flex-basis: 10%;
        /* or 93% */

        color: #696969;
      }

    }



    .ta_div {
      .evaluate_table {
        background: #FFFFFF;
        border: 1px solid #DDDDDD;
        border-radius: 4px;

        .th_header {
          width: 60px;
          border: none;
          border-bottom: 1px solid #ddd;
          font-family: 'Pretendard';
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 14px;
          /* identical to box height, or 88% */


          /* 기본색 333 */

          color: #333333;
          background: none !important;
        }

        tbody {


          tr {
            &:nth-child(odd) {
              >td {
                background: #F8F8F8 !important;

              }
            }

            td {
              padding: 0 !important;

              @media screen and (max-width: 768px) {
                font-size: 10px;
              }

              background-color: #fff !important;
              vertical-align: middle;
              text-align: center;
              border: none;
              font-size: 16px;
              padding: 10px;
              font-family: 'Pretendard';
              font-style: normal;
              font-weight: 400;
              font-size: 15px;
              line-height: 40px;
              /* or 300% */


              /* 696969 */

              color: #696969;
            }

            .question_list_title {
              text-align: unset !important;
            }
          }
        }
      }
    }



    .boardDetail__content {
      background: #F3F3F3;
      border-radius: 4px;
      font-family: 'Pretendard';
      font-style: normal;
      font-weight: 400;
      font-size: 15px;
      line-height: 14px;
      /* identical to box height, or 93% */
      margin: 0px 0 10px 0;

      color: #696969;


      >.quill {
        >.ql-container {
          height: auto !important;
          border: none !important;

          >.ql-editor {
            >p {
              font-family: 'Pretendard';
              font-style: normal;
              font-weight: 400;
              font-size: 15px !important;
              line-height: 14px;
              color: #696969 !important;
              padding: 20px !important;

            }
          }
        }
      }



      input[type=text] {
        width: 100%;
        border: 1px solid #e5e5e5;
        height: 130px;
      }
    }

    h1 {
      font-family: 'Pretendard';
      font-style: normal;
      font-weight: 500 !important;
      font-size: 16px !important;
      line-height: 14px !important;
      color: #333333 !important;
      position: relative !important;
      padding: 30px 20px 20px 30px;

      &::before {
        content: '';
        display: block;
        height: 1.3em;
        border: 3px solid #00B852;
        /* border-radius: 50%; */
        position: absolute;
        left: 16px;
        top: 26px;
        width: 3px;
        background: #00B852;
      }
    }

    label {
      vertical-align: middle;
      padding: 0 0 15px 10px;
      font-family: 'Pretendard';
      font-style: normal;
      font-weight: 400;
      font-size: 15px;
      line-height: 35px;
      /* or 233% */

      display: flex;
      align-items: center;

      color: #696969;

      input[type=radio] {
        background-color: #ececec;
        border: 7px solid #ececec;
        width: 30px;
        height: 30px;
        display: inline-block;
        vertical-align: middle;
        margin-right: 15px;
        appearance: none;
        border-radius: 50px;

        &:checked {
          border: 7px solid #00B852;
          width: 30px;
          height: 30px;
        }
      }

      input[type=checkbox] {
        background-color: #ececec;
        border: 5px solid #ececec;
        width: 20px;
        height: 20px;
        display: inline-block;
        vertical-align: middle;
        margin-right: 15px;
        appearance: none;

        &:checked {
          border: 7px solid #00B852;
          width: 20px;
          height: 20px;
        }
      }
    }

    .evaluate_table {

      input[type=radio] {
        background-color: #ececec;
        border: 7px solid #ececec;
        width: 20px;
        height: 20px;
        display: inline-block;
        vertical-align: middle;
        appearance: none;
        border-radius: 50px;

        &:checked {
          border: 5px solid #00B852;
          width: 20px;
          height: 20px;
        }
      }
    }
  }
}


// .design {
//   @for $i from 0 to 50 {

//     .label_wrapper_row_#{$i} {
//       &>.label_wrapper_td_#{$i} {
//         display: flex;
//         justify-content: space-around;

//         &>p {
//           padding: 0 !important;
//           border: none !important;
//           margin: 0 !important;
//           display: flex;
//           justify-content: center;
//           align-items: center;

//           [type="radio"]:checked,
//           [type="radio"]:not(:checked) {
//             position: absolute;
//             left: -9999px;
//           }

//           [type="radio"]:checked+label,
//           [type="radio"]:not(:checked)+label {
//             position: relative;
//             padding-left: 20px;
//             cursor: pointer;
//             line-height: 20px;
//             display: inline-block;
//             color: #666;
//           }

//           [type="radio"]:checked+label:before,
//           [type="radio"]:not(:checked)+label:before {
//             content: '';
//             position: absolute;
//             left: 0;
//             top: 0;
//             width: 18px;
//             height: 18px;
//             border: 2px solid #ddd;
//             border-radius: 100%;
//             background: #fff;
//           }

//           [type="radio"]:checked+label:before {
//             border: 2px solid #00B852;
//             border-radius: 100%;
//           }

//           [type="radio"]:checked+label:after,
//           [type="radio"]:not(:checked)+label:after {
//             content: '';
//             width: 18px;
//             height: 18px;
//             border: 5px solid #00B852;
//             position: absolute;
//             top: 0px;
//             left: 0px;
//             border-radius: 100%;
//             -webkit-transition: all 0.2s ease;
//             transition: all 0.2s ease;
//           }

//           [type="radio"]:not(:checked)+label:after {
//             opacity: 0;
//             -webkit-transform: scale(0);
//             transform: scale(0);
//           }

//           [type="radio"]:checked+label:after {
//             opacity: 1;
//             -webkit-transform: scale(1);
//             transform: scale(1);
//           }
//         }
//       }
//     }
//   }
// }

// .development {
//   @for $i from 0 to 50 {

//     .label_wrapper_row_#{$i} {
//       &>.label_wrapper_td_#{$i} {
//         display: flex;
//         justify-content: space-around;

//         &>p {
//           padding: 0 !important;
//           border: none !important;
//           margin: 0 !important;
//           display: flex;
//           justify-content: center;
//           align-items: center;

//           [type="radio"]:checked,
//           [type="radio"]:not(:checked) {
//             position: absolute;
//             left: -9999px;
//           }

//           [type="radio"]:checked+label,
//           [type="radio"]:not(:checked)+label {
//             position: relative;
//             padding-left: 20px;
//             cursor: pointer;
//             line-height: 20px;
//             display: inline-block;
//             color: #666;
//           }

//           [type="radio"]:checked+label:before,
//           [type="radio"]:not(:checked)+label:before {
//             content: '';
//             position: absolute;
//             left: 0;
//             top: 0;
//             width: 18px;
//             height: 18px;
//             border: 2px solid #ddd;
//             border-radius: 100%;
//             background: #fff;
//           }

//           [type="radio"]:checked+label:before {
//             border: 2px solid #00B852;
//             border-radius: 100%;
//           }

//           [type="radio"]:checked+label:after,
//           [type="radio"]:not(:checked)+label:after {
//             content: '';
//             width: 18px;
//             height: 18px;
//             border: 5px solid #00B852;
//             position: absolute;
//             top: 0px;
//             left: 0px;
//             border-radius: 100%;
//             -webkit-transition: all 0.2s ease;
//             transition: all 0.2s ease;
//           }

//           [type="radio"]:not(:checked)+label:after {
//             opacity: 0;
//             -webkit-transform: scale(0);
//             transform: scale(0);
//           }

//           [type="radio"]:checked+label:after {
//             opacity: 1;
//             -webkit-transform: scale(1);
//             transform: scale(1);
//           }
//         }
//       }
//     }
//   }
// }


.label_wrapper_td {
  display: flex;
  justify-content: center;
  align-items: center;

  &>p {
    padding: 0 !important;
    border: none !important;
    margin: 0 !important;
    display: flex;
    justify-content: center;
    align-items: center;

    input {
      pointer-events: none;
    }

    label {
      pointer-events: none;
    }

    [type="radio"]:checked,
    [type="radio"]:not(:checked) {
      position: absolute;
      left: -9999px;
    }

    [type="radio"]:checked+label,
    [type="radio"]:not(:checked)+label {
      position: relative;
      padding-left: 20px;
      cursor: pointer;
      line-height: 20px;
      display: inline-block;
      color: #666;
    }

    [type="radio"]:checked+label:before,
    [type="radio"]:not(:checked)+label:before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 18px;
      height: 18px;
      border: 2px solid #ddd;
      border-radius: 100%;
      background: #fff;
    }

    [type="radio"]:checked+label:before {
      border: 2px solid #00B852;
      border-radius: 100%;
    }

    [type="radio"]:checked+label:after,
    [type="radio"]:not(:checked)+label:after {
      content: '';
      width: 18px;
      height: 18px;
      border: 5px solid #00B852;
      position: absolute;
      top: 0px;
      left: 0px;
      border-radius: 100%;
      -webkit-transition: all 0.2s ease;
      transition: all 0.2s ease;
    }

    [type="radio"]:not(:checked)+label:after {
      opacity: 0;
      -webkit-transform: scale(0);
      transform: scale(0);
    }

    [type="radio"]:checked+label:after {
      opacity: 1;
      -webkit-transform: scale(1);
      transform: scale(1);
    }
  }
}


.preview_video_content {

  max-width: 700px !important;
  height: fit-content !important;

  .modal_header {

    .modal_title {
      margin-left: 20px !important;
      padding-left: 0px !important;

      &::after {
        background: url('#{$image-path}/preview_video_title.svg') no-repeat 0;
        background-size: contain;
        width: 20px !important;
        height: 20px !important;
        left: -30px !important;
        background-color: transparent !important;
        border: none !important;
      }
    }
  }

  .preview_video {
    width: 100%;
    height: 485px;
    word-wrap: break-word;
    border-radius: 11px;
    color: #fff;
    font-size: 0.9rem;
    display: flex;
    align-items: center;
    justify-content: center;

    @media screen and (max-width: 768px) {
      height: 315px;
      width: 100%;
    }

    >iframe {
      width: 100%;
      height: 100%;
    }
  }

  .description_box {
    padding: 28px;
  }
}


.editKeyword{
  display: inline-block;
  padding: 5px 10px;
  margin-left: 7px;
  line-height: 0.85rem;
  border-radius: 100px;
  background-color: #ff8a0d;
  color: #fff;
  font-size: 0.9rem;
  font-weight: 700;
  cursor:pointer;
}