.footer {
  background: $theme-black-6;

  .footlogo {
    @media screen and (max-width: 1220px) {
      height: 40px;
    }
    @media screen and (max-width: 768px) {
      height: 30px;
    }
  }
}

.footer__top {
  border-bottom: 1px solid $theme-black-3;
  .wrap {
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    @media screen and (max-width: 1220px) {
    }
    @media screen and (max-width: 768px) {
      height: auto;
      min-height: 60px;
      padding: 10px 0;
    }
  }

  a {
    color: $white;
    font-size: 1rem;
    padding: 0 1em;
    line-height: 1em;
    & ~ a {
      border-left: 1px solid $white;
    }
    @media screen and (max-width: 1220px) {
    }
    @media screen and (max-width: 768px) {
      margin: 0.5em 0;
    }
  }
}

.footer__middle {
  padding: 2.5em 0;
  .wrap {
    text-align: center;
  }
  p {
    font-size: 1rem;
    line-height: 2em;
    margin-top: 1em;
    color: $white;
  }
}

.footer__bottom {
  background: $theme-black-7;
  padding: 2em;
  .wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
  }

  a {
    width: 50px;
    flex: none;
    & ~ a {
      margin-left: 8px;
    }
    @media screen and (max-width: 768px) {
      width: 40px;
    }
  }
}
