.quick {
  position: fixed;
  //left: -180px;
  right: 0px;
  top: 17vh;
  width: 80px;
  height: 384px;
  z-index: 998;
  background: $white;
  box-shadow: 5px 10px 10px rgba(0, 0, 0, 0.03);
  transition: all 0.5s;
  margin-right: 30px;

  .quick__content {
    border-radius: 4px;
    overflow: hidden;
  }


  &.active {
    //left: 0;
    right: 0;

    .quick__toggle {
      img {
        margin-top: 6px;
        transform: none;
      }
    }
  }

  @media screen and (max-width: 1470px) {
    display: none !important;
  }

  .quick__toggle {
    position: absolute;
    //right: -50px;
    left: -50px;
    top: 50%;
    transform: translateY(-50%);
    width: 50px;
    height: 50px;
    background: $theme-black-4;

    img {
      margin-top: 6px;
      transform: rotate(45deg);
    }
  }

  /* .quick__header {
     background: #1a9952;
     //background: $theme-blue-1;
     color: $white;
     text-align: center;
     font-size: 17px;
     padding: 25px 0;
     line-height: 26px;
     img {
       height: 50px;
     }
   }*/

  .quick__menu {
    line-height: 50px;
    text-align: center;
    background: #337842;
    //background: $theme-blue-2;
    color: $white;
  }

  .quick__body {
    padding: 15px 5px;
    border-bottom: 0;
    background-color: white;
    border: 1px solid #eeeeee;

    a {
      height: 66px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      color: $theme-black-2;
      white-space: nowrap;
      font-weight: 600;

      &~a {
        margin-top: 10px;
      }

      .quick_img {
        text-align: center;
        display: flex;
        gap: 3px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }

      span {
        width: 45px;
        height: 20px;
        // margin-right: -1px;
        font-size: 12px;
        //border: 1px solid $theme-gray-1;
        border-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        // margin-left: -1px;
        color: #575757;
        letter-spacing: -1px;
      }
    }

    .quick_hover:hover {
      .quick_txt {
        color: #489659;
      }
    }
  }

  .quick__foot {
    padding: 14px;
    text-align: center;
    background-color: #3e3e3e;
    height: 57px;

    img {
      display: block;
      margin: 0 auto 0;
    }

    span {
      font-size: 14px;
      color: white;
    }

    /*h2 {
      margin-top: 5px;
      font-size: 17px;
    }

    h3 {
      margin-top: 3px;
      font-size: 15px;
    }

    h4 {
      margin-top: 1em;
      font-size: 13px;
    }

    p {
      margin-top: 5px;
      font-size: 12px;
      line-height: 18px;
      color: $theme-black-3;
    }*/
  }
}