.size-1-1 {
  padding-top: 100%;
  width: 100%;
  max-width: 100%;
  height: 0;
  position: relative;
  overflow: hidden;

  img, iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }
}
.equipmentInfo {
  .equipmentInfo__content {
    margin-top: 1.5em;
  }

  .slick-dots {
    margin-top: 2em;
    position: static;
    width: auto;
    height: 6px;

    li {
      margin: 0;
      width: auto;
      padding: 0 4px;
      height: auto;
      vertical-align: top;

      &.slick-active button {
        background: $theme-black-4;
      }
    }
    button {
      transition: all 0.5s;
      background: $theme-gray-2;
      width: 36px;
      height: 6px;
      padding: 0;
    }
  }
  .slick-dots li button:before {
    display: none;
  }
}

.equipmentInfo__slide {
  .slidebox {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -20px;
    @media screen and (max-width: 1220px) {
      margin: 0 -10px;
    }
    @media screen and (max-width: 768px) {
      margin: 0 -5px;
    }
    @media screen and (max-width: 500px) {
      margin: 0px;
    }
  }
  .item {
    margin: 0 20px;
    position: relative;
    width: calc(25% - 40px);
    &:nth-child(n + 5) {
      margin-top: 40px;
    }
    @media screen and (max-width: 1220px) {
      margin: 0 10px;
      width: calc(33.33% - 20px);
      &:nth-child(n + 4) {
        margin-top: 20px;
      }
    }
    @media screen and (max-width: 768px) {
      margin: 0 5px;
      width: calc(50% - 10px);
      &:nth-child(n + 3) {
        margin-top: 40px;
      }
    }
    @media screen and (max-width: 500px) {
      margin: 0;
      margin-top: 10px;
      width: 100%;
    }
    a {
      color: $theme-black-2;
      display: block;

      img {
        width: 100%;
      }
    }
    .status {
      position: absolute;
      left: 0;
      top: 0;
      font-size: 1rem;
      line-height: 40px;
      white-space: nowrap;
      padding: 0 1em;
      color: $white;
      background: $theme-red;

      &.disabled {
        background: $theme-gray-9;
      }
    }
    .info {
      min-height: 100px;
      text-align: center;
      border: 1px solid $theme-gray-1;
      padding: 1em;
    }

    .download{
      background: #e72e35;
      color: #fff;
      border-radius: 2em;
      text-align: center;
      font-size: 0.875rem;
      padding: 2px 15px;
    }
    h3 {
      //text-overflow: ellipsis;
      //overflow: hidden;
      //white-space: nowrap;
      font-size: 1.1rem;
      //font-size: 1.375rem;
      font-weight: 400;
    }
    p {
      font-size: 1rem;
      color: $theme-black-3;
      margin-top: 10px;
    }
  }
}
