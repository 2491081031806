.page-home-cloudlab {
  .title {
    line-height: 70px;
    color: #000;
    font-size: 22px;
    font-weight: 700;
    padding-left: 10px;
    margin-top: 25px;
    margin-bottom: 5px;
  }

  .lab__filter {
    background: #fff;
    border-radius: 5px;
    padding: 30px;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    -webkit-align-items: center;
    align-items: center;
  }

  .row-md-col {
    margin-bottom: 1.5rem;
    width: 100%;

    &.virTops {
      margin-left: 0;

      .col {
        padding-left: 0;
        padding-right: 0;
      }
    }
  }

  ///////// 검색
  .select-count {
    margin-top: 1px;
    width: 125px;
    margin-right: 10px;
  }

  select {
    appearance: auto;
  }

  .form-control {
    display: block;
    width: 100%;
    //height: calc(1.5em + 0.75rem + -1px);
    height: 35px;
    //padding: 0.375rem 0.75rem;
    padding: 0;
    padding-left: 10px;
    font-size: 0.9rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }

  .searchform button {
    margin-top: 1px;
    width: 65px;
    background: #0079c2;
    color: #fff;
    font-size: 1rem;
    height: 35px;
    flex: none;
  }

  ////////////

  .searchform input {
    border-right: 0px;
    border: 1px solid #ced4da;
    width: 260px;
    flex: 1;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    height: 35px;
  }

  .action-buttons {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .action-buttons .btn-save,
  .action-buttons .btn-normal {
    height: 35px;
    line-height: 35px;
    padding: 0 15px;
    color: #fff;
    background: #172344;
    border-radius: 3px;
    font-size: 14px;
    margin-left: 5px;
  }


  .board_header {
    position: relative;

    .boardTap {
      float: left;
      display: flex;
      position: relative;
    }

    .tabbutton {
      padding: 10px 18px;
      background: #dfe3ea;
      border: 1px solid #b8c6d4;
      border-bottom: none;
      cursor: pointer;

      &.active {
        background: #fff;

        .inner_board {
          border-top: none;
        }
      }
    }
  }

  .ptable {
    overflow-x: visible;

    .table {
      width: 100%;
      position: relative;
    }

    th {
      text-align: center;
    }

    td {
      text-align: center;

      .titleArea {
        text-align: left;
        font-weight: bold;
        margin-bottom: 0;
      }

      .urlLinkButton {
        padding: 6px 7px;
        border: 1px solid #d64316;
        color: #d64316;
        font-size: 0.9rem;
        border-radius: 5px;

        &:hover {
          background-color: #d64316;
          color: #fff;
        }

        &:hover .oragneArrow {
          background: url('#{$image-path}/labArrow_wh.png') no-repeat;
        }

        .oragneArrow {
          display: inline-block;
          margin-left: 4px;
          width: 10px;
          height: 11px;
          background: url('#{$image-path}/labArrow.png') no-repeat;
        }
      }

    }

  }



  .inner_board {
    border-top: 1px solid #b8c6d4;
    background-color: #fff;
    border-radius: 3px;
    box-shadow: 5px 5px 5px #00000010;
    padding: 5px 30px 30px 30px;
    position: relative;
    display: inline-block;
    width: 100%;


  }

  .eachaction {
    display: flex;
    align-items: center;
    margin-top: 25px;
    margin-bottom: 10px;
  }

  .total {
    margin-right: 10px;
    color: #595757;
    font-size: 14px;
    letter-spacing: -0.025em;
    line-height: 35px;
  }

  .action-buttons-white .btn.btn-rm {
    //background: url(/assets/images/icon-trash.png) no-repeat calc(100% - 10px) center;
    padding-right: 27px;
  }

  .action-buttons-white .btn.btn-rf {
    //background: url(/assets/images/icon-refresh.png) no-repeat calc(100% - 10px) center;
    padding-right: 30px;
  }


  .tt-btn {
    position: relative;

    &:hover .btnModal {
      z-index: 100;
      opacity: 1;
      top: -66px;
    }

    .btn_gephi1 {
      background: url('#{$image-path}/icon_interface.png') no-repeat calc(100% - 5px) center;
    }

    .btn_gephi2 {
      background: url('#{$image-path}/icon_console.png') no-repeat calc(100% - 5px) center;
    }

    .btnModal {
      position: absolute;
      display: flex;
      vertical-align: middle;
      border: 1px solid #ddd;
      background: #fff;
      top: -73px;
      transition: 0.3s ease;
      left: 50%;
      transform: translateX(-50%);
      opacity: 0;
      box-shadow: 0 0 14px rgba(0, 0, 0, 0.2);
      z-index: -1;
      border: 1px solid #fff;


      .blockLink {
        width: 50%;
        text-align: center;
        color: #000;
        line-height: 1;
        padding: 10px;
        min-width: 86px;

        a {
          display: block;

          img {
            display: block;
            margin: 0 auto;
            margin-bottom: 5px;
          }

          color:#000;
          word-break: break-all;
          font-size: 0.8rem;
          white-space: break-spaces;

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }


  .use_img_div {
    display: inline-block;
    text-align: center;

    .pytronName {
      font-size: 0.8rem;
      margin-bottom: 0 !important;
    }
  }

  .use_img {
    margin: 0 5px;
    text-align: -webkit-center;
  }

  .img_plus {
    display: inline-block;
    margin: 0 10px;
    position: relative;
    bottom: 20px;
  }

  .manage_container {
    display: inline-block;
    width: 30%;
    text-align: center;

    .modify_img {
      padding-bottom: 20px;
    }

    img {
      display: block;
      margin: auto;
    }
  }

  .modify_btn {
    display: block;
    margin: 0 auto;
  }


  .computation-popup {
    .modal-body {
      padding: 10px 15px;
    }

    .form-container {
      border: 1px solid #ced4da;
      border-radius: 0.25rem;

      .img_select_header {
        border-bottom: 1px solid #ced4da;
      }
    }

    .select_table {
      width: 100%;

      th,
      td {
        padding: 7px 13px 4px 11px;
        text-align: center;
        font-size: 0.9rem;

        .dockerListBox {
          display: flex;
          align-items: center;
        }
      }

      tr {
        border-bottom: 0.5px solid #f5f6f8;
      }

      .stable_th {
        background: #f5f6f8;
      }
    }

    .resource_container {
      border: 1px solid #ced4da;
      border-radius: 3px;
      padding: 0 11px;

      .resource_div {
        margin: 17px 0;
        display: flex;
        align-items: center;
      }

      .resource_title {
        display: inline-block;
        width: 40px;
        margin-right: 16px;
        font-size: 0.9rem;
      }

      .range_div {
        display: inline-block;
      }

      .number_div {
        display: flex;
        margin-left: 6px;
        font-size: 0.8rem;

        .cpuValue {
          margin-right: 3px;
          color: #dc3545;
          font-weight: bold
        }
      }

      .form-number {
        width: 25px;
        border-bottom: 1px solid #dddddd;
      }

      .form-range {
        display: inline-block;
        -webkit-appearance: none;
        background: #dddddd;
        outline: none;
        width: 90px;
        height: 10px;
        overflow: hidden;

        &::-webkit-slider-thumb {
          -webkit-appearance: none;
          width: 10px;
          height: 10px;
          border-radius: 50%;
          background: #dc3545;
          cursor: pointer;
          border: 1px solid #fff;
          box-shadow: -407px 0 0 400px #dc3545;

        }

        :focus {
          outline: none;
        }

        &::-webkit-slider-runnable-track {
          width: 100%;
          height: 8px;
          cursor: pointer;
          box-shadow: none;
          background: #dddddd;
          border-radius: 0px;
          border: 0px solid #010101;
        }

      }
    }
  }
}


//실습실 성능 아이콘 재배치
.ptable td ul {
  width: 100%;
  margin: 0 auto;
  max-width: 128px;

  &:after {
    display: block;
    visibility: hidden;
    clear: both;
    content: '';
  }

  .sysli {
    float: left;
    width: 50%;
    max-width: 62px;
    margin-bottom: 0;
  }
}

.modal-footer {
  justify-content: center;

  .btn-normal {
    margin-left: 0 !important;
  }
}


.btn-gephi2 {
  margin-right: 2px;
}


.page_num {
  clear: left;
  position: relative;
  width: 100%;
  margin-top: 28px;
  text-align: center;
  padding-right: 23px;
  display: flex;
  align-items: center;
  justify-content: center;

  .page_button_position {


    .page_num_button {
      display: inline-block;
      margin-left: 7px;
      font-weight: 700;
      width: 27px;
      cursor: pointer;

      div {
        display: block;
        width: 100%;
        height: 100%;
        color: #000;
      }

      &:nth-child(1) {
        margin-left: 0;
      }
    }

    .pagination {
      justify-content: space-between;
      width: 100%;
      max-width: 400px;

      .page-item {
        margin: 0 2px;

        a {
          line-height: 35px;
          display: block;
          border: none;
          width: 35px;
          height: 35px;
          padding: 0;
          text-align: center;
          border-radius: 50%;
          overflow: hidden;
          color: $theme-black-2;

          &.active {
            color: $white;
            background: #00863C !important;
          }
        }
      }

      .page-item--arrow {
        a {
          // background: $theme-gray-8;
        }
      }
    }
  }

  .page_active {
    padding: 3px;
    border: 1px solid #000;
  }

}

.popup-content {
  max-width: 274px !important;

  .computation-popup {

    .btn-danger {
      font-size: 0.9rem;
    }

    .modal-body {
      padding: 10px 15px;
    }

    .form-container {
      border: 1px solid #ced4da;
      border-radius: 0.25rem;

      .img_select_header {
        border-bottom: 1px solid #ced4da;
      }
    }

    .select_table {
      width: 100%;

      th,
      td {
        padding: 7px 13px 4px 11px;
        text-align: center;
        font-size: 0.85rem;
      }

      tr {
        border-bottom: 0.5px solid #f5f6f8;
      }

      .stable_th {
        background: #f5f6f8;
      }
    }

    .resource_container {
      border: 1px solid #ced4da;
      border-radius: 3px;
      padding: 0 11px;

      .resource_div {
        margin: 17px 0;
        display: flex;
        align-items: center;
      }

      .resource_title {
        display: inline-block;
        width: 40px;
        margin-right: 16px;
        font-size: 0.9rem;
      }

      .range_div {
        display: inline-block;
      }

      .number_div {
        display: flex;
        margin-left: 10px;
        font-size: 0.85rem;
        align-items: center;

        .cpuValue {
          margin-right: 3px;
          color: #dc3545;
          font-weight: bold
        }
      }

      .form-number {
        width: 25px;
        border-bottom: 1px solid #dddddd;
      }

      .form-range {
        display: inline-block;
        -webkit-appearance: none;
        background: #fff;
        outline: none;
        width: 100%;
        min-width: 216px;
        height: 11px;
        border: 1px solid #bfbfbf;
        border-radius: 3px;
        font-size: 0.9rem;
        overflow: hidden;

        &::-webkit-slider-thumb {
          -webkit-appearance: none;
          width: 10px;
          height: 10px;
          border-radius: 50%;
          background: #dc3545;
          cursor: pointer;
          border: 1px solid #fff;
          box-shadow: -407px 0 0 400px #dc3545;

        }

        /*&:focus {
          outline: none;
        }
        &::-webkit-slider-runnable-track {
          width: 100%;
          height: 8px;
          cursor: pointer;
          box-shadow: none;
          background: #dddddd;
          border-radius: 0px;
          border: 0px solid #010101;
        }*/

      }
    }
  }
}


//실습실테이블 디자인
.ptable {
  table {
    thead {
      tr {
        th {
          font-size: 0.9rem;

          &:nth-child(1) {
            // width:55px !important;
          }
        }
      }
    }

    tbody {
      tr {
        td {
          vertical-align: middle !important;
          font-size: 0.85rem;

          .titleArea {
            font-size: 0.85rem;
          }

          .label {
            font-size: 0.8rem;
          }

          .list-unstyled {
            .sysli {
              display: flex;
              font-size: 0.8rem;

              img {
                display: inline-block;
                margin-right: 4px;
              }

            }
          }

          div {
            font-size: 0.8rem
          }

        }
      }
    }
  }
}




//0929 koreatech 종합수정
.tt-btn {

  &.btn-gephi2 {
    width: 35px;
    padding: 0;
    background: #fff url('#{$image-path}/icon_console.png') no-repeat calc(100% - 5px) center !important;

    a {
      display: block;
      width: 100%;
      height: 100%;
    }
  }

}


//0930 실습실 관리부분 수정
.settingWrap {
  display: flex;
  align-items: center;
  justify-content: center;

  .manage_container {
    width: 35px;
    height: 30px;
    display: flex;
    align-items: center;
    vertical-align: middle;
    justify-content: center;
    margin: 0 1px;
    border: 1px solid #ddd;
    border-radius: 3px;
  }

  .Manager {
    background-color: #fff;
  }

  .Monitoring {
    background: #949494 url('#{$image-path}/icon_monitoring.png') no-repeat calc(100% - 7px);
  }

  .MonitoringActive {
    background: #fff url('#{$image-path}/icon_monitoring.png') no-repeat calc(100% - 7px);

    a {
      display: block;
      width: 100%;
      height: 100%;
    }
  }
}


//1111 페이지네이션변경
.page_button_position {
  &.course {
    display: block;
  }

  display: flex;
  align-items: center;
  justify-content: center;

  .nextButtons {
    margin-left: 10px;
    width: 20px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

  }

  .fullNext {
    margin-left: 3px;
  }

  .prevButtons {
    margin-right: 10px;
    width: 20px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  .fullPrev {
    margin-left: 3px;
  }


}

//1112 페이지네이션 디자인변경
.page_num {
  .page_button_position {
    .page_num_button {
      width: fit-content;

      div {
        padding: 0 7px;
      }

      &.page_active {
        padding: 0;
      }
    }
  }
}

.page_button_position {
  .nextButtons {
    img {
      height: 16px
    }
  }

  .prevButtons {
    img {
      height: 16px
    }
  }
}