.comment-box{
  padding: 30px 30px;
  background: white;
  margin-top: 20px;
  margin-bottom: 20px;
  .writer{
    font-size: 0.9rem;
    display: inline-block;
    &:before{
      content: '';
      display: inline-block;
      width: 30px;
      height: 30px;
      vertical-align: middle;
      background: url('#{$image-path}/icon_user.png') no-repeat left center;
    }
  }
  .timer{
    margin-left: 5px;
    font-size: 0.9rem;
    display: inline-block;
  }
  .title_container{
    border-bottom: 1px solid #dddddd;
    padding-bottom: 15px;
    h2{
      color: #212529;
      padding-bottom:15px;
      font-size: 18px;
      font-weight: bold;
      display: inline-block;
      float:right;
    }
    .add_container{
      //float: left;
      display: inline-block;
      strong{
        margin: 0 10px;
      }
      .see{
        //&:before{
        //  content: '';
        //  display: inline-block;
        //  width: 20px;
        //  height: 15px;
        //  background: url('#{$image-path}/icon_see.png') no-repeat left center;
        //}
      }
      .thumb{
        margin-left:15px;
        margin:0 15px;
        //&:before{
        //  content: '';
        //  display: inline-block;
        //  width: 20px;
        //  height: 15px;
        //  background: url('#{$image-path}/icon_good.png') no-repeat left center;
        //}
      }
    }
  }
  form{
    .comment-form-fields{
      background: white;
      width: 95%;
      margin: 0 auto;
      margin-top: 20px;
      input{
        width: 730px;
        border: none;
      }
      textarea{
        width: 730px;
        height:60px;
        border: none;
        margin-top:10px;
      }
      .comment-form-actions{
        text-align: right;
        button{
          color:white;
          background: #1aa874;
          padding: 5px 10px;
          font-size: 0.9rem;
          border-radius: 5px;
        }
      }
      .disnone{
        display:none;
      }
    }
  }
  .comment_info{
    width: 95%;
    margin: 0 auto;
    border-bottom: 2px #dddddd;
    margin-top:30px;
    .comment-count{
      font-size: 18px;
      font-weight: bold;
      border-bottom: 2px solid #dddddd;
      padding-bottom: 10px;
    }
    .comment_title{
      display: inline-block;
    }
  }
  .comment-reveal_container{
    text-align: right;
    width: 730px;
    margin: 0 20px auto;
    button {
      &.comment-reveal {
        display: inline-block;
        padding: 5px 8px;
        border-radius: 5px;
      }
    }
  }
  .comment-list{
    .comment{
      width: 95%;
      margin: 0 auto;
      .comment-body{
        padding-left: 15px;
        margin: 20px 0;
        font-size: 0.95rem;
      }
      .comment-footer{
        padding-top:10px;
        border-top: 1px solid #f8f8f8;
        font-size:0.9rem;
        text-align: right;
        button{
          padding:5px 10px;
          border-radius: 3px;
          &.edit{
            border: 1px solid #666666;
          }
          &.delete{
            margin-left: 5px;
            color: white;
            border: 1px solid #d7021b;
            background: #d7021b;
          }
        }
      }
    }
  }
}