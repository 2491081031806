.lnb {
  width: 200px;
  position: relative;
  z-index: 2;
  padding: 0 10px;

  @media screen and (max-width: 1220px) {
    width: 180px;
  }

  @media screen and (max-width: 768px) {
    width: calc(100% + 20px);
    margin: 0 -10px;
  }

  flex: none;

  .lnb__layout {
    width: 200px;

    @media screen and (max-width: 1220px) {
      width: 180px;
    }

    @media screen and (max-width: 768px) {
      display: none;
      // width: 100%;
      // position: static !important;
      // border-radius: 0px;
    }
  }

  &.fixed {
    .lnb__layout {
      position: fixed;
      top: 145px;
    }
  }

  .lnb__scroll {
    margin-top: 15px;
    overflow-x: auto;
    width: 100%;
    background: #489659;

  }

  .lnb__list {
    background: white;

    @media screen and (max-width: 768px) {
      display: flex;
      border-bottom: 1px solid$theme-gray-4;
      width: auto;
      overflow: scroll;
      overflow-y: hidden;
    }
  }

  .lnb__item {
    border-bottom: 1px solid $theme-gray-4;
    word-break: keep-all;

    &:last-child {
      &:hover {}
    }

    &:first-child {
      border-top: none;

      &:hover {}
    }


    @media screen and (max-width: 768px) {
      flex: none;
      white-space: nowrap;
      border-bottom: none;
      position: relative;
    }

    &>a {
      display: flex;
      align-items: center;
      line-height: 50px;
      font-size: 1rem;
      //color: $theme-gray-6;
      color: #000;
      padding: 0 0.5em;

      @media screen and (max-width: 768px) {
        line-height: 35px;
      }

      &:hover {
        //border: 1 0 1 0 #d7e6d4;
        background: #d7e6d4;
      }

      &.active {
        position: relative;
        font-weight: 700;
        //color: $white;
        //background: $theme-black-4;
        color: #000;
        border-bottom: 2px solid #489659;

        @media screen and (max-width: 768px) {
          color: $theme-black-2;
          font-weight: 600;
          background: none;
          line-height: 33px;
          border-bottom: 2px solid #00863C;
          // max-width: 50px;
          // margin-bottom: 55px;
        }

        &:after {
          position: absolute;
          right: 0;
          content: '';
          width: 7px;
          height: 11px;
          background: url('#{$image-path}/now_film2.png') no-repeat center;
          display: block;

          @media screen and (max-width: 768px) {
            display: block;
          }
        }

        &+.sub_depth {

          display: block;

          @media screen and (max-width: 768px) {
            display: flex;
            position: absolute;
            top: 30%;
            left: -30%;
            z-index: 999;
          }
        }


      }
    }

  }

  .sub_depth {
    display: none;
    padding: 5px 0;
  }

  .lnb__inner__item {
    padding: 5px 15px;

    a {
      @media screen and (max-width: 768px) {
        line-height: 35px;
      }

      &.active {
        position: relative;
        font-weight: 700;
        //color: $white;
        //background: $theme-black-4;
        color: #000;

        @media screen and (max-width: 768px) {
          color: $theme-black-2;
          font-weight: 600;
          background: none;
          line-height: 33px;
          border-bottom: 2px solid $theme-black-2;
        }
      }
    }
  }

  .lnb__title {
    //border-bottom: 2px solid;
    //background: #489659;
    //background: $theme-red;
    margin-top: 25px;
    color: $white;
    //line-height: 110px;
    line-height: 1.25em;
    font-size: 1.25em;
    font-weight: 600;
    // padding: 40px 20px;
    padding: 12px 20px;
    text-align: left;
    // border-radius: 10px;
    background: #1a9952 url('#{$image-path}/main-quick-deco_3.png') right top repeat-y;
    background-size: 100%;
    font-family: 'Pretendard';

    @media screen and (max-width: 768px) {
      font-size: 1.3rem;
      line-height: 45px;
      margin-top: 0;
      padding: 25px 12px;
      border-radius: 0;
    }
  }
}

.active_mb {
  @media screen and (max-width: 768px) {
    color: $theme-black-2;
    font-weight: 600;
    background: none;
    line-height: 33px;
    border-bottom: 2px solid $theme-black-2;
    // max-width: 50px;
    margin-bottom: 30px;
  }
}