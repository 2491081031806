.faqList {
  .board__search {
    margin-top: 2em;
    justify-content: center;

    .input {
      width: 300px;
    }
  }

  .faqs {
    margin-top: 5em;
    border-top: 2px solid #337842;
  }

  .faqs__item {
    width: 100%;
    border-bottom: 1px solid$theme-gray-4;

    button {
      padding: 15px;
      font-size: 1rem;
      width: 100%;
      text-align: left;
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: #333;

      svg {
        color: $theme-gray-6;
      }
    }

    div {
      background: #f1f1f1;
      padding: 15px;
      white-space: pre-line;
    }
  }
}