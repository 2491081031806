.localdata {
  .lnb {
    display: none;
    @media screen and (max-width: 768px) {
      display: block;
    }
  }
  .wrap {
    display: flex;
  }

  .localdata__layout {
    padding-top: 3em;
    @media screen and (max-width: 768px) {
    }
    .wrap {
      display: flex;
      @media screen and (max-width: 768px) {
        display: block;
      }
    }

    & > .sub__title {
      margin-bottom: 3em;
      @media screen and (max-width: 768px) {
        margin-bottom: 2em;
      }
    }
  }

  .localdata__filter {
    width: 250px;
    flex: none;
    @media screen and (max-width: 1024px) {
      width: 200px;
    }
    @media screen and (max-width: 768px) {
      width: 100%;
      margin-bottom: 2em;
    }

    input {
      margin-right: 0.5em;
    }
  }

  .localdata__content {
    flex: 1;
    padding-left: 40px;
    @media screen and (max-width: 768px) {
      padding-left: 0;
    }
  }
}

.localdata__search {
  display: flex;
  align-items: center;

  input {
    flex: 1;
    border: 1px solid $theme-gray-4;
    height: 45px;
    border-right: 0;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
    padding: 0 1em;
  }
  button {
    height: 45px;
    width: 45px;
    background: $theme-red;
    color: $white;
    font-size: 1.5rem;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
  }
  .localdata__reset{
    background:#272b37;
    font-size:1rem;
    width:5rem;
    margin-left:1rem;
    border-radius: 3px;
  }
}

.localdata__list {
  margin-top: 1em;
}

.localdata__item {
  display: flex;
  padding: 1em;
  border: 1px solid $theme-gray-3;
  & ~ .localdata__item {
    margin-top: 1em;
  }
  @media screen and (max-width: 550px) {
    display: block;
  }

  .thumb {
    width: 250px;
    flex: none;
    @media screen and (max-width: 1024px) {
      width: 180px;
    }
    @media screen and (max-width: 550px) {
      width: 100%;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      vertical-align: top;
    }
  }
  .info {
    flex: 1;
    padding-left: 2em;
    @media screen and (max-width: 550px) {
      padding-left: 0;
      margin-top: 1em;
    }
    .info__top {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 0.5em;
      h2 {
        font-weight: 600;
        font-size: 1.25rem;
      }
      @media screen and (max-width: 1024px) {
        display: block;
        .created {
          margin-top: 5px;
        }
      }
    }
    .description {
      font-size: 1rem;
      line-height: 1.5em;
    }
    .info__foot {
      margin-top: 1em;
      display: flex;
      justify-content: flex-end;

      span {
        background: $theme-red;
        height: 28px;
        font-size: 14px;
        line-height: 30px;
        color: $white;
        padding: 0 1em;
      }
    }
    .created {
      font-size: 0.875rem;
      color: $theme-gray-5;
    }
  }
}

.localdataDetail__top {
  background: $theme-gray-10;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background: url('#{$image-path}/dummyimg32.jpg');
  position: relative;
  &:after {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1;
  }
  .wrap {
    position: relative;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 250px;
    @media screen and (max-width: 768px) {
      height: auto;
      display: block;
      padding: 2em 10px;
    }
  }
  .right {
    .right__row {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      @media screen and (max-width: 768px) {
        justify-content: center;
        margin-top: 2em;
      }

      & ~ .right__row {
        margin-top: 2em;
      }
      button {
        width: 80px;
        height: 40px;
        line-height: 40px;
        background: $black;
        border: 2px solid $white;
        color: $white;
        margin-left: 0.5em;
      }

      ._col {
        text-align: center;
        color: $white;
        font-size: 1.25rem;
        margin-left: 1.5em;
        strong {
          display: block;
          font-size: 2rem;
        }
      }
    }
  }
  .left {
    @media screen and (max-width: 768px) {
      text-align: center;
    }
    h2 {
      font-size: 2rem;
      color: $white;
    }
    p {
      font-size: 1.25rem;
      margin: 0.25em 0 0.5em;
      color: $white;
    }
    .snsbox {
      display: flex;
      @media screen and (max-width: 768px) {
        justify-content: center;
      }
      a {
        height: 40px;
        line-height: 40px;
        width: 40px;
        flex: none;
        margin-right: 5px;
        text-align: center;

        &:last-child {
          margin-right: 0;
        }
      }
      .facebook {
        font-size: 1.5rem;
        background: $color-facebook;
        color: $white;
      }
      .google {
        font-size: 2rem;
        background: $color-google;
        color: $white;
      }
      .twitter {
        font-size: 1.5rem;
        background: $color-twitter;
        color: $white;
      }
    }
  }
}

.localdataDetail__body {
  .wrap {
    display: flex;
    padding: 3em 10px;
    align-items: flex-start;
    @media screen and (max-width: 768px) {
      display: block;
      padding: 2em 10px;
    }
  }
  .thumb {
    width: 300px;
    flex: none;
    border: 1px solid $theme-gray-4;
    padding: 1em;
    @media screen and (max-width: 768px) {
      width: 100%;
    }
    img {
      width: 100%;
      vertical-align: top;
    }
    a {
      display: block;
      margin-top: 0.5em;
      line-height: 40px;
      background: $theme-red;
      color: $white;
      text-align: center;
    }
  }
  .detail {
    flex: 1;
    margin-left: 2em;
    padding: 1em 1em 1em 1.5em;
    border: 1px solid $theme-gray-4;
    @media screen and (max-width: 768px) {
      margin-top: 1em;
      margin-left: 0;
    }
  }
  ._row {
    &.hori {
      display: flex;
      @media screen and (max-width: 768px) {
        display: block;
      }
      strong {
        display: flex;
        justify-content: space-between;
        &:after {
          content: ':';
          @media screen and (max-width: 768px) {
            display: none;
          }
        }
      }
      p {
        padding-left: 0.5em;
        @media screen and (max-width: 768px) {
          padding: 0;
        }
      }
    }
    padding-left: 0.75em;
    position: relative;
    margin-bottom: 1em;

    strong {
      color: $theme-black-2;
      font-weight: 600;
      width: 70px;
      @media screen and (max-width: 768px) {
        width: 100%;
      }
      &:before {
        content: '';
        display: block;
        width: 3px;
        height: 3px;
        background: $theme-black-2;
        position: absolute;
        left: 0;
        top: 8px;
      }
    }
  }
}

.localdataDetail__foot {
  .wrap {
    display: block !important;
  }

  .files {
    background: $theme-gray-1;
    border: 1px solid $theme-gray-6;
    padding: 1em;
    ._row {
      display: flex;
      margin: 0 -10px;
    }
  }
  .file {
    display: block;
    margin: 0 10px;
    max-width: calc(20% - 20px);
    flex: 1;
    text-align: center;
    .icon {
      display: block;
      width: 100%;
      line-height: 80px;
      background: $theme-gray-3;
      border: 1px solid $theme-gray-4;
      font-size: 2rem;
    }
    p {
      margin-top: 10px;
      font-size:0.5rem;
    }
  }

  .actions {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 3em;

    a {
      background: $theme-red;
      color: $white;
      line-height: 45px;
      text-align: center;
      width: 150px;
    }
  }
}

.cklDetails {
  .actions {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 3em;

    a {
      background: $theme-red;
      color: $white;
      line-height: 45px;
      text-align: center;
      width: 150px;
    }
  }

  .cklDetails__body {
    padding: 2em 0;

    .slick-dots {
      margin-top: 1em;
      position: static;
    }
  }
  .cklDetails__header {
    display: flex;
    background: $theme-white-1;
    padding: 15px;
    border-top: 1px solid $theme-black-2;
    align-items: center;
    justify-content: space-between;
    h2 {
      //font-size: 1.125rem;
      font-size: 1rem;
    }
    .info {
      display: flex;
      align-items: center;
      margin-right: -10px;
      span {
        padding: 0 10px;
        //font-size: 1rem;
        font-size: 0.8rem;
        line-height: 1em;
        & ~ span {
          border-left: 1px solid $theme-gray-4;
        }
      }
    }
  }
}
