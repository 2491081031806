@-webkit-keyframes dialog-fadeout {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes dialog-fadeout {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@-webkit-keyframes dialog-fadein {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes dialog-fadein {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-webkit-keyframes dialog-flyin {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-40px);
    transform: translateY(-40px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@keyframes dialog-flyin {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-40px);
    transform: translateY(-40px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@-webkit-keyframes dialog-flyout {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  100% {
    opacity: 0;
    -webkit-transform: translateY(-40px);
    transform: translateY(-40px);
  }
}

@keyframes dialog-flyout {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  100% {
    opacity: 0;
    -webkit-transform: translateY(-40px);
    transform: translateY(-40px);
  }
}

@-webkit-keyframes jump {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      -moz-transform: translate3d(0, 0, 0);
    }
  
    25% {
      -webkit-transform: translate3d(0, 3%, 0);
      -moz-transform: translate3d(0, 3%, 0);
    }
  
    50% {
      -webkit-transform: translate3d(0, 6%, 0);
      -moz-transform: translate3d(0, 6%, 0);
    }
  
    75% {
      -webkit-transform: translate3d(0, 3%, 0);
      -moz-transform: translate3d(0, 3%, 0);
    }
  
    100% {
      -webkit-transform: translate3d(0, 0%, 0);
      -moz-transform: translate3d(0, 0%, 0);
    }
  }
  @keyframes jump {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      -moz-transform: translate3d(0, 0, 0);
    }
  
    25% {
      -webkit-transform: translate3d(0, 3%, 0);
      -moz-transform: translate3d(0, 3%, 0);
    }
  
    50% {
      -webkit-transform: translate3d(0, 6%, 0);
      -moz-transform: translate3d(0, 6%, 0);
    }
  
    75% {
      -webkit-transform: translate3d(0, 3%, 0);
      -moz-transform: translate3d(0, 3%, 0);
    }
  
    100% {
      -webkit-transform: translate3d(0, 0%, 0);
      -moz-transform: translate3d(0, 0%, 0);
    }
  }

  @-webkit-keyframes dialog-slidein {
    from {
        opacity: 0;
        height: 0;
        padding-top: 0;
        padding-bottom: 0;
        visibility: hidden;
      }
      to { 
        opacity: 1;
        height: auto;
        padding-top: 20px;
        padding-bottom: 20px;
        visibility: visible;
      }
  }
  
  @keyframes dialog-slidein {
    from {
        opacity: 0;
        height: 0;
        padding-top: 0;
        padding-bottom: 0;
        visibility: hidden;
      }
      to { 
        opacity: 1;
        height: auto;
        padding-top: 20px;
        padding-bottom: 20px;
        visibility: visible;
      }
  }
  
  @-webkit-keyframes dialog-slideout {
    from {
        opacity: 1;
        height: 100%;
        padding-top: 20px;
        padding-bottom: 20px;
        visibility: visible;
      }
      to { 
        opacity: 0;
        height: 0;
        padding-top: 0;
        padding-bottom: 0;
        visibility: hidden;
      }
  }
  @keyframes dialog-slideout {
    from {
        opacity: 1;
        height: 100%;
        padding-top: 20px;
        padding-bottom: 20px;
        visibility: visible;
      }
      to { 
        opacity: 0;
        height: 0;
        padding-top: 0;
        padding-bottom: 0;
        visibility: hidden;
      }
  }
  @-webkit-keyframes dialog-right_slide_in {
    from {
        right: -100%;
      }
      to { 
          right: 0%;
      }
  }
  
  @keyframes dialog-right_slide_in {
    from {
        right: -100%;
      }
      to { 
          right: 0%;
      }
  }
  
  @-webkit-keyframes dialog-right_slide_out {
    from {
        right: 0%;
      }
      to { 
          right: -100%;
      }
  }
  @keyframes dialog-right_slide_out {
    from {
        right: 0%;
      }
      to { 
          right: -100%;
      }
  }
  @-webkit-keyframes dialog-top_in {
    from {
        top: -100%;
      }
      to { 
        top: 0%;
      }
  }
  
  @keyframes dialog-top_in {
    from {
        top: -100%;
      }
      to { 
        top: 0%;
      }
  }
  
  @-webkit-keyframes dialog-top_out {
    from {
        top: 0%;
      }
      to { 
        top: -100%;
      }
  }
  @keyframes dialog-top_out {
    from {
        top: 0%;
      }
      to { 
        top: -100%;
      }
  }
  @-webkit-keyframes dialog-left_slide_in {
    from {
        left: -100%;
      }
      to { 
        left: 0%;
      }
  }
  
  @keyframes dialog-left_slide_in {
    from {
      left: -100%;
      }
      to { 
        left: 0%;
      }
  }
  
  @-webkit-keyframes dialog-left_slide_out {
    from {
      left: 0%;
      }
      to { 
        left: -100%;
      }
  }
  @keyframes dialog-left_slide_out {
    from {
      left: 0%;
      }
      to { 
        left: -100%;
      }
  }

  @keyframes hamburger-menu-top{
    from {
            transform: none;
        }
        to { 
          transform: translate(0%, 0%) matrix(0.7071, -0.7071, 0.7071, 0.7071, 0, 10);
        } 
  }
  @-webkit-keyframes hamburger-menu-top {
    from {
        transform: none;
      }
      to { 
        transform: translate(0%, 0%) matrix(0.7071, -0.7071, 0.7071, 0.7071, 0, 10);
      }
  }

  @keyframes hamburger-menu-top-return{
    from {
            transform: translate(0%, 0%) matrix(0.7071, -0.7071, 0.7071, 0.7071, 0, 10) ;
        }
        to { 
          transform: none;
        } 
  }
  @-webkit-keyframes hamburger-menu-top-return {
    from {
        transform: translate(0%, 0%) matrix(0.7071, -0.7071, 0.7071, 0.7071, 0, 10) ;
      }
      to { 
        transform: none;
      }
  }
  @-webkit-keyframes hamburger-menu-middle {
    from {
        width: 60%;
      }
      to { 
        width: 0;
      }
  }
  @keyframes hamburger-menu-middle {
    from {
        width: 60%;
      }
      to { 
        width: 0;
      }
  }
  @-webkit-keyframes hamburger-menu-middle-return {
    from {
        width: 0;
      }
      to { 
        width: 60%;
      }
  }
  @keyframes hamburger-menu-middle-return {
    from {
        width: 0;
      }
      to { 
        width: 60%;
      }
  }
  @-webkit-keyframes hamburger-menu-bottom {
    from {
        transform: none;
      }
      to { 
        transform: translate(0%, 0%) matrix(0.7071, 0.7071, -0.7071, 0.7071, 0, -8);
      }
  }
  @keyframes hamburger-menu-bottom {
    from {
        transform: none;
      }
      to { 
        transform: translate(0%, 0%) matrix(0.7071, 0.7071, -0.7071, 0.7071, 0, -8);
      }
  }
  @-webkit-keyframes hamburger-menu-bottom-return {
    from {
        transform: translate(0%, 0%) matrix(0.7071, 0.7071, -0.7071, 0.7071, 0, -8);
      }
      to { 
        transform: none translate(0%, 0%) matrix(0.7071, 0.7071, -0.7071, 0.7071, 0, -8);
      }
  }
  @keyframes hamburger-menu-bottom-return {
    from {
        transform: translate(0%, 0%) matrix(0.7071, 0.7071, -0.7071, 0.7071, 0, -8);
      }
      to { 
        transform: none ;
      }
  }