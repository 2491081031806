.resource {
  .section {
    margin-bottom: 3em;
  }
}

.greenButton{
  margin-left: 5px;
  background-color: #489659;
  width: 50px;
  height: 30px;
  border-radius: 12px;
  color: white;
}

.page-resource-evaluate {
  .sub_survey_title {
    width: 100%;
    background-color: #f8f8f8;
    padding: 30px;
    border-radius: 5px;

    h1 {
      font-size: 1.4rem;
      font-weight: 600;
      position: unset;
      margin-bottom: 10px;
    }
  }

  .ql-container {
    height: auto;
    border: 1px solid #bedaf4 !important;
    border-radius: 5px;
    padding: 20px;
    margin: 20px 0 40px;
  }

  .survey_question {
    padding: 0 30px 15px;
    border: 1px solid #e5e5e5;
    border-radius: 5px;
    margin: 20px 0;

    .boardDetail__content {
      input[type=text] {
        width: 100%;
        border: 1px solid #e5e5e5;
        height: 40px;
      }
    }

    h1 {
      margin-top: 0.3rem;
      margin-bottom: 2.3rem;
      line-height: 4.3rem;
      border-bottom: 0.5px solid #e5e5e5;
      font-size: 1.2rem;
      font-weight: 600;
      position: unset;
      letter-spacing: -1.5px;
    }

    label {
      vertical-align: middle;
      font-size: 17px;
      padding: 0 0 15px 10px;
      font-weight: 500;

      input[type=radio] {
        background-color: #ececec;
        border: 7px solid #ececec;
        width: 30px;
        height: 30px;
        display: inline-block;
        vertical-align: middle;
        margin-right: 15px;
        appearance: none;
        border-radius: 50px;

        &:checked {
          border: 7px solid #92c1ec;
          width: 30px;
          height: 30px;
        }
      }

      input[type=checkbox] {
        background-color: #ececec;
        border: 5px solid #ececec;
        width: 20px;
        height: 20px;
        display: inline-block;
        vertical-align: middle;
        margin-right: 15px;
        appearance: none;

        &:checked {
          border: 7px solid #92c1ec;
          width: 20px;
          height: 20px;
        }
      }
    }

    .evaluate_table {

      input[type=radio] {
        background-color: #ececec;
        border: 7px solid #ececec;
        width: 20px;
        height: 20px;
        display: inline-block;
        vertical-align: middle;
        appearance: none;
        border-radius: 50px;

        &:checked {
          border: 5px solid #92c1ec;
          width: 20px;
          height: 20px;
        }
      }
    }
  }
}