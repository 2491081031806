.video__pagination {

}

.page-lms{
  .header,.intro__footer,.mobileUserMenu,.mobileNav,.quick{
    display:none;
    &.header_video{
      display: block;
    }
  }
  .header_video {
    height: 60px;
    // @include theme('background', 'background');
    display: flex;
    align-items: center;

    @media screen and (max-width: 768px) {
      padding:0 20px;
      position: fixed;
      left: 0;
      top: 0;
      width: 100%;
      z-index: 5000;
    }

    ._breadcrumb {
      background:none;
      display: flex;
      @media screen and (max-width: 768px) {
        display: none;
      }
      a {
        font-size: 14px;
        // @include theme('color', 'default-color');
        & ~ a {
          background: url('#{$image-path}/bread-right.png') no-repeat 8px center;
          .gradient & {
            background: url('#{$image-path}/bread-right-white.png') no-repeat 8px center;
          }
          padding-left: 20px;
        }
      }
    }

    .mobile-header-title {
      display: none;
      align-items: center;
      @media screen and (max-width: 768px) {
        display: flex;
      }

      .menu {
        background: url('#{$image-path}/hamburger-icon.png') no-repeat center;
        background-size: 100% auto;
        width: 20px;
        height: 30px;
        margin-right: 10px;

        .theme-dark & {
          background: url('#{$image-path}/hamburger-icon2.png') no-repeat center;
          background-size: 100% auto;
        }
      }
      h2 {
        // @include theme('color', 'nav-block-name');
        font-size: 20px;
        font-weight: 700;
      }
    }
    .haeder__right {
      display: flex;
      padding-right: 30px;
      margin-left: auto;
      @media screen and (max-width: 768px) {
        padding-right: 0;
      }

      button {
        height: 30px;
        min-width: 30px;
        margin-left: 10px;
        position: relative;
        outline-width: 0;
        @media screen and (max-width: 768px) {
          min-width: auto;

        }
        .new {
          position: absolute;
          background:#f00;
          color:#fff;
          font-size: 10px;
          width: 15px;
          height: 15px;
          display: block;
          border-radius: 50%;
          text-align: center;
          line-height: 15px;
          left:-2px;
          top: 0px;
        }
      }

      .drop-button {
        font-size: 14px;
        text-align: left;
        padding-right: 20px;
        background: url('#{$image-path}/small-arrow-bottom.png') no-repeat right center;
        // @include theme('color', 'header-text');
      }
    }
  }

}

.mobileShowContentList {
  height: 40px;
  // @include theme('color', 'nav-block-name');
  // @include theme('background', 'PanelContentList-top');
  width: 100%;
  text-align: right;
  padding: 0 20px;
  display: none;

  @media screen and (max-width: 768px) {
    display: block;
  }
}

.PanelContentList {
  position: fixed;
  top: 61px;
  height: calc(100vh - 61px);
  width: 400px;
  left: -400px;
  border-right: 1px solid #ddd;
  z-index: 3300;
  transition: all .3s;

  @media screen and (max-width: 768px) {
    width: 300px;
    left: auto;
    right: -300px;
    top: 0;
    height: 100vh;
    z-index: 9000;
  }

  // @include theme('background', 'whitenblack');
  // @include theme('border-color', 'border');

  &.active {
    left: 0;
    @media screen and (max-width: 768px) {
      left: auto;
      right: 0;
      background: white;
    }
  }

  .info {
    // @include theme('background', 'PanelContentList-info');
    padding: 20px;

    h4 {
      // @include theme('color', 'default-color');

    }
  }

  .sectionBlock {
    padding:0 20px;

    h3 {
      font-size: 1rem;
      // @include theme('color', 'default-color');
      line-height: 55px;
    }


    .section {
      border-top: 1px solid #ddd;
      // @include theme('border-color', 'border');
      padding: 1.5em 0;


      }
    }

  h4 {
    font-size: 1.125rem;
    font-weight: 600;
    // @include theme('color', 'nav-block-name');
  }
  ul {
    margin-top: 10px;
  }
  li {
    font-size: 1rem;
    & ~ li {
      margin-top: 5px;
    }
  }
  a {
    display: block;
    // @include theme('color', 'default-color');
    padding-left: 1.5em;
    position: relative;

    &:before {
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      content: '';
      display: block;
      width: 18px;
      height: 18px;
      border: 4px solid #e0e0e0;
      border-radius: 50%;
    }

    &.active {
      color:#ff0000;
      &:before {
        border-color:#ff0000;
      }
    }
  }
  .top {
    text-align: center;
    line-height: 40px;
    position: relative;
    // @include theme('color', 'nav-block-name');
    // @include theme('background', 'PanelContentList-top');

    button {
      right: -40px;
      height: 40px;
      width: 40px;
      border: 1px solid #ddd;
      border-left: 0;
      border-top: 0;
      position: absolute;
      // @include theme('background', 'background');
      // @include theme('border-color', 'border');
      // @include theme('color', 'nav-block-name');
      @media screen and (max-width: 768px) {
        display: none;
      }
      svg {
        vertical-align: baseline;
      }
    }
  }
}

.panelLayout {
  .panelLayout__content {
    width: 100%;
    max-width: 820px;
    margin:0 auto;
    &.panelLayout__content--module2 {
      padding-top: 15px;
    }
  }

  .dim {
    @media screen and (min-width: 769px) {
      display: none !important;
    }
  }

  .chatbox {
    margin-top: 20px;
    padding-top: 20px;
    border-top: 1px solid #ddd;
    // @include theme('border-color', 'border');
    margin-bottom: 10px;
  }


}

.Video__header {
  height: 40px;
  display: flex;
  padding:0 5px 0 10px;
  align-items: center;
  border: 1px solid #ddd;
  border-top: none;
  // @include theme('background', 'background');
  // @include theme('border-color', 'border');

  &.Video__header--border {
    border-top: 1px solid #ddd;
    // @include theme('border-color', 'border');
  }
  strong {
    font-size: 1rem;
    background: url('#{$image-path}/activity2-icon.png') no-repeat left 8px;
    padding-left: 1.5em;
    // @include theme('color', 'nav-block-name');
    .theme-dark & {
      background: url('#{$image-path}/activity2-icon2.png') no-repeat left 8px;
    }
  }

  .actions {
    margin-left: auto;

    button {
      width: 30px;
      height: 30px;
      vertical-align: top;
    }
    .action1 {
      background: url('#{$image-path}/video-action1.png') no-repeat center;
    }
    .action2 {
      background: url('#{$image-path}/video-action2.png') no-repeat center;
    }
  }
}
.pdfLayout {
  width: 100%;
  margin: auto;
  flex: 1;
  iframe {
    width: 100%;
    height: 100%;
    @media screen and (max-width: 768px) {
      min-height: 400px;
    }
  }
}

.interactiveLayout {

  & > .splitter-layout {
    height: calc(100% - 60px);
  }

  .videoLayout {
    width: 100%;
    max-width: 820px;
    margin: auto;
  }



  .chat{
    flex: 1;
    display: flex;
    flex-direction: column;

    .chat__body {
      flex: 1;
      max-height: none;
    }
  }

  .block {
    // @include theme('background', 'whitenblack');
    height: 100%;
    display: flex;
    flex-direction: column;
    @media screen and (max-width: 768px) {
      & ~ .block {
        margin-top: 10px;
      }
    }
  }
  .block__tabs {
    border-bottom: 1px solid #ddd;
    // @include theme('border-color', 'border');
    button {
      height: 35px;
      padding:0 20px;
      // @include theme('background', 'block-tab-bg');
      // @include theme('color', 'default-color');

      span {
        padding-left: 1.25em;
      }
      &.video {
        span {
          background: url('#{$image-path}/activity2-icon.png') no-repeat left center;
          .theme-dark & {
            background: url('#{$image-path}/activity2-icon2.png') no-repeat left center;
          }
        }
      }

      &.comment {
        span {
          background: url('#{$image-path}/activity1-icon.png') no-repeat left center;
          .theme-dark & {
            background: url('#{$image-path}/activity1-icon2.png') no-repeat left center;
          }
        }
      }

      &.training {
        span {
          background: url('#{$image-path}/activity6-icon.png') no-repeat left center;
          .theme-dark & {
            background: url('#{$image-path}/activity6-icon2.png') no-repeat left center;
          }
        }
      }
      &.book {
        span {
          background: url('#{$image-path}/activity3-icon.png') no-repeat left center;
        }
      }

      &.active {
        position:relative;
        top: 1px;
        // @include theme('background', 'whitenblack');
        // @include theme('color', 'nav-block-name');
      }
    }
  }
}


.detailLayout3 {
  padding-top: 70px;
  min-height: calc(100vh - 60px);
  position: relative;

  @media screen and (max-width: 768px) {
    min-height: calc(100vh - 60px);
    padding-top: 0;
    padding-bottom: 100px;

    .detailLayout3__progress {
      position: fixed !important;
      top: auto !important;
      left: 0;
      bottom: 50px !important;
      z-index: 3000;
      background:#f6f8fb;

      .theme-dark & {
        background: #2c3038;
      }
    }
  }


  .detailLayout3__progress {
    position: absolute;
    height: 70px;
    width: 100%;
    left: 0;
    top: 0;
    padding-right: 130px;
    display: flex;
    align-items: center;
    @media screen and (max-width: 1500px) {
      padding-right: 20px;
    }
    @media screen and (max-width: 768px) {
      height: 50px;
    }


    .inner_progress {
      flex: 1;
      height: 5px;
      border-radius: 3px;
      margin-right: 10px;
      position: relative;
      cursor: pointer;
      margin-left:  65px;
      @media screen and (max-width: 768px) {
        margin-left:  0px;
      }

      ._section-list {
        display: flex;
        ._section {
          flex: none;
          background:#e5eaf1;
          height: 5px;
          margin-right: 3px;
          position: relative;

          &:hover {
            top: -2px;
            height: 9px;
          }
        }
      }

      .play-control {
        box-shadow: 0px 0px 5px rgba(0,0,0,.5);
        position: absolute;
        left: -13px;
        top: 50%;
        transform: translateY(-50%);
        width: 25px;
        height: 25px;
        z-index: 20;
        border-radius: 50%;
        background: #fff url('#{$image-path}/play-icon.png') no-repeat center;
        background-size:auto 10px;
        @media screen and (max-width: 768px) {
          left: 55px;
        }
        &.play {
          background: #fff url('#{$image-path}/pause-icon.png') no-repeat 9px center;
        }
      }

      .inner_progress-bar {
        width: 0%;
        display: block;
        height: 5px;
        background:#8c98aa;
        position: absolute;
        left: 0;
        top: 0;
        &:after {
          content:'';
          width: 15px;
          height: 15px;
          position: absolute;
          right: -7px;
          top: 50%;
          transform: translateY(-50%);
          border: 3px solid #8c98aa;
          background:#fff;
          border-radius: 50%;
          box-shadow: 0px 0px 5px rgba(0,0,0,.5);
        }
      }
    }

    .timer {
      color:#666;
      // @include theme('color', 'default-color');
      display: flex;
      align-items: center;
      span {
        font-size: 14px;
      }
      .divider {
        margin:0 3px;
      }
      .current {
        font-weight: 600;
      }
    }
  }

  .Video__header {
    border-top: 1px solid #ddd;
    // @include theme('border-color', 'border');
  }

  .detailLayout3__inner {
    height: 100%;
    display: flex;
    padding:0 130px 20px;
    position: relative;
    @media screen and (max-width: 1500px) {
      padding-right: 20px;
    }
    @media screen and (max-width: 1024px) {
      display: block;
    }
    @media screen and (max-width: 768px) {
      padding: 10px 0 0 !important;
      min-height: calc(100vh - 170px);

      .mobileView {
        display: none !important;

        &.active {
          display: block !important;
        }
      }

      .chatbox {
        margin: 0;

        .chat {
          height: 100%;
          min-height: calc(100vh - 170px);
        }
      }
    }
  }


  .detailLayout3__left,
  .detailLayout3__right {
    flex: 1;
    background:#fff;
    display: flex;
    flex-direction: column;
  }

  .detailLayout3__left {
    background:#f6f8fb;
  }
  .detailLayout3__right {
    margin-left: 20px;

    @media screen and (max-width: 1024px) {
      margin-left:0;
      margin-top: 10px;
    }
  }


  .chatbox {
    flex: 1;
    margin-top: 10px;

    .chat {
      height: 100%;
      display: flex;
      flex-direction: column;
    }

    .chat__body {
      flex: 1;
      max-height: none;
    }
  }
  .Codebox {
    border: 1px solid #ddd;
    flex: 1;
    border-top: none;
    // @include theme('border-color', 'border');

    .editorbox {
      height: 100%;
    }
  }

  .detailLayout3__tab {
    position: absolute;
    left: 0;
    top: 0;
    width: 130px;

    @media screen and (max-width: 768px) {
      position: fixed;
      display: flex;
      top: auto;
      bottom: 0;
      z-index: 2000;
      height: 60px;
      padding: 10px;
      width: 100%;
      overflow: auto;
      overflow-y: hidden;
    }

    .block {
      margin-bottom: 10px;
      text-align: center;
      @media screen and (max-width: 768px) {
        margin: 0;
        flex: 1;
        margin-right: 5px;
        display: none;

        &.active {
          display:block;
          button {
            background:#2e8bc5 !important;
            .theme-dark & {
              background:#1d1f22 !important;
            }
            span {
              color:#fff !important;
            }
          }
        }

        &:last-child {
          padding-right: 10px;
        }
      }
    }
    button {
      width: 60px;
      height: 60px;
      border-radius: 15px;
      transition: all .3s;
      position: relative;
      outline-width: 0;
      i {
        position: absolute;
        color:#fff;
        background:#f00;
        display: block;
        width: 15px;
        height: 15px;
        right: 5px;
        top: 5px;
        line-height: 13px;
        font-size: 10px;
        border-radius: 50%;

        &.hidden {
          opacity: 0;
        }
        @media screen and (max-width: 768px) {
          display: none;
        }
      }

      span {
        display: none;
        position: absolute;
        left: 70px;
        transform: translateY(-50%);
        white-space: nowrap;
        z-index: 50;
        background:#fff;
        font-size: 12px;
        border-radius: 3px;
        line-height: 20px;
        box-shadow: -5px 0px 10px rgba(0,0,0,.2);
        padding:0 10px;
        @media screen and (max-width: 768px) {
          display: block;
          box-shadow: none;
          background: none;
          padding: 0;
          position: static;
          transform: none;
          color:#666;
        }
        &:after {
          content: '';
          display: block;
          width: 0px;
          height: 0px;
          border-top:5px solid transparent;
          border-bottom:5px solid transparent;
          border-right: 5px solid #fff;
          border-left: 5px solid  transparent;
          position: absolute;
          left: -10px;
          top: 50%;
          transform: translateY(-50%);

          @media screen and (max-width: 768px) {
            display: none;
          }
        }
      }

      &:hover {
        span {
          display: block;
        }
      }
      @for $i from 1 through 6 {
        &.tab#{$i} {
          background: url('#{$image-path}/inter-icon#{$i}.png') no-repeat center;
          &.active{
            background: #eff3f9 url('#{$image-path}/inter-icon#{$i}.png') no-repeat center;
            .theme-dark & {
              background: #1d1f22 url('#{$image-path}/inter-icon#{$i}.png') no-repeat center;
            }
          }
        }
      }


      @media screen and (max-width: 768px) {
        background:#fff !important;
        border: 1px solid #ddd;
        height: 40px !important;
        width: 100%;
        border-radius:5px;
        min-width: 100px;
        // @include theme('border-color', 'border');

      }
    }
  }
}

._wedget-header {
  border-top: none !important;
  border-left: none !important;
  border-top: none !important;
  border-bottom: 1px solid #eee;
  // @include theme('border-color', 'border');

  background: #fff;

  .theme-dark & {
    background: #1d1f22;
  }

  // @include theme('background', 'background');
  // @include theme('border-color', 'border');

  & > div:not(.jqx-window-close-button-background) {
    margin: 0 !important;
    display: flex;
    align-items: center;
    width: 100%;
  }

  .actions {
    margin-left: auto !important;
  }
}

.jqx-widget-content {
  // @include theme('background', 'background');
  // @include theme('border-color', 'border');
}

.write_comment{
  position: absolute;
    background: #cecece;
    padding: 10px;
    display: grid;
    border-radius: 5px;

  input[type="file"] {
    margin-top: 7px;
    font-size: 0.9rem;
  }
  textarea {
    border: 1px solid #ddd;
    border-radius: 5px;
  }
}
.hidden {
  display: none !important;
}
.jqx-window,
.detailLayout3__pc {
  flex: 1;
  display: flex;
  @media screen and (max-width: 768px) {
    display: none !important;
  }
}

.jqx-window-content {

}

.detailLayout3__mobile {
  display: none;
  @media screen and (max-width: 768px) {
    display: block;
  }
}
